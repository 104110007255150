import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Interruptions } from './../data/data';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Interruption from './interruption';
import { withStyles } from '@material-ui/core/styles';
import { updateGroupStateParamsVal } from './../../../../../store/actions/gameActions';
import styles from '../../../../Styles';

const INITIAL_STATE = {
    timerActive: true,
    currentInterruption: null
}

class ActivityTimer extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount(){
        let intervalId = setInterval(() => {

            if ( this.state.timerActive === true && (this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === undefined || this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === false) ){

                let lastInterruption = this.props.session.active_game.groupStates[this.props.group_id].params.lastInterruption;
                if ( lastInterruption !== undefined ){
                    let diffMs = (new Date().getTime() - new Date(lastInterruption)); // milliseconds between now & Christmas
                    let minutes = (diffMs/1000)/60;
                    if ( minutes >= 2.5 ){
                        this.interrupt();
                    }
                } else {
                    let diffMs = (new Date().getTime() - new Date(this.props.session.active_game.activeOn)); // milliseconds between now & Christmas
                    let minutes = (diffMs/1000)/60;
                    
                    if ( minutes >= 5 ){
                        this.interrupt();
                    }   
                }
            } else { }
        }, 5000);
        this.setState({intervalId: intervalId});
        this.updateInterruptionState();
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);       
    }

    updateInterruptionState() {
        if ( (this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === undefined || this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === false) && this.state.currentInterruption !== null ){
            this.setState({
                ...this.state,
                currentInterruption: null
            });
        }
        if ( (this.props.session.active_game.groupStates[this.props.group_id].params.interrupting !== undefined && this.props.session.active_game.groupStates[this.props.group_id].params.interrupting !== false) && this.state.currentInterruption === null ){
            this.setState({
                ...this.state,
                currentInterruption: this.props.session.active_game.groupStates[this.props.group_id].params.interrupting
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateInterruptionState();
        if ( this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === false && (prevProps.session.active_game.groupStates[this.props.group_id].params.interrupting !== undefined && prevProps.session.active_game.groupStates[this.props.group_id].params.interrupting !== false) ){
            if ( this.state.timerActive === false ){
                this.setState({
                    ...this.state,
                    timerActive: true
                })
            }
        }
    }

    pickCase() {
        let arr = Interruptions;
        let alreadyPicked = this.props.session.active_game.groupStates[this.props.group_id].params.alreadyPicked === undefined ? [] : this.props.session.active_game.groupStates[this.props.group_id].params.alreadyPicked;
        let randomItem = null;

        if ( alreadyPicked.length > 0 ){
            let onlyValidValues = arr.filter(v => alreadyPicked.indexOf(v.id) === -1 );
            if (onlyValidValues.length === 0) {
                throw new Error('empty array');
            }
            randomItem = onlyValidValues[Math.floor(Math.random() * onlyValidValues.length)];
        } else {            
            randomItem = arr[Math.floor(Math.random() * arr.length)];   
        }
        return randomItem;
    } 

    interrupt() {
        /*  We don't want to duplicate data here, so only the lead player can actually cause the interruption.
            This means that a host that's logged in can't trigger the interruptions without the lead player actvely having the screen open */
        if ( this.props.session.active_game.groups[this.props.group_id].leadPlayer !== this.props.auth.uid ){
            return;
        }
        /* tell the interface to close down any windows it had open temporarily */
        /* disable the timer while we are interrupting */
        this.setState({
            ...this.state,
            timerActive: false
        });
        /* get a random interruption */
        let interruption = this.pickCase();
        /* as an added measure, log that the group is currently interrupted */
        this.props.updateGroupStateParamsVal(this.props.group_id, 'interrupting', interruption.id, this.props.host);
        /* store which interruption we picked so that we don't pick it again */
        let alreadyPicked = this.props.session.active_game.groupStates[this.props.group_id].params.alreadyPicked === undefined ? [] : this.props.session.active_game.groupStates[this.props.group_id].params.alreadyPicked;

        alreadyPicked = [
            ...alreadyPicked,
            interruption.id
        ];
        this.props.updateGroupStateParamsVal(this.props.group_id, 'alreadyPicked', alreadyPicked, this.props.host);
    }

    render() {
        if ( this.state.currentInterruption === null ){
            return null;
        } else {
            return <Interruption id={this.state.currentInterruption} session={this.props.session} profile={this.props.profile} auth={this.props.auth} host={this.props.host} group_id={this.props.group_id} control={this.props.control} />;
        }
    }
}

ActivityTimer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
        updateGroupStateParamsVal : (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID))
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ActivityTimer);
