import React, { useMemo } from "react";
import { animated, useTransition } from "react-spring";

const Message = ({ message, updater }) => {
    const items = useMemo(
        () =>
        message.split("").map((letter, index) => ({
            item: letter,
            key: index
        })),
        [message]
    );
    //console.log('Hm', message, items);
    const transitions = useTransition(items, item => item.key, {
        trail: 25,
        from: { display: "none" },
        enter: { display: "" }
    });
    
    React.useEffect(() => {
        //console.log('HMMM', deselectAll);
        let l = transitions.length;
        //console.log(l, (l*25)+250);
        setTimeout(() => {
            updater();
        }, (l*25))
    }, [message])

    return (
        <div>
        {transitions.map(({ item, props, key }) => {
            return (
            <animated.span key={key} style={props}>
                {item.item}
            </animated.span>
            );
        })}
        </div>
    );
};

export default Message;
