export const CardList = [
    {
        id: 1,
        card_words: 'Trust',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 2,
        card_words: 'Information',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 3,
        card_words: 'New Ideas',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 4,
        card_words: 'Warnings',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 5,
        card_words: 'Enthusiasm',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 6,
        card_words: 'Respect',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 7,
        card_words: 'Feedback',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 8,
        card_words: 'My Extra Time',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 9,
        card_words: 'Loyalty',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 10,
        card_words: 'Meet Deadlines',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 11,
        card_words: 'Highest Quality',
        card_category: 'blue',
        card_fun: false
    },
    {
        id: 12,
        card_words: 'Trust',
        card_category: 'green',
        card_fun: false
    },
    {
        id: 13,
        card_words: 'Information',
        card_category: 'green',
        card_fun: false
    },
    {
        id: 14,
        card_words: 'New Ideas',
        card_category: 'green',
        card_fun: false
    },
    {
        id: 15,
        card_words: 'Warnings',
        card_category: 'green',
        card_fun: false
    },
    {
        id: 16,
        card_words: 'Enthusiasm',
        card_category: 'green',
        card_fun: false
    },
    {
        id: 17,
        card_words: 'Respect',
        card_category: 'green',
        card_fun: false
    },
    {
        id: 18,
        card_words: 'Feedback',
        card_category: 'green',
        card_fun: false
    },
    {
        id: 19,
        card_words: 'My Extra Time',
        card_category: 'green',
        card_fun: false
    },
    {
        id: 20,
        card_words: 'Loyalty',
        card_category: 'green',
        card_fun: false
    },
    {
        id: 21,
        card_words: 'Meet Deadlines',
        card_category: 'green',
        card_fun: false
    },
    {
        id: 22,
        card_words: 'Highest Quality',
        card_category: 'green',
        card_fun: false
    }
];


