export const LANDING = '/';
export const LOGIN = '/login';
export const HOME = '/';
export const SIGN_UP = '/signup';
export const SIGN_UP_REDIRECT = '/signup/:id';
export const PASSWORD_FORGET = '/pw-forget';
export const FORGOTTEN_PASSWORD = '/password-reset';
export const ANON_SIGN_IN = '/p/:id';
export const ANON_SIGN_IN_NID = '/p';
export const CHANGE_NAME = '/name';
export const DEFAULT = '/about';
export const ADMIN = '/user-management';
export const PASTDATA = '/past-data';
export const DASHBOARD = '/dashboard';
export const CONTACT = '/contact';
export const THEME = '/theme';
export const PLANNING = '/session_planning';