import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import styles from '../../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DiSC_Icon from '../images/DiSC_Icon.png'
import Button from '@material-ui/core/Button';


const INITIAL_STATE = {
    open: false
}

class TrustCard extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    handleOpen(){
        console.log("opening?");
        this.setState({
            ...this.state,
            open: true
        });
    }

    handleClose(){
        this.setState({
            ...this.state,
            open: false
        });
    }


    render() {
        const { classes, card_words, card_subwords, card, card_fun, card_category, type } = this.props;

        let color = "#2e75b5";
        let gradient = '#2e75b5';
        if ( card.card_category === 'green' ){
            color = '#51c3a1';
            gradient = '#51c3a1';
        }


        if ( type === 'minimal' ){
            //console.log('card', card);
            return (
                <div style={{width: '100%'}}>
                    <Dialog onClose={() => this.handleClose()} aria-labelledby="customized-dialog-title" open={this.state.open} >
                        <DialogTitle id="customized-dialog-title" onClose={() => this.handleClose()} style={{backgroundColor: color, color: "#fff"}}>
                        <span className={classes.inlineProfileScenario}>Trust Card #{card.id}</span> 
                        </DialogTitle>
                        <DialogContent dividers className={classes.noPad} style={{borderColor: color, background: gradient}}> 
                            <Grid container className={classes.alignVerticalCenter} style={{alignItems: 'stretch'}}>                        
                                <Grid item sm={12} xs={12} className={classes.padMe}>
                                    <Typography variant="body1">
                                        {card.card_words}
                                    </Typography>
                                </Grid>                           
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            { this.props.hasControl === true ? 
                            <Button size="small" color="secondary" onClick={(e) => this.props.clearSelected(e, card.id, this.props.column)}>
                                Clear from Column
                            </Button>
                            : null }
                            <Button size="small" color="primary" onClick={() => this.handleClose()}>
                                Done
                            </Button>                    
                        </DialogActions>
                    </Dialog>                 
                
                
                <Card onClick={() => this.handleOpen()}  variant="outlined" style={{borderColor: "#fff", borderRadius: "5px", background: color, width: '100%'}}>
                    <CardActionArea className={`${classes.alignVerticalCenter}`} style={{padding: "10px 5px"}}>
                        <Grid container className={classes.alignVerticalCenter}>
                            <Grid item xs={12} align="center" style={{color: '#fff'}}>
                               <Typography variant="body2">{card.card_words}</Typography>
                            </Grid>                        
                        </Grid>
                    </CardActionArea>
                </Card>
            </div>
        
            );
        } 
        
        return (
                    <Card className={`${classes.discCard}`} variant="outlined" style={{borderColor: color, background: gradient, width: '220px', minHeight: '95px'}}>
                        <CardActionArea className={`${classes.alignVerticalCenter}`} style={{padding: "10px 5px"}}>
                            <Grid container className={classes.alignVerticalCenter}>
                                <Grid item xs={12} className={classes.cardPadding} align="center">
                                    <Typography variant="body2" style={{fontSize: "1.25rem", fontWeight: "bold", color: "#fff"}}>
                                        {card_words}
                                    </Typography>                                
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
            
        );
    }
}



export default withStyles(styles)(TrustCard);



