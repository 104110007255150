import React from 'react'
import cube from './../images/cube.svg';
import cyl1 from './../images/cylinder.svg';
import cyl2 from './../images/cylinder2.svg';

const GamePiece = (props) => {
    let size = "75px";
    if ( props.size === 'icon' ){
        size = "20px"
    }
    switch (props.type){
        case 'team1': 
            return <img src={cyl1} style={{ height: size }}/>;
        case 'team2':
            return <img src={cyl2} style={{ height: size }}/>
        case 'wild':
            return <img src={cube} style={{ height: size }}/>
        default:
            return null;
    }
}

export default GamePiece;