import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import WalkProfileResult from './../specific_components/profileResult';
import { getUserGroup_hc } from '../../game_functions/functions';
import { ProfileList } from './../profiles/profileList';
import { PROFILE_VALUES } from '../profiles/profileList';
import emotionalIcon from '../images/scenario.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
import { PDFHeader } from './../../../../PastData/PDFHeader';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }
 

    render() {
        const {
            results,
            classes,
           isPDFVIew,
            profiles
        } = this.props;
        console.log('results?', results);

        if(this.props.isPDFView === true){
            return (
                <Document>
                     <Page wrap style={{padding: "30 0"}}>
                        <PDFHeader/>
                        <View>
                            <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={emotionalIcon }/>
                            {Object.keys(results.results)
                            .sort()
                            .map((groupKey, ind) => {
                            return (
                            <React.Fragment key={ind}>
                                <View style={{marginHorizontal: "50", marginBottom: "50"}}>
                                    <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>
                                    <Text style={{marginBottom: "10", fontSize: "18pt"}}>YOUR CHOICES:</Text> 
                                    <View>
                                    {ProfileList.map((p, indy) =>{
                                        return(
                                            <View key={indy} style={{ marginBottom: '5' ,borderRadius:"5px", border: "1px solid #000000", alignContent: "center", textAlign: 'center'}} wrap={false}>
                                                <Text style={{ fontSize:"12pt", fontWeight: "bold", marginVertical: "10"}}>SCENARIO {p.profile_step}</Text>
                                                <Image style={{marginHorizontal: "220", borderRadius: "50%"}} src={require(`../images/avatar/${p.id}.png`)}/>
                                                <Text style={{ fontSize:"12pt"}}>{p.profile_title}</Text>
                                                <View style={{marginHorizontal: "7", marginVertical: "5", borderRadius:"15px", border: "1px solid #D3D3D3", textAlign: 'left', flexDirection: "row"}}>
                                                {
                                            
                                            results.results[groupKey].hasOwnProperty(`step_${p.profile_step}`) ?
                                                results.results[groupKey][`step_${p.profile_step}`].choice !== false && results.results[groupKey][`step_${p.profile_step}`].choice !== undefined ?
                                                <React.Fragment>
                                                    <Text style={{marginVertical: "20", marginLeft: "10" ,fontSize:"12pt"}}>You chose </Text>
                                                    <Text style={{marginVertical: "20" ,fontSize: "12pt" ,fontWeight: "bold"}}>{ PROFILE_VALUES[results.results[groupKey][`step_${p.profile_step}`].choice].title }.</Text>
                                                </React.Fragment>
                                                : <Text style={{ marginVertical: "20", marginLeft: "10" ,fontSize:"12pt"}} >You didn't make a choice for this scenario.</Text>
                                            : <Text style={{ marginVertical: "20", marginLeft: "10" ,fontSize:"12pt"}}>You didn't make a choice for this scenario.</Text>
                                            
                                        }
                                                </View>

                                            </View>
                                        )
                                    })}                                   
                                   </View>
                                </View> 
                            </React.Fragment>       
                            );
                        })}
                        </View>
                    </Page>
                </Document> 
            ) 
        }


       return (
           <div>
            <Typography variant="h3">{results.name}</Typography>
            <Typography variant='h5' className={classes.spaceBottom}>
                Debrief
            </Typography>
            {Object.keys(results.groups).sort().map((groupKey, ind) => {
                console.log(results.results[groupKey]);
                if ( groupKey === 'group-0'){
                    return null;
                }
                return (
                <Grid container key={ind}>
                    <Grid item xs={12} sm={12} className={classes.spacingTop}>
                    <Typography variant="h3" className={classes.spacingTop}>{results.groups[groupKey].title}</Typography>
                
                    
                    <Typography variant="h4" className={classes.spacingBottom}>Your Choices:</Typography>
                        { ProfileList.map((p, indy) => {         
                            return(
                            <Grid container spacing={3} key={indy} className={`${classes.spacingBottom} ${classes.walkResult}`}>
                                <Grid item xs={12}>
                                    <Typography variant='h5' className={`${classes.spaceBottom} ${classes.profileTypeDescriptor} ${classes.minorMargin}`} align="center">Scenario {p.profile_step}<br/><span className={classes.profileScenario}></span></Typography>  
                                    <div>
                                        <WalkProfileResult                       
                                        step={p.profile_step}
                                        session={results}
                                        
                                        />
                                    </div>
                                    <List className={classes.listClass}>
                                        {
                                            
                                            results.results[groupKey].hasOwnProperty(`step_${p.profile_step}`) ?
                                                results.results[groupKey][`step_${p.profile_step}`].choice !== false && results.results[groupKey][`step_${p.profile_step}`].choice !== undefined ?
                                                    <div className={classes.padMe}><Typography variant="body1">You chose <span className={classes.boldedText}>{ PROFILE_VALUES[results.results[groupKey][`step_${p.profile_step}`].choice].title }</span>.</Typography></div>
                                                : <Typography variant="body1" className={classes.padMe}>You didn't make a choice for this scenario.</Typography>
                                            : <Typography variant="body1" className={classes.padMe}>You didn't make a choice for this scenario.</Typography>
                                        }
                                    </List>
                                </Grid>
                            </Grid>)
                        })}

                    </Grid>
                </Grid>
                )
            })}
         </div>
    )
        
       
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
