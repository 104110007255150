export const wordGroups = {
    'Easy' : [
        {
            'words': ['hand', 'left', 'stand', 'far', 'field', 'by'],
            'correct_order' : ['stand', 'by', 'far', 'left', 'field', 'hand'],
            'associations' : {
                'stand' : {
                    'before' : 'hand',
                    'after' : 'by'
                },
                'by' : {
                    'before' : 'stand',
                    'after' : 'far'
                },
                'far' : {
                    'before' : 'by',
                    'after' : 'left'
                },
                'left' : {
                    'before' : 'far',
                    'after' : 'field'
                },
                'field' : {
                    'before' : 'left',
                    'after' : 'hand'
                },
                'hand' : {
                    'before' : 'field',
                    'after' : 'stand'
                }
            }
        },
        {
            'words': ['seat', 'up', 'pace', 'belt', 'car', 'keep', 'buckle' ],
            'correct_order' : ['car', 'seat', 'belt', 'buckle', 'up', 'keep', 'pace'],
            'associations' : {
                'car' : {
                    'before' : 'pace',
                    'after' : 'seat'
                },
                'seat' : {
                    'before' : 'car',
                    'after' : 'belt'
                },
                'belt' : {
                    'before' : 'seat',
                    'after' : 'buckle'
                },
                'buckle' : {
                    'before' : 'belt',
                    'after' : 'up'
                },
                'up' : {
                    'before' : 'buckle',
                    'after' : 'keep'
                },
                'keep' : {
                    'before' : 'up',
                    'after' : 'pace'
                },
                'pace' : {
                    'before' : 'keep',
                    'after' : 'car'
                }
            }
        },
        {
            'words': ['up', 'back', 'side', 'hill', 'brace', 'horse', 'long' ],
            'correct_order' : ['back', 'brace', 'up', 'hill', 'side', 'long', 'horse'],
            'associations' : {
                'back' : {
                    'before' : 'horse',
                    'after' : 'brace'
                },
                'brace' : {
                    'before' : 'back',
                    'after' : 'up'
                },
                'up' : {
                    'before' : 'brace',
                    'after' : 'hill'
                },
                'hill' : {
                    'before' : 'up',
                    'after' : 'side'
                },
                'side' : {
                    'before' : 'hill',
                    'after' : 'long'
                },
                'long' : {
                    'before' : 'side',
                    'after' : 'horse'
                }, 
                'horse' : {
                    'before' : 'long',
                    'after' : 'back'
                }
            }
        }
    ],
    'Medium' : [        
        {
            'words': ['log', 'back', 'end', 'talk', 'line', 'book', 'safety', 'board' ],
            'correct_order' : ['back', 'log', 'book', 'end', 'board', 'line', 'safety', 'talk'],
            'associations' : {
                'back' : {
                    'before' : 'talk',
                    'after' : 'log'
                },
                'log' : {
                    'before' : 'back',
                    'after' : 'book'
                },
                'book' : {
                    'before' : 'log',
                    'after' : 'end'
                },
                'end' : {
                    'before' : 'book',
                    'after' : 'board'
                },
                'board' : {
                    'before' : 'end',
                    'after' : 'line'
                },
                'line' : {
                    'before' : 'board',
                    'after' : 'safety'
                }, 
                'safety' : {
                    'before' : 'line',
                    'after' : 'talk'
                }, 
                'talk' : {
                    'before' : 'safety',
                    'after' : 'back'
                }
            }
        },
        {
            'words': ['yard', 'week', 'front', 'work', 'end', 'note', 'store', 'book' ],
            'correct_order' : ['yard', 'work', 'week', 'end', 'note', 'book', 'store', 'front'],
            'associations' : {
                'yard' : {
                    'before' : 'front',
                    'after' : 'work'
                },
                'work' : {
                    'before' : 'yard',
                    'after' : 'week'
                },
                'week' : {
                    'before' : 'work',
                    'after' : 'end'
                },
                'end' : {
                    'before' : 'week',
                    'after' : 'note'
                },
                'note' : {
                    'before' : 'end',
                    'after' : 'book'
                },
                'book' : {
                    'before' : 'note',
                    'after' : 'store'
                }, 
                'store' : {
                    'before' : 'book',
                    'after' : 'front'
                }, 
                'front' : {
                    'before' : 'store',
                    'after' : 'yard'
                }
            }
        },
        {
            'words': ['box', 'tower', 'head', 'car', 'bell', 'block', 'lock', 'door' ],
            'correct_order' : ['block', 'head', 'lock', 'box', 'car', 'door', 'bell', 'tower'],
            'associations' : {
                'block' : {
                    'before' : 'tower',
                    'after' : 'head'
                },
                'head' : {
                    'before' : 'block',
                    'after' : 'lock'
                },
                'lock' : {
                    'before' : 'head',
                    'after' : 'box'
                },
                'box' : {
                    'before' : 'lock',
                    'after' : 'car'
                },
                'car' : {
                    'before' : 'box',
                    'after' : 'door'
                },
                'door' : {
                    'before' : 'car',
                    'after' : 'bell'
                }, 
                'bell' : {
                    'before' : 'door',
                    'after' : 'tower'
                }, 
                'tower' : {
                    'before' : 'bell',
                    'after' : 'block'
                }
            }
        },
        {
            'words': ['water', 'work', 'mark', 'salt', 'end', 'pipe', 'up', 'bench', 'table' ],
            'correct_order' : ['salt', 'water', 'pipe', 'work', 'bench', 'mark', 'up', 'end', 'table'],
            'associations' : {
                'salt' : {
                    'before' : 'table',
                    'after' : 'water'
                },
                'water' : {
                    'before' : 'salt',
                    'after' : 'pipe'
                },
                'pipe' : {
                    'before' : 'water',
                    'after' : 'work'
                },
                'work' : {
                    'before' : 'pipe',
                    'after' : 'bench'
                },
                'bench' : {
                    'before' : 'work',
                    'after' : 'mark'
                },
                'mark' : {
                    'before' : 'bench',
                    'after' : 'up'
                }, 
                'up' : {
                    'before' : 'mark',
                    'after' : 'end'
                }, 
                'end' : {
                    'before' : 'up',
                    'after' : 'table'
                }, 
                'table' : {
                    'before' : 'end',
                    'after' : 'salt'
                }
            }
        },
        {
            'words': ['knife', 'wise', 'back', 'put', 'pocket', 'crack', 'beat', 'edge', 'shot', 'off' ],
            'correct_order' : ['pocket', 'knife', 'edge', 'wise', 'crack', 'shot', 'put', 'off', 'beat', 'back'],
            'associations' : {
                'pocket' : {
                    'before' : 'back',
                    'after' : 'knife'
                },
                'knife' : {
                    'before' : 'pocket',
                    'after' : 'edge'
                },
                'edge' : {
                    'before' : 'knife',
                    'after' : 'wise'
                },
                'wise' : {
                    'before' : 'edge',
                    'after' : 'crack'
                },
                'crack' : {
                    'before' : 'wise',
                    'after' : 'shot'
                },
                'shot' : {
                    'before' : 'crack',
                    'after' : 'put'
                }, 
                'put' : {
                    'before' : 'shot',
                    'after' : 'off'
                }, 
                'off' : {
                    'before' : 'put',
                    'after' : 'beat'
                }, 
                'beat' : {
                    'before' : 'off',
                    'after' : 'back'
                }, 
                'back' : {
                    'before' : 'beat',
                    'after' : 'pocket'
                }
            }
        }
    ],
    'Hard' : [
        {
            'words': ['pull', 'file', 'white', 'toe', 'tip', 'nail', 'drawer', 'off', 'hot'],
            'correct_order' : ['tip', 'toe', 'nail', 'file', 'drawer', 'pull', 'off', 'white', 'hot'],
            'associations' : {
                'tip' : {
                    'before' : 'hot',
                    'after' : 'toe'
                },
                'toe' : {
                    'before' : 'tip',
                    'after' : 'nail'
                },
                'nail' : {
                    'before' : 'toe',
                    'after' : 'file'
                },
                'file' : {
                    'before' : 'nail',
                    'after' : 'drawer'
                },
                'drawer' : {
                    'before' : 'file',
                    'after' : 'pull'
                },
                'pull' : {
                    'before' : 'drawer',
                    'after' : 'off'
                }, 
                'off' : {
                    'before' : 'pull',
                    'after' : 'white'
                }, 
                'white' : {
                    'before' : 'off',
                    'after' : 'hot'
                }, 
                'hot' : {
                    'before' : 'white',
                    'after' : 'tip'
                }
            }
        },
        {
            'words': ['seat', 'buckle', 'play', 'hand', 'pass', 'tool', 'belt', 'under', 'word', 'box', 'free' ],
            'correct_order' : ['free', 'hand', 'tool', 'box', 'seat', 'belt', 'buckle', 'under', 'pass', 'word', 'play'],
            'associations' : {
                'free' : {
                    'before' : 'play',
                    'after' : 'hand'
                },
                'hand' : {
                    'before' : 'free',
                    'after' : 'tool'
                },
                'tool' : {
                    'before' : 'hand',
                    'after' : 'box'
                },
                'box' : {
                    'before' : 'tool',
                    'after' : 'seat'
                },
                'seat' : {
                    'before' : 'box',
                    'after' : 'belt'
                },
                'belt' : {
                    'before' : 'seat',
                    'after' : 'buckle'
                }, 
                'buckle' : {
                    'before' : 'belt',
                    'after' : 'under'
                }, 
                'under' : {
                    'before' : 'buckle',
                    'after' : 'pass'
                }, 
                'pass' : {
                    'before' : 'under',
                    'after' : 'word'
                }, 
                'word' : {
                    'before' : 'pass',
                    'after' : 'play'
                }, 
                'play' : {
                    'before' : 'word',
                    'after' : 'free'
                }
            }
        },
        {
            'words': ['rolling', 'shop', 'back', 'eye', 'log', 'stone', 'contact', 'talk', 'print', 'wall' ],
            'correct_order' : ['log', 'rolling', 'stone', 'wall', 'eye', 'contact', 'print', 'shop', 'talk', 'back'],
            'associations' : {
                'log' : {
                    'before' : 'back',
                    'after' : 'rolling'
                },
                'rolling' : {
                    'before' : 'log',
                    'after' : 'stone'
                },
                'stone' : {
                    'before' : 'rolling',
                    'after' : 'wall'
                },
                'wall' : {
                    'before' : 'stone',
                    'after' : 'eye'
                },
                'eye' : {
                    'before' : 'wall',
                    'after' : 'contact'
                },
                'contact' : {
                    'before' : 'eye',
                    'after' : 'print'
                }, 
                'print' : {
                    'before' : 'contact',
                    'after' : 'shop'
                }, 
                'shop' : {
                    'before' : 'print',
                    'after' : 'talk'
                }, 
                'talk' : {
                    'before' : 'shop',
                    'after' : 'back'
                }, 
                'back' : {
                    'before' : 'talk',
                    'after' : 'log'
                }
            }
        },
        {
            'words': ['cat', 'side', 'day', 'house', 'game', 'kick', 'walk', 'ball', 'school', 'way' ],
            'correct_order' : ['side', 'kick', 'ball', 'game', 'day', 'school', 'house', 'cat', 'walk', 'way'],
            'associations' : {
                'side' : {
                    'before' : 'way',
                    'after' : 'kick'
                },
                'kick' : {
                    'before' : 'side',
                    'after' : 'ball'
                },
                'ball' : {
                    'before' : 'kick',
                    'after' : 'game'
                },
                'game' : {
                    'before' : 'ball',
                    'after' : 'day'
                },
                'day' : {
                    'before' : 'game',
                    'after' : 'school'
                },
                'school' : {
                    'before' : 'day',
                    'after' : 'house'
                }, 
                'house' : {
                    'before' : 'school',
                    'after' : 'cat'
                }, 
                'cat' : {
                    'before' : 'house',
                    'after' : 'walk'
                }, 
                'walk' : {
                    'before' : 'cat',
                    'after' : 'way'
                }, 
                'way' : {
                    'before' : 'walk',
                    'after' : 'side'
                }
            }
        },
        {
            'words': ['score', 'diving', 'work', 'game', 'ball', 'card', 'sky', 'base', 'night', 'day', 'shift', 'off', 'gear'],
            'correct_order' : ['ball', 'game', 'score', 'card', 'night', 'sky', 'diving', 'gear', 'shift', 'work', 'day', 'off', 'base'],
            'associations' : {
                'ball' : {
                    'before' : 'base',
                    'after' : 'game'
                },
                'game' : {
                    'before' : 'ball',
                    'after' : 'score'
                },
                'score' : {
                    'before' : 'game',
                    'after' : 'card'
                },
                'card' : {
                    'before' : 'score',
                    'after' : 'night'
                },
                'night' : {
                    'before' : 'card',
                    'after' : 'sky'
                },
                'sky' : {
                    'before' : 'night',
                    'after' : 'diving'
                }, 
                'diving' : {
                    'before' : 'sky',
                    'after' : 'gear'
                }, 
                'gear' : {
                    'before' : 'diving',
                    'after' : 'shift'
                }, 
                'shift' : {
                    'before' : 'gear',
                    'after' : 'work'
                }, 
                'work' : {
                    'before' : 'shift',
                    'after' : 'day'
                }, 
                'day' : {
                    'before' : 'work',
                    'after' : 'off'
                }, 
                'off' : {
                    'before' : 'day',
                    'after' : 'base'
                }, 
                'base' : {
                    'before' : 'off',
                    'after' : 'ball'
                }
            }
        }
    ]
}