
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import AggregateLogo from '../images/on_deck.png'
import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions'
import { updateGroupState, editResultsPath } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { CARDS_LIST } from './../data/data';


const shuffleObject = (obj) => {
    // new obj to return
    let newObj = {};
    // create keys array
    var keys = Object.keys(obj);
    // randomize keys array
    console.log('a', keys);

    keys.sort(function(a,b){return Math.random()- 0.5;});

    console.log('b', keys);
    // save in new array
    keys.forEach(function(k) {
        console.log('waht', k);
        newObj[`${k}`] = obj[k];
    });
    console.log(newObj);
    return newObj;
}

class Instructions extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    sendToNextStep = (event) => {
    let groupID =  getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);   

    if ( this.props.session.active_game.results[groupID] === undefined ){     
        let unshuffled = { ... CARDS_LIST };
        let shuffled = shuffleObject(unshuffled);

        let path = `active_game.results.${this.props.group_id}.cardHand`;

        this.props.editResultsPath(path, shuffled, determineHost(this.props.profile, this.props.auth));   

        let p = `active_game.results.${this.props.group_id}.startedOn`;
        let startedOn = (this.props.session.active_game.groupStates[groupID].params.startedOn === undefined || this.props.session.active_game.groupStates[groupID].params.startedOn === null) ? Date.now() : this.props.session.active_game.groupStates[groupID].params.startedOn;
        
        this.props.editResultsPath(p, startedOn, determineHost(this.props.profile, this.props.auth));  
    }



    let s = { 
            'state' : GROUP_STATE_ACTIVE,
            'params' : {...this.props.session.active_game.groupStates[groupID].params}
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth)); 
    }

    render(){
        const { session, profile, auth, classes, leader } = this.props;

        return (
            <div>
               <Typography variant='h3' className={classes.spaceBottom}>
                    {session.active_game.name}
                </Typography>
               <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img src={AggregateLogo} className={classes.imageStyle} alt="Game"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>  
                        <Typography variant="body1" className={classes.spacingBottom}>Let's look at how we organize ourselves through an experience. <br/><br/>This activity is Sudoku-like. Slide the cards into different positions on a 4x4 grid until no row or column has the same suit or the same number/rank.</Typography>
                        <Typography variant="body2" className={classes.spacingBottom}>Source: Adapted from an activity in the book, Playing with a Full Deck, 52 Team Activities Using a Deck of Playing Cards, by Michelle Cummings.</Typography>
                        { hasGameControl(leader, auth.uid, profile)
                            ?
                            <Button type='submit' color="primary" variant='contained' onClick={this.sendToNextStep}>
                                Continue
                            </Button>
                            :
                            <Button type='submit' disabled={true} variant='contained'>
                                Leader can hit Continue
                            </Button>
                        }
                    </Grid>
                </Grid>  
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState : (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID)),
    };
  };
  
  export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(Instructions);