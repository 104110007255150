import React, { Component } from 'react';


import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { GROUP_STATE_RESULTS } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AggregateBackground from './../images/AggregateBackground.png';
import { hasGameControl, determineHost } from '../../game_functions/functions';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults, editResultsPath, removeResultsPath, updateGroupStateStateVal, updateGroupStateParamsVal } from './../../../../../store/actions/gameActions';
import AppBar from '@material-ui/core/AppBar';
import { DragDropContext } from 'react-beautiful-dnd';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import DropArea from './../specific_components/dropArea';
import GridOnIcon from '@material-ui/icons/GridOn';
import Box from '@material-ui/core/Box';
import svgCards from './../data/svg-cards.svg';
import GridArea from './../specific_components/gridArea';
import { CARDS_LIST } from './../data/data';
import Card from './../specific_components/card';
import OnDeckCardHand from './../specific_components/cardHand';
import { DragDropContain } from './../specific_components/dragDropContain';


const INITIAL_STATE = {
  noItems: true,
  plusItemsExist: false,
  deltaItemsExist: false,
  open: false,
  priorities: {},
  curColumn: null,
  dropDisabled: false,
  drawerOpen: false,
  width: window.innerWidth,
  height: window.innerHeight,
  wasOpen: false,
  tab: 0,
  filter: 'all',
  isEdit: false,
  showList: false,
  won: false,
  drawer: [],
  grid: {}
};



class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    this.updateGridResults = this.updateGridResults.bind(this);
    //this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ 
      ...this.state, 
      width: window.innerWidth, 
      height: window.innerHeight 
    });
  }

  calculateWin() {
    
  }

  
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.add('full-width');
    //let won = this.calculateWin();
    
    //window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateGridResults(val) {
    let gridPath = `active_game.results.${this.props.group_id}`;
    this.props.editResultsPath(gridPath, val, determineHost(this.props.profile, this.props.auth));
  }

  completeActivity() {
    document.body.classList.remove('full-width');
  }

  componentWillUnmount() {
    document.body.classList.remove('full-width');
    //window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps, prevState) { 
      /* Something has moved or been created. Update UI */
      /*if ( this.props.session.active_game.results[this.props.group_id] !== prevProps.session.active_game.results[this.props.group_id] ){
          if ( this.state.priorities !== this.props.session.active_game.results[this.props.group_id] ){
            let won = this.calculateWin();
  
            this.setState({
              ...this.state,
              drawer: (this.props.session.active_game.results[this.props.group_id] === undefined ? {} : this.props.session.active_game.results[this.props.group_id].grid.drawer ),
              grid: (this.props.session.active_game.results[this.props.group_id] === undefined ? {} : this.props.session.active_game.results[this.props.group_id].grid.condo === undefined ? {} : this.props.session.active_game.results[this.props.group_id].grid.condo ),
              won: won
            });
          }
      }

      if ( prevState.won === false && this.state.won === true ){
        // FINISH UP
        if ( this.props.auth.uid === this.props.session.active_game.groups[this.props.group_id].leadPlayer ){
            this.props.updateGroupStateStateVal(this.props.group_id, GROUP_STATE_RESULTS, determineHost(this.props.profile, this.props.auth));
        }
      }*/
  }

  finishActivity = () => {
    let groupID = this.props.group_id;
      
    let now = Date.now();
    this.props.updateGroupStateParamsVal(groupID, 'finishedOn', now, determineHost(this.props.profile, this.props.auth)); 
    let timeelapsed = now - this.props.session.active_game.results[groupID].startedOn;
    let secondsElapsed = Math.floor(timeelapsed / 1000);

    //this.props.updateResultsParamsVal(groupID, 'finishedIn', secondsElapsed, determineHost(this.props.profile, this.props.auth)); 
    /* TODO: Update group state, but ensure it doesn't touch the params */
    this.props.updateGroupStateStateVal(groupID, GROUP_STATE_RESULTS, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });    
  };

  handleHelpClose = () => {
    this.setState({
      ...this.state,
      helpOpen: false
    });
  }

  openHelp = () => {
    this.setState({
      ...this.state,
      helpOpen: true
    })
  }

  onWin = () => {
    this.finishActivity();
  }

  render() {
    const {
      session,
      profile,
      auth,
      group_id,
      classes,
      //leader
    } = this.props;
    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);


    return (
      <div>

            <div className="container secondary">
              <Typography variant='h3' className={classes.spacingBottom} style={{display: "inline-block"}}>
                  {session.active_game.name}
              </Typography>
           
              <div style={{clear: "both"}} className={`${classes.spacingBottom}`}></div>
            </div>
              <DragDropContain hasControl={hasControl} set={session.active_game.Puzzle === undefined ? 1 : session.active_game.Puzzle} results={session.active_game.results[group_id] === undefined ? {} : session.active_game.results[group_id]} host={determineHost(this.props.profile, this.props.auth)} updateGridResults={this.updateGridResults} onWin={this.onWin}/>
            
              
      </div>
    );
  }
}


/*
  Cards: 
    - Negative margin to display side by side
    - Padding Top, then remove padding on hover

    - On hover over drop zone, remove what's in zone, and if something was there, display exchange icon. If no, display drop icon.
    SWAP: import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
    DROP: import InputIcon from '@material-ui/icons/Input';


*/


const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateGroupStateParamsVal: (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID)),
    //updateResultsParamsVal: (group, val, groupStateParams, hostID) => dispatch( updateResultsParamsVal(group, val, groupStateParams, hostID) ),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    removeResultsPath: (group, value, hostID) => dispatch(removeResultsPath(group, value, hostID))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);