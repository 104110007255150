import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ViewListIcon from '@material-ui/icons/ViewList';
import ei_board from './../images/ei_board.png';
import ei_step from './../images/ei_step.png';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const INITIAL_STATE = {
    open: false,
    legendOpen: false
};

class HostInstructions extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    handleLegendClickOpen = () => {
        this.setState({
            ...this.state,
            legendOpen: true
        });
    };
    
    handleLegendClose = () => {
        this.setState({
            ...this.state,
            legendOpen: false
        });
    };

    render() {
        const {
        classes,
        } = this.props;

        return (
        <div>
            <Typography variant='h5' className={classes.greyText}>
                Host Tools
            </Typography>
            <div className={classes.spacingTop}>
                <div><Button 
                        variant="contained"
                        color="primary"
                        startIcon={<SpeakerNotesIcon />}
                        onClick={this.handleClickOpen}
                        >
                    Leader Notes
                    </Button>
                    <div className={classes.spacingTop}>
                        <Button 
                            variant="contained"
                            color="primary"
                            startIcon={<ViewListIcon />}
                            onClick={this.handleLegendClickOpen}
                            >
                        State Legend
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                        <Typography variant="h6" className={classes.boldedText}>
                        Leader Notes
                        </Typography>                    
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} className={classes.dialogueGrid}>
                    <Grid item xs={12}  className={classes.spacingTop}>
                    <Typography variant="body1" className={classes.boldedText}>Our Two Minds</Typography>
                        <Typography variant="body1">Our two minds - always competing.</Typography>

                        <div className={classes.bodyText}>
                            <br/>
                            <div className={classes.boldedText}>Rational</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>Weighing the costs and the benefits</li>
                                <li className={classes.properListItem}>Organizing our behaviours to suit us</li>
                            </ul>
                            <br/>
                            <div className={classes.boldedText}>Emotional</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>Impulsive: letting emotions dominate our decision making</li>
                                <li className={classes.properListItem}>Reactive - in the moment</li>
                            </ul>
                            
                            <div className={classes.boldedText}>Voices overcrowd each other, and though we think about it, we typically act on our immediate emotional state.</div>
                            <br/>

                            <div className={classes.boldedText}>Books began being published in the mid 90’s and since then young students have been introduced to emotional intelligence
studies have been done on this group and this is what they found:</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>Disruptive behaviour  (anti social behaviour) down 10%</li>
                                <li className={classes.properListItem}>Pro social behavior – linking school – up 10%</li>
                                <li className={classes.properListItem}>Academic achievement - up  11%</li>
                            </ul>
                            <br/>

                            <div className={classes.boldedText}>Intelligence consists of:</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>IQ</li>
                                <li className={classes.properListItem}>EQ</li>
                                <li className={classes.properListItem}>Personality</li>
                            </ul>

                            <br/>

                            <div className={classes.boldedText}>EI Model:</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>Self Awareness - moral compass</li>
                                <li className={classes.properListItem}>Self-Management - handle your distressing emotions in an effective way not crippling; every emotion has a function</li>
                                <li className={classes.properListItem}>Empathy - know what someone else is feeling</li>
                                <li className={classes.properListItem}>Relationship Manangment (or social skills) - bringing it all together</li>
                            </ul>
                            <br/>
                            <div className={classes.boldedText}>Activity:</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>Identifying the Core Skills (done well/not done well)</li>
                                <li className={classes.properListItem}>Review the profiles</li>
                                <li className={classes.properListItem}>What core skill – reflected well and not well in each card</li>
                            </ul>
                            <br/>
                            <div className={classes.boldedText}>Answers:</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>Joanna - Self-Awareness</li>
                                <li className={classes.properListItem}>Roger - Lack of Self-Awareness</li>
                                <li className={classes.properListItem}>Miriam - Social-Awareness</li>
                                <li className={classes.properListItem}>Marco - Lack of Social-Awareness</li>
                                <li className={classes.properListItem}>Rue - Self-Management</li>
                                <li className={classes.properListItem}>Steve - Lack of Self-Management</li>
                                <li className={classes.properListItem}>Jacob - Relationship Management</li>
                                <li className={classes.properListItem}>Nora - Lack of Relationship Management</li>
                            </ul>
                            <br/>
                            <div className={classes.boldedText}>Debrief:</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>How can knowing this help us be a better leader?</li>
                                <li className={classes.properListItem}>Take a minute and jot down in your binder what you want to remember from this activity.</li>
                            </ul>
                        </div>                     
                    </Grid>
                </Grid>
                               
            </Dialog>
            <Dialog  open={this.state.legendOpen} onClose={this.handleLegendClose} TransitionComponent={Transition}>
                <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={this.handleLegendClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                        <Typography variant="h6" className={classes.boldedText}>
                        State Legend
                        </Typography>                    
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} className={classes.dialogueGrid}>
                    <Grid item xs={12}  className={classes.spacingTop}>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Board</Typography>
                        <div>
                            <img src={ei_board} alt='game board' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Step</Typography>
                        <div>
                            <img src={ei_step} alt='game choices' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                        <Typography variant="body1">Step # corresponds to the scenario.</Typography>
                    </Grid>
                </Grid>                   
            </Dialog>
        </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(HostInstructions);