import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { ProfileListHTML } from '../profiles/profileList';
import parse from 'html-react-parser';
import styles from '../../../../Styles';


const INITIAL_STATE = {
    open: false
}

class WalkProfileResult extends Component {
    constructor(props){
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    } 

    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        }); 
    }

    openProfile = () => {
        this.setState({
            ...this.state,
            open: true
        });        
    }  

    getCorrespondingProfiles = (step, ProfileList) => {
        let e = ProfileList.find(obj => {
          return (obj.profile_step === step)
        });
        return {
          profile : e,     
        };
      }

  endSpeech = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.state.speechBot.cancel();      
  }

  render() {
      
    const { classes, step } = this.props;
    const { open } = this.state;

    const walkProfile = this.getCorrespondingProfiles(step, ProfileListHTML).profile;  

    if ( walkProfile === undefined ){
        return null;
    }

    return (
        <div>
            <Dialog aria-labelledby="customized-dialog-title" open={open} onClose={this.handleClose}  maxWidth='md'>
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose} >
                   <span className={classes.inlineProfileScenario}>{walkProfile.profile_title} </span> 
                </DialogTitle>
                <DialogContent dividers className={classes.noPad}>  
                    <Grid container className={classes.alignVerticalCenter} style={{alignItems: 'stretch'}}>
                        <Grid item sm={4} xs={12} align="center" style={{backgroundImage: `url(${require(`./../images/${walkProfile.id}.png`)})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundColor: '#ccc', minHeight: '200px', backgroundPosition: 'center'}}>  
                        </Grid>
                        <Grid item sm={8} xs={12} className={classes.padMe}>
                            <Typography variant="body1" className={classes.boldedText}>What people who work with her say:</Typography>
                            <Typography variant="body1" className={classes.spacingTop}>
                                {parse(walkProfile.profile_text)}
                            </Typography>
                        </Grid>                           
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size="small" color="primary" onClick={this.handleClose}>
                        Done
                    </Button>     
                </DialogActions>
            </Dialog>                        
            <Grid container className={classes.alignVerticalCenter}>
                <Grid item xs={12} className={classes.cardPadding} align="center">
                    <div className={classes.relativeIconParent}>
                        <Avatar alt="Remy Sharp" src={require(`./../images/avatar/${walkProfile.id}.png`)}  onClick={() => this.openProfile()} className={classes.profileAvatar} style={{cursor: 'pointer'}}/>
                    </div>
                    <Typography variant="body1" className={`${classes.profileTypeDescriptor} ${classes.minorMargin}`}>
                        {walkProfile.profile_title}
                    </Typography>                                
                </Grid>
            </Grid>
        </div>
    );   
  }
}

const mapStateToProps = (state) => {
        return {}
    };
    
    const mapDispatchToProps = (dispatch) => {
        return {           
        };
    };

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(WalkProfileResult);



