import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button'; 
import { endSession } from '../../store/actions/sessionActions';
import { updateHostState, endGame, updateModState } from '../../store/actions/gameActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { GAME_STATE_ACTIVE, GAME_STATE_RESULTS } from '../../store/actions/gameActions';
import styles from '../Styles';


const INITIAL_STATE = {
  open: false,
  dialogueOpen: false
};

class sessionControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  handleDrawerOpen = () => {
    this.setState({
      ...this.state,
      open: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  handleDialogueOpen = () => {
    this.setState({
      ...this.state,
      dialogueOpen: true,
    });
  };

  handleDialogueClose = () => {
    this.setState({
      ...this.state,
      dialogueOpen: false,
    });
  };

  handleBothClose = () => {
    this.setState({
      ...this.state,
      dialogueOpen: false,
      open: false
    });
  }

  endSession = (event) => {
    event.preventDefault();
    this.props.endSession();
    this.handleDrawerClose();
  };

  editGroups = () => {
    const hostState = { state: 'group_select', params: {} };
    if ( this.props.profile.role === 'host' ){
      this.props.updateHostState(hostState);
    }
    if ( this.props.profile.role === 'moderator' ){
      this.props.updateModState(this.props.auth.uid, hostState, this.props.profile.moderates);
    }
    this.handleDrawerClose();
  };

  goToDashboard = () => {
    const hostState = { state: 'main', params: {} };
    if ( this.props.profile.role === 'host' ){
      this.props.updateHostState(hostState);
    }
    if ( this.props.profile.role === 'moderator' ){
      this.props.updateModState(this.props.auth.uid, hostState, this.props.profile.moderates);
    }
    this.handleDrawerClose();
  };
  goToResults = () => {
    const hostState = { state: 'results', params: {} };
    if ( this.props.profile.role === 'host' ){
      this.props.updateHostState(hostState);
    }
    if ( this.props.profile.role === 'moderator' ){
      this.props.updateModState(this.props.auth.uid, hostState, this.props.profile.moderates);
    }
    this.handleDrawerClose();
  };

  gotoGroup = (groupKey) => {
    const hostState = { state: 'group', params: { group: groupKey } };
    if ( this.props.profile.role === 'host' ){
      this.props.updateHostState(hostState);
    }
    if ( this.props.profile.role === 'moderator' ){
      this.props.updateModState(this.props.auth.uid, hostState, this.props.profile.moderates);
    }
    this.handleDrawerClose();
  };

  endGame = () => {
    this.props.endGame();
    this.handleBothClose();
  };

  render() {
    const { open } = this.state;
    const {
      classes,
      profile,
      session,
    } = this.props;

    if (session.active_game === undefined || Object.keys(session.active_game).length === 0) {
      return profile.role === 'host' ? (
        <div>
          <IconButton color='inherit' aria-label='open drawer' edge='end' onClick={this.handleDrawerOpen} className={`${clsx(open && classes.hide)} ${classes.menuZ}`}>
            <MenuIcon className={classes.menuZ} />
          </IconButton>
          <Drawer
            className={`${classes.drawer} ${classes.menuZ}`}
            variant='persistent'
            anchor='left'
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>{' '}
              <span className={classes.playersTitle}>Controls</span>
            </div>
            <Divider />
            <List>
              {session !== undefined ? (
                <div>
                  <Divider />
                  <ListItem button onClick={this.endSession}>
                    <ListItemIcon>
                      <HighlightOffIcon />
                    </ListItemIcon>
                    <ListItemText primary='End Session' />
                  </ListItem>
                  <Divider />
                </div>
              ) : null}
            </List>
          </Drawer>
        </div>
      ) : null;
    } else {
      return ( profile.role === 'host'  || profile.role === 'moderator' ) ? (
        <div>
          <Dialog
              open={this.state.dialogueOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  This action will <span className={classes.boldedText}>end the current activity</span> and <span className={classes.boldedText}>take you back to the activity selection list.</span> Are you sure you want to do this?
              </DialogContentText>
              </DialogContent>
              <DialogActions>
              <Button onClick={this.handleDialogueClose} variant="contained" color="primary">
                  Cancel
              </Button>
              <Button onClick={this.endGame} variant="contained" color="primary" autoFocus>
                  Confirm
              </Button>
              </DialogActions>
          </Dialog>
          <IconButton color='inherit' aria-label='open drawer' edge='end' onClick={this.handleDrawerOpen} className={`${clsx(open && classes.hide)} ${classes.menuZ}`}>
            <MenuIcon className={classes.menuZ} />
          </IconButton>
          <Drawer
            className={`${classes.drawer} ${classes.menuZ}`}
            variant='persistent'
            anchor='left'
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>{' '}
              <span className={classes.playersTitle}>Controls</span>
            </div>
            <Divider />
            <List>
              {session !== undefined && session.players.length > 0 ? (
                <ListItem
                  button
                  onClick={() => {
                    this.editGroups();
                  }}
                >
                  <ListItemIcon>
                    <GroupIcon className={classes.lightPurple} />
                  </ListItemIcon>
                  <ListItemText primary='Edit Groups' />
                </ListItem>
              ) : null}
              {session !== undefined && session.active_game.gameState.state === GAME_STATE_ACTIVE ? (
                <ListItem
                  button
                  onClick={() => {
                    this.goToDashboard();
                  }}
                >
                  <ListItemIcon>
                    <DashboardIcon className={classes.medPurple} />
                  </ListItemIcon>
                  <ListItemText primary='Activity Dashboard' className={classes.boldedText}/>
                </ListItem>
              ) : null}
              {session !== undefined && session.active_game.gameState.state === GAME_STATE_RESULTS ? (
                <ListItem
                  button
                  onClick={() => {
                    this.goToResults();
                  }}
                >
                  <ListItemIcon>
                    <EqualizerIcon />
                  </ListItemIcon>
                  <ListItemText primary='Activity Results' />
                </ListItem>
              ) : null}
              {session !== undefined && session.active_game.gameState.state === GAME_STATE_ACTIVE && Object.keys(session.active_game.groups).length > 0 ? (
                <div>
                  <ListItem>
                    <ListItemText primary='View Groups' />
                  </ListItem>
                  <List>
                    {Object.keys(session.active_game.groups)
                      .sort()
                      .map((groupKey, index) => {
                        if (session.active_game.groups[groupKey].id !== 'group-0') {
                          return (
                            <ListItem
                              button
                              key={index}
                              onClick={() => {
                                this.gotoGroup(groupKey);
                              }}
                            >
                              <ListItemIcon>
                                <PeopleOutlineIcon />
                              </ListItemIcon>
                              <ListItemText primary={session.active_game.groups[groupKey].title} />
                            </ListItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </List>
                </div>
              ) : null}
            </List>
          </Drawer>
        </div>
      ) : null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    players_list: state.firestore.ordered.players,
    players_list_O: state.firestore.data.players,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    endSession: () => dispatch(endSession()),
    endGame: () => dispatch(endGame()),
    updateHostState: (hostState) => dispatch(updateHostState(hostState)),
    updateModState: (mod, hostState, host) => dispatch(updateModState(mod, hostState, host)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(sessionControls);
