import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  hasGameControl,
  getUserGroup_hc,
  determineHost
} from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults } from './../../../../../store/actions/gameActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Cards from '../specific_components/cards';
import DiscCardDraggable from '../specific_components/cardDraggable';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Hidden from '@material-ui/core/Hidden';
import { Droppable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import DoneIcon from '@material-ui/icons/Done';
import Slide from '@material-ui/core/Slide';
import CreateIcon from '@material-ui/icons/Create';
import TextEntry from './../specific_components/textEntry';


const INITIAL_STATE = {
  open: false,
  helpOpen: false,
  circle: null,
  activeCol: false,
  entry_text: '',
  isEdit: false,
  before_choices: [],
  during_choices: [],
  after_choices: []
}

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#ffffff',
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( this.props.session.active_game.results[group_id] !== undefined ){
      //if (this.props.session.active_game.results[group_id].before_choices !== undefined ){
        this.setState({
            ...this.state,
            before_choices: this.props.session.active_game.results[group_id].before_choices === undefined ? [] : this.props.session.active_game.results[group_id].before_choices,
            after_choices: this.props.session.active_game.results[group_id].after_choices === undefined ? [] : this.props.session.active_game.results[group_id].after_choices,
            during_choices: this.props.session.active_game.results[group_id].during_choices === undefined ? [] : this.props.session.active_game.results[group_id].during_choices
        });
      //}
      
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( (prevProps.session.active_game.results[group_id] !== this.props.session.active_game.results[group_id]) ){


          this.setState({
              ...this.state,
              before_choices: this.props.session.active_game.results[group_id].before_choices === undefined ? [] : this.props.session.active_game.results[group_id].before_choices,
              after_choices: this.props.session.active_game.results[group_id].after_choices === undefined ? [] : this.props.session.active_game.results[group_id].after_choices,
              during_choices: this.props.session.active_game.results[group_id].during_choices === undefined ? [] : this.props.session.active_game.results[group_id].during_choices
          });
        
    
      
    }
  }


  finishActivity = () => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_RESULTS,
      params: {},
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleHelpClose = () => {
    this.setState({
      ...this.state,
      helpOpen: false
    });
  }

  openHelp = () => {
    this.setState({
      ...this.state,
      helpOpen: true
    })
  }

  /*onDragStart = (event) => {
    console.log('starting, ', event.source.droppableId);
    this.setState({
      ...this.state,
      activeCol: event.source.droppableId
    });
  }*/

  

  

  onDragEnd(result) {
    const { source, destination } = result;
 
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let col = result.source.droppableId;
    if ( !col ){
      return;
    }

    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    console.log('rrr', result);
    if ( result.destination.droppableId === col ){

        let choices;
        
        if ( col === 'before_choices' ){
          choices = reorder(
            this.state.before_choices,
            result.source.index,
            result.destination.index
          );
          this.props.editResults(groupID, 'before_choices', choices, determineHost(this.props.profile, this.props.auth));
          this.setState({
            ...this.state,
            before_choices: choices
          });
        }

        if ( col === 'after_choices' ){
          choices = reorder(
            this.state.after_choices,
            result.source.index,
            result.destination.index
          );
          this.props.editResults(groupID, 'after_choices', choices, determineHost(this.props.profile, this.props.auth));
          this.setState({
            ...this.state,
            after_choices: choices
          });
        }

        if ( col === 'during_choices' ){
          choices = reorder(
            this.state.during_choices,
            result.source.index,
            result.destination.index
          );
          this.props.editResults(groupID, 'during_choices', choices, determineHost(this.props.profile, this.props.auth));
          this.setState({
            ...this.state,
            during_choices: choices
          });
        }

    } else {
      // different list
      const result = move(
          this.state[source.droppableId],
          this.state[destination.droppableId],          
          source,
          destination
      );

      //this.props.editResults(groupID, destination.droppableId, result[destination.droppableId], determineHost(this.props.profile, this.props.auth));
      //this.props.editResults(groupID, source.droppableId, result[source.droppableId], determineHost(this.props.profile, this.props.auth));
      let paths = ['active_game.results.' + groupID + '.' + destination.droppableId, 'active_game.results.' + groupID + '.' + source.droppableId];
      let vals =  [ result[destination.droppableId], result[source.droppableId] ];

      this.props.editResultsPaths(paths, vals, determineHost(this.props.profile, this.props.auth))
      
      this.setState({
        ...this.state,
        [destination.droppableId]: result[destination.droppableId],
        [source.droppableId]: result[source.droppableId]
      });
 
    }

    return;
  }

  render() {
    const {
      session,
      profile,
      auth,
      classes,
    } = this.props;
    const { helpOpen, 
      after_choices,
      before_choices,
      during_choices } = this.state;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const numResults = this.props.session.active_game.results[group_id] === undefined ? 0 : this.props.session.active_game.results[group_id].all_choices === undefined ? 0 : this.props.session.active_game.results[group_id].all_choices.length;
    const hasControl = hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile);


    console.log('choices: ', after_choices, before_choices, during_choices);
    const num_during = during_choices.map(a => a.id);
    const num_before = before_choices.map(a => a.id);
    const num_after = after_choices.map(a => a.id);
    const all_ids = num_during.concat(num_before, num_after);
    console.log('array?', all_ids);
    return (
      <div>
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Typography variant='h4' className={classes.spaceBottom}>{session.active_game.name}</Typography>   
        <Hidden smDown>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
                <Typography variant='h6'>Options</Typography>
                <Cards all_choices={all_ids} session={session} profile={profile} auth={auth} numResults={numResults}/> 
                
                <span style={{display: "inline-block", marginRight: 10}}>
                <Button 
                    variant="contained"
                    color="primary"
                    startIcon={<LiveHelpIcon />}
                    onClick={() => this.openHelp()}
                    
                    >
                What Should I Do?
                </Button>
                </span> 
                { all_ids.length >= 5 && hasControl ?
                <span style={{display: "inline-block"}}>                  
                  <Button type='submit' color="primary" variant='contained' onClick={this.finishActivity} startIcon={<DoneIcon/>}>
                    Finish Activity
                  </Button>
                </span>
                : null }
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item sm={4} xs={4}>
                  <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>Before Training</Typography> <TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"before_choices"} savePath={`active_game.results.${group_id}.before_choices`}/>
                  <Droppable droppableId={`before_choices`} direction='vertical'>
                      {(provided, snapshot) => (
                        <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                          
                          { this.state.before_choices.map((card_id, index) => (                                        
                              <DiscCardDraggable all_choices={all_ids} session={session} profile={profile} col={`before_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={hasControl}/>                          
                          ))
                          }
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                </Grid>
                <Grid item sm={4} xs={4}>
                <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>During Training</Typography> <TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"during_choices"} savePath={`active_game.results.${group_id}.during_choices`}/>
                <Droppable droppableId={`during_choices`} direction='vertical'>
                    {(provided, snapshot) => (
                      <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        
                        { this.state.during_choices.map((card_id, index) => (                                        
                            <DiscCardDraggable all_choices={all_ids} session={session} profile={profile} col={`during_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={hasControl}/>                          
                        ))
                        }
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </Grid>
                <Grid item sm={4} xs={4}>
                <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>After Training</Typography> <TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"after_choices"} savePath={`active_game.results.${group_id}.after_choices`}/>
                <Droppable droppableId={`after_choices`} direction='vertical'>
                    {(provided, snapshot) => (
                      <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        
                        { this.state.after_choices.map((card_id, index) => (                                        
                            <DiscCardDraggable all_choices={all_ids} session={session} profile={profile} col={`after_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={hasControl}/>                          
                        ))
                        }
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container spacing={3}>            
            <Grid item xs={12} sm={12}>
                <Typography variant='h6'>Options</Typography>
                <Cards all_choices={all_ids}  session={session} profile={profile} auth={auth} numResults={numResults}/> 
                
                <span  style={{display: "inline-block", marginRight: 10}}>
                <Button 
                    variant="contained"
                    color="primary"
                    startIcon={<LiveHelpIcon />}
                    onClick={() => this.openHelp()}
                    >
                What Should I Do?
                </Button>
                </span> 
                { all_ids.length >= 5 && hasControl ?
                <span  style={{display: "inline-block", marginRight: 5}}>
                  <Button type='submit' color="primary" variant='contained' onClick={this.finishActivity} startIcon={<DoneIcon/>}>
                    Finish Activity
                  </Button>
                </span>
                : null }
            </Grid>
            <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>Before Training</Typography> <TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"before_choices"} savePath={`active_game.results.${group_id}.before_choices`}/>
                  <Droppable droppableId={`before_choices`} direction='vertical'>
                      {(provided, snapshot) => (
                        <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                          
                          { this.state.before_choices.map((card_id, index) => (                                        
                              <DiscCardDraggable all_choices={all_ids} session={session} profile={profile} col={`before_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={hasControl}/>                          
                          ))
                          }
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>During Training</Typography> <TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"during_choices"} savePath={`active_game.results.${group_id}.during_choices`}/>
                <Droppable droppableId={`during_choices`} direction='vertical'>
                    {(provided, snapshot) => (
                      <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        
                        { this.state.during_choices.map((card_id, index) => (                                        
                            <DiscCardDraggable all_choices={all_ids} session={session} profile={profile} col={`during_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={hasControl}/>                          
                        ))
                        }
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>After Training</Typography> <TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"after_choices"} savePath={`active_game.results.${group_id}.after_choices`}/>
                <Droppable droppableId={`after_choices`} direction='vertical'>
                    {(provided, snapshot) => (
                      <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        
                        { this.state.after_choices.map((card_id, index) => (                                        
                            <DiscCardDraggable all_choices={all_ids} session={session} profile={profile} col={`after_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={hasControl}/>                          
                        ))
                        }
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </Grid>
              </Grid>
                
            </Grid>
          </Grid>
        </Hidden>

        {/*<TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"notes"} savePath={`active_game.results.${group_id}.notes`}/>*/}
        
      </DragDropContext>
      <Dialog
          open={helpOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleHelpClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
              <Typography variant="h4">What Should I Do?</Typography>
              <Typography variant='body1'>- With your team discuss the best practices and identify if they are a before, during or after training initiative</Typography>
              <Typography variant='body1' className={classes.spaceBottom}>- If you get stumped, click on “browse cards” to see a few ideas, if a card resonates with you, tap on it and add it to the corresponding column</Typography>
              <Typography variant="body1" className={classes.spacingTop}>As a leader, by tapping the pencil beside a column name, you can write a response, or if you get stuck, browse the cards and add in any that resonate with you. Three buttons will appear, allowing you to select which column to place the card into.</Typography>
              <Typography variant="body1" className={`${classes.spacingTop}`}>Additionally, the leader can sort the cards within the areas by dragging and dropping either within that column, or by moving it to another column. To remove a card, tap on it and hit the Remove button.</Typography>
              <Typography variant="body1" className={`${classes.spacingTop}`}>As a leader, by tapping the pencil beside a column name, you can enter your own custom card.</Typography>
              <Typography variant="body1" className={`${classes.spacingTop} ${classes.spacingBottom}`}>When you have captured all of your ideas, finish up via the Finish Activity button.</Typography> 
          </DialogContent>                  
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);
