import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
//New sign in method
import { connect } from 'react-redux';
import { signInAnon, anonProfileCreate, updateProfileName, endLoading } from '../../store/actions/authActions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Footer from '../Footer';
import LogoHeader from '../Header';
import LoadingBlock from '../Loading';
import styles from '../Styles';

const INITIAL_STATE = {
  name: '',
  setName: true,
  nameLoaded: null
};

class ChangeName extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount(){
   this.setState({
     ...this.state,
     namedLoaded: false
   })
  }

  componentDidUpdate(prevProps, prevState){
    if ( prevProps.user.isLoaded !== this.props.user.isLoaded && this.props.user.displayName !== undefined ){
        this.setState({
            ...this.state,
            name: this.props.user.displayName
        });
    } else if ( ( this.state.nameLoaded === false || this.state.nameLoaded === null ) && this.props.user.displayName !== undefined ){
      this.setState({
        ...this.state,
        nameLoaded: true,
        name: this.props.user.displayName
    });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.updateProfileName(this.state.name, this.props.user);
    this.setState({
        ...this.state,
        setName: false
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      name,
      setName
    } = this.state;

    const isInvalid = name === '';
    const { auth, t, classes } = this.props;

    if (auth.uid) {
      /* We don't want to be at this page if we're already signed in. 
        If signed in, that means you're already in as a host, or you're already connected to a game */
      
      /* TODO: We need to wait here and not allow them to enter their name unless their profile is loaded && they exist in the players list */

      if (!this.props.user.isEmpty) {
        if (setName === false && this.props.authMessage === 'PROFILE_UPDATED') {
            return <Redirect to={ROUTES.LANDING} />;
        }
        if ( this.props.user.displayName !== undefined ){
            return (
                <div className='meta-react'>
                    <LogoHeader t={t} />
                    <div className='outer-container'>
                    <div className='container'>
                        <div className='row'>
                        <div className='col-12'>
                            <form onSubmit={this.onSubmit}>
                            <Grid item container spacing={3} justify='center'>
                                <Grid item xs={12}>
                                    { this.props.user.role === 'host' ? 
                                    <Typography variant="h6" className={classes.spacingBottom} style={{fontWeight: "bold"}}>NOTE: YOU ARE CURRENTLY SIGNED IN AS A HOST.</Typography>
                                    : null}
                                    { this.props.user.role === 'moderator' ? 
                                    <Typography variant="h6" className={classes.spacingBottom} style={{fontWeight: "bold"}}>NOTE: YOU ARE CURRENTLY SIGNED IN AS A MODERATOR.</Typography>
                                    : null}
                                    <Typography variant="h5">Changing Your Display Name:</Typography>
                                    <Typography varaint="body1">Enter the name you wish you use for the session.</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField name='name' onChange={this.onChange} className={classes.textField} value={name} type='text' label={t('signIn.displayName')} />
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Button type='submit' disabled={isInvalid} variant='contained' onClick={this.onSubmit}>
                                        {t('signIn.anonButton')}
                                    </Button>
                                </Grid>
                            </Grid>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                    <Footer />
                </div>
                );
        } else {
            return (
            <div className='meta-react'>
                <LogoHeader t={t} />
                <div className='outer-container'>
                <div className='container'>
                    <div className='row'>
                    <div className='col-12'>
                        <form onSubmit={this.onSubmit}>
                        <Grid item container spacing={3} justify='center'>
                            <Grid item xs={12}>
                                <Typography variant="h3">Almost There!</Typography>
                                <Typography varaint="body1">Enter the name you wish you use for the session.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='name' onChange={this.onChange} className={classes.textField} value={name} type='text' label={t('signIn.displayName')} />
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Button type='submit' disabled={isInvalid} variant='contained' onClick={this.onSubmit}>
                                    {t('signIn.anonButton')}
                                </Button>
                            </Grid>
                        </Grid>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
                <Footer />
            </div>
            );
        }   
      } else {
            return null;
      }
    } else {
        return (
        <div className='meta-react'>
            <LogoHeader t={t} />
            <div className='outer-container'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    Joining up, hold on a moment...
                    <LoadingBlock />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>);
    }
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.gol_auth.authError,
    authMessage: state.gol_auth.authMessage,
    authPopUp: state.gol_auth.authPopUp,
    authErrorPopUp: state.gol_auth.authErrorPopUp,
    authCodeValid: state.gol_auth.authCodeValid,
    user: state.firebase.profile,
    sessionHost: state.gol_auth.sessionHost,
    authCode: state.gol_auth.authCode,
    isLoading: state.gol_auth.isLoading,
    setName: state.gol_auth.setName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signInAnon: (creds) => dispatch(signInAnon(creds)),
    anonProfileCreate: (authState) => dispatch(anonProfileCreate(authState)),
    updateProfileName: (creds, code) => dispatch(updateProfileName(creds, code)),
    endLoading: () => dispatch(endLoading())
  };
};

export default compose(withTranslation(), withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ChangeName);
