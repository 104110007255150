import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { deepPurple } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  glassPurple: {
    color: deepPurple[500],
  },
}));

export default function LoadingBlock() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item>
          <CircularProgress className={classes.glassPurple} />
        </Grid>
      </Grid>
    </div>
  );
}
