import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PlayersBase from '../Players';
import LogoAlt from '../../images/LogoAlt.png';
import SessionControls from '../Session/sessionControls';
import { deepPurple } from '@material-ui/core/colors';

const HeaderStyles = makeStyles((theme) => ({
  logo: {
    margin: '0 auto',
    display: 'block',
    maxWidth: "40vw",   
    maxHeight: "100px",
    textAlign: 'center',
  },
  title: {
    fontWeight: 300,
    marginLeft: 40,
    color: '#656366',
  },
  padBottom: {
    paddingBottom: "0"
  },
  logoHeader: {
    backgroundColor: '#fff',
    paddingTop: 10,
  },
  textRight: {
    paddingTop: 30,
    textAlign: 'right',
  },
  purpleLine: {
    background: deepPurple[500],
    display: 'block',
    height: 10,
  },
  purpleGradient: {
    height: 60,
    display: 'block',
  },
  menu: {
    textAlign: 'right',
  },
  navContain: {
    padding: '0 30px',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  gridAlign: {
    justifyContent: 'center',
    alignSelf: 'center',
  }
}));

const LogoHeader = ({ t, session, profile, auth }) => {
  const classes = HeaderStyles();
  //let name = profile === undefined ? '': profile.displayName;

  return (
    <div className='outer-header-container'>
      <div className={`${classes.logoHeader} ${classes.padBottom}`}>
          <Grid container>
            <Grid item xs={4} className={classes.gridAlign}>
              {session !== undefined ? (
                <div className={classes.navContain}>
                  <SessionControls profile={profile} t={t} session={session} auth={auth}/>
                </div>
              ) : null}
            </Grid>
            <Grid item xs={4} align="center">
              <img src={LogoAlt} alt='Solta' className={classes.logo} />
              <div style={{marginBottom: "15px", fontSize: "1.6rem", fontFamily: "'Bebas Neue', cursive"}}>ADMIN</div>
            </Grid>
            <Grid item xs={4} className={`${classes.menu} ${classes.gridAlign}`} >
              
                <div className={classes.navContain} align="right">
                  
                </div>
             
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={0} alignItems='center'>
          <Grid item xs={12}>
            <Paper square elevation={0} className={classes.purpleLine}></Paper>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Paper square elevation={0} className={classes.purpleGradient}></Paper>
          </Grid>
        </Grid>
      </div>
  );
};

export default LogoHeader;