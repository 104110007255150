import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { PROFILE_VALUES } from '../profiles/profileList';
import { Draggable } from 'react-beautiful-dnd';
import styles from './../../../../Styles';

class EISkill extends Component {
  render() {
    const { classes, p } = this.props;
    return (
      <Draggable draggableId={p} index={this.props.index}>
        {(provided, snapshot) => (
          <span className={`${classes.eiSkill} ${classes[p]}`} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >{PROFILE_VALUES[p].title}</span>
        )}
      </Draggable>
    );
  }
}

EISkill.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EISkill);
