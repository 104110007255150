import React, { Component } from 'react';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Group from './groups/group';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import LiquidLoadingBlock from '../Loading/loadingCup';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { gameHasOptions } from './../../components/Game/gameServer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import styles from '../Styles';
import { saveSessionGroups, renamePlannedSessionGroup } from '../../store/actions/adminActions';
 


// fake data generator
const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `${k}`,
    content: `item ${k}`,
  }));

const INITIAL_STATE = {
  players: getItems(4),
  groupNameOpen: false,
  group_name_id : false,
  group_name: '',
  groups: {
    'group-1': {
      id: 'group-1',
      title: 'Group 1',
      playerIds: [],
    },
    'group-2': {
      id: 'group-2',
      title: 'Group 2',
      playerIds: [],
    },
  },
  groupOrder: ['group-1', 'group-2'],
  groupsLoaded: false,
  playersLoaded: false,
  isLoading: false,
  mounted: false,
  ready: false,
  mouseY: null,
  mouseX: null,
  selectedPlayers: []
};

class GroupEditor extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    //this.props.endLoading();
    this.setState({
      ...this.state,
      mounted: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    let updatePlayers = false;
    let updateGroups = false;
    let newState = {
      ...this.state,
    };

    //console.log('t', this.props.players);
    if ( !isLoaded(this.props.players) || !isLoaded(this.props.planned_sessions_groups) ){
        return;
    }

    if (!this.state.groupsLoaded) {

      let g2 = {
        ...this.props.planned_sessions_groups[0].groups,
      };

      let g_order = [...Object.keys(this.props.planned_sessions_groups[0].groups).sort(this.gOrderSort)];

      this.setState({
        ...this.state,
        groups: g2,
        groupOrder: g_order,
        groupsLoaded: true,
      });
    }

    if ((prevProps.players !== this.props.players || prevState.groupsLoaded !== this.state.groupsLoaded) && this.state.groupsLoaded && this.props.players !== undefined) {
      /* Players have changed */
      updatePlayers = true;
      newState.players = this.props.players;

      let g2 = {
        ...this.props.planned_sessions_groups[0].groups,
      };
      newState.groups = g2;
      newState.playersLoaded = true;
    } else {

      if (this.props.players.length <= 0 && this.state.playersLoaded) {
        //console.log('going false', this.props.players.length);
        this.setState({
          ...this.state,
          playersLoaded: false,
        });
      }
    }

    if (Object.keys(prevProps.planned_sessions_groups[0].groups).length !== Object.keys(this.props.planned_sessions_groups[0].groups).length && this.state.groupsLoaded && this.props.players !== undefined){

      //console.log('should be in here after a delete');
      let g_order = [...Object.keys(this.props.planned_sessions_groups[0].groups).sort(this.gOrderSort)];
      let g2 = {
        ...this.props.planned_sessions_groups[0].groups,
      };
      
      newState = {
        ...newState,
        groups: g2,
        groupOrder : g_order
      };
      updateGroups = true;
    }

    if (updatePlayers || updateGroups) {
      this.setState({
        ...newState,
      });
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addGroup = () => {
    let teams = false;
    if ( this.props.inlineGameOptions !== false && this.props.inlineGameOptions !== undefined ){
      //console.log(this.props.inlineGameOptions);
      if ( this.props.inlineGameOptions.Teams === true ){
        // we need there to be an even number of groups
        teams = true;
      }
    }

    if ( teams === true ){
      let c = Object.keys(this.state.groups).length;
      let newG = {
        ['group-' + c]: {
          id: 'group-' + c,
          title: 'Group ' + c,
          vsKey: 'group-' + c + ':group-' + (c+1),
          leadPlayer: '',
          playerIds: [],
        },
        ['group-' + (c+1)] : {
          id: 'group-' + (c+1),
          title: 'Group ' + (c+1),
          vsKey: 'group-' + c + ':group-' + (c+1),
          leadPlayer: '',
          playerIds: [],
        }
      };
      let g = { ...this.state.groups, ...newG };
      let g_order = [...Object.keys(g)];

      this.setState({
        ...this.state,
        groups: g,
        groupOrder: g_order.sort(this.gOrderSort),
      });
      this.props.saveSessionGroups(g, this.props.session.id);

    } else {
      let c = Object.keys(this.state.groups).length;
      let d = Date.now();
      let newG = {
        ['group-' + d]: {
          id: 'group-' + d,
          title: 'New Group ',
          leadPlayer: '',
          playerIds: [],
        },
      };
      let g = { ...this.state.groups, ...newG };
      let g_order = [...Object.keys(g)];

      this.setState({
        ...this.state,
        groups: g,
        groupOrder: g_order.sort(),
      });
      this.props.saveSessionGroups(g, this.props.session.id);
    }
    

    
  };

  gOrderSort = (a,b) => {
    //console.log('sorting', a, b);
    if ( a === 'group-0' ){
      return -1;
    }
    if ( b === 'group-0' ){
      return 1;
    }
    let aTL = a.toLowerCase();
    let bTL = b.toLowerCase();
    if ( aTL < bTL ){
      return -1;
    }
    if ( aTL > bTL ){
      return 1;
    }
    if ( aTL === bTL ){
      return 0;
    }
  }

  



  groupChange = (type, index) => {
    if (type === 'add') {
    } else if (type === 'remove') {
    }
  };

  beginGame = () => {
    
    let valid = true;
    if ( this.props.inlineGameOptions !== false && this.props.inlineGameOptions !== undefined  ){
      //console.log('this', this.props.inlineGameOptions);
      if ( this.props.inlineGameOptions.Teams === true ){
        // we need there to be an even number of groups
        //console.log('haaaaa', Object.keys(this.state.groups).length);
        if ( Object.keys(this.state.groups).length % 2 === 0 ){
          valid = false;

          // popup an alert
        }
      }
    }

    if ( valid === true ){
      this.props.beginGame(this.state.groups);
    }
  };

  onSave = () => {
    this.props.saveSessionGroups(this.state.groups, this.props.session.id);
  };

  clickHandler = (event, playerId) => {
    let selPlayers = [...this.state.selectedPlayers];
    if ( selPlayers.includes(playerId) ){
        let index = selPlayers.indexOf(playerId);
        if ( index > -1 ){
          selPlayers.splice(index,1);
        }
    } else {
      selPlayers.push(playerId);
    }
    this.setState({
      ...this.state,
      selectedPlayers : selPlayers
    });
  }
  
  contextHandler = (event, playerId) => {
    event.preventDefault();
    event.stopPropagation();

    if ( this.state.selectedPlayers.length <= 0 ){
      return;
    }

    this.setState({
      ...this.state,
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }
  
  handleContextClose = () => {
    this.setState({
      ...this.state,
      mouseX: null,
      mouseY: null
    });
  }

  

  doMoveAction = (groupId) => {
    let selPlayers = [...this.state.selectedPlayers];
    let groups = {...this.state.groups};
    let ngroups = {...this.state.groups};
    let groupPlayers = [...this.state.groups[groupId].playerIds];


    selPlayers.forEach((playerId) => {

      if ( !groups[groupId].playerIds.includes(playerId) ){        
        groupPlayers.push(playerId);
        
        ngroups = {
          ...ngroups,
          [groupId] : {
            ...ngroups[groupId],
            playerIds: groupPlayers
          }
        }

        Object.keys(groups).forEach((group_id) => {
          if ( groups[group_id].playerIds.includes(playerId) && group_id !== groupId ){

            let index = ngroups[group_id].playerIds.indexOf(playerId);

            if ( index > -1 ){
              let p = [...ngroups[group_id].playerIds];
              p.splice(index,1)
              ngroups = {
                ...ngroups,
                [group_id] : {
                  ...ngroups[group_id],
                  playerIds: p
                }
              }
            }
          }
        });
      }
    });

    this.setState({
      ...this.state,
      groups: ngroups,
      selectedPlayers: [],
      mouseX: null,
      mouseY: null
    });
    this.props.saveSessionGroups(ngroups, this.props.session.id);
  }

  clearSelected = () => {
    this.setState({
      ...this.state,
      selectedPlayers: [],
      mouseX: null,
      mouseY: null
    });
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      group_name: "",
      group_name_id : false,
      groupNameOpen: false
    })
  }

  handleSave = () => {
      console.log('GROUP STUFF', this.state.group_name_id, this.state.group_name, this.props.session.id);
      this.props.renamePlannedSessionGroup(this.state.group_name_id, this.state.group_name, this.props.session.id);
      this.setState({
        ...this.state,
        groupNameOpen: false
      })
  }
  
  groupSort = (a, b) => {
      let aInd = a.indexOf('group-');
      let bInd = b.indexOf('group-');

      //console.log('Sorting: ', a, b, aInd, bInd);

      if ( aInd < 0 || bInd < 0 ){
        return -1;
      }

      let aNum = a.substring(6);
      let bNum = b.substring(6);

      //console.log('Nums: ', aNum, bNum);

      if ( parseInt(aNum) > parseInt(bNum) ){
        //console.log('Sorting Less');
        return 1;
      } else if ( parseInt(bNum) > parseInt(aNum) ) {
        //console.log('Sorting More');
        return -1;
      }

      return 0;
  }

  renameGroup = (groupId, groupName) => {
    this.setState({
      ...this.state,
      group_name_id : groupId,
      group_name : groupName,
      groupNameOpen: true
    })
  }

  deleteGroup = (groupId) => {

    if ( !window.confirm('Are you sure you want to remove this group?') ){
      return;
    }



    let players = [...this.state.groups[groupId].playerIds];
    let groups = {...this.state.groups};

    delete groups[groupId];

    if ( players.length > 0 ){
      //console.log('players', players);
      let groupZeroPlayers = [...this.state.groups[`group-0`].playerIds];
      players.forEach((playerId) => {
        groupZeroPlayers.push(playerId);
      });
      let newGroups = {
        ...groups,
        'group-0' : {
          ...groups['group-0'],
          playerIds: groupZeroPlayers
        }
      };
      console.log('new groups: ', newGroups);
      this.props.saveSessionGroups(newGroups, this.props.session.id);
    } else {
      console.log('new groups o: ', groups);
      this.props.saveSessionGroups(groups, this.props.session.id);
    }
  }

  render() {
    const {
      game_state,
      classes,
    } = this.props;

    if ( isLoaded(this.props.planned_sessions_groups) ){
      console.log('SESSION: ', this.props.planned_sessions_groups[0]);
    }

    let teams = false;
    /*if ( this.props.inlineGameOptions !== false && this.props.inlineGameOptions !== undefined  ){
      console.log(this.props.inlineGameOptions);
      if ( this.props.inlineGameOptions.Teams === true ){
        // we need there to be an even number of groups
        teams = true;
      }
    }*/

    //console.log('TEST:', this.state.groupsLoaded, this.state.playersLoaded, this.state.groups, this.state.groupOrder, this.props.players);
    if (this.state.groupsLoaded && this.state.playersLoaded) {
      return (
        <div className={classes.root}>
          <Dialog open={this.state.groupNameOpen} onClose={this.handleClose} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth>
            <DialogContent>
              <DialogContentText className={classes.bodyText}>Group Name:</DialogContentText>
              <TextField multiline autoFocus autoComplete="off" margin='dense' id='group_name' name='group_name' value={this.state.group_name} onChange={this.onChange} label="Group Name" type='email' fullWidth />
            </DialogContent>
            <DialogActions>             
                  <Button onClick={this.handleClose} color='primary'>
                    Cancel
                  </Button>
                  <Button onClick={this.handleSave} color='primary'>
                    Save
                  </Button>                
            </DialogActions>
          </Dialog> 
       
        <Menu
          keepMounted
          open={this.state.mouseY !== null}
          onClose={this.handleContextClose}
          anchorReference="anchorPosition"
          anchorPosition={
            this.state.mouseY !== null && this.state.mouseX !== null
              ? { top: this.state.mouseY, left: this.state.mouseX }
              : undefined
          }
        >
          {this.state.groupOrder.sort(this.groupSort).map((groupId, ind) => {
              return (
                <MenuItem key={ind} onClick={() => this.doMoveAction(groupId)}>Move to {this.props.planned_sessions_groups[0].groups[groupId] === undefined ? '' : this.props.planned_sessions_groups[0].groups[groupId].title}</MenuItem>
              );
          })}
          <MenuItem>--------------</MenuItem>
          <MenuItem onClick={() => this.clearSelected()}>Clear Selected</MenuItem>
        </Menu>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h4' className={`${classes.greyText}`}>
                Group Selection
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.spacingBottom} />
            </Grid>
          </Grid>
          <div onContextMenu={(event) => this.contextHandler(event, null)}>
            {this.state.groupOrder.sort(this.groupSort).map((groupId, ind) => {
              const group = this.state.groups[groupId];
              const groupSession = this.props.planned_sessions_groups[0].groups[groupId] === undefined ? { title: '' } : this.props.planned_sessions_groups[0].groups[groupId];
              const players = group.playerIds.map((playerId) => {
                  let newPlayers = { ...this.props.playersFull[playerId], id: playerId };     
                return newPlayers;
              });
              return <Group teams={teams} ind={ind} deleteGroup={this.deleteGroup} title={groupSession.title} selectedPlayers={this.state.selectedPlayers} key={group.id} group={group} group_id={groupId} players={players} player_list={this.props.playersFull} clickHandler={this.clickHandler} contextHandler={this.contextHandler} renameGroup={this.renameGroup}/>;
            })}
          </div> 
          <Grid container spacing={3} className={classes.buttonGroup}>
            {game_state === 'init' ? (
              gameHasOptions(this.props.session.active_game.id) === true ?
                this.props.session.active_game.optionsSet === true ?
              <Grid item>
                <Button type='submit' onClick={this.beginGame} className={classes.glassButton} variant='contained' startIcon={<PlayCircleFilledWhiteIcon />}>
                  Begin Game
                </Button>
              </Grid>
                :
                <Grid item>
                <Button type='submit' onClick={this.beginGame} className={classes.glassButton} variant='contained' disabled={true} startIcon={<PlayCircleFilledWhiteIcon />}>
                  Host Must Set Options Before Game Can Begin
                </Button>
              </Grid>
              :
              <Grid item>
                <Button type='submit' onClick={this.beginGame} className={classes.glassButton} variant='contained' startIcon={<PlayCircleFilledWhiteIcon />}>
                  Begin Game
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button type='submit' onClick={this.addGroup} variant='contained' startIcon={<GroupAddIcon />}>
                Add a New Group
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Loading...</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} align='center' className={classes.spacingTop}>
              <LiquidLoadingBlock />
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    players: state.firestore.ordered.planned_session_players,
    playersFull: state.firestore.data.planned_session_players,
    planned_sessions_groups: state.firestore.ordered.planned_session_groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      saveSessionGroups: (groups, sessionDoc) => dispatch(saveSessionGroups(groups, sessionDoc)),
      renamePlannedSessionGroup: (groupKey, groupName, sessionDoc) => dispatch(renamePlannedSessionGroup(groupKey, groupName, sessionDoc)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation(),firestoreConnect((props) => {
    if ( props.session.id === undefined ){
        return [];
    }
    return [
        {
          collection: 'planned_session_players',
          doc: props.session.id,
          subcollections: [{ collection: 'players' }],
          storeAs: 'planned_session_players'
        },
        {
          collection: 'planned_sessions',
          doc: props.session.id,
          storeAs: 'planned_session_groups'
        },
    ]
  }))(GroupEditor);