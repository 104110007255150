import { Dialog, DialogActions, IconButton, DialogContent, DialogContentText, DialogTitle, Grid, Typography, MenuItem, InputLabel } from '@material-ui/core';
import React, {Component} from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TextField from '@material-ui/core/TextField';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './../Styles';
import Select from '@material-ui/core/Select';
import { createNewEmailUser, checkEmailAddress, resetAdminMessage, addExistingModToMyAccount } from '../../store/actions/adminActions';
import LiquidLoadingBlockModal from '../Loading/loadingModal';

const INITIAL_STATE = {
    addDialog: false,
    isMod: false,
    firstName: '',
    lastName: '',
    emailAddress: '',
    password:'',
    reTypePassword:'',
    moderates: '',
    emailTaken: false,
    emailValidated: false,
    emailAlreadyModForYou: false
}; 

class AddUser extends Component{
    constructor(props) {
        super(props);
    
        this.state = {
          ...INITIAL_STATE
        };
    
      }
   
     onChange = (event) => {
        this.setState({...this.state,
             [event.target.name]: event.target.value });
      };

    handleDialogClose=()=>{
        this.props.resetAdminMessage();
        this.setState({
            ...this.state,
            addDialog: false,
            firstName: '',
            lastName: '',
            emailAddress: '',
            password:'',
            reTypePassword:'',
            selectRole:'',
            moderates:'',
            isMod:false,
            emailValid: false,
            emailTaken: false,
            emailAlreadyModForYou: false
        });

    }

    componentDidUpdate(prevProps, prevState){
        console.log('MESSAGE: ', this.props.adminMessage, this.props.adminLoading);
        if ( this.props.adminMessage === 'EMAIL ALREADY EXISTS' && prevProps.adminMessage !== 'EMAIL ALREADY EXISTS' ){
            this.setState({...this.state, emailTaken: true, emailValidated: false, emailAlreadyModForYou: false })
        }
        if ( this.props.adminMessage === 'EMAIL ALREADY EXISTS AS MOD' && prevProps.adminMessage !== 'EMAIL ALREADY EXISTS AS MOD' ){
            this.setState({...this.state, emailTaken: true, emailValidated: false, emailAlreadyModForYou: false })
        }

        if ( this.props.adminMessage === 'EMAIL OKAY' && prevProps.adminMessage !== 'EMAIL OKAY' ){
            this.setState({ ...this.state, emailValidated: true, emailTaken: false })
        }
    }

    handleDialogOpen=()=>{
        this.setState({...this.state,
            addDialog:true,
            emailValidated: false,
            emailTaken: false
        });
    }

    /*getHostID=(nameString)=>{
        //name string holds user input for who they moderate
        const objCopy   = this.props.playerList;
        let hostID ='';
        let idArray =Object.keys(objCopy);

        // search for where display name === nameString && role === 'host'
        if(this.props.playerList !== undefined ){

            idArray.map((uid,ind)=>{
                let user =objCopy[uid];
                if(user.role ==='host' && user.displayName === nameString){
                   hostID = uid
                }
              })
        }
        return (hostID)
    };*/

    checkEmail=()=>{
        if ( this.props.verbage === 'Moderator' && this.props.isAdmin === false ){
            // we are adding a moderator, so we need to check that the email we're adding here isn't already in the list of moderators for this host
            console.log("EMAIL CHECK, MOD EDIT, NO ADMIN");
            let exists = false;
            if ( this.props.moderators !== undefined && this.props.moderators !== null ){
                Object.keys(this.props.moderators).forEach((mod, ind) => {
                    console.log('mod check: ',  this.props.moderators[mod].email, this.state.emailAddress);
                    if ( this.props.moderators[mod].email === this.state.emailAddress ){
                        console.log('exists');
                        exists = true;
                    }
                });
                if (exists === true ){
                    // mod listed is already in my list
                    console.log('setting');
                    this.setState({
                        ...this.state,
                        emailAlreadyModForYou: true,
                        emailTaken: true
                    })
                } else {
                    this.props.checkEmailAddress(this.state.emailAddress);
                }
            } else {
                this.props.checkEmailAddress(this.state.emailAddress);
            }
        } else if ( this.props.verbage === 'Moderator' && this.props.isAdmin === true ){
            console.log("EMAIL CHECK, MOD EDIT, IS ADMIN");
            let exists = false;
            if ( this.props.moderators !== undefined && this.props.moderators !== null ){
                Object.keys(this.props.moderators).forEach((mod, ind) => {
                    
                    if ( this.props.moderators[mod].email === this.state.emailAddress && this.props.moderators[mod].moderatesFor.includes(this.props.hostID)){
                        exists = true;
                    }
                });
                if (exists === true ){
                    // mod listed is already in my list
                    this.setState({
                        ...this.state,
                        emailAlreadyModForYou: true,
                        emailTaken: true
                    })
                } else {
                    this.props.checkEmailAddress(this.state.emailAddress);
                }
            } else {
                this.props.checkEmailAddress(this.state.emailAddress);
            }
        } else {
            console.log("EMAIL CHECK, HOST EDIT");
            this.props.checkEmailAddress(this.state.emailAddress);
        }
    }

    addExistingModToMyAccount=()=>{
        this.props.addExistingModToMyAccount(this.state.emailAddress, this.props.hostID);
        this.handleDialogClose();
    }

    resetEmail=()=>{
        this.setState({
            ...this.state,
            emailAddress: '',
            emailTaken: false,
            emailValidated: false,
            emailAlreadyModForYou: false
            
        });
        this.props.resetAdminMessage();
    }

    addNewUser=()=>{
        let idOfHost ='';
        let amAMod = false
        if(this.props.hostID !== undefined){
            idOfHost = this.props.hostID;
            amAMod = true;
        }

    //(firstName,lastName,eMail,passWord,mod,modsWho)
       this.props.createNewEmailUser(this.state.firstName,this.state.lastName,this.state.emailAddress,this.state.password,amAMod,idOfHost);
        this.handleDialogClose();
    };

    render(){

        const {firstName, lastName, emailAddress, password, reTypePassword, addDialog, isMod, moderates, emailValidated, emailTaken, emailAlreadyModForYou} = this.state;
        const { classes, hostID } = this.props;
        const formFilledOut = firstName.length > 0 && lastName.length > 0 && emailAddress.length > 0 && password.length > 8 && reTypePassword.length >8;
        const emailFilledOut = emailAddress.length > 0;

        const isHost = this.props.hostID !== undefined ? false : true;
        console.log('Is Host: ', isHost, emailAlreadyModForYou);

        return(
            <React.Fragment>
            {/* Dialog for adding new user by email */}
                <Dialog aria-labelledby='add-user-page' aria-describedby='add new user page' open={addDialog} onClose={()=>this.handleDialogClose()}>
                    <DialogTitle id='add-user-page'>
                        <Grid container spacing={2} justify='flex-start' alignItems='center'>
                            <Grid item xs={10}>
                                <Typography variant='h6'>Setup New {this.props.verbage}.</Typography>
                            </Grid>
                            <Grid item sm={2} xs={1} align='right'>
                                <IconButton  onClick={()=>this.handleDialogClose()} >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the required information below.
                        </DialogContentText>
                        <br/>
                        <Grid item xs={12}>
                            <TextField required id='emailAddress' name='emailAddress' label='Email'  disabled={emailTaken && !isHost && this.props.adminMessage === 'EMAIL ALREADY EXISTS AS MOD'} value={emailAddress} onChange={(event)=>this.onChange(event)} className={classes.textField} />

                        </Grid>
                        { emailTaken ?
                        <Grid item xs={12}>
                            { isHost || emailAlreadyModForYou ?
                                isHost ?
                                <div>
                                    <Typography variant="body2">Unfortunately, this email is already taken by another user in the system. Please try again with another email.</Typography>
                                </div>
                                :
                                <div>
                                    <Typography variant="body2">Unfortunately, this moderator has already been added to your account. Please try again with another email.</Typography>
                                </div>
                            :
                                this.props.adminMessage === 'EMAIL ALREADY EXISTS AS MOD' ?
                                    <Typography variant="body2" style={{marginTop: 30}}>Would you like to add this existing user as a moderator for your account?</Typography>
                                :
                                <div>
                                    <Typography variant="body2" style={{marginTop: 30}}>Unfortunately, this email is already taken by another user in the system. Please try again with another email.</Typography>
                                </div>
                            }
                        </Grid>
                        : null }
                        { emailValidated ?
                        <>
                        <Grid item xs={12}>
                            <TextField required id='user-first-name' name='firstName' label='First Name' value={firstName} onChange={(event)=>this.onChange(event)} className={classes.TextField}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id="user-last-name" name='lastName' label='Last Name' value={lastName} onChange={(event)=>this.onChange(event)} className={classes.TextField}/>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TextField required type='password' id='password' name='password' label='Password' value={password} onChange={(event)=>this.onChange(event)} className={classes.textField} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required type='password' id='reTypePassword' name='reTypePassword' label='Retype Password' value={reTypePassword} onChange={(event)=>this.onChange(event)} className={classes.textField} />
                        </Grid>
                        </>
                        : null }
                    </DialogContent> 
                    <DialogActions>
                        { emailValidated ?
                        <Button size='large' disabled={!formFilledOut} onClick={()=>this.addNewUser()}>
                            SUBMIT
                        </Button>
                        : 
                            emailTaken && this.props.adminMessage === 'EMAIL ALREADY EXISTS AS MOD' && this.props.verbage !== 'Host' ?
                            <>
                                <Button size='large' disabled={!emailFilledOut} onClick={()=>this.addExistingModToMyAccount()}>
                                Yes
                            </Button>
                            <Button size='large' disabled={!emailFilledOut} onClick={()=>this.resetEmail()}>
                                No, Try Another Email
                            </Button>
                            </>
                            :
                            <Button size='large' disabled={!emailFilledOut} onClick={()=>this.checkEmail()}>
                                { (emailTaken && this.props.adminMessage !== 'EMAIL ALREADY EXISTS AS MOD') || (emailTaken && this.props.verbage === 'Host') ? `Retry` : `Next`}
                            </Button>
                    }
                    </DialogActions>
                </Dialog>

            { /* button to show- on click open dialog */}
            
                { this.props.adminLoading === true ? <LiquidLoadingBlockModal/> : null }
                <Button startIcon={< PersonAddIcon/>} variant='contained' color="primary" size='large' style={{maxWidth: "300px"}} className={classes.button} onClick={()=>this.handleDialogOpen()} >
                    ADD NEW {this.props.verbage}
                </Button>
             </React.Fragment>
         )
    }
};

AddUser.propTypes = {
    classes: PropTypes.object.isRequired,
}; 

const mapStateToProps = (state) =>{
    return {
        adminLoading: state.admin_state.adminLoading,
        adminMessage: state.admin_state.adminMessage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createNewEmailUser: (firstName,lastName,eMail,passWord,mod,modsWho) => dispatch(createNewEmailUser(firstName,lastName,eMail,passWord,mod,modsWho)),
        checkEmailAddress: (email) => dispatch(checkEmailAddress(email)),
        resetAdminMessage: () => (dispatch(resetAdminMessage())),
        addExistingModToMyAccount: (email,hostID) => (dispatch(addExistingModToMyAccount(email,hostID)))
    }
};

export default 
    compose(withStyles(styles),
    connect(mapStateToProps,
    mapDispatchToProps),
)(AddUser);