import React, { Component } from 'react';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Group from './groups/group';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import LiquidLoadingBlock from '../Loading/loadingCup';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { gameHasOptions } from './../../components/Game/gameServer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import styles from '../Styles';
import { addPlannedSessionPlayers, updatePlannedSessionPlayer, removePlannedSessionPlayer, sendEmailToPlannedSesssionPlayer } from '../../store/actions/adminActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EmailIcon from '@material-ui/icons/Email';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EditIcon from '@material-ui/icons/Edit';
import Badge from '@material-ui/core/Badge';
import PersonIcon from '@material-ui/icons/Person';
import ContactsIcon from '@material-ui/icons/Contacts';
import CSVParser from './comp_csv_parser';
import AddOrEditPlayer from './comp_addedit_player';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import CheckedAvatar from './comp_checked_avatar';
import LiquidLoadingBlockModal from '../Loading/loadingModal';

const INITIAL_STATE = {
    csvDialogOpen: false,
    addDialogOpen: false,
    deleteDialogOpen: false,
    editMode: 'add',
    selectedPlayer: false,
    joined: 0
};

class PlayerEditor extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

    componentDidMount(){
        if ( this.props.session.is_planned === true ){
            console.log('here?');
            if ( this.props.session.playerProfiles !== undefined ){
                let j = 0;
                Object.keys(this.props.session.playerProfiles).forEach((playerId, ind) => {
                    if ( this.props.session.playerProfiles[playerId].plannedSessionCode !== undefined && this.props.session.playerProfiles[playerId].plannedSessionCode !== '' ){
                        j += 1;
                    }
                });
                this.setState({
                    ...this.state,
                    joined: j
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState){
        console.log(this.props.session);
        if ( this.props.session.is_planned === true && this.props.session !== prevProps.session ){
            console.log('here?');
            if ( this.props.session.playerProfiles !== undefined ){
                let j = 0;
                Object.keys(this.props.session.playerProfiles).forEach((playerId, ind) => {
                    if ( this.props.session.playerProfiles[playerId].plannedSessionCode !== undefined && this.props.session.playerProfiles[playerId].plannedSessionCode !== '' ){
                        j += 1;
                    }
                });
                this.setState({
                    ...this.state,
                    joined: j
                })
            }
        }
    }

    closeParser = () => {
        this.setState({
            ...this.state,
            csvDialogOpen: false
        })
    }

    saveUpload = (csvData) => {
        //console.log(csvData, this.props.session.id);
        console.log('groups', this.props.session.groups);
        this.props.addPlannedSessionPlayers(this.props.session.id, csvData, this.props.session.groups);
        this.closeParser();
    }

    openParser = () => {
        this.setState({
            ...this.state,
            csvDialogOpen: true
        })
    }
    openAdd = () => {
        this.setState({
            ...this.state,
            addDialogOpen: true,
            editMode: 'add'
        })
    }
    closeAdd = () => {
        this.setState({
            ...this.state,
            addDialogOpen: false
        })
    }
    sortPlayers = (a,b) => {
        //console.log(a,b);
        if (a.name > b.name){ 
            return 1;
        } 
        if (a.name < b.name){ 
            return -1;
        } 
        if (a.name===b.name){
            return 0;
        } 
    }

    closeAddOrEdit = () => {
        this.setState({
            ...this.state,
            addDialogOpen: false,
            selectedPlayer: false
        });
    }
    showAddOrEdit = () => {
        this.setState({
            ...this.state,
            addDialogOpen: true
        })
    }
    saveAddOrEdit = (name, email) => {
        
        if ( this.state.editMode === 'add' ){
            this.props.addPlannedSessionPlayers(this.props.session.id, [{ name: name, email: email }], this.props.session.groups)
            this.closeAddOrEdit();
        }

        if ( this.state.editMode === 'edit' ){
            const player = {
                ...this.state.selectedPlayer,
                name: name,
                email: email
            };
            this.props.updatePlannedSessionPlayer(this.props.session.id, player.id, player);
            this.closeAddOrEdit();
        }
    }

    openEditPlayer = (player) => {
        this.setState({
            ...this.state,
            addDialogOpen: true,
            editMode: "edit",
            selectedPlayer: player
        })
    }

    confirmDeletePlayer = () => {
        this.props.removePlannedSessionPlayer(this.props.session.id, this.state.selectedPlayer.id);
        this.closeDeletePlayer();
    }
    openDeletePlayer = (player) => {
        this.setState({
            ...this.state,
            deleteDialogOpen: true,
            selectedPlayer: player
        })
    }
    closeDeletePlayer = () => {
        this.setState({
            ...this.state,
            deleteDialogOpen: false,
            selectedPlayer: false
        })
    }

    

    render() {
        const { classes } = this.props;
        

        if ( !isLoaded(this.props.players) ){
            return (
                <LiquidLoadingBlock/>
            )
        }

        return (<div>
            { 
                this.props.isLoading ?
                    <LiquidLoadingBlockModal/>
                : 
                null
            }
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h5">Manage Players { isLoaded(this.props.players) ? `(${this.props.players.length})` : null }</Typography>
                    { this.props.session.is_planned === true && isLoaded(this.props.players) ?
                        <Typography variant="body1">Joined Players: {this.state.joined}/{this.props.players.length}</Typography>
                    : null }
                </Grid>
                <Grid item xs={6} align="right">
                    { this.props.session.is_planned !== true ?
                    <Button variant='contained' color='primary' onClick={() => this.openAdd()} style={{marginRight: 10}}>
                        Add Player
                    </Button>
                    : null }
                    { this.props.session.is_planned !== true ?
                    <Button variant='contained' color='primary' onClick={() => this.openParser()}>
                        Upload CSV
                    </Button>
                    : null }
                </Grid>
            </Grid>
            <div style={{paddingTop: 30}}>
                <List>
            {
                isLoaded(this.props.players) ?
                    [...this.props.players].sort((a,b) => this.sortPlayers(a,b)).map((player, ind) => {
                        return (<ListItem key={ind}>
                        <ListItemAvatar>
                            { this.props.session.is_planned === true ?                                
                                <CheckedAvatar  playerProfiles={this.props.session.playerProfiles} player={player}/>                                
                            :
                                <Avatar></Avatar>
                            }
                            
                        </ListItemAvatar>
                        <ListItemText
                            primary={player.name}
                            secondary={ <React.Fragment>
                                <Typography variant="body1" component="span" style={{display: "block"}}>{player.email}</Typography>
                                <Typography variant="body2" component="span" style={{display: "block"}}>https://virtual.glassoflearning.com/p/{this.props.session.is_planned === true ? this.props.session.url : this.props.session.url_code}/{player.code}</Typography>
                            </React.Fragment>}
                        />
                        <ListItemSecondaryAction>
                            { this.props.session.is_planned !== true ?
                            <Tooltip title="Edit Player" aria-label="edit">
                                <IconButton edge="end" style={{marginRight: 5}} aria-label="update-session" onClick={() => this.openEditPlayer(player)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            : null }
                            <Tooltip title="Send Email" aria-label="send-email">
                                <IconButton edge="end" style={{marginRight: 5}} aria-label="send-email" onClick={() => {this.props.sendEmailToPlannedSesssionPlayer(this.props.session, player)}}>
                                    <EmailIcon />
                                </IconButton>
                            </Tooltip>
                            { this.props.session.is_planned !== true ?
                            <Tooltip title="Remove Player" aria-label="remove-player">
                                <IconButton edge="end" aria-label="delete" onClick={() => this.openDeletePlayer(player)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            : null }
                        </ListItemSecondaryAction>
                       
                        </ListItem>);
                    })
                :
                <LiquidLoadingBlock/>
            }
                </List>
            </div>


            <Dialog aria-labelledby='remove-player' aria-describedby='Remove Player'  maxWidth='md'  open={this.state.deleteDialogOpen} fullWidth>
                <AppBar position="static" className={classes.LNNoShadow}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={()=>this.closeDeletePlayer()} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                            <Typography variant="h6">
                            Remove Player             
                            </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>

                    <DialogContentText style={{paddingTop: 30}}>Are you sure you want to remove {this.state.selectedPlayer.name}? This action cannot be undone.</DialogContentText>                        
                    
                </DialogContent> 
                <DialogActions>
                    <Button size='large'  onClick={()=>this.closeDeletePlayer()}>
                        Cancel
                    </Button>
                    <Button size='large'  onClick={()=>this.confirmDeletePlayer()}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            
            <AddOrEditPlayer classes={this.props.classes} showAddOrEdit={this.state.addDialogOpen} closeAddOrEdit={this.closeAddOrEdit} saveAddOrEdit={this.saveAddOrEdit} mode={this.state.editMode} player={this.state.selectedPlayer}/>
            <CSVParser classes={this.props.classes} showParser={this.state.csvDialogOpen} closeParser={this.closeParser} saveParser={this.saveUpload}/>

        </div>)    
    }
}

const mapStateToProps = (state) => {
  return {
    players: state.firestore.ordered.planned_session_players,
    playersFull: state.firestore.data.planned_session_players,
    isLoading: state.admin_state.adminLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      addPlannedSessionPlayers: (sessionDoc, players, sessionGroups) => dispatch(addPlannedSessionPlayers(sessionDoc, players, sessionGroups)),
      updatePlannedSessionPlayer: (sessionDoc, playerDoc, playerObj) => dispatch(updatePlannedSessionPlayer(sessionDoc, playerDoc, playerObj)),
      removePlannedSessionPlayer: (sessionDoc, playerDoc) => dispatch(removePlannedSessionPlayer(sessionDoc, playerDoc)),
      sendEmailToPlannedSesssionPlayer: (session, player) => dispatch(sendEmailToPlannedSesssionPlayer(session, player))


  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation(),firestoreConnect((props) => {
    console.log(props);
    if ( props.session.id === undefined ){
        return [];
    }
    if ( props.session.is_planned === true ){
        return [
            {
              collection: 'planned_session_players',
              doc: props.session.planned_session_id,
              subcollections: [{ collection: 'players' }],
              storeAs: 'planned_session_players'
            }
        ]
    } else {
        return [
            {
            collection: 'planned_session_players',
            doc: props.session.id,
            subcollections: [{ collection: 'players' }],
            storeAs: 'planned_session_players'
            }
        ]
    }
  }))(PlayerEditor);