import React, { useState } from "react";
import Typography from '@material-ui/core/Typography';
import { steps } from './../data/data';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';

const ChooseYourOwnHistory = (props) => {

  const [historyShown, setHistoryShown] = useState(false);

  const closeHistory = () => {
    setHistoryShown(false);
  }
  const openHistory = () => {
    setHistoryShown(true);
  }
  
  let history = props.history;
  const isRestart = (element) => element.choice === 'restart';
  if ( props.onlyCurrentRun === true ){
    let ind = history.findIndex(isRestart);
    while ( ind > 0 ){
      history = history.slice(ind+1);
      ind = history.findIndex(isRestart);
    }     
  }
  console.log('History:', history);

  const historyLength = history.length;
  let i = 0;

  return (
    <div>
      <Button variant="contained" color="primary" onClick={openHistory}>View Your Scenario Choices</Button>
    
      <Dialog open={historyShown} onClose={closeHistory} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth>
          <DialogTitle onClose={closeHistory}  id="customized-dialog-title" style={{background: "#3f51b5", color: "#fff"}} >
            Your Path
          </DialogTitle>
          <DialogContent style={{paddingTop: "20px", paddingBottom: "20px", fontSize: "1.1rem"}}>
            <div style={{textAlign: 'center', padding: "30px 0", maxWidth: "80%", margin: "0 auto"}}>
              <div  className={props.classes.historyItem}><Grid container spacing={3}>
                              <Grid item xs={4}>
                                <img src={steps['step_001'].image} className={props.classes.imageSize}/>
                              </Grid>  
                              <Grid item xs={8}>
                                { steps['step_001'].text }
                              </Grid>
                            </Grid></div>
              <div><ArrowDownwardIcon fontSize="large"/></div>
              {
                  history.map((historyItem, ind) => {
                    i+=1;
                    if ( historyItem.type === 'no_choice' ){
                      return (
                        <div key={ind}>
                          <div className={props.classes.historyItem}>
                              <Grid container spacing={3}>
                                  <Grid item xs={4}>
                                    <img src={steps[historyItem.proceedTo].image} className={props.classes.imageSize}/>
                                  </Grid>  
                                  <Grid item xs={8}>
                                    { steps[historyItem.proceedTo].text }
                                  </Grid>
                              </Grid>
                            </div>
                            { i !== historyLength ? <div><ArrowDownwardIcon fontSize="large"/></div> : null }
                          </div>
                      )
                        
                      
                    }
                    if ( historyItem.choice === 'restart' ){
                      return (
                        <div key={ind}>
                          <div className={props.classes.historyRestart}>RESTART</div>
                          <div className={props.classes.historyItem}>
                            <Grid container spacing={3}>
                              <Grid item xs={4}>
                                <img src={steps['step_001'].image} className={props.classes.imageSize}/>
                              </Grid>  
                              <Grid item xs={8}>
                                { steps['step_001'].text }
                              </Grid>
                            </Grid>
                          </div>
                          <div><ArrowDownwardIcon fontSize="large"/></div>
                        </div>
                      )
                    }
                    return (
                      <div key={ind}>
                        <div>
                          <div className={props.classes.historyChoice}>CHOICE</div>
                          <div className={props.classes.historyItemChoice}>{ historyItem.text }</div>
                        </div>
                        <div><ArrowDownwardIcon fontSize="large"/></div>

                        { historyItem.choiceSubText !== '' ? 
                        <div>
                          <div className={props.classes.historyItem}>{ historyItem.choiceSubText }</div>
                          <div><ArrowDownwardIcon fontSize="large"/></div>
                        </div>
                        : null
                        }
                        
                        <div className={props.classes.historyItem}>
                        <Grid container spacing={3}>
                              <Grid item xs={4}>
                                <img src={steps[historyItem.proceedTo].image} className={props.classes.imageSize}/>
                              </Grid>  
                              <Grid item xs={8}>
                                { steps[historyItem.proceedTo].text }
                              </Grid>
                            </Grid></div>
                        { i !== historyLength ? <div><ArrowDownwardIcon fontSize="large"/></div> : null }
                      </div>
                      
                    );
                  })
              }
            </div>
          </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChooseYourOwnHistory;


