import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';

import {
  //GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
  //HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE,
  GROUP_STATE_RESULTS, GROUP_STATE_ACTIVE
} from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  hasGameControl,
  getUserGroup_hc,
  determineHost
} from '../../game_functions/functions';
//import GameHelp from './gameHelp';
import { updateGroupState, updateGroupStateParams, updateResultsPathObject, editResults, removeResults, moveToPhaseWithParamsOneGroup } from './../../../../../store/actions/gameActions';
import WordPlayground from './../specific_components/wordPlayground.js';
import WordActionCenter, { getPuzzle, defaultDifficulty, defaultId, getNextPuzzle } from './../specific_components/wordActionCenter.js';
import styles from '../../../../Styles';

import { wordGroups } from './../data/data';

import { DragDropContext } from 'react-beautiful-dnd';
import DropArea from './../specific_components/dropArea';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';

const INITIAL_STATE = {  
  open: false,  
  postits: [],
  isEdit: false,
  controlEdit: false,
  isInterfaceLoaded: false,
  containerWidth: null,
  gridRightWidth: null,
  gridLeftWidth: null,
  groupSandbox: true
};



class Activity extends Component {
  constructor(props) {
    super(props);
    this.gridLeft = React.createRef();
    this.gridRight = React.createRef();
    this.mainDiv = React.createRef();
    
    this.state = {
      ...INITIAL_STATE,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    
  }

  updateWindowDimensions() {
    this.setState({ 
      ...this.state, 
      containerWidth: this.mainDiv.current.offsetWidth,
      gridLeftWidth: this.gridLeft.current.offsetWidth,
      gridRighttWidth: this.gridRight.current.offsetWidth,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if ( !this.state.isInterfaceLoaded ){
      if ( this.mainDiv !== undefined ){
        //console.log('hERE!', this.mainDiv);
        this.setState({
          ...this.state,
          isInterfaceLoaded: true,
          containerWidth: this.mainDiv.current.offsetWidth,
          gridLeftWidth: this.gridLeft.current.offsetWidth,
          gridRighttWidth: this.gridRight.current.offsetWidth,
        });
      }
    }
    window.addEventListener('resize', this.updateWindowDimensions);

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  toggleSandbox = (val) => {
    console.log('toggling sandbox', val);
    this.setState({
      ...this.state,
      groupSandbox: val
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if ( !this.state.isInterfaceLoaded ){
      if ( this.mainDiv !== undefined ){
        console.log('hERE!');
      }
    }
    
  }


  sendNext(){
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const host = determineHost(this.props.profile, this.props.auth);


    const timeelapsed = Date.now() - this.props.session.active_game.groupStates[group_id].params.startedOn;
    //console.log('Time elapsed' , timeelapsed);
    let secondsElapsed = Math.floor(timeelapsed / 1000);

    if ( isNaN(secondsElapsed) ){
      secondsElapsed = 0;
    }

    const minutes = Math.floor(secondsElapsed / 60);
    const remainder = secondsElapsed % 60;

    let time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds`;

    let completions = [];

    let diff = defaultDifficulty;
    let pId = defaultId;
    if ( this.props.session.active_game.groupStates[group_id].params.puzzle !== undefined ){
        diff = this.props.session.active_game.groupStates[group_id].params.puzzle.puzzleDifficulty;
        pId = this.props.session.active_game.groupStates[group_id].params.puzzle.puzzleId;
    }

    console.log('TIME COMPARISON', Date.now(), this.props.session.active_game.groupStates[group_id].params.startedOn)
    let completion =  { 
      puzzleDifficulty: diff,
      puzzleId : pId,
      finishedIn: `SKIPPED, after ${time}`
    };
    if ( this.props.session.active_game.groupStates[group_id].params.completions !== undefined ){        
      completions = [
        ...this.props.session.active_game.groupStates[group_id].params.completions,
      ];
    }
    completions.push(completion);
    
     
    let nextData = getNextPuzzle(this.props.session, wordGroups, group_id);
    let params = {
      'puzzle' : {
          'puzzleDifficulty' : nextData.puzzleDifficulty,
          'puzzleId' : nextData.puzzleId
      },
      'startedOn' : Date.now(),
      'completions' : completions
    };

    this.props.moveToPhaseWithParamsOneGroup(this.props.session, host, params, GROUP_STATE_ACTIVE, group_id);
}

  



  

  render() {
    const {
      //game_id, game_title,
      session,
      profile,
      auth,
      //group_state, game_state,
      classes,
      game_options
      //leader
    } = this.props;
    const { open, helpOpen, entry_text } = this.state;
    const isInvalid = entry_text === '';

    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const numResults = (session.active_game.results[group_id] === undefined) ? 0 : (session.active_game.results[group_id].card_choices === undefined ? 0 : session.active_game.results[group_id].card_choices.length);
    //console.log('GROUP_ID:', group_id);
    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);

    const results = session.active_game.results[group_id] === undefined ?
                      {} :
                      session.active_game.results[group_id].choices === undefined ?
                      {} :
                      session.active_game.results[group_id].choices;
    const host = determineHost(profile, auth);
    
    let lockText = "";
    if ( game_options !== false ){
      lockText = game_options.game_texts.lock_text;
    }

    //console.log("WIDTH: ", this.state.containerWidth, this.state.gridLeftWidth);
 

    let data = getPuzzle(session, wordGroups, group_id);
    //console.log('The puzzle: ', data);
    let nextData = getNextPuzzle(this.props.session, wordGroups, group_id);

   
                    
    return (
      
      <div ref={this.mainDiv}>
       
        <Grid container spacing={2}>    
          <Grid item xs={12} md={9} lg={9} ref={this.gridLeft}>
            { this.state.isInterfaceLoaded === false ?
              null
            :
            hasControl ?
              <div>
                <WordPlayground session={session} control={hasControl} type={`group`} profile={profile} host={host} auth={auth} group_id={group_id} width={this.state.gridLeftWidth * 0.9}  results={session.active_game.results}/>
                
                { nextData !== false ?
                <div className={`${classes.spacingTop} ${classes.spacingBottom}`} style={{paddingTop: "70px"}}>
                  <Button variant="contained" color="primary" onClick={() => this.sendNext()}>SKIP Activity &amp; Proceed to Next Puzzle</Button>
                </div>
                : 
                <div className={`${classes.spacingTop} ${classes.spacingBottom}`} style={{paddingTop: "70px"}}>
                  <Typography variant="body1">This is the final puzzle. Go for it!</Typography>
                </div>
                }
              </div>
            :
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={(this.state.groupSandbox === true) ? true : false}
                  startIcon={<GroupIcon/>}
                  onClick={() => this.toggleSandbox(true)}
                  style={{marginRight: "20px"}}
                >
                  Group Sandbox
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.toggleSandbox(false)}
                  disabled={(this.state.groupSandbox === true) ? false : true}
                  startIcon={<PersonIcon/>}
                >
                  Personal Sandbox
                </Button>
                <div style={{marginTop: "20px", display: (this.state.groupSandbox === true ? 'block' : 'none' )}}>
                <WordPlayground session={session} control={false} type={`group`} profile={profile} auth={auth} host={host} group_id={group_id} width={this.state.gridLeftWidth * 0.9}  results={session.active_game.results}/>
                </div>
                <div style={{marginTop: "20px", display: (this.state.groupSandbox === true ? 'none' : 'block' )}}>
                <WordPlayground session={session} control={true} type={`personal`} profile={profile} auth={auth} host={host} group_id={group_id} width={this.state.gridLeftWidth * 0.9}  results={session.active_game.results}/>
                </div>

                
                
              </div>
              
            }
            
          </Grid> 
          <Grid item xs={12} md={3} ref={this.gridRight}>
            
            { this.state.isInterfaceLoaded === false ?
              null
            :
            <div>
              <div dangerouslySetInnerHTML={{ __html: lockText }}></div>
              
              <WordActionCenter session={session} hasControl={hasControl} profile={profile} host={host} auth={auth} wordsList={data.words} group_id={group_id} correct_order={data.correct_order} associations={data.associations} results={results} />
              
            </div>
            }
            
          
          </Grid>
         
        </Grid>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResultsPathObject: (path, value, hostID) => dispatch(updateResultsPathObject(path, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    moveToPhaseWithParamsOneGroup: (session, host, parameters, groupState, group) => dispatch(moveToPhaseWithParamsOneGroup(session, host, parameters, groupState, group)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);
