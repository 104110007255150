import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults, updateGroupStateParamsVal, editResultsPath, updateResultsPath, removeResultsPath } from './../../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingDots from './../../game_components/loadingDots';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { ProfileList, PROFILE_EMPLOYEE, PROFILE_MANAGER, ProfileCategories } from '../profiles/profileList_temp';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Board from './board';
import WalkProfile from './../specific_components/profile';
import GameHelp from './../specific_components/gameHelp';


const INITIAL_STATE = {
  noteOpen: false,
  entry_text: '',
  isEdit: false
};

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  handleClose = (event) => {
    
    this.props.updateGroupStateParamsVal(this.props.group_id, 'writing', false, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      noteOpen: false,
      isEdit: false,
    });
  };

  handleSave = (event) => {
    this.props.updateResultsPath(`active_game.results.${this.props.group_id}.step_${this.props.step}.notes`, this.state.entry_text, determineHost(this.props.profile, this.props.auth));
    this.props.updateGroupStateParamsVal(this.props.group_id, 'writing', false, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      noteOpen: false,
    });
  };

  

  itemAction = (index, value) => {
    this.setState({
      ...this.state,
      isEdit: index,
      noteOpen: true,
      entry_text: value,
    });
  };

  updateRow = (row) => {
    let c = [...this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].notes];
    c[row] = this.state.entry_text;

    this.props.editResultsPath(`active_game.results.${this.props.group_id}.step_${this.props.step}.notes`, c, determineHost(this.props.profile, this.props.auth));
    this.props.updateGroupStateParamsVal(this.props.group_id, 'writing', false, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      noteOpen: false,
      isEdit: false,
    });
  };

  deleteRow = (row) => {
    let c = [...this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].notes];
    c.splice(row, 1);

    this.props.removeResultsPath(`active_game.results.${this.props.group_id}.step_${this.props.step}.notes`, c, determineHost(this.props.profile, this.props.auth));
    this.setState({
      ...this.state,
      noteOpen: false,
      isEdit: false,
    });
  };

  

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  nextStep = () => {
    this.props.updateGroupStateParams(this.props.group_id, {
      phase : 'board'
    }, determineHost(this.props.profile, this.props.auth));
  }

  addToNotes = () => {
    this.setState({
        ...this.state,
        entry_text: '',
        noteOpen: true
      });
      this.props.updateGroupStateParamsVal(this.props.group_id, 'writing', true, determineHost(this.props.profile, this.props.auth));
  }


  getCorrespondingProfiles = (step) => {
    let e = ProfileList.find(obj => {
      return (obj.profile_step === step && obj.profile_type === PROFILE_EMPLOYEE)
    });
    let m = ProfileList.find(obj => {
      return (obj.profile_step === step && obj.profile_type === PROFILE_MANAGER)
    });
    return {
      [PROFILE_EMPLOYEE] : e,
      [PROFILE_MANAGER] : m          
    };
  }
  

  render() {
    const {
      session,
      profile,
      auth,
      params, 
      step,
      phase,
      classes,
      group_id,
    } = this.props;

    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);
    const results = session.active_game.results;
    let group_result = results[group_id] ? results[group_id] : false;
    let e_result = false;
    let m_result = false;
    let boardStep = 1;
    let stepsCompleted = 0;
    if ( group_result !== false ){       
        if ( group_result[`step_${step}`] ){ 
          e_result = group_result[`step_${step}`].done.includes(PROFILE_EMPLOYEE);
          m_result = group_result[`step_${step}`].done.includes(PROFILE_MANAGER);
        }
        boardStep = parseInt(Object.keys(group_result).length) + 1;
        stepsCompleted = parseInt(Object.keys(group_result).length)
        if ( boardStep > 11 ){
          boardStep = 11;
        }
    }

    if ( phase === 'board' ){
      return <Board
        params={params}
        step={boardStep}
        session={session}
        group_id={group_id}
        profile={profile}
        auth={auth}
        stepsDone={stepsCompleted}
        leader={session.active_game.groups[group_id].leadPlayer}
      />;
    }

    /* IF WE NEED TO CHANGE THE STEP DEPENDING ON THE GROUP, WE DO THAT HERE */
    const calculatedStep = step;
    const walkAwhileProfiles = this.getCorrespondingProfiles(calculatedStep);  
    const isInvalid = this.state.entry_text === '';

    return (
      <div>
        <Dialog open={this.state.noteOpen} onClose={this.handleClose} aria-labelledby='form-dialog-title'  maxWidth='md' fullwidth>
            <DialogContent className={`${classes.minDialogue}`}>
              <DialogContentText className={`${classes.bodyText}`}>{this.state.isEdit !== false ? `Editing note.` : `Type your note below and hit save.`}</DialogContentText>
              <TextField autoFocus autoComplete="off" multiline margin='dense' id='entry_text' name='entry_text' value={this.state.entry_text} onChange={this.onChange} label="Answer" type='text' fullWidth />
            </DialogContent>
            <DialogActions>
              {this.state.isEdit !== false ? (
                <div>
                  <Button onClick={() => this.deleteRow(this.state.isEdit)} color='secondary'>
                    Delete
                  </Button>
                  <Button onClick={this.handleClose} color='primary'>
                    Cancel
                  </Button>
                  <Button onClick={() => this.updateRow(this.state.isEdit)} color='primary'>
                    Save
                  </Button>
                </div>
              ) : (
                <div>
                  <Button onClick={this.handleClose} color='primary'>
                    Cancel
                  </Button>
                  <Button disabled={isInvalid} onClick={this.handleSave} color='primary'>
                    Save
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>
        <Typography variant="body1"  className={classes.spaceBottom}>
          { hasControl ? 
            `Tap on a profile to view the scenario from that person's perspective.`
          :
            `Your group leader can tap on the profiles below to allow your group to view that perspective.`
          }  <GameHelp/>
        </Typography>  
        <Typography variant='h5' className={`${classes.spaceBottom} ${classes.profileTypeDescriptor} ${classes.minorMargin}`} align="center">Scenario {step}<br/><span className={classes.profileScenario}>{ProfileCategories[step]}</span></Typography>  
        <Grid container spacing={3} className={classes.spacingTop}>
            <Grid item xs={6}>
                <WalkProfile 
                 walkProfile={walkAwhileProfiles[PROFILE_EMPLOYEE]}
                 stepTitle={ProfileCategories[step]}
                 type={PROFILE_EMPLOYEE}
                 step={step}
                 session={session}
                 group_id={group_id}
                 profile={profile}
                 auth={auth}
                 hasControl={hasControl}
                 d_result={e_result}
                />
            </Grid>
            <Grid item xs={6}>
                <WalkProfile 
                  walkProfile={walkAwhileProfiles[PROFILE_MANAGER]}
                  stepTitle={ProfileCategories[step]}
                  type={PROFILE_MANAGER}
                  step={step}
                  session={session}
                  group_id={group_id}
                  profile={profile}
                  auth={auth}
                  hasControl={hasControl}
                  d_result={m_result}
                />
            </Grid>
            <Grid item xs={12}>
              { e_result === true && m_result === true ?
                <div className={classes.spacingTop}>
                  <Typography variant="h5">Now that you're done reviewing...</Typography>
                  <Typography variant="body1">What did you learn from this scenario? Jot down any notes that come to mind. <br/>When done, the leader can tap Finish Scenario to head to the next scenario.</Typography>
                  <Grid container className={classes.spacingTop}>
                    <Grid item xs={12} sm={12}>
                        <Grid container className={classes.spacingBottom}>
                            <Grid item xs={6}>
                                <Typography variant="body1"></Typography>
                            </Grid>
                            <Grid item xs={6} align="right">
                                { hasControl ? 
                                <div>
                                <Button type='submit' variant="contained"  onClick={() => this.addToNotes()} startIcon={<AddIcon/>} className={classes.buttonMargin}>
                                Add Note
                                </Button>
                                <Button type='submit' variant="contained"  onClick={() => this.nextStep()} startIcon={<DoneIcon/>}>
                                Finish Scenario
                                </Button>
                                </div>
                                : null }
                            </Grid>
                        </Grid>
                        <List className={classes.listClass}>
                            {
                               group_result[`step_${step}`].hasOwnProperty('notes')
                                ? group_result[`step_${step}`].notes.map((item, index) => {
                                    return (
                                        hasControl ?
                                        <ListItem key={index} button onClick={() => this.itemAction(index, item)}>
                                        <ListItemText primary={item} className={`${classes.bodyText}`} />
                                        </ListItem>
                                        : 
                                        <ListItem key={index}>                 
                                            <ListItemText
                                                className={classes.bodyText} 
                                                primary={item}                                
                                            />
                                        </ListItem>
                                    );
                                    })
                                : session.active_game.groupStates[group_id].params.writing !== true ?
                                <div className={classes.padMe}>Your notes are empty. Have the group leader add a note to view them here.</div>
                                : null
                           }
                            {session.active_game.groupStates[group_id].params.writing === true ? <ListItem className={classes.setHeightListItem}><LoadingDots /></ListItem> : null}
                        </List>                              
                    </Grid>
                  </Grid>
                </div>
              : null }
            </Grid>
        </Grid>  
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    updateGroupStateParamsVal: ( group, value, groupStateParams, hostID ) => dispatch(updateGroupStateParamsVal(group, value, groupStateParams, hostID) ),
    editResultsPath: (path, value, host) => dispatch(editResultsPath(path, value, host)),
    updateResultsPath: (path, value, host) => dispatch(updateResultsPath(path, value, host)),
    removeResultsPath: (path, value, host) => dispatch(removeResultsPath(path, value, host)),

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);