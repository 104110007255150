import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EditIcon from '@material-ui/icons/Edit';
import Badge from '@material-ui/core/Badge';
import PersonIcon from '@material-ui/icons/Person';
import Tooltip from "@material-ui/core/Tooltip";
import ContactsIcon from '@material-ui/icons/Contacts';
import moment from 'moment';
/* session={session} profile={this.props.profile} auth={this.props.auth} ind={ind} */

export const PlannedSession = (props) => {

    
    //let t = moment(props.session.date);
    const [momentDate, setMomentDate] = React.useState(moment(props.session.date));
    const [theDate, setTheDate] = React.useState(momentDate.format('LLLL'));
    const [fromNow, setFromNow] = React.useState(momentDate.fromNow());
    const [intervalID, setIntervalID] = React.useState(false);

   
    //console.log(props.session);


    React.useEffect(() => {
        
        let t = moment(props.session.date);
        console.log('HERE!: ', props.session.date, t.format('LLLL'));
        setMomentDate(t);
        setTheDate(t.format('LLLL'));
        setFromNow(t.fromNow());

        if ( intervalID !== false ){
            clearInterval(intervalID);
        }
        setIntervalID(setInterval(() => {
            //setTheDate(t.format('LLLL'));
            //console.log('In interval: ', momentDate.format("LLLL"), props.session.date);
            setFromNow(t.fromNow());
            
        }, 1000));

        return () => { clearInterval(intervalID) }
        //clearInterval()
    },[props.session.date])

    return (
          
            <ListItem>
                <ListItemAvatar>
                    <Tooltip title={`${props.session.is_planned ? props.session.players.length : props.session.number_of_players} Players`} aria-label="number-of-players">
                        <Badge showZero badgeContent={props.session.is_planned ? props.session.players.length : props.session.number_of_players} color="primary">
                            <PersonIcon />
                        </Badge>
                    </Tooltip>
                </ListItemAvatar>
                <ListItemText
                    primary={`${theDate} (${fromNow})`}
                    secondary={
                        <React.Fragment>
                            <Typography variant="body1" component="span" style={{display:'block'}}>{props.session.title}</Typography>
                            <Typography variant="body2" component="span">https://virtual.glassoflearning.com/p/{props.session.url_code}</Typography>
                        </React.Fragment>
                    }
                />
                <ListItemSecondaryAction>
                    <Tooltip title="Edit Session" aria-label="edit">
                        <IconButton edge="end" style={{marginRight: 5}} aria-label="update-session" onClick={() => props.openSessionEditor(props.session)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Update Players" aria-label="edit-players" onClick={() => props.openPlayerEditor(props.session, 'player')}>
                        <IconButton edge="end" style={{marginRight: 5}} aria-label="update-users">
                            <ContactsIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Set Groups" aria-label="edit-groups" onClick={() => props.openGroupEditor(props.session, 'group')}>
                        <IconButton edge="end" style={{marginRight: 5}} aria-label="edit-groups">
                            <PeopleAltIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Session" aria-label="delete" onClick={() => props.deleteSession(props.session.id)}>
                        <IconButton edge="end" aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>             

    );
}