import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import Typography from '@material-ui/core/Typography'
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
    TableBody, TableCell, Collapse, TableHead, TableRow, Box, Button } from '@material-ui/core';
/*


                        */

const ContactForm = (props) => {

    const recaptchaRef = React.createRef();
    const [name, setName] = React.useState('');
    const [company, setCompany] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [errors, setErrors] = React.useState({
        name: false,
        email: false,
        phone: false,
        message: false,
        recaptcha: false
    })

    const onChangeName = (event) => {
        setName( event.target.value );    
        if ( event.target.value !== '' ){
            setErrors({
                ...errors,
                name: false
            })
        }
    };
    const onChangeCompany = (event) => {
        setCompany( event.target.value );    
    };
    const onChangeEmail = (event) => {
        setEmail( event.target.value );  
        if ( event.target.value !== '' ){
            setErrors({
                ...errors,
                email: false
            })
        }  
    };
    const onChangePhone = (event) => {
        setPhone( event.target.value );    
        if ( event.target.value !== '' ){
            setErrors({
                ...errors,
                phone: false
            })
        }
    };
    const onChangeMessage = (event) => {
        setMessage( event.target.value ); 
        if ( event.target.value !== '' ){
            setErrors({
                ...errors,
                message: false
            })
        }   
    };

    const recaptchaChange = (value) => {
        if ( value !== '' && value !== ' ' ){
            setErrors({
                ...errors,
                recaptcha: false
            })
        }
    }

    const onSubmit = () => {
        const recaptchaValue = recaptchaRef.current.getValue();

        let valid = true;
        let nameInvalid, emailInvalid, phoneInvalid, messageInvalid, recaptchaInvalid = false;
        if ( name === '' ){
            nameInvalid = true;
            valid = false;
        }
        if ( email === '' ){
            emailInvalid = true;
            valid = false;
        }
        if ( phone === '' ){
            phoneInvalid = true;
            valid = false;
        }
        if ( message === '' ){
            messageInvalid = true;
            valid = false;
        }
        if ( recaptchaValue === '' || recaptchaValue === " "){
            recaptchaInvalid = true;
            valid = false;
        }

        if ( valid === false ){
            setErrors({
                ...errors,
                name: nameInvalid,
                email: emailInvalid,
                phone: phoneInvalid,
                message: messageInvalid,
                recaptcha: recaptchaInvalid
            });
        } else {
            props.onSubmit(name, company, email, phone, message);
        }



        console.log('val', recaptchaValue, valid);
        //props.onSubmit(recaptchaValue);
    }

    if ( props.visible === false ){
        return (
            <Typography variant="body1">Thank you! Your message has been sent.</Typography>
        )
    }

    return (
        <form noValidate autoComplete="off" className="contact-form" style={{marginBottom:"30px"}}>
            <Grid container spacing={3} style={{marginBottom:"15px"}}>
                <Grid item sm={6} xs={12}>
                <TextField
                    required 
                    style={{width:"100%"}}                             
                    id="outlined-required"
                    label="Name"
                    value={name}
                    error={errors.name}
                    onChange={onChangeName}
                    variant="outlined"
                />
                </Grid>
                <Grid item sm={6} xs={12}>
                <TextField
                    style={{width:"100%"}}                            
                    id="outlined"
                    label="Company"
                    value={company}
                    onChange={onChangeCompany}
                    variant="outlined"
                    
                />
                </Grid>
            </Grid>
            <Grid  spacing={3} container>
                <Grid item sm={6} xs={12} style={{marginBottom:"15px"}}>
                <TextField
                    required 
                    style={{width:"100%"}}                             
                    id="outlined-required"
                    label="Email"
                    value={email}
                    error={errors.email}
                    onChange={onChangeEmail}
                    variant="outlined"
                />
                </Grid>
                <Grid item sm={6} xs={12}>
                <TextField
                    style={{width:"100%"}}
                    required                              
                    id="outlined-required"
                    label="Phone"
                    value={phone}
                    error={errors.phone}
                    onChange={onChangePhone}
                    variant="outlined"
                />
                </Grid>
            </Grid>
            <Grid  spacing={3} container>
                <Grid item sm={12} xs={12} style={{marginBottom:"15px"}}>
                <TextField
                    required 
                    multiline
                    style={{width:"100%"}}                             
                    id="outlined-required"
                    value={message}
                    error={errors.message}
                    label="Message"
                    onChange={onChangeMessage}
                    rows={4}
                    variant="outlined"
                />
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <div style={{padding: "0 12px"}}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LdwJBUdAAAAAOT6Yb0ffoU5E4j7bC9w1hcmVfRa"
                                onChange={recaptchaChange}
                                />
                            <div style={{color: "red"}}>{ errors.recaptcha === true ? 'Please use recatpcha.' : '' }</div>
                        </div>
                    </Grid>
                    <Grid item sm={6} align="right">
                        <div style={{padding: "0 12px"}}>
                            <Button variant="contained" color="primary" onClick={() => onSubmit()}>
                                Submit
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default ContactForm;