import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styles from '../../../../Styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

const DELTA = "delta";

class TextEntryList extends Component {
  
  render() {
    const { session, profile, classes, auth, group_id, resultsPath, finalPath, debrief } = this.props;

    let hasResults = false;
    if ( resultsPath.hasOwnProperty(group_id ) ){
        if ( resultsPath[group_id].hasOwnProperty(finalPath) ){
            hasResults = true;
        }
    }

      return (
      <List className={`${classes.listClass} ${classes.relativeList}`}>
          { hasResults === true
              ? Object.keys(resultsPath[group_id][finalPath]).sort().map((item, index) => {
                  if ( debrief === false ){
                    return (
                      this.props.hasControl === true ?
                      <ListItem key={index} onClick={() => this.props.itemAction(item, resultsPath[group_id][finalPath][item])} style={{alignItems: 'center', justifyContent: 'center', padding: '0', margin: '0 8px 0 0'}}>
                        <Card className={`${classes.discCard}`} variant="outlined" style={{alignItems: 'center', justifyContent: 'center', padding: '0',
    margin: '0 8px 0 0'}}>
                            <CardActionArea className={`${classes.alignVerticalCenter} ${classes.cardActionPadding}`}>
                                <Grid container className={classes.alignVerticalCenter}>                                    
                                    <Grid item xs={12}>
                                       <div dangerouslySetInnerHTML={ {__html: resultsPath[group_id][finalPath][item] } } className={classes.bodyListText} style={{fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: '1.2rem'}}></div>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Card>                        
                      </ListItem>
                      :
                      <ListItem key={index} style={{alignItems: 'center', justifyContent: 'center', padding: '0', margin: '0 8px 0 0'}}>                 
                         <Card className={`${classes.discCard}`} variant="outlined" style={{alignItems: 'center', justifyContent: 'center', padding: '0',
    margin: '0 8px 0 0',}}>
                            <CardActionArea className={`${classes.alignVerticalCenter} ${classes.cardActionPadding}`}>
                                <Grid container className={classes.alignVerticalCenter}>                                    
                                    <Grid item xs={12}>
                                       <div dangerouslySetInnerHTML={ {__html: resultsPath[group_id][finalPath][item] } } className={classes.bodyListText} style={{fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: '1.2rem'}}></div>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Card>                                  
                      </ListItem>
                    );
                  } else {
                    return (                      
                      <ListItem key={index} style={{alignItems: 'center', justifyContent: 'center', padding: '0', margin: '0 8px 0 0'}}>
                        <Card className={`${classes.discCard}`} variant="outlined" style={{alignItems: 'center', justifyContent: 'center', padding: '0',
    margin: '0 8px 0 0',}}>
                            <CardActionArea className={`${classes.alignVerticalCenter} ${classes.cardActionPadding}`}>
                                <Grid container className={classes.alignVerticalCenter}>                                    
                                    <Grid item xs={12}>
                                       <div dangerouslySetInnerHTML={ {__html: resultsPath[group_id][finalPath][item] } } className={classes.bodyListText} style={{fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: '1.2rem'}}></div>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Card>   
                      </ListItem>                      
                    );
                  }
                })
              : <ListItem>
                <div style={{fontSize:"10px", fontStyle:"italic", opacity:"0.7"}}>Click "Add Response" to add an answer to this list.</div>
              </ListItem>
          }
        </List>
      );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TextEntryList);
