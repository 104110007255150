import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import disc_active1 from './../images/disc_active1.png';
import disc_active2 from './../images/disc_active2.png';
import disc_init from './../images/disc_init.png';
import disc_results from './../images/disc_results.png';
import ViewListIcon from '@material-ui/icons/ViewList';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const INITIAL_STATE = {
    open: false,
    legendOpen: false
};

class HostInstructions extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    handleLegendClickOpen = () => {
        this.setState({
            ...this.state,
            legendOpen: true
        });
    };
    
    handleLegendClose = () => {
        this.setState({
            ...this.state,
            legendOpen: false
        });
    };
    render() {
        const {
        classes,
        } = this.props;

        return (
        <div>
            <Typography variant='h5' className={classes.greyText}>
                Host Tools
            </Typography>
            <div className={classes.spacingTop}>
                <div><Button 
                        variant="contained"
                        color="primary"
                        startIcon={<SpeakerNotesIcon />}
                        onClick={this.handleClickOpen}
                        >
                    Leader Notes
                    </Button>
                    <div className={classes.spacingTop}>
                        <Button 
                            variant="contained"
                            color="primary"
                            startIcon={<ViewListIcon />}
                            onClick={this.handleLegendClickOpen}
                            >
                        State Legend
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                        <Typography variant="h6" className={classes.boldedText}>
                        Leader Notes
                        </Typography>                    
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} className={classes.dialogueGrid}>
                    <Grid item xs={12}  className={classes.spacingTop}>
                        <div className={classes.bodyText}>
                            
                            <div className={classes.boldedText}>Exercise: Part 1</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>In teams review the set of cards you have been given</li>
                                <li className={classes.properListItem}>As a group, select the top 5 cards that you believe best describe your group, and note them on your group flipchart.</li>
                                <li className={classes.properListItem}>Teams need to have consensus on the words chosen.</li>
                                <li className={classes.properListItem}>It is possible that not all words on a card will best describe your team.</li>
                                <li className={classes.properListItem}>If necessary, add something else that you feel does represent you.</li>
                            </ul>
                            <br/>
                            <div className={classes.boldedText}>Exercise: Part 2</div>
                            <div>In teams complete the following 3 statements on a flip chart.</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>The kind of work I like to do is...</li>
                                <li className={classes.properListItem}>People drive me crazy when...</li>
                            </ul>
                            <div>Identify a spokesperson to report to the group.</div>
          
                            <br/><br/>
                            <div className={classes.boldedText}>Faciliator Note: </div>
                            <div>Have the groups move to a section of the room with a flipchart labeled D, I, S, C.  As they select their top 5 cards, they can record or post them on the flipcharts for ease of presenting to the full group later.</div>

                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>Dominance - Green Cards</li>
                                <li className={classes.properListItem}>Influence - Pink Cards</li>
                                <li className={classes.properListItem}>Steadiness - Blue Cards</li>
                                <li className={classes.properListItem}>Conscientousness - Yellow Cards</li>
                            </ul>
                            <br/>
                            <div>Pay close attention to the actions of each of the groups as they review the cards; you want to be able to highlight the differences you see when you debrief.</div>
                            <br/><br/>
                            <div className={classes.boldedText}>Dominance:</div>
                            <div>Will typically be the first to sort through the cards; they will work quickly and efficiently, possibly placing the cards in logical groups and categorizing them. 
If flip-charting, sensors may not write on the chart at all – they may simply stick the top 5 cards up on the chart.</div>
                            <br/>
                            <div className={classes.boldedText}>Influence:</div>
                            <div>Will typically be very creative as they review the cards. They may want to re-word certain phrases and use flowery descriptive words.</div>
                            <br/>
                            <div className={classes.boldedText}>Steadiness:</div>
                            <div>Will typically sit around the table and chat, barely reviewing the cards; they will still want to keep talking and be together; be concerned about the relationship.</div>
                            <br/>
                            <div className={classes.boldedText}>Conscientiousness:</div>
                            <div>will typically be very orderly, maybe sort the cards, want the choices they make to be right and accurate, may discuss exactly how they want to explain their choices.
Detailed and specific when reporting back to the group.</div>
                            <br/> 
                        </div>                     
                    </Grid>
                </Grid>              
            </Dialog>
            <Dialog  open={this.state.legendOpen} onClose={this.handleLegendClose} TransitionComponent={Transition}>
                <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={this.handleLegendClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                        <Typography variant="h6" className={classes.boldedText}>
                        State Legend
                        </Typography>                    
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} className={classes.dialogueGrid}>
                    <Grid item xs={12}  className={classes.spacingTop}>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Init</Typography>
                        <div>
                            <img src={disc_init} alt='D i S C instructions' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Active - Step 1</Typography>
                        <div>
                            <img src={disc_active1} alt='D i S C card choices' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Active - Step 2</Typography>
                        <div>
                            <img src={disc_active2} alt='answer the following questions' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Results</Typography>
                        <div>
                            <img src={disc_results} alt='results and wrapping up' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                        
                    </Grid>
                </Grid>
                
                                
            </Dialog>
        </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(HostInstructions);
