import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DiscCardDraggable from './../specific_components/cardDraggable';
import LiquidLoadingBlock from '../../../../Loading/loadingCup';
import { getUserGroup_hc } from '../../game_functions/functions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { Q1_FS, Q2_FS, Q1, Q2 } from './activityTwo';


class Results extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

  render() {
    const {
      session,
      classes,
      profile,
      auth
    } = this.props;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    return (
      <div>
        <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant="h5">Wrapping Up!</Typography>
            <div>
                <Typography variant="body1">Waiting for other groups to finish their activity.</Typography>
              <br />
              <br />
              <div className={classes.cupContainerLeft}>
                <LiquidLoadingBlock/>
              </div>
            </div>
            </Grid>
          <Grid item xs={12} sm={6}>        
                <Typography variant="body1">{Q1}</Typography>    
                <List className={classes.listClass}>
                    {session.active_game.results.hasOwnProperty(group_id)
                        ? session.active_game.results[group_id].hasOwnProperty(Q1_FS)
                        ? session.active_game.results[group_id][Q1_FS].map((item, index) => {
                            return (   
                                <ListItem key={index}>                 
                                    <ListItemText
                                        className={classes.bodyText} 
                                        primary={item}                                
                                    />
                                </ListItem>
                            );
                            })
                        : null
                    : null}
                </List>
                <Typography variant="body1" className={classes.spacingTop}>{Q2}</Typography>    
                <List className={classes.listClass}>
                    {session.active_game.results.hasOwnProperty(group_id)
                        ? session.active_game.results[group_id].hasOwnProperty(Q2_FS)
                        ? session.active_game.results[group_id][Q2_FS].map((item, index) => {
                            return ( 
                                <ListItem key={index}>                 
                                    <ListItemText
                                        className={classes.bodyText} 
                                        primary={item}                                
                                    />
                                </ListItem>
                            );
                            })
                        : null
                    : null}
                </List>      
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container>
                <Typography variant="body1">Card Choices</Typography> 
                <List className={`${classes.listClass} ${classes.relativeList} ${classes.fullWidthListItem}`} >
                { session.active_game.results[group_id] !== undefined ?
                    session.active_game.results[group_id].card_choices !== undefined ?
                      session.active_game.results[group_id].card_choices.map((card_id, index) => (                                        
                          <DiscCardDraggable session={session} profile={profile} auth={auth} card_id={card_id} key={card_id} index={index} control={false} />                          
                      ))
                    : null
                  : null
                }
            </List>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Results);