import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import { updateGroupStateParamsVal, editResultsPath, updateGroupStateStateVal } from '../../../../../store/actions/gameActions';
//import { createPiecesGroup } from './../utils/createPiecesGroup';
import paper from "paper/dist/paper-core"
import { Tangram } from './tangramImport';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
    //GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
    //HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE,
    GROUP_STATE_RESULTS, GROUP_STATE_ACTIVE, GAME_STATE_RESULTS
  } from '../../../../../store/actions/gameActions';
import { Stage, Layer, Text } from 'react-konva';
import Konva from 'konva';

import { getPuzzle, defaultDifficulty, defaultId } from './wordActionCenter';

import styles from '../../../../Styles';

import { wordGroups } from '../data/data.js';


const INITIAL_STATE = {
    timerActive: true,
    currentInterruption: null,
    x: 50,
    y: 50,
    isDragging: false
}

class WordPlayground extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount(){
   
        console.log("THE PANE WIDTH", this.props.width, wordGroups);

        let data = getPuzzle(this.props.session, wordGroups, this.props.group_id);

        let x = 25;
        let y = 25;
        data.words.map((key, ind) => {
            let skip = false;
            //console.log("IN LOOP", this.props.group_id, this.props.results);
            if ( this.props.results[this.props.group_id] !== undefined && this.props.type === 'group'){
                //console.log('IN RESULTS');
                if ( this.props.results[this.props.group_id].sandbox !== undefined ){
                    if ( this.props.results[this.props.group_id].sandbox[key] !== undefined ){
                        this.spawnRectangle(0, this.props.results[this.props.group_id].sandbox[key].x, this.props.results[this.props.group_id].sandbox[key].y, this.props.width, key);
                        skip = true;
                    }
                }
            }
            if ( !skip) {
                this.spawnRectangle(0, x, y, this.props.width, key);
            }
            y+=65;
            if ( y > 600 ){
                y = 25;
                x += 180;
            }
        });
        
    }

    componentDidUpdate(prevProps, prevState){
        console.log("HERE WE ARE1", this.props.results[this.props.group_id], prevProps.results[this.props.group_id], this.props.type );
        let iCP = this.props.results[this.props.group_id] === undefined ? {} : this.props.results[this.props.group_id];
        let pCP = prevProps.results[this.props.group_id] === undefined ? {} : prevProps.results[this.props.group_id];

        if ( this.props.type === 'group' ){
            let cP = iCP.sandbox === undefined ? {} : iCP.sandbox;
            let pP = pCP.sandbox === undefined ? {} : pCP.sandbox;
            console.log("HERE WE ARE", cP, pP);

                if ((cP !== pP) && (Object.keys(cP).length !== 0)){
                    console.log("HERE WE ARE 2");
                    this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].children.map((child, ind) => {
                        //console.log('POOP', child);
                        let text = child.children[1].attrs.text;
                        if ( this.props.results[this.props.group_id].sandbox[text] !== undefined ){
                            
                            /* 
                                TODO: Make these calculations percentage based so that things don't appear off groups members' canvases.
                            */

                            if ( 
                                this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].children[ind].x() !== this.props.results[this.props.group_id].sandbox[text].x ||
                                this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].children[ind].y() !== this.props.results[this.props.group_id].sandbox[text].y
                            ){

                                

                                this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].children[ind].x(this.props.results[this.props.group_id].sandbox[text].x);
                                this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].children[ind].y(this.props.results[this.props.group_id].sandbox[text].y);
                                child.clearCache();
                                this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].draw();
                            }
                            
                            
                            //this.refs[`recLayer_${this.props.type}`].children[ind] = child;
                        }
                    });
                }
            
        }
        console.log('IN UPDATE');
        if ( this.props.session.active_game.groupStates[this.props.group_id] !== undefined ){
            if ( this.props.session.active_game.groupStates[this.props.group_id].params.puzzle !== undefined ){
                console.log('IN UPDATE2');

                let curP = this.props.session.active_game.groupStates[this.props.group_id].params.puzzle;
                let prevP = prevProps.session.active_game.groupStates[this.props.group_id].params.puzzle === undefined ? { 'puzzleDifficulty' : defaultDifficulty, 'puzzleId' : defaultId } : prevProps.session.active_game.groupStates[this.props.group_id].params.puzzle;

                if ( 
                    (curP.puzzleDifficulty !== prevP.puzzleDifficulty) ||
                    (curP.puzzleId !== prevP.puzzleId)
                   
                ){
                    console.log('IN UPDATE3');
                    this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].removeChildren();
                    let data = getPuzzle(this.props.session, wordGroups, this.props.group_id);

                    let x = 25;
                    let y = 25;
                    data.words.map((key, ind) => {
                        let skip = false;
                        //console.log("IN LOOP", this.props.group_id, this.props.results);
                        if ( this.props.results[this.props.group_id] !== undefined && this.props.type === 'group'){
                            //console.log('IN RESULTS');
                            if ( this.props.results[this.props.group_id].sandbox !== undefined ){
                                if ( this.props.results[this.props.group_id].sandbox[key] !== undefined ){
                                    this.spawnRectangle(0, this.props.results[this.props.group_id].sandbox[key].x, this.props.results[this.props.group_id].sandbox[key].y, this.props.width, key);
                                    skip = true;
                                }
                            }
                        }
                        if ( !skip) {
                            this.spawnRectangle(0, x, y, this.props.width, key);
                        }
                        y+=65;
                        if ( y > 600 ){
                            y = 25;
                            x += 180;
                        }
                    });
                    this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].draw();
                }
            }
        }

        if ( this.props.group_id !== prevProps.group_id ){
            this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].removeChildren();
            let data = getPuzzle(this.props.session, wordGroups, this.props.group_id);

            let x = 25;
            let y = 25;
            data.words.map((key, ind) => {
                let skip = false;
                //console.log("IN LOOP", this.props.group_id, this.props.results);
                if ( this.props.results[this.props.group_id] !== undefined && this.props.type === 'group'){
                    //console.log('IN RESULTS');
                    if ( this.props.results[this.props.group_id].sandbox !== undefined ){
                        if ( this.props.results[this.props.group_id].sandbox[key] !== undefined ){
                            this.spawnRectangle(0, this.props.results[this.props.group_id].sandbox[key].x, this.props.results[this.props.group_id].sandbox[key].y, this.props.width, key);
                            skip = true;
                        }
                    }
                }
                if ( !skip) {
                    this.spawnRectangle(0, x, y, this.props.width, key);
                }
                y+=65;
                if ( y > 600 ){
                    y = 25;
                    x += 180;
                }
            });
            this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].draw();
        }
    
    }



    handleDragStart = (e) => {
        
    };
    handleDragEnd = (e) => {
       console.log('hererere');
    };
    
    componentWillUnmount(){
        //paper.project.remove();      
    }

    finishActivity = () => {
        let groupID = this.props.group_id;
      
        this.props.updateGroupStateParamsVal(groupID, 'finishedOn', Date.now(), this.props.host); 
        /* TODO: Update group state, but ensure it doesn't touch the params */
        this.props.updateGroupStateStateVal(groupID, GROUP_STATE_RESULTS, this.props.host);
      };


    spawnRectangle(angle, x, y, width, word){
        let isDraggable = this.props.control;
        console.log('isDraggable?', isDraggable);
        let self = this;

        var rectangle = new Konva.Group({
            x: x, 
            y: y, 
            width: 130,
            height: 25,
            rotation: angle, 
            draggable: isDraggable,
            
            dragBoundFunc: function (pos) {
                var newY = pos.y < 10 ? 10 : pos.y;
                newY = newY > (700 - 115) ? (700-115) : newY;
                var newX = pos.x < 10 ? 10 : pos.x;
                newX = newX > (width - 160) ? (width - 160) : newX;
                //console.log(newX, pos.x);
                return {
                  x: newX,
                  y: newY,
                };
              }
        }); 
    
        rectangle.add(new Konva.Rect({
            width: 130,
            height: 45,
            fill: '#673ab7'
        }));
    
        rectangle.add(new Konva.Text({
            text:word,
            fontSize: 20,
            fontFamily: 'Arial',
            fill: '#FFF',
            width: 130,
            padding: 15,
            align: 'center'
        }));

        rectangle.on('dragend', function(e){
            //console.log('AA', self.props, self.state);
            //console.log('ASAA', e.target.x());
            
            if (self.props.type === 'personal' ){
                return;
            }
            let x = e.target.x();
            let y = e.target.y();
            console.log('dragend', x, y, e);
            console.log('HA', self.refs[`recLayer_${self.props.type}_${self.props.group_id}`]);

            console.log('the text of the dragged item', e.target.children[1].attrs.text);
            let theText = e.target.children[1].attrs.text;

            let path = `active_game.results.${self.props.group_id}.sandbox.${theText}`;
            let val = {
                x : x,
                y : y
            }
            self.props.editResultsPath(path, val, self.props.host);
        })
        rectangle.on('click', function(){
            console.log('HERE');
        });
        rectangle.on('mouseenter', function(e){
            const container = e.target.getStage().container();
            container.style.cursor = "pointer";
        });
        rectangle.on('mouseleave', function(e){
            const container = e.target.getStage().container();
            container.style.cursor = "default";
        });
        this.refs[`recLayer_${this.props.type}_${this.props.group_id}`].add(rectangle);
        
        //this.refs.konvastage.add(rectangleLayer);
    }




    render() {
        const { classes, type } = this.props;
        console.log('RESULTS', this.props.results);
        return (
            <div>

                <div style={{height: "700px", width: this.props.width}} id="white-board">
                    <div className="whiteboard-base-text">{this.props.type} Sandbox</div>
                        <Stage width={this.props.width} height={700} ref={`konvastage_${type}_${this.props.group_id}`}>
                            <Layer ref={`recLayer_${type}_${this.props.group_id}`}>
                            
                            </Layer>
                        </Stage>
                </div>
               
            </div>
        ); 
    
    }
}



const mapStateToProps = (state) => {
    //console.log('this is my state', state);
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
        updateGroupStateParamsVal : (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID)),
        updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID))
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(WordPlayground);
