import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { DropzoneDialog } from 'material-ui-dropzone';
import Typography from '@material-ui/core/Typography';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import Button from '@material-ui/core/Button';
import { getFirebase } from 'react-redux-firebase';

const ImageFileList = ({ files }) => {
    const filenames = files.map((f) => {
      return f.name;
    });
  
    if ( files.length === 0 ){
        return null;
    }
    return (
      <p>
        <span style={{fontWeight:"bold"}}>New Image:</span> {filenames}
      </p>
    );
  };


/*


    async componentDidMount(){
        
    }

    componentDidUpdate(prevProps, prevState){
      if ( !this.props.options.hasOwnProperty(this.props.auth.uid) ){
        this.saveOptions(false);
      }
      if ( this.props.game_state.newImage !== false ){
        const sto = getFirebase().storage();        
        let ref = sto.ref(this.props.game_state.newImage);
        ref.getDownloadURL().then((url) => {
            this.props.doneUpdatingImage();
            this.setState({
                ...this.state,
                logoURLs: {
                  ...this.state.logoURLs,
                  main: url
                },
                newImages: {}
            })
        });
      }
    }
*/

export default function GameImage(props) {

    const [imageURL, setImageURL] = React.useState('');
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const [imageOpen, setImageOpen] = React.useState(false);
    const [newImage, setNewImage] = React.useState('');

    const imageChanged = (files, key) => {
      setImageOpen(false);
      props.saveActivityOptionSetFile(props.hostID, key, files );
    }

    const handleImageOpen = () => {
      setImageOpen(true);
    }

    const handleImageClose = () => {
      setImageOpen(false);
    }


    React.useEffect(() => {
        async function getLogos() {
            let logos = {};
            const sto = getFirebase().storage();
            console.log(sto, props.o, props.oKey);
            let ref = sto.ref(props.o[props.oKey]);
            let url = await ref.getDownloadURL();
            await setImageURL(url);
        }
        getLogos().then(() => {
            setImageLoaded(true);
        });
    },[]);

    React.useEffect(() => {
      async function getLogos() {
            let logos = {};
            const sto = getFirebase().storage();
            let ref = sto.ref(props.o[props.oKey]);
            let url = await ref.getDownloadURL();
            await setImageURL(url);
        }
        getLogos().then(() => {
            setImageLoaded(true);
        });
    },[props.o[props.oKey]]);


    return(<Grid container spacing={2} key={props.ind}>
        <Grid item xs={12}>
            <Typography variant="h6" style={{fontWeight: "bold"}}>{props.oKey}</Typography>
        { imageURL === '' ? null : 
        <img src={imageURL} alt='Game Logos' style={{maxWidth: "200px", maxHeight: "200px", display: "block"}}/> 
        }
        <Button disabled={props.disabled} variant='contained' color='secondary' onClick={handleImageOpen} style={{marginTop: "10px"}}>
            Upload New Image
        </Button>     

        <DropzoneDialog name='image' open={imageOpen} onSave={(files) => imageChanged(files, props.oKey)} onClose={handleImageClose} filesLimit={1} onChange={(files) => imageChanged(files, props.oKey)} showPreviews={true} id='image' label="label" />
        
        { newImage !== undefined ? newImage !== undefined ? <ImageFileList files={ newImage === '' ? [] : [newImage]}/> : null : null }
        </Grid>  
    </Grid>)
}