import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { deepDiffMapper, determineHost } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import { CardList } from '../cards/cardList';
import trustIcon from '../images/Trust-Spectrum-Logo.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
  import { PDFHeader } from './../../../../PastData/PDFHeader';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  
  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }


const INITIAL_STATE = {
    open: false,
    tab: 0,
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

  

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleTabChange = (event, newValue) => {
   
        this.setState({
            ...this.state,
            tab: newValue
        })
    };


    render() {
    const {
      
      classes,
      isPDFView,
      results
    } = this.props;
    const { diff } = this.state;


    if(this.props.isPDFView === true){
        return (
            <Document>
                 <Page wrap style={{padding: "30 0"}}>
                    <PDFHeader/>
                    <View>
                        <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={trustIcon}/>
                        {Object.keys(results.results)
                        .sort()
                        .map((groupKey, ind) => {
                            let res = results.results[groupKey] === undefined ? {} : results.results[groupKey];

                            let res1 = res[1] === undefined ? [] : res[1];
                            let res2 = res[2] === undefined ? [] : res[2];
                            let res3 = res[3] === undefined ? [] : res[3];
                            let res4 = res[4] === undefined ? [] : res[4];
                            let res5 = res[5] === undefined ? [] : res[5];
                        return (
                        <React.Fragment key={ind}>
                            <View style={{marginHorizontal: "50", marginBottom: "50"}} >
                                <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>

                                <View style={{padding: "8px", backgroundColor: "#2e75b5"}} wrap={false}>
                                    <Text style={{color:'#fff', fontSize: '12px'}}>5 (Always)</Text>
                                </View>
                                <View style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}} wrap={false}>
                                    { 
                                        res5.length > 0 ?
                                            res5.map((card, ind) => {
                                                let the_card = CardList.filter(v => v.id === card )[0];
                                                return (
                                                    <React.Fragment key={ind}>
                                                        <Text style={{fontWeight: "bold", fontSize: '10px', marginBottom: "5"}}>Trust Card #{card}</Text>
                                                        <Text style={{ fontSize: '11px', marginBottom: "10"}}>{the_card.card_words}</Text>
                                                    </React.Fragment>
                                                )
                                            })
                                        :
                                        <Text style={{ fontSize: '11px', marginBottom: "10"}}>No items to display.</Text>
                                    }
                                </View>

                                <View style={{padding: "8px", backgroundColor: "#51c3a1"}} wrap={false}>
                                    <Text style={{color:'#fff'}}>4</Text>
                                </View>
                                <View style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}} wrap={false}>
                                    { 
                                        res4.length > 0 ?
                                            res4.map((card, ind) => {
                                                let the_card = CardList.filter(v => v.id === card )[0];
                                                return (
                                                    <React.Fragment key={ind}>
                                                        <Text style={{fontWeight: "bold", fontSize: '10px', marginBottom: "5"}}>Trust Card #{card}</Text>
                                                        <Text style={{ fontSize: '11px', marginBottom: "10"}}>{the_card.card_words}</Text>
                                                    </React.Fragment>
                                                )
                                            })
                                        :
                                        <Text style={{ fontSize: '11px', marginBottom: "10"}}>No items to display.</Text>
                                    }
                                </View>

                                        <View style={{padding: "8px", backgroundColor: "#fff100"}} wrap={false}>
                                            <Text style={{color:'#fff', fontSize: '12px'}}>3 (Sometimes)</Text>
                                        </View>
                                        <View style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}} wrap={false}>
                                            { 
                                                res3.length > 0 ?
                                                    res3.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <React.Fragment key={ind} >
                                                                <Text style={{fontWeight: "bold", fontSize: '10px', marginBottom: "5"}}>Trust Card #{card}</Text>
                                                                <Text style={{ fontSize: '11px', marginBottom: "10"}}>{the_card.card_words}</Text>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                :
                                                <Text style={{ fontSize: '11px', marginBottom: "10"}}>No items to display.</Text>
                                            }
                                        </View>
                                        
                                        <View style={{padding: "8px", backgroundColor: "#f78f19"}} wrap={false}>
                                            <Text style={{color:'#fff', fontSize: '12px'}}>2</Text>
                                        </View>
                                        <View style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}} wrap={false}>
                                            { 
                                                res2.length > 0 ?
                                                    res2.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <React.Fragment key={ind} >
                                                                <Text style={{fontWeight: "bold", fontSize: '10px', marginBottom: "5"}}>Trust Card #{card}</Text>
                                                                <Text style={{ fontSize: '11px', marginBottom: "10"}}>{the_card.card_words}</Text>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                :
                                                <Text style={{ fontSize: '11px', marginBottom: "10"}}>No items to display.</Text>
                                            }
                                        </View>

                                        <View style={{padding: "8px", backgroundColor: "#f14a24"}} wrap={false}>
                                            <Text style={{color:'#fff', fontSize: '12px'}}>1 (Never)</Text>
                                        </View>
                                        <View style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}} wrap={false}>
                                            { 
                                                res1.length > 0 ?
                                                    res1.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <React.Fragment key={ind} >
                                                                <Text style={{fontWeight: "bold", fontSize: '10px', marginBottom: "5"}}>Trust Card #{card}</Text>
                                                                <Text style={{ fontSize: '11px', marginBottom: "10"}}>{the_card.card_words}</Text>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                :
                                                <Text style={{ fontSize: '11px', marginBottom: "10"}}>No items to display.</Text>
                                            }
                                        </View>                                  
                                
                            </View> 
                        </React.Fragment>       
                        );
                    })}
                    </View>
                </Page>
            </Document> 
        ) 
    }

        return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">{results.name}</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                
                        { Object.keys(results.groups).sort().map((groupKey, ind) => {
                            let res = results.results[groupKey] === undefined ? {} : results.results[groupKey];

                            let res1 = res[1] === undefined ? [] : res[1];
                            let res2 = res[2] === undefined ? [] : res[2];
                            let res3 = res[3] === undefined ? [] : res[3];
                            let res4 = res[4] === undefined ? [] : res[4];
                            let res5 = res[5] === undefined ? [] : res[5];

                            if ( groupKey !== 'group-0' ){
                                
                               
                                return (
                                    <Grid container key={ind}>
                                    <Grid item xs={12} sm={12} className={classes.spacingTop}>
                                    <Typography variant="h3" className={classes.spacingTop}>{results.groups[groupKey].title}</Typography>
                                        <div style={{padding: "8px", color: "#fff", background: "#2e75b5"}}><Typography variant="h5">5 (Always)</Typography></div>
                                        <div style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}}>
                                            { 
                                                res5.length > 0 ?
                                                    res5.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <div key={ind} style={{marginBottom:"15px"}}>
                                                                <div style={{fontWeight: "bold"}}>Trust Card #{card}</div>
                                                                <div><Typography variant="body1">{the_card.card_words}</Typography></div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div>No items to display.</div>
                                            }
                                        </div>

                                        <div style={{padding: "8px", color: "#fff", background: "#51c3a1"}}><Typography variant="h5">4</Typography></div>
                                        <div style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}}>
                                            { 
                                                res4.length > 0 ?
                                                    res4.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <div key={ind} style={{marginBottom:"15px"}}>
                                                                <div style={{fontWeight: "bold"}}>Trust Card #{card}</div>
                                                                <div><Typography variant="body1">{the_card.card_words}</Typography></div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div>No items to display.</div>
                                            }
                                        </div>

                                        <div style={{padding: "8px", color: "#fff", background: "#fff100"}}><Typography variant="h5">3 (Sometimes)</Typography></div>
                                        <div style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}}>
                                            { 
                                                res3.length > 0 ?
                                                    res3.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <div key={ind} style={{marginBottom:"15px"}}>
                                                                <div style={{fontWeight: "bold"}}>Trust Card #{card}</div>
                                                                <div><Typography variant="body1">{the_card.card_words}</Typography></div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div>No items to display.</div>
                                            }
                                        </div>
                                        
                                        <div style={{padding: "8px", color: "#fff", background: "#f78f19"}}><Typography variant="h5">2</Typography></div>
                                        <div style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}}>
                                            { 
                                                res2.length > 0 ?
                                                    res2.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <div key={ind} style={{marginBottom:"15px"}}>
                                                                <div style={{fontWeight: "bold"}}>Trust Card #{card}</div>
                                                                <div><Typography variant="body1">{the_card.card_words}</Typography></div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div>No items to display.</div>
                                            }
                                        </div>

                                        <div style={{padding: "8px", color: "#fff", background: "#f14a24"}}><Typography variant="h5">1 (Never)</Typography></div>
                                        <div style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}}>
                                            { 
                                                res1.length > 0 ?
                                                    res1.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <div key={ind} style={{marginBottom:"15px"}}>
                                                                <div style={{fontWeight: "bold"}}>Trust Card #{card}</div>
                                                                <div><Typography variant="body1">{the_card.card_words}</Typography></div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div>No items to display.</div>
                                            }
                                        </div>
                                    </Grid></Grid>
                                )
                            } else {
                                return null;
                            }
                            
                        })}                    
                    
                </Grid>

            </Grid>
        </div>
        )
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
