import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import { updateGroupStateParamsVal, editResultsPath, updateGroupStateStateVal, removeResultsPath, updateResultsPath } from '../../../../../store/actions/gameActions';
//import { createPiecesGroup } from './../utils/createPiecesGroup';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
    //GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
    //HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE,
    GROUP_STATE_RESULTS, GROUP_STATE_ACTIVE, GAME_STATE_RESULTS, removeExactItemFromArrayResultsPath
  } from '../../../../../store/actions/gameActions';
  
  import Drawer from '@material-ui/core/Drawer';
  import ListItem from '@material-ui/core/ListItem';
  import List from '@material-ui/core/List';
  import ChevronRightIcon from '@material-ui/icons/ChevronRight';
  import IconButton from '@material-ui/core/IconButton';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExtensionIcon from '@material-ui/icons/Extension';
import { pieces } from './../data/data';
import AddIcon from '@material-ui/icons/Add';
import bbss_logo from './../images/bbss_logo.png';
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


import WordPlayground from './wordPlayground';


import styles from '../../../../Styles';

//import { wordGroups } from './../data/data';




const INITIAL_STATE = {
    faded: true,
    expanded: true,
    otherExpanded: true
}

class Suggestions extends Component {
    constructor(props) {
        super(props);
        //this.draggedEl = React.createRef();
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount(){
        let resultsFiltered = {};
        Object.keys(this.props.results).map((oid, ind) => {
            if ( this.props.results[oid].vetted === false ){
                resultsFiltered[oid] = this.props.results[oid];
            }
            return null;
        });
        if ( Object.keys(resultsFiltered).length > 0 ){
            this.setState({
                ...this.state,
                faded: false,
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        let resultsFiltered = {};
        Object.keys(this.props.results).map((oid, ind) => {
            if ( this.props.results[oid].vetted === false ){
                resultsFiltered[oid] = this.props.results[oid];
            }
            return null;
        });
        let prevResultsFiltered = {};
        Object.keys(prevProps.results).map((oid, ind) => {
            if ( prevProps.results[oid].vetted === false ){
                prevResultsFiltered[oid] = prevProps.results[oid];
            }
            return null;
        });
        if ( (Object.keys(prevResultsFiltered).length !== Object.keys(resultsFiltered).length) && Object.keys(resultsFiltered).length > 0 ){
            if ( this.state.faded === true ){
                console.log('setting back', Object.keys(prevResultsFiltered).length, Object.keys(resultsFiltered).length, prevProps.results, this.props.results);
                this.setState({
                    ...this.state,
                    faded: false
                });
            }
        }
    }


    dragStart = (ev) => {
        let w = ev.target.attributes.pwidth.value;
        let h = ev.target.attributes.pheight.value;            
        
        ev.dataTransfer.setData("text", ev.target.id);
        ev.dataTransfer.setDragImage(ev.target, 10,10);

        let r = {
            src: ev.target.attributes.src.value,
            id: ev.target.attributes.piece.value,
            width: w,
            height: h
        };
        //console.log('O: ', r);

        this.props.assignDragEl(r);

        //console.log(this.draggedEl.current);


        //console.log('piece', ev.target.attributes.piece.value,);
        //console.log('piece size', ev.target.attributes.pwidth.value);
        this.props.updateDragState(true);
    }
    clearSuggestion = (suggestion) => {
        let path = `active_game.results.${this.props.group_id}.suggestions.${suggestion.suggestId}`
        let s = {
            ...suggestion,
            vetted: true
        }        
        console.log('setting');
        this.setState({
            ...this.state,
            faded: true
        });
        setTimeout(() => {
            this.props.editResultsPath(path, s, this.props.host);
        },1000);
    }

    expandCheck = () => {
        this.setState({
            ...this.state,
            expanded: this.state.expanded === true ? false : true
        })
    }
    expandOtherCheck = () => {
        this.setState({
            ...this.state,
            otherExpanded: this.state.otherExpanded === true ? false : true
        })
    }
   
    render() {
        const { classes, session, control, group_id, profile, host, auth, results } = this.props;

        let resultsFiltered = [];
        Object.keys(results).map((oid, ind) => {
            if ( results[oid].vetted === false ){
                resultsFiltered.push(results[oid]);
            }
            return null;
        });
        resultsFiltered.sort(function(a, b) {
            return a.added - b.added;
        });
        //console.log('SUGGESTIONS', Object.keys(results).length);
        
        /*if ( !control && results.length > 0 ){
            let mySuggestions = 0;
            results.forEach(suggest => {
                console.log('UM', suggest);
                if ( suggest.suggestedBy === auth.uid ){
                    mySuggestions += 1;
                }
            });
            if ( mySuggestions > 0 ){
                return (
                    <div className={classes.suggestionWindow} style={{padding: "20px",}}>
                        You are currently suggesting <span style={{fontWeight: "bold", fontSize: "1.2rem"}}>{mySuggestions}</span> pieces.
                    </div>
                )
            } else {
                return null;
            }
        }

        if ( !control ){
            return null;
        }*/


        let suggestion = {};
        let playerSuggested = {};
        if ( Object.keys(resultsFiltered).length > 0 ){
            suggestion = resultsFiltered[0];
            playerSuggested = session.playerProfiles[suggestion.suggestedBy] === undefined ? 'N/A' : session.playerProfiles[suggestion.suggestedBy].displayName;
        }

 
        return (
            <div>
                <div className={classes.suggestionContainer}>
                    <div className={`${classes.suggestionWindow} ${this.state.faded === true ? classes.suggestionFaded: null}`}>
                        { 
                            resultsFiltered.length > 0 ? 
                            <div>
                                <div style={{width:"100%", background: "#3f51b5", padding: "10px", color: "#fff", fontFamily: 'Permanent Marker'}}>
                                    SUGGESTION 1 of {resultsFiltered.length}
                                    { control ? <div style={{float: 'right', padding: "0 5px", cursor: "pointer"}} onClick={() => this.clearSuggestion(suggestion)}>X</div> : null }<div style={{float: 'right', padding: "0 5px", cursor: "pointer"}}>{this.state.otherExpanded ? <ExpandLessIcon onClick={this.expandOtherCheck}/> : <ExpandMoreIcon onClick={this.expandOtherCheck}/>}</div>
                                </div>
                                <div style={{height: this.state.otherExpanded ? '300px' : '0px', transition: "height 0.3s"}}>
                                    <div style={{padding: "20px"}}>
                                        <div style={{textAlign: 'center'}}><span style={{fontWeight: "bold", fontSize: "1.2rem"}}>{playerSuggested}</span>:</div>
                                        <div style={{textAlign: 'center'}}>{suggestion.text}</div>
                                        { control ?
                                        <img alt="" draggable="true" style={{width: suggestion.previewSize.width, maxHeight: "150px", maxWidth: "90%", margin: "15px auto", display: this.state.otherExpanded ? "block" : "none"}} piece={suggestion.id} pwidth={suggestion.size.width} pheight={suggestion.size.height} 
                                                        onDragStart={(ev) => this.dragStart(ev)} src={suggestion.image} onDragEnd={(ev) => this.clearSuggestion(suggestion)}/>
                                        :
                                        <img alt="" draggable="false" src={suggestion.image} style={{width: suggestion.previewSize.width, maxHeight: "150px", maxWidth: "90%", margin: "15px auto", display: this.state.otherExpanded ? "block" : "none"}} piece={suggestion.id} pwidth={suggestion.size.width} pheight={suggestion.size.height} 
                                                    />
                                        }
                                        { control ? <div style={{textAlign: 'center', fontSize: "0.8rem"}}>Drag the image onto the canvas, or hit the x above to close.</div> : null }
                                    </div>
                                </div>
                            </div>
                            : null
                        }

                    </div>
                </div>

                <div className={classes.suggestionContainerLeft}>
                    { 
                        Object.keys(results).length > 1 ?
                            <div className={`${classes.suggestionWindowAlt}`}>
                                
                                <div style={{background: "#3f51b5", color: "#fff", padding: "10px 15px", fontFamily: 'Permanent Marker'}}>LIST OF SUGGESTIONS ({Object.keys(results).length})
                                <div style={{float: 'right', padding: "0 5px", cursor: "pointer"}}>{this.state.expanded ? <ExpandLessIcon onClick={this.expandCheck}/> : <ExpandMoreIcon onClick={this.expandCheck}/>}</div></div>
                                    <div style={{ maxHeight: "50vh", overflow: "auto", width: "100%", height: this.state.expanded ? '50vh' : '0px'}} className={classes.suggestionBrowser}>
                                    <div style={{padding: "20px"}}>
                                    { Object.keys(results).sort(function(a, b) {
                                            return results[a].added - results[b].added;
                                        }).map((res, ind) => {
                                        if ( !resultsFiltered.hasOwnProperty(res) ){
                                            let s = results[res];
                                            return (
                                                <div key={ind} style={{marginBottom: "5px", border: "1px solid #000", padding: "10px", borderRadius: "5px"}}>
                                                    <img alt="" draggable="false" src={s.image} style={{maxHeight: "25px", maxWidth: "25px", margin: "10px 5px 0 0", display: "block", float: 'right'}}  />
                                                    <div style={{fontWeight: "bold"}}>{session.playerProfiles[s.suggestedBy] === undefined ? 'N/A' : session.playerProfiles[s.suggestedBy].displayName}</div>
                                                    <div style={{fontStyle: "italic"}}> {s.text}</div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })} 
                                    </div>
                                </div>
                            </div>
                        : null
                    }
                </div>
            </div>
        ); 
    
    }
}



const mapStateToProps = (state) => {
    //console.log('this is my state', state);
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
        editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID))
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Suggestions);
