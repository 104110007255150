import paper from "paper/dist/paper-core"
import { SCRAMBLE_PADDING } from "./constants"

export const scrambleGroup = (group, canvas, updateCoords) => {
  
  const maxPoint = new paper.Point(
    paper.project.view.bounds.width,
    paper.project.view.bounds.height
  ).subtract(SCRAMBLE_PADDING * 2)

  group.position = paper.Point.random()
    .multiply(maxPoint)
    .add(SCRAMBLE_PADDING)

  if (group.data.id === "rh") {
    const rotation = Math.round(Math.random() * 3) * 45
    group.rotation = rotation
    group.data.rotation = rotation
    group.rotationReadable = rotation;
    group.initialRotation = rotation;
  } else {
    let r = Math.round(Math.random() * 7) * 45;
    group.rotation = group.rotation + r
    group.rotationReadable = r;
    group.initialRotation = r;
  }
  updateCoords(group.data.id, group.position.x, group.position.y, group.rotationReadable, group.rotationReadable);
}



export const scrambleGroupInit = (group) => {
  
  const maxPoint = new paper.Point(
    paper.project.view.bounds.width,
    paper.project.view.bounds.height
  ).subtract(SCRAMBLE_PADDING * 2)

  group.position = paper.Point.random()
    .multiply(maxPoint)
    .add(SCRAMBLE_PADDING)

  if (group.data.id === "rh") {
    const rotation = Math.round(Math.random() * 3) * 45
    group.rotation = rotation
    group.data.rotation = rotation
    group.rotationReadable = rotation;
    group.initialRotation = rotation;
  } else {
    let r = Math.round(Math.random() * 7) * 45;
    group.rotation = r
    group.rotationReadable = r;
    group.initialRotation = r;
  }
  return { x: group.position.x, y: group.position.y, rotation: group.rotationReadable};
}