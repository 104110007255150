import React, { Component } from 'react';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import * as ROUTES from '../../constants/routes';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Styles';
//New sign in method
import { connect } from 'react-redux';
import { signIn, signInGoogle, closeAuthPopUps } from '../../store/actions/authActions';
import { addPlannedSession, updatePlannedSession, removePlannedSession } from '../../store/actions/adminActions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ImageHeader from '../Header/imageHeader';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Footer from '../Footer';
//import GoogleLogo from '../../images/icons/GoogleLogo';
//import FacebookIcon from '@material-ui/icons/Facebook';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import { PlannedSession } from './comp_session';
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
    TableBody, TableCell, Collapse, TableHead, TableRow, Box, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CSVReader from './file_reader';
import LiquidLoadingBlockModal from '../Loading/loadingModal';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Tooltip from "@material-ui/core/Tooltip";
import PersonIcon from '@material-ui/icons/Person';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import EditSession from './comp_edit_session';
import AddSession from './comp_add_session';
import PlayerEditor from './comp_player';
import GroupEditor from './comp_groups';
import ContactsIcon from '@material-ui/icons/Contacts';


const INITIAL_STATE = {
    formFilledOut: false,
    deleteDialogOpen: false,
    deleteSessionId: false,
    dialogOpen: false,
    sessionTitle: '',
    sessionDate: '',
    csvData: false,
    csvMessage: false,
    selectedSession: false,
    editor: false,
};

class SessionPlanningPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
        ...INITIAL_STATE,
        selectedHost: props.profile.role === 'host' ? props.auth.uid : false
        };

        
    }

    addSession = (title, date, players) => {
        //const players = this.state.csvData === false ? [] : this.state.csvData;
        
        this.props.addPlannedSession(title, date, players, this.props.host);
        this.setState({
            ...this.state,
            dialogOpen: false
        });
    }
    closeSessionAdder = () => {
        this.setState({
            ...this.state,
            dialogOpen: false
        })
    }

    openGroupEditor = () => {

    }
    openPlayerEditor = () => {

    }
    openSessionEditor = (session) => {
        //console.log('session', session);
        this.setState({
            ...this.state,
            selectedSession: session
        })
    }
    deleteSession = (id) => {
        this.setState({
            ...this.state,
            deleteDialogOpen: true,
            deleteSessionId: id
        })
    }
    confirmDeleteSession = () => {
        const id = this.state.deleteSessionId;
        this.props.removePlannedSession(id);
        this.setState({
            ...this.state,
            deleteDialogOpen: false,
            deleteSessionId: false
        })
    }

    closeEditSession = () => {
        this.setState({
            ...this.state,
            selectedSession: false
        })
    }
    saveEditSession = (sessionID, sessionName, sessionDate) => {
        this.props.updatePlannedSession(sessionID, sessionName, sessionDate);
        this.closeEditSession();
    }
    setEditor = (session, val) => {
        //console.log('opening groups', session, this.props.planned_sessions_manip[session.id]);
        this.setState({
            ...this.state,
            editor: val,
            selectedSession: session
        })
    }


    render(){
        const { planned_sessions, classes } = this.props;
        const { formFilledOut, selectedHost, dialogOpen, deleteDialogOpen } = this.state;

        let upcoming_sessions = [];
        let past_sessions = [];

        if ( planned_sessions !== undefined ){
            if ( planned_sessions.length > 0 ){
                //let today = moment();
                planned_sessions.forEach((ps, ind) => {
                    if ( moment().isBefore(ps.date) ){
                        upcoming_sessions.push(ps);
                    }
                    if ( moment().isAfter(ps.date) ){
                        past_sessions.push(ps);
                    }
                });
            }            
        }

        const today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        

        if ( this.state.editor === 'group' ){
            return <div>
                <Button variant='contained' color='primary' onClick={() => this.setState({...this.state, editor: false, selectedSession: false})} style={{marginBottom: "40px"}}>
                    Return to Session List
                </Button>
                <Typography variant="h6">Sesssion: {this.state.selectedSession.title} ({moment(this.state.selectedSession.date).format('LLLL')})</Typography>
                <GroupEditor session={this.state.selectedSession}/>
            </div>
        }

        if ( this.state.editor === 'player' ){
            let title = this.state.selectedSession.title;
            let d = this.state.selectedSession.date;
            if ( this.state.selectedSession.is_planned === true ){
                // is a current session
                title = this.state.selectedSession.sessionName;
                d = false;
            }
            return <div>
                <Button variant='contained' color='primary' onClick={() => this.setState({...this.state, editor: false, selectedSession: false})} style={{marginBottom: "40px"}}>
                    Return to Session List
                </Button>
                <Typography variant="h6">Session: {title} ({d === false ? `Current` : moment(this.state.selectedSession.date).format('LLLL')})</Typography>
                <PlayerEditor session={this.state.selectedSession}/>
            </div>
        }

        return (
            <div>
                <Button variant='contained' color='primary' onClick={() => this.setState({...this.state, dialogOpen: true})} style={{marginBottom: "40px"}}>
                    Add Session
                </Button>  
            { 
                this.props.isLoading ?
                    <LiquidLoadingBlockModal/>
                : 
                null
            }

            {
                this.props.current_session !== undefined ?
                    this.props.current_session.length > 0 ?
                    <React.Fragment>
                        <Typography variant="h5">Current Session</Typography>
                        <List>
                        <ListItem>
                                <ListItemAvatar>
                                    <Tooltip title={this.props.current_session[0].players.length} aria-label="number-of-players">
                                        <Badge showZero badgeContent={this.props.current_session[0].players.length} color="primary">
                                            <PersonIcon />
                                        </Badge>
                                    </Tooltip>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={this.props.current_session[0].sessionName}
                                    secondary={
                                        <React.Fragment>
                                            <Typography variant="body1" component="span" style={{display:'inline'}}>https://virtual.glassoflearning.com/p/{this.props.current_session[0].url}</Typography>
                                        </React.Fragment>
                                    }
                                />

                        { this.props.current_session[0].is_planned === true ? 
                        <ListItemSecondaryAction>                            
                            <Tooltip title="View Players" aria-label="edit-players" onClick={() => this.setEditor(this.props.current_session[0], 'player')}>
                                <IconButton edge="end" style={{marginRight: 5}} aria-label="update-users">
                                    <ContactsIcon />
                                </IconButton>
                            </Tooltip>                            
                        </ListItemSecondaryAction>   
                        : null }    
                        </ListItem>    
                        </List>
                    </React.Fragment>
                    : null
                : null
            }

            { planned_sessions === undefined ?
                <Typography variant="body1">No planned sessions to display.</Typography>
                : null
            }

            { upcoming_sessions.length > 0 ?
            <React.Fragment>
                <Typography variant="h5">Upcoming Sessions</Typography>
                <List>
                {
                    upcoming_sessions.sort((a,b) => { if ( new Date(a.date).getTime() < new Date(b.date).getTime() ){ return -1; } if ( new Date(a.date).getTime() > new Date(b.date).getTime() ){ return 1; } }).map((session, ind) => {            
                        return (
                            <PlannedSession deleteSession={this.deleteSession} openPlayerEditor={this.setEditor} openGroupEditor={this.setEditor} openSessionEditor={this.openSessionEditor} session={session} profile={this.props.profile} auth={this.props.auth} ind={ind} key={ind}/>
                        );
                    })                   
                }
                </List>
            </React.Fragment>
            : null }

            { past_sessions.length > 0 ?
            <React.Fragment>
                <Typography variant="h5">Past Sessions ({past_sessions.length})</Typography>               
            </React.Fragment>
            : null }

                    

                <Dialog aria-labelledby='add-planned-session' aria-describedby='Add Planned Session'  maxWidth='md'  open={deleteDialogOpen} fullWidth>
                    <AppBar position="static" className={classes.LNNoShadow}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={()=>this.setState({...this.state,deleteDialogOpen: false})} aria-label="close">
                            <CloseIcon />
                            </IconButton>
                                <Typography variant="h6">
                                Remove Session             
                                </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>

                        <DialogContentText style={{paddingTop: 30}}>Are you sure you want to remove this session? This action cannot be undone.</DialogContentText>                        
                        
                    </DialogContent> 
                    <DialogActions>
                        <Button size='large'  onClick={()=>this.setState({...this.state,deleteDialogOpen: false})}>
                            Cancel
                        </Button>
                        <Button size='large'  onClick={()=>this.confirmDeleteSession()}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <AddSession classes={classes} showAddSession={this.state.dialogOpen} closeAddSession={this.closeSessionAdder} saveAddSession={this.addSession}/>
                <EditSession classes={classes} showEditSession={this.state.selectedSession === false ? false : true} session={this.state.selectedSession} closeEditSession={this.closeEditSession} saveEditSession={this.saveEditSession}/>


            </div>
            
        
        )

    }

  
}


const mapStateToProps = (state) => {
    return {
        planned_sessions: state.firestore.ordered.planned_sessions,
        current_session: state.firestore.ordered.current_sessions,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        isLoading: state.admin_state.adminLoading
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        addPlannedSession: (sessionTitle, sessionDate, players, hostID) => dispatch(addPlannedSession(sessionTitle, sessionDate, players, hostID)),
        updatePlannedSession: (sessionDoc, sessionName, sessionDate) => dispatch(updatePlannedSession(sessionDoc, sessionName, sessionDate)),
        removePlannedSession: (sessionDoc) => dispatch(removePlannedSession(sessionDoc))
    };
  };
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    firestoreConnect((props) => {
      if ( props.auth.uid === undefined ){
        return [];
      }
  
      if ( props.host === undefined ){
          return [];
      }
      return [
          {
            collection: 'planned_sessions',
            where: ['host', '==', props.host]
          },
          { 
            collection: 'current_sessions',
            where: ['host', '==', props.host]
          }
      ]
       
      
      
    })
  )(SessionPlanningPage);