import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import set_expectations from './images/set_expectations.png';
import Accountability_Sign from './images/Accountability_Sign.png';
import { GROUP_STATE_RESULTS } from '../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  hasGameControl,
  getUserGroup_hc,
  determineHost
} from '../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults } from './../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingDots from './../game_components/loadingDots';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import styles from '../../../Styles';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import AddIcon from '@material-ui/icons/Add';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const INITIAL_STATE = {
  open: false,
  circle: null,
  entry_text: '',
  isEdit: false,
};
export const EXPECTATIONS = 'Expectations';
export const ACCOUNTABILITIES = 'Accountabilities';

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate() {}

  handleClose = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  handleSave = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    this.props.updateResults(groupID, this.state.circle, this.state.entry_text, determineHost(this.props.profile, this.props.auth));

    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
    });
  };

  clickExpectations = (event) => {
    this.setState({
      ...this.state,
      entry_text: '',
      open: true,
      circle: EXPECTATIONS,
    });

    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: EXPECTATIONS,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  clickAccountabilities = (event) => {
    this.setState({
      ...this.state,
      entry_text: '',
      open: true,
      circle: ACCOUNTABILITIES,
    });

    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: ACCOUNTABILITIES,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  itemAction = (index, value, circle) => {
    this.setState({
      ...this.state,
      isEdit: index,
      open: true,
      circle: circle,
      entry_text: value,
    });
  };

  updateRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.circle]];
    c[row] = this.state.entry_text;

    this.props.editResults(groupID, this.state.circle, c, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  deleteRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.circle]];
    c.splice(row, 1);

    this.props.removeResults(groupID, this.state.circle, c, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  finishActivity = () => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_RESULTS,
      params: {},
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      session,
      profile,
      auth,
      classes,
    } = this.props;
    const { open, entry_text } = this.state;
    const isInvalid = entry_text === '';

    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    if ( session.active_game.groupStates[group_id].params.phase === ACCOUNTABILITIES ){
      return (
        <div>
          <Typography variant='h4' className={classes.spaceBottom}>{session.active_game.name}</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div className={classes.imgContainer}>
                <img src={Accountability_Sign} alt='instructions' className={classes.heightLimitedImage} />
              </div>
              <Dialog open={open} onClose={this.handleClose} aria-labelledby="draggable-dialog-title" PaperComponent={PaperComponent}>
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Accountability
              </DialogTitle>
                <DialogContent>
                  <DialogContentText className={classes.bodyText}>{this.state.isEdit !== false ? `Editing an Accountability` : `What is your Accountability? Type your entry below and hit save.`}</DialogContentText>
                  <TextField autoFocus autoComplete="off" margin='dense' id='entry_text' name='entry_text' value={entry_text} onChange={this.onChange} label="Accountability:" type='email' fullWidth />
                </DialogContent>
                <DialogActions>
                  {this.state.isEdit !== false ? (
                    <div>
                      <Button onClick={() => this.deleteRow(this.state.isEdit)} color='secondary'>
                        Delete
                      </Button>
                      <Button onClick={this.handleClose} color='primary'>
                        Cancel
                      </Button>
                      <Button onClick={() => this.updateRow(this.state.isEdit)} color='primary'>
                        Save
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button onClick={this.handleClose} color='primary'>
                        Cancel
                      </Button>
                      <Button disabled={isInvalid} onClick={this.handleSave} color='primary'>
                        Save
                      </Button>
                    </div>
                  )}
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Typography variant='h6' className={`${classes.boldedText}`}>Demonstrate Fostering Accountability:</Typography>
                    <ol className={classes.bodyText}>
                      <li>Clear Expectations</li>
                      <li>Clear Capability (skills required)</li>
                      <li>Clear Measurement</li>
                      <li>Clear Feedback</li>
                      <li>Clear Consequences</li>
                    </ol>
                    <Typography variant="body1" className={classes.spacingBottom}>Take the task you set expectations for.<br/><br/>What will you do at each step listed here to hold the person accountable? Number and list each of the steps.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                  <Grid item xs={6}>
                  <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Accountabilities:</Typography>
                  </Grid>
                  <Grid item xs={6} align="right">
                    { hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile) ?
                    <Button type='submit' variant="contained"  onClick={() => this.clickAccountabilities()} startIcon={<AddIcon/>} className={classes.buttonMargin}>
                      Add Accountability
                    </Button>
                    : null }
                  </Grid>
                <Grid item xs={12} sm={12}>
                  {/* Results Here */}
                  <List className={classes.listClass}>
                    {session.active_game.results.hasOwnProperty(group_id)
                      ? session.active_game.results[group_id].hasOwnProperty(ACCOUNTABILITIES)
                        ? session.active_game.results[group_id][ACCOUNTABILITIES].map((item, index) => {
                            return (
                              hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile) ?
                              <ListItem key={index} button onClick={() => this.itemAction(index, item, ACCOUNTABILITIES)}>
                                <ListItemText primary={item} className={`${classes.bodyText}`} />
                              </ListItem>
                              : 
                              <ListItem key={index}>                 
                                  <ListItemText
                                      className={classes.bodyText} 
                                      primary={item}                                
                                  />
                              </ListItem>
                            );
                          })
                        : <div className={classes.padMe}>Your accountabilities are empty. Have the group leader add some to view them here.</div>
                      : <div className={classes.padMe}>Your accountabilities are empty. Have the group leader add some to view them here.</div> }
                      {session.active_game.groupStates[group_id].params.writing === ACCOUNTABILITIES ? <ListItem className={classes.setHeightListItem}><LoadingDots /></ListItem> : null}
                  </List>
                </Grid>                
              </Grid>
            </Grid>
            <Grid item xs={12}>
                  <Typography variant="h6" className={`${classes.boldedText} ${classes.spacingTop}`}>For reference, here are the Expectations you entered:</Typography>
                    <List className={classes.listClass}>
                  {session.active_game.results.hasOwnProperty(group_id)
                    ? session.active_game.results[group_id].hasOwnProperty(EXPECTATIONS)
                      ? session.active_game.results[group_id][EXPECTATIONS].map((item, index) => {
                          return (                          
                            <ListItem key={index}>                 
                                <ListItemText
                                    className={classes.bodyText} 
                                    primary={item}                                
                                />
                            </ListItem>
                          );
                        })
                      : <div className={classes.padMe}>Your expectations are empty. Have the group leader add some to view them here.</div>
                    : <div className={classes.padMe}>Your expectations are empty. Have the group leader add some to view them here.</div> }
                    {session.active_game.groupStates[group_id].params.writing === EXPECTATIONS ? <ListItem className={classes.setHeightListItem}><LoadingDots /></ListItem> : null}
                </List>
              </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {session.active_game.results[group_id] && (auth.uid === session.active_game.groups[group_id].leadPlayer || profile.role === 'host') ? (
                  <div className={`${classes.spacingTop} ${classes.spacingBottom}`}>
                    <Typography variant="body1" className={classes.pSpaceBottom}>When you're finished, use the button below to finish the activity.</Typography>
                    <Button type='submit' variant='contained' onClick={this.finishActivity}>
                      Finish Activity
                    </Button>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }

    return (
      <div>
        <Typography variant='h4' className={classes.spaceBottom}>{session.active_game.name}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className={classes.imgContainer}>
              <img src={set_expectations} alt='instructions' className={classes.cofImageStyle} />
            </div>
            <Dialog open={open} onClose={this.handleClose} aria-labelledby="draggable-dialog-title" PaperComponent={PaperComponent}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Expectations
            </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.bodyText}>{this.state.isEdit !== false ? `Editing an Expectation` : `What is your Expectation? Type your entry below and hit save.`}</DialogContentText>
                <TextField autoFocus autoComplete="off" margin='dense' id='entry_text' name='entry_text' value={entry_text} onChange={this.onChange} label="Expectation:" type='email' fullWidth />
              </DialogContent>
              <DialogActions>
                {this.state.isEdit !== false ? (
                  <div>
                    <Button onClick={() => this.deleteRow(this.state.isEdit)} color='secondary'>
                      Delete
                    </Button>
                    <Button onClick={this.handleClose} color='primary'>
                      Cancel
                    </Button>
                    <Button onClick={() => this.updateRow(this.state.isEdit)} color='primary'>
                      Save
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button onClick={this.handleClose} color='primary'>
                      Cancel
                    </Button>
                    <Button disabled={isInvalid} onClick={this.handleSave} color='primary'>
                      Save
                    </Button>
                  </div>
                )}
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography variant='h6' className={`${classes.boldedText}`}>Demonstrate Setting Expectations</Typography>
                  <ol className={classes.bodyText}>
                    <li>Define the Task</li>
                    <li>Set Boundaries</li>
                    <li>Clarify Roles & Responsibilities</li>
                    <li>Establish the Standards</li>
                    <li>Provide Feedback</li>
                  </ol>
                  <Typography variant="body1" className={classes.spacingBottom}>Identify an expectation for one task, one employee. List and number each of the 5 steps for setting an expectation.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
                <Grid item xs={6}>
                <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Expectations</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                { hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile) ?
                  <Button type='submit' variant="contained"  onClick={() => this.clickExpectations()} startIcon={<AddIcon/>} className={classes.buttonMargin}>
                    Add Expectation
                  </Button>
                  : null }
                </Grid>
              <Grid item xs={12} sm={12}>
                {/* Results Here */}
                <List className={classes.listClass}>
                  {session.active_game.results.hasOwnProperty(group_id)
                    ? session.active_game.results[group_id].hasOwnProperty(EXPECTATIONS)
                      ? session.active_game.results[group_id][EXPECTATIONS].map((item, index) => {
                          return (
                            hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile) ?
                            <ListItem key={index} button onClick={() => this.itemAction(index, item, EXPECTATIONS)}>
                              <ListItemText primary={item} className={`${classes.bodyText}`} />
                            </ListItem>
                            : 
                            <ListItem key={index}>                 
                                <ListItemText className={classes.bodyText} primary={item} />
                            </ListItem>
                          );
                        })
                      : <div className={classes.padMe}>Your expectations are empty. Have the group leader add some to view them here.</div>
                    : <div className={classes.padMe}>Your expectations are empty. Have the group leader add some to view them here.</div> }
                    {session.active_game.groupStates[group_id].params.writing === EXPECTATIONS ? <ListItem className={classes.setHeightListItem}><LoadingDots /></ListItem> : null}
                </List>
              </Grid>   
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {session.active_game.results[group_id] && (auth.uid === session.active_game.groups[group_id].leadPlayer || profile.role === 'host') ? (
                <div className={`${classes.spacingTop} ${classes.spacingBottom}`}>
                  <Typography variant="body1" className={classes.pSpaceBottom}>When you're finished, use the button below to finish the activity.</Typography>
                  <Button type='submit' variant='contained' onClick={this.finishActivity}>
                    Finish Activity
                  </Button>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);
