import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams, updateGroupStateStateVal } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Button from '@material-ui/core/Button';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import ClueCard from './../specific_components/clue';
import DropArea from './../specific_components/dropArea';
import AggregateBackground from './../images/AggregateBackground.png';
import { ClueCards, BLOCK_COLORS } from './../data/data';
import AggregateBoard from './../images/AggregateBoard.png';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const INITIAL_STATE = {
    open: false,
    width: window.innerWidth,
    height: window.innerHeight
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.results.resultSnapshot === undefined ? {} : this.props.results.resultSnapshot, this.props.results.results)
        })
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    getResults( results, group_id, quadrant ){
        if ( results === undefined ){
            return {};
        }
        if ( results[group_id] === undefined ){
            return {};
        }
        if ( results[group_id][quadrant] === undefined ){
            return {};
        }
        return results[group_id][quadrant];        
    }

    

    calculateWin(group_id) {
        let winningCombo = {
          condo: {
            '1x5' : [19,20,21,22,23,24],
            '1x6' : [19,20,21,22,23,24],
            '2x5' : [1,2,3,4,5,6],
            '2x6' : [19,20,21,22,23,24],
            '3x3' : [7,8,9,10,11,12],
            '3x4' : [1,2,3,4,5,6],
            '3x5' : [1,2,3,4,5,6],
            '3x6' : [13,14,15,16,17,18],
            '4x3' : [7,8,9,10,11,12],
            '4x4' : [7,8,9,10,11,12],
            '4x5' : [13,14,15,16,17,18],
            '4x6' : [13,14,15,16,17,18]
          }
        };

        if ( this.props.results.results[group_id].grid.condo === undefined ){
          return false;
        }
    
        if ( JSON.stringify(Object.keys(this.props.results.results[group_id].grid.condo).sort()) === JSON.stringify(Object.keys(winningCombo.condo).sort()) ){
          // keys match, determine if right colors
          let res = true;
          Object.keys(this.props.results.results[group_id].grid.condo).forEach(el => {
            let v = this.props.results.results[group_id].grid.condo[el];
            if ( !winningCombo.condo[el].includes(parseInt(v)) ){
              res = false;
            }
          });          
          return res;
    
        } else {
          return false;
        }
      }

    render() {
    const {
      results,
      profiles,
      classes,
    } = this.props;

    console.log('RESULTS: ', results);

    const calcWidth = this.state.width > 1280 ? 1280 : this.state.width;
    const containerPWidth = calcWidth >= 993 ? 0.7 : calcWidth >= 601 ? 0.85 : 0.9;
    const containerMod = calcWidth >= 993 ? 50 : calcWidth >= 601 ? 54 : 58;
    const containerWidth = Math.round(calcWidth*containerPWidth)-12;
    const boxWidth = (containerWidth-containerMod) * 0.16666667;

        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
            <img src={AggregateBoard} alt='Aggregate board display'className={classes.imageSize}/>
            </Grid>
            <Grid item xs={12} sm={12}>
            {Object.keys(results.results)
                .sort()
                .map((group_id, ind) => {
                    let imp = results.results[group_id].importance !== undefined ? results.results[group_id].importance : {};
                    let res = this.props.results.results[group_id].grid;
                    if ( res.condo === undefined ){
                        return null;
                    } else {
                        return (
                            <div key={ind}>
                                <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{results.groups[group_id].title} { this.calculateWin(group_id) === true ? <CheckCircleIcon/> : null }</Typography>
                                <Grid container className={classes.paperExtra}>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={3}>
                                        <Grid item xs={12} >
                                            <Typography variant="h6" className={classes.spacingBottom}>Condo Complex</Typography> 
                                        </Grid>
                                        <Grid item xs={12} className={classes.spacingTop} align="center" style={{backgroundImage: `url(${AggregateBackground})`, backgroundSize: 'contain', backgroundPosition: '50%', backgroundRepeat: 'no-repeat'}}>
                                            <Grid container align="center" style={{alignItems: 'center', justifyContent: "center"}}>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`1x1`] !== undefined ? [ res.condo[`1x1`]] : []} id="1x1" boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} direction="horizontal" disabled={false} multiple={false} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`1x2`] !== undefined ? [ res.condo[`1x2`]] : []} id="1x2" boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} direction="horizontal" disabled={false} multiple={false} type="box" />
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`1x3`] !== undefined ? [ res.condo[`1x3`]] : []} id="1x3" boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} direction="horizontal" disabled={false} multiple={false} type="box" />
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`1x4`] !== undefined ? [ res.condo[`1x4`]] : []} id="1x4" boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} direction="horizontal" disabled={false} multiple={false} type="box" />
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`1x5`] !== undefined ? [ res.condo[`1x5`]] : []} id="1x5" boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} direction="horizontal" disabled={false} multiple={false} type="box" />
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`1x6`] !== undefined ? [ res.condo[`1x6`]] : []} id="1x6" boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} direction="horizontal" disabled={false} multiple={false} type="box" />
                                                </div>
                                            </Grid>
                                            <Grid container style={{alignItems: 'center', justifyContent: "center"}}>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`2x1`] !== undefined ? [ res.condo[`2x1`]] : []} id="2x1" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box" />
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`2x2`] !== undefined ? [ res.condo[`2x2`]] : []} id="2x2" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`2x3`] !== undefined ? [ res.condo[`2x3`]] : []} id="2x3" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`2x4`] !== undefined ? [ res.condo[`2x4`]] : []} id="2x4" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`2x5`] !== undefined ? [ res.condo[`2x5`]] : []} id="2x5" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box" />
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`2x6`] !== undefined ? [ res.condo[`2x6`]] : []} id="2x6" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                            </Grid>
                                            <Grid container style={{alignItems: 'center', justifyContent: "center"}}>
                                            <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`3x1`] !== undefined ? [ res.condo[`3x1`]] : []} id="3x1" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`3x2`] !== undefined ? [ res.condo[`3x2`]] : []} id="3x2" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`3x3`] !== undefined ? [ res.condo[`3x3`]] : []} id="3x3" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`3x4`] !== undefined ? [ res.condo[`3x4`]] : []} id="3x4" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`3x5`] !== undefined ? [ res.condo[`3x5`]] : []} id="3x5" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`3x6`] !== undefined ? [ res.condo[`3x6`]] : []} id="3x6" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                            </Grid>
                                            <Grid container style={{alignItems: 'center', justifyContent: "center"}}>
                                            <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`4x1`] !== undefined ? [ res.condo[`4x1`]] : []} id="4x1" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`4x2`] !== undefined ? [ res.condo[`4x2`]] : []} id="4x2" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`4x3`] !== undefined ? [ res.condo[`4x3`] ] : []} id="4x3" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`4x4`] !== undefined ? [ res.condo[`4x4`] ] : []} id="4x4" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box" />
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`4x5`] !== undefined ? [ res.condo[`4x5`] ] : []} id="4x5" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                                <div className={`box ${classes.gridB}`} style={{width: boxWidth, height: boxWidth}}> 
                                                    <DropArea  control={false} postits={res.condo[`4x6`] !== undefined ?  [ res.condo[`4x6`] ] : []} id="4x6" direction="horizontal" disabled={false} multiple={false} boxWidth={boxWidth} bgColorTable={BLOCK_COLORS} type="box"/>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={3}>
                                        <Grid item xs={12} className={classes.spacingTop}>
                                        <Typography variant="h6" className={classes.spacingBottom}>Clues Sorted</Typography> 
                                        <Typography variant="body1" className={classes.boldedText}></Typography>    
                                        { ClueCards.sort(function(a,b){ return (Object.keys(imp).includes(b.id.toString()) ? imp[b.id] : -1) - (Object.keys(imp).includes(a.id.toString()) ? imp[a.id] : -1) }).map((card,ind) => {
                                            return <ClueCard filter={'all'} key={ind} clue={card} adjustImportance={this.adjustImportance} importance={results.results[group_id] !== undefined ? results.results[group_id].importance !== undefined ? results.results[group_id].importance[card.id] !== undefined ? results.results[group_id].importance[card.id] : -1 : -1 : -1}/>;
                                        })} 
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>                        
                            );
                        }
                    })
                }
                </Grid>
                
            </Grid>
        </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);