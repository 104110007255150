import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GROUP_STATE_RESULTS, } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults } from './../../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingDots from './../../game_components/loadingDots';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import ChooseYourOwnManager from './../specific_components/chooseYourOwnManager';

import { CSSTransition } from "react-transition-group";
import CreateIcon from '@material-ui/icons/Create';

import { steps } from './../data/data';


const INITIAL_STATE = {

};


const withAnimation = WrappedComponent => {
  class _AnimatedComponent extends React.Component {
    state = {
      mount: this.props.mount,
      inProgress: this.props.mount && this.props.animateOnLoad,
      blockAnimation: !this.props.animateOnLoad
    };

    static getDerivedStateFromProps(props, state) {
      if (!state.blockAnimation && props.mount !== state.mount) {
        return {
          inProgress: true,
          mount: props.mount
        };
      }

      return null;
    }

    constructor(props) {
      super(props);
      this.wrapperRef = React.createRef();
    }

    componentDidMount() {
      this.setState({
        blockAnimation: false
      });
    }

    shouldComponentUpdate(nextProps, nextState) {
      return this.state.blockAnimation === nextState.blockAnimation;
    }

    onAnimationEnd = event => {
      const { target } = event;
      const { current } = this.wrapperRef;

      if (target === current) {
        this.setState({
          inProgress: false
        });
      }
    };

    render() {
      const { mount, inProgress, blockAnimation } = this.state;
      const { onMount, onUnmount, defaultClass } = this.props;
      const animationClass = mount ? onMount : onUnmount;

      return  (inProgress || mount) ? (
        <div
          ref={this.wrapperRef}
          className={`${defaultClass} ${!blockAnimation ? animationClass : ''}`}
          onAnimationEnd={this.onAnimationEnd}
        >
          <WrappedComponent {...this.props} />
        </div>
      ) : null;
    }
  };

  _AnimatedComponent.defaultProps = {
    animateOnLoad: true,
    defaultClass: ''
  };
  return _AnimatedComponent;
};



/*

  Keep track of current step via group results. Default step to `step_001`

  Toggleable intro option that will introduce the narrator. If enabled, will play a narrated sequence before step_001 starts.

  Components: dialogBox, dialogChoice

  dialogBox is always present, but sometimes will slide up to make room for the dialogChoices. When there are no choices, will slide back down.

  dialogChoices slide up side by side under the dialogBox. Function to be passed in to handle moving to the next step.

  ----

  Moving to the next step involves either:

  Flashing the selected choice for 2s, then fading out the choices and sliding the dialogBox back down. New message appears.

  OR

  Same as above, but with interim step of viewing the response message.



*/



class Activity extends Component {
  constructor(props) {
      super(props);
      this.state = {
        ...INITIAL_STATE,
      };
  
  }

  onDone = () => {

  }

  render() {
    const {
      session,
      profile,
      auth,
      classes,
    } = this.props;


    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);

    const results = session.active_game.results[group_id] === undefined ? {} : session.active_game.results[group_id];
    const step = results.step === undefined ? `step_001` : results.step;
    const hostID = determineHost(profile, auth);

    return (

      <div>
          <Typography variant='h3' className={classes.spaceBottom}>
              {session.active_game.name}
          </Typography>
          <ChooseYourOwnManager session={session} group_id={group_id} host={hostID} hasControl={hasControl} auth={auth} profile={profile} results={results} step={step}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);