import React, { useState } from "react";

import Message from "./message";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DialogChoices from './dialogChoices';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebriefChart from './debriefChart';

const withAnimation = WrappedComponent => {
  class _AnimatedComponent extends React.Component {
    state = {
      mount: this.props.mount,
      inProgress: this.props.mount && this.props.animateOnLoad,
      blockAnimation: !this.props.animateOnLoad
    };

    static getDerivedStateFromProps(props, state) {
      if (!state.blockAnimation && props.mount !== state.mount) {
        return {
          inProgress: true,
          mount: props.mount
        };
      }

      return null;
    }

    constructor(props) {
      super(props);
      this.wrapperRef = React.createRef();
    }

    componentDidMount() {
      this.setState({
        blockAnimation: false
      });
    }

    shouldComponentUpdate(nextProps, nextState) {
      return this.state.blockAnimation === nextState.blockAnimation;
    }

    onAnimationEnd = event => {
      const { target } = event;
      const { current } = this.wrapperRef;

      if (target === current) {
        this.setState({
          inProgress: false
        });
      }
    };

    render() {
      const { mount, inProgress, blockAnimation } = this.state;
      const { onMount, onUnmount, defaultClass } = this.props;
      const animationClass = mount ? onMount : onUnmount;

      return  (inProgress || mount) ? (
        <div
          ref={this.wrapperRef}
          className={`${defaultClass} ${!blockAnimation ? animationClass : ''}`}
          onAnimationEnd={this.onAnimationEnd}
        >
          <WrappedComponent {...this.props} />
        </div>
      ) : null;
    }
  };

  _AnimatedComponent.defaultProps = {
    animateOnLoad: true,
    defaultClass: ''
  };
  return _AnimatedComponent;
};

const AnimatedDialogChoices = withAnimation(DialogChoices);







const DialogBox = (props) => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const [messageFinished, setMessageFinished] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const [shouldChoicesShow, setShouldChoicesShow] = useState(false);

  const handleClick = () => {
    if ( props.hasControl !== true ){
      return;
    }
    if (currentMessage < props.messages.length - 1) {
      /* For this activity, this will never happen */
      setMessageFinished(false);
      setCurrentMessage(currentMessage + 1);
    } else {

      console.log('wtf', props.choice);
      if ( props.choice.type === 'no_choice' ){
        console.log('hereRe?');
        //choice: choice.choice, proceedTo: choice.proceedTo, choiceSubText: choice.choiceSubText, text: choice.text, id: historyLength
        props.chooseChoice({ choice: null, choiceSubText: null, text: null, proceedTo: props.choice.proceedTo, type : 'no_choice' }, false);
      } else {
        // bring up choices
        setMessageFinished(false);
        //setCurrentMessage(0);
        setShouldChoicesShow(true);
        props.triggerChoices();
      }
    }
  };

  const updateStatus = () => {
    console.log('whaaaaat');
    setMessageFinished(true);
  }

  React.useEffect(() => {

    if ( props.groupState.effect === 'choices' ){
      setMessageFinished(false);
      setShouldChoicesShow(true);
    }
    /*if ( props.groupState.effect === 'choiceMade' ){

    }*/
  }, [props.groupState.effect])

  React.useEffect(() => {
      //console.log('HMMM', deselectAll);
     setTimeout(() => {
      setShouldShow(true);
     }, 500); 
  }, [props.messages])


  //console.log(props.messages[currentMessage]);
  let opacity = props.hasControl ? '1' : '0.5';
  let p = props.hasControl ? 'pointer' : 'default';

  const lastMessage = props.messages.length === (currentMessage+1) ? true : false;

  console.log("um, ", lastMessage, messageFinished, shouldChoicesShow);
  return (
    <div>
      <div className="DialogWindow">
        <div className="DialogContainer">
          <div className="DialogMessage">            
            <Grid container spacing={3}>
              <Grid item xs={4}>
                  <img src={props.image} className={props.classes.imageSize} style={{ maxHeight: "200px", borderRadius: "10px"}}/>
              </Grid>
              <Grid item xs={8}>
                
                    { shouldShow ? <Message message={props.messages[currentMessage]} key={currentMessage} updater={updateStatus}/> : null }
                
              </Grid>
            </Grid>
          </div>
          
          <div className="DialogFooter" style={{ opacity: messageFinished ? 1 : 0, transition: "opacity 0.5s"}}>
              { shouldChoicesShow === true 
                  ? 
                  `` 
                  : lastMessage && messageFinished 
                      ? 
                        props.choice.type === 'end' ?
                        <span><span onClick={props.restartActivity} style={{background: '#fff', opacity: opacity, color: "#373535", padding: "10px", borderRadius: "5px", fontWeight: "bold", cursor: p  }}>Restart Activity</span> <span onClick={props.finishActivity} style={{background: '#fff', color: "#373535", padding: "10px", borderRadius: "5px", fontWeight: "bold", opacity: opacity, cursor: p }}>Finish Activity</span></span> 
                        :
                        <span onClick={handleClick} style={{background: '#fff', color: "#373535", opacity: opacity, padding: "10px", borderRadius: "5px", fontWeight: "bold", cursor: p }}>PROCEED</span> 

                      : <ArrowDropDownIcon style={{color:"#fff"}}/> 
              }
          </div>
          
        </div>
      </div>
      <AnimatedDialogChoices 
        choices={props.choices}
        classes={props.classes}
        chooseChoice={props.chooseChoice}
        groupState={props.groupState}
        hasControl={props.hasControl}
        isDialogOpen={props.isDialogOpen}
        openDialog={props.openDialog}

        mount={shouldChoicesShow === true}
        onMount="in"
        onUnmount="out"
        defaultClass="wrapper"
        animateOnLoad={true}
      />

      { props.choice.type === 'end' && messageFinished ?
          <DebriefChart 
            history={props.history} 
            onlyCurrentRun={true} 
            debrief={false}
            classes={props.classes} 
            session={props.session} 
            profile={props.profile} 
            auth={props.auth} 
            group_id={props.group_id}
            hasControl={props.hasControl}
          />        
        : null
      }
    </div>
  );
};

export default DialogBox;



/*

  Pass in message(s) to display.

  At end of message(s), send notification back to main to display options. Options do not use this style of text display.

  OnMount : set Timer to only show the next button when the text is done displaying

  Button text: Proceed... if done, or flashing down arrow if more messages

  Props:

  messages: []
  onDone: func
  storyTellerName: string


*/