import React, { useState, useEffect } from 'react';

import OnDeckCardHand from './cardHand';
import GridArea from './gridArea';
import { DragDropContext } from 'react-beautiful-dnd';
import { CARDS_LIST, WON, SETS, SETS_EVALUATE, COLS, ROWS, HEARTS, SPADES, DIAMONDS, CLUBS, KINGS, QUEENS, JACKS, ACES, TWOS, THREES, FOURS, FIVES, SIXES, SEVENS, EIGHTS, NINES, TENS } from './../data/data';



const valueChecker = (v) => {
    return v === undefined ? undefined : v;
}


/* -----------

  Props:
  cardId: id of the card to display
 
   -----------
*/

export const DragDropContain = (props) => {

    const [cardPositions, setCardPositions] = useState({
        '1x1' : valueChecker(props.results['1x1']),
        '1x2' : valueChecker(props.results['1x2']),
        '1x3' : valueChecker(props.results['1x3']),
        '1x4' : valueChecker(props.results['1x4']),
        '2x1' : valueChecker(props.results['2x1']),
        '2x2' : valueChecker(props.results['2x2']),
        '2x3' : valueChecker(props.results['2x3']),
        '2x4' : valueChecker(props.results['2x4']),
        '3x1' : valueChecker(props.results['3x1']),
        '3x2' : valueChecker(props.results['3x2']),
        '3x3' : valueChecker(props.results['3x3']),
        '3x4' : valueChecker(props.results['3x4']),
        '4x1' : valueChecker(props.results['4x1']),
        '4x2' : valueChecker(props.results['4x2']),
        '4x3' : valueChecker(props.results['4x3']),
        '4x4' : valueChecker(props.results['4x4']),
        'cardHand' : valueChecker(props.results['cardHand'])
    });
    const [source, setSource] = useState(false);
    const [won, setWon] = useState({ row: { 1 : false, 2 : false, 3 : false, 4 : false }, col: { 1 : false, 2 : false, 3 : false, 4 : false } });

    const checkWon = () => {
        let t = { ...cardPositions };
        delete t.cardHand;
        delete t.startedOn;

        let set = props.set;
        //console.log('set: ', set);
        let valid = true;

        if ( Object.values(t).length !== 16 ){
            //console.log("Checking Won: False. Not enough cards played");
            valid = false;
        }
        Object.values(t).forEach((v) => {       
            //console.log('here: ', v);     
            if ( !WON[set].includes(parseInt(v)) || v === false || v === 'false' ){
                //console.log("Checking Won: False. Wrong cards played", v, WON[set], t);
                valid = false;
            }
        });

        let w = { ...won };

        const FOUND_DEFAULT = { 1 : 0, 2 : 0, 3 : 0, 4: 0 };
        //let found = FOUND_DEFAULT;
        // if we made it here, they have only valid cards, and a full set of them
        ROWS.forEach((rowArray, ind) => {
            let found = { ...FOUND_DEFAULT };
            let suitFound = { ...FOUND_DEFAULT };

            rowArray.forEach((row) => {
                let v = cardPositions[row];
                if ( SETS_EVALUATE[set][1].includes(parseInt(v)) ){
                    found[1] += 1;
                }
                if ( SETS_EVALUATE[set][2].includes(parseInt(v)) ){
                    found[2] += 1;
                }
                if ( SETS_EVALUATE[set][3].includes(parseInt(v)) ){
                    found[3] += 1;
                }
                if ( SETS_EVALUATE[set][4].includes(parseInt(v)) ){
                    found[4] += 1;
                }

                if ( HEARTS.includes(parseInt(v)) ){
                    suitFound[1] += 1;
                }
                if ( CLUBS.includes(parseInt(v)) ){
                    suitFound[2] += 1;
                }
                if ( DIAMONDS.includes(parseInt(v)) ){
                    suitFound[3] += 1;
                }
                if ( SPADES.includes(parseInt(v)) ){
                    suitFound[4] += 1;
                }


            });

            if ( found[1] !== 1 || found[2] !== 1 || found[3] !== 1 || found[4] !== 1 ){
                //console.log(`Checking Won: False. Row ${rowArray} failed check.`, found);
                w.row[(ind+1)] = false;
                valid = false;
            } else {
                //console.log(`Checking Win: True. Row ${rowArray} `);
                w.row[(ind+1)] = true;

            }    
            
            if ( suitFound[1] !== 1 || suitFound[2] !== 1 || suitFound[3] !== 1 || suitFound[4] !== 1 ){
                //console.log(`Checking Suit Won: False. Row ${rowArray} failed check.`, suitFound);
                w.row[(ind+1)] = false;
                valid = false;
            } else {
                //console.log(`Checking Suit Win: True. Col ${rowArray} `);
                if ( w.row[(ind+1)] !== false ){
                    w.row[(ind+1)] = true;
                }
            }    
        });

        COLS.forEach((colArray, ind) => {
            let found = { ...FOUND_DEFAULT };
            let suitFound = { ...FOUND_DEFAULT };

            colArray.forEach((col) => {
                let v = cardPositions[col];
                if ( SETS_EVALUATE[set][1].includes(parseInt(v)) ){
                    found[1] += 1;
                }
                if ( SETS_EVALUATE[set][2].includes(parseInt(v)) ){
                    found[2] += 1;
                }
                if ( SETS_EVALUATE[set][3].includes(parseInt(v)) ){
                    found[3] += 1;
                }
                if ( SETS_EVALUATE[set][4].includes(parseInt(v)) ){
                    found[4] += 1;
                }

                if ( HEARTS.includes(parseInt(v)) ){
                    suitFound[1] += 1;
                }
                if ( CLUBS.includes(parseInt(v)) ){
                    suitFound[2] += 1;
                }
                if ( DIAMONDS.includes(parseInt(v)) ){
                    suitFound[3] += 1;
                }
                if ( SPADES.includes(parseInt(v)) ){
                    suitFound[4] += 1;
                }
            });

            if ( found[1] !== 1 || found[2] !== 1 || found[3] !== 1 || found[4] !== 1 ){
                //console.log(`Checking Won: False. Col ${colArray} failed check.`, found);
                won.col[(ind+1)] = false;
                valid = false;
            } else {
                //console.log(`Checking Win: True. Col ${colArray} `);
                w.col[(ind+1)] = true;
            } 

            if ( suitFound[1] !== 1 || suitFound[2] !== 1 || suitFound[3] !== 1 || suitFound[4] !== 1 ){
                //console.log(`Checking Suit Won: False. Col ${colArray} failed check.`, suitFound);
                valid = false;
                w.col[(ind+1)] = false;
            } else {
                //console.log(`Checking Suit Win: True. Col ${colArray} `);
                if ( w.col[(ind+1)] !== false ){
                    w.col[(ind+1)] = true;
                }
            }  

           
        });

        //console.log(`Checking Won: ${valid}`, w);
        setWon(w);
        //return valid;
        if ( valid === true ){
            props.onWin();
        }
    }



    useEffect(() => {
        setCardPositions(props.results);
        checkWon();
        //console.log('hererere');
    }, [props.results])

    useEffect(() => {
        const interval = setInterval(() => {
            //console.log('This will run every second!');
            checkWon();
          }, 1000);
          return () => clearInterval(interval);
    })

    useEffect(() => {
        //console.log('cardPositions', cardPositions);
        
    }, [cardPositions]);

    useEffect(() => {
        checkWon();
    }, [])

    const onDragUpdate = (result,event) => {
        //console.log('dragging', result,event);
    }

    const onDragEnd = (result) => {
        //console.log('end drag', result);
        const { source, destination, draggableId } = result;
        // dropped outside the list
        setSource(false);
        if (!destination) {
            return;
        }


        // we don't care about re-ordering here, so just ignore drops that are in the same area where they started
        if (destination.droppableId === source.droppableId) {
            return;
        }


        let updatedPositions = { ...cardPositions };
        let startSpot = source.droppableId;
        let finishSpot = destination.droppableId;
        
        
        let start = null;
        let finish = null;

        if ( finishSpot !== 'cardHand' ){
            // We're dragging to a spot on the grid


            if ( startSpot !== 'cardHand' ){
                // we are swapping from grid to grid


                // first, check if we need to swap
                if ( updatedPositions[finishSpot] !== undefined && updatedPositions[finishSpot] !== false ){
                    // we are swapping the card that's in the grid spot back into the starting spot
                    updatedPositions[startSpot] = updatedPositions[finishSpot];
                    updatedPositions[finishSpot] = draggableId;
                    setCardPositions(updatedPositions);
                    props.updateGridResults(updatedPositions);
                    
                } else {
                    updatedPositions[startSpot] = false;
                    updatedPositions[finishSpot] = draggableId;
                    setCardPositions(updatedPositions);
                    props.updateGridResults(updatedPositions);
                 
                }

            } else {
                // we are dragging from hand to grid
                //console.log('here?');

                // first, check if we need to swap
                if ( updatedPositions[finishSpot] !== undefined && updatedPositions[finishSpot] !== false ){
                    // we are swapping the card that's in the grid spot back into the hand
                    //console.log('ah', { ...updatedPositions[startSpot], [updatedPositions[finishSpot]] : CARDS_LIST[updatedPositions[finishSpot]] });
                    updatedPositions[startSpot] = { ...updatedPositions[startSpot], [updatedPositions[finishSpot]] : CARDS_LIST[updatedPositions[finishSpot]] };
                    
                } 
                    //updatedPositions[startSpot] = { ...updatedPositions[startSpot], [draggableId] : CARDS_LIST[draggableId] };
                    console.log(finishSpot, startSpot, draggableId);
                    //delete updatedPositions[startSpot][draggableId];
                    let t = { ...updatedPositions[startSpot] };
                    delete t[draggableId];
                    updatedPositions[startSpot] = { ...t };

                    updatedPositions[finishSpot] = draggableId;
                    setCardPositions(updatedPositions);
                    props.updateGridResults(updatedPositions);
                
                
            }



        } else {
            // we're dragging from the grid, to the cardHand
            updatedPositions[finishSpot] = { ...updatedPositions[finishSpot], [draggableId] : CARDS_LIST[draggableId] };
            updatedPositions[startSpot] = false;
            setCardPositions(updatedPositions);
            props.updateGridResults(updatedPositions);
            checkWon();

           
        }
  
    }

    const onDragStart = (result) => {
        //console.log('start drag', result);
        setSource(result.source.droppableId);
    }

    return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>          
          <GridArea control={true} results={cardPositions} source={source} set={props.set} won={won}/>
          <div style={{marginTop: "20px"}}>
            <OnDeckCardHand control={true} cards={cardPositions['cardHand']}/>
          </div>
    </DragDropContext>
    );
}