import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { IMPORTANCE } from './../data/data';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styles from '../../../../Styles';

const INITIAL_STATE = {
    mouseY: null,
    mouseX: null
}

class ClueCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    handleContextClose = () => {
        this.setState({
            ...this.state,
            mouseX: null,
            mouseY: null
          });
    }

    contextHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
          ...this.state,
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
    }

    adjust = (id, importance) => {
        this.props.adjustImportance(id, importance);
        this.setState({
            ...this.state,
            mouseX: null,
            mouseY: null
          });
    }

    render() {  
        const {
            classes,
          } = this.props;

        if ( this.props.filter === 'all' || this.props.filter === this.props.importance.toString() ){
            return (
                <Card className={classes.clueCardRoot} onContextMenu={(event) => this.contextHandler(event)}>
                    <CardHeader
                        avatar={
                        <Avatar aria-label="recipe" className={classes[`importance${this.props.importance}`]}>
                            {this.props.clue.id}
                        </Avatar>
                        }                
                        title={`Clue #${this.props.clue.id}`}
                        subheader={ IMPORTANCE[this.props.importance] } />
                    <CardContent>
                        <Typography variant="body1" color="textSecondary" component="p">
                        {this.props.clue.profile_text}
                        </Typography>
                    </CardContent>
                    <Menu
                        keepMounted
                        open={this.state.mouseY !== null}
                        onClose={this.handleContextClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            this.state.mouseY !== null && this.state.mouseX !== null
                            ? { top: this.state.mouseY, left: this.state.mouseX }
                            : undefined}>
                        { this.props.importance !== 0 ? <MenuItem onClick={() => this.adjust(this.props.clue.id, 0)}>Change to Not Important</MenuItem> : null }
                        { this.props.importance !== 1 ? <MenuItem onClick={() => this.adjust(this.props.clue.id, 1)}>Change to Important</MenuItem> : null }
                        { this.props.importance !== 2 ? <MenuItem onClick={() => this.adjust(this.props.clue.id, 2)}>Change to Very Important</MenuItem> : null }
                    </Menu>
                </Card>
            );
        } else {
            return null;
        }
    };
}

const mapStateToProps = (state) => {
    return {};
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
    };
  };
  
  export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ClueCard);
