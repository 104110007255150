import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  hasGameControl,
  getUserGroup_hc,
  determineHost
} from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults, removeResultsArray } from './../../../../../store/actions/gameActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Cards from '../specific_components/cards';

import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Hidden from '@material-ui/core/Hidden';
import { Droppable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import DoneIcon from '@material-ui/icons/Done';
import Slide from '@material-ui/core/Slide';
import TLogo from './../images/Trust-Spectrum-Logo.png';
import SpectrumCategory from './../specific_components/spectrumCategory.js';
import ts_help5 from './../images/ts_help5.png';
import ts_help4 from './../images/ts_help4.png';
import ts_help3 from './../images/ts_help3.png';
import ts_help2 from './../images/ts_help2.png';
import ts_help1 from './../images/ts_help1.png';

const INITIAL_STATE = {
  open: false,
  helpOpen: false,
  circle: null,
  entry_text: '',
  isEdit: false,
  choices: []
};
export const CIRCLE_CONCERN = 'Concern';
export const CIRCLE_INFLUENCE = 'Influence';

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#ffffff',
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  handleClose = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };


  finishActivity = () => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_RESULTS,
      params: {}
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleHelpClose = () => {
    this.setState({
      ...this.state,
      helpOpen: false
    });
  }

  openHelp = () => {
    this.setState({
      ...this.state,
      helpOpen: true
    })
  }

  

  clearSelected = (e, card_id, column_id) => {
    console.log("CLEARLIGNS", card_id, column_id);
    e.preventDefault();
    e.stopPropagation();
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const results = this.props.session.active_game.results[group_id] === undefined ? {} : this.props.session.active_game.results[group_id];
    this.props.removeResultsArray(group_id, column_id, card_id, determineHost(this.props.profile, this.props.auth));
  }

  render() {
    const {
      session,
      profile,
      auth,
      classes,
    } = this.props;
    const { helpOpen } = this.state;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const results = session.active_game.results[group_id] === undefined ? {} : session.active_game.results[group_id];

    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);

    return (
      <div>
     
     
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
            <div className={`${classes.spacingTop} ${classes.spacingBottom}`}>
                <Grid container>
                  <Grid item xs={6}><Cards session={session} profile={profile} auth={auth} finishActivity={this.finishActivity}/> </Grid>
                  <Grid item xs={6} align="right">
                    <Button 
                        variant="contained"
                        color="primary"
                        startIcon={<LiveHelpIcon />}
                        onClick={() => this.openHelp()}
                        >
                    What Should I Do?
                    </Button>
                  </Grid>
                </Grid>
                
                
                </div> 
                  <div id="trustSpectrumContainer">
                    <div id="trustSpectrumHeader"><img src={TLogo} className={classes.imageSize} style={{width: "70%", display: "block", margin: "0 auto"}}/></div>
                    <div id="trustSpectrumBoard" className={classes.trustSpectrumBase}>
                      <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} align="center">
                          <span className="title-number">1</span><br/>NEVER
                          <SpectrumCategory profile={profile} auth={auth} cards={results[1]} column={1} hasControl={hasControl} session={session} clearSelected={this.clearSelected}/>
                        </Grid>
                        <Grid item xs={2} align="center">
                          <span className="title-number">2</span><br/>&nbsp;
                          <SpectrumCategory profile={profile} auth={auth} cards={results[2]} column={2} hasControl={hasControl} session={session} clearSelected={this.clearSelected}/>
                        </Grid>
                        <Grid item xs={2} align="center">
                          <span className="title-number">3</span><br/>SOMETIMES
                          <SpectrumCategory profile={profile} auth={auth} cards={results[3]} column={3} hasControl={hasControl} session={session} clearSelected={this.clearSelected}/>
                        </Grid>
                        <Grid item xs={2} align="center">
                          <span className="title-number">4</span><br/>&nbsp;
                          <SpectrumCategory profile={profile} auth={auth} cards={results[4]} column={4} hasControl={hasControl} session={session} clearSelected={this.clearSelected}/>
                        </Grid>
                        <Grid item xs={2} align="center">
                          <span className="title-number">5</span><br/>ALWAYS
                          <SpectrumCategory profile={profile} auth={auth} cards={results[5]} column={5} hasControl={hasControl} session={session} clearSelected={this.clearSelected}/>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </div>
                  </div>
                   
            </Grid>
            <Grid item xs={12} sm={12}>
               
             
            </Grid>
          </Grid>

        
        
      <Dialog
          open={helpOpen}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="md"
          onClose={this.handleHelpClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
              <Typography variant="h4" style={{marginBottom: "10px"}}>What Should I Do?</Typography>
              <Typography variant="body1" style={{marginBottom: "10px"}}>In this exercise, <span style={{fontWeight: "bold"}}>Trust Cards</span> are placed onto the Trust Spectrum where you as a group feel they belong, according to this scale:</Typography> 
              <ul className="browser-default">
                <li><span style={{fontWeight: "bold"}}>1-Never:</span> I would NEVER trust this leader.</li>
                <li><span style={{fontWeight: "bold"}}>3-Sometimes:</span> I would SOMETIMES trust this leader.</li>
                <li><span style={{fontWeight: "bold"}}>5-Always:</span> I would ALWAYS try to trust this leader.</li>
              </ul>
              <Typography variant="body1">To open the side drawer and view the Trust Cards, click the <span style={{fontWeight: "bold"}}>View Trust Cards</span> button, as seen below.</Typography> 
              <img src={ts_help1}/>
              <Typography variant="body1" style={{marginBottom: "10px"}}>As a group leader, you can click on the cards to open a popout menu as seen below. Use this menu to move the cards into positions on the Trust Spectrum.</Typography> 
              <img src={ts_help2}/>
              <Typography variant="body1" style={{marginBottom: "10px", marginTop: "10px"}}>When you move a card to the Trust Spectrum, it will appear as seen below.</Typography>
              <img src={ts_help3} className={classes.imageSize}/>
              <Typography variant="body1" style={{marginBottom: "10px", marginTop: "10px"}}>From there, clicking on a card within the Trust Spectrum will bring it up to view the card as seen below. As the group leader, if you click on the <span style={{fontWeight: "bold"}}>Clear From Column</span> button, it will remove the card and sent it back to the list of unused cards.</Typography>
              <img src={ts_help4} className={classes.imageSize}/>
              <Typography variant="body1" style={{marginBottom: "10px", marginTop: "10px"}}>When all cards have been placed onto the Trust Spectrum, a <span style={{fontWeight: "bold"}}>Finish Activity</span> button will appear beside the <span style={{fontWeight: "bold"}}>View Trust Cards</span> button. Clicking it as group leader will end the activity for your group.</Typography>
          </DialogContent>                  
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    removeResultsArray: (group, top_level, value, hostID) => dispatch(removeResultsArray(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);
