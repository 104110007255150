import step_001 from './../images/steps/step_001.jpeg';
import step_002 from './../images/steps/step_002.jpeg';
import step_003 from './../images/steps/step_003.jpg';
import step_004 from './../images/steps/step_004.jpeg';
import step_005 from './../images/steps/step_005.jpeg';
import step_006 from './../images/steps/step_006.jpeg';
import step_007 from './../images/steps/step_007.jpeg';
import step_008 from './../images/steps/step_008.jpg';
import step_009 from './../images/steps/step_009.jpeg';
import step_010 from './../images/steps/step_010.jpeg';
import step_011 from './../images/steps/step_011.jpeg';
import step_012 from './../images/steps/step_012.jpeg';
import step_013 from './../images/steps/step_013.jpeg';

export const PROFILE_MALE = 'Microsoft Guy Online (Natural) - English (United States)';
export const PROFILE_FEMALE = 'Microsoft Clara Online (Natural) - English (Canada)';

export const PROFILE_MALE2 = 'Google UK English Male';
export const PROFILE_FEMALE2 = 'Google UK English Female';

/* images:
step_001: https://stock.adobe.com/ca/images/conceptual-image-of-personal-and-career-promotion/173541989?prev_url=detail
step_002: https://stock.adobe.com/ca/images/young-smiling-male-worker-in-warehouse-wearing-safety-helmet-and-vest-standing-between-shelf-with-goods-using-cellphone/336991277?prev_url=detail
step_003: https://stock.adobe.com/ca/images/shouting-at-a-brick-wall/85761977?prev_url=detail
step_004: https://stock.adobe.com/ca/images/word-writing-text-quid-pro-quo-business-photo-showcasing-a-favor-or-advantage-granted-or-expected-in-return-of-something/312305292?prev_url=detail
step_005: https://stock.adobe.com/ca/images/3d-rendering-of-a-group-of-sun-umbrellas-with-one-exclude-from-the-group/194992506?prev_url=detail
step_006: https://stock.adobe.com/ca/images/multiethnic-group-sneaky-sly-liar-people-women-men-plotting-something/130781347?prev_url=detail
step_007: https://stock.adobe.com/ca/images/frustrated-young-woman-with-crossed-hands-stands-isolated-on-white/209417473?hide_header=1 | https://stock.adobe.com/ca/images/unhappy-young-woman-looking-at-laptop-screen-irritated-by-bad-gadget-work-low-internet-connection-working-remotely-at-home-stressed-attractive-lady-annoyed-by-hard-work-task-or-system-crash/328955608?prev_url=detail
step_008: https://stock.adobe.com/ca/images/single-continuous-line-drawing-of-young-tired-businessman-feel-dizzy-while-he-is-working-at-office-work-fatigue-after-overload-job-concept-trendy-one-line-draw-design-graphic-vector-illustration/357394693?prev_url=detail
step_009: https://stock.adobe.com/ca/images/th-n-pfeil-chaos-i/102171726?prev_url=detail
step_010: https://stock.adobe.com/ca/images/portrait-of-young-caucasian-woman-in-yellow-shirt-casually-standing-near-gray-wall-concept-of-being-confident-woman/207839985?prev_url=detail
step_011: https://stock.adobe.com/ca/images/bright-idea-and-creative-thinking/314829742?prev_url=detail
step_012: https://stock.adobe.com/ca/images/cartoon-spaceship-crashed-on-white-background/281029613?prev_url=detail
step_013: https://stock.adobe.com/ca/images/business-concept-problem-solution-success/84899215?prev_url=detail
*/



export const steps = {
    'step_001' : {
        step: 1,
        type: 'normal',
        image: step_001,
        title: 'The Beginning',
        text: `Congratulations! You have just been promoted to team leader.
        You are happy for the raise and the recognition that you have been doing a good job.  
        You're not really sure what to do first. Everyone on the team seems to be on pins and needles watching you.`,
        textParts: ['Congratulations!', 'You have just been promoted to team leader.', 'You are happy for the raise and the recognition that you have been doing a good job.', `You're not really sure what to do first. Everyone on the team seems to be on pins and needles watching you.`],
        choices: [
            {
                choice: 0,
                text: `Tell the team you are happy to have the new job but you are still the same person and look forward to working together.`,
                proceedTo: 'step_003',
                choiceSubText: ``
            },
            {
                choice: 1,
                text: `Meet with your team, let them know what you will be expecting.`,
                proceedTo: 'step_002',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_002' : {
        step: 2,
        type: 'normal',
        image: step_002,
        text: `You’re not sure how your co-workers will feel about you being the boss. 
        There are a few things about this place that you have never been happy about and have never hesitated 
        to share with your peers.  Sure enough your buddy Eric just stopped by to start the usual complaint discussion. 
        Eric now works for you. What will you do?`,
        choices: [            
            {
                choice: 0,
                text: `You politely stop Eric once he starts gossiping and complaining, 
                and tell him you can no longer share in such conversations.`,
                proceedTo: 'step_004',
                choiceSubText: `Once you politely stop Eric, 
                his immediate response is “What! You used to complain about these things when you were one of us!”  
                To which you respond: “I realize that but I see a bigger picture now that I am doing this job. 
                I still care about the issue and welcome your solutions.”`
            },
            {
                choice: 1,
                text: `Join in the conversation with Eric, after all he is your buddy and tonight is trivia night. 
                You plan to just let him vent a bit and then get things back on track.`,
                proceedTo: 'step_004',
                choiceSubText: `That night at Trivia, Eric tells you he knew you would still be the 
                same great guy that he’s been friends with all these years even if you were now his boss.`
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_003' : {
        step: 3,
        type: 'normal',
        image: step_003,
        text: `So you’re the boss, doesn’t
        change much except you get
        more pay and that is always
        good. You notice that team
        members are basically doing
        their own thing and even you
        know they are making some
        poor choices in their daily
        activities. Despite your
        experience in the company,
        you are not too familiar with
        the specifics of all the tasks
        your team does. What will you
        do?`,
        choices: [            
            {
                choice: 0,
                text: `You decide to spend more time with each team member to really understand what they are doing, 
                what their concerns might be. Your focus is in building team trust in you.`,
                proceedTo: 'step_005',
                choiceSubText: ``
            },{
                choice: 1,
                text: `You would like to ignore this and just get on with your job. 
                But there seems to be a lot of gossip in the department. 
                This might be a good time to really be the boss and tell everyone to shape up and show why. 
                That might help them understand that you do know they are making poor choices.`,
                proceedTo: 'step_005',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_004' : {
        step: 4,
        type: 'normal',
        image: step_004,
        text: `You’re still part of the gang, so what’s the big deal if you all talk a bit. 
        You have always been known as the person to help others out and you want to continue this reputation.`,
        choices: [
            {
                choice: 0,
                text: `Now that you are the boss, you want the team to really like you, so you are trying to do a few favors for people – 
                rearrange their schedule if requested, not complain about long breaks if they had a rough morning, that kind of thing.`,
                proceedTo: 'step_006',
                choiceSubText: ``
            },
            {
                choice: 1,
                text: `As the boss now you need to
                establish boundaries rather than
                work to be everyone’s friend.`,
                proceedTo: 'step_006',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_005' : {
        step: 5,
        type: 'normal',
        image: step_005,
        text: `Seems you are no longer part of the shop talk, instead it feels like the gossip is about you! 
        The team are so focused on gossip that work is slipping.`,
        choices: [
            {
                choice: 0,
                text: `Ask Eric why he’s now talking about you.`,
                proceedTo: 'step_002',
                choiceSubText: ``
            },
            {
                choice: 1,
                text: `Have a team meeting and layout your expectations of the team in a calm easy manner.`,
                proceedTo: 'step_006',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_005a' : {
        step: '5a',
        type: 'normal',
        image: step_005,
        text: `Eric says to get over yourself as it’s not all about you. You calm yourself down and plan to talk to your team.`,
        choices: [
            {
                choice: 0,
                text: `Have a team meeting and layout your expectations of the team in a calm easy manner.`,
                proceedTo: 'step_006',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_006' : {
        step: 6,
        type: 'normal',
        image: step_006,
        text: `Although you have set expectations for the team, sometimes the team still do things the way they have always done things. 
        There was a big problem on the production line yesterday, so you went down and basically took over and fixed it yourself.`,
        choices: [            
            {
                choice: 0,
                text: `Tell the team you are one of
                them and will always be here to
                help when the line goes down and
                they need support.`,
                proceedTo: 'step_007',
                choiceSubText: ``
            },
            {
                choice: 1,
                text: `Apologize to the team for
                “taking over” yesterday. This is not
                your role and as they work the line
                each day. However, you will be
                there to discuss options and
                solutions.`,
                proceedTo: 'step_007',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_007' : {
        step: 7,
        type: 'normal',
        image: step_006,
        text: `With the team members, it feels like they are watching your every move, maybe thinking you will take over again.`,
        choices: [            
            {
                choice: 0,
                text: `Back off on pushing the team
                to do things differently, and let the
                team do what they typically would
                have done. They all have lots of job
                experience. Take the pressure off.
                If needed you can always come
                back and lay down the law.`,
                proceedTo: 'step_008',
                choiceSubText: ``
            },
            {
                choice: 1,
                text: `The team are watching to see what kind of leader you will be.  
                Focus on building trust with your team.  
                Show your team you mean them no harm and you have their best interest at heart. 
                Do this by demonstrating trust for the team.`,
                proceedTo: 'step_008',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_008' : {
        step: 8,
        type: 'normal',
        image: step_007,
        text: `Monica just can’t let it go that you you got this job.  
        She wanted the job and felt she could have done much better at it than you.  
        She is a great friend of yours and you want her to still be your friend. 
        Heck your families get together for parties and BBQs all the time.`,
        choices: [
            {
                choice: 0,
                text: `Go out for a drink with Monica and the team tonight and just have some laughs. 
                Tell Monica you still want to be friends and can’t help that you were chosen for the job.`,
                proceedTo: 'step_009',
                choiceSubText: ``
            },
            {
                choice: 1,
                text: `Take Monica aside and quietly talk with her about her concerns. Have an open conversation about the transition.`,
                proceedTo: 'step_011',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_009' : {
        step: 9,
        type: 'normal',
        image: step_008,
        text: `The Monica issue has not changed, Eric is still angry with you and the team are slowing down every day.  
        Less and less productivity is happening on the line.`,
        choices: [
            {
                choice: 0,
                text: `This too shall pass, things
                always work out, and eventually
                the team will be on your side,
                you’ve seen this happen before. It
                is just a rough patch that is to be
                expected for any new supervisor.`,
                proceedTo: 'step_010',
                choiceSubText: ``
            },
            {
                choice: 1,
                text: `You decide to have a private conversation with Monica to see if you can at least get that back on track.`,
                proceedTo: 'step_011',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_010' : {
        step: 10,
        type: 'normal',
        image: step_009,
        text: `You realize you’re back at square one.  
        Your team aren’t treating you like the team leader, they go above you for answers, and basically do whatever they want.`,
        choices: [            
            {
                choice: 0,
                text: `Have a team meeting.  Be honest with changes you needed to make and didn’t.  
                Share the expectations for you in this role and your expectations for them.`,
                proceedTo: 'step_011',
                choiceSubText: ``
            },
            {
                choice: 1,
                text: `Don’t push things, just stick to the plan of work and those you have known through the years will always be there to support you. 
                You are known for being a great employee and this will serve you in this new role.`,
                proceedTo: 'step_011',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_011' : {
        step: 11,
        type: 'normal',
        image: step_010,
        text: `So much going on in this new role! Your discussion with Monica raised her concerns about being passed over and how she 
        really is happy for you just disappointed for her.  She just wanted you to acknowledge this.`,
        choices: [
            {
                choice: 0,
                text: `You welcome Monica to support you in making this the best team in the plant. 
                With so much happening you feel it’s time to get advice from your supervisor on how to handle employees and 
                how to get people to want to follow your instructions and expectations.`,
                proceedTo: 'step_012',
                choiceSubText: ``
            }
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_012' : {
        step: 12,
        type: 'normal',
        image: step_011,
        text: `After talking with your boss you have learned some ideas on how to manage your team. 
        You decide to have a team meeting to discuss the production schedule.`,
        choices: [            
            {
                choice: 0,
                text: `Although it seemed like your team were ignoring you, 
                you have learned sometimes a leader needs to push on.  
                You tell them that you are responsible for this team and need their support to keep them safe on the job, 
                and keep things moving smoothly in production.  
                You ask for their suggestions and their support.`,
                proceedTo: 'step_013',
                choiceSubText: ``
            },
            {
                choice: 1,
                text: `Your team basically ignore you and you want to give up. You promise to do better. 
                You tell the team that you will draw on your experience to make this a safe and happy work environment for all.`,
                proceedTo: 'step_014',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_013' : {
        step: 13,
        type: 'normal',
        image: step_011,
        text: `The team has suggested changes to some ineffective and time-wasting activities in the plant, 
        as well as documentation that is repetitive and time consuming. After another conversation with 
        your boss, you know these are all changes you can make.`,
        choices: [            
            {
                choice: 0,
                text: `Now that you have started to think about it, you’re not sure how to go about making these changes.  
                You want to be sure you get this right, so you wait to talk to your boss again.`,
                proceedTo: 'step_015',
                choiceSubText: ``
            },
            {
                choice: 1,
                text: `After the chat with your boss, you get the team to help you make the necessary changes. 
                The team responded well to this and seem much more positive on the job.`,
                proceedTo: 'step_016',
                choiceSubText: ``
            },
        ],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_014' : {
        step: 14,
        image: step_012,
        text: `Your boss is frustrated you
        didn’t just go ahead and make
        the changes as she thought
        had been clearly discussed.`,
        choices: [ {
            choice: 0,
            text: `You go back to your team and
            tell them how the changes will be,
            yet the team think since this
            change took so long to happen do
            you really plan to make the
            changes?`,
            proceedTo: 'step_015',
            choiceSubText: ``
        }],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_015' : {
        step: 15,
        type: 'no_choice',
        image: step_012,        
        proceedTo: "step_017",
        text: `Your boss wants to talk to you.  After 8 months of doing the job of team leader, 
        he feels you are really not the right person for this job and that it would be best if you 
        remain as a team member and not a team leader.`,
        choices: [],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_016' : {
        step: 16,
        type: 'no_choice',
        image: step_013,
        proceedTo: "step_018",
        text: `Your team is really starting to work well together.  
        They bring forward issues and concerns and together your team is exceeding production, 
        quality and safety expectations.  Time to celebrate.`,
        choices: [],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_017' : {
        step: 17,
        type: 'end',
        image: step_013,
        text: `YIKES! Let’s straighten this
        mess out.`,
        choices: [],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    'step_018' : {
        step: 18,
        type: 'end',
        image: step_013,
        text: `Congratulations! Awesome
        Team Leader.`,
        choices: [],
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
};