import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import styles from '../../../../Styles';
import DiSC_Icon from '../images/DiSC_Icon.png'

class DiscCard extends Component {
  render() {
    const { classes, card_words, card_subwords, card_fun, card_category } = this.props;
    return (
                <Card className={`${classes.discCard} ${classes[card_category]}`} variant="outlined">
                    <CardActionArea className={`${classes.alignVerticalCenter} ${classes.cardActionPadding}`}>
                        <Grid container className={classes.alignVerticalCenter}>
                            <Grid item xs={3} align="center">
                                <img src={DiSC_Icon} className={classes.iconStyle} alt="Game"/>
                                { card_fun ? <Typography variant="body2" align="center">
                                    FUN
                                </Typography>
                                : null }
                            </Grid>
                            <Grid item xs={9} className={classes.cardPadding}>
                                <Typography variant="body1">
                                    {card_words}
                                </Typography>
                                <Typography color="textSecondary" variant="body2" gutterBottom>
                                    {card_subwords}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Card>
           
    );
  }
}

DiscCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DiscCard);



