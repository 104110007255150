export const SESSION_LOADED = 'SESSION_LOADED';
export const SESSION_ERROR_1 = 'ERROR CREATING SESSION';
export const SESSION_ERROR_2 = 'ERROR LOADING SESSION';
export const SESSION_ERROR_3 = 'ERROR ENDING SESSION. NOT FOUND.';
export const SESSION_ARCHIVED = 'SESSION ARCHIVED SUCCESSFULLY';
export const SESSION_ERROR_4 = 'ERROR REMOVING OLD SESSION';
export const SESSION_ERROR_5 = 'ERROR ARCHIVING SESSION';

export const maybeCreateSession = (user) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const url_code = Date.now() + Math.floor(Math.random() * 1000 + 1);
    const doc_id = (firebase.auth().currentUser.uid + Math.floor(Math.random() * 1000 + 1)).toString();
    if (user.role === 'host') {
      firestore
        .collection('current_sessions')
        .doc(doc_id)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            const d = {
              host: firebase.auth().currentUser.uid,
              url: url_code.toString(),
              players: [],
              moderators: [],
              past_games: [],
              active_game: {},
            };

            firestore
              .collection('current_sessions')
              .doc(doc_id)
              .set(d)
              .then(() => {
                dispatch({ type: SESSION_LOADED });
              })
              .catch((err) => {
                dispatch({ type: SESSION_ERROR_1, err });
              });
          }
        })
        .catch((err) => {
          dispatch({ type: SESSION_ERROR_2, err });
        });
    } else {
      firebase.auth().signOut();
    }
  };
};

export const addModeratorToSession = (uid, host) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
         
          doc.ref
            .update({
              moderators: firestore.FieldValue.arrayUnion(uid),
            })
            .then(() => {
              dispatch({ type: 'MODERATOR_JOINED' });
            })
            .catch((err) => {
              dispatch({ type: 'MODERATOR_JOIN_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'MODERATOR_JOIN_ERROR', err });
      });
  };
}

export const maybeSignOutUser = (user) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    if (user.role === 'player') {
      // TODO: remove player from DB
      firebase.auth().signOut();
    }
  };
};

export const endSession = () => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const ref = firestore.collection('current_sessions').where('host', '==', user.uid);

    ref
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              dispatch({ type: SESSION_ARCHIVED });
            })
            .catch((err) => {
              dispatch({ type: SESSION_ERROR_4, err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: SESSION_ERROR_4, err });
      });
  };
};