import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ViewListIcon from '@material-ui/icons/ViewList';
import walk_board from './../images/walk_board.png';
import walk_step from './../images/walk_step.png';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const INITIAL_STATE = {
    open: false,
    legendOpen: false
};

class HostInstructions extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    handleLegendClickOpen = () => {
        this.setState({
            ...this.state,
            legendOpen: true
        });
    };
    
    handleLegendClose = () => {
        this.setState({
            ...this.state,
            legendOpen: false
        });
    };

    render() {
        const {
        classes,
        } = this.props;

        return (
        <div>
            <Typography variant='h5' className={classes.greyText}>
                Host Tools
            </Typography>
            <div className={classes.spacingTop}>
                <div><Button 
                        variant="contained"
                        color="primary"
                        startIcon={<SpeakerNotesIcon />}
                        onClick={this.handleClickOpen}
                        >
                    Leader Notes
                    </Button>
                    <div className={classes.spacingTop}>
                        <Button 
                            variant="contained"
                            color="primary"
                            startIcon={<ViewListIcon />}
                            onClick={this.handleLegendClickOpen}
                            >
                        State Legend
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                <AppBar position="static">  
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                        <Typography variant="h6" className={classes.boldedText}>
                        Leader Notes
                        </Typography>                    
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} className={classes.dialogueGrid}>
                    <Grid item xs={12}  className={classes.spacingTop}>
                        <div className={classes.bodyText}> 
                            <div className={classes.boldedText}>Gallery Walk</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>Move around to the different scenarios.</li>
                                <li className={classes.properListItem}>Record your learnings from the readings.</li>
                            </ul>
                            <br/>
                            <div className={classes.boldedText}>What I Ask of You</div>
                            <ul className={classes.properList}>
                                <li className={classes.properListItem}>Appreciate that my work is no <span className={classes.boldedText}>E</span>asier than yours.</li>
                                <li className={classes.properListItem}>Don't assume the worst of <span className={classes.boldedText}>M</span>e.</li>
                                <li className={classes.properListItem}>Continue to <span className={classes.boldedText}>P</span>erform your job as best you can.</li>
                                <li className={classes.properListItem}><span className={classes.boldedText}>A</span>dopt the mindset that to be successful at work, you need me as much as I need you.</li>
                                <li className={classes.properListItem}>Assume half <span className={classes.boldedText}>T</span>he responsibility for our working relationship.</li>
                                <li className={classes.properListItem}>Remember that I'm <span className={classes.boldedText}>H</span>uman.</li>
                                <li className={classes.properListItem}>If <span className={classes.boldedText}>Y</span>ou do, I think you'll find...</li>
                                <li className={classes.properListItem}>With more understanding and great appreciation, we can meet in the middle and walk toward a more successful future TOGETHER.</li>
                            </ul> 
                        </div>                     
                    </Grid>
                </Grid>                 
            </Dialog>
            <Dialog  open={this.state.legendOpen} onClose={this.handleLegendClose} TransitionComponent={Transition}>
                <AppBar position="static">  
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={this.handleLegendClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                        <Typography variant="h6" className={classes.boldedText}>
                        State Legend
                        </Typography>                    
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} className={classes.dialogueGrid}>
                    <Grid item xs={12}  className={classes.spacingTop}>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Board</Typography>
                        <div>
                            <img src={walk_board} alt='senario one' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Step</Typography>
                        <div>
                            <img src={walk_step} alt='what did you learn' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                        <Typography variant="body1">Step # corresponds to the scenario.</Typography>
                    </Grid>
                </Grid>                  
            </Dialog>
        </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(HostInstructions);