import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { updateGroupStateParamsVal, editResultsPath, updateGroupStateStateVal } from '../../../../../store/actions/gameActions';
import paper from "paper/dist/paper-core"
import { Tangram } from './tangramImport';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { GROUP_STATE_RESULTS, GAME_STATE_RESULTS } from '../../../../../store/actions/gameActions';
import styles from '../../../../Styles';

const INITIAL_STATE = {
    timerActive: true,
    currentInterruption: null
}

class TangramMS extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount(){
        
    }

    componentWillUnmount(){
        paper.project.remove();      
    }

   
   


    render() {
        const { results, classes } = this.props;
        return (
            <div>
                
                <div style={{height: "700px", width: "920px", display: "flex", border: "1px solid #000", borderRadius: "5px", marginTop: "30px"}}>
                    <Tangram group_id={this.props.group_id} role={this.props.role} tangramCoords={this.props.results.results[this.props.group_id] !== undefined ? this.props.results.results[this.props.group_id].tangramCoords : undefined} updateCoords={this.updateTangramCoords}></Tangram>
                </div>
               
            </div>
        ); 
    
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
        updateGroupStateParamsVal : (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID)),
        updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID))
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TangramMS);