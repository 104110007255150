import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './../../store';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
import LogoAlt from '../../images/LogoAlt.png';

const styles = StyleSheet.create({
    image: {
        marginVertical: 15,
        marginHorizontal: 220,
      },
});

export const PDFHeader = (props) => {
    return (
        <View style={{ textAlign: "center", width: "100%"}}>
            <Image
                style={styles.image}
                src={LogoAlt}
            />
        </View>
    )
}