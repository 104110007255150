import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: "1.1rem"
    },
    helpText: {
        fontWeight: "bold",
        paddingTop: "30px",
        fontSize: "1.2rem"
    },
    spacingTop: { paddingTop: "30px" },
    spacingBottom: { paddingBottom: "30px" },
    lister: { listStyleType: "disc" },
    list: { listStyleType: "disc", paddingLeft: "1rem" }
}));

export default function ActivityHelpDelta() {
    const classes = useStyles();  
    const [open, setOpen] = useState(false);

    return (
        <div className={classes.root}>
            <HelpOutlineIcon onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Transition} maxWidth="sm" fullWidth>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.boldedText}>
                        Help - Delta
                        </Typography>                    
                    </Toolbar>
                </AppBar>
                <DialogContent>
                <Grid container spacing={2} className={classes.spacingBottom}>
                    <Grid item xs={12} >
                        <div className={classes.root}>
                            <ul className="browser-default">
                                <li className={classes.lister}>What were the challenges?</li>
                                <li className={classes.lister}>What would you like to see/do different?</li>
                                <li className={classes.lister}>Or less of?</li>
                            </ul>
                        </div>
                        <div className={classes.root}>
                            To add an entry, tap on the pencil icon in the Delta column.
                        </div>
                        <div className={classes.root}><br/>
                            To edit or delete an entry, tap on the entry itself and then select the appropriate option.
                        </div>
                    </Grid>
                </Grid>
                </DialogContent>                 
            </Dialog>
        </div>
    );  
}