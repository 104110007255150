import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import { removePlayerSignOut } from '../../store/actions/gameActions';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import styles from './../Styles';
import { getUserGroup_hc } from './../../components/Game/games/game_functions/functions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const signOutLinks = (auth, session, t, classes) => {
  if ( auth === undefined ) {
    return false;
  }
  if ( window.location.pathname !== '/p' && window.location.pathname.substring(0, 3) !== '/p/' ){
    return true;
  }
  return false;
}


class Footer extends Component{

  signUserOut = () => {
    if ( this.props.profile.role === 'host' || this.props.profile.role === 'moderator' ){
      this.props.signOut();
    } else {
      if ( this.props.session === undefined ){
        this.props.signOut();
      } else {
        if ( this.props.session.active_game.id === undefined ){
          this.props.removePlayerSignOut(this.props.auth.uid, getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role), this.props.session.playerProfiles, false);
        } else {
          this.props.removePlayerSignOut(this.props.auth.uid, getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role), this.props.session.playerProfiles, true);
        }
      }
    }
  }

  render() {

    const { t, classes, session, auth, profile, loading } = this.props;

    return (
      
      <div className={classes.footer}>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid container item xs={6} spacing={1} className={classes.languageTop}>
                      <Grid item xl={1} lg={2} md={2} xs={2}></Grid>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12}>
                      <Typography variant='body2' className={classes.copy}>
                        {t('footer.finePrintOne')}
                      </Typography>
                      <Typography variant='body2' className={classes.copy}>
                        {t('footer.finePrintTwo')}
                      </Typography>
                      <Typography variant='body2' className={classes.copy}>
                        {t('footer.finePrintThree', { year: moment().format('YYYY') })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      { profile !== undefined ?
                        profile.role === 'player' ?
                        <Typography variant="body2" className={classes.copy}>Welcome, {profile.displayName}</Typography>
                        :
                        <Typography variant="body2" className={classes.copy}>You are signed in as, {profile.displayName}</Typography>
                      : null }
                    </Grid>
                    <Grid item xs={6}>
                      { signOutLinks(auth, session, t, classes ) ? 
                        <Button onClick={() => this.signUserOut()} className={classes.white} style={{padding:0, minWidth: "0"}}>
                          {t('navigation.signOutButton')}
                        </Button>
                      : null }
                    </Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    loading: state.game_state.footer_loading
  };
};

const mapDisptachToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    removePlayerSignOut: (player, group, playersList, isGameActive) => dispatch(removePlayerSignOut(player,group, playersList, isGameActive))
  };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDisptachToProps), withStyles(styles))(Footer);
