import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { CIRCLE_CONCERN, CIRCLE_INFLUENCE } from './activity';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircleOfInfluenceDefbrief from './images/CircleOfInfluenceDebrief.png'
import { getUserGroup_hc} from '../game_functions/functions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../Styles';
import LiquidLoadingBlock from '../../../Loading/loadingCup';



class Results extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

  render() {
    const { session, classes } = this.props;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    return (
      <div>
        <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant="h5">Wrapping Up!</Typography>
            <div>
                <Typography variant="body1">Waiting for other groups to finish their activity.</Typography>
              <br />
              <br />
              <div className={classes.cupContainerLeft}>
                <LiquidLoadingBlock/>
              </div>
            </div>
            </Grid>
          <Grid item xs={12} sm={6}>            
            <img src={CircleOfInfluenceDefbrief} className={classes.imageSize} alt="Circle of Influence"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Circle of Concern</Typography>
                {/* Results Here */}
                <List className={classes.listClass}>
                  {session.active_game.results.hasOwnProperty(group_id)
                    ? session.active_game.results[group_id].hasOwnProperty(CIRCLE_CONCERN)
                      ? session.active_game.results[group_id][CIRCLE_CONCERN].map((item, index) => {
                          return (
                            <ListItem key={index}>
                              <ListItemText primary={item} />
                            </ListItem>
                          );
                        })
                      : null
                    : null}
                </List>  
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Circle of Influence</Typography>
                {/* Results Here */}
                <List className={classes.listClass}>
                  {session.active_game.results.hasOwnProperty(group_id)
                    ? session.active_game.results[group_id].hasOwnProperty(CIRCLE_INFLUENCE)
                      ? session.active_game.results[group_id][CIRCLE_INFLUENCE].map((item, index) => {
                          return (
                            <ListItem key={index}>
                              <ListItemText primary={item} />
                            </ListItem>
                          );
                        })
                      : null
                    : null}
                </List>  
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Results);
