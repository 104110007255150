
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
  TableBody, TableCell, Collapse, TableHead, TableRow, Box, } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { signOut } from '../../store/actions/authActions';
import Footer from '../Footer';
import LogoHeader from '../Header';
import styles from '../Styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {closeAdminPopUps, renameUser,resetPasswordFromAdmin,toggleStatus} from '../../store/actions/adminActions';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { deepPurple } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import Navigation from '../Navigation';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { accessCheck } from './../Functions';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { ADMIN, THEME, PLANNING, PASTDATA } from '../../constants/routes';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import AssessmentIcon from '@material-ui/icons/Assessment';




const INITIAL_STATE = {
  links: [
    { to: ADMIN, text: 'User Management', description: 'Manage Users for your Account' },
    { to: PASTDATA, text: 'Past Session Data', description: 'View activity results from previous sessions' },
    { to: THEME, text: 'Theme Management', description: 'Adjust theming for your account' },
    { to: PLANNING, text: 'Session Planning', description: 'Plan sessions in advance with a range of tools to help plan users and groups.' }
  ]
};

const GameCard = withStyles((theme) => ({
  root: {
    '&:focused': {
      background: deepPurple[100],
    },
  }
}))(CardContent);
 

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
        ...INITIAL_STATE
        };

    }

    getImage = (to) => {
      switch (to){
        case ADMIN:
          return <PersonIcon style={{fontSize: 190, color: deepPurple[500]}}/>
        case PASTDATA:
          return <AssessmentIcon style={{fontSize: 190, color: deepPurple[500]}}/>
        case THEME:
          return <ColorLensIcon style={{fontSize: 190, color: deepPurple[500]}}/>
        case PLANNING: 
          return <ScheduleIcon style={{fontSize: 190, color: deepPurple[500]}}/>
        default:
          return <PersonIcon style={{fontSize: 190, color: deepPurple[500]}}/>
      }
    }


    render() {
        const { t, classes } = this.props;

        if ( !this.props.auth.uid ){
            return <Redirect to={ROUTES.LOGIN} />;
        }

        const name = this.props.profile === undefined ? '': this.props.profile.displayName;
        console.log('prop', this.props.sessions);
        return (
            <div className='meta-react'>
                <Navigation  profile={this.props.profile} auth={this.props.auth}/>
                <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} />
                <div className='outer-container'>
                    <div className='container'>
                        <Typography variant="h2">Dashboard</Typography>
                        {
                            this.props.profile.role === 'admin' ? 
                            <Grid container>
                                <Grid item xs={12} style={{marginBottom:"20px"}}>Welcome, <span style={{fontWeight:"bold"}}>{name}</span></Grid>
                                <Grid item xs={6}>Number of Host Accounts: {this.props.hosts === undefined ? 0 : this.props.hosts.length}</Grid>
                                <Grid item xs={6}></Grid>
                            </Grid>
                            : 
                            <Grid container>
                                <Grid item xs={12} style={{marginBottom:"20px"}}>Welcome, <span style={{fontWeight:"bold"}}>{name}</span></Grid>
                                <Grid item xs={6}>Moderators for your Account: {this.props.moderators === undefined ? 0 : this.props.moderators.length}</Grid>
                                <Grid item xs={6}></Grid>
                            </Grid>
                        }

                        <div style={{paddingTop: 60}}>
                          <Grid container spacing={2} className={classes.stretchContainer}>
                            {this.state.links.map((link, index) =>
                            
                                <Grid item lg={4} key={index} className={classes.stretchMe} onClick={() => {window.location = link.to}}>
                                  <Card className={classes.gameListRoot} >
                                    <CardActionArea>
                                      <CardMedia className={`${classes.media} ${classes.lightPurpleBG}`} title={``} style={{textAlign: "center", fontSize: 60}}>
                                        {this.getImage(link.to)}
                                      </CardMedia>
                                      <GameCard>
                                        <Typography gutterBottom variant='h5' component='h2' className={classes.glassPurple}>
                                          {link.text}
                                        </Typography>
                                        <Typography variant='body1' color='textSecondary' component='p'>
                                          {link.description}
                                        </Typography>
                                      </GameCard>
                                    </CardActionArea>
                                    
                                  </Card>
                                </Grid>                          
                            )}
                          </Grid>
                        </div>
                        
                    </div>
                </div>
                <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/>
            </div>
        );

    }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    sessions: state.firestore.ordered.current_sessions,
    hosts: state.firestore.ordered.hosts,
    moderators: state.firestore.ordered.moderators,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => {
      console.log('test: ', props.profile, props.auth);
    if ( props.auth.uid === undefined || props.profile.isLoaded === false || props.profile === undefined ){
      return [];
    }
    if ( props.profile.role === 'admin' ){
      return [
        {
          collection: 'players',
          where: [
            ['role', '==', 'host'],      
          ],
          storeAs: 'hosts'
        },
        {
          collection: 'players',
          where: [
            ['role', '==', 'moderator']        
          ],
          storeAs: 'moderators'
        },
        {
            collection: 'current_sessions'
        }
      ]
    } else {
      return [
        {
          collection: 'players',
          where: [
            ['role', '==', 'moderator'],    
            ['moderates', '==', props.auth.uid]    
          ],
          storeAs: 'moderators'
        },
        {
            collection: 'current_sessions',
            where: [
                ['host', '==', props.profile.role === 'host' ? props.auth.uid : props.profile.moderates]
            ]
        }
      ]
    }
})     
)(Dashboard);