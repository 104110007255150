import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import styles from '../../../../Styles';
import Button from '@material-ui/core/Button';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import TrustCard from './card';
import { CardList } from '../cards/cardList';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import AddIcon from '@material-ui/icons/Add';
import { updateResults, editResults } from './../../../../../store/actions/gameActions';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DoneIcon from '@material-ui/icons/Done';

const INITIAL_STATE = {
  open: false,
  anchorEl: null,
  curCard: null,
  mouseY: null,
  mouseX: null,
  selectedCard: null,
};


class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleDrawerOpen = () => {
    this.setState({
      ...this.state,
      open: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  doMoveAction = (columnId) => {
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let selCard = this.state.selectedCard;
    this.props.updateResults(group_id, columnId, selCard, determineHost(this.props.profile, this.props.auth));
    this.handleContextClose();
  }

  contextHandler = (event, card) => {
    //console.log('here?', event.clientX - 2, event.clientY - 4);
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      ...this.state,
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      selectedCard: card.id
    });
  }
  
  handleContextClose = () => {
    this.setState({
      ...this.state,
      mouseX: null,
      mouseY: null
    });
  }

  render() {
    const { classes, session, profile, auth } = this.props;
    const { open, anchorEl } = this.state;
    const group_id = getUserGroup_hc(session, auth.uid, profile.role);
    const popopen = Boolean(anchorEl);
    const id = popopen ? 'simple-popover' : undefined;
    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);

    let res = session.active_game.results[group_id] === undefined ? {} : session.active_game.results[group_id];
    let res1 = res[1] === undefined ? [] : res[1];
    let res2 = res[2] === undefined ? [] : res[2];
    let res3 = res[3] === undefined ? [] : res[3];
    let res4 = res[4] === undefined ? [] : res[4];
    let res5 = res[5] === undefined ? [] : res[5];

    let usedCards = res1.concat(res2).concat(res3).concat(res4).concat(res5);

    return (
      <div>
        
          <Button 
              variant="contained"
              color="primary"
              startIcon={<ViewAgendaIcon />}
              onClick={this.handleDrawerOpen} className={clsx(open && classes.hide)}>
          View Trust Cards
          </Button>
          <Button 
              variant="contained"
              color="primary"
              disabled={true}
              startIcon={<ViewAgendaIcon />}
              onClick={this.handleDrawerOpen} className={clsx(!open && classes.hide)}>
          Browsing Trust Cards
          </Button>
          {CardList.length === usedCards.length && hasControl === true ?
            <Button type='submit' color="primary" variant='contained' onClick={this.props.finishActivity} startIcon={<DoneIcon/>} style={{marginLeft: "5px"}}>
            Finish Activity
          </Button>
          : null}
          
          <Drawer anchor='right' open={open} onClose={this.handleDrawerClose}>
          <Menu
          keepMounted
          open={this.state.mouseY !== null}
          onClose={this.handleContextClose}
          anchorReference="anchorPosition"
          anchorPosition={
            this.state.mouseY !== null && this.state.mouseX !== null
              ? { top: this.state.mouseY, left: this.state.mouseX }
              : undefined
          }
        >
                <MenuItem  onClick={() => this.doMoveAction(1)}>Move to&nbsp;<span style={{fontWeight: "bold"}}>Column #1</span></MenuItem>
                <MenuItem  onClick={() => this.doMoveAction(2)}>Move to&nbsp;<span style={{fontWeight: "bold"}}>Column #2</span></MenuItem>
                <MenuItem  onClick={() => this.doMoveAction(3)}>Move to&nbsp;<span style={{fontWeight: "bold"}}>Column #3</span></MenuItem>
                <MenuItem  onClick={() => this.doMoveAction(4)}>Move to&nbsp;<span style={{fontWeight: "bold"}}>Column #4</span></MenuItem>
                <MenuItem  onClick={() => this.doMoveAction(5)}>Move to&nbsp;<span style={{fontWeight: "bold"}}>Column #5</span></MenuItem>
                <MenuItem  onClick={() => this.handleContextClose()}>Cancel</MenuItem>

        </Menu>
              <div className={classes.drawerHeader}>
                <IconButton onClick={this.handleDrawerClose}>
                  <ChevronRightIcon />
                </IconButton>{' '}
                <span className={classes.playersTitle}>Trust Cards</span>
              </div>
              <List className={`${classes.relativeList} ${classes.doNotPadTop}`}>
                <ListItem className={classes.doNotPad}>
                  <List className={`${classes.horizontalList} ${classes.borderTop}`}>

                      { 
                        
                        CardList.length === usedCards.length ?
                            <ListItem className={classes.stretchToFit}>
                              No cards to show.
                            </ListItem>
                        :
                      
                        CardList.map((card, ind) => {
                          if ( usedCards.includes(card.id) ){
                            return null;
                          }

                          if ( !hasControl ){
                            return (<ListItem key={ind} className={classes.stretchToFit}>
                                <TrustCard type={`full`} session={session} card={card} profile={profile} auth={auth} card_id={card.id} card_words={card.card_words} card_category={card.card_category} card_subwords={card.card_subwords} card_fun={card.card_fun}/>
                            </ListItem>)
                          } else {
                            return (<ListItem  onClick={(e) => this.contextHandler(e, card)} key={ind} className={classes.stretchToFit}>
                                <TrustCard type={`full`}  session={session} card={card} profile={profile} auth={auth} card_id={card.id} card_words={card.card_words} card_category={card.card_category} card_subwords={card.card_subwords} card_fun={card.card_fun}/>
                            </ListItem>)
                          }
                        
                      })
                    
                    }
                  </List>
                </ListItem>
              </List>
          </Drawer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Cards);