import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {  GROUP_STATE_RESULTS } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  hasGameControl,
  getUserGroup_hc,
  determineHost
} from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults } from './../../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingDots from './../../game_components/loadingDots';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Slide from '@material-ui/core/Slide';



const INITIAL_STATE = {
  open: false,
  entry_text: '',
  isEdit: false,
  curQuestion: null,
  helpOpen: false,
  curQ_FS: null
};
export const Q1 = 'How I like to work and the kind of work I like to do…';
export const Q2 = 'People drive me crazy when...';
export const Q1_FS = 'q1';
export const Q2_FS = 'q2';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class ActivityTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( this.props.session.active_game.results[group_id] !== undefined ){
      this.setState({
          ...this.state,
          choices: this.props.session.active_game.results[group_id].card_choices
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( this.state.choices.length > 0 && (prevProps.session.active_game.results[group_id] !== this.props.session.active_game.results[group_id]) ){
      
        if ( this.state.choices !== this.props.session.active_game.results[group_id].card_choices ){
          this.setState({
            ...this.state,
            choices: this.props.session.active_game.results[group_id].card_choices
        });
        }
    }
  }

  addToQ = (q, qfs) => {
    this.setState({
        ...this.state,
        entry_text: '',
        open: true,
        curQuestion: q,
        curQ_FS: qfs
      });
  
      let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
      let s = {
        ...this.props.session.active_game.groupStates[groupID].params,
        writing: qfs,
      };
      this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));
  }

  handleClose = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  handleSave = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    this.props.updateResults(groupID, this.state.curQ_FS, this.state.entry_text, determineHost(this.props.profile, this.props.auth));
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
    });
  };

  

  itemAction = (index, value, curQFS, Q) => {
    this.setState({
      ...this.state,
      isEdit: index,
      open: true,
      curQ_FS: curQFS,
      curQuestion: Q,
      entry_text: value,
    });
  };

  updateRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.curQ_FS]];
    c[row] = this.state.entry_text;

    this.props.editResults(groupID, this.state.curQ_FS, c, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  deleteRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.curQ_FS]];
    c.splice(row, 1);

    this.props.removeResults(groupID, this.state.curQ_FS, c, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  finishActivity = () => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_RESULTS,
      params: {},
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const choices = reorder(
      this.state.choices,
      result.source.index,
      result.destination.index
    );

    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    this.props.editResults(groupID, 'card_choices', choices, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      choices
    });
    return;
  }
  
  handleHelpClose = () => {
    this.setState({
      ...this.state,
      helpOpen: false
    });
  }

  openHelp = () => {
    this.setState({
      ...this.state,
      helpOpen: true
    })
  }

  render() {
    const {
      session,
      profile,
      auth,
      classes,
    } = this.props;
    const { open, helpOpen, entry_text } = this.state;
    const isInvalid = entry_text === '';

    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    return (
      <div>
        <Typography variant='h4' >{session.active_game.name}</Typography>   
        <Typography variant="h5" className={classes.spaceBottom}>Answer the following questions:</Typography>
                <Dialog open={open} onClose={this.handleClose} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth>
              <DialogContent>
                <DialogContentText className={classes.bodyText}>{this.state.isEdit !== false ? `Editing answer for "${this.state.curQuestion}"` : `${this.state.curQuestion}? Type your entry below and hit save.`}</DialogContentText>
                <TextField multiline autoFocus autoComplete="off" margin='dense' id='entry_text' name='entry_text' value={entry_text} onChange={this.onChange} label="Answer" type='email' fullWidth />
              </DialogContent>
              <DialogActions>
                {this.state.isEdit !== false ? (
                  <div>
                    <Button onClick={() => this.deleteRow(this.state.isEdit)} color='secondary'>
                      Delete
                    </Button>
                    <Button onClick={this.handleClose} color='primary'>
                      Cancel
                    </Button>
                    <Button onClick={() => this.updateRow(this.state.isEdit)} color='primary'>
                      Save
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button onClick={this.handleClose} color='primary'>
                      Cancel
                    </Button>
                    <Button disabled={isInvalid} onClick={this.handleSave} color='primary'>
                      Save
                    </Button>
                  </div>
                )}
              </DialogActions>
            </Dialog>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Grid container className={classes.spacingBottom}>
                    <Grid item xs={6}>
                        <Typography variant="body1">How I like to work and the kind of work I like to do…</Typography>
                    </Grid>
                    <Grid item xs={6} align="right">
                        { hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile) ? 
                        <Button type='submit'  onClick={() => this.addToQ(Q1, Q1_FS)} startIcon={<AddIcon/>}>
                        Add Answer
                        </Button>
                        : null }
                    </Grid>
                </Grid>
                <List className={classes.listClass}>
                    {session.active_game.results.hasOwnProperty(group_id)
                        ? session.active_game.results[group_id].hasOwnProperty(Q1_FS)
                        ? session.active_game.results[group_id][Q1_FS].map((item, index) => {
                            return (
                                hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile) ?
                                <ListItem key={index} button onClick={() => this.itemAction(index, item, Q1_FS, Q1)}>
                                <ListItemText primary={item} className={`${classes.bodyText}`} />
                                </ListItem>
                                : 
                                <ListItem key={index}>                 
                                    <ListItemText
                                        className={classes.bodyText} 
                                        primary={item}                                
                                    />
                                </ListItem>
                            );
                            })
                        : null
                    : null}
                    {session.active_game.groupStates[group_id].params.writing === Q1_FS ? <ListItem className={classes.setHeightListItem}><LoadingDots /></ListItem> : null}
                </List>                              
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid container className={classes.spacingBottom}>
                    <Grid item xs={6}>
                        <Typography variant="body1">People drive me crazy when...</Typography>
                    </Grid>
                    <Grid item xs={6} align="right">
                        {  hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile) ?
                        <Button type='submit'  onClick={() => this.addToQ(Q2, Q2_FS)} startIcon={<AddIcon/>}>
                        Add Answer
                        </Button>
                        : null }
                    </Grid>
                </Grid>
                <List className={classes.listClass}>
                    {session.active_game.results.hasOwnProperty(group_id)
                        ? session.active_game.results[group_id].hasOwnProperty(Q2_FS)
                        ? session.active_game.results[group_id][Q2_FS].map((item, index) => {
                            return (
                                hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile) ?
                                <ListItem key={index} button onClick={() => this.itemAction(index, item, Q2_FS, Q2)}>
                                <ListItemText primary={item} className={`${classes.bodyText}`} />
                                </ListItem>
                                : 
                                <ListItem key={index}>                 
                                    <ListItemText
                                        className={classes.bodyText} 
                                        primary={item}                                
                                    />
                                </ListItem>
                            );
                            })
                        : null
                    : null}
                    {session.active_game.groupStates[group_id].params.writing === Q2_FS ? <ListItem className={classes.setHeightListItem}><LoadingDots /></ListItem> : null}
                </List>   
            </Grid>
          </Grid>
          <Grid container className={classes.spacingTop}>
              <Grid item xs={6}>
                  <Button 
                      variant="contained"
                      color="primary"
                      startIcon={<LiveHelpIcon />}
                      className={classes.spaceBottom}
                      onClick={() => this.openHelp()}
                      >
                      What Should I Do?
                  </Button>
              </Grid>
              <Grid item xs={6} align="right">
                  <Button 
                      variant="contained"
                      disabled={!hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile)}
                      color="primary"
                      startIcon={<DoneIcon />}
                      className={classes.spaceBottom}
                      onClick={this.finishActivity}
                      >
                      Finish Activity
                  </Button>
              </Grid>
          </Grid>
          <Dialog
            open={helpOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleHelpClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
                <Typography variant="h4">What Should I Do?</Typography>
                <Typography variant="body1">For this part of the exercise, your group should come up with a set of answers for each of the 2 questions below.</Typography>
                <Typography variant="body1" className={classes.spacingTop}>As a leader, you can tap on the Add Answer button to add an answer to the corresponding section.</Typography>
                <Typography variant="body1" className={`${classes.spacingTop}`}>If you've made a mistake and want to edit or remove one of your answers, tap on that answer.</Typography>
                <Typography variant="body1" className={`${classes.spacingTop} ${classes.spacingBottom}`}>When you are done providing answers, tap on the Finish Activity button to complete the activity.</Typography>
            </DialogContent>                  
          </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ActivityTwo);
