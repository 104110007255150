import React, { useState, CSSProperties } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
    TableBody, TableCell, Collapse, TableHead, TableRow, Box, } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

export default function EditSession (props) {

    const FORMAT = "YYYY-MM-DD[T]HH:mm";
    const [formFilledOut, setFormFilledOut] = useState(true);
    const [sessionName, setSessionName] = useState(props.session.title);
    const [sessionDate, setSessionDate] = useState(moment(props.session.date).local().format(FORMAT));
    const [sessionError, setSessionError] = useState(false);

    

    const onChangeName = (event) => {
        setSessionName(event.target.value);
    }
    const onChangeDate = (event) => {
        setSessionDate(event.target.value);
    }
    const saveEditSession = () => {
        if ( moment().isAfter(sessionDate) || moment().isSame(sessionDate) ){
            setSessionError('Please choose a date that is sometime in the future.');
        } else {
            setSessionError(false);
            props.saveEditSession(props.session.id, sessionName, moment(sessionDate).utc().toISOString());
        }
    }
    const closeEditSession = () => {
        setSessionError(false);
        props.closeEditSession();
    }

    React.useEffect(() => {
        setSessionName(props.session.title === undefined ? '' : props.session.title);
        setSessionDate(props.session.date === undefined ? '' : moment(props.session.date).local().format(FORMAT));
    }, [props.session]);

    React.useEffect(() => {
        if ( sessionName === '' || sessionDate === '' ){
            setFormFilledOut(false);
        } else {
            if ( formFilledOut === false ){
                setFormFilledOut(true);
            }
        }
    }, [sessionName, sessionDate]);

    return (
        <Dialog aria-labelledby='add-planned-session' aria-describedby='Add Planned Session'  maxWidth='md'  open={props.showEditSession} fullWidth>
                        
                <AppBar position="static" className={props.classes.LNNoShadow}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={()=>closeEditSession()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                            <Typography variant="h6">
                                Edit Session             
                            </Typography>
                    </Toolbar>
                </AppBar>
            <DialogContent>
                
                <form className={props.classes.container} style={{display: 'flex', flexWrap: 'wrap', paddingTop: 30}} noValidate>
                    <div style={{width: '100%'}}><TextField id="outlined-basic" label="Session Title" placeholder={`Session Title`} value={sessionName} variant="outlined" onChange={onChangeName} style={{marginBottom: "20px", width: 400}}/></div>
                    <div style={{width: '100%'}}>
                    <TextField
                        id="datetime-local"
                        label="Date of Session"
                        type="datetime-local"
                        onChange={onChangeDate}
                        value={sessionDate}
                        className={props.classes.textField}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        style={{width: 250}}
                    />
                    </div>
                    <div style={{width: '100%', marginTop: "20px", marginBottom: "20px"}}>
                        <Typography variant="body1" style={{color: "red", fontWeight: "bold"}}>{sessionError}</Typography>                        
                    </div>
                </form>


                
            </DialogContent> 
            <DialogActions>
                <Button size='large' onClick={()=>closeEditSession()}>
                    Cancel
                </Button>
                <Button size='large' disabled={!formFilledOut} onClick={()=>saveEditSession()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
    
}