import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  




export const ComparisonTapTable = (props) => {

  
    

  return (
    <div style={{marginTop: "15px", marginBottom: "15px"}}>
      <div>Tap-Ins</div>
      <TableContainer component={Paper} style={{maxWidth: "600px"}}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Group</StyledTableCell>
              <StyledTableCell align="right" style={{minWidth:"150px"}}>Last Round</StyledTableCell>
              <StyledTableCell align="right">This Round</StyledTableCell>
              <StyledTableCell align="right">Difference</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(props.results.results).sort().map((groupKey, ind) => {
              
              //console.log("starting group", groupKey);

              let thisTapIns = 0;
              let lastTapIns = 0;
              let timeToFirstTapIn = 0;
              let lastTimeToFirstTapIn = 0;

              let avgTapTime = 0;
              let lastAvgTapTime = 0;

              let minutes = 0;
              let remainder = 0;
              let time = '';
            

              let diff = 0;
              let lastDiff = 0;
              
              if ( props.results.results[groupKey] !== undefined ){
                if ( props.results.results[groupKey].tapins !== undefined ){
                  thisTapIns = Object.keys(props.results.results[groupKey].tapins).length;

                  let timeBetween = [];
                  let lastTime = 0;
                  //console.log('started on', props.results.results[groupKey].startedOn);
                  Object.keys(props.results.results[groupKey].tapins).sort().map((key, index) => {
                    //console.log('key?', key);
                    if (index === 0){
                      timeToFirstTapIn = (key - props.results.results[groupKey].startedOn) / 1000;
                    } 
                    //console.log('after', timeToFirstTapIn);

                    if ( index === 0 ){
                      //console.log('what');
                      timeBetween.push( timeToFirstTapIn );
                    } else {
                      timeBetween.push ( Math.abs( key - lastTime ) / 1000 );
                    }
                    lastTime = key;
                  });

                  //console.log('ar', JSON.stringify(timeBetween));

                  var total = 0;
                  for(var i = 0; i < timeBetween.length; i++) {
                      total += timeBetween[i];
                  }
                  avgTapTime = total / timeBetween.length;

                  minutes = Math.floor(avgTapTime / 60);
                  remainder = Math.round(avgTapTime) % 60;

                  time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${Math.round(avgTapTime)} seconds`;
                  avgTapTime = `${time}`;

                  minutes = Math.floor(timeToFirstTapIn / 60);
                  remainder = Math.round(timeToFirstTapIn) % 60;

                  time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${Math.round(timeToFirstTapIn)} seconds`;
                  timeToFirstTapIn = `${time}`;

                } else {
                  avgTapTime = "N/A";
                  timeToFirstTapIn = "N/A";
                }

              } else {
                thisTapIns = "N/A";
                timeToFirstTapIn = "N/A";
                avgTapTime = "N/A";
              }

              if ( props.results.resultSnapshot[groupKey] !== undefined ){
                if ( props.results.resultSnapshot[groupKey].tapins !== undefined ){
                  lastTapIns = Object.keys(props.results.resultSnapshot[groupKey].tapins).length;
                  //console.log('what', lastTapIns);

                  let timeBetween = [];
                  let lastTime = 0;
                  Object.keys(props.results.resultSnapshot[groupKey].tapins).sort().map((key, index) => {
                    if (index === 0){
                      lastTimeToFirstTapIn = (key - props.results.resultSnapshot[groupKey].startedOn) / 1000;
                      //console.log('asdfasdfa', lastTimeToFirstTapIn);
                    } 

                    if ( index === 0 ){
                      timeBetween.push( lastTimeToFirstTapIn );
                    } else {
                      timeBetween.push ( Math.abs( key - lastTime ) / 1000 );
                    }
                    lastTime = key;

                    var total = 0;
                    for(var i = 0; i < timeBetween.length; i++) {
                        total += timeBetween[i];
                    }
                    lastAvgTapTime = total / timeBetween.length;

                    //console.log('checking: ', lastTimeToFirstTapIn, lastAvgTapTime, groupKey);

                    minutes = Math.floor(lastAvgTapTime / 60);
                    remainder = Math.round(lastAvgTapTime) % 60;
  
                    time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${Math.round(lastAvgTapTime)} seconds`;
                    lastAvgTapTime = `${time}`;

                    //console.log('tfw',lastTimeToFirstTapIn);
                    //minutes = Math.floor(lastTimeToFirstTapIn / 60);
                    //remainder = Math.round(lastTimeToFirstTapIn) % 60;
  
                    //console.log('wtf', minutes, remainder);
                    //time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${Math.round(lastTimeToFirstTapIn)} seconds`;
                    //lastTimeToFirstTapIn = `${time}`;

                    //console.log('checking 2: ', lastTimeToFirstTapIn, lastAvgTapTime, groupKey);
                  });
                } else {
                  lastTimeToFirstTapIn = "N/A";
                  lastAvgTapTime = "N/A";
                }
              } else {
                lastTapIns = "N/A";
                lastTimeToFirstTapIn = "N/A";
                lastAvgTapTime = "N/A";
              }

              if ( lastTapIns === "N/A" ){
                diff = "N/A";
              } else {
                diff = thisTapIns - lastTapIns;
              }


              //console.log('checking 3: ', lastTimeToFirstTapIn, lastAvgTapTime, groupKey);

              if ( lastTimeToFirstTapIn !== 'N/A' ){
                minutes = Math.floor(lastTimeToFirstTapIn / 60);
                remainder = Math.round(lastTimeToFirstTapIn) % 60;
    
                //console.log('wtf', minutes, remainder);
                time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${Math.round(lastTimeToFirstTapIn)} seconds`;
                lastTimeToFirstTapIn = `${time}`;
              }
              

              return (
                <StyledTableRow key={ind}>
                  <StyledTableCell component="th" scope="row">
                    {props.results.groups[groupKey].title}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {lastTapIns} Tap-Ins <br/><br/>
         
                    Avg Time Between Tap-Ins: <br/>{lastAvgTapTime === "N/A" ? lastAvgTapTime : `${lastAvgTapTime}`}<br/><br/>
                    Time Until First Tap-In: <br/>{lastTimeToFirstTapIn === "N/A" ? lastTimeToFirstTapIn : `${lastTimeToFirstTapIn}`}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {thisTapIns} Tap-Ins <br/><br/>
                
                    Avg Time Between Tap-Ins: <br/>{avgTapTime === "N/A" ? avgTapTime : `${avgTapTime}`}<br/><br/>
                    Time Until First Tap-In: <br/>{timeToFirstTapIn === "N/A" ? timeToFirstTapIn : `${timeToFirstTapIn}`}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{verticalAlign: "top"}}>
                    {diff}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
