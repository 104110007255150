import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  popover: {
    padding: '20px',
    maxWidth: '350px',
  },
  title: {
    fontWeight: 'bold',
  },
};

const INITIAL_STATE = {
  anchorEl: null,
};

class GameHelp extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleClick = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  render() {
    const { classes } = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <div>
        <IconButton aria-label='Help' onClick={this.handleClick}>
          <HelpIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.popover}>
            <div className={classes.title}>Circle of Concern:</div>
            <p>What are you concerned with – e.g. economy, low sales, department productivity</p>

            <div className={classes.title}>Circle of Influence:</div>
            <p>Concerns that we can do something about or have control over. e.g. how I manage my team, ensuring standards are kept up</p>
          </div>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameHelp);