import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import bbss_logo_line from './../images/bbss_logo_line.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
import { PDFHeader } from './../../../../PastData/PDFHeader';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

   
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.results.resultSnapshot === undefined ? {} : this.props.results.resultSnapshot, this.props.results.results)
        })
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
    const {
      results,
      classes,
      profiles,
      isPDFView
    } = this.props;
    console.log('res', results, profiles);


        if ( this.props.isPDFView === true ){

            return (
                <Document>
                    <Page wrap>
                        <PDFHeader/>
                        <View>
                            <Image style={{marginHorizontal: "50", marginBottom: "30"}} src={bbss_logo_line}/>
                        {Object.keys(results.results)
                        .sort()
                        .map((groupKey, ind) => {
                            if ( results.results[groupKey].boardresults !== undefined ){
                                return (
                               <React.Fragment key={ind}>
                                    <View style={{marginHorizontal: "50", marginBottom: "50"}}>
                                        <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>                                    
                                        { Object.keys(results.results[groupKey].boardresults).sort().map((objKey, index) => {
                                                const name = profiles[results.results[groupKey].boardresults[objKey].uid] === undefined ?
                                                'Host / Moderator' :
                                                profiles[results.results[groupKey].boardresults[objKey].uid].displayName;
                                            return (
                                                <View key={index} style={{marginVertical: "5", flexDirection: "row", border: "1pt solid #000"}}>                                                
                                                    <View style={{ flex: 1, fontSize: "12pt", borderRight: "1pt solid #000", padding: "5", backgroundColor: "rgb(204, 204, 204)"  }}>
                                                        <Text>{name ? name : 'New Player'}</Text>
                                                    </View>
                                                    <View style={{ flex: 2, fontSize: "12pt", padding: "5" }}>
                                                        <Text>{results.results[groupKey].boardresults[objKey].text}</Text>
                                                    </View>
                                                </View>
                                            );
                                        })}
                                    </View>
                                </React.Fragment>       
                                );
                            } else {
                                return null;
                            }
                        })}
                        </View>
                    </Page>
                </Document> 
            )
        }

        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <img src={bbss_logo_line} alt='bring and brag share and steal logo' style={{maxWidth: "65%" }}/>
                <Typography variant='h5' className={classes.spaceBottom} style={{marginLeft: "8px"}}>
                    Debrief
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(results.results)
                .sort()
                .map((groupKey, ind) => {
                    if ( results.results[groupKey].boardresults !== undefined ){
                        return (
                        <div key={ind}>
                            <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{results.groups[groupKey].title}</Typography>
                            <div>
                                <ul className="thumb-list">
                            { Object.keys(results.results[groupKey].boardresults).sort().map((objKey, index) => {
                                    const name = profiles[results.results[groupKey].boardresults[objKey].uid] === undefined ?
                                    'Host / Moderator' :
                                    profiles[results.results[groupKey].boardresults[objKey].uid].displayName;
                                return (
                                    <li key={index}>
                                        <Grid container>
                                            <Grid item xs={4} md={3} align="center" style={{padding:"15px", backgroundColor: "#ccc"}}>
                                                <Avatar className={` ${classes.medium} ${classes.glassPurpleAvatar}`}></Avatar>
                                                <Typography variant='body1' className={classes.nameSpacing} style={{fontWeight: "bold"}}>
                                                {name ? name : 'New Player'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} md={9} style={{padding: "15px"}}>
                                                <div dangerouslySetInnerHTML={{__html:results.results[groupKey].boardresults[objKey].text}}></div>
                                            </Grid>
                                        </Grid>  
                                    </li>
                                );
                            })}
                                </ul>
                            </div>
                        </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </Grid>
            </Grid>
        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);