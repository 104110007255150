import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {GROUP_STATE_RESULTS} from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, getUserGroup_hc, determineHost} from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResultsPathObject, editResults, removeResults } from './../../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import styles from '../../../../Styles';
import bbss_logo_line from './../images/bbss_logo_line.png';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import { DragDropContext } from 'react-beautiful-dnd';
import DropArea from './../specific_components/dropArea';

const INITIAL_STATE = {  
  open: false,  
  postits: [],
  isEdit: false,
  controlEdit: false
};

class Activity extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) { }

  addToQ = () => {
   
    this.setState({
        ...this.state,
        entry_text: '',
        open: true
      });
  }

  handleClose = (event) => {
    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  handleSave = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    let id = Date.now() + '_' + this.props.auth.uid;
    let path = `active_game.results.${groupID}.boardresults.${id}`;
    this.props.updateResultsPathObject(path, {
      text: this.state.entry_text.replaceAll('\n', '<br/>'), 
      uid: this.props.auth.uid,
      added: Date.now()
      },
      determineHost(this.props.profile, this.props.auth));
   
    this.setState({
      ...this.state,      
      controlEdit: false,
      open: false,
    });
  };

  itemAction = (index, value, hasControl) => {
    this.setState({
      ...this.state,
      isEdit: index,
      open: true,
      controlEdit: hasControl,
      entry_text: value.replaceAll('<br/>', '\n'),
    });
  };

  updateRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let text = this.state.entry_text.replaceAll('\n', '<br/>');
    let path = `active_game.results.${groupID}.boardresults.${row}.text`;

    this.props.updateResultsPathObject(path, text, determineHost(this.props.profile, this.props.auth));
    this.setState({
      ...this.state,
      open: false,      
      controlEdit: false,
      isEdit: false,
    });
  };

  deleteRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.curColumn_FS]];
    c.splice(row, 1);

    this.props.removeResults(groupID, this.state.curColumn_FS, c, determineHost(this.props.profile, this.props.auth));
    this.setState({
      ...this.state,
      open: false,
      controlEdit: false,
      isEdit: false,
    });
  };

  finishActivity = () => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_RESULTS,
      params: {}
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      session,
      profile,
      auth,
      classes,
      game_options
    } = this.props;
    const { entry_text } = this.state;
    const isInvalid = entry_text === '';
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);
    const results = session.active_game.results[group_id] === undefined ?
                      {} :
                      session.active_game.results[group_id].boardresults;
                    
    return (
      <div>
         <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth PaperProps={{style: {backgroundColor: '#fdfd86'}}}>
            <DialogContent>
              <DialogContentText className={classes.bodyText}>{this.state.isEdit !== false ? `` : `Creating. Type your entry below and hit save. You can hit enter to proceed to a new line.`}</DialogContentText>
              { this.state.controlEdit === true || this.state.isEdit === false ? 
              <TextField multiline autoFocus autoComplete="off" margin='dense' id='entry_text' name='entry_text' value={entry_text} onChange={this.onChange} label="Entry" type='email' fullWidth />
              : <div dangerouslySetInnerHTML={{ __html: this.state.entry_text}}></div> }
            </DialogContent>
            <DialogActions>
              {this.state.isEdit !== false ? (
                this.state.controlEdit === true ?
                <div>
                  <Button onClick={() => this.deleteRow(this.state.isEdit)} color='secondary'>
                    Delete
                  </Button>
                  <Button onClick={this.handleClose} color='primary'>
                    Cancel
                  </Button>
                  <Button onClick={() => this.updateRow(this.state.isEdit)} color='primary'>
                    Save
                  </Button>
                </div>
                : null
              ) : (
                <div>
                  <Button onClick={this.handleClose} color='primary'>
                    Cancel
                  </Button>
                  <Button disabled={isInvalid} onClick={this.handleSave} color='primary'>
                    Save
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>
        <Grid container spacing={2}>    
          <Grid item xs={12} md={12} lg={12}>
          <img src={bbss_logo_line} alt='bring and brag, share and steal' style={{maxWidth: "65%", margin: "0 auto", display: "block" }}/>
          <div id="white-board">
            <div id="text">
              <span> {game_options.game_texts.game_title}</span>
            </div>
            <div className="whiteboard-base-text">
              <span dangerouslySetInnerHTML={{__html: game_options.game_texts.sub_title }}></span>
            </div>
            <div style={{ minWidth: '100%', minHeight: "50vh"}}>
              <div>
                <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                  <DropArea auth={this.props.auth} playerProfiles={session.playerProfiles} control={false} id="main" postits={results} direction="horizontal"  itemAction={this.itemAction} disabled={false}/>
                </DragDropContext>
              </div>
            </div>
            <div id="eraser"></div>
            <div id="red-pen"></div>
            <div id="blue-pen"></div>
          </div>
          </Grid> 
          <Grid item xs={12} md={6}>
            <div className={classes.spacingTop}  style={{fontWeight:"bold"}}>Instructions:</div>
            <div dangerouslySetInnerHTML={{ __html: game_options.game_texts.intro_text}}>
            </div>
          </Grid>
          <Grid item xs={12} md={6} align="right">
            <Button type='submit' color="primary" variant='contained' onClick={() => this.addToQ()} className={classes.spacingTop} startIcon={<AddIcon/>}>
              <span dangerouslySetInnerHTML={{__html: game_options.game_texts.add_button }}></span>
            </Button>
            {
              results !== {} ?
              <div className={classes.spacingTop} dangerouslySetInnerHTML={{__html: game_options.game_texts.help_one }}></div>
              : null 
            }
            { hasControl && session.active_game.results[group_id] !== undefined ?
              <div className={classes.spacingTop}>
               <span dangerouslySetInnerHTML={{__html: game_options.game_texts.help_finish }}></span>
                <Button type='submit' color="primary" variant='contained' onClick={() => this.finishActivity()} className={classes.spacingTop} startIcon={<DoneIcon/>}>
                  Finish Activity
                </Button>
              </div>
              :
              null
            }
          </Grid>   
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResultsPathObject: (path, value, hostID) => dispatch(updateResultsPathObject(path, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);
