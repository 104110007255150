
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GAME_STATE_RESULTS, HOST_STATE_MAIN, 
    HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS } from '../../../store/actions/gameActions';
import { getUserGroup } from './game_functions/functions';
import AwaitingGroup from '../../AwaitingGroup'
import { withStyles } from '@material-ui/core/styles';
import Instructions from './ondeck/phases/instructions';
import HostInstructions from './ondeck/specific_components/hostInstructions';
import GameResults from './ondeck/phases/gameResults';
import Activity from './ondeck/phases/activity';
import Results from './ondeck/phases/results';
import Grid from '@material-ui/core/Grid';
import GroupStatus from './game_components/groupStatus';
import LeaderControls from './game_components/leaderControls';

import styles from '../../Styles';



const INITIAL_STATE = {
    dialogueOpen: false
}


class OnDeck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    }

    render(){
        const { results, profiles, isPDFView } = this.props;

        return <GameResults 
            isPDFView={isPDFView}           
            results={results}
            profiles={profiles}
        />;
    }

}



const mapStateToProps = (state) => {
    return {};
};
  
const mapDispatchToProps = (dispatch) => {
    return {  };
};
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(OnDeck);
