import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Avatar from '@material-ui/core/Avatar';
import {
    hasGameControl,
    determineHost
  } from '../../game_functions/functions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { editResultsPath, updateGroupStateParamsVal } from './../../../../../store/actions/gameActions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PROFILE_EMPLOYEE, PROFILE_MANAGER } from '../profiles/profileList_temp';
import styles from '../../../../Styles';
import Speech from 'speak-tts';

/* updateGroupStateParams to toggle the profiles, etc */

const INITIAL_STATE = {
    open: false,
    speechBot: null,
    playing: false
}

function capitalizeFLetter(s) { 
    return s[0].toUpperCase() + s.slice(1); 
}


class WalkProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    }

    componentDidMount(){
        if ( this.props.session.active_game.groupStates[this.props.group_id].params.viewing === PROFILE_EMPLOYEE || this.props.session.active_game.groupStates[this.props.group_id].params.viewing === PROFILE_MANAGER ){
        }
        const speech = new Speech();
        if(speech.hasBrowserSupport()) { // returns a boolean
        }
        speech
        .init({
        volume: 0.5,
        lang: "en-GB",
        rate: 1,
        pitch: 1,
        })
        .then(data => {
            speech.setLanguage('en-GB');
            let isDefined = data.voices.find(obj => {
                return obj.name === this.props.walkProfile.profile_gender
            });
            if ( isDefined !== undefined ){
                speech.setVoice(this.props.walkProfile.profile_gender);
            } else {
                let isDefined2 = data.voices.find(obj => {
                    return obj.name === this.props.walkProfile.profile_gender2
                });
                if ( isDefined2 !== undefined ){
                    speech.setVoice(this.props.walkProfile.profile_gender2);
                }
            }
            this.setState({
                ...this.state,
                speechBot: speech
            })
        })
        .catch(e => {
            console.error("An error occured while initializing : ", e);
        });
    }

    componentWillUnmount(){
        this.state.speechBot.cancel();
    }

    componentDidUpdate(prevProps, prevState){
        if ( this.state.speechBot === null ){
            const speech = new Speech();
            if(speech.hasBrowserSupport()) { // returns a boolean
            }
            speech
            .init({
            volume: 0.5,
            lang: "en-GB",
            rate: 1,
            pitch: 1,            
            })
            .then(data => {
                speech.setLanguage('en-GB');
                let isDefined = data.voices.find(obj => {
                    return obj.name === this.props.walkProfile.profile_gender
                });
                if ( isDefined !== undefined ){
                    speech.setVoice(this.props.walkProfile.profile_gender);
                } else {
                    let isDefined2 = data.voices.find(obj => {
                        return obj.name === this.props.walkProfile.profile_gender2
                    });
                    if ( isDefined2 !== undefined ){
                        speech.setVoice(this.props.walkProfile.profile_gender2);
                    }
                }
                this.setState({
                    ...this.state,
                    speechBot: speech
                })
            })
            .catch(e => {
                console.error("An error occured while initializing : ", e);
            });
        }

        if ( !this.state.open && this.props.session.active_game.groupStates[this.props.group_id].params.viewing === this.props.type ){
            this.setState({
                ...this.state,
                open: true
            });
        }

        if ( this.state.open && this.props.session.active_game.groupStates[this.props.group_id].params.viewing === false ){
            this.state.speechBot.cancel();
            this.setState({
                ...this.state,
                open: false
            });
        }
    }

    handleClose = () => {
        const hasControl = hasGameControl(this.props.session.active_game.groups[this.props.group_id].leadPlayer, this.props.auth.uid, this.props.profile);
        if ( !hasControl ){
            return;
        }

        const path = `active_game.results.${this.props.group_id}.step_${this.props.step}.done`;
        const results = this.props.session.active_game.results;
        let group_result = results[this.props.group_id] ? results[this.props.group_id] : false;
        let value = [this.props.type];

        if ( group_result !== false ){       
            if ( group_result[`step_${this.props.step}`] ){ 
                if ( this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].done.includes(this.props.type) === false ){
                    value = [...this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].done, this.props.type];
                } else {
                    value = [...this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].done];
                }
            } 
        } 
        this.state.speechBot.cancel();
        this.props.editResultsPath(path, value, determineHost(this.props.profile, this.props.auth));
        this.props.updateGroupStateParamsVal(this.props.group_id, 'viewing', false, determineHost(this.props.profile, this.props.auth));
    }

  openProfile = () => {
    const hasControl = hasGameControl(this.props.session.active_game.groups[this.props.group_id].leadPlayer, this.props.auth.uid, this.props.profile);
    
    if ( hasControl ) {
        this.props.updateGroupStateParamsVal(this.props.group_id, 'viewing', this.props.type, determineHost(this.props.profile, this.props.auth));
    }
  }  

  playSpeech = () => {
    this.state.speechBot
    .speak({
      text: this.props.walkProfile.profile_text,
      queue: false,
      listeners: {
        onstart: () => {
          this.setState({
                ...this.state,
                playing: true
          })
        },
        onend: () => {
         if ( !this.state.speechBot.pending() ){
                this.setState({
                    ...this.state,
                    playing: false
                })
            }
        },
        onresume: () => {
            this.setState({
                ...this.state,
                playing: true
            })
        },
        onboundary: event => {
        }
      }
    })
    .then(data => {
    })
    .catch(e => {
    });
  }

  endSpeech = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.state.speechBot.cancel();      
  }

  render() {
      
    const { classes, walkProfile, session, profile, auth, type, stepTitle, group_id, d_result } = this.props;
    const { open } = this.state;
    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);

    return (
        <div>
            <Dialog aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose} >
                   <span className={classes.inlineProfileScenario}>{stepTitle}</span> - <span className={classes.inlineProfileDescriptor}>{capitalizeFLetter(type)} </span> 
                </DialogTitle>
                <DialogContent dividers className={classes.noPad}> 
                    <Grid container className={classes.alignVerticalCenter} style={{alignItems: 'stretch'}}>
                        <Grid item sm={4} xs={12} align="center" style={{backgroundImage: `url(${require(`./../images/p${walkProfile.id}.jpeg`)})`, backgroundSize: 'cover', minHeight: '200px', backgroundPosition: 'center'}}>
                        </Grid>
                        <Grid item sm={8} xs={12} className={classes.padMe}>
                            <Typography variant="body1">
                                {walkProfile.profile_text}
                            </Typography>
                        </Grid>                           
                    </Grid>
                </DialogContent>
                <DialogActions>
                    { hasControl === true ?
                            <Button size="small" color="primary" onClick={this.handleClose}>
                                Done
                            </Button>
                    : null }
                        { this.state.playing ? 
                            <Button size="small" variant="contained" color="primary" onClick={(e) => this.endSpeech(e)}>
                                Stop Audio
                            </Button>
                        : 
                        <Button size="small" variant="contained" color="primary" onClick={(e) => this.playSpeech(e)}>
                            Play Audio
                        </Button>
                        }
                </DialogActions>
            </Dialog>                 
            <Grid container className={classes.alignVerticalCenter}>
                <Grid item xs={12} className={classes.cardPadding} align="center">
                    <div className={classes.relativeIconParent}>
                        { d_result === true ? <CheckCircleIcon fontSize="large" className={classes.absoluteIcon} /> : null } 
                        <Avatar alt="Remy Sharp" src={require(`./../images/avatar/p${walkProfile.id}.jpeg`)}  onClick={() => this.openProfile()} className={classes.profileAvatar} style={{cursor: 'pointer'}}/>
                    </div>
                    <Typography variant="body1" className={`${classes.profileTypeDescriptor} ${classes.minorMargin}`}>
                        {capitalizeFLetter(type)}
                    </Typography>                                
                </Grid>
            </Grid>     
        </div>
    );
  }
}

const mapStateToProps = (state) => {
        return {}
    };
    
    const mapDispatchToProps = (dispatch) => {
        return {        
            editResultsPath: (path, value, host) => dispatch(editResultsPath(path, value, host)),
            updateGroupStateParamsVal: ( group, value, groupStateParams, hostID ) => dispatch(updateGroupStateParamsVal(group, value, groupStateParams, hostID) )
        };
    };

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(WalkProfile);



