import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
//import { GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
//    HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS } from '../../../../store/actions/gameActions';
import {
    hasGameControl,
    getUserGroup_hc,
    determineHost
  } from '../../game_functions/functions';
  import Avatar from '@material-ui/core/Avatar';
//import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams, moveBackToInitPhaseWithOption  } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import Box from '@material-ui/core/Box';

import bbss_logo_line from './../images/bbss_logo_line.png';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import { getPuzzle, defaultDifficulty, defaultId } from './../specific_components/wordActionCenter.js';
import { wordGroups } from './../data/data';
import WordPlayground from './../specific_components/wordPlayground.js';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import Paper from '@material-ui/core/Paper';
import ForwardIcon from '@material-ui/icons/Forward';
import { gameHasOptions, gameOptions } from './../../../gameServer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import circleWordsLogo from './../images/Circle-Words-Wordmark.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
import { PDFHeader } from './../../../../PastData/PDFHeader';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  
  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

const INITIAL_STATE = {
    open: false,
    dialogOpen: false,
    tab: 0,
    containerWidth: null,
    gridLeftWidth: null,
    isInterfaceLoaded: false,
    newPuzzleId: null,
    newPuzzleTitle: null,
    newPuzzleDifficulty : null
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        
        this.gridLeft = React.createRef();
        this.mainDiv = React.createRef();
    
        
        this.state = {
          ...INITIAL_STATE,
        };
    }

  
    componentDidMount() {
        window.scrollTo(0, 0);
        //console.log("Do WE HAVE STUFF?", this.props.results.results, this.props.results.resultSnapshot);
        //console.log('THE DIFF:', deepDiffMapper.map({}, this.props.results.results));
        //console.log('THE DIFF:', deepDiffMapper.map(this.props.results.resultSnapshot === undefined ? {} : this.props.results.resultSnapshot, this.props.results.results));
        if ( !this.state.isInterfaceLoaded ){
            if ( this.mainDiv !== undefined ){
              console.log('hERE!', this.mainDiv);
              this.setState({
                ...this.state,
                isInterfaceLoaded: true,
                containerWidth: this.mainDiv.current.offsetWidth,
                gridLeftWidth: this.gridLeft.current.offsetWidth,
              });
            }
        
        }
    }


    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    handleTabChange = (event, newValue) => {
        console.log('????', event, newValue);
        this.setState({
          ...this.state,
          tab: newValue
        })
      };

    setOption = (id, title, diff) => {
        console.log('id', id);
        this.setState({
            ...this.state,
            dialogOpen: true,
            newPuzzleId: id,
            newPuzzleTitle: title,
            newPuzzleDifficulty : diff
        })
    }

    handleDialogClose = () => {
        this.setState({
            ...this.state,
            dialogOpen: false
        })
    }

    /*endGame = () => {
        this.props.moveBackToInitPhaseWithOption(this.props.session, this.props.auth.uid, 'Puzzle', {
            puzzleIndex: this.state.newPuzzleId,
            puzzleDifficulty: this.state.newPuzzleDifficulty
        });
    }*/

    render() {
    const {
      results,
      classes,      
      profiles,
      isPDFView      
      //game_id, game_title,  profile, auth, group_state, game_state
    } = this.props;

         console.log("cwords Props->", this.props);


        if(this.props.isPDFView === true){
            return (
                <Document>
                     <Page wrap style={{padding: "30 0"}}>
                        <PDFHeader/>
                        <View>
                            <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={circleWordsLogo}/>
                            {Object.keys(results.results)
                            .sort()
                            .map((groupKey, ind) => {
                            return (
                            <React.Fragment key={ind}>
                                <View style={{marginHorizontal: "50", marginBottom: "50"}}>
                                    <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>                                    
                                    
                                </View>
                            </React.Fragment>       
                            );
                        })}
                        </View>
                    </Page>
                </Document> 
            ) 
        }


        if ( results === undefined ) {
            return null;
        }
       
        return (
        <div ref={this.mainDiv}>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>


            <Dialog
                    open={this.state.dialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to restart the activity and use the <span style={{fontWeight: "bold"}}>{this.state.newPuzzleTitle}</span> puzzle?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleDialogClose} variant="contained" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.endGame} variant="contained" color="primary">
                        Confirm
                    </Button>
                    </DialogActions>
                </Dialog>



                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>

          
                { /*: null */ }
            </Grid>
            <Grid item xs={12} sm={12} ref={this.gridLeft}>
                  
                        { Object.keys(results.groups).slice().sort().map((groupKey, ind) => {
                                /*results.results[groupKey] === undefined ?
                                {} :
                                results.results[groupKey].choices === undefined ?
                                {} :
                                results.results[groupKey].choices;*/
                            if ( groupKey === 'group-0' ){
                                return null;
                            }

                            //console.log("RESULTS", results);
                            //const host = determineHost(profile, auth);
                            let data = getPuzzle(results, wordGroups, groupKey);
                            let curPuzzleDifficulty = defaultDifficulty;
                            let curPuzzleId = defaultId;

                            if ( results.groupStates[groupKey].params.puzzle !== undefined ){
                                curPuzzleDifficulty = results.groupStates[groupKey].params.puzzle.puzzleDifficulty;
                                curPuzzleId = results.groupStates[groupKey].params.puzzle.puzzleId;
                            }

                            if ( groupKey !== 'group-0' ){
                                let minutes = 0;
                                let secondsElapsed = 0;
                                let remainder = 0;
                                let timeelapsed = 0;
                                let finishedLast = false;
                                if ( results.groupStates[groupKey].params.finishedOn !== undefined ){
                                    finishedLast = true;
                                    timeelapsed = results.groupStates[groupKey].params.finishedOn - results.groupStates[groupKey].params.startedOn;
                                    secondsElapsed = Math.floor(timeelapsed / 1000);
                                
                                    if ( isNaN(secondsElapsed) ){
                                        secondsElapsed = 0;
                                    }

                                    minutes = Math.floor(secondsElapsed / 60);
                                    remainder = secondsElapsed % 60;
                                }
                                return (
                                    <Grid container key={ind}>
                                    <Grid item xs={12} sm={12} className={classes.spacingTop}>
                                    <Typography variant="h3" className={classes.spacingTop}>{results.groups[groupKey].title}</Typography>
                           
                                        <Typography variant="h6" className={classes.spacingBottom}>Summary of Activities:</Typography>
                                        { results.groupStates[groupKey].params.completions !== undefined ?
                                            results.groupStates[groupKey].params.completions.map((completion, index) => {
                                                return <div key={index} className={classes.spacingBottom}>
                                                    <Typography variant="h5">Puzzle: <span style={{fontWeight: "bold"}}>{completion.puzzleDifficulty} #{parseInt(completion.puzzleId)+1}</span></Typography>
                                                    <Typography variant="body2">Words: {wordGroups[completion.puzzleDifficulty][completion.puzzleId].correct_order.join(', ')}</Typography>
                                                    <Typography variant="body1">Completed in: <span style={{fontWeight: "bold"}}>{ completion.finishedIn }</span>.</Typography>
                                                </div>;
                                            }) 
                                            : null
                                        }

                                            <div>
                                                <Typography variant="h5">Puzzle: <span style={{fontWeight: "bold"}}>{curPuzzleDifficulty} #{parseInt(curPuzzleId)+1}</span></Typography>
                                                <Typography variant="body2">Words: {wordGroups[curPuzzleDifficulty][curPuzzleId].correct_order.join(', ')}</Typography>
                                                { finishedLast === true ?
                                                    <Typography variant="body1">Completed in: <span style={{fontWeight: "bold"}}>{ minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds` }</span>.</Typography>
                                                :
                                                <Typography variant="body1">Completed in: <span style={{fontWeight: "bold"}}>Not Completed.</span></Typography>
                                                }
                                            </div>
                                    </Grid></Grid>
                                )
                            } else {
                                return null;
                            }
                            
                        })}                    
                
                    { /*profile.role === 'host' ?
                    <div style={{marginTop: "60px"}}>
                        <Typography variant="h4">Reset Activity with New Puzzle</Typography>
                        <Typography variant="body1" >Clicking a puzzle image will re-start activity using that puzzle.</Typography>
                        <Typography variant="body2" className={classes.spacingBottom}>Current puzzle is highlighted in green.</Typography>
                        <Grid container>            
                            <Grid container>
                                {
                                    Object.keys(options).map((oKey, ind) => (
                                        
                                        options[oKey].map((oItem, index) => {
                                            let curPuzzleDifficulty = 'Easy';
                                            let curPuzzleId = 0;
                                            if ( this.props.results.Puzzle !== undefined ){
                                                curPuzzleDifficulty = this.props.results.Puzzle.puzzleDifficulty;
                                                curPuzzleId = this.props.results.Puzzle.puzzleIndex;
                                            }
                                            let border = "1px solid #000";
                                            if ( oKey === curPuzzleDifficulty && index === curPuzzleId){
                                                border = "3px solid green";
                                            }
                                            return (
                                                <Grid item xs={3} id={oKey} align="center" onClick={() => this.setOption(index, `${oKey} - ${index+1}`, oKey)} key={index}>
                                                    <div style={{border: border, borderRadius: "5px", padding: "15px", cursor: "pointer", }} >
                                                        <Typography variant="body1" style={{fontWeight: 'bold'}}>{oKey} - {index+1}</Typography>
                                                        <Typography variant="body2">{oItem.words.join(', ')}</Typography>                     
                                                    </div>
                                                </Grid>
                                            )
                                        })
                                                
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </div>
                            : null */ }
            </Grid>
            
            </Grid>
        </div>
        );
    
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
