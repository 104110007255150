
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Logo from '../images/Logo.png'
import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions'
import { updateGroupState, editResultsPath } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const INITIAL_STATE = {
    engOpen: false,
    eng: '',
    conOpen: false,
    con: ''
}

class Instructions extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };  
      }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            con: this.props.session.active_game.groupStates[this.props.group_id].contractor === undefined ? '' : this.props.session.active_game.groupStates[this.props.group_id].contractor,
            eng: this.props.session.active_game.groupStates[this.props.group_id].engineer === undefined ? '' : this.props.session.active_game.groupStates[this.props.group_id].engineer
        })
    }

    sendToNextStep = (event) => {
    let groupID =  getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);  
    
    if ( this.props.session.active_game.results[groupID] === undefined ){     
            let d = {
                'lt1' : {
                    'originalRotation' : 270,
                    'rotation' : 360,
                    'x' : 696,
                    'y' : 150
                },
                'lt2' : {
                    'originalRotation' : 0,
                    'rotation' : 0,
                    'x' : 217,
                    'y' : 167
                },
                'mt1' : {
                    'originalRotation' : 180,
                    'rotation' : 180,
                    'x' : 284,
                    'y' : 339
                },
                'rh' : {
                    'originalRotation' : 135,
                    'rotation' : 135,
                    'x' : 709,
                    'y' : 332
                },
                'sq' : {
                    'originalRotation' : 270,
                    'rotation' : 270,
                    'x' : 494,
                    'y' : 456
                },
                'st1' : {
                    'originalRotation' : 270,
                    'rotation' : 270,
                    'x' : 582,
                    'y' : 259
                },
                'st2' : {
                    'originalRotation' : 270,
                    'rotation' : 360,
                    'x' : 435,
                    'y' : 182
                }
            };            
            let path = `active_game.results.${this.props.group_id}.tangramCoords`;
            
            this.props.editResultsPath(path, d, determineHost(this.props.profile, this.props.auth));   
    }

    let s = { 
            'state' : GROUP_STATE_ACTIVE,
            'params' : {
                'contractor' : this.state.con,
                'engineer' : this.state.eng,
                'startedOn' : (this.props.session.active_game.groupStates[groupID].params.startedOn === undefined || this.props.session.active_game.groupStates[groupID].params.startedOn === null) ? Date.now() : this.props.session.active_game.groupStates[groupID].params.startedOn
            }
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render(){
        const { eng, con } = this.state;
        const { session, profile, auth, classes, leader } = this.props;

        return (
            <div>
               <Typography variant='h3' className={classes.spaceBottom}>
                    {session.active_game.name}
                </Typography>
               <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img src={Logo} className={classes.imageStyle} alt="Game"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1" className={classes.spacingBottom}>Complete a puzzle in teams with a twist!</Typography>
                        <Typography variant="body1" className={classes.spacingBottom}>This activity features a CONTRACTOR role, and ENGINEER roles. The contractor must complete the puzzle while the engineers describe how based on a set of plans that they have access to. Only one engineer can view the plans at a time.</Typography>
                        <Typography variant="body1" className={classes.spacingBottom}>Assign your roles here:</Typography>
                        <FormControl className={classes.formControl} style={{width: '100%'}}>
                            <InputLabel id="demo-controlled-open-select-label">Contractor</InputLabel>
                            <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            name="con"
                            value={con}
                            onChange={this.handleChange}
                            disabled={hasGameControl(leader, auth.uid, profile) === false}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            { this.props.session.active_game.groups[this.props.group_id].playerIds.map((playerid, ind) => {
                                if ( eng === playerid ){
                                    return null;
                                }
                                return (
                                    <MenuItem key={ind} value={playerid}>{this.props.session.playerProfiles[playerid].displayName}</MenuItem>
                                );
                            }) }
                            </Select> 
                        </FormControl>
                        <br/><br/>
                        <FormControl className={classes.formControl} style={{width: '100%'}}>
                        <InputLabel id="demo-controlled-open-select-label">Lead Engineer</InputLabel>
                            <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            name="eng"
                            value={eng}
                            onChange={this.handleChange}
                            disabled={hasGameControl(leader, auth.uid, profile) === false}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            { this.props.session.active_game.groups[this.props.group_id].playerIds.map((playerid, ind) => {
                                if ( con === playerid ){
                                    return null;
                                }
                                return (
                                    <MenuItem key={ind} value={playerid}>{this.props.session.playerProfiles[playerid].displayName}</MenuItem>
                                );
                            }) }
                            </Select>
                        </FormControl>
                        <br/><br/><br/>
                        { hasGameControl(leader, auth.uid, profile)
                            ?
                            (con !== '' && eng !== '') ?
                                <Button type='submit' color="primary" variant='contained' onClick={this.sendToNextStep}>
                                    Continue
                                </Button>
                            :
                                <Button type='submit' disabled={true} variant='contained'>
                                    Select a Contractor & Engineer to Continue
                                </Button>
                            :
                            <Button type='submit' disabled={true} variant='contained'>
                                Leader can hit Continue
                            </Button>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState : (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID)),
    };
  };
  
  export default compose( connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Instructions);