import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'
import parse from 'html-react-parser';
import { CaseStudy } from './../data/data';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: "1rem",
        display: "inline-block"
    },
    helpText: {
        fontWeight: "bold",
        paddingTop: "30px",
        fontSize: "1.2rem"
    },
    linkout: {
        fontWeight: "bold",
        textDecoration: "underline",
        cursor: "pointer"
    },  
    boldedText: { fontWeight: "bold"},
    smallText: { fontStyle: "italic" },
    spacingTop: { paddingTop: "30px" },
    spacingBottom: { paddingBottom: "30px" },
    lister: { listStyleType: "disc" },
    list: { listStyleType: "disc", paddingLeft: "1rem" }
}));

export default function CaseStudyPopup() {
    const classes = useStyles();  
    const [open, setOpen] = useState(false);
 
    return (
        <span className={classes.root}>
            <span onClick={() => setOpen(true)} className={classes.linkout}>Case Study</span>
            <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Transition} maxWidth="sm" fullWidth>
                <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.boldedText}>
                    Case Study
                    </Typography>                    
                </Toolbar>
                </AppBar>
                <DialogContent>
                <Grid container spacing={2} className={`${classes.spacingBottom} ${classes.spacingTop}`}>
                    <Grid item xs={12} >
                        {parse(CaseStudy.html)}
                    </Grid>
                </Grid>
                </DialogContent>              
            </Dialog>
        </span>
    );
}