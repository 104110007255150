import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { updateGroupStateParamsVal } from './../../../../../store/actions/gameActions';
import styles from '../../../../Styles';

class TextLogger extends Component {

    componentDidMount(){
        console.log("LOGGER MOUNTED", this.props.ttd, this.props.val);
        let intervalId = setInterval(() => {
            if ( this.props.ttd !== this.props.val ){
                this.props.updateGroupStateParamsVal(this.props.group_id, 'ttd', this.props.val, this.props.host);
            }
        }, 1000);
        this.setState({intervalId: intervalId});
    }

      
    componentWillUnmount(){
        clearInterval(this.state.intervalId);
        if ( this.props.ttd !== this.props.val ){
            this.props.updateGroupStateParamsVal(this.props.group_id, 'ttd', this.props.val, this.props.host);
        }
    }
       
    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
        updateGroupStateParamsVal : (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID))
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TextLogger);


