import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { EXPECTATIONS, ACCOUNTABILITIES } from './activity';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import set_expectations from './images/set_expectations.png'
import { getUserGroup_hc} from '../game_functions/functions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../Styles';
import LiquidLoadingBlock from '../../../Loading/loadingCup';
import Accountability_Sign from './images/Accountability_Sign.png';



class Results extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

  render() {
    const { session,classes } = this.props;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    if ( session.active_game.groupStates[group_id].params.phase === ACCOUNTABILITIES ){
      return (
        <div>
          <Grid container spacing={3}>
              <Grid item xs={12}>
              <Typography variant="h5">Wrapping Up!</Typography>
              <div>
                  <Typography variant="body1">Waiting for other groups to finish their activity.</Typography>
                <br />
                <br />
                <div className={classes.cupContainerLeft}>
                  <LiquidLoadingBlock/>
                </div>
              </div>
              </Grid>
            <Grid item xs={12} sm={6}>            
            <img src={Accountability_Sign} alt='instructions' className={classes.heightLimitedImage} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Your Accountabilities:</Typography>
                  {/* Results Here */}
                  <List className={classes.listClass}>
                    {session.active_game.results.hasOwnProperty(group_id)
                      ? session.active_game.results[group_id].hasOwnProperty(ACCOUNTABILITIES)
                        ? session.active_game.results[group_id][ACCOUNTABILITIES].map((item, index) => {
                            return (
                              <ListItem key={index}>
                                <ListItemText primary={item} />
                              </ListItem>
                            );
                          })
                        : null
                      : null}
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }

    return (
      <div>
        <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant="h5">Wrapping Up!</Typography>
            <div>
                <Typography variant="body1">Waiting for other groups to finish their activity.</Typography>
              <br />
              <br />
              <div className={classes.cupContainerLeft}>
                <LiquidLoadingBlock/>
              </div>
            </div>
            </Grid>
          <Grid item xs={12} sm={6}>            
            <img src={set_expectations} className={classes.imageSize} alt="Circle of Influence"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Your Expectations:</Typography>
                {/* Results Here */}
                <List className={classes.listClass}>
                  {session.active_game.results.hasOwnProperty(group_id)
                    ? session.active_game.results[group_id].hasOwnProperty(EXPECTATIONS)
                      ? session.active_game.results[group_id][EXPECTATIONS].map((item, index) => {
                          return (
                            <ListItem key={index}>
                              <ListItemText primary={item} />
                            </ListItem>
                          );
                        })
                      : null
                    : null}
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>  
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Results);
