import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
//import { GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
//    HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS } from '../../../../store/actions/gameActions';
import {
    hasGameControl,
    getUserGroup_hc,
    determineHost
  } from '../../game_functions/functions';
  import Avatar from '@material-ui/core/Avatar';
//import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams, moveBackToInitPhaseWithOption, updateResultsPath, updateGameStateParams  } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import bbss_logo_line from './../images/bbss_logo_line.png';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';

//import { wordGroups } from './../data/data';
import WordPlayground from './../specific_components/wordPlayground.js';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import Paper from '@material-ui/core/Paper';
import ForwardIcon from '@material-ui/icons/Forward';
import { gameHasOptions, gameOptions } from './../../../gameServer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  
  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

const INITIAL_STATE = {
    open: false,
    dialogOpen: false,
    tab: 0,
    containerWidth: null,
    gridLeftWidth: null,
    isInterfaceLoaded: false,
    newPuzzleId: null,
    newPuzzleTitle: null,
    newPuzzleDifficulty : null,
    curGroup: null,
    dialogOpen: false,
    questionText: ''
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        
        this.gridLeft = React.createRef();
        this.mainDiv = React.createRef();
    
        
        this.state = {
          ...INITIAL_STATE,
        };
    }

    

    componentDidMount() {
        window.scrollTo(0, 0);
        
        if ( !this.state.isInterfaceLoaded ){
            if ( this.mainDiv !== undefined ){
              
              this.setState({
                ...this.state,
                isInterfaceLoaded: true,
                containerWidth: this.mainDiv.current.offsetWidth,
                gridLeftWidth: this.gridLeft.current.offsetWidth,
              });
            }
        
        }
    }

    

    onChange = (event) => {
      this.setState({ ...this.state,
          [event.target.name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            dialogOpen: false,
        });
    };

    handleTabChange = (event, newValue) => {
        console.log('????', event, newValue);
        this.setState({
          ...this.state,
          tab: newValue
        })
      };

    setOption = (id, title, diff) => {
        console.log('id', id);
        this.setState({
            ...this.state,
            dialogOpen: true,
            newPuzzleId: id,
            newPuzzleTitle: title,
            newPuzzleDifficulty : diff
        })
    }

    handleDialogClose = () => {
        this.setState({
            ...this.state,
            dialogOpen: false
        })
    }

    /*endGame = () => {
        this.props.moveBackToInitPhaseWithOption(this.props.session, this.props.auth.uid, 'Puzzle', {
            puzzleIndex: this.state.newPuzzleId,
            puzzleDifficulty: this.state.newPuzzleDifficulty
        });
    }*/

    render() {
    const {
      results,
      classes
    } = this.props;

       
        return (
        <div ref={this.mainDiv}>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>

               


                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>

          
                { /*: null */ }
            </Grid>
            <Grid item xs={12} sm={12} ref={this.gridLeft}>
                   
                        { Object.keys(results.groups).sort().map((groupKey, ind) => {
                          if ( groupKey === 'group-0' ){
                            return null
                          }
                         return (
                          <Grid container>
                          <Grid item xs={12} sm={12} className={classes.spacingTop}>
                          <Typography variant="h5">{results.groups[groupKey].title}</Typography>
                            <div className={classes.spacingTop} align="center">
                                <WordPlayground title={results.groups[groupKey].title} results={results.results[groupKey] === undefined ? {} : results.results[groupKey]} draggedEl={null} clearDraggedEl={null} updateLines={null} updateImages={null} control={false} host={null} group_id={groupKey}/>
                                { this.props.results.gameState.params.questions !== undefined ?
                                <div style={{margin: "30px 0"}}>
                                    <Typography variant="h4">How does it feel to work for this Leader?</Typography>

                                    <List className={classes.listClass} style={{margin: "15px 0"}}>
                                      {
                                      results.results.hasOwnProperty(groupKey)
                                          ? results.results[groupKey].hasOwnProperty('questionDebrief')
                                          ? results.results[groupKey]['questionDebrief'].map((item, index) => {
                                              return (                                                  
                                                  <ListItem key={index} align="center">                 
                                                      <ListItemText
                                                      align="center"
                                                          className={classes.bodyText} 
                                                          primary={item}                                
                                                      />
                                                  </ListItem>                                        
                                              );
                                            })
                                          : null
                                      : null }
                                     
                                  </List>      


                                    
                                </div>
                                : null }
                            </div>
                            </Grid></Grid>
         
                         )                           
                        })}                    
                    


                    
                    
            </Grid>
            
            </Grid>
        </div>
        );
    
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params)),
    moveBackToInitPhaseWithOption : (session,host,optionName,optionVal) => dispatch(moveBackToInitPhaseWithOption(session,host,optionName,optionVal)),
    updateGameStateParams: (params, hostID) => dispatch(updateGameStateParams(params, hostID)),
    updateResultsPath: (path, value, hostID) => dispatch(updateResultsPath(path, value, hostID))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
