import React from 'react'
import { ChromePicker } from 'react-color'
import Button from '@material-ui/core/Button';

function ColorSelection(props) {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [background, setBackground] = React.useState(props.color)


  const handleClick = () => {
    console.log('what');
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  const handleChangeComplete = (color) => {
    setBackground(color.hex);
    props.updateColor(color.hex);
  };

  
    const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    return (
      <div>
        <Button variant='contained' color='secondary' onClick={ handleClick }>
            Open Color Picker
        </Button>
       
       
        { displayColorPicker ? <div style={ popover }>
          <div style={ cover } onClick={ handleClose }/>
          <ChromePicker color={ background } onChangeComplete={ handleChangeComplete } disableAlpha={true} />
        </div> : null }
        <div style={{ margin: '10px 0', width: '150px', height: '150px', backgroundColor: `${background}`}}></div>
      </div>
    )
  
}

export default ColorSelection