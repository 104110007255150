import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomePage from '../Home';
import LoginPage from '../SignIn';
import Default from '../Default';
import SignUp from '../SignUp';
import AnonSignIn from '../SignIn/anonSignIn';
import ChangeName from '../SignIn/changeName';
import ForgotPasswordForm from '../SignIn/forgotPassword';
import AdminPage from '../AdminPage';
import PastData from '../PastData';
import {connect} from 'react-redux';
import Dashboard from '../Dashboard';
import Contact from '../Contact';
import ThemeManagement from '../Theme';
import PlanningPage from '../PlanningPage';
import * as ROUTES from '../../constants/routes';

const App = (props) => (
  <Router>
    <div>
      <Route exact path={ROUTES.LANDING} component={HomePage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUp} />
      <Route exact path={ROUTES.SIGN_UP_REDIRECT} component={SignUp} />
      <Route exact path={ROUTES.LOGIN} component={LoginPage} />
      <Route exact path={ROUTES.FORGOTTEN_PASSWORD} component={ForgotPasswordForm} />
      <Route exact path={ROUTES.ANON_SIGN_IN} component={AnonSignIn} />
      <Route exact path={ROUTES.ANON_SIGN_IN_NID} component={AnonSignIn} />
      <Route exact path={ROUTES.CHANGE_NAME} component={ChangeName} />
      <Route exact path={ROUTES.DEFAULT} component={Default} />
      <Route exact path={ROUTES.ADMIN} component={AdminPage} />
      <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
      <Route exact path={ROUTES.PASTDATA} component={PastData} />
      <Route exact path={ROUTES.CONTACT} component={Contact} />
      <Route exact path={ROUTES.THEME} component={ThemeManagement}/>
      <Route exact path={ROUTES.PLANNING} component={PlanningPage}/>
    </div>
  </Router>
);

const MapStateToProps = (state) => {
    return { }
};

const MapDispatchToProps = (dispatch) => {
    return { }
};

export default connect(MapStateToProps, MapDispatchToProps)(App);