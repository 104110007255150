

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Button from '@material-ui/core/Button';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import { Q1_FS, Q2_FS, Q1, Q2 } from './activityTwo';
import ForwardIcon from '@material-ui/icons/Forward';
import Card from '@material-ui/core/Card';
import commStylesIcon from '../images/CommunicationsStyles.png';
import { sortGroupStrings } from './../../game_functions/functions';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
import { PDFHeader } from './../../../../PastData/PDFHeader';


const INITIAL_STATE = {
    open: false
};

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
  });

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    returnToResults = () => {
        //this.props.moveBackToResultsPhase(this.props.session, this.props.auth.uid);
    }

    moveToFloorExercise = () => {
        //this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { 'phase' : 'floor' });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
       
    }


    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
        const {
            results,classes,isPDFView, groups 
          } = this.props;
      
          if(this.props.isPDFView === true){
              return (
                  <Document>
                       <Page wrap style={{padding: "30 0"}}>
                          <PDFHeader/>
                          <View>
                              
                              {Object.keys(results.results)
                              .sort(sortGroupStrings)
                              .map((groupKey, ind) => {
                              return (
                              <React.Fragment key={ind}>
                                  <View style={{marginHorizontal: "50"}}  wrap={false}>
                                      <Text style={{marginBottom: "10", fontSize: "20pt"}}>{groups[groupKey].title}</Text>                                    
                                      <View style={{marginVertical: "5", flexDirection: "row", borderRadius: "15", backgroundColor: "rgb(209, 196, 233)"}}>
      
                                          <View style={{ width: "100%", paddingBottom: 20, }}>
                                              <Text style={{ fontSize: "10pt", marginBottom: 5, marginLeft: "15", marginTop: 10}}>Before Training</Text>
                                              <View style={{display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap"}}>
                                              { results.results[groupKey].before_choices.map((card, index) => { 
                                                  let t = card.text.split("<br/>").join('\n');
                                                  return( 
                                                      <View key={index} style={{backgroundColor: '#FFF', marginVertical: "4", marginHorizontal:"10", borderRadius: "2", maxWidth: 140, minWidth: 140 }}>
                                                          
                                                              
                                                              <Text style={{ fontSize: "10pt", marginVertical: "15", padding: 5}}>{t}</Text>
                                                          {/* <Text style={{ fontSize: "10pt", marginVertical: "15", marginLeft: "5", marginRight: "15"}}>{card.card_subwords}</Text> */}
                                                          
                                                      </View>
                                                  )                         
                                              })} 
                                              </View>
                                          </View> 

                                          <View style={{ width: "100%", paddingBottom: 20, }} >
                                              <Text style={{ fontSize: "10pt", marginBottom: 5, marginLeft: "15", marginTop: 10}}>During Training</Text>
                                              <View style={{display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap"}}>
                                              { results.results[groupKey].during_choices.map((card, index) => { 
                                                  let t = card.text.split("<br/>").join('\n');
                                                  return( 
                                                      <View key={index} style={{backgroundColor: '#FFF', marginVertical: "4", marginHorizontal:"10", borderRadius: "2", maxWidth: 140, minWidth: 140 }}>
                                                          
                                                              
                                                              <Text style={{ fontSize: "10pt", marginVertical: "15", padding: 5}}>{t}</Text>
                                                          {/* <Text style={{ fontSize: "10pt", marginVertical: "15", marginLeft: "5", marginRight: "15"}}>{card.card_subwords}</Text> */}
                                                          
                                                      </View>
                                                  )                         
                                              })} 
                                              </View>
                                          </View> 

                                          <View style={{ width: "100%", paddingBottom: 20, }} >
                                              <Text style={{ fontSize: "10pt", marginBottom: 5, marginLeft: "15", marginTop: 10}}>After Training</Text>
                                              <View style={{display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap"}}>
                                              { results.results[groupKey].after_choices.map((card, index) => { 
                                                  let t = card.text.split("<br/>").join('\n');
                                                  return( 
                                                      <View key={index} style={{backgroundColor: '#FFF', marginVertical: "4", marginHorizontal:"10", borderRadius: "2", maxWidth: 140, minWidth: 140 }}>
                                                          
                                                              
                                                              <Text style={{ fontSize: "10pt", marginVertical: "15", padding: 5}}>{t}</Text>
                                                          {/* <Text style={{ fontSize: "10pt", marginVertical: "15", marginLeft: "5", marginRight: "15"}}>{card.card_subwords}</Text> */}
                                                          
                                                      </View>
                                                  )                         
                                              })} 
                                              </View>
                                          </View> 
                                      </View>
                                  </View> 
                              </React.Fragment>       
                              );
                          })}
                          </View>
                      </Page>
                  </Document> 
              ) 
          }

   



    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">{results.name}</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                {Object.keys(results.results)
                .sort(sortGroupStrings)
                .map((group_id, ind) => {
                    if ( group_id === 'group-0' ){
                        return null;
                    }
                        const before_choices = results.results[group_id] === undefined ? [] : results.results[group_id].before_choices === undefined ? [] : results.results[group_id].before_choices;
                        const during_choices = results.results[group_id] === undefined ? [] : results.results[group_id].during_choices === undefined ? [] : results.results[group_id].during_choices;
                        const after_choices = results.results[group_id] === undefined ? [] : results.results[group_id].after_choices === undefined ? [] : results.results[group_id].after_choices;

                        return (
                        <div key={ind}>
                            <Typography variant="h4">{groups[group_id].title}</Typography>
                            <Grid container className={classes.paperExtra}>
                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>
                                        <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>Before Training</Typography> 
                                        
                                                <List className={`${classes.listClass} ${classes.relativeList}`}>
                                                
                                                { before_choices.map((card, index) => (                                        
                                                    <ListItem key={index} className={classes.doNotPad} style={{width: '30%', alignSelf: 'stretch', maxWidth: '100%', minWidth: 300}}> 
                                                    <Card className={`${classes.discCardDraggable}`} variant="outlined">
                                                        <div className={`${classes.alignVerticalCenter} ${classes.cardActionPadding}`} style={{padding: '20px'}}>
                                                            <Grid container className={classes.alignVerticalCenter}>
                                                                
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body1" dangerouslySetInnerHTML={{__html: card.text}}>
                                                                    
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Card>
                                                </ListItem>                         
                                                ))
                                                }
                                                </List>
                                            
                                        </Grid>
                                        <Grid item sm={4}>
                                        <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>During Training</Typography> 
                                       
                                            <List className={`${classes.listClass} ${classes.relativeList}`}>
                                                
                                                { during_choices.map((card, index) => (                                        
                                                   <ListItem key={index} className={classes.doNotPad} style={{width: '30%', alignSelf: 'stretch', maxWidth: '100%', minWidth: 300}}> 
                                                   <Card className={`${classes.discCardDraggable}`} variant="outlined">
                                                       <div className={`${classes.alignVerticalCenter} ${classes.cardActionPadding}`} style={{padding: '20px'}}>
                                                           <Grid container className={classes.alignVerticalCenter}>
                                                               
                                                               <Grid item xs={12}>
                                                                   <Typography variant="body1" dangerouslySetInnerHTML={{__html: card.text}}>
                                                                   
                                                                   </Typography>
                                                               </Grid>
                                                           </Grid>
                                                       </div>
                                                   </Card>
                                               </ListItem>             
                                                ))
                                                }
                                            </List>
                                        </Grid>
                                        <Grid item sm={4}>
                                        <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>After Training</Typography> 
                                      
                                            <List className={`${classes.listClass} ${classes.relativeList}`}>
                                                
                                                { after_choices.map((card, index) => (                                        
                                                    <ListItem key={index} className={classes.doNotPad} style={{width: '30%', alignSelf: 'stretch', maxWidth: '100%', minWidth: 300}}> 
                                                    <Card className={`${classes.discCardDraggable}`} variant="outlined">
                                                        <div className={`${classes.alignVerticalCenter} ${classes.cardActionPadding}`} style={{padding: '20px'}}>
                                                            <Grid container className={classes.alignVerticalCenter}>
                                                                
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body1" dangerouslySetInnerHTML={{__html: card.text}}>
                                                                    
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Card>
                                                </ListItem>           
                                                ))
                                                }
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        );
                })}
                </Grid>
                
            </Grid>
        </div>
        );


        /*return (
        <div>
             <DragDropContext>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">{session.active_game.name}</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {Object.keys(session.active_game.results)
                    .sort()
                    .map((group_id, ind) => {
                        if ( group_id === 'floor' ){
                            return null;
                        }
                        const before_choices = session.active_game.results[group_id] === undefined ? [] : session.active_game.results[group_id].before_choices === undefined ? [] : session.active_game.results[group_id].before_choices;
                        const during_choices = session.active_game.results[group_id] === undefined ? [] : session.active_game.results[group_id].during_choices === undefined ? [] : session.active_game.results[group_id].during_choices;
                        const after_choices = session.active_game.results[group_id] === undefined ? [] : session.active_game.results[group_id].after_choices === undefined ? [] : session.active_game.results[group_id].after_choices;

                        return (
                        <div key={ind}>
                            <Typography variant="h4">{session.groups[group_id].title}</Typography>
                            <Grid container className={classes.paperExtra}>
                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>
                                        <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>Before Training</Typography> 
                                        <Droppable droppableId={`before_choices`} direction='vertical'>
                                            {(provided, snapshot) => (
                                                <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                                                
                                                { before_choices.map((card_id, index) => (                                        
                                                    <DiscCardDraggable session={session} profile={profile} col={`before_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={false}/>                          
                                                ))
                                                }
                                                {provided.placeholder}
                                                </List>
                                            )}
                                            </Droppable>
                                        </Grid>
                                        <Grid item sm={4}>
                                        <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>During Training</Typography> 
                                        <Droppable droppableId={`during_choices`} direction='vertical'>
                                            {(provided, snapshot) => (
                                            <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                                                
                                                { during_choices.map((card_id, index) => (                                        
                                                    <DiscCardDraggable session={session} profile={profile} col={`during_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={false}/>                          
                                                ))
                                                }
                                                {provided.placeholder}
                                            </List>
                                            )}
                                        </Droppable>
                                        </Grid>
                                        <Grid item sm={4}>
                                        <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>After Training</Typography> 
                                        <Droppable droppableId={`after_choices`} direction='vertical'>
                                            {(provided, snapshot) => (
                                            <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                                                
                                                { after_choices.map((card_id, index) => (                                        
                                                    <DiscCardDraggable session={session} profile={profile} col={`after_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={false}/>                          
                                                ))
                                                }
                                                {provided.placeholder}
                                            </List>
                                            )}
                                        </Droppable>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        );
                    })}
                </Grid>
                
            </Grid>
            </DragDropContext>
        </div>
        );*/
    
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
