export const IMPORTANT = "1";
export const NOT_IMPORTANT = "0";
export const VERY_IMPORTANT = "2";

export const IMPORTANCE = {
    [IMPORTANT] : 'Important',
    [NOT_IMPORTANT] : 'Not Important',
    [VERY_IMPORTANT] : 'Very Important',
    [-1] : 'Unsorted'
};

export const PROFILE_MALE = 'Microsoft Guy Online (Natural) - English (United States)';
export const PROFILE_FEMALE = 'Microsoft Clara Online (Natural) - English (Canada)';

export const PROFILE_MALE2 = 'Google UK English Male';
export const PROFILE_FEMALE2 = 'Google UK English Female';

export const BLOCKS = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];
export const BLOCK_COLORS = {
    1 : '#cd3527',
    2 : '#cd3527',
    3 : '#cd3527',
    4 : '#cd3527',
    5 : '#cd3527',
    6 : '#cd3527',
    7 : '#a9b23b',
    8 : '#a9b23b',
    9 : '#a9b23b',
    10 : '#a9b23b',
    11 : '#a9b23b',
    12 : '#a9b23b',
    13 : '#e6b153',
    14 : '#e6b153',
    15 : '#e6b153',
    16 : '#e6b153',
    17 : '#e6b153',
    18 : '#e6b153',
    19 : '#4ba9c3',
    20 : '#4ba9c3',
    21 : '#4ba9c3',
    22 : '#4ba9c3',
    23 : '#4ba9c3',
    24 : '#4ba9c3'
}

export const ClueCards = [
    {
        id: 1,
        profile_text: `The condo complex consists of 12 equal sized cubes.`,
    },
    {
        id: 2,
        profile_text: `From the top left, count down one vertical square to create the fitness area.`,
    },
    {
        id: 3,
        profile_text: `Each section of the condo complex is identical in shape.`,
    },
    {
        id: 4,
        profile_text: `From the top right count down 2 vertical squares and construct an adjacent sound proof wall between the shopping area and the fitness area.`,
    },
    {
        id: 5,
        profile_text: `From the bottom left, count up 2 vertical squares to construct an outside wall for the dining area.`,
    },
    {
        id: 6,
        profile_text: `The bottom left area of the complex is a mirror reflection of the bottom right side.`,
    },
    {
        id: 7,
        profile_text: `From the bottom right, count 2 horizontal squares and construct an adjacent sound proof wall in the parking garage.`,
    },
    {
        id: 8,
        profile_text: `The dining area that will be in the bottom left corner is a mirror reflection of the shopping area in the top right.`,
    },
    {
        id: 9,
        profile_text: `The parking area will be next to the dining area for easy access.`,
    },
    {
        id: 10,
        profile_text: `The outside perimeter of the condo complex is surrounded by gardens.`,
    },
    {
        id: 11,
        profile_text: `In the top right corner count across two horizontal squares and construct an outside wall for the shopping center.`,
    },
    {
        id: 12,
        profile_text: `Once completed, tenants will have access to all areas from the outside gardens.`,
    },
    {
        id: 13,
        profile_text: `Each section of the complex consists of 3 cubes.`,
    },
    {
        id: 14,
        profile_text: `The three sets of squares form similar shapes for each of the areas of the ground floor.`,
    },
    {
        id: 15,
        profile_text: `Top left count down one vertical square and construct and adjacent sound proof wall for the dining area.`,
    },
    {
        id: 16,
        profile_text: `Completing the complex on time will ensure residents get time to enjoy the outdoor gardens.`,
    },
    {
        id: 17,
        profile_text: `The top right aligns with the grid.`,
    },
    {
        id: 18,
        profile_text: `Adjacent walls happen when two inside walls touch.`,
    },
    {
        id: 19,
        profile_text: `On the ground floor of the condo complex you will find: Dining, Fitness, Shopping, and Parking.`,
    },
    {
        id: 20,
        profile_text: `The condo complex is the same shape as the Fitness area.`,
    },
    {
        id: 21,
        profile_text: `From the outside wall of the Shopping area, move one vertical square down and across to create the Fitness area.`,
    },
    {
        id: 22,
        profile_text: `Where the stores intersect there is a double sound proof wall to minimize noise from the gym.`,
    },
    {
        id: 23,
        profile_text: `One of the restaurants will have a wood fired pizza oven.`,
    },
    {
        id: 24,
        profile_text: `You can access the fitness area from anywhere on the ground floor of the condo complex.`,
    }
];

