import React, { useState } from 'react';
import svgCards from './../data/svg-cards.svg';
import { Draggable } from 'react-beautiful-dnd';
import { CARDS_LIST } from './../data/data';

const getItemStyle = (isDragging, draggableStyle, visible, draggingOver) => ({
  userSelect: 'none',
  display: visible ? "inline-block" : isDragging ? "inline-block" : "none",
  height: "158px",
  opacity: isDragging ? "visible" :  visible ? "visible" : "hidden",
  ...draggableStyle,
});

const getCardStyle = (isDragging) => ({  
  opacity: isDragging ? 0.5 : 1,
  cursor: "pointer",
  userSelect: "none"
});


/* -----------

  Props:
  cardId: id of the card to display
 
   -----------
*/

export default function OnDeckCard(props) {



  if ( props.control === true ){
    return (      
      <Draggable draggableId={`${props.cardId}`} index={parseInt(props.index)}>
      {(provided, snapshot) => (
        <div className="od-card-contain" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, props.visible, snapshot.draggingOver)}>              
          <svg 
            className="od-card"
            width="102"
            height="158"
            style={getCardStyle(snapshot.isDragging)}
          >
                <use href={`${svgCards}#${CARDS_LIST[props.cardId]}`} x="0" y="0" transform="scale(0.6)"/>
          </svg>      
        </div>
        )}
      </Draggable>      
    );
  } else {
      return (
        <svg 
          className="od-card"
          width="102"
          height="158"
          style={{cursor:"pointer", userSelect: "none"}}
        >
              <use href={`${svgCards}#${CARDS_LIST[props.cardId]}`} x="0" y="0" transform="scale(0.6)"/>
        </svg>
      );
    }
}