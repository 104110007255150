import bbss_logo from './../images/bbss_logo.png';
import bbss_logo_line from './../images/bbss_logo_line.png';
import balloon_red from './../images/activity_svgs/001-balloon-red.svg';
import balloon_blue from './../images/activity_svgs/001-balloon-blue.svg';
import balloon_yellow from './../images/activity_svgs/001-balloon-yellow.svg';
import balloon_green from './../images/activity_svgs/001-balloon-green.svg';
import balloon_white from './../images/activity_svgs/001-balloon-white.svg';
import feather_red from './../images/activity_svgs/004-feather-red.svg';
import feather_blue from './../images/activity_svgs/005-feather-blue.svg';

import pompom_white from './../images/activity_svgs/006-pompom-white.svg';
import pompom_green from './../images/activity_svgs/006-pompom-green.svg';
import pompom_blue from './../images/activity_svgs/006-pompom-blue.svg';
import pompom_purple from './../images/activity_svgs/006-pompom-purple.svg';
import pompom_pink from './../images/activity_svgs/006-pompom-pink.svg';

import star_red from './../images/activity_svgs/007-star-red.svg';
import star_green from './../images/activity_svgs/007-star-green.svg';
import star_blue from './../images/activity_svgs/007-star-blue.svg';

import oval from './../images/activity_svgs/010-oval.svg';

import circle_orange from './../images/activity_svgs/008-circle-orange.svg';
import circle_purple from './../images/activity_svgs/008-circle-purple.svg';
import circle_green from './../images/activity_svgs/008-circle-green.svg';
import circle_white from './../images/activity_svgs/008-circle-white.svg';

import rhombus_green from './../images/activity_svgs/011-rhombus-green.svg';
import rhombus_purple from './../images/activity_svgs/011-rhombus-purple.svg';
import rhombus_orange from './../images/activity_svgs/011-rhombus-orange.svg';

import tongue_depressor_orange from './../images/activity_svgs/002-tongue-depressor-orange.svg';
import tongue_depressor_red from './../images/activity_svgs/002-tongue-depressor-red.svg';
import tongue_depressor_blue from './../images/activity_svgs/002-tongue-depressor-blue.svg';
import tongue_depressor_wood from './../images/activity_svgs/002-tongue-depressor-wood.svg';

import line_blue from './../images/activity_svgs/012-line-blue.svg';
import line_red from './../images/activity_svgs/012-line-red.svg';
import line_white from './../images/activity_svgs/012-line-white.svg';
import line_yellow from './../images/activity_svgs/012-line-yellow.svg';
import line_pink from './../images/activity_svgs/012-line-pink.svg';
import line_purple from './../images/activity_svgs/012-line-purple.svg';

export const PIECE_CATEGORY_PIPE_CLEANER = "Pipe Cleaner";
export const PIECE_CATEGORY_POPSICLE_STICK = "Popsicle Stick";
export const PIECE_CATEGORY_FLUFF = "Feather";
export const PIECE_CATEGORY_PUFF = "Pompom";
export const PIECE_CATEGORY_BALLOON = "Balloon";
export const PIECE_CATEGORY_SHAPE_OVAL = "Oval";
export const PIECE_CATEGORY_SHAPE_CIRCLE = "Circle";
export const PIECE_CATEGORY_SHAPE_STAR = "Star";
export const PIECE_CATEGORY_SHAPE_RHOMBUS = "Rhombus";



export const PIECE_CATEGORIES = [
    PIECE_CATEGORY_PIPE_CLEANER,
    PIECE_CATEGORY_POPSICLE_STICK,
    PIECE_CATEGORY_FLUFF,
    PIECE_CATEGORY_PUFF,
    PIECE_CATEGORY_BALLOON,
    PIECE_CATEGORY_SHAPE_OVAL,
    PIECE_CATEGORY_SHAPE_CIRCLE,
    PIECE_CATEGORY_SHAPE_STAR,
    PIECE_CATEGORY_SHAPE_RHOMBUS
];

export const PIECE_COLORS = {
    'red' : '#c51225',
    'blue' : '#2d46d6',
    'orange' : '#df9d6b',
    'wood' : '#9b9a86',
    'yellow' : '#c0e34b',
    'pink' : '#d466ba',
    'green' : '#299621',
    'white' : "#FFFFFF",
    'purple' : '#826cd8'
}

export const pieces = [
    {
        id: 1,
        category: PIECE_CATEGORY_PIPE_CLEANER,
        color: 'red', 
        image: line_red,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 2,
        category: PIECE_CATEGORY_PIPE_CLEANER,
        color: 'pink', 
        image: line_pink,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 3,
        category: PIECE_CATEGORY_PIPE_CLEANER,
        color: 'yellow', 
        image: line_yellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 4,
        category: PIECE_CATEGORY_PIPE_CLEANER,
        color: 'white', 
        image: line_white,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 5,
        category: PIECE_CATEGORY_PIPE_CLEANER,
        color: 'blue', 
        image: line_blue,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 6,
        category: PIECE_CATEGORY_PIPE_CLEANER,
        color: 'purple', 
        image: line_purple,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 7,
        category: PIECE_CATEGORY_BALLOON,
        color: 'red', 
        image: balloon_red,
        size: {
            width: 300,
            height: 300
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 8,
        category: PIECE_CATEGORY_BALLOON,
        color: 'green', 
        image: balloon_green,
        size: {
            width: 300,
            height: 300
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 9,
        category: PIECE_CATEGORY_BALLOON,
        color: 'yellow', 
        image: balloon_yellow,
        size: {
            width: 300,
            height: 300
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 10,
        category: PIECE_CATEGORY_BALLOON,
        color: 'white', 
        image: balloon_white,
        size: {
            width: 300,
            height: 300
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 11,
        category: PIECE_CATEGORY_BALLOON,
        color: 'blue', 
        image: balloon_blue,
        size: {
            width: 300,
            height: 300
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 12,
        category: PIECE_CATEGORY_FLUFF,
        color: 'red', 
        image: feather_red,
        size: {
            width: 50,
            height: 40
        },
        previewSize: {
            width: 50,
            height: 20
        }
    },
    {
        id: 13,
        category: PIECE_CATEGORY_FLUFF,
        color: 'blue', 
        image: feather_blue,
        size: {
            width: 50,
            height: 40
        },
        previewSize: {
            width: 50,
            height: 20
        }
    },
    {
        id: 14,
        category: PIECE_CATEGORY_PUFF,
        color: 'blue', 
        image: pompom_blue,
        size: {
            width: 50,
            height: 50
        },
        previewSize: {
            width: 50,
            height: 50
        }
    },
    {
        id: 15,
        category: PIECE_CATEGORY_PUFF,
        color: 'white', 
        image: pompom_white,
        size: {
            width: 50,
            height: 50
        },
        previewSize: {
            width: 50,
            height: 50
        }
    },
    {
        id: 16,
        category: PIECE_CATEGORY_PUFF,
        color: 'purple', 
        image: pompom_purple,
        size: {
            width: 50,
            height: 50
        },
        previewSize: {
            width: 50,
            height: 50
        }
    },
    {
        id: 17,
        category: PIECE_CATEGORY_PUFF,
        color: 'pink', 
        image: pompom_pink,
        size: {
            width: 50,
            height: 50
        },
        previewSize: {
            width: 50,
            height: 50
        }
    },
    {
        id: 18,
        category: PIECE_CATEGORY_PUFF,
        color: 'green', 
        image: pompom_green,
        size: {
            width: 50,
            height: 50
        },
        previewSize: {
            width: 50,
            height: 50
        }
    },
    {
        id: 19,
        category: PIECE_CATEGORY_POPSICLE_STICK,
        color: 'wood', 
        image: tongue_depressor_wood,
        size: {
            width: 100,
            height: 50
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 20,
        category: PIECE_CATEGORY_POPSICLE_STICK,
        color: 'blue', 
        image: tongue_depressor_blue,
        size: {
            width: 100,
            height: 50
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 21,
        category: PIECE_CATEGORY_POPSICLE_STICK,
        color: 'red', 
        image: tongue_depressor_red,
        size: {
            width: 100,
            height: 50
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 22,
        category: PIECE_CATEGORY_POPSICLE_STICK,
        color: 'orange', 
        image: tongue_depressor_orange,
        size: {
            width: 100,
            height: 50
        },
        previewSize: {
            width: 100,
            height: 20
        }
    },
    {
        id: 23,
        category: PIECE_CATEGORY_SHAPE_OVAL,
        color: 'wood', 
        image: oval,
        size: {
            width: 35,
            height: 90
        },
        previewSize: {
            width: 35,
            height: 20
        }
    },
    {
        id: 24,
        category: PIECE_CATEGORY_SHAPE_OVAL,
        color: 'wood', 
        image: oval,
        size: {
            width: 75,
            height: 70
        },
        previewSize: {
            width: 75,
            height: 20
        }
    },
    {
        id: 25,
        category: PIECE_CATEGORY_SHAPE_OVAL,
        color: 'wood', 
        image: oval,
        size: {
            width: 100,
            height: 90
        },
        previewSize: {
            width: 100 ,
            height: 20
        }
    },
    {
        id: 26,
        category: PIECE_CATEGORY_SHAPE_STAR,
        color: 'red', 
        image: star_red,
        size: {
            width: 15,
            height: 15
        },
        previewSize: {
            width: 15,
            height: 20
        }
    },
    {
        id: 27,
        category: PIECE_CATEGORY_SHAPE_STAR,
        color: 'blue', 
        image: star_blue,
        size: {
            width: 15,
            height: 15
        },
        previewSize: {
            width: 15,
            height: 20
        }
    },
    {
        id: 28,
        category: PIECE_CATEGORY_SHAPE_STAR,
        color: 'green', 
        image: star_green,
        size: {
            width: 15,
            height: 15
        },
        previewSize: {
            width: 15,
            height: 20
        }
    },
    {
        id: 29,
        category: PIECE_CATEGORY_SHAPE_STAR,
        color: 'red', 
        image: star_red,
        size: {
            width: 45,
            height: 45
        },
        previewSize: {
            width: 45,
            height: 20
        }
    },
    {
        id: 30,
        category: PIECE_CATEGORY_SHAPE_STAR,
        color: 'blue', 
        image: star_blue,
        size: {
            width: 45,
            height: 45
        },
        previewSize: {
            width: 45,
            height: 20
        }
    },
    {
        id: 31,
        category: PIECE_CATEGORY_SHAPE_STAR,
        color: 'green', 
        image: star_green,
        size: {
            width: 45,
            height: 45
        },
        previewSize: {
            width: 45,
            height: 20
        }
    },
    {
        id: 32,
        category: PIECE_CATEGORY_SHAPE_CIRCLE,
        color: 'green', 
        image: circle_green,
        size: {
            width: 30,
            height: 30
        },
        previewSize: {
            width: 30,
            height: 30
        }
    },
    {
        id: 33,
        category: PIECE_CATEGORY_SHAPE_CIRCLE,
        color: 'purple', 
        image: circle_purple,
        size: {
            width: 30,
            height: 30
        },
        previewSize: {
            width: 30,
            height: 30
        }
    },
    {
        id: 34,
        category: PIECE_CATEGORY_SHAPE_CIRCLE,
        color: 'orange', 
        image: circle_orange,
        size: {
            width: 30,
            height: 30
        },
        previewSize: {
            width: 30,
            height: 30
        }
    },
    {
        id: 41,
        category: PIECE_CATEGORY_SHAPE_CIRCLE,
        color: 'white', 
        image: circle_white,
        size: {
            width: 30,
            height: 30
        },
        previewSize: {
            width: 30,
            height: 30
        }
    },
    {
        id: 35,
        category: PIECE_CATEGORY_SHAPE_CIRCLE,
        color: 'green', 
        image: circle_green,
        size: {
            width: 60,
            height: 60
        },
        previewSize: {
            width: 60,
            height: 60
        }
    },
    {
        id: 36,
        category: PIECE_CATEGORY_SHAPE_CIRCLE,
        color: 'purple', 
        image: circle_purple,
        size: {
            width: 60,
            height: 60
        },
        previewSize: {
            width: 60,
            height: 60
        }
    },
    {
        id: 37,
        category: PIECE_CATEGORY_SHAPE_CIRCLE,
        color: 'orange',
        image: circle_orange, 
        size: {
            width: 60,
            height: 60
        },
        previewSize: {
            width: 60,
            height: 60
        }
    },
    {
        id: 42,
        category: PIECE_CATEGORY_SHAPE_CIRCLE,
        color: 'white',
        image: circle_white, 
        size: {
            width: 60,
            height: 60
        },
        previewSize: {
            width: 60,
            height: 60
        }
    },
    {
        id: 38,
        category: PIECE_CATEGORY_SHAPE_RHOMBUS,
        color: 'green', 
        image: rhombus_green,
        size: {
            width: 65,
            height: 35
        },
        previewSize: {
            width: 65,
            height: 20
        }
    },
    {
        id: 39,
        category: PIECE_CATEGORY_SHAPE_RHOMBUS,
        color: 'purple', 
        image: rhombus_purple,
        size: {
            width: 65,
            height: 35
        },
        previewSize: {
            width: 65,
            height: 20
        }
    },
    {
        id: 40,
        category: PIECE_CATEGORY_SHAPE_RHOMBUS,
        color: 'orange', 
        image: rhombus_orange,
        size: {
            width: 65,
            height: 35
        },
        previewSize: {
            width: 65,
            height: 20
        }
    },

];