import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GROUP_STATE_RESULTS, } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults } from './../../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingDots from './../../game_components/loadingDots';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import { CSSTransition } from "react-transition-group";
import CreateIcon from '@material-ui/icons/Create';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextEntry from './../specific_components/textEntry';


const INITIAL_STATE = {

};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#673ab7",
    color: theme.palette.common.white,
    borderRadius: 0,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


class Activity extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        ...INITIAL_STATE,
    };
    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    
  }

  componentDidUpdate(prevProps, prevState) {
      
  }

  finishActivity = () => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_RESULTS,
      params: {}
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  

  render() {
    const {
      session,
      profile,
      auth,
      classes,
      game_options
    } = this.props;
    const { entry_text } = this.state;
    const isInvalid = entry_text === '';
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);
    let title = session.active_game.name;
        if ( game_options !== false ){
            title = game_options.game_texts.game_title;
        }
    return (
      <div>
          <Typography variant='h3' className={classes.spaceBottom}>
              {title}
          </Typography>


          <TableContainer component={Paper} style={{width: "100%"}}>
            <Table aria-label="customized table">
            <TableHead>
              <TableRow style={{fontSize: "1.3em", textTransform: "uppercase"}}>
                <StyledTableCell align="center">Role</StyledTableCell>
                <StyledTableCell align="center">Do/Say to <span style={{fontWeight:"bold", fontSize: "1.3em", color: "green"}}>Build</span> Trust</StyledTableCell>
                <StyledTableCell align="center">Do/Say to <span style={{fontWeight:"bold", fontSize: "1.3em", color: "red"}}>Destroy</span> Trust</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
    
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      Plant Manager
                    </StyledTableCell>
                    <StyledTableCell align="right"><TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"PM_buildtrust"} savePath={`active_game.results.${group_id}.PM_buildtrust`}/></StyledTableCell>
                    <StyledTableCell align="right"><TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"PM_destroytrust"} savePath={`active_game.results.${group_id}.PM_destroytrust`}/></StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      Department Manager
                    </StyledTableCell>
                    <StyledTableCell align="right"><TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"DM_buildtrust"} savePath={`active_game.results.${group_id}.DM_buildtrust`}/></StyledTableCell>
                    <StyledTableCell align="right"><TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"DM_destroytrust"} savePath={`active_game.results.${group_id}.DM_destroytrust`}/></StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      Supervisor (YOU)
                    </StyledTableCell>
                    <StyledTableCell align="right"><TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"SUPERVISOR_buildtrust"} savePath={`active_game.results.${group_id}.SUPERVISOR_buildtrust`}/></StyledTableCell>
                    <StyledTableCell align="right"><TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"SUPERVISOR_destroytrust"} savePath={`active_game.results.${group_id}.SUPERVISOR_destroytrust`}/></StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      Employee
                    </StyledTableCell>
                    <StyledTableCell align="right"><TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"EMPLOYEE_buildtrust"} savePath={`active_game.results.${group_id}.EMPLOYEE_buildtrust`}/></StyledTableCell>
                    <StyledTableCell align="right"><TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"EMPLOYEE_destroytrust"} savePath={`active_game.results.${group_id}.EMPLOYEE_destroytrust`}/></StyledTableCell>
                  </StyledTableRow>
                    
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container>
            <Grid item xs={12} className={classes.spacingTop}>
                  { hasControl ? 
                    <div>
                    <div className={classes.spacingTop}>When you are finished, click on the finish activity button.</div>
                    <Button type='submit' color="primary" variant='contained' onClick={this.finishActivity} startIcon={<DoneIcon/>} className={classes.spacingTop}>
                      Finish Activity
                    </Button>
                    </div>
                  : null
                  }
              </Grid>
            </Grid>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);