import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/performance';
//import 'firebase/analytics';





const config = {};
if (process.env.REACT_APP_ENV === 'dev') {
  console.log(`🔥🔥🐉 DEVELOPMENT MODE 🐉🔥🔥`);

  config.apiKey = process.env.REACT_APP_API_KEY_DEV;
  config.authDomain = process.env.REACT_APP_AUTH_DOMAIN_DEV;
  config.databaseURL = process.env.REACT_APP_DATABASE_URL_DEV;
  config.projectId = process.env.REACT_APP_PROJECT_ID_DEV;
  config.storageBucket = process.env.REACT_APP_STORAGE_BUCKET_DEV;
  config.messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID_DEV;
  config.appId = process.env.REACT_APP_APP_ID_DEV;
  config.measurementId = process.env.REACT_APP_MEASUREMENT_ID_DEV;
} else {
  config.apiKey = process.env.REACT_APP_API_KEY;
  config.authDomain = process.env.REACT_APP_AUTH_DOMAIN;
  config.databaseURL = process.env.REACT_APP_DATABASE_URL;
  config.projectId = process.env.REACT_APP_PROJECT_ID;
  config.storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
  config.messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
  config.appId = process.env.REACT_APP_APP_ID;
  config.measurementId = process.env.REACT_APP_MEASUREMENT_ID;
}






/*var config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};*/

firebase.initializeApp(config);
//firebase.analytics();
firebase.performance();
//firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
