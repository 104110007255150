
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
  TableBody, TableCell, Collapse, TableHead, TableRow, Box, MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { signOut } from '../../store/actions/authActions';
import Footer from '../Footer';
import LogoHeader from '../Header';
import styles from '../Styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {saveActivityOptionSetFile, saveSpecificActivityOptionPath} from '../../store/actions/adminActions';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AddUser from './addUser';
import Alert from '@material-ui/lab/Alert';
import Navigation from '../Navigation';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { accessCheck } from './../Functions';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { getGameImage } from './../GamesList';
import GameImage from './game_image';
import ColorSelection from './color_selection';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import LiquidLoadingBlock from '../Loading/loadingCup';


const INITIAL_STATE = {
  selectedHost: false
};



class ThemeManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };

  }

  saveOptionSetFile = (hostID, key, files) => {
    let setOrUpdate = "update";
    if ( this.props.profile.role === 'host' ){
      if ( this.props.themes === null || this.props.themes === undefined ){
        setOrUpdate = "set";
      }
    }
    if ( this.props.profile.role === 'admin' ){
      if ( this.props.themes[hostID] === null || this.props.themes[hostID] === undefined ){
        setOrUpdate = "set";
      }
  }
    this.props.saveActivityOptionSetFile(hostID, key, files, setOrUpdate);
  }
  saveOption = (doc, fieldName, val, setOrUpdate) => {
    this.props.saveSpecificActivityOptionPath(doc, fieldName, val, setOrUpdate);
  }
  
  updateColor = (color) => {
    const host = this.props.profile.role === 'host' ? this.props.auth.uid : this.state.selectedHost;
    let setOrUpdate = "update";
    if ( this.props.profile.role === 'host' ){
      if ( this.props.themes === null || this.props.themes === undefined ){
        setOrUpdate = "set";
      }
    }
    if ( this.props.profile.role === 'admin' ){
        if ( this.props.themes[host] === null || this.props.themes[host] === undefined ){
          setOrUpdate = "set";
        }
    }
    console.log(color, host);
    this.saveOption(host, 'color', color, setOrUpdate);
  }
  
  onChange = (event) => {
    console.log('setting: ', event.target.name, event.target.value);
    this.setState({
       ...this.state,
      [event.target.name]: event.target.value });

  };

  render() {

    if ( !this.props.auth.uid ){
      return <Redirect to={ROUTES.LOGIN} />;
    }


    console.log('role: ', this.props.profile, this.props.hosts);
    if ( this.props.profile.isLoaded === false ){
      console.log('here1');
      if ( this.props.profile.role === 'admin' && this.props.hosts === undefined ){
        return (
          <div className='meta-react'>
          <Navigation profile={this.props.profile} auth={this.props.auth}/>
          <LogoHeader profile={this.props.profile} auth={this.props.auth} />
          <div className='outer-container'>
            <div className='container'>
              <Typography variant="h4">Theme Management</Typography>
              
              </div>
            </div>
            <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/>
        </div>
        )
      }

      if ( this.props.profile.role === 'host' && this.props.themes.isLoaded === false ){
        return (
          <div className='meta-react'>
          <Navigation profile={this.props.profile} auth={this.props.auth}/>
          <LogoHeader profile={this.props.profile} auth={this.props.auth} />
          <div className='outer-container'>
            <div className='container'>
              <Typography variant="h4">Theme Management</Typography>
              
              </div>
            </div>
            <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/>
        </div>
        )
      }
    }

    if ( !isLoaded(this.props.profile) ){
      return (
        <div className='meta-react'>
        <Navigation profile={this.props.profile} auth={this.props.auth}/>
        <LogoHeader profile={this.props.profile} auth={this.props.auth} />
        <div className='outer-container'>
          <div className='container'>
            <Typography variant="h4">Theme Management</Typography>
                <LiquidLoadingBlock/>
            </div>
          </div>
          <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/>
      </div>);
    }


    if ( this.props.profile.role === 'admin' && this.state.selectedHost === false ){
      console.log("HOSTS:", this.props.hosts);


      if ( !isLoaded(this.props.hosts) ){
        return (
          <div className='meta-react'>
          <Navigation profile={this.props.profile} auth={this.props.auth}/>
          <LogoHeader profile={this.props.profile} auth={this.props.auth} />
          <div className='outer-container'>
            <div className='container'>
              <Typography variant="h4">Theme Management</Typography>
                  <LiquidLoadingBlock/>
              </div>
            </div>
            <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/>
        </div>);
      }

      return (
        <div className='meta-react'>
        <Navigation profile={this.props.profile} auth={this.props.auth}/>
        <LogoHeader profile={this.props.profile} auth={this.props.auth} />
        <div className='outer-container'>
          <div className='container'>
            <Typography variant="h4">Theme Management</Typography>

            { /* HOST SELECTION HERE */ }
            <Typography> DROP DOWN HOST SELECTOR</Typography>
              <InputLabel id='host-selector-label'>Please select host to view theme data.</InputLabel>
              <Select aria-describedby='Host selector' value={this.state.selectedHost} name='selectedHost' onChange={this.onChange}>
                {
                  this.props.hosts.map((t, index)=>{
                    return(
                    <MenuItem key={index} value={t.id}>{t.displayName}</MenuItem>
                    )
                  })
                }
              </Select>
            </div>
          </div>
          <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/>
      </div>
      )
    }

    console.log("themes: ", this.props.themes);
    const host = this.props.profile.role === 'host' ? this.props.auth.uid : this.state.selectedHost;
    console.log("selectedHost: ", host, this.state.selectedHost, this.props.themes);
    let theme = this.props.profile.role === 'host' ? this.props.themes : this.props.themes === undefined || this.props.themes === null ? null : this.props.themes[this.state.selectedHost];
    //this.props.themes[this.state.selectedHost]
    if ( theme === null || theme === undefined ){
      theme = this.props.defaultTheme;
    }

    if ( theme === null || theme === undefined || theme === 0 ){
      console.log('here?');
      return (
        <div className='meta-react'>
        <Navigation profile={this.props.profile} auth={this.props.auth}/>
        <LogoHeader profile={this.props.profile} auth={this.props.auth} />
        <div className='outer-container'>
          <div className='container'>
            <Typography variant="h4">Theme Management</Typography>
              
            </div>
          </div>
          <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/>
      </div>
      )
    }


    console.log("THEME? : ", theme, this.props.defaultTheme);
    if ( theme.color === undefined ){
      theme = {
        ...theme,
        color: this.props.defaultTheme.color
      };
    }
    if ( theme.logo === undefined ){
      theme = {
        ...theme,
        logo: this.props.defaultTheme.logo
      }
    }
    console.log('theme', theme);
    
    return (
      <div className='meta-react'>
      <Navigation profile={this.props.profile} auth={this.props.auth}/>
      <LogoHeader profile={this.props.profile} auth={this.props.auth} />
      <div className='outer-container'>
        <div className='container'>
          <Typography variant="h4">Theme Management</Typography>
            <br/>
            { this.props.profile.role === 'admin' ? 
            <Button  variant="contained" color="primary" onClick={() => { this.setState({ ...this.state, selectedHost: false })}}>Select a Different Host</Button>
            : null }
            <br/><br/>

            <GameImage saveActivityOptionSetFile={this.saveOptionSetFile} hostID={host} saveOption={this.saveOption} key={`logo`} o={theme} oKey={`logo`}/>
<br/><br/>
            <Typography variant="h6" style={{fontWeight: "bold"}}>Color</Typography>
            <ColorSelection color={theme.color} updateColor={this.updateColor}/>

          </div>
        </div>
        <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/>
    </div>
    )
  }
}



const mapStateToProps = (state) => {
  return {
    themes: state.firestore.data.themes,
    defaultTheme: state.firestore.data.defaultTheme,
    hosts: state.firestore.ordered.hosts,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSpecificActivityOptionPath : (doc, fieldName, val, setOrUpdate) => dispatch(saveSpecificActivityOptionPath(doc, fieldName, val, setOrUpdate) ),
    saveActivityOptionSetFile : (hostID, key, files, setOrUpdate) => dispatch( saveActivityOptionSetFile(hostID, key, files, setOrUpdate) )
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => {
    if ( props.auth.uid === undefined ){
      return [];
    }
    if ( props.profile.role === 'admin' ){
      return [
        {
          collection: 'theme',
          storeAs: 'themes'
        },
        {
          collection: 'players',
          where: [
            ['role', '==', 'host'],      
          ],
          storeAs: 'hosts'
        },
        { 
          collection: 'theme', 
          doc: 'default',
          storeAs: 'defaultTheme'
        }
      ]
    } else {
      //console.log("hm", props.auth.uid);
      return [
        {
          collection: 'theme',
          doc: props.auth.uid,
          storeAs: 'themes'
        },
        { 
          collection: 'theme', 
          doc: 'default',
          storeAs: 'defaultTheme'
        }
      ]
    }
    
  })
)(ThemeManagement);