import { gameGroupServer, gameGroupStateServer, GameGroupDefaultStates, gameHasOptions } from './../../components/Game/gameServer';
require("firebase/functions")

export const GAME_STATE_INIT = 'init';
export const GAME_STATE_ACTIVE = 'active';
export const GAME_STATE_RESULTS = 'results';
export const HOST_STATE_GROUP_SELECT = 'group_select';
export const HOST_STATE_GAME_OPTIONS = 'game_options';
export const HOST_STATE_GROUP = 'group';
export const HOST_STATE_MAIN = 'main';
export const HOST_STATE_RESULTS = 'results';

export const GROUP_STATE_INIT = 'init';
export const GROUP_STATE_ACTIVE = 'active';
export const GROUP_STATE_RESULTS = 'results';

export const startGame = (gameID, gameName, game) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let players = getState().firestore.ordered.current_sessions[0].players;
    let moderators = getState().firestore.ordered.current_sessions[0].moderators;

    let hasOptions = gameHasOptions(gameID);

    let hostStateDetermined = HOST_STATE_GROUP_SELECT;
    if ( game.hasGroups === false ){
      hostStateDetermined = HOST_STATE_MAIN;
    }


    let modState = {
      defaultState: { state: hostStateDetermined, params: {} }
    };
    if ( moderators !== undefined ){
      if ( moderators.length > 0 ){
        moderators.map((mod, ind) => {
          modState[mod] = { state: hostStateDetermined, params: {} }
          return null;
        });
      }
    }

    console.log("WHAT's GOING ON", hostStateDetermined, game, game.hasGroups);

    let host = firebase.auth().currentUser.uid;
    if ( getState().firebase.profile.role === 'moderator' ){
      host = getState().firebase.profile.moderates;
    }

    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              active_game: {
                id: gameID,
                name: gameName,
                hasGroups: (game.hasGroups === false ? false : true),
                groups: gameGroupServer(gameID, players),
                groupStates: gameGroupStateServer(gameID),
                gameState: { state: GAME_STATE_INIT, params: {} },
                hostState: { state: hasOptions ? HOST_STATE_GAME_OPTIONS : hostStateDetermined, params: {} },
                moderatorStates: {
                  defaultState: { state: hostStateDetermined, params: {} }
                },
                results: {},
                resultSnapshot: {},
                startedOn: Date.now(),
              },
            })
            .then(() => {
              dispatch({ type: 'GAME_CREATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GAME_CREATION_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GAME_CREATION_ERROR', err });
      });
  };
};

export const endGame = () => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection('current_sessions')
      .where('host', '==', firebase.auth().currentUser.uid)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const copyable_data = { ...doc.data() };
          doc.ref
            .update({
              active_game: {},
              past_games: firestore.FieldValue.arrayUnion(copyable_data.active_game),
            })
            .then(() => {
              dispatch({ type: 'GAME_ENDED' });
            })
            .catch((err) => {
              dispatch({ type: 'GAME_END_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GAME_END_ERROR', err });
      });
  };
};




export const endLoading = () => {
  return (dispatch) => {
    dispatch({ type: 'LOADING_DONE' });
  };
}

export const beginGame = (groups) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });

    let g = groups;
    let gStates = getState().firestore.ordered.current_sessions[0].active_game.groupStates;
    let updateGState = false;
    Object.keys(g).map((group, index) => {

      if (g[group].id !== 'group-0') {

        if (g[group].leadPlayer === '') {
          // There is no leadPlayer. If the group has players, select one to be the lead.

          if (g[group].playerIds.length > 0) {
            g[group].leadPlayer = g[group].playerIds[0];
          }
        } else {
          // There is a leadPlayer. Ensure that the player that is the leadPlayer is still in the group
          if (!g[group].playerIds.includes(g[group].leadPlayer)) {
            if (g[group].playerIds.length > 0) {
              g[group].leadPlayer = g[group].playerIds[0];
            }
          }
        }        
      }

      if (!gStates.hasOwnProperty(group)) {
        gStates = {
          ...gStates,
          [group]: {
            state: 'init',
            params: {},
          },
        };
        return (updateGState = true);
      } else {
        return null;
      }
    });

    let host = firebase.auth().currentUser.uid;
    if ( getState().firebase.profile.role === 'moderator' ){
      host = getState().firebase.profile.moderates;
    }

    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (updateGState) {
            doc.ref
              .update({
                'active_game.gameState': { state: GAME_STATE_ACTIVE, params: {} },
                'active_game.groups': g,
                'active_game.hostState': { state: HOST_STATE_MAIN, params: {} },
                'active_game.groupStates': gStates,
                'active_game.activeOn': Date.now(),
              })
              .then(() => {
                dispatch({ type: 'GAME_STARTED' });
              })
              .catch((err) => {
                dispatch({ type: 'GAME_START_ERROR', err });
              });
          } else {
            doc.ref
              .update({
                'active_game.gameState': { state: GAME_STATE_ACTIVE, params: {} },
                'active_game.groups': g,
                'active_game.hostState': { state: HOST_STATE_MAIN, params: {} },
                'active_game.activeOn': Date.now(),
              })
              .then(() => {
                dispatch({ type: 'GAME_STARTED' });
              })
              .catch((err) => {
                dispatch({ type: 'GAME_START_ERROR', err });
              });
          }
        });
      })
      .catch((err) => {
        dispatch({ type: 'GAME_START_ERROR', err });
      });
  };
};

export const moveBackToResultsPhase = (session, host) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    let groupStates = { ...session.active_game.groupStates };
    let results = { ...session.active_game.results };
    let GSKeys = Object.keys(groupStates);
    let i = 0;
    for( i=0; i < GSKeys.length; i++ ){
      groupStates[GSKeys[i]] = {
        params: {},
        state: GROUP_STATE_ACTIVE
      };
    }
    
    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              'active_game.gameState.state' : GAME_STATE_ACTIVE,
              'active_game.groupStates' : groupStates,
              'active_game.resultSnapshot' : results
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  }
}

export const getActivityLogo = (url) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const storage = firebase.storage().ref();
    if (url) {
      storage
        .child(url)
        .getDownloadURL()
        .then((url) => {
          // This can be downloaded directly:
          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function (event) {
          };
          xhr.open('GET', url);
          xhr.send();

          let resp = { url: url };
          dispatch({ type: 'LOGO_WAS_FOUND', resp });
        })
        .catch((error) => {
          dispatch({ type: 'LOGO_WAS_NOT_FOUND', error });
        });
    } else {
      dispatch({ type: 'NO_COMPANY_LOGO' });
    }
  };
};

export const doneUpdatingImage = () => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({ type: "OPTIONS_IMAGE_RELOADED", s : getState.game_state });
  }
}

export const saveSpecificActivityOption = (key, val, game_id, game_name, property) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    let host = firebase.auth().currentUser.uid;
    let path = `${host}.${property}.${key}`

    dispatch({ type: 'OPTIONS_SAVE_LOADING', s : getState().game_state } );

    firestore
      .collection('game_options')
      .doc(game_id)
      .update({
        [path] : val
      })
      .then(() => {
          dispatch({ type: 'OPTIONS_SAVE_DONE', s : getState().game_state, newImage: false });        
      })
      .catch((err) => {
        dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
      }); 
  }
}


export const saveActivityOptionFile = (key, file, game_id, game_name, property) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    let host = firebase.auth().currentUser.uid;
    let path = `${host}.${property}.${key}`;
    let newImg = "";

    dispatch({ type: 'OPTIONS_SAVE_LOADING', s : getState().game_state } );

    firebase
    .uploadFiles(`activity_images/${game_id}/`, file)
    .then((snapshot) => {
      newImg = snapshot[0].uploadTaskSnapshot.metadata.fullPath;
      firestore
        .collection('game_options')
        .doc(game_id)  
        .update({
          [path] : snapshot[0].uploadTaskSnapshot.metadata.fullPath
        })
        .then(() => {

            dispatch({ type: 'OPTIONS_SAVE_DONE', s : getState().game_state, newImage: newImg });
          
        })
        .catch((err) => {
          dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
        });
        
      }).catch((err) => {
        dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
      });
  }
}


export const saveActivityOptions = (options, files, game_id, game_name, shouldStart) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    let host = firebase.auth().currentUser.uid;

    if ( files !== false ){
      firebase
          .uploadFiles(`activity_images/${game_id}/`, files.main)
          .then((snapshot) => {
            let optionsRevised = {
              ...options,
              game_images: {
                ...options.game_images,
                main: snapshot[0].uploadTaskSnapshot.metadata.fullPath
              }
            }
            firestore
              .collection('game_options')
              .doc(game_id)  
              .update({
                [host] : optionsRevised
              })
              .then(() => {
                if ( shouldStart ){
                  dispatch(startGame(game_id, game_name));
                } else {
                  dispatch({ type: 'OPTIONS_SAVE_DONE', s : getState().game_state, newImage: optionsRevised.game_images.main });
                }                
              })
              .catch((err) => {
                dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
              });
             
            }).catch((err) => {
              dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
            });    
    } else {
        firestore
          .collection('game_options')
          .doc(game_id)
          .update({
            [host] : options
          })
          .then(() => {
            if ( shouldStart ){
              dispatch(startGame(game_id, game_name));
            } else {
              dispatch({ type: 'OPTIONS_SAVE_DONE', s : getState().game_state, newImage: false });
            }
          })
          .catch((err) => {
            dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
          });     
    }
  }
}

export const moveBackToInitPhaseWithOption = (session, host, optionName, optionVal) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    let groupStates = { ...session.active_game.groupStates };
    let results = { ...session.active_game.results };
    let GSKeys = Object.keys(groupStates);
    let i = 0;
    for( i=0; i < GSKeys.length; i++ ){
      groupStates[GSKeys[i]] = {
        params: {},
        state: GROUP_STATE_INIT
      };
    }
    
    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              'active_game.gameState.state' : GAME_STATE_ACTIVE,
              'active_game.groupStates' : groupStates,
              'active_game.resultSnapshot' : results,
              'active_game.results' : {},
              [`active_game.${optionName}`] : optionVal
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  }
}


export const moveBackToActiveWithParams = (session, host, parameters) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    let groupStates = { ...session.active_game.groupStates };
    let results = { ...session.active_game.results };
    let GSKeys = Object.keys(groupStates);
    let i = 0;
    for( i=0; i < GSKeys.length; i++ ){
      groupStates[GSKeys[i]] = {
        params: parameters,
        state: GROUP_STATE_ACTIVE
      };
    }
    
    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              'active_game.gameState.state' : GAME_STATE_ACTIVE,
              'active_game.gameState.params' : parameters,
              'active_game.groupStates' : groupStates,
              'active_game.resultSnapshot' : results
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  }
}

export const saveGroups = (groups) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let g = groups;
    let gStates = getState().firestore.ordered.current_sessions[0].active_game.groupStates;
    let updateGState = false;

    Object.keys(g).map((group, index) => {
      if (g[group].id !== 'group-0') {
        if (g[group].leadPlayer === '') {
          // There is no leadPlayer. If the group has players, select one to be the lead.
          if (g[group].playerIds.length > 0) {
            g[group].leadPlayer = g[group].playerIds[0];
          }
        } else {
          // There is a leadPlayer. Ensure that the player that is the leadPlayer is still in the group
          if (!g[group].playerIds.includes(g[group].leadPlayer)) {
            if (g[group].playerIds.length > 0) {
              g[group].leadPlayer = g[group].playerIds[0];
            }
          }
        }
      }

      if (!gStates.hasOwnProperty(group)) {
        gStates = {
          ...gStates,
          [group]: {
            state: GameGroupDefaultStates(getState().firestore.ordered.current_sessions[0].active_game.id),
            params: {},
          },
        };
        return (updateGState = true);
      } else {
        return null;
      }
    });

    let host = firebase.auth().currentUser.uid;
    if ( getState().firebase.profile.role === 'moderator' ){
      host = getState().firebase.profile.moderates;
    }

    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (updateGState) {
            doc.ref
              .update({
                'active_game.groups': g,
                'active_game.groupStates': gStates,
              })
              .then(() => {
                dispatch({ type: 'GAME_GROUPS_UPDATED' });
              })
              .catch((err) => {
                dispatch({ type: 'GAME_GROUPS_UPDATE_ERROR', err });
              });
          } else {
            doc.ref
              .update({
                'active_game.groups': g,
              })
              .then(() => {
                dispatch({ type: 'GAME_GROUPS_UPDATED' });
              })
              .catch((err) => {
                dispatch({ type: 'GAME_GROUPS_UPDATE_ERROR', err });
              });
          }
        });
      })
      .catch((err) => {
        dispatch({ type: 'GAME_GROUPS_UPDATE_ERROR', err });
      });
  };
};

export const renameGroup = (groupKey, groupName, host) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    let st = `active_game.groups.${groupKey}.title`;

    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
             [st] : groupName,
            })
            .then(() => {
              dispatch({ type: 'GROUP_NAME_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_NAME_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_NAME_UPDATE_ERROR', err });
      });
  };
};

export const updateHostState = (hostState) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });

    firestore
      .collection('current_sessions')
      .where('host', '==', firebase.auth().currentUser.uid)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              'active_game.hostState': hostState,
            })
            .then(() => {
              dispatch({ type: 'HOST_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'HOST_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'HOST_STATE_UPDATE_ERROR', err });
      });
  };
};

export const updateModState = (mod, modState, host) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    if ( mod !== undefined ){
      firestore
        .collection('current_sessions')
        .where('host', '==', host)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
              .update({
                ['active_game.moderatorStates.' + mod]: modState,
              })
              .then(() => {
                dispatch({ type: 'MOD_STATE_UPDATED' });
              })
              .catch((err) => {
                dispatch({ type: 'MOD_STATE_UPDATE_ERROR', err });
              });
          });
        })
        .catch((err) => {
          dispatch({ type: 'MOD_STATE_UPDATE_ERROR', err });
        });
    } else {
      dispatch({ type: 'MOD_STATE_UPDATE_ERROR' });
    }
  };
};

export const updateGameState = (gameState, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              'active_game.gameState': gameState,
            })
            .then(() => {
              dispatch({ type: 'GAME_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GAME_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GAME_STATE_UPDATE_ERROR', err });
      });
  };
};

export const updateGameStateParams = (params, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              'active_game.gameState.params': params,
            })
            .then(() => {
              dispatch({ type: 'GAME_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GAME_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GAME_STATE_UPDATE_ERROR', err });
      });
  };
};

export const updateGroupState = (group, groupState, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.groupStates.' + group]: groupState,
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const updateGroupStateStateVal = (group, groupState, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.groupStates.' + group + '.state']: groupState,
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const updateGroupStateParams = (group, groupStateParams, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.groupStates.' + group + '.params']: groupStateParams,
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const updateGroupStateParamsVal = (group, val, groupStateParams, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.groupStates.' + group + '.params.' + val]: groupStateParams,
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const updateGroupStateEndInterruption = (group, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.groupStates.' + group + '.params.interrupting']: false,
              ['active_game.groupStates.' + group + '.params.lastInterruption']: Date.now(),
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const updateGroupStateVal = (group, val, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.groupStates.' + group + '.state']: val,
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const removePlayer = (player, groupKey, playersList, isGameActive) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let st = 'active_game.groups.' + groupKey + '.playerIds';
    let host = firebase.auth().currentUser.uid;
    if ( getState().firebase.profile.role === 'moderator' ){
      host = getState().firebase.profile.moderates;
    }

    const pList = {...playersList};
    delete pList[player];

    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (groupKey !== null) {
            if ( isGameActive === true ){
              doc.ref
                .update({
                  'players': firestore.FieldValue.arrayRemove(player),
                  [st]: firestore.FieldValue.arrayRemove(player),
                  'playerProfiles' : pList
                })
                .then(() => {                  
                  dispatch({ type: 'GAME_ENDED' });
                })
                .catch((err) => {
                  dispatch({ type: 'GAME_END_ERROR', err });
                });
            } else {
              doc.ref
                .update({
                  'players': firestore.FieldValue.arrayRemove(player),                  
                  'playerProfiles' : pList
                })
                .then(() => {                  
                  dispatch({ type: 'GAME_ENDED' });
                })
                .catch((err) => {
                  dispatch({ type: 'GAME_END_ERROR', err });
                });
            }
          } else {
            doc.ref
              .update({
                'players': firestore.FieldValue.arrayRemove(player),
                'playerProfiles' : pList
              })
              .then(() => {
                firestore
                .collection('players')
                .doc(player)
                .delete();
                dispatch({ type: 'GAME_ENDED' });
              })
              .catch((err) => {
                dispatch({ type: 'GAME_END_ERROR', err });
              });
          }
        });
      })
      .catch((err) => {
        dispatch({ type: 'GAME_END_ERROR', err });
      });
  };
};

export const removePlayerSignOut = (player, groupKey, playersList, isGameActive) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const sendUser = firebase.functions().httpsCallable('removePlayerFromAdmin');
    dispatch({ type: 'FOOTER_LOADING' });

    const pList = {...playersList};
    delete pList[player];
    let data = {
      group: groupKey,
      host: getState().firebase.profile.role === 'host' ? firebase.auth().currentUser.uid : getState().firebase.profile.sessionHost,
      list: {...pList},
      isGameActive: isGameActive
    }

    sendUser(data).then((result) => {
      if (result.data.success === true) {
        dispatch({ type: 'FOOTER_LOADING_DONE' });
      } else if (result.data.success === false) {
        dispatch({ type: 'FOOTER_LOADING_DONE', result });
      }
    });
  };
};


export const removePlayerProfile = (player) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
    .collection('players')
    .doc(player)
    .delete()
    .then(() => {
      dispatch({ type: 'PROFILE_REMOVED' });
    }).catch((err) => {
      dispatch({ type: 'PROFILE_REMOVE_ERROR', err });
    });;
  }
}

export const changeLeader = (groupId, playerId, host) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    let st = 'active_game.groups.' + groupId + '.leadPlayer';

    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              [st]: playerId,
            })
            .then(() => {
              dispatch({ type: 'LEAD_PLAYER_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'LEAD_PLAYER_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'LEAD_PLAYER_UPDATE_ERROR', err });
      });
  };
};

export const updateResults = (group, top_level, value, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.results.' + group + '.' + top_level]: firestore.FieldValue.arrayUnion(value),
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const removeResultsArray = (group, top_level, value, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.results.' + group + '.' + top_level]: firestore.FieldValue.arrayRemove(value),
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const removeExactItemFromArrayResultsPath = (path, value, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              [path]: firestore.FieldValue.arrayRemove(value),
            })
            .then(() => {
              dispatch({ type: 'RESULTS_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'RESULTS_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'RESULTS_UPDATE_ERROR', err });
      });
  };
};

export const updateResultsPath = (path, value, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              [path]: firestore.FieldValue.arrayUnion(value),
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};


/* specific to the choose your own adventure game */
export const editResultsAndGroupStatePath = (resultsPath, resultsValue, statePath, stateValue, historyPath, historyValue, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    console.log('what?');

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              [resultsPath]: resultsValue,
              [statePath]: stateValue,
              [historyPath]: firestore.FieldValue.arrayUnion(historyValue)
            })
            .then(() => {
              console.log('yes');
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              console.log('no');
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        console.log('no2', err);
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const updateResultsPathObject = (path, value, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              [path]: value,
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const editResults = (group, top_level, value, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.results.' + group + '.' + top_level]: value,
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const editResultsPath = (path, value, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              [path]: value,
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};



export const editResultsFloor = (uid, value, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.results.floor.' + uid]: value,
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};


export const removeResults = (group, top_level, value, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              ['active_game.results.' + group + '.' + top_level]: value,
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};

export const removeResultsPath = (path, value, hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    dispatch({ type: 'LOADING' });
    firestore
      .collection('current_sessions')
      .where('host', '==', hostID)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              [path]: firestore.FieldValue.delete(),
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  };
};



export const moveToPhaseWithParamsOneGroup = (session, host, parameters, groupState, group) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    let results = { ...session.active_game.results };

    let pathState = `active_game.groupStates.${group}.state`;
    let pathParams = `active_game.groupStates.${group}.params`;
    let resultsPath = `active_game.results.${group}`;

    

    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              [pathState] : groupState,
              [pathParams] : parameters,
              'active_game.resultSnapshot' : results,
              [resultsPath] : firestore.FieldValue.delete()
            })
            .then(() => {
              dispatch({ type: 'GROUP_STATE_UPDATED' });
            })
            .catch((err) => {
              dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_STATE_UPDATE_ERROR', err });
      });
  }
}
