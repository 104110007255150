import React, { Component } from "react";
import {
  determineHost,
  getUserGroup_hc,
  hasGameControl,
} from "../../game_functions/functions";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import { GROUP_STATE_ACTIVE } from "../../../../../store/actions/gameActions";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import LoadingBlock from "../../../../Loading";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { compose } from "redux";
import { connect } from "react-redux";
import { getFirebase } from "react-redux-firebase";
import logo from "../images/logo.png";
import styles from "../../../../Styles";
import { updateGroupState, editResultsPath } from "./../../../../../store/actions/gameActions";
import { withStyles } from "@material-ui/core/styles";
import { pieces } from './../data/data';

const INITIAL_STATE = {
  logoURLs: {},
  imagesLoaded: false,
  helpOpen: false,
  secondaryLeaderOpen: false,
  secondaryLeader: "",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Instructions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  processImages = async () => {
    let logos = {};
    const sto = getFirebase().storage();
    await Promise.all(
      Object.keys(this.props.game_options.game_images).map(async (key, ind) => {
        let ref = sto.ref(this.props.game_options.game_images[key]);
        let url = await ref.getDownloadURL();
        logos[key] = url;
      })
    );
    return Promise.resolve(logos);
  };

  async componentDidMount() {
    try {
      await this.processImages().then((logos) => {
        window.scrollTo(0, 0);
        this.setState({
          ...this.state,
          logoURLs: logos,
          imagesLoaded: true,
          secondaryLeader:
            this.props.session.active_game.groupStates[this.props.group_id]
              .secondaryLeader === undefined
              ? ""
              : this.props.session.active_game.groupStates[this.props.group_id]
                  .secondaryLeader,
        });
        return Promise.resolve();
      });
    } catch (error) {
      console.log(error);
    }
  }

  sendToNextStep = (event) => {
    const groupID = getUserGroup_hc(
      this.props.session,
      this.props.auth.uid,
      this.props.profile.role
    );

    if (this.props.session.active_game.results[groupID] === undefined) {
      let d = [ ...pieces ];
      let path = `active_game.results.${this.props.group_id}.images`

      this.props.editResultsPath(
          path,
          d,
          determineHost(this.props.profile, this.props.auth)
      )

      
  }


    let s = {
      state: GROUP_STATE_ACTIVE,
      params: {
        startedOn:
          this.props.session.active_game.groupStates[groupID].params
            .startedOn === undefined ||
          this.props.session.active_game.groupStates[groupID].params
            .startedOn === null
            ? Date.now()
            : this.props.session.active_game.groupStates[groupID].params
                .startedOn,
        secondaryLeader: this.state.secondaryLeader,
      },
    };
    this.props.updateGroupState(
      groupID,
      s,
      determineHost(this.props.profile, this.props.auth)
    );
  };

  helpOpen = () => {
    this.setState({
      ...this.state,
      helpOpen: true,
    });
  };

  handleChange = (event) => {
    console.log("what", event.target.name, event.target.value);
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  handleLegendClose = () => {
    this.setState({
      ...this.state,
      helpOpen: false,
    });
  };

  render() {
    const { session, profile, auth, classes, leader, game_options } =
      this.props;
    const { secondaryLeader } = this.state;

    if (!this.state.imagesLoaded) {
      return <LoadingBlock />;
    }

    let title = session.active_game.name;
    //console.log("HOW?", game_options);
    if (game_options !== false) {
      title = game_options.game_texts.game_title;
    }

    return (
      <div><Typography variant="h3" className={classes.spaceBottom}>
          {title}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <img src={this.state.logoURLs['main'] !== undefined ? this.state.logoURLs['main'] : logo} className={classes.imageStyle} alt="Game" />

            <div className={classes.spacingTop}>
              <div style={{fontSize: "10px", fontStyle: "italic"}}>Adapted from Seeing the Point Dr. Jim Cain</div>
              <br />
              <br />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              className={classes.spaceBottom}
              dangerouslySetInnerHTML={{
                __html: game_options.game_texts.intro_text,
              }}
            ></Typography>

           
            <Typography variant="body1" className={classes.spacingBottom}>
            In this activity you will need to stretch your thinking style to find the solution. The problem solution may come quickly or may seem insurmountable. There are 7 pieces to create five arrowhead shapes. All arrowheads must be the exact same size and shape. One arrowhead is completed and provides the size template.
            </Typography>

           
            {hasGameControl(leader, auth.uid, profile) ? (
              
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={this.sendToNextStep}
                >
                  Continue
                </Button>              
            ) : (
              <Button type="submit" disabled={true} variant="contained">
                Leader can hit Continue
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) =>
      dispatch(updateGroupState(group, groupState, hostID)),
      editResultsPath: (path, value, hostID) =>
      dispatch(editResultsPath(path, value, hostID))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Instructions);
