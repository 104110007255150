import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: "1.1rem",    
    },
    helpText: {
        fontWeight: "bold",
        paddingTop: "30px",
        fontSize: "1.2rem"
    },
    spacingTop: { paddingTop: "30px" },
    spacingBottom: { paddingBottom: "30px" },
    lister: { listStyleType: "disc" },
    list: { listStyleType: "disc", paddingLeft: "1rem" }
}));

export default function ActivityHelpPlus(props) {
    const classes = useStyles();  
    const [open, setOpen] = useState(false);
   
    return (
        <div className={classes.root}>
            <HelpOutlineIcon onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Transition} maxWidth="sm" fullWidth>
                <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.boldedText}>
                    Help - Plus
                    </Typography>                    
                </Toolbar>
                </AppBar>
                <DialogContent>
                <Grid container spacing={2} className={classes.spacingBottom}>
                    <Grid item xs={12} >
                        <div className={classes.helpText} dangerouslySetInnerHTML={{ __html : props.game_options.game_texts.plus_help }}></div>
                    </Grid>
                </Grid>
                </DialogContent>                 
            </Dialog>
        </div>
    );
}