import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GAME_STATE_RESULTS, HOST_STATE_MAIN, 
    HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS } from '../../../store/actions/gameActions';
import { getUserGroup } from './game_functions/functions';
import AwaitingGroup from '../../AwaitingGroup'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GroupStatus from './game_components/groupStatus';
import LeaderControls from './game_components/leaderControls';
import Button from '@material-ui/core/Button'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { updateGameState } from './../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import Instructions from './trustSpectrum/phases/instructions';
import Activity from './trustSpectrum/phases/activity';

import Results from './trustSpectrum/phases/results';
import GameResults from './trustSpectrum/phases/gameResults';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import HostInstructions from './trustSpectrum/specific_components/hostInstructions';
import styles from '../../Styles';

const INITIAL_STATE = {
  dialogueOpen: false
}

class TrustSpectrum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    }

   
    render(){
        const { results, profiles, isPDFView } = this.props;

       
        return <GameResults
            isPDFView={isPDFView}            
            results={results}
            profiles={profiles}
        />;
       
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TrustSpectrum);