import React, { useState, CSSProperties } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
    TableBody, TableCell, Collapse, TableHead, TableRow, Box, } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import CSVReader from './file_reader';

export default function AddOrEditPlayer (props) {
    //console.log('player?', props.player);
    const [formFilledOut, setFormFilledOut] = useState(true);
    const [name, setName] = useState(props.player !== false ? props.player.name : '');
    const [email, setEmail] = useState(props.player !== false ? props.player.email : '');

    const saveAddOrEdit = () => {
        props.saveAddOrEdit(name, email);
        
    }
    const closeAddOrEdit = () => {
        props.closeAddOrEdit();
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }
    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    React.useEffect(() => {
        if ( name === '' || email === '' ){
            setFormFilledOut(false);
        } else {
            if ( formFilledOut === false ){
                setFormFilledOut(true);
            }
        }
    }, [name, email]);

    React.useEffect(() => {
        if ( props.showAddOrEdit === false ){
            setName('');
            setEmail('');
        } else {
            if ( props.player !== false ){
                setName(props.player.name);
                setEmail(props.player.email);
            }
        }
    },[props.showAddOrEdit])
   
    return (
        <Dialog aria-labelledby='add-planned-session' aria-describedby='Add Planned Session'  maxWidth='md'  open={props.showAddOrEdit} fullWidth>
                        
                <AppBar position="static" className={props.classes.LNNoShadow}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={()=>props.closeAddOrEdit()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                            <Typography variant="h6">
                                { props.mode === 'add' ? `Add a Player` : `Edit a Player` }
                            </Typography>
                    </Toolbar>
                </AppBar>
            <DialogContent>

                
            <form className={props.classes.container} style={{display: 'flex', flexWrap: 'wrap', paddingTop: 30}} noValidate>                   
            <div style={{width: '100%'}}><TextField id="outlined-basic" label="Player Name" placeholder={`Player Name`} value={name} variant="outlined" onChange={onChangeName} style={{marginBottom: "20px", width: 400}}/></div>
            <div style={{width: '100%'}}><TextField id="outlined-basic" label="Player Email" placeholder={`Player Email`} value={email} variant="outlined" onChange={onChangeEmail} style={{marginBottom: "20px", width: 400}}/></div>
            </form>


                
            </DialogContent> 
            <DialogActions>
                <Button size='large' onClick={()=>props.closeAddOrEdit()}>
                    Cancel
                </Button>
                <Button size='large' disabled={!formFilledOut} onClick={()=>saveAddOrEdit()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
    
}