import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GamePiece from './gamePiece';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#333",
      color: theme.palette.common.white,
      borderRadius: 0,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
const valParser = (val) => {
  if ( val === undefined ){
    return '';
  } else {
    return val;
  }
}

const arraysMatch = (arr1, arr2) => {
	// Check if the arrays are the same length
	if (arr1.length !== arr2.length) return false;

	// Check if all items exist and are in the same order
	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}

	// Otherwise, return true
	return true;

};


export const PointCalculation = (dbRes, vsKey) => {
  let rows = [1,2,3,4];
  let cols = [1,2,3,4];

  const res = {
    '1x1' : '1x1',
    '1x2' : '1x2',
    '1x3' : '1x3',
    '1x4' : '1x4',
    '2x1' : '2x1',
    '2x2' : '2x2',
    '2x3' : '2x3',
    '2x4' : '2x4',
    '3x1' : '3x1',
    '3x2' : '3x2',
    '3x3' : '3x3',
    '3x4' : '3x4',
    '4x1' : '4x1',
    '4x2' : '4x2',
    '4x3' : '4x3',
    '4x4' : '4x4',
    ...dbRes
  }

  //console.log(res);
  ////console.log("POINT CALC: ", res, vsKey);

  let groups = vsKey.split(':');
  let points = {
    [groups[0]] : 0,
    [groups[1]] : 0,
    'wild': 0
  }

  let linesCounted = [];
  let linesCountedDetailed = {
    [groups[0]] : [],
    [groups[1]] : [],
    'wild' : [],
  };

  let g1Valid = [groups[0], `wild-${groups[0]}`, `wild-${groups[1]}`];
  let g2Valid = [groups[1], `wild-${groups[0]}`, `wild-${groups[1]}`];
  let wilds = [`wild-${groups[0]}`, `wild-${groups[1]}`];

  // vertical 
  rows.forEach((row, ind) => {

    let rInd = [];
    let line = [];
    cols.forEach((col, index) => {
      let id = `${row}x${col}`;
      rInd.push(res[id]);
      line.push(id);
    });
    
    
    // test for a 4
    let allWild = true; 
    let g1v = 0;
    let g2v = 0;
    rInd.forEach((r, i) => {
      if ( wilds.indexOf(r) < 0 ){
        allWild = false;
      }

      if ( g1Valid.indexOf(r) >= 0 ){
        g1v += 1;
      }
      if ( g2Valid.indexOf(r) >= 0 ){
        g2v += 1;
      }

    });
    //console.log('Row', row, g1v, g2v);

    if ( allWild ){
      points['wild'] += 20;
      linesCounted.push(line);
      linesCountedDetailed['wild'].push(line);
    } else {

      if ( g1v === 4 || g2v === 4 ){
        if ( g1v === 4 ){
          points[groups[0]] += 20;
          linesCountedDetailed[groups[0]].push(line);
        } else {
          points[groups[1]] += 20;
          linesCountedDetailed[groups[1]].push(line);
        }
        linesCounted.push(line);
      
      } else if ( g1v === 3 || g2v === 3 ){
        //console.log(row, g1v, g2v);
        // figure out which
        let l = [];
        let theLine = [];
        if ( g1v === 3 ){
          l = [res[`${row}x${1}`], res[`${row}x${2}`], res[`${row}x${3}`]];
          theLine = [`${row}x${1}`, `${row}x${2}`, `${row}x${3}`];
          if ( g1Valid.indexOf(l[0]) >= 0 && g1Valid.indexOf(l[1]) >= 0 && g1Valid.indexOf(l[2]) >= 0 ){
            points[groups[0]] += 10;
            linesCounted.push(theLine);
            linesCountedDetailed[groups[0]].push(theLine);
          }
          l = [res[`${row}x${2}`], res[`${row}x${3}`], res[`${row}x${4}`]];
          theLine = [`${row}x${2}`, `${row}x${3}`, `${row}x${4}`];
          if ( g1Valid.indexOf(l[0]) >= 0 && g1Valid.indexOf(l[1]) >= 0 && g1Valid.indexOf(l[2]) >= 0 ){
            points[groups[0]] += 10;
            linesCounted.push(theLine);
            linesCountedDetailed[groups[0]].push(theLine);
          }
        }

        if ( g2v === 3 ){
          l = [res[`${row}x${1}`], res[`${row}x${2}`], res[`${row}x${3}`]];
          theLine = [`${row}x${1}`, `${row}x${2}`, `${row}x${3}`];
          if ( g2Valid.indexOf(l[0]) >= 0 && g2Valid.indexOf(l[1]) >= 0 && g2Valid.indexOf(l[2]) >= 0 ){
            points[groups[1]] += 10;
            linesCounted.push(theLine);
            linesCountedDetailed[groups[1]].push(theLine);
          }
          l = [res[`${row}x${2}`], res[`${row}x${3}`], res[`${row}x${4}`]];
          theLine = [`${row}x${2}`, `${row}x${3}`, `${row}x${4}`];
          if ( g2Valid.indexOf(l[0]) >= 0 && g2Valid.indexOf(l[1]) >= 0 && g2Valid.indexOf(l[2]) >= 0 ){
            points[groups[1]] += 10;
            linesCounted.push(theLine);
            linesCountedDetailed[groups[1]].push(theLine);
          }
        }
        

      }

    }


    // test for 3s

  });


  // vertical 
  cols.forEach((col, ind) => {

    let rInd = [];
    let line = [];
    rows.forEach((row, index) => {
      let id = `${row}x${col}`;
      rInd.push(res[id]);
      line.push(id);
    });
    
    
    // test for a 4
    let allWild = true; 
    let g1v = 0;
    let g2v = 0;
    rInd.forEach((r, i) => {
      if ( wilds.indexOf(r) < 0 ){
        allWild = false;
      }

      if ( g1Valid.indexOf(r) >= 0 ){
        g1v += 1;
      }
      if ( g2Valid.indexOf(r) >= 0 ){
        g2v += 1;
      }

    });
    //console.log('Col', col, g1v, g2v);

    if ( allWild ){
      points['wild'] += 20;
      linesCounted.push(line);
      linesCountedDetailed['wild'].push(line);
    } else {

      if ( g1v === 4 || g2v === 4 ){
        if ( g1v === 4 ){
          points[groups[0]] += 20;
          linesCountedDetailed[groups[0]].push(line);
        } else {
          points[groups[1]] += 20;
          linesCountedDetailed[groups[1]].push(line);
        }
        linesCounted.push(line);
      
      } else if ( g1v === 3 || g2v === 3 ){
        //console.log(row, g1v, g2v);
        // figure out which
        let l = [];
        let theLine = [];
        if ( g1v === 3 ){
          l = [res[`${1}x${col}`], res[`${2}x${col}`], res[`${3}x${col}`]];
          theLine = [`${1}x${col}`, `${2}x${col}`, `${3}x${col}`];
          if ( g1Valid.indexOf(l[0]) >= 0 && g1Valid.indexOf(l[1]) >= 0 && g1Valid.indexOf(l[2]) >= 0 ){
            points[groups[0]] += 10;
            linesCounted.push(theLine);
            linesCountedDetailed[groups[0]].push(theLine);
          }
          l = [res[`${2}x${col}`], res[`${3}x${col}`], res[`${4}x${col}`]];
          theLine = [`${2}x${col}`, `${3}x${col}`, `${4}x${col}`];
          if ( g1Valid.indexOf(l[0]) >= 0 && g1Valid.indexOf(l[1]) >= 0 && g1Valid.indexOf(l[2]) >= 0 ){
            points[groups[0]] += 10;
            linesCounted.push(theLine);
            linesCountedDetailed[groups[0]].push(theLine);
          }
        }

        if ( g2v === 3 ){
          l = [res[`${1}x${col}`], res[`${2}x${col}`], res[`${3}x${col}`]];
          theLine = [`${1}x${col}`, `${2}x${col}`, `${3}x${col}`];
          if ( g2Valid.indexOf(l[0]) >= 0 && g2Valid.indexOf(l[1]) >= 0 && g2Valid.indexOf(l[2]) >= 0 ){
            points[groups[1]] += 10;
            linesCounted.push(theLine);
            linesCountedDetailed[groups[1]].push(theLine);
          }
          l = [res[`${2}x${col}`], res[`${3}x${col}`], res[`${4}x${col}`]];
          theLine = [`${2}x${col}`, `${3}x${col}`, `${4}x${col}`];
          if ( g2Valid.indexOf(l[0]) >= 0 && g2Valid.indexOf(l[1]) >= 0 && g2Valid.indexOf(l[2]) >= 0 ){
            points[groups[1]] += 10;
            linesCounted.push(theLine);
            linesCountedDetailed[groups[1]].push(theLine);
          }
        }
        

      }

    }


    // test for 3s

  });

  

  // Diagonal

  // Lines are: (1x1, 2x2, 3x3, 4x4), (4x1, 3x2, 2x3, 1x4), (2x1, 3x2, 4x3), (1x2, 2x3, 3x4), (3x1, 2x2, 1x3), (4x2, 3x3, 2x4)
  let l = [res[`${1}x${1}`], res[`${2}x${2}`], res[`${3}x${3}`], res[`${4}x${4}`]];
  let theLine = [`${1}x${1}`, `${2}x${2}`, `${3}x${3}`, `${4}x${4}`];
  let allWild = true;
  let g1v = 0;
  let g2v = 0;
  l.forEach((r, i) => {
    if ( wilds.indexOf(r) < 0 ){
      allWild = false;
    }
    if ( g1Valid.indexOf(r) >= 0 ){
      g1v += 1;
    }
    if ( g2Valid.indexOf(r) >= 0 ){
      g2v += 1;
    }
  });
  if ( allWild ){
    points['wild'] += 20;
    linesCounted.push(theLine);
    linesCountedDetailed['wild'].push(theLine);
  } else {

    if ( g1v === 4 || g2v === 4 ){
      if ( g1v === 4 ){
        points[groups[0]] += 20;
        linesCountedDetailed[groups[0]].push(theLine);
      } else {
        points[groups[1]] += 20;
        linesCountedDetailed[groups[1]].push(theLine);
      }
      linesCounted.push(theLine);
    
    } else if ( g1v === 3 || g2v === 3 ){
      //console.log(row, g1v, g2v);
      // figure out which
      let l = [];
      let theLine = [];
      if ( g1v === 3 ){
        l = [res[`${1}x${1}`], res[`${2}x${2}`], res[`${3}x${3}`]];
        theLine = [`${1}x${3}`, `${2}x${3}`, `${3}x${3}`];
        if ( g1Valid.indexOf(l[0]) >= 0 && g1Valid.indexOf(l[1]) >= 0 && g1Valid.indexOf(l[2]) >= 0 ){
          points[groups[0]] += 10;
          linesCounted.push(theLine);
          linesCountedDetailed[groups[0]].push(theLine);
        }
        l = [res[`${2}x${2}`], res[`${3}x${3}`], res[`${4}x${4}`]];
        theLine = [`${2}x${2}`, `${3}x${3}`, `${4}x${4}`];
        if ( g1Valid.indexOf(l[0]) >= 0 && g1Valid.indexOf(l[1]) >= 0 && g1Valid.indexOf(l[2]) >= 0 ){
          points[groups[0]] += 10;
          linesCounted.push(theLine);
          linesCountedDetailed[groups[0]].push(theLine);
        }
      }

      if ( g2v === 3 ){
        l = [res[`${1}x${1}`], res[`${2}x${2}`], res[`${3}x${3}`]];
        theLine = [`${1}x${1}`, `${2}x${2}`, `${3}x${3}`];
        if ( g2Valid.indexOf(l[0]) >= 0 && g2Valid.indexOf(l[1]) >= 0 && g2Valid.indexOf(l[2]) >= 0 ){
          points[groups[1]] += 10;
          linesCounted.push(theLine);
          linesCountedDetailed[groups[1]].push(theLine);
        }
        l = [res[`${2}x${2}`], res[`${3}x${3}`], res[`${4}x${4}`]];
        theLine = [`${2}x${2}`, `${3}x${3}`, `${4}x${4}`];
        if ( g2Valid.indexOf(l[0]) >= 0 && g2Valid.indexOf(l[1]) >= 0 && g2Valid.indexOf(l[2]) >= 0 ){
          points[groups[1]] += 10;
          linesCounted.push(theLine);
          linesCountedDetailed[groups[1]].push(theLine);
        }
      }
      

    }

  }



  l = [res[`${4}x${1}`], res[`${3}x${2}`], res[`${2}x${3}`], res[`${1}x${4}`]];
  theLine = [`${4}x${1}`, `${3}x${2}`, `${2}x${3}`, `${1}x${4}`];
  allWild = true;
  g1v = 0;
  g2v = 0;
  l.forEach((r, i) => {
    if ( wilds.indexOf(r) < 0 ){
      allWild = false;
    }
    if ( g1Valid.indexOf(r) >= 0 ){
      g1v += 1;
    }
    if ( g2Valid.indexOf(r) >= 0 ){
      g2v += 1;
    }
  });
  if ( allWild ){
    points['wild'] += 20;
    linesCounted.push(theLine);
    linesCountedDetailed['wild'].push(theLine);
  } else {

    if ( g1v === 4 || g2v === 4 ){
      if ( g1v === 4 ){
        points[groups[0]] += 20;
        linesCountedDetailed[groups[0]].push(theLine);
      } else {
        points[groups[1]] += 20;
        linesCountedDetailed[groups[1]].push(theLine);
      }
      linesCounted.push(theLine);
    
    } else if ( g1v === 3 || g2v === 3 ){
      //console.log(row, g1v, g2v);
      // figure out which
      l = [];
      theLine = [];
      if ( g1v === 3 ){
        l = [res[`${4}x${1}`], res[`${3}x${2}`], res[`${2}x${3}`]];
        theLine = [`${4}x${3}`, `${3}x${3}`, `${2}x${3}`];
        if ( g1Valid.indexOf(l[0]) >= 0 && g1Valid.indexOf(l[1]) >= 0 && g1Valid.indexOf(l[2]) >= 0 ){
          points[groups[0]] += 10;
          linesCounted.push(theLine);
          linesCountedDetailed[groups[0]].push(theLine);
        }
        l = [res[`${3}x${2}`], res[`${2}x${3}`], res[`${1}x${4}`]];
        theLine = [`${3}x${2}`, `${2}x${3}`, `${1}x${4}`];
        if ( g1Valid.indexOf(l[0]) >= 0 && g1Valid.indexOf(l[1]) >= 0 && g1Valid.indexOf(l[2]) >= 0 ){
          points[groups[0]] += 10;
          linesCounted.push(theLine);
          linesCountedDetailed[groups[0]].push(theLine);
        }
      }

      if ( g2v === 3 ){
        l = [res[`${4}x${1}`], res[`${3}x${2}`], res[`${2}x${3}`]];
        theLine = [`${4}x${1}`, `${3}x${2}`, `${2}x${3}`];
        if ( g2Valid.indexOf(l[0]) >= 0 && g2Valid.indexOf(l[1]) >= 0 && g2Valid.indexOf(l[2]) >= 0 ){
          points[groups[1]] += 10;
          linesCounted.push(theLine);
          linesCountedDetailed[groups[1]].push(theLine);
        }
        l = [res[`${3}x${2}`], res[`${2}x${3}`], res[`${1}x${4}`]];
        theLine = [`${3}x${2}`, `${2}x${3}`, `${1}x${4}`];
        if ( g2Valid.indexOf(l[0]) >= 0 && g2Valid.indexOf(l[1]) >= 0 && g2Valid.indexOf(l[2]) >= 0 ){
          points[groups[1]] += 10;
          linesCounted.push(theLine);
          linesCountedDetailed[groups[1]].push(theLine);
        }
      }
      

    }

  }




  // (2x1, 3x2, 4x3)
  l = [res[`${2}x${1}`], res[`${3}x${2}`], res[`${4}x${3}`]];
  theLine = [`${2}x${1}`, `${3}x${2}`, `${4}x${3}`];

  g1v = 0;
  g2v = 0;
  l.forEach((r, i) => {  
    if ( g1Valid.indexOf(r) >= 0 ){
      g1v += 1;
    }
    if ( g2Valid.indexOf(r) >= 0 ){
      g2v += 1;
    }
  });
  
  if ( g1v === 3 || g2v === 3 ){
    if ( g1v === 3 ){
      points[groups[0]] += 10;
      linesCounted.push(theLine);
      linesCountedDetailed[groups[0]].push(theLine);
    }
    if ( g2v === 3 ){
      points[groups[1]] += 10;
      linesCounted.push(theLine);
      linesCountedDetailed[groups[1]].push(theLine);
    }

  }

  // (1x2, 2x3, 3x4), 
  l = [res[`${1}x${2}`], res[`${2}x${3}`], res[`${3}x${4}`]];
  theLine = [`${1}x${2}`, `${2}x${3}`, `${3}x${4}`];

  g1v = 0;
  g2v = 0;
  l.forEach((r, i) => {  
    if ( g1Valid.indexOf(r) >= 0 ){
      g1v += 1;
    }
    if ( g2Valid.indexOf(r) >= 0 ){
      g2v += 1;
    }
  });
  
  if ( g1v === 3 || g2v === 3 ){
    if ( g1v === 3 ){
      points[groups[0]] += 10;
      linesCounted.push(theLine);
      linesCountedDetailed[groups[0]].push(theLine);
    }
    if ( g2v === 3 ){
      points[groups[1]] += 10;
      linesCounted.push(theLine);
      linesCountedDetailed[groups[1]].push(theLine);
    }

  }
  
  
  // (3x1, 2x2, 1x3), 
  l = [res[`${3}x${1}`], res[`${2}x${2}`], res[`${1}x${3}`]];
  theLine = [`${3}x${1}`, `${2}x${2}`, `${1}x${3}`];

  g1v = 0;
  g2v = 0;
  l.forEach((r, i) => {  
    if ( g1Valid.indexOf(r) >= 0 ){
      g1v += 1;
    }
    if ( g2Valid.indexOf(r) >= 0 ){
      g2v += 1;
    }
  });
  
  if ( g1v === 3 || g2v === 3 ){
    if ( g1v === 3 ){
      points[groups[0]] += 10;
      linesCounted.push(theLine);
      linesCountedDetailed[groups[0]].push(theLine);
    }
    if ( g2v === 3 ){
      points[groups[1]] += 10;
      linesCounted.push(theLine);
      linesCountedDetailed[groups[1]].push(theLine);
    }

  }
  
  
  
  // (4x2, 3x3, 2x4)
  l = [res[`${4}x${2}`], res[`${3}x${3}`], res[`${2}x${4}`]];
  theLine = [`${4}x${2}`, `${3}x${3}`, `${2}x${4}`];

  g1v = 0;
  g2v = 0;
  l.forEach((r, i) => {  
    if ( g1Valid.indexOf(r) >= 0 ){
      g1v += 1;
    }
    if ( g2Valid.indexOf(r) >= 0 ){
      g2v += 1;
    }
  });
  
  if ( g1v === 3 || g2v === 3 ){
    if ( g1v === 3 ){
      points[groups[0]] += 10;
      linesCounted.push(theLine);
      linesCountedDetailed[groups[0]].push(theLine);
    }
    if ( g2v === 3 ){
      points[groups[1]] += 10;
      linesCounted.push(theLine);
      linesCountedDetailed[groups[1]].push(theLine);
    }

  }
  



  //console.log("DONE ------------------------- FINAL LINES: ", linesCounted, linesCountedDetailed);



  if ( points['wild'] > 0 ){    
    points[groups[0]] = points[groups[0]] + points['wild'];
    points[groups[1]] = points[groups[1]] + points['wild'];
  }



 
  points['lines'] = linesCounted;
  points['linesDetailed'] = linesCountedDetailed;
  ////console.log('Points', points);
  return points;
}


export const ComparisonTable = (props) => {

  const split = props.vsKey.split(":");
  let round = 0;
  ////console.log('whoha', props.session.active_game.resultSnapshot);
  let score = props.session.active_game.results[props.vsKey] === undefined ? { [split[0]] : 0, [split[1]]: 0} : props.session.active_game.results[props.vsKey].grid === undefined ? { [split[0]] : 0, [split[1]]: 0} : PointCalculation(props.session.active_game.results[props.vsKey].grid, props.vsKey );
  
  return (
    <div>
     
      <TableContainer component={Paper} style={{maxWidth: "400px"}}>
        <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Round</StyledTableCell>
            <StyledTableCell align="right">{props.session.active_game.groups[split[0]].title} <GamePiece type={'team1'} size="icon"/></StyledTableCell>
            <StyledTableCell align="right">{props.session.active_game.groups[split[1]].title} <GamePiece type={'team2'} size="icon"/></StyledTableCell>
            <StyledTableCell align="right">Total</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {
            props.session.active_game.resultSnapshot !== undefined ? 
              Object.keys(props.session.active_game.resultSnapshot).length > 0 ?            
                Object.keys(props.session.active_game.resultSnapshot).sort().map((roundKey, ind) => {
                  round += 1;
                  ////console.log("PAST ROUND: ", roundKey)
                  let scoreB = props.session.active_game.resultSnapshot[roundKey][props.vsKey] === undefined ? { [split[0]] : 0, [split[1]]: 0} : props.session.active_game.resultSnapshot[roundKey][props.vsKey].grid === undefined ? { [split[0]] : 0, [split[1]]: 0} : PointCalculation(props.session.active_game.resultSnapshot[roundKey][props.vsKey].grid, props.vsKey );
                  //let scoreB = PointCalculation(props.session.active_game.resultSnapshot[roundKey][props.vsKey].grid, props.vsKey );
                  return (
                  <StyledTableRow key={ind}>
                    <StyledTableCell component="th" scope="row">
                      {round}
                    </StyledTableCell>
                    <StyledTableCell align="right">{scoreB[split[0]]}</StyledTableCell>
                    <StyledTableCell align="right">{scoreB[split[1]]}</StyledTableCell>
                    <StyledTableCell align="right">{scoreB[split[0]] + scoreB[split[1]]}</StyledTableCell>
                  </StyledTableRow>
                  )
                })
                : null
            : null
          }
 
             
 
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      {round+1}
                    </StyledTableCell>
                    <StyledTableCell align="right">{score[split[0]]}</StyledTableCell>
                    <StyledTableCell align="right">{score[split[1]]}</StyledTableCell>
                    <StyledTableCell align="right">{score[split[0]] + score[split[1]]}</StyledTableCell>
                  </StyledTableRow>
                
              
          
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
