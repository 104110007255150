import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import ActivityListPlus from '../specific_components/activityListPlus';
import ActivityListDelta from '../specific_components/activityListDelta';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import { QUESTIONS } from './../data/data';
import showFeedDiscIcon from '../images/goldf.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
  import { PDFHeader } from '../../../../PastData/PDFHeader';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }


    render() {
    const {
      results,
      classes,
      auth,
      profile,
      isPDFView,
    } = this.props;

    const PLUS = "plus";

    if( this.props.isPDFView === true ){
        return (
          <Document>
               <Page wrap style={{padding: "30 0"}}>
                  <PDFHeader/>
                  <View>
                    <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={showFeedDiscIcon}/>
                    {Object.keys(results.results).sort().map((groupKey, ind) => {
                    return (
                        <React.Fragment key={ind}>
                            <View style={{marginHorizontal: "50", marginBottom: "50"}}>
                                <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>                                    
                                <View style={{marginVertical: "5", flexDirection: 'column'}} wrap={false}>
                                    <Text style={{ fontWeight: 'bold', fontSize:'12pt', marginVertical: '5'}}>QUESTION:</Text>
                                    <Text style={{ fontSize:'11pt', marginBottom:'10'}}>{QUESTIONS[groupKey]}</Text>                                                
                                    <View style={{marginTop: "5", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(103, 58, 183)"}}>
                                        <View style={{ textAlign: 'center'}}>
                                            <Text style={{ fontSize: '12pt', marginVertical: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>Answers</Text> 
                                        </View>
                                    </View>
                                    <View style={{ textAlign: 'left', flexDirection: 'column', border: "1pt solid #D3D3D3"}}>
                                        { results.results[groupKey][PLUS] ?
                                            Object.keys(results.results[groupKey][PLUS]).map((item, key)=>{
                                                return(
                                                    <Text key={key} style={{ fontSize: '8pt', marginVertical: '10', marginLeft: '10'}}>{results.results[groupKey][PLUS][item]}</Text>
                                                )
                                            }
                                            ):(
                                                <Text style={{ fontSize: '8pt', marginVertical: '10',  marginLeft: '10'}}> </Text>
                                            )
                                        }
                                    </View>

                                </View>
                            </View>
                        </React.Fragment>       
                    );
                  })}
                  </View>
              </Page>
          </Document> 
      ) 
        
      }

        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(results.results)
                .sort()
                .map((groupKey, ind) => {
                    return (
                    <div key={ind}>
                        <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{results.groups[groupKey].title}</Typography>
                        <Typography variant="body1" style={{fontWeight:"bold"}}>QUESTION:</Typography>
                        <Typography variant="body1" style={{fontSize: "1.5em"}} className={classes.spacingBottom}>{QUESTIONS[groupKey]}</Typography>
                        <Grid container spacing={2}>        
                            <Grid item xs={12} sm={12}>
                                <Grid item xs={12}  align="center" className={classes.tableHead}>  
                                    Answers
                                </Grid>
                                <Grid item xs={12}  align="center" className={classes.tableBody}>
                                    <ActivityListPlus results={results} profile={profile} auth={auth} group_id={groupKey} debrief={true} hasGameControl={false} showList={true}/>
                                </Grid>
                            </Grid>
                           
                        </Grid>
                    </div>
                    );
                })}
            </Grid>
            </Grid>
        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
