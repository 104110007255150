import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CircleOfInfluenceConcern from './images/CircleOfInfluenceConcern.png'
import {
  //GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
  //HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE,
  GROUP_STATE_RESULTS,
} from '../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  hasGameControl,
  getUserGroup_hc,
  determineHost
} from '../game_functions/functions';
import GameHelp from './gameHelp';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults } from './../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import LoadingDots from './../game_components/loadingDots';
//import ActivityResults from './specific_components/activityResults';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import styles from '../../../Styles';




const INITIAL_STATE = {
  open: false,
  circle: null,
  entry_text: '',
  isEdit: false,
};
export const CIRCLE_CONCERN = 'Concern';
export const CIRCLE_INFLUENCE = 'Influence';

class Activity extends Component {
  constructor(props) {
    super(props);
    // session.active_game.results
    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate() {}

  handleClose = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  handleSave = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    this.props.updateResults(groupID, this.state.circle, this.state.entry_text, determineHost(this.props.profile, this.props.auth));
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
    });
  };

  clickConcern = (event) => {
    this.setState({
      ...this.state,
      entry_text: '',
      open: true,
      circle: CIRCLE_CONCERN,
    });

    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: CIRCLE_CONCERN,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  clickInfluence = (event) => {
    this.setState({
      ...this.state,
      entry_text: '',
      open: true,
      circle: CIRCLE_INFLUENCE,
    });

    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: CIRCLE_INFLUENCE,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  itemAction = (index, value, circle) => {
    this.setState({
      ...this.state,
      isEdit: index,
      open: true,
      circle: circle,
      entry_text: value,
    });
  };

  updateRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.circle]];
    c[row] = this.state.entry_text;

    this.props.editResults(groupID, this.state.circle, c, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  deleteRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.circle]];
    c.splice(row, 1);

    this.props.removeResults(groupID, this.state.circle, c, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  finishActivity = () => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_RESULTS,
      params: {},
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      session,
      profile,
      auth,
      classes,
    } = this.props;
    const { open, entry_text } = this.state;
    const isInvalid = entry_text === '';
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    return (
      <div>
        <Typography variant='h4' className={classes.spaceBottom}>{session.active_game.name}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='body1' className={classes.pSpaceBottom}>What are all of your work concerns? Tap on the circle of concern to add a concern.</Typography>
            <Typography variant='body1' className={classes.pSpaceBottom}>What do you have influence over? Tap on the circle of influence to add an influence.</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GameHelp />
            <div className={classes.imgContainer}>
              <img src={CircleOfInfluenceConcern} alt='instructions' className={classes.cofImageStyle} />
              { hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile) ?
                <div><div className={classes.outerRing} onClick={this.clickConcern}></div>
                <div className={classes.innerRing} onClick={this.clickInfluence}></div></div>
                : null }
            </div>
            <Dialog open={open} onClose={this.handleClose} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth>
              <DialogContent>
                <DialogContentText className={classes.bodyText}>{this.state.isEdit !== false ? `Editing ${this.state.circle}` : `What is your ${this.state.circle}? Type your entry below and hit save.`}</DialogContentText>
                <TextField multiline autoFocus autoComplete="off" margin='dense' id='entry_text' name='entry_text' value={entry_text} onChange={this.onChange} label={this.state.circle} type='email' fullWidth />
              </DialogContent>
              <DialogActions>
                {this.state.isEdit !== false ? (
                  <div>
                    <Button onClick={() => this.deleteRow(this.state.isEdit)} color='secondary'>
                      Delete
                    </Button>
                    <Button onClick={this.handleClose} color='primary'>
                      Cancel
                    </Button>
                    <Button onClick={() => this.updateRow(this.state.isEdit)} color='primary'>
                      Save
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button onClick={this.handleClose} color='primary'>
                      Cancel
                    </Button>
                    <Button disabled={isInvalid} onClick={this.handleSave} color='primary'>
                      Save
                    </Button>
                  </div>
                )}
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Circle of Concern</Typography>
                {/* Results Here */}
                <List className={classes.listClass}>
                  {session.active_game.results.hasOwnProperty(group_id)
                    ? session.active_game.results[group_id].hasOwnProperty(CIRCLE_CONCERN)
                      ? session.active_game.results[group_id][CIRCLE_CONCERN].map((item, index) => {
                          return (
                            hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile) ?
                            <ListItem key={index} button onClick={() => this.itemAction(index, item, CIRCLE_CONCERN)}>
                              <ListItemText primary={item} className={`${classes.bodyText}`} />
                            </ListItem>
                            : 
                            <ListItem key={index}>                 
                                <ListItemText
                                    className={classes.bodyText} 
                                    primary={item}                                
                                />
                            </ListItem>
                          );
                        })
                      : null
                    : null}
                    {session.active_game.groupStates[group_id].params.writing === CIRCLE_CONCERN ? <ListItem className={classes.setHeightListItem}><LoadingDots /></ListItem> : null}
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Circle of Influence</Typography>
                {/* Results Here */}
                <List className={classes.listClass}>
                  {session.active_game.results.hasOwnProperty(group_id)
                    ? session.active_game.results[group_id].hasOwnProperty(CIRCLE_INFLUENCE)
                      ? session.active_game.results[group_id][CIRCLE_INFLUENCE].map((item, index) => {
                          return (
                            hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile) ?
                            <ListItem key={index} button onClick={() => this.itemAction(index, item, CIRCLE_INFLUENCE)}>
                              <ListItemText primary={item} className={classes.bodyText}  />
                            </ListItem>
                            :
                            <ListItem key={index}>                 
                                <ListItemText
                                    className={classes.bodyText} 
                                    primary={item}                                
                                />                                            
                            </ListItem>
                          );
                        })
                      : null
                    : null}
                    {session.active_game.groupStates[group_id].params.writing === CIRCLE_INFLUENCE ? <ListItem className={classes.setHeightListItem}><LoadingDots /></ListItem> : null}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {session.active_game.results[group_id] && (auth.uid === session.active_game.groups[group_id].leadPlayer || profile.role === 'host') ? (
                <div>
                  <Typography variant="body1" className={classes.pSpaceBottom}>When you're finished, use the button below to finish the activity.</Typography>
                  <Button type='submit' variant='contained' onClick={this.finishActivity}>
                    Finish Activity
                  </Button>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);