import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GROUP_STATE_RESULTS, } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { editResultsAndGroupStatePath, editResultsPath, updateGroupState } from './../../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingDots from './../../game_components/loadingDots';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import DialogBox from './dialogBox';

import { CSSTransition } from "react-transition-group";
import CreateIcon from '@material-ui/icons/Create';
import LiquidLoadingBlock from '../../../../Loading/loadingCup';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import { steps } from './../data/data';




export const withAnimation = WrappedComponent => {
  class _AnimatedComponent extends React.Component {
    state = {
      mount: this.props.mount,
      inProgress: this.props.mount && this.props.animateOnLoad,
      blockAnimation: !this.props.animateOnLoad
    };

    static getDerivedStateFromProps(props, state) {
      if (!state.blockAnimation && props.mount !== state.mount) {
        return {
          inProgress: true,
          mount: props.mount
        };
      }

      return null;
    }

    constructor(props) {
      super(props);
      this.wrapperRef = React.createRef();
    }

    componentDidMount() {
      this.setState({
        blockAnimation: false
      });
    }

    shouldComponentUpdate(nextProps, nextState) {
      return this.state.blockAnimation === nextState.blockAnimation;
    }

    onAnimationEnd = event => {
      const { target } = event;
      const { current } = this.wrapperRef;

      if (target === current) {
        this.setState({
          inProgress: false
        });
      }
    };

    render() {
      const { mount, inProgress, blockAnimation } = this.state;
      const { onMount, onUnmount, defaultClass } = this.props;
      const animationClass = mount ? onMount : onUnmount;

      return  (inProgress || mount) ? (
        <div
          ref={this.wrapperRef}
          className={`${defaultClass} ${!blockAnimation ? animationClass : ''}`}
          onAnimationEnd={this.onAnimationEnd}
        >
          <WrappedComponent {...this.props} />
        </div>
      ) : null;
    }
  };

  _AnimatedComponent.defaultProps = {
    animateOnLoad: true,
    defaultClass: ''
  };
  return _AnimatedComponent;
};


const AnimatedDialogBox = withAnimation(DialogBox);
/*

  State: 
    messageState: message | choice | subMessage

  

*/

const INITIAL_STATE = {
    changingStep: true,
    makingChoice: false,
    changingTo: null,
    ericOpen: false,
    monicaOpen: false
};


class ChooseYourOwnManager extends Component {
  constructor(props) {
      super(props);
      this.state = {
        ...INITIAL_STATE,
      };
  
  }

  componentDidMount(){
      if ( this.props.results.step !== undefined ){
        this.setState({
            ...this.state,
            changingStep: false
        });
      }
  }

  componentDidUpdate(prevProps, prevState) {
    
    /* STEP HAS CHANGED */
    if ( prevProps.step !== this.props.step && this.props.results.step !== undefined ){

        this.setState({
            ...this.state,
            changingStep: true
        });
        setTimeout(() => {
            this.setState({
                ...this.state,
                changingStep: false
            })
        },1500);
    }

    if ( prevProps.session.active_game.groupStates[this.props.group_id].params.effect !== 'choiceMade' && this.props.session.active_game.groupStates[this.props.group_id].params.effect === 'choiceMade' ){
      this.setState({
        ...this.state,
        changingStep: true
      });
    }

    if ( prevProps.results.step === undefined && this.props.results.step === 'step_001' ){
      this.setState({
        ...this.state,
        changingStep: false
    });
    }
  }

  onDone = () => {
    console.log('Done!');
  }

  tempF = () => {
      let resultPath = `active_game.results.${this.props.group_id}.step`; 
      this.props.editResultsPath(resultPath, 'step_001', this.props.host);    
      this.setState({
          ...this.state,
          changingStep: false
      })
  }

  openSubDialog = (t) => {
    let statePath = `active_game.groupStates.${this.props.group_id}.params`;
    let p = {
      ...this.props.session.active_game.groupStates[this.props.group_id].params,
      dialogOpen: t
    }
    this.props.editResultsPath(statePath, p, this.props.host);
  }

  chooseChoice = (choice, shouldDelay = true) => {
        let statePath = `active_game.groupStates.${this.props.group_id}.params`;
        this.props.editResultsPath(statePath, { effect : 'choiceMade', val : choice.proceedTo }, this.props.host);
        setTimeout(() => {
            let resultPath = `active_game.results.${this.props.group_id}.step`;          
            let historyPath = `active_game.results.${this.props.group_id}.choiceHistory`;    
            this.setState({
                ...this.state,
                changingStep: true,
                changingTo: choice
            });
            let historyLength = this.props.results.choiceHistory === undefined ? 0 : this.props.results.choiceHistory.length;
            this.props.editResultsAndGroupStatePath(resultPath, choice.proceedTo, statePath, {}, historyPath, { choice: choice.choice, proceedTo: choice.proceedTo, choiceSubText: choice.choiceSubText, text: choice.text, id: historyLength, type: choice.type === undefined ? 'normal' : choice.type }, this.props.host);
        },shouldDelay ? 1000 : 10);
  }

  triggerChoices = () => {
        let statePath = `active_game.groupStates.${this.props.group_id}.params`;
        this.props.editResultsPath(statePath, { effect : 'choices' }, this.props.host);
  }

  finishActivity = () => {
    let s = {
        state: GROUP_STATE_RESULTS,
        params: {}
    };
    this.props.updateGroupState(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));
  }

  restartActivity = () => {
    this.setState({
        ...this.state,
        changingStep: true
    });
    setTimeout(() => {
        let statePath = `active_game.groupStates.${this.props.group_id}.params`;
        let resultPath = `active_game.results.${this.props.group_id}.step`;
        let historyPath = `active_game.results.${this.props.group_id}.choiceHistory`;              
        let historyLength = this.props.session.active_game.results[this.props.group_id].choiceHistory === undefined ? 0 : this.props.session.active_game.results[this.props.group_id].choiceHistory.length;
        this.props.editResultsAndGroupStatePath(resultPath, `step_001`, statePath, {}, historyPath, { choice: `restart`, id: historyLength }, this.props.host);      
    }, 1500);
  }

  openProfile = (t) => {
    if ( t === 'eric' ){
      this.setState({
        ...this.state,
        ericOpen: true
      });
    } else {
      this.setState({
        ...this.state,
        monicaOpen: true
      });
    }
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      ericOpen: false,
      monicaOpen: false
    });
  }

  render() {
    const {
      session,
      profile,
      auth,
      classes,
      group_id,
      hasControl,
      results,
      step
    } = this.props;

    

    /* results has:
        - step
        - choiceHistory
    */

    console.log('STEP COMPARE: ', step, this.state.changingStep);

    return (
        <div style={{position: 'relative'}}>
            { Object.keys(steps).map((st, ind) => {
                //if ( step === st && this.state.changingStep !== true ){
                    return <AnimatedDialogBox 
                        key={ind} 
                        messages={[steps[st].text]}
                        image={steps[st].image} 
                        choices={steps[st].choices} 
                        choice={steps[st]}
                        step={st} 
                        storyTellerName={`Ryan Host`} 
                        onDone={this.onDone}
                        classes={classes}
                        chooseChoice={this.chooseChoice}
                        triggerChoices={this.triggerChoices}
                        groupState={session.active_game.groupStates[group_id].params}
                        finishActivity={this.finishActivity}
                        restartActivity={this.restartActivity}
                        openDialog={this.openSubDialog}
                        isDialogOpen={session.active_game.groupStates[group_id].params.dialogOpen === undefined ? false : session.active_game.groupStates[group_id].params.dialogOpen}
                        history={results.choiceHistory === undefined ? [] : results.choiceHistory}
                        hasControl={hasControl}
                        session={session}
                        profile={profile}
                        auth={auth}
                        group_id={group_id}

                        mount={step === st && this.state.changingStep !== true}
                        onMount="in"
                        onUnmount="out"
                        defaultClass="wrapper"
                        animateOnLoad={true}
                    />
                //}
                //return null;
            }) } 
            {
                this.state.changingStep === true && results.step !== undefined && this.state.makingChoice !== true ? 
                    <div style={{textAlign: 'center', top: '15vh', left: "0", position: "absolute", width: "100%"}}> <LiquidLoadingBlock/> </div>
                : null
            }
            { 
                this.state.changingStep === true && results.step === undefined ?
                  <div align="center">
                    <Typography variant="h4">Important People in the Scenario</Typography>
                    <Typography variant="body1">Click on a profile to view more information about that person.</Typography>
                    <Grid container spacing={3} className={classes.spacingTop}>
                      <Grid item xs={6} align="center">
                        <div className={classes.relativeIconParent}>                           
                            <Avatar alt="Remy Sharp" src={require(`./../images/avatar/eric.png`)}  onClick={() => this.openProfile('eric')} className={classes.profileAvatar} style={{cursor: 'pointer'}}/>
                        </div>
                        <Typography variant="body1" className={`${classes.profileTypeDescriptor} ${classes.minorMargin}`}>
                            Eric
                        </Typography>   
                      </Grid>
                      <Grid item xs={6}  align="center">
                      <div className={classes.relativeIconParent}>                           
                            <Avatar alt="Remy Sharp" src={require(`./../images/avatar/monica.png`)}  onClick={() => this.openProfile('monica')} className={classes.profileAvatar} style={{cursor: 'pointer'}}/>
                        </div>
                        <Typography variant="body1" className={`${classes.profileTypeDescriptor} ${classes.minorMargin}`}>
                            Monica
                        </Typography> 
                      </Grid>
                    </Grid>
                    <Typography variant="body1" className={classes.spacingTop}>When you are ready, click the button below to begin.</Typography>
                    <Button style={{marginTop: "20px"}} onClick={this.tempF} variant="contained" color="primary" disabled={!hasControl}>Begin the Scenario</Button>




                    <Dialog aria-labelledby="customized-dialog-title" open={this.state.ericOpen} onClose={this.handleClose} >
                      <DialogTitle id="customized-dialog-title" onClose={this.handleClose} style={{background: "#673ab7"}} >
                        <span className={classes.inlineProfileDescriptor} style={{fontWeight:"bold", color: "#fff", fontSize: "1.3rem"}}>Eric</span> 
                      </DialogTitle>
                      <DialogContent dividers className={classes.noPad} > 
                          <Grid container className={classes.alignVerticalCenter} style={{alignItems: 'stretch'}}  >
                              <Grid item sm={4} xs={12} align="center" style={{backgroundImage: `url(${require(`./../images/avatar/eric_full.png`)})`, backgroundSize: 'cover', minHeight: '350px', backgroundPosition: 'center'}}>
                              </Grid>
                              <Grid item sm={8} xs={12} className={classes.padMe}>
                                  <Typography variant="body1">
                                    A great friend since high school. Every winter you go on a fishing trip and every Friday night you get together at the pub for Trivia night. Together you started on the line several years ago.
                                  </Typography>
                              </Grid>                           
                          </Grid>
                      </DialogContent>                      
                  </Dialog>


                  <Dialog aria-labelledby="customized-dialog-title" open={this.state.monicaOpen} onClose={this.handleClose} >
                      <DialogTitle id="customized-dialog-title" onClose={this.handleClose} style={{background: "#673ab7"}} >
                        <span className={classes.inlineProfileDescriptor} style={{fontWeight:"bold", color: "#fff", fontSize: "1.3rem"}}>Monica</span> 
                      </DialogTitle>
                      <DialogContent dividers className={classes.noPad}> 
                          <Grid container className={classes.alignVerticalCenter} style={{alignItems: 'stretch'}}>
                              <Grid item sm={4} xs={12} align="center" style={{backgroundImage: `url(${require(`./../images/avatar/monica_full.png`)})`, backgroundSize: 'cover', minHeight: '350px', backgroundPosition: 'center'}}>
                              </Grid>
                              <Grid item sm={8} xs={12} className={classes.padMe}>
                                  <Typography variant="body1">
                                    Monica you met through your kids about five years ago at their school. The kids became great friends and your families got to know each other and started having summer barbeques together. 
                                  </Typography>
                              </Grid>                           
                          </Grid>
                      </DialogContent>                      
                    </Dialog>
                  </div>
                : null
            }

        
        </div>      
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    editResultsPath: (group, top_level, value, hostID) => dispatch(editResultsPath(group, top_level, value, hostID)),
    editResultsAndGroupStatePath: (resultsPath, resultsValue, statePath, stateValue, historyPath, historyValue, hostID) => dispatch(editResultsAndGroupStatePath(resultsPath, resultsValue, statePath, stateValue, historyPath, historyValue, hostID))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ChooseYourOwnManager);