import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { moveBackToResultsPhase, moveBackToActiveWithParams, updateGroupStateStateVal, moveBackToInitPhaseWithOption } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { gameOptions } from './../../../gameServer';



const INITIAL_STATE = {
    dialogOpen: false,
    newPuzzleId: false,
    newPuzzleTitle: false,
    width: window.innerWidth,
    height: window.innerHeight
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    setOption = (id, title) => {
      this.setState({
          ...this.state,
          dialogOpen: true,
          newPuzzleId: id,
          newPuzzleTitle: title
      })
    }

    handleDialogClose = () => {
        this.setState({
            ...this.state,
            dialogOpen: false
        })
    }

    endGame = () => {
        this.props.moveBackToInitPhaseWithOption(this.props.session, this.props.auth.uid, 'Puzzle', this.state.newPuzzleId);
    }
    

    

    render() {
    const {
      results,
      classes,
      profile
    } = this.props;

    let g = gameOptions(results.id);
    

   
      return (
        <div>

        <Typography variant="h3">{results.name}</Typography>
        <Typography variant='h5' className={classes.spaceBottom}>
            Debrief
        </Typography>
          <div>
            <div>Puzzle Times</div>
            <TableContainer component={Paper} style={{maxWidth: "600px"}}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Group</StyledTableCell>
                    <StyledTableCell align="right">Last Round</StyledTableCell>
                    <StyledTableCell align="right">This Round</StyledTableCell>
                    <StyledTableCell align="right">Difference</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(results.results).sort().map((groupKey, ind) => {
                    let minutes = 0;
                    let secondsElapsed = 0;
                    let lastSecondsElapsed = 0;
                    let remainder = 0;
                    let timeelapsed = 0;
                    let time = "";
                    let timeMessage = "";
                    let lastMessage = "";

                    if ( results.results[groupKey].finishedIn !== undefined ){

                      /* they finished the current round */
                        secondsElapsed = results.results[groupKey].finishedIn;
                        minutes = Math.floor(secondsElapsed / 60);
                        remainder = secondsElapsed % 60;

                        time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds`;
                        timeMessage = `${time}`;
                    } else {
                        timeMessage = "Puzzle not completed.";
                    }
                    //console.log('GroupKey', groupKey,results.results.resultSnapshot[groupKey]);
                    if ( results.results[groupKey] === undefined ){
                      lastMessage = "N/A";
                      lastSecondsElapsed = 0;
                    } else {
                      if ( results.results[groupKey].finishedIn !== undefined ){
                        lastSecondsElapsed = results.results[groupKey].finishedIn;
                        minutes = Math.floor(lastSecondsElapsed / 60);
                        remainder = lastSecondsElapsed % 60;
                        time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${lastSecondsElapsed} seconds`;
                        lastMessage = `${time}`;
                      } else {
                        lastMessage = "Puzzle not completed.";
                      }
                    }
                    let diff = '';
                    if ( lastMessage === "N/A" ){
                      diff = 'N/A';
                    } else {
                      diff = (secondsElapsed - lastSecondsElapsed);
                    }
                    if ( timeMessage === 'Puzzle not completed.' || lastMessage === 'Puzzle not completed.'){
                      diff = "N/A";
                    }
                    if ( diff !== 'N/A' ){ 
                      minutes = Math.floor(Math.abs(diff) / 60);
                      remainder = lastSecondsElapsed % 60;
        
                      diff = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${lastSecondsElapsed} seconds`;
                    }

                    return (
                      <StyledTableRow key={ind}>
                        <StyledTableCell component="th" scope="row">
                          {results.groups[groupKey].title}
                        </StyledTableCell>
                        <StyledTableCell align="right">{lastMessage}</StyledTableCell>
                        <StyledTableCell align="right">{timeMessage}</StyledTableCell>
                        <StyledTableCell align="right">{diff}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
      </div>
      );    
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params)),
    moveBackToInitPhaseWithOption : (session,host,optionName,optionVal) => dispatch(moveBackToInitPhaseWithOption(session,host,optionName,optionVal))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);