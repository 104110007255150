import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
//import { GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
//    HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS } from '../../../../store/actions/gameActions';
import {
    hasGameControl,
    getUserGroup_hc,
    determineHost
  } from '../../game_functions/functions';
  import Avatar from '@material-ui/core/Avatar';
//import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams, moveBackToInitPhaseWithOption, updateResultsPath, updateGameStateParams  } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';


import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';

//import { wordGroups } from './../data/data';
import DebriefChart from './../specific_components/debriefChart.js';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import Paper from '@material-ui/core/Paper';
import ForwardIcon from '@material-ui/icons/Forward';
import { gameHasOptions, gameOptions } from './../../../gameServer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import peer2BossIcon from '../images/peertoboss.png';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  PDFDownloadLink
} from "@react-pdf/renderer";
import { PDFHeader } from './../../../../PastData/PDFHeader';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  
  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

const INITIAL_STATE = {
    open: false,    
    tab: 0,
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        

        this.state = {
          ...INITIAL_STATE,
        };
    }

    handleTabChange = (event, newValue) => {
        console.log('????', event, newValue);
        this.setState({
          ...this.state,
          tab: newValue
        })
    };

    render() {
    const {
      results,
      classes,
      isPDFView,
      profiles,
      //game_id, game_title,  profile, auth, group_state, game_state
    } = this.props;


    if(this.props.isPDFView === true){
      return (
          <Document>
               <Page wrap style={{padding: "30 0"}}>
                  <PDFHeader/>
                  <View>
                      <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={peer2BossIcon}/>
                      {Object.keys(results.results)
                      .sort()
                      .map((groupKey, ind) => {
                      return (
                      <React.Fragment key={ind}>
                          <View style={{marginHorizontal: "50", marginBottom: "50"}} wrap={false}>
                              <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>                                    
                              <View style={{marginVertical: "5", flexDirection: "row",}} wrap={false}>
                                  {/* Values View */}
                                  <View style={{ width: "49%", borderRadius: '3', border: "1pt solid #000", marginRight: "10"}}>
                                      <View style={{ flexDirection: "row", textAlign: "center", backgroundColor:"rgb(103, 58, 183)"}}>
                                          <Text style={{color: "#fff", paddingVertical: "20"}}>SITUATIONS YOU HAVE OVERCOME</Text>
                                      </View>
                                      <View style={{backgroundColor: "rgb(255, 255, 255)"}}>
                                      { 
                                          results.results[groupKey] !== undefined ?
                                              results.results[groupKey]["lc"] !== undefined ?
                                          
                                                  Object.keys(results.results[groupKey]['lc']).map((item, i) =>{
                                                      return(
                                                          <Text key={i} style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}>{results.results[groupKey]['lc'][item]}</Text>
                                                      )
                                                  })
                                          
                                              : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                          : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                      }
                                     </View>
                                  </View>
                                  {/* Stressors View */}
                                  <View style={{ width: "49%", borderRadius: '3', border: "1pt solid #000"}}>
                                      <View style={{ flexDirection: "row", textAlign: "center", backgroundColor:"rgb(103, 58, 183)"}}>
                                          <Text style={{color: "#fff", paddingVertical: "20"}}>SITUATIONS STILL CHALLENGING</Text>
                                      </View>
                                      <View style={{backgroundColor: "rgb(255, 255, 255)"}}>
                                      { 
                                          results.results[groupKey] !== undefined ?
                                              results.results[groupKey]["rc"] !== undefined ?
                                          
                                                  Object.keys(results.results[groupKey]['rc']).map((item, i) =>{
                                                      return(
                                                          <Text key={i} style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}>{results.results[groupKey]['rc'][item]}</Text>
                                                      )
                                                  })
                                          
                                              : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                          : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                      }
                                      </View>
                                  </View>
                              </View>
                          </View> 
                      </React.Fragment>       
                      );
                  })}
                  </View>
              </Page>
          </Document> 
      ) 
  }

        return (
        <div ref={this.mainDiv}>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>


                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>

          
                { /*: null */ }
            </Grid>
            <Grid item xs={12} sm={12} ref={this.gridLeft}>
                    
                        { Object.keys(results.groups).sort().map((groupKey, ind) => {
                          if ( groupKey === 'group-0' ){
                            return null
                          }
                        
                          let history = this.props.results.results[groupKey] !== undefined ?
                          this.props.results.results[groupKey].choiceHistory === undefined ? [] : this.props.results.results[groupKey].choiceHistory
                          : [];
                         return (
                          <Grid container key={ind}>
                                    <Grid item xs={12} sm={12} className={classes.spacingTop}>
                                    <Typography variant="h3" className={classes.spacingTop}>{results.groups[groupKey].title}</Typography>
                            <div className={classes.spacingTop}>
                                <DebriefChart 
                                    history={history} 
                                    onlyCurrentRun={false} 
                                    debrief={true}
                                    classes={this.props.classes} 
                                    results={results}
                                    
                                    group_id={groupKey}
                                    hasControl={false}
                                />
                                
                            </div>
                            
                          </Grid></Grid>
                         )                           
                        })}                    
       
            </Grid>
            
            </Grid>
        </div>
        );
    
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
   
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
