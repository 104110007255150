
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
  TableBody, TableCell, Collapse, TableHead, TableRow, Box, } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { signOut } from '../../store/actions/authActions';
import Footer from '../Footer';
import LogoHeader from '../Header';
import styles from '../Styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {closeAdminPopUps, renameUser,resetPasswordFromAdmin,toggleStatus,updateAvailableActivities} from '../../store/actions/adminActions';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AddUser from './addUser';
import Alert from '@material-ui/lab/Alert';
import Navigation from '../Navigation';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { accessCheck } from './../Functions';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { getGameImage } from './../GamesList';

const GameCard = withStyles((theme) => ({
  root: {
    '&:focused': {
      background: "#673ab7",
    },
  }
}))(CardContent);

const INITIAL_STATE = {
  copied: false,
  valid: true,
  nameDialog: false,
  passwordDialog: false,
  activitiesAvailable: [],
  resetPassword:'',
  newName: '',
  targetID:'',
  open: false,
  rowOpen: {

  },
  dialogOpen: false,
  curHost: false
};

export function getHost(profile, auth) {
    return profile.role === 'host' ? auth.uid : profile.sessionHost;
}

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };

  }



  checkIsLoaded = function(firestore) {
    return !Object.values(firestore.status.requesting).find(isRequesting => isRequesting);
  };

  createSession = (event) => {
    event.preventDefault();
    this.props.maybeCreateSession(this.props.profile);
  };

  endSession = (event) => {
    event.preventDefault();
    this.props.endSession();
  };

  onChange = (event) => {
    this.setState({ ...this.state,
      [event.target.name]: event.target.value });
  };

  handleClose = (event) => {
    this.setState({
      ...this.state,
      copied: false
    });
  }

  //Currently not showing players
  /* getPlayerList = () =>{
    if(this.props.current_session !== undefined && this.props.playerList !== undefined && this.props.current_session.players.length >0){
    let {name,r} = '';
    let i = 0;
    let t = '';


    t    =this.props.current_session.players[i];
    name =this.props.current_session.playerProfiles[t].displayName;
    r    =this.props.current_session.playerProfiles[t].role; 

 
    return(
      <Typography>
        INSIDE GETPLAYERLIST
      </Typography>
    )
    }
  }; */

  handleDialogClose =() =>{
    this.setState({
      ...this.state,
      nameDialog: false});
  };

  toggleDialog = (s) => {
    this.setState({
      ...this.state,
      dialogOpen: s,
      activitiesAvailable: [],
    })
  }

  handleSubmit = (usersID,newName) =>{
    this.props.renameUser(this.state.newName,this.state.targetID);
    this.handleDialogClose(); 
  };

  resetPasswordOpen=(email) =>{
    this.setState({
      ...this.state,
      passwordDialog:true,
      targetID: email});
  };

  resetPasswordClose=()=>{
    this.setState({
      ...this.state,
      passwordDialog: false,
      targetID: null
    });
  };

  resetPasswordSubmit=(email)=>{
    this.props.resetPasswordFromAdmin(email);
    this.resetPasswordClose();
  };

  toggleOpen=(uid) =>{

    //console.log(this.state.rowOpen, uid);
    if ( this.state.rowOpen[uid] === true ){
      this.setState({
        ...this.state,
        rowOpen: {
          ...this.state.rowOpen,
          [uid]: false
        }
      })
    } else {
      this.setState({
        ...this.state,
        rowOpen: {
          ...this.state.rowOpen,
          [uid]: true
        }
      })
    }

    /*if(this.state.open === true){
    this.setState({
      ...this.state,
      open: false});
    } else{
      this.setState({
        ...this.state,
        open: true});
    }*/
  };

  getTablesStart=(isAdmin)=>{
    return(
      <div>
      {/* CHANGE NAME DIALOG */}
      <Dialog aria-labelledby='rename-user-page' aria-describedby='rename user page' open={this.state.nameDialog} onClose={this.handleDialogClose}>
      <DialogTitle id='rename-user-page'>
        <Grid container spacing={2} justify='flex-start' alignItems='center'>
          <Grid item xs={10}>
            <Typography variant='h6'>Rename User.</Typography>
          </Grid>
          <Grid item sm={2} xs={1} align='right'>
            <IconButton  onClick={this.handleDialogClose} >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='new-name'>
          Please enter the new desired name for the user below.
        </DialogContentText>
        <TextField required id="new-user-name" name='newName' label='New User Name' value={this.state.newName} onChange={this.onChange} className={this.props.classes.TextField}/>
      </DialogContent>
      <DialogActions>
          <Grid item container xs={12}>
            <Grid item xs={12} />
            <Grid item xs={1} />
            <Grid item xs={4} align='left'>
              <Button  onClick={this.handleDialogClose} >CANCEL</Button>
            </Grid>
            <Grid item xs={4} align='right'>
              <Button color='primary'  onClick={()=> this.handleSubmit(this.state.targetID, this.state.newName)}  >
                SUBMIT
              </Button>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid item xs={12} />
        </DialogActions>
    </Dialog>

      {/* RESET PASSWORD DIALOG */}
    <Dialog aria-labelledby='reset-password-page' aria-describedby='reset password page' open={this.state.passwordDialog} onClose={this.resetPasswordClose}>
      <DialogTitle id='reset-password-page'>
        <Grid container spacing={2} justify='flex-start' alignItems='center'>
          <Grid item xs={10}>
            <Typography variant='h6'>Reset Password.</Typography>
          </Grid>
          <Grid item sm={2} xs={1} align='right'>
            <IconButton  onClick={this.resetPasswordClose} >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='new-password'>
          Hitting the submit button will send the user an email that they can use to reset their password. Do you wish to continue?
        </DialogContentText>
        
      </DialogContent>
      <DialogActions>
          <Grid item container xs={12}>
            <Grid item xs={12} />
            <Grid item xs={1} />
            <Grid item xs={4} align='left'>
              <Button  onClick={this.resetPasswordClose} >CANCEL</Button>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={4} align='right'>
              <Button color='primary'  onClick={()=> this.resetPasswordSubmit(this.state.targetID)}  >
                SUBMIT
              </Button>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid item xs={12} />
        </DialogActions>
    </Dialog>

      {/* USER TABLE */}
       
     </div>
    )
  };

  manageActivites = (hostObject, t) => {
    //console.log('WHAAAAT', hostObject.activitiesAvailable, hostObject);
    this.setState({
      ...this.state,
      activitiesAvailable: hostObject.activitiesAvailable === undefined ? ['ALL'] : hostObject.activitiesAvailable,      
      curHost: { ...hostObject, id: t },
      dialogOpen: true
    })

  }
  updateSelectedActivites = (gameID) => {
    //console.log(this.state.curHost);
    if ( gameID === 'ALL' ) {
      this.setState({
        ...this.state,
        activitiesAvailable: ['ALL']
      })
    } else {
      let selA = [ ...this.state.activitiesAvailable ];
      if ( selA.includes('ALL') ){
        selA.splice(selA.indexOf('ALL'),1);
      }

      if ( selA.includes(gameID) ){
        selA.splice(selA.indexOf(gameID),1);
      } else {
        selA.push(gameID);
      }
      this.setState({
        ...this.state,
        activitiesAvailable: selA
      })
    }
  }

  updateActivities = () => {
    let hostID = this.state.curHost.id;
    let data = this.state.activitiesAvailable;
    this.props.updateAvailableActivities(hostID, data);

    this.setState({
      ...this.state,
      dialogOpen: false
    })
  }


  getNewName = (usersID) =>{
    this.setState({
      ...this.state,
      nameDialog:true,
      targetID: usersID});
    
  };

  getHostList = () =>{
    const {classes,hosts,moderators } = this.props;
    //console.log('MODS: ', moderators);
    const {newName,nameDialog, open} = this.state;

    let hostsO = hosts;


    //console.log('hosts before', hostsO);
    if ( this.props.profile.role === 'host' ){
      hostsO = {
        [this.props.auth.uid] : {
          ...this.props.profile
        }
      };
    }
    //console.log('hosts after', hostsO);

    if ( hostsO === undefined || hostsO === null ){
      return null;
    }

    return(
          Object.keys(hostsO).map((t, index)=>{
            //console.log(hosts);

            let hostEnable = hostsO[t].status;
            let hButtonStatus = true;
            let mButtonStatus = true;
            if(hostsO[t].status === undefined){
              hostEnable = 'Active';
            } else {
              hostEnable = hostsO[t].status;
            }
            if(hostEnable === "Active"){
              hButtonStatus = true;
            }
            if(hostEnable === 'Disabled'){
              hButtonStatus = false;
            }
            return(
              <React.Fragment key={index}>
                <TableRow className={`${classes.root} row_${hostEnable.toLowerCase()}`}>
                  <TableCell>
                    <IconButton size='small' onClick={() => this.toggleOpen(t)}>
                      {this.state.rowOpen[t] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </TableCell>

                  <TableCell align='left'  style={{fontWeight:"bold"}}>
                    {hostsO[t].displayName}
                  </TableCell>
                  <TableCell align='left'>
                    {hostsO[t].email}
                  </TableCell>
                  <TableCell align='right'>
                    { hostsO[t].activitiesAvailable === undefined ? `All` : hostsO[t].activitiesAvailable[0] === 'ALL' ? `All` : hostsO[t].activitiesAvailable.length }
                  </TableCell>
                  <TableCell align='right'>
                    
                    {
                      this.props.profile.role === 'admin' ?
                    hButtonStatus ?(
                        <Button startIcon={<BlockIcon />} color='secondary' onClick={() => this.props.toggleStatus(t, hostEnable)} >DISABLE</Button>
                      ):(
                        // if status is Disabled
                        <Button startIcon={<CheckCircleIcon />} color='primary' onClick={()=> this.props.toggleStatus(t, hostEnable)} >ENABLE</Button>
                      )
                        : `${hostEnable}`

                    }

                  </TableCell>
                  
                    <React.Fragment>
                      <TableCell align='right'>
                        { this.props.profile.role === 'admin' ? <Button startIcon={< DynamicFeedIcon/>}  onClick={() => this.manageActivites(hostsO[t], t)} >Manage Activities</Button> : null }
                        <Button startIcon={< CreateIcon/>}  onClick={() => this.getNewName(t)} >RENAME</Button>
                        <Button startIcon={<VpnKeyIcon/>} onClick={() => this.resetPasswordOpen(hostsO[t].email) }>Reset Password</Button>
                        
                        </TableCell>
                    </React.Fragment>
                  
                  
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={this.state.rowOpen[t]} timeout="auto" unmountOnExit>
                      <Box margin={1}  style={{ padding: "20px" }}>
                        
                        <Grid container className={classes.spacingBottom}>
                          <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom component="div">
                              Moderators for {hostsO[t].displayName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} align="right">
                            <AddUser hostID={t} verbage="Moderator" moderators={moderators} isAdmin={this.props.profile.role === 'admin' ? true : false}/>
                          </Grid>

                        </Grid>
                        
                          
                            
                          
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                       
                              <TableCell align="left">Name</TableCell>
                              <TableCell align="left">Email</TableCell>
                              <TableCell align='right'>OPTIONS</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                

                          {moderators !== undefined && moderators !== null ? 
                            Object.keys(moderators).map((m,i)=>{
                              //console.log("Starting MOD: ", m);
                              let modEnable = m.status;
                              if ( moderators[m] === null ){
                                return null;
                              }
                              //console.log("Going MOD: ", m);
                         

                              if(this.props.profile.role === 'admin'){                                
                                if(!moderators[m].moderatesFor.includes(t) ){
                                  return null;
                                }
                              }

                            

                            //console.log("Continuing MOD: ", m);

                            if(moderators[m].status === undefined){
                              modEnable = 'Active';
                            } else {
                              modEnable = moderators[m].status;
                            }
                            if(modEnable === "Active"){
                              mButtonStatus = true;
                            }
                            if(modEnable === 'Disabled'){
                              mButtonStatus = false;
                            }
                            //console.log('uh', moderators[m]);
                            return(
                              <TableRow key={i} className={`row_${modEnable.toLowerCase()}`}> 
                                
                                <TableCell  align="left" style={{fontWeight:"bold"}}>
                                  {moderators[m].displayName}
                                </TableCell>
                                <TableCell align="left">{moderators[m].email}</TableCell>
                                
                                
                                  <React.Fragment>
                                    <TableCell align='right'>
                                      { mButtonStatus ?(
                                        <Button startIcon={<BlockIcon />} color='secondary'  onClick={()=>this.props.toggleStatus(m, modEnable)} >DISABLE</Button>
                                      ):(
                                        /*  if status is 'Disabled'*/
                                        <Button startIcon={<CheckCircleIcon />} color='primary'  onClick={()=>this.props.toggleStatus(m, modEnable)} >ENABLE</Button>
                                      )}
                                      <Button startIcon={< CreateIcon/>}  onClick={()=> this.getNewName(m)} >RENAME</Button> 
                                      <Button startIcon={<VpnKeyIcon/>} onClick={() => this.resetPasswordOpen(moderators[m].email) }>Reset Password</Button>   
                                    </TableCell>
                                  </React.Fragment>
                               
                              </TableRow>
                              )
                            })
                            :null  
                          }
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>        
            </React.Fragment>
          );
        })
      )
    
  };

  getNumberOfHosts = () => {
    if ( this.props.hosts === undefined || this.props.hosts === null ){
      return 0;
    }
    return Object.keys(this.props.hosts).length;
  }

  getNumberOfMods = () => {
    //console.log('asdfasdfa', this.props.moderators);
    if ( this.props.moderators === undefined || this.props.moderators === null ){
      return 0;
    }
    return Object.keys(this.props.moderators).length;
  }

  sortGames = (a,b) => { var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0; 
  }

  render() {
    const { t, players, adminErrorPopUp, classes, adminPopUp, adminError, adminMessage, games, gamesList } = this.props;
    let name = this.props.profile === undefined ? '': this.props.profile.displayName;
    let isAdmin = false;

    if ( !this.props.auth.uid ){
      return <Redirect to={ROUTES.LOGIN} />;
    }

 

    //if(this.props.profile !== undefined ){}
    if(this.props.profile.role === 'admin'){
      isAdmin = true;
    }

    if ( this.props.profile.role === 'player' ){
      return null;
    }


    //console.log('games', this.props.games);
    const allH = this.state.activitiesAvailable === undefined || this.state.activitiesAvailable[0] === 'ALL' ? '8px solid green' : '8px solid transparent';

    return(
      <div className='meta-react'>
        <Navigation profile={this.props.profile} auth={this.props.auth}/>
        <LogoHeader t={t} profile={this.props.profile} auth={this.props.auth} />
        <div className='outer-container'>
          <div className='container'>
            <Typography variant="h4">User Management</Typography>
              <br/>
              {isAdmin ? (
                <React.Fragment>

                    <Dialog aria-labelledby='update-activities' fullWidth maxWidth="lg" aria-describedby='Update Activites' open={this.state.dialogOpen} onClose={()=>this.toggleDialog(false)}>
                        <DialogTitle id='update-activites'>
                            <Grid container spacing={2} justify='flex-start' alignItems='center'>
                                <Grid item xs={10}>
                                    <Typography variant='h6'>Update Activities</Typography>
                                </Grid>
                                <Grid item sm={2} xs={1} align='right'>
                                    <IconButton  onClick={()=>this.toggleDialog(false)} >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                               Select which activites should be available for {this.state.curHost.displayName}
                            </DialogContentText>
                            <Grid container>
                              <Grid item lg={4}  className={classes.stretchMe} style={{marginBottom: "20px"}}>
                                  
                                  <Card className={classes.gameListRoot} style={{ border: allH }}  onClick={() => this.updateSelectedActivites('ALL')}>
                                    <CardActionArea>
                                      <CardMedia className={`${classes.media} ${classes.lightPurpleBG}`} image={getGameImage('ALL')} title={'ALL'} />
                                      <GameCard >
                                        <Typography gutterBottom variant='h5' component='h2' className={classes.glassPurple}>
                                          ALL Activities
                                        </Typography>                                       
                                      </GameCard>
                                    </CardActionArea>
                                    <CardActions>
                                      
                                    </CardActions>
                                  </Card>
                                </Grid>
                            {
                              gamesList !== undefined ? 
                              gamesList.slice().sort(this.sortGames).map((game, index) => {
                                const h = this.state.activitiesAvailable === undefined ? '8px solid transparent' : this.state.activitiesAvailable.includes(game.id) ? "8px solid green" : '8px solid transparent';
                                //console.log('GAME: ', game.id, game.name, this.state.activitiesAvailable, this.state.curHost, h);
                                return (
                                <Grid item lg={4} key={index} className={classes.stretchMe} style={{marginBottom: "20px"}}> 
                                  <Card className={`${classes.gameListRoot}`} style={{ border: h }} onClick={() => this.updateSelectedActivites(game.id)}>
                                    <CardActionArea>
                                      <CardMedia className={`${classes.media} ${classes.lightPurpleBG}`} image={getGameImage(game.id)} title={game.name} />
                                      <GameCard >
                                        <Typography gutterBottom variant='h5' component='h2' className={classes.glassPurple}>
                                          {game.name} {!game.available ? ' - Dev' : null}
                                        </Typography>                                       
                                      </GameCard>
                                    </CardActionArea>
                                    <CardActions>
                                      
                                    </CardActions>
                                  </Card>
                                </Grid>                             
                                )
                              })
                              : null
                            }
                            </Grid>
                            
                        </DialogContent> 
                        <DialogActions>
                            <Button size='large' onClick={()=>this.updateActivities()}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                  
                  <br/>
                  <Typography variant='body1' align='right'>Number Of Hosts: {this.getNumberOfHosts()}</Typography>
                  <Typography variant='body1' align='right'>Number Of Moderators: {this.getNumberOfMods()}</Typography>
                  <br />
                </React.Fragment>
              ):(
                <React.Fragment>
                  <Typography variant='body1' align='right'>Number Of Moderators: {this.getNumberOfMods()}</Typography>
                  <br/>
                </React.Fragment>
              )}
              {isAdmin?(
              <div style={{textAlign: "right"}}><AddUser verbage="Host" isAdmin={this.props.profile.role === 'admin' ? true : false}/></div>
              ):null
              } 

              
            <br/>
              
            { 
              ( ( this.props.profile.role === 'host' && !isLoaded(this.props.moderators ) ) || ( this.props.profile.role === 'admin' && (!isLoaded(this.props.hosts) || !isLoaded(this.props.moderators) )) )
                 ?
                <div>Loading..</div>
              :
              <TableContainer style={{marginTop:"20px"}}>
              <Table aria-label='Users table'>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align='left'>HOST NAME</TableCell>
                    <TableCell align='left'>EMAIL</TableCell>
                    <TableCell align='right'>ACTIVITIES AVAILABLE</TableCell>
                    <TableCell align='right'>ENABLE/DISABLE</TableCell>
                    <TableCell align='right'>OPTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.getHostList()}
                </TableBody>
              </Table>
            </TableContainer>
            }
    
            </div>
          </div>
          {adminErrorPopUp ?(
            <Alert severity='error' onClose={()=>{this.props.closeAdminPopUps()}}>{adminError}</Alert>
            
            ):( null)
          }
          {adminPopUp ?(
            <Alert severity='success' onClose={()=>{this.props.closeAdminPopUps()}}>{adminMessage}</Alert>
          ):(null)
          }

          { this.getTablesStart(isAdmin) }
      <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/>
    </div>
    )
    
  }
}

AdminPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    games: state.firestore.data.games,
    gamesList: state.firestore.ordered.games,
    hostInfo: state.firestore.data.hostInfo,
    hosts: state.firestore.data.hosts,
    moderators: state.firestore.data.moderators,
    adminErrorPopUp: state.admin_state.adminErrorPopUp,
    adminPopUp: state.admin_state.adminPopUp,
    adminError : state.admin_state.adminError,
    adminMessage: state.admin_state.adminMessage,
    adminLoading: state.admin_state.adminLoading,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    renameUser: (name,uid) => dispatch(renameUser(name,uid)),
    toggleStatus: (uid,status) => dispatch(toggleStatus(uid,status)),
    closeAdminPopUps: () => dispatch(closeAdminPopUps()),
    resetPasswordFromAdmin: (email)=> dispatch(resetPasswordFromAdmin(email)),
    updateAvailableActivities: (hostID, data) => dispatch(updateAvailableActivities(hostID, data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => {
    if ( props.auth.uid === undefined ){
      return [];
    }
    if ( props.profile.role === 'admin' ){
      return [
        {
          collection: 'players',
          where: [
            ['role', '==', 'host'],      
          ],
          storeAs: 'hosts'
        },
        {
          collection: 'players',
          where: [
            ['role', '==', 'moderator']        
          ],
          storeAs: 'moderators'
        },
        {
          collection: 'games'
        }
      ]
    } else {
      //console.log("hm", props.auth.uid);
      return [
        {
          collection: 'players',
          where: [
            ['role', '==', 'moderator'],    
            ['moderatesFor', 'array-contains', props.auth.uid]    
          ],
          storeAs: 'moderators'
        }
      ]
    }
    
  })
)(AdminPage);