import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from '../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ViewListIcon from '@material-ui/icons/ViewList';
import cof_active from './images/cof_active.png';
import cof_init from './images/cof_init.png';
import cof_results from './images/cof_results.png';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const INITIAL_STATE = {
    open: false,
    legendOpen: false
};

class HostInstructions extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    handleLegendClickOpen = () => {
        this.setState({
            ...this.state,
            legendOpen: true
        });
    };
    
    handleLegendClose = () => {
        this.setState({
            ...this.state,
            legendOpen: false
        });
    };

    render() {
        const {
        classes,
        } = this.props;

        return (
        <div>
            <Typography variant='h5' className={classes.greyText}>
                Host Tools
            </Typography>
            <div className={classes.spacingTop}>
                <div><Button 
                        variant="contained"
                        color="primary"
                        startIcon={<SpeakerNotesIcon />}
                        onClick={this.handleClickOpen}
                        >
                    Leader Notes
                    </Button>
                    <div className={classes.spacingTop}>
                        <Button 
                            variant="contained"
                            color="primary"
                            startIcon={<ViewListIcon />}
                            onClick={this.handleLegendClickOpen}
                            >
                        State Legend
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog  open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                        <Typography variant="h6" className={classes.boldedText}>
                        Leader Notes
                        </Typography>                    
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} className={classes.dialogueGrid}>
                    <Grid item xs={12}  className={classes.spacingTop}>
                    <Typography variant="body1" className={classes.boldedText}>Circle of Concern:</Typography>
                        <Typography variant="body1">Our health, our children, problems at work, future of the company, politics, the economy, the threat of war</Typography>
                        <br/><br/>
                        <Typography variant="body1" className={classes.boldedText}>Circle of Influence:</Typography>
                        <Typography variant="body1" >Concerns that we can do something about or have control over</Typography>
                        <br/><br/>
                        <div className={classes.bodyText}>
                        <div className={classes.boldedText}>Exercise:</div>
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>View the 2 circles</li>
                            <li className={classes.properListItem}>What are all of your work concerns – add to outside circle</li>
                            <li className={classes.properListItem}>What do you have influence over - add to inside circle</li>
                        </ul>

                        <div className={classes.boldedText}>Debrief</div>
                        SAY:<br/>
                        <ul className={classes.properList}>
                        <li className={classes.properListItem}>What happens if we are consumed with our circle of concern?</li>
                        <li className={classes.properListItem}>We have negative energy surrounding us</li>
                        <li className={classes.properListItem}>How do we act when we are stressed or worried? What can negative energy look like?</li>
                        <li className={classes.properListItem}>Albert Einstein’s formula E = MC2.</li>
                        <li className={classes.properListItem}>What did we learn about EI? = we “catch” emotions</li>
                        <li className={classes.properListItem}>When we focus on what we can’t influence, our circle of influence shrinks</li>
                        <li className={classes.properListItem}>When we focus on what can influence, our circle of concern shrinks</li>
                        <li className={classes.properListItem}>limit what you can’t influence; focus on what you can influence</li>
                        <li className={classes.properListItem}>Covey notes that highly effective people think and act primarily within their Circle of Influence.</li>
                        </ul>

                        <div className={classes.boldedText}>Exercise:</div>
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>Review your circle of concern</li>
                            <li className={classes.properListItem}>What can you influence?</li>
                            <li className={classes.properListItem}>Expand your circle of influence</li>
                        </ul>
                        <div className={`${classes.boldedText} ${classes.spacingBottom}`}>Focus on things you can control and don’t waste energy on the things you cannot.</div>    
                        </div>                     
                    </Grid>
                </Grid>              
            </Dialog>
            <Dialog  open={this.state.legendOpen} onClose={this.handleLegendClose} TransitionComponent={Transition}>
                <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={this.handleLegendClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                        <Typography variant="h6" className={classes.boldedText}>
                        State Legend
                        </Typography>                    
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} className={classes.dialogueGrid}>
                    <Grid item xs={12}  className={classes.spacingTop}>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Init</Typography>
                        <div>
                            <img src={cof_init} alt='circle of influence intro' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Active</Typography>
                        <div>
                            <img src={cof_active} alt='circle of influence instructional' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                        <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>Results</Typography>
                        <div>
                            <img src={cof_results} alt='wrapping up and waiting for others' className={`${classes.imageSize} ${classes.borderImage}`}/>
                        </div>
                    </Grid>
                </Grid>                
            </Dialog>
        </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(HostInstructions);