import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import {
  moveBackToResultsPhase,
  moveBackToActiveWithParams,
  moveBackToInitPhaseWithOption,
  updateResultsPath,
  updateGameStateParams,
} from "../../../../../store/actions/gameActions";
import Typography from "@material-ui/core/Typography";
import styles from "../../../../Styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import WordPlayground from "./../specific_components/wordPlayground.js";
import Paper from "@material-ui/core/Paper";
import { gameOptions } from "./../../../gameServer";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function LinkTab(props) {
  return (
    <Tab
      component="a"
      
      {...props}
    />
  );
}

const INITIAL_STATE = {
  open: false,
  dialogOpen: false,
  tab: 0,
  containerWidth: null,
  gridLeftWidth: null,
  isInterfaceLoaded: false,
  newPuzzleId: null,
  newPuzzleTitle: null,
  newPuzzleDifficulty: null,
  curGroup: null,
  // dialogOpen: false,
  questionText: "",
};

class GameResults extends Component {
  constructor(props) {
    super(props);

    this.gridLeft = React.createRef();
    this.mainDiv = React.createRef();

    this.state = {
      ...INITIAL_STATE,
    };
  }

  returnToResults = () => {
    this.props.moveBackToResultsPhase(this.props.session, this.props.auth.uid);
  };

  turnOnQuestions = () => {
    let s = {
      questions: true,
    };
    this.props.updateGameStateParams(s, this.props.auth.uid);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    //console.log("Do WE HAVE STUFF?", this.props.session.active_game.results, this.props.session.active_game.resultSnapshot);
    //console.log('THE DIFF:', deepDiffMapper.map({}, this.props.session.active_game.results));
    //console.log('THE DIFF:', deepDiffMapper.map(this.props.session.active_game.resultSnapshot === undefined ? {} : this.props.session.active_game.resultSnapshot, this.props.session.active_game.results));
    if (!this.state.isInterfaceLoaded) {
      if (this.mainDiv !== undefined) {
        console.log("hERE!", this.mainDiv);
        this.setState({
          ...this.state,
          isInterfaceLoaded: true,
          containerWidth: this.mainDiv.current.offsetWidth,
          gridLeftWidth: this.gridLeft.current.offsetWidth,
        });
      }
    }
  }

  addResponse = (group_id) => {
    this.setState({
      ...this.state,
      dialogOpen: true,
      curGroup: group_id,
      questionText: "",
    });
  };

  saveResponse = () => {
    let g = this.state.curGroup;
    let path = `active_game.results.${g}.questionDebrief`;
    this.props.updateResultsPath(
      path,
      this.state.questionText,
      this.props.auth.uid
    );
    this.handleClose();
  };

  onChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  handleClickOpen = () => {
    this.setState({
      ...this.state,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      dialogOpen: false,
    });
  };

  handleTabChange = (event, newValue) => {
    console.log("????", event, newValue);
    this.setState({
      ...this.state,
      tab: newValue,
    });
  };

  setOption = (id, title, diff) => {
    console.log("id", id);
    this.setState({
      ...this.state,
      dialogOpen: true,
      newPuzzleId: id,
      newPuzzleTitle: title,
      newPuzzleDifficulty: diff,
    });
  };

  handleDialogClose = () => {
    this.setState({
      ...this.state,
      dialogOpen: false,
    });
  };

  render() {
    const {
      results,
      classes,
      auth,
      profile,
      game_options,
      //game_id, game_title,  profile, auth, group_state, game_state
    } = this.props;



    const options = gameOptions(results.id);

    return (
      <div ref={this.mainDiv}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>

            <Typography variant="h3">{results.name}</Typography>
            <Typography variant="h5" className={classes.spaceBottom}>
              Debrief
            </Typography>

          </Grid>
          <Grid item xs={12} sm={12} ref={this.gridLeft}>
            <Paper
              square
              className={classes.root}
              style={{ minHeight: "700px", minWidth: "920px" }}
            >
              <AppBar position="static">
                <Tabs
                  value={this.state.tab}
                  variant="fullWidth"
                  indicatorColor="secondary"
                  aria-label="icon label tabs example"
                >
                  {Object.keys(results.groups)
                    .sort()
                    .map((groupKey, ind) => {
                      if (groupKey !== "group-0") {
                        return (
                          <Tab
                            label={results.groups[groupKey].title}
                            key={ind - 1}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                </Tabs>
              </AppBar>
              {Object.keys(results.groups)
                .sort()
                .map((groupKey, ind) => {
                  if (groupKey === "group-0") {
                    return null;
                  }
                  return (
                    <TabPanel
                      value={this.state.tab}
                      index={ind - 1}
                      key={ind - 1}
                    >
                      <div className={classes.spacingTop} align="center">
                        <WordPlayground
                          title={results.groups[groupKey].title}
                          session={results}
                          results={
                            results.results[groupKey] === undefined
                              ? {}
                              : results.results[groupKey]
                          }
                          draggedEl={null}
                          clearDraggedEl={null}
                          updateLines={null}
                          updateImages={null}
                          control={false}
                          profile={profile}
                          host={null}
                          auth={auth}
                          group_id={groupKey}
                        />
                        
                      </div>
                    </TabPanel>
                  );
                })}
            </Paper>

           
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase: (session, host) =>
      dispatch(moveBackToResultsPhase(session, host)),
    moveBackToActiveWithParams: (session, host, params) =>
      dispatch(moveBackToActiveWithParams(session, host, params)),
    moveBackToInitPhaseWithOption: (session, host, optionName, optionVal) =>
      dispatch(
        moveBackToInitPhaseWithOption(session, host, optionName, optionVal)
      ),
    updateGameStateParams: (params, hostID) =>
      dispatch(updateGameStateParams(params, hostID)),
    updateResultsPath: (path, value, hostID) =>
      dispatch(updateResultsPath(path, value, hostID)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(GameResults);
