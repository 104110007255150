import React from 'react'
import CardListItem from './cardListItem'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography'

const CardList = (props) => {

    const listCards = props.cards.map((card, index) => {
        return (<li key={card.id}>
                <CardListItem
                    key={card.id}
                    id={card.id}
                    text={card.text}
                ></CardListItem>
                <br />
            </li>
        )
    })

    return (
       
            <Dialog open={props.open} onClose={props.handleClose} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth>
                <DialogContent>
                    <Typography variant="h4">Your Clues:</Typography>
                    <Typography variant="body1" style={{margin: "10px 0 30px 0"}}>These clues are specific to you. No one else can see them.</Typography>
                    <ul className="list browser-default">{listCards}</ul>
                    { props.profile.role === 'host' || props.profile.role === 'moderator' ? 
                        <Typography variant="body1" style={{margin: "20px 0", fontWeight:"bold"}}>You are logged in as a host/moderator, so you will not have any hidden agendas assigned to you.</Typography>
                        : 
                        null
                    }
                </DialogContent>           
            </Dialog>
           
       
    )
}

export default CardList
