export const URGENT_AND_NOT_IMPORTANT = 'urgent_not_important';
export const URGENT_AND_IMPORTANT = 'urgent_and_important';
export const NOT_URGENT_AND_NOT_IMPORTANT = 'not_urgent_and_not_important';
export const IMPORTANT_AND_NOT_URGENT = 'important_and_not_urgent';

export const QUADRANT_COLORS = {
    [URGENT_AND_NOT_IMPORTANT] : {
        color: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(207,165,97,1) 0%, rgba(207,165,97,0.6979166666666667) 100%, rgba(0,212,255,1) 100%);",
        title: 'Urgent & Not Important'
    },
    [URGENT_AND_IMPORTANT] : {
        //color: "rgba(80,156,147, 0.7)",
        color: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(80,156,147,1) 0%, rgba(80,156,147,0.7035189075630253) 100%, rgba(0,212,255,1) 100%);",
        title: 'Urgent & Important'
    },
    [NOT_URGENT_AND_NOT_IMPORTANT] : {
        color: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,114,185,1) 0%, rgba(6,114,185,0.6979166666666667) 100%, rgba(0,212,255,1) 100%);",
        title: 'Not Urgent & Not Important'
    },
    [IMPORTANT_AND_NOT_URGENT] : {
        color: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(142,115,179,1) 0%, rgba(142,115,179,0.6979166666666667) 100%, rgba(0,212,255,1) 100%);",
        title: 'Important & Not Urgent'
    }
}


export const PROFILE_MALE = 'Microsoft Guy Online (Natural) - English (United States)';
export const PROFILE_FEMALE = 'Microsoft Clara Online (Natural) - English (Canada)';

export const PROFILE_MALE2 = 'Google UK English Male';
export const PROFILE_FEMALE2 = 'Google UK English Female';

export const CaseStudy = {
    html: `Another meeting?  Where are you going to find time?  You look at your calendar and see a Plant Management meeting at 8 am, followed by a Pillar Review at 9:00.  Then at about 10 o’clock, you are scheduled for an interview/plant tour until noon. <br/><br/>
    Lucky for you, you have a break at lunch…but WAIT, you notice on your calendar you have a pending invite to attend a Lunch and Learn to discuss SAP progress.  The problem is, even though the meeting is scheduled from noon to 1 pm., the meeting seldom ends on time and often ends up on tangents that have nothing to do with SAP and inevitably prolongs the meeting. <br/><br/>
    While you are thinking your schedule through, your plant manager calls you with an urgent employee issue to discuss.  You explain to him/her, you have another meeting to go to… the response:  reschedule your meeting… see me now. <br/><br/>
    As you are going to the Plant Manager’s office, your HR Manager gives you that look….”we need to talk about your Glass of Learning discussions with your supervisors….I know you are busy, but you need to make this a priority.  Can we get together today to develop a plan?” <br/><br/>
    Some days you are not really sure what value you are contributing as a manager.  Every day the focus seems to be on firefighting instead of dealing with issues that will move the Company forward.  You really want to lead your department to achieve “Must Wins”, but realistically everything you receive from your boss or Corporate seems to be their “Must Win”, not necessarily yours. Yet you are really under pressure to get things done.  Your department alone is responsible for completing three (3) F Matrix projects that directly impact the plant’s performance and your meeting to review progress on your 100 day plans which have not yet been developed is due next Tuesday. <br/><br/>
    Although delegating some responsibilities to your staff would be helpful, realistically, there is too little time to train them, so it’s just easier to jump in and do things yourself.  Besides that, you are comfortable in making the day to day decisions…. you’ve been handling these decisions for some time now and have a good track record. In fact, you have a supervisor who is struggling with his team (too many quality issues due to insufficient training).  In your effort to give the supervisor a break and to address these issues, you’ve set up a meeting with the team to discuss/resolve issues (who could argue with engaging others – it’s a “must win” right?).  In addition, you’re planning on conducting Step Zero meetings (employee development – another “must win”).  And perhaps you might even have time to plan a “cook out” as a morale booster (a little celebration can only help things). <br/><br/>
    Lately, you’ve been noticing that even the most minor things are coming to your attention to address.  It’s not that you mind; sometimes, you relish being in charge.  But you notice that you are spending more and more time in the office and less time at home, and hardly any time on the shop floor. <br/><br/>
    As you are about to leave the office, the phone rings. It’s Central calling – Joe is an engineer that you really connected with at a recent WCM Forum. Joe has a project he wants your help with an of course you’d be delighted to help out.`,
};


export const Interruptions = [
    {
        id: 1,
        profile_title: "Process Engineer - Disrespect",
        profile_text: `A process engineer has just brought an ongoing concern to your attention. Although he repeatedly tries to work with your production supervisor, the supervisor continues to disrespect him and ignores his requests. You need to address this.`,
    },
    {
        id: 2,
        profile_title: "Plant Manager - Quarterly Meeting",
        profile_text: `Your Plant Manager has quarterly meetings with the management team and expects you to be doing the same with your team. It’s nearly the end of the quarter and you’ve yet to have this meeting and he’s looking for an update on the results.`,
    },
    {
        id: 3,
        profile_title: "Plant Manager - Wrong Level",
        profile_text: `Your Plant Manager has stopped you on your rounds and told you “You’re not working at the right level. You’re not a worker on the floor, you are a manager!”  You were only trying to get the right knife blades in place, which seemed like a safety issue to you.`,
    },
    {
        id: 4,
        profile_title: "Call the School",
        profile_text: `Please call your son’s school principal. It’s important you call back as soon as possible.`,
    },
    {
        id: 5,
        profile_title: "EHS Engineer - Training Request",
        profile_text: `The Environmental Health and Safety (EHS) engineer just called to tell you that for the second time this month, your Supervisors have not sent anyone to training, despite his follow up.`,
    },
    {
        id: 6,
        profile_title: "Maintenance Engineer - AM Tags",
        profile_text: `The AM Tags have been sitting for months in the AIB room. The Maintenance Engineer has requested a meeting with you and the Plant Manager to discuss.`,
    },
    {
        id: 7,
        profile_title: "Plant Manager - Learning Roadmap",
        profile_text: `The Plant Manager stops by to see how you’re doing and if you’ve had a chance to finish this weeks Learning Roadmap and progress on your project, as he’d love to discuss it with you.`,
    },
    {
        id: 8,
        profile_title: "Call your Spouse",
        profile_text: `Your partner/spouse wants you to call back as soon as possible.`,
    },
    {
        id: 9,
        profile_title: "Board Line Jam - Alarm",
        profile_text: `There is a jam on the board line and you’re hearing alarms going off.`,
    },
    {
        id: 10,
        profile_title: "Production Manager - CEO Tour",
        profile_text: `You just got a message from the Production Manager that CEO is coming tomorrow for a tour, and your area better be clean and while he’s here he wants your Supervisors to present their graduation projects.`,
    },
    {
        id: 11,
        profile_title: "Warehouse Manager - Take Off Driver Issue",
        profile_text: `The Warehouse Manager calls you and says you need to talk to the take off drivers because they are damaging board and putting board in improper locations.`,
    },
    {
        id: 12,
        profile_title: "Plant Manager - EWO Details",
        profile_text: `The Plant Manager just stopped by and questions why the EWO are not detailed enough as the way they are now will not get to the root cause – he  asks you to get personally involved and get your Supervisors back on track.`,
    },
];

