import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import LiquidLoadingBlock from '../../../../Loading/loadingCup';
import { getUserGroup_hc } from '../../game_functions/functions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';



class Results extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

  render() {
    const {
      session,
      classes,
      profile,
      auth
    } = this.props;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    return (
      <div>
        <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant="h5">Wrapping Up!</Typography>
            <div>
                <Typography variant="body1">Waiting for other groups to finish their activity.</Typography>
              <br />
              <br />
              <div className={classes.cupContainerLeft}>
                <LiquidLoadingBlock/>
              </div>
            </div>
            </Grid>
          <Grid item xs={12} sm={6}>        
                      
          </Grid>
          <Grid item xs={12} sm={6}>
           
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Results);