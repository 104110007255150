import React, { Component } from 'react'
import Footer from '../Footer';
import LogoHeader from '../Header';

class ErrorBoundary extends Component {
    constructor(props){
        super(props)
        this.state = { error: null, errorInfo: null }
    }

    componentDidCatch(error, info){
        this.setState({ error: error, errorInfo: info })
    }

    render() {
        if(this.state.errorInfo){
            return (
                <div className='meta-react'>
                    <LogoHeader/>
                    <div className='outer-container'>
                        <div className='container'>
                            <div className="error-boundary">
                                <summary>Oops. Something went wrong...</summary>
                                <details style={{ whiteSpace: 'pre-wrap' }}>
                                    { this.state.error && this.state.error.toString() }
                                    { this.state.errorInfo.componentStack }
                                </details>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>  
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary;