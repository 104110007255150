import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getFirebase } from 'react-redux-firebase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { startGame, getActivityLogo, saveActivityOptions, doneUpdatingImage, saveSpecificActivityOption, saveActivityOptionFile } from '../../store/actions/gameActions';
import styles from '../Styles';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import TextField from '@material-ui/core/TextField';
import { DropzoneDialog } from 'material-ui-dropzone';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';


const INITIAL_STATE = {
  optionsDisplay: undefined,
  otherRef: undefined,
  imageOpen: false,
  logoURLs: {},
  imagesLoaded: false,
  isSaving: false
}

const ImageFileList = ({ files }) => {
    const filenames = files.map((f) => {
      return f.name;
    });
  
    return (
      <p>
        <span style={{fontWeight:"bold"}}>New Image:</span> {filenames}
      </p>
    );
  };

class GameOptions extends Component {
  constructor(props) {
        super(props);
        let o = null;
        if ( props.options[props.auth.uid] !== undefined ){
            // we have host specific saved options
            o = props.options[props.auth.uid];
        } else {
            // we want to use the default
            o = props.options.default;
        }
        const updateINITIAL_STATE = {
            ...INITIAL_STATE,
            game_images: o.game_images,
            game_texts: o.game_texts,
            game_misc: o.game_misc
        }
        this.state = {
            ...updateINITIAL_STATE
        }   
  }

    processImages = async () => {
        let logos = {};
        const sto = getFirebase().storage();
        await Promise.all( Object.keys(this.state.game_images).map( async (key, ind) => {
            let ref = sto.ref(this.state.game_images[key]);
            let url = await ref.getDownloadURL();
            logos[key] = url;
        }));
        return Promise.resolve(logos);
    }

    async componentDidMount(){
        try {
            await this.processImages().then((logos) => {
                this.setState({
                    ...this.state,
                    logoURLs : logos,
                    imagesLoaded: true
                }); 
                return Promise.resolve();
            });
        } catch (error) {
        }
    }

    componentDidUpdate(prevProps, prevState){
      if ( !this.props.options.hasOwnProperty(this.props.auth.uid) ){
        this.saveOptions(false);
      }
      if ( this.props.game_state.newImage !== false ){
        const sto = getFirebase().storage();        
        let ref = sto.ref(this.props.game_state.newImage);
        ref.getDownloadURL().then((url) => {
            this.props.doneUpdatingImage();
            this.setState({
                ...this.state,
                logoURLs: {
                  ...this.state.logoURLs,
                  main: url
                },
                newImages: {}
            })
        });
      }
    }

    startNewGame = (index, name) => {
        this.props.startGame(index, name);
    };

    onChangeText = (event) => {
        this.setState({ 
            ...this.state, 
           game_texts : {
               ...this.state.game_texts,
               [event.target.name] : event.target.value
           }
         });
      };

      handleImageClose = (event) => {
        this.setState({
          imageOpen: false,
        });
      };
    
      handleImageOpen = (event) => {
        this.setState({
          imageOpen: true,
        });
      };
    
      imageChanged = (files, key) => {
        this.setState({
          ...this.state,
          imageOpen: false
        });
        this.props.saveActivityOptionFile(key, files, this.props.game_id, this.props.game_name, 'game_images');
      };

      saveText = (event, key) => {
        const t = this.state.game_texts[key];
        this.setState({
          ...this.state,
          isSaving: true
        });
        if ( !this.props.options.hasOwnProperty(this.props.auth.uid) ){
          this.saveOptions(false);
        } else {
          this.props.saveSpecificActivityOption(key, t, this.props.game_id, this.props.game_name, 'game_texts');
        }
      }
  
  saveOptions = (shouldStart) => {
      const optionsToSave = {
          game_images: this.state.game_images,
          game_texts: this.state.game_texts,
          game_misc: this.state.game_misc
      };
      let files = false;
      this.props.saveActivityOptions(optionsToSave, files, this.props.game_id, this.props.game_name, shouldStart)
  }

  render() {
    const {
        auth,options,game_name,classes
    } = this.props;
   
    let o = null;
    if ( options[auth.uid] !== undefined ){
        // we have host specific saved options
        o = options[auth.uid];
    } else {
        // we want to use the default
        o = options.default;
    }

    if ( this.state.imagesLoaded === false ){
        return null;
    }

    return (
        <div>
            <Backdrop className={classes.backdrop} open={this.props.game_state.isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Typography variant="h4">Options for {game_name}</Typography>
            <Typography variant="body2">Changes made to these fields are saved instantly.</Typography>
            <form autoComplete="off" noValidate>
            <div className={classes.spacingTop}>
                <Typography variant="h6">Images</Typography>
                <div style={{ border: "1px solid #000", borderRadius: "5px", padding: "30px"}}>
                {
                    Object.keys(o.game_images).map((key, ind) => {
                       return(<Grid container item spacing={2} key={ind}>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{fontWeight: "bold"}}>{key}</Typography>
                            <img src={this.state.logoURLs[key]} alt='Game Logos' style={{maxWidth: "200px", maxHeight: "200px", display: "block"}}/> 
                            <Button variant='contained' color='secondary' onClick={this.handleImageOpen}>
                                Upload New Image
                            </Button>           
                            <DropzoneDialog name='image' open={this.state.imageOpen} onSave={(files) => this.imageChanged(files, key)} onClose={this.handleImageClose} filesLimit={1} onChange={(files) => this.imageChanged(files, key)} showPreviews={true} id='image' label="label" />
                            { this.state.newImages !== undefined ? this.state.newImages[key] !== undefined ? <ImageFileList files={this.state.newImages[key]}/> : null : null }
                            </Grid>  
                        </Grid>)
                    })
                }
                </div>
            </div>
            <div className={classes.spacingTop}>
                <Typography variant="h6">Text</Typography>
                <div style={{ border: "1px solid #000", borderRadius: "5px", padding: "30px"}}>
                {
                    Object.keys(o.game_texts).sort().map((key, ind) => {
                        return (
                        <div key={ind} style={{margin: "15px 0"}}>
                          <TextField label={key} name={key} id={key} value={this.state.game_texts[key]} onChange={this.onChangeText} onBlur={(event) => this.saveText(event, key)}  fullWidth multiline />
                        </div>)
                    })
                }
                </div>
            </div>
            {  Object.keys(o.game_misc).length > 0 ?
                <div className={classes.spacingTop}>
                    <Typography variant="h6">Miscellaneous</Typography>
                </div>
             : null }
            </form>
            <div className={`${classes.spacingTop} ${classes.spacingBottom}`}>
            <Button size='small' onClick={() => this.props.returnToGameList()} variant="contained" className={classes.glassPurple} startIcon={<AssignmentReturnIcon />} style={{marginRight: "10px"}}>
               Return to Game List
            </Button>
            <Button size='small' onClick={() => this.saveOptions(true)} variant="contained"  color="primary" className={classes.glassPurple} startIcon={<PlayCircleFilledWhiteIcon />} style={{marginRight: "10px", color: "#fff"}}>
              Start Activity
            </Button>
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    game_state: state.game_state
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    startGame: (creds, name) => dispatch(startGame(creds, name)),
    getActivityLogo: (url) => dispatch(getActivityLogo(url)),
    saveActivityOptions: (options, file, game_id, game_name, shouldStart) => dispatch(saveActivityOptions(options, file, game_id, game_name, shouldStart)),
    doneUpdatingImage: () => dispatch(doneUpdatingImage()),
    saveSpecificActivityOption : (key, t, game_id, game_name, property) => dispatch(saveSpecificActivityOption(key, t, game_id, game_name, property)),
    saveActivityOptionFile : (key, file, game_id, game_name, property) => dispatch(saveActivityOptionFile(key, file, game_id, game_name, property))
  };
};

export default compose( connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation() )(GameOptions);