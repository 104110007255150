import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import ei_example from './../images/ei_example.png';
import styles from './../../../../Styles';

const INITIAL_STATE = {
  anchorEl: null,
};

class GameHelp extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleClick = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  render() {
    const { classes } = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <span className={classes.inlineEl}>
        <IconButton aria-label='Help' onClick={this.handleClick} >
          <HelpIcon />
        </IconButton>
        <Dialog
          maxWidth='md'
          id={id}
          open={open}
          onClose={this.handleClose}
        >
          <div className={classes.padMe}>
            <img src={ei_example} alt='emotional intellegence example' className={classes.imageSize}/>
          </div>
        </Dialog>
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameHelp);