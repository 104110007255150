import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './../../store';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
import Button from '@material-ui/core/Button';



export const PDFView = (props) => {
    
    return (
        <PDFDownloadLink document={
            <Provider store={store}>
                {props.children}
            </Provider>} fileName={`${props.title}.pdf`}>
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <Button variant="contained" color="primary" style={{marginBottom:"30px"}}>Download PDF</Button>)}
        </PDFDownloadLink>
    )
}



/*

 <Document>
                    <Page wrap>
                        <View>
                        <Text style={{width: "100%", textAlign: "center"}}>test</Text>
                        <Text>YEST@</Text>
                        </View>
                    </Page>
                </Document> 
*/