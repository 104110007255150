import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "1.1rem",
    padding: "30px 20px 0 20px",
  },
  helpText: {
      fontWeight: "bold",
      padding: "30px 20px 0 20px",
      fontSize: "1.2rem"
  }
}));

export default function ActivityDescPlus(props) {
    const classes = useStyles();  

    return (
        <div className={classes.root} dangerouslySetInnerHTML={{ __html : props.game_options.game_texts.plus_help }}></div>
    );
}