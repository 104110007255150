import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Button from '@material-ui/core/Button';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import DiscCardDraggable from './../specific_components/cardDraggable';
import { Q1_FS, Q2_FS, Q1, Q2 } from './activityTwo';
import ForwardIcon from '@material-ui/icons/Forward';
import discIcon from '../images/DiSC_Icon.png';
import { CardList, CardCategoryColors} from '../cards/cardList';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
import { PDFHeader } from './../../../../PastData/PDFHeader';
import { CardCategoryCSColors } from '../../communicationStyles/cards/cardList';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    returnToResults = () => {
        this.props.moveBackToResultsPhase(this.props.session, this.props.auth.uid);
    }

    moveToFloorExercise = () => {
        this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { 'phase' : 'floor' });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.results.resultSnapshot === undefined ? {} : this.props.results.resultSnapshot, this.props.results.results)
        })
    }


    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
    const {
      results,
      classes,
      profiles,
      isPDFView
    } = this.props;
    const { diff } = this.state;


    if(this.props.isPDFView === true){
        return (
            <Document>
                 <Page wrap style={{padding: "30 0"}}>
                    <PDFHeader/>
                    <View>
                        <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={discIcon}/>
                        {Object.keys(results.results)
                        .sort()
                        .map((groupKey, ind) => {
                        return (
                        <React.Fragment key={ind}>
                            <View style={{marginHorizontal: "50", marginBottom: "50"}} wrap={false}>
                                <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>                                    
                                <View style={{marginVertical: "5", flexDirection: "row", borderRadius: "15", backgroundColor: "rgb(209, 196, 233)"}} wrap={false}>

                                    <View style={{ width: "45%"}}>
                                        <Text style={{ fontSize: "10pt", marginBottom: "7", marginLeft: "15", marginTop: "20"}}>CARD CHOICES</Text>
                                        <View style={{marginVertical: "10", marginHorizontal: "7", flexDirection: "column", borderRadius: "15", border: "1pt solid #FFF", backgroundColor: "rgb(209, 196, 233)", alignContent: "center"}}>
                                        {results.results[groupKey].card_choices.map((card_id, index) => { 
                                            let card = CardList.find(obj => {
                                                return obj.id === card_id
                                            }); 
                                            if( card.card_fun === true){                              
                                            return(
                                                
                                                <View key={index} style={{backgroundColor: CardCategoryColors[card.card_category], marginVertical: "4", marginHorizontal:"25", borderRadius: "2", flexDirection: "row" }}>
                                                    <View style={{ width: "30%", flexDirection: "column"}}>
                                                        <Image style={{marginVertical:"10", width: '30px', display: "block", marginLeft: "10"}} src={discIcon}/>
                                                        <Text style={{ fontSize: "8pt", display: "block", marginLeft: "15", marginBottom: "5", fontWeight: "light"}}>FUN</Text>
                                                        
                                                    </View>
                                                    <View style={{ width: "70%"}}>
                                                        <Text style={{ fontSize: "10pt", marginTop: "15", marginBottom: "5", marginLeft: "5", marginRight: "15"}}>{card.card_words}</Text>
                                                        <Text style={{ fontSize: "8pt", marginBottom: "5", marginLeft: "5", marginRight: "15", fontWeight: "light", color: "rgb(120,120,120)"}}>{card.card_subwords}</Text>
                                                    </View>
                                                </View>
                                            ) }
                                            else if(card.card_fun === false){                              
                                                return(
                                                    
                                                    <View key={index} style={{backgroundColor: CardCategoryColors[card.card_category], marginVertical: "4", marginHorizontal:"25", borderRadius: "2", flexDirection: "row" }}>
                                                        <View style={{ width: "30%"}}>
                                                            <Image style={{marginVertical:"10", width: '30px', display: "block", marginLeft: "10"}} src={discIcon}/>
                                                            
                                                        </View>
                                                        <View style={{ width: "70%"}}>
                                                            <Text style={{ fontSize: "10pt", marginTop: "15", marginBottom: "5", marginLeft: "5", marginRight: "15"}}>{card.card_words}</Text>
                                                            <Text style={{ fontSize: "8pt", marginBottom: "5", marginLeft: "5", marginRight: "15", fontWeight: "light", color: "rgb(120,120,120)"}}>{card.card_subwords}</Text>
                                                        </View>
                                                    </View>
                                                ) }                        
                                        })} 
                                        </View>
                                    </View>

                                    <View style={{ width: "45%"}}>
                                        <Text style={{ fontSize: "10pt", marginLeft: "15", marginTop: "20"}}>QUESTION/ANSWER</Text>
                                        <View style={{marginVertical: "10", marginHorizontal: "7", flexDirection: "column", backgroundColor: "rgb(209, 196, 233)"}}>
                                            <Text style={{ fontWeight: 'bold', fontSize: "10pt", marginBottom: "5", marginLeft: "15"}}>How I like to work and the kind of work I like to do...</Text>
                                            <View style={{marginBottom: "10", marginHorizontal: "7", flexDirection: "column", borderRadius: "15", border: "1pt solid #FFF", backgroundColor: "rgb(209, 196, 233)"}}>
                                                {/* q1 map */}

                                                { 
                                                    results.results[groupKey] !== undefined ?
                                                        results.results[groupKey]["q1"] !== undefined ?
                                                    
                                                            results.results[groupKey]['q1'].map((item, i) =>{
                                                                return(
                                                                    <Text key={i} style={{ fontSize: "10pt", marginBottom: "15", marginLeft: "15", marginTop: "10"}}>{item}</Text>
                                                                )
                                                            })
                                                    
                                                        : <Text style={{ fontSize: "10pt", marginBottom: "15", marginLeft: "15", marginTop: "20"}}> </Text>
                                                    : <Text style={{ fontSize: "10pt", marginBottom: "15", marginLeft: "15", marginTop: "20"}}> </Text>
                                                   }
                                            </View>
                                            <Text style={{ fontWeight: 'bold', fontSize: "10pt", marginBottom: "5", marginLeft: "15", marginTop: "10"}}>People drive me crazy when...</Text>
                                            <View style={{marginBottom: "10", marginHorizontal: "7", flexDirection: "column", borderRadius: "15", border: "1pt solid #FFF", backgroundColor: "rgb(209, 196, 233)"}}>
                                                {/* q2 map */}
                                                { 
                                                    results.results[groupKey] !== undefined ?
                                                        results.results[groupKey]["q2"] !== undefined ?
                                                    
                                                            results.results[groupKey]['q2'].map((item, i) =>{
                                                                return(
                                                                    <Text key={i} style={{ fontSize: "10pt", marginBottom: "15", marginLeft: "15", marginTop: "10"}}>{item}</Text>
                                                                )
                                                            })
                                                    
                                                        : <Text style={{ fontSize: "10pt", marginBottom: "15", marginLeft: "15", marginTop: "20"}}> </Text>
                                                    : <Text style={{ fontSize: "10pt", marginBottom: "15", marginLeft: "15", marginTop: "20"}}> </Text>
                                                   }
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View> 
                        </React.Fragment>       
                        );
                    })}
                    </View>
                </Page>
            </Document> 
        ) 
    }


    if ( diff === undefined ){
        return null;
    } else {
        return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">{results.name}</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {Object.keys(results.results)
                    .sort()
                    .map((group_id, ind) => {
                        if ( group_id === 'floor' ){
                            return null;
                        }
                        return (
                        <div key={ind}>
                            <Typography variant="h4">{results.groups[group_id].title}</Typography>
                            <Grid container className={classes.paperExtra}>
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                <Typography variant="h6">Card Choices</Typography> 
                                    <List className={`${classes.listClass} ${classes.relativeList} ${classes.fullWidthListItem}`} >
                                    { results.results[group_id].card_choices.map((card_id, index) => (                                        
                                        <DiscCardDraggable results={results} card_id={card_id} key={card_id} index={index} control={false} />                          
                                    ))
                                    }
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <Typography variant="h6" className={classes.spacingBottom}>Question/Answer</Typography> 
                                <Typography variant="body1" className={classes.boldedText}>{Q1}</Typography>    
                                    <List className={classes.listClass}>
                                        {results.results.hasOwnProperty(group_id)
                                            ? results.results[group_id].hasOwnProperty(Q1_FS)
                                            ? results.results[group_id][Q1_FS].map((item, index) => {
                                                return (  
                                                    <ListItem key={index}>                 
                                                        <ListItemText
                                                            className={classes.bodyText} 
                                                            primary={item}                                
                                                        />
                                                    </ListItem>
                                                );
                                                })
                                            : null
                                        : null}
                                    </List>
                                    <Typography variant="body1" className={`${classes.spacingTop} ${classes.boldedText}`}>{Q2}</Typography>    
                                    <List className={classes.listClass}>
                                        {results.results.hasOwnProperty(group_id)
                                            ? results.results[group_id].hasOwnProperty(Q2_FS)
                                            ? results.results[group_id][Q2_FS].map((item, index) => {
                                                return (
                                                    <ListItem key={index}>                 
                                                        <ListItemText
                                                            className={classes.bodyText} 
                                                            primary={item}                                
                                                        />
                                                    </ListItem>
                                                );
                                                })
                                            : null
                                        : null}
                                    </List>
                                </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                        </div>
                        );
                    })}
                </Grid>
                
            </Grid>
        </div>
        );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
