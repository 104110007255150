import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Interruptions } from './../data/data';
import { connect } from 'react-redux';
import { editResultsPath, updateGroupStateEndInterruption } from './../../../../../store/actions/gameActions';
import { compose } from 'redux';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider';
import styles from '../../../../Styles';

class InterruptionPostIt extends Component {
  
  endInterruption(){
      this.props.updateGroupStateEndInterruption(this.props.group_id, this.props.host);
      
  }

  render() {

    const { classes } = this.props;
    const result = Interruptions.filter(v => v.id === this.props.id );
    let text = "";
    if ( result.length === 1 ){
      text = result[0].profile_text;
    }

    return (
      <Dialog open={true}  maxWidth="md" fullWidth>
          <AppBar position="static">
          <Toolbar>
              { this.props.control === true ?
              <IconButton edge="start" color="inherit" onClick={() => this.endInterruption()} aria-label="close">
              <CloseIcon />
              </IconButton>
              : null }
              <Typography variant="h6" className={classes.boldedText}>
              Something has come up!
              </Typography>                    
          </Toolbar>
          </AppBar>
          <DialogContent>
          <Grid container spacing={2} className={`${classes.spacingBottom} ${classes.spacingTop}`}>
              <Grid item xs={12} >
                  <Typography variant="body1">
                      {text}
                  </Typography>
                  <Divider className={`${classes.spacingTop} ${classes.spacingBottom}`}/>
                  <div className={classes.spacingTop}>
                    A new issue has just been added to your unassigned issues list. { this.props.control === true ? `Click the X in the top left to close this item.` : null }
                  </div>
              </Grid>
          </Grid>
          </DialogContent>               
      </Dialog>
    );
  }
}

InterruptionPostIt.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {        
      updateGroupStateEndInterruption : (group, hostID) => dispatch(updateGroupStateEndInterruption(group, hostID)),
      editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(InterruptionPostIt);
