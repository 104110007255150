import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults, updateGroupStateParamsVal, editResultsPath, updateResultsPath, removeResultsPath } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { PROFILE_VALUES } from '../profiles/profileList';
import EISkill from './../profiles/eiSkill';


const INITIAL_STATE = {
  noteOpen: false,
  entry_text: '',
  isEdit: false, 
  choiceSet: Object.keys(PROFILE_VALUES),
  choices: Object.keys(PROFILE_VALUES),
  answer: []
};

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#fff',
  display: 'flex',
  flexWrap: 'wrap',
  padding: '8px 16px',
  overflow: 'auto',
  margin: '0 0 10px 0',
  minHeight: '60px',
  width: '100%',
});
const getListStyle2 = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#fff',
  display: 'inline-block',
  flexWrap: 'wrap',
  padding: '8px 16px',
  overflow: 'auto',
  margin: '0 3px 0 0',
  minHeight: '60px',
  width: '200px',
});

class SkillPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( this.props.session.active_game.results.hasOwnProperty(groupID) ){
      if ( this.props.session.active_game.results[groupID].hasOwnProperty(`step_${this.props.step}`) ){
        if ( this.props.session.active_game.results[groupID][`step_${this.props.step}`].choice !== false && this.props.session.active_game.results[groupID][`step_${this.props.step}`].choice !== undefined ){
          let c = [...Object.keys(PROFILE_VALUES)];
          let ch = this.props.session.active_game.results[groupID][`step_${this.props.step}`].choice;
          let i = c.indexOf(ch);
          c.splice(i, 1);
          this.setState({
            ...this.state,
            answer: [ch],
            choices: [...c]
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps){
    if ( this.props.session.active_game.results.hasOwnProperty(this.props.group_id) ){
      if ( this.props.session.active_game.results[this.props.group_id].hasOwnProperty(`step_${this.props.step}`) ){
        if ( this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].choice !== undefined ){
          if ( prevProps.session.active_game.results[this.props.group_id][`step_${this.props.step}`].choice !== this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].choice ){
            let c = [...Object.keys(PROFILE_VALUES)];
            let ch = this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].choice;
            if ( ch === false ){
              this.setState({
                ...this.state,
                answer: [],
                choices: [...c]
              });
            } else {
              let i = c.indexOf(ch);
              if ( i >= 0 ){
                c.splice(i, 1);
              }
              this.setState({
                ...this.state,
                answer: [ch],
                choices: [...c]
              });
            }
          }
        }
      }
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (!hasGameControl(this.props.session.active_game.groups[this.props.group_id].leadPlayer, this.props.auth.uid, this.props.profile)){
        return;
    }
    if (result.destination.droppableId === 'step_answer' && this.state.answer.length > 0 ){
      return;
    }
    if ( result.destination.droppableId === result.source.droppableId ){
      return;
    }

    const c = [...Object.keys(PROFILE_VALUES)];
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    if ( result.destination.droppableId === 'step_answer' ){
      const a = Object.keys(PROFILE_VALUES)[result.source.index];
      const answer = [a];
    
      c.splice(result.source.index,1);
      this.setState({
        ...this.state,
        choices: [...c],
        answer: [...answer]
      });    
      this.props.editResultsPath(`active_game.results.${groupID}.step_${this.props.step}.choice`, a, determineHost(this.props.profile, this.props.auth));
    }

    if ( result.destination.droppableId === 'step_eis' ){
        if ( result.source.droppableId === 'step_answer' ){
          this.setState({
            ...this.state,
            choices: [...c],
            answer: []
          }); 
          this.props.editResultsPath(`active_game.results.${groupID}.step_${this.props.step}.choice`, false, determineHost(this.props.profile, this.props.auth));
        }
    }
    return;
  } 

  render() {
    const {
      classes,
    } = this.props;

    return (
      <div>
        <div className={classes.spacingTop}>
            <DragDropContext onDragEnd={this.onDragEnd}>
            <Typography variant="h5">Now that you've read the profile...</Typography>
            <Typography variant="h6" className={classes.spacingBottom}>Complete the following by dragging the appropriate answer into the blank space:</Typography>
            <Typography variant="body1">The <span className={classes.descriptiveText}>Emotional Intelligence</span> skill that is  
            <span className={classes.boldedText}> demonstrated</span> or <span className={classes.boldedText}>lacking</span> in this scenario is </Typography>
            <Droppable droppableId={`step_answer`} direction='horizontal'>
                {(provided, snapshot) => (
                <span className={classes.eiDropArea} ref={provided.innerRef} {...provided.droppableProps} style={getListStyle2(snapshot.isDraggingOver)} >
                    { this.state.answer.map((p, ind) => {
                    return <EISkill classes={classes} p={p} index={ind} key={ind}/>                    
                    }) }
                    {provided.placeholder}
                </span> 
                )}
            </Droppable>.
            <Grid container className={classes.spacingTop}>
                <Grid item xs={12} sm={12}>
                    <Grid container className={classes.spacingBottom}>
                        <Grid item xs={12} align="left">
                        <Typography variant="body1" className={classes.boldedText}>EI Core Skills:</Typography>
                        <Droppable droppableId={`step_eis`} direction='horizontal'>
                        {(provided, snapshot) => (
                            <div className={classes.eiFullDropArea} ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                            { this.state.choices.map((p, ind) => {
                                return <EISkill classes={classes} p={p} index={ind} key={p}/>                    
                            })                                    
                            }
                            {provided.placeholder}
                            </div>
                            )}
                            </Droppable>
                        </Grid>
                    </Grid>               
                </Grid>
            </Grid>
            </DragDropContext>
        </div>             
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    updateGroupStateParamsVal: ( group, value, groupStateParams, hostID ) => dispatch(updateGroupStateParamsVal(group, value, groupStateParams, hostID) ),
    editResultsPath: (path, value, host) => dispatch(editResultsPath(path, value, host)),
    updateResultsPath: (path, value, host) => dispatch(updateResultsPath(path, value, host)),
    removeResultsPath: (path, value, host) => dispatch(removeResultsPath(path, value, host)),

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(SkillPicker);