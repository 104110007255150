import React, { useState, CSSProperties } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from "@material-ui/core/Tooltip";

export default function CheckedAvatar(props) {

    const [joined, setJoined] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    
    React.useEffect(() => {
        updatedJoined();
    },[]);
        
    
    const updatedJoined = () => {
        if ( props.playerProfiles !== undefined ){
            Object.keys(props.playerProfiles).forEach((playerProfile, ind) => {
                if ( props.playerProfiles[playerProfile].plannedSessionCode === props.player.code.toString() ){
                    setJoined(true);
                    setLoading(false);
                }
            });
        }
    }

    React.useEffect(() => {
        updatedJoined();
    },[props.playerProfiles]);

    

    //console.log('CHECKING ON PLAYER: ', joined, props.playerProfiles, props.player);
    if ( loading ) {
        return <Avatar></Avatar>
    }


    if ( joined ) {        
        return <Tooltip title={`Player Has Joined Session`} aria-label="player-has-joined"><Avatar style={{color: "blue"}}></Avatar></Tooltip>
    } else {
        return <Avatar></Avatar>
    }
}