import { Layer, Line, Stage, Rect, Transformer } from "react-konva";
import React, { useEffect, useState } from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { URLImage } from "./URLImage";
import { pieces } from "../data/data";
import { v4 as uuidv4 } from "uuid";

const INITIAL_STATE = {
  timerActive: true,
  currentInterruption: null,
  x: 50,
  y: 50,
  isDragging: false,
  images: [],
  isDrawing: false,
  lines: [],
};


const WordPlayground = (props) => {
  const stageRef = React.useRef();
  const [images, setImages] = useState([]);

  const [lines, setLines] = useState([]);
  const isDrawing = React.useRef(false);

  const [enableDrawing, setEnableDrawing] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [mouseY, setMouseY] = useState(null);
  const [mouseX, setMouseX] = useState(null);
  const [contextImageId, setContextImageId] = useState(null);

  const [deselectAll, setDeselectAll] = useState(false);


  const [selectedId, selectShape] = React.useState(null);
  const [nodesArray, setNodes] = React.useState([]);

  const trRef = React.useRef();
  const layerRef = React.useRef();
  const Konva = window.Konva;

  React.useEffect(() => {
    if (props.results.lines !== lines && props.results.lines !== undefined) {
      let newLines = props.results.lines.slice();
      setLines(newLines);
    }
    if (props.results.images !== images && props.results.images !== undefined) {
      let newImages = props.results.images.slice();
      setImages(newImages);
    }
  }, [props.results]);

  const handleTransformEnd = (event) => {
    let scaleX = event.target.attrs.scaleX;
    let scaleY = event.target.attrs.scaleY;
    let rotation = event.target.attrs.rotation;

    const id = event.target.attrs.id;

    const slicedImages = images.slice();

    const imageInQuestion = slicedImages.find((i) => i.id === id);
    const index = slicedImages.indexOf(imageInQuestion);

    let newImage = {
      ...imageInQuestion,
      scaleX: scaleX,
      scaleY: scaleY,
      rotation: rotation,
      x: event.target.x(),
      y: event.target.y(),
    };

    slicedImages[index] = newImage;

    if (props.control) {
      setImages(slicedImages);
    }
    props.updateImages("update", slicedImages);
  };

  const onDragStartImage = (e) => {
    if (isDrawing.current === true) {
      return false;
    }
  };

  const sendToBack = (e) => {
    const id = contextImageId;

    const items = images.slice();

    const item = items.find((i) => i.id === id);
    const index = items.indexOf(item);
    // remove from the list:
    items.splice(index, 1);

    let newImage = {
      ...item,
    };

    // add to the back
    items.unshift(newImage);
    if (props.control) {
      setImages(items);
    }
    props.updateImages("update", items);
    handleContextClose();
  };

  const handleImageDragEnd = (e) => {
    const id = e.target.attrs.id;

    const items = images.slice();
    const item = items.find((i) => i.id === id);
    const index = items.indexOf(item);
    // remove from the list:
    //items.splice(index, 1);

    let newImage = {
      ...item,
      x: e.target.x(),
      y: e.target.y(),
    };

    items[index] = newImage;

    setDeselectAll(true);
    // add to the top
    //items.push(newImage);
    if (props.control) {
      setImages(items);
    }
    props.updateImages("update", items);
  };

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
      trRef.current.nodes([]);
      setNodes([]);
      // layerRef.current.remove(selectionRectangle);
    }
  };

  const selectionRectRef = React.useRef();
  const selection = React.useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  });

  const updateSelectionRect = () => {
    const node = selectionRectRef.current;
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: "rgba(0, 161, 255, 0.3)"
    });
    node.getLayer().batchDraw();
  };

  const oldPos = React.useRef(null);
  const onMouseDown = (e) => {
    const isElement = e.target.findAncestor(".elements-container");
    const isTransformer = e.target.findAncestor("Transformer");
    if (isElement || isTransformer) {
      return;
    }

    const pos = e.target.getStage().getPointerPosition();
    selection.current.visible = true;
    selection.current.x1 = pos.x;
    selection.current.y1 = pos.y;
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };

  const onMouseMove = (e) => {
    if (!selection.current.visible) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };

  const onMouseUp = () => {
    oldPos.current = null;
    if (!selection.current.visible) {
      return;
    }
    const selBox = selectionRectRef.current.getClientRect();

    const elements = [];
    //console.log('uh', layerRef.current);
    layerRef.current.children.forEach((elementNode) => {
      
      if ( elementNode instanceof Konva.Image ){
        //console.log('element, ', elementNode);
      
        const elBox = elementNode.getClientRect();
        if (Konva.Util.haveIntersection(selBox, elBox)) {
          //console.log('this one', elementNode)
          elements.push(elementNode);
        }
      }
    });
    trRef.current.nodes(elements);
    selection.current.visible = false;
    // disable click event
    Konva.listenClickTap = false;
    updateSelectionRect();
  };

  const onClickTap = (e) => {
    // if we are selecting with rect, do nothing
    //if (selectionRectangle.visible()) {
      //return;
    //}
    let stage = e.target.getStage();
    let layer = layerRef.current;
    let tr = trRef.current;
    // if click on empty area - remove all selections
    if (e.target === stage) {
      selectShape(null);
      setNodes([]);
      tr.nodes([]);
      layer.draw();
      return;
    }

    // do nothing if clicked NOT on our rectangles
    if (!e.target.hasName(".rect")) {
      return;
    }

    // do we pressed shift or ctrl?
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
    const isSelected = tr.nodes().indexOf(e.target) >= 0;

    if (!metaPressed && !isSelected) {
      // if no key pressed and the node is not selected
      // select just one
      tr.nodes([e.target]);
    } else if (metaPressed && isSelected) {
      // if we pressed keys and node was selected
      // we need to remove it from selection:
      const nodes = tr.nodes().slice(); // use slice to have new copy of array
      // remove node from array
      nodes.splice(nodes.indexOf(e.target), 1);
      tr.nodes(nodes);
    } else if (metaPressed && !isSelected) {
      // add the node into selection
      const nodes = tr.nodes().concat([e.target]);
      tr.nodes(nodes);
    }
    layer.draw();
  };

  const addElementsToCanvas = (els, results) => {
    const newImages = [];

    if ( results.images !== undefined ){
      results.images.forEach((el) => {
        let newImage = {
          ...el
        };
        newImages.push(newImage);
      });
    } else {
      if ( props.hasControl ){
        els.forEach((el) => {
          let newImage = {
            ...el
          };
          newImages.push(newImage);
        });
        //console.log('newImages: ', newImages);
        props.updateImages("add", newImages);
      }
    }

    
    
  };

  useEffect(() => {
    addElementsToCanvas(pieces, props.results);
  }, []);

  const openContextMenu = (event, contextImageId) => {
    setContextImageId(contextImageId);
    setMouseY(event.clientY - 4);
    setMouseX(event.clientX - 2);
  };

  const handleContextClose = () => {
    setMouseY(null);
    setMouseX(null);
    setContextImageId(null);
    setDeselectAll(true);
  };

  const resetDeselectAll = () => {
    setDeselectAll(false);
  };

  
  

  return (
    <div>
      <Menu
        keepMounted
        open={mouseY !== null && mouseX !== null}
        onClose={handleContextClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY !== null && mouseX !== null
            ? { top: mouseY, left: mouseX }
            : undefined
        }
      >
        <MenuItem onClick={sendToBack}>Send to Back</MenuItem>
        {/* <MenuItem onClick={deletePiece}>Remove this Piece</MenuItem> */}
        <MenuItem>--------------</MenuItem>
        <MenuItem onClick={handleContextClose}>Cancel</MenuItem>
      </Menu>

      <div
        style={{ height: "760px", width: "900px", marginTop: "20px" }}
        id="white-board"
        onDrop={(e) => {
          // e.preventDefault();
          // console.log("dragged el: ", props.draggedEl);
          // stageRef.current.setPointersPositions(e);
          // addElementToCanvas(props.draggedEl);
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className="whiteboard-base-text">
          {props.title === undefined ? "SEEING THE POINT" : props.title}
        </div>
        <Stage
          width={900}
          height={700}
          
          ref={stageRef}
          id="theStage"
        >
          <Layer ref={layerRef}>
            {images.map((image, ind) => {
              return (
                <URLImage
                  key={ind}
                  isDrawing={isDrawing.current}
                  image={image}
                  handleDragEnd={handleImageDragEnd}
                  dragStart={onDragStartImage}
                  transformEnd={handleTransformEnd}
                  control={props.control}
                  openContext={openContextMenu}
                  deselectAll={false}
                  resetDeselectAll={resetDeselectAll}
                />
              );
            })}
            <Transformer
          // ref={trRef.current[getKey]}
            ref={trRef}
            resizeEnabled={false}
            flipEnabled={true}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          />
          <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef} />
          </Layer>
         
        </Stage>
      </div>
    </div>
  );
};

export default WordPlayground;
