export const CARD_CAT_THINKER = "thinker";
export const CARD_CAT_INTUITOR = "intuitor";
export const CARD_CAT_FEELER = "feeler";
export const CARD_CAT_SENSOR = "sensor";

export const CardCategoryCSColors = {
    [CARD_CAT_THINKER] : '#dbc5ed',
    [CARD_CAT_INTUITOR] : '#ffcdcd',
    [CARD_CAT_FEELER] : '#ffe599',
    [CARD_CAT_SENSOR] : '#d9e1f3',
}

export const CardList = [
    {
        id: 1,
        card_category : CARD_CAT_THINKER,
        card_words: 'Structured',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 2,
        card_category : CARD_CAT_THINKER,
        card_words: 'Logical',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 3,
        card_category : CARD_CAT_THINKER,
        card_words: 'Analytical',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 4,
        card_category : CARD_CAT_THINKER,
        card_words: 'Organized',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 5,
        card_category : CARD_CAT_THINKER,
        card_words: 'Conservative',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 6,
        card_category : CARD_CAT_THINKER,
        card_words: 'Steady',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 7,
        card_category : CARD_CAT_THINKER,
        card_words: 'Skeptical of Unplanned Changes',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 8,
        card_category : CARD_CAT_THINKER,
        card_words: '"Sleep" on New Ideas',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 9,
        card_category : CARD_CAT_THINKER,
        card_words: 'Rational',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 10,
        card_category : CARD_CAT_THINKER,
        card_words: 'Avoid Emotional Displays',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 11,
        card_category : CARD_CAT_FEELER,
        card_words: 'Expression',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 12,
        card_category : CARD_CAT_FEELER,
        card_words: 'Human Interaction',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 13,
        card_category : CARD_CAT_FEELER,
        card_words: 'Social',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 14,
        card_category : CARD_CAT_FEELER,
        card_words: 'Entertaining',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 15,
        card_category : CARD_CAT_FEELER,
        card_words: 'Informal',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 16,
        card_category : CARD_CAT_FEELER,
        card_words: 'Warm',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 17,
        card_category : CARD_CAT_FEELER,
        card_words: 'Good Listener',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 18,
        card_category : CARD_CAT_FEELER,
        card_words: 'Perceptive of People',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 19,
        card_category : CARD_CAT_FEELER,
        card_words: 'Patient',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 20,
        card_category : CARD_CAT_FEELER,
        card_words: 'Understanding',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 21,
        card_category : CARD_CAT_INTUITOR,
        card_words: 'Imaginative',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 22,
        card_category : CARD_CAT_INTUITOR,
        card_words: 'Envisioning',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 23,
        card_category : CARD_CAT_INTUITOR,
        card_words: 'Future',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 24,
        card_category : CARD_CAT_INTUITOR,
        card_words: 'Concepts',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 25,
        card_category : CARD_CAT_INTUITOR,
        card_words: 'Unstructured',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 26,
        card_category : CARD_CAT_INTUITOR,
        card_words: 'Deep Thinker',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 27,
        card_category : CARD_CAT_INTUITOR,
        card_words: 'Questions Self/Others',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 28,
        card_category : CARD_CAT_INTUITOR,
        card_words: 'Dislikes Strict Structure',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 29,
        card_category : CARD_CAT_INTUITOR,
        card_words: 'Creative',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 30,
        card_category : CARD_CAT_INTUITOR,
        card_words: 'Original',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 31,
        card_category : CARD_CAT_SENSOR,
        card_words: 'Competing',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 32,
        card_category : CARD_CAT_SENSOR,
        card_words: 'Action',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 33,
        card_category : CARD_CAT_SENSOR,
        card_words: 'Winning',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 34,
        card_category : CARD_CAT_SENSOR,
        card_words: 'Achievement',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 35,
        card_category : CARD_CAT_SENSOR,
        card_words: 'Results',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 36,
        card_category : CARD_CAT_SENSOR,
        card_words: 'Getting Things Done',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 37,
        card_category : CARD_CAT_SENSOR,
        card_words: 'Direct',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 38,
        card_category : CARD_CAT_SENSOR,
        card_words: 'Decisive',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 39,
        card_category : CARD_CAT_SENSOR,
        card_words: 'Organized',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 40,
        card_category : CARD_CAT_SENSOR,
        card_words: 'High Standards',
        card_subwords: null,
        card_fun: false
    },
];


