
export const CardList = [
    {
        id: 1,        
        card_words: '1. Group Projects to apply learning',
    },
    {
        id: 2,        
        card_words: '2. Additional reading, podcasts, or video',
    },
    {
        id: 3,        
        card_words: '3. Behavior Checklists – what should a manager see in the trainee',
    },
    {
        id: 4,        
        card_words: '4. Create learning opportunities in class that mirror on the job application',
    },
    {
        id: 5,        
        card_words: '5. Generate analogies and metaphors that link prior knowledge/experience to create associations between old and new ideas.',
    },
    {
        id: 6,        
        card_words: '6. Case Studies – before, during, after',
    },
    {
        id: 7,        
        card_words: '7. Situational Judgment Testing – what would you do if...',
    },
    {
        id: 8,        
        card_words: '8. Challenge learners and direct leaders to find daily opportunities to apply new learning',
    },
    {
        id: 9,        
        card_words: '9. Review quiz – testing to highlight gaps in understanding',
    },
    {
        id: 10,        
        card_words: '10. Establish learning goals to know expected outcomes',
    },
    {
        id: 11,        
        card_words: '11. Badges and points for completion of learning transfer activities',
    },
    {
        id: 12,        
        card_words: '12. Discussion topics – online',
    }
];


