import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DiscCardDraggable from './../specific_components/cardDraggable';
import { Droppable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import LiquidLoadingBlock from '../../../../Loading/loadingCup';
import { getUserGroup_hc } from '../../game_functions/functions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { Q1_FS, Q2_FS, Q1, Q2 } from './activityTwo';

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#ffffff',
});

class Results extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

  render() {
    const {
      session,
      classes,
      profile,
      auth
    } = this.props;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    const before_choices = session.active_game.results[group_id] === undefined ? [] : session.active_game.results[group_id].before_choices === undefined ? [] : session.active_game.results[group_id].before_choices;
    const during_choices = session.active_game.results[group_id] === undefined ? [] : session.active_game.results[group_id].during_choices === undefined ? [] : session.active_game.results[group_id].during_choices;
    const after_choices = session.active_game.results[group_id] === undefined ? [] : session.active_game.results[group_id].after_choices === undefined ? [] : session.active_game.results[group_id].after_choices;

    return (
      <div>
         <DragDropContext>
        <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant="h5">Wrapping Up!</Typography>
            <div>
                <Typography variant="body1">Waiting for other groups to finish their activity.</Typography>
              <br />
              <br />
              <div className={classes.cupContainerLeft}>
                <LiquidLoadingBlock/>
              </div>
            </div>
            </Grid>
          <Grid item xs={12} sm={12}>        
          <Grid container spacing={3}>
                <Grid item sm={4}>
                  <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>Before Training</Typography> 
                  <Droppable droppableId={`before_choices`} direction='vertical'>
                      {(provided, snapshot) => (
                        <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                          
                          { before_choices.map((card_id, index) => (                                        
                              <DiscCardDraggable session={session} profile={profile} col={`before_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={false}/>                          
                          ))
                          }
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                </Grid>
                <Grid item sm={4}>
                <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>During Training</Typography> 
                <Droppable droppableId={`during_choices`} direction='vertical'>
                    {(provided, snapshot) => (
                      <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        
                        { during_choices.map((card_id, index) => (                                        
                            <DiscCardDraggable session={session} profile={profile} col={`during_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={false}/>                          
                        ))
                        }
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </Grid>
                <Grid item sm={4}>
                <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>After Training</Typography> 
                <Droppable droppableId={`after_choices`} direction='vertical'>
                    {(provided, snapshot) => (
                      <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        
                        { after_choices.map((card_id, index) => (                                        
                            <DiscCardDraggable session={session} profile={profile} col={`after_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={false}/>                          
                        ))
                        }
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </Grid>
              </Grid>
          </Grid>
          
        </Grid>
        </DragDropContext>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Results);