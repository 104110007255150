import React, { useState } from 'react';

import { Droppable } from 'react-beautiful-dnd';
import OnDeckCard from './card';
import PutIn from './../images/put-in-button.png';
import Sort from './../images/sort.png';


const getListStyle = (isDraggingOver, hasCard) => ({
backgroundColor: "transparent",
  backgroundImage: isDraggingOver ? `url(${PutIn})` : 'none',
  backgroundSize: "75px 75px",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  display: 'block',
  flexWrap: 'wrap',
  margin: '0',
  width: '100%',
  alignItems: 'center',
  borderTop: "3px solid #000",
  padding: "5px 0 0 15px",
  justifyContent: 'center'
});



/* -----------

  Props:
  cardId: id of the card to display
 
   -----------
*/

export default function OnDeckCardHand(props) {
    const w = props.cards === undefined ? `0` : `${(Object.keys(props.cards).length * 30)}px`;
    //console.log('w', w);

    if ( props.control === true ){
        return (                 
          <Droppable droppableId={`cardHand`} key={0} direction="horizontal" isDropDisabled={false}>
            {(provided, snapshot) => (
              <div className="hand" ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver, props.cards !== undefined)}>
                  <div style={{width: w}}>
                { props.cards !== undefined ?
                    Object.keys(props.cards).map((cardId, ind) => {
                        return <OnDeckCard cardId={cardId} key={cardId} index={ind} key={cardId} control={props.control}  visible={!snapshot.isDraggingOver} />
                    })                  
                  : null
                }
                {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>        
      );
      } else {
        return (                 
              <div className="hand" style={getListStyle(false, props.direction === 'vertical' ? true : false, props.boxWidth)}>
                { props.cards !== undefined ?
                    Object.keys(props.cards).map((cardId, ind) => {
                        return <OnDeckCard key={ind} control={props.control} cardId={cardId}/>
                    })                  
                  : null
                }
              </div>
        );
      }
}