import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { signInAnon, anonProfileCreate, validateCode, updateProfileName, endLoading } from '../../store/actions/authActions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Footer from '../Footer';
import LogoHeader from '../Header';
import styles from '../Styles';
import LiquidLoadingBlock from '../Loading/loadingCup';




const INITIAL_STATE = {
  code_validated: false,
  redirectToHome: false,
  redirectToName: false,
  name: '',
  setName: true,
};

class AnonSignIn extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount(){    
    this.props.endLoading();

    if ( this.props.match.params.id) {
        // we have a code, let's verify
        this.props.validateCode( this.props.match.params.id );
    }

    setTimeout(() => {
      /* If they haven't connected after 10 seconds, something's wrong. Try again. */
      if ( this.props.auth.uid !== undefined && this.props.user.isEmpty === true) {
        this.props.anonProfileCreate(this.props.gol_auth);
      }
    }, 10000)
  }

  componentDidUpdate(prevProps, prevState){

    if ( this.props.authCodeValid === true && this.props.authMessage === null ){
        if ( this.props.user.isLoaded === true && this.props.user.isEmpty === true ){
          if ( this.props.user.isEmpty === true && !this.props.auth.uid ){
            this.props.signInAnon( this.props.match.params.id );
          } else {}
        } else {
          if ( this.props.auth.uid !== undefined && this.props.user.isEmpty !== true ){
            this.setState({
              ...this.state,
              redirectToName: true
            });
          }
        } 
    }

    if ( this.props.authMessage === 'SIGNOUT_SUCCESS' && prevProps.authMessage !== this.props.authMessage ){
        if ( this.props.authCodeValid ){
            this.props.signInAnon();
        }
    }

    if ( this.props.authMessage === 'LOGIN_SUCCESS' && prevProps.authMessage !== this.props.authMessage ){
        // we have just logged in
        setTimeout(() => {        
          this.props.anonProfileCreate(this.props.gol_auth);
        }, 1500);
    }


    if ( this.props.authMessage === 'LOGIN_PROFILE_CREATED' && prevProps.authMessage !== this.props.authMessage ){
        // we have just logged in
        this.setState({
            ...this.state,
            redirectToName: true
        })
    }
  }

  render() {
    const { t, classes } = this.props;

    /* MUST BE AT TOP */
    if ( this.state.redirectToHome ){
        return <Redirect to={ROUTES.LANDING} />;
    }
    if ( this.state.redirectToName ){
        return <Redirect to={ROUTES.CHANGE_NAME} />;
    }
    if ( this.props.authCodeValid === true ){
        return (
            <div className='meta-react'>
              <LogoHeader t={t} />
              <div className='outer-container'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-12' align="center">
                      <Typography variant="h3">Code Validated</Typography>
                      <Typography variant="body1">Joining Session...</Typography>
                      <div className={classes.spacingTop}>
                      <LiquidLoadingBlock />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
        );
    }  

    if ( this.props.authCodeValid === false ){
        return (
            <div className='meta-react'>
              <LogoHeader t={t} />
              <div className='outer-container'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-12' align="center">
                      Invalid Code.
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
        );
    }

    /* catch all return */
    return (
        <div className='meta-react'>
          <LogoHeader t={t} />
          <div className='outer-container'>
            <div className='container'>
              <div className='row'>
                <div className='col-12' align="center">
                  <Typography variant="h3">Validating Code</Typography>
                  <Typography variant="body1" className={classes.spacingTop}>One moment please...</Typography>                      
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
    );
  } 
}

AnonSignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    user: state.firebase.profile,
    gol_auth: state.gol_auth,
    authError: state.gol_auth.authError,
    authMessage: state.gol_auth.authMessage,
    authPopUp: state.gol_auth.authPopUp,
    authErrorPopUp: state.gol_auth.authErrorPopUp,
    authCodeValid: state.gol_auth.authCodeValid,
    sessionHost: state.gol_auth.sessionHost,
    authCode: state.gol_auth.authCode,
    isLoading: state.gol_auth.isLoading,
    setName: state.gol_auth.setName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signInAnon: (creds) => dispatch(signInAnon(creds)),
    anonProfileCreate: (a) => dispatch(anonProfileCreate(a)),
    updateProfileName: (creds) => dispatch(updateProfileName(creds)),
    endLoading: () => dispatch(endLoading()),
    validateCode: (code) => dispatch(validateCode(code))
  };
};

export default compose(withTranslation(), withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(AnonSignIn);