import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as LANGUAGES from './constants/languages';


import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


const fallbackLng = [LANGUAGES.ENGLISH];
const availableLanguages = [LANGUAGES.ENGLISH];

i18n
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: fallbackLng,
        whitelist: availableLanguages,
        debug: true,
        //saveMissing: true, // send not translated keys to endpoint

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }

    });


export default i18n;