import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import TangramMS from './../specific_components/tangram';
import {  determineHost } from '../../game_functions/functions';
import ImgPuzzleClue from './../images/puzzleClue1.png';
import { moveBackToResultsPhase, moveBackToActiveWithParams, updateGroupStateStateVal, moveBackToInitPhaseWithOption } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Box from '@material-ui/core/Box';
import ActivityOptions from './../../../activityOptions';
import Button from '@material-ui/core/Button'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ComparisonTable } from './../specific_components/comparisonTable';
import { ComparisonTapTable } from './../specific_components/comparisonTapTable';
import { gameOptions } from './../../../gameServer';
import distortIcon from '../images/Logo.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
import { PDFHeader } from '../../../../PastData/PDFHeader';



const INITIAL_STATE = {
    open: false,
    tab: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    dialogOpen: false,
    newPuzzleId: null,
    newPuzzleTitle: null
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other} >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    handleTabChange = (event, newValue) => {
        this.setState({
          ...this.state,
          tab: newValue
        })
      };

    setOption = (id, title) => {
        this.setState({
            ...this.state,
            dialogOpen: true,
            newPuzzleId: id,
            newPuzzleTitle: title
        })
    }

    handleDialogClose = () => {
        this.setState({
            ...this.state,
            dialogOpen: false
        })
    }


    render() {
        const { results, profiles, classes, session, game_options } = this.props;
        console.log('results:', results, game_options);

        

        let oImg = null;
        let p = (results.Puzzle === undefined) ? game_options.game_misc.Puzzle : results.Puzzle;
        let g = gameOptions(results.id);
        if ( p !== undefined ){
            g['Puzzle'].options.map((option, ind) => {
                if ( option.id === parseInt(p) ){
                    oImg = option.img;
                } 
                return null;
            })
        }

        const theImg = oImg;


        if( this.props.isPDFView === true ){

            return (
                <Document>
                     <Page wrap style={{padding: "30 0"}}>
                        <PDFHeader/>
                        <View>
                            <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={distortIcon}/>
                        {Object.keys(results.results).sort().map((groupKey, ind) => { 
                            let minutes = 0;
                            let secondsElapsed = 0;
                            let lastSecondsElapsed = 0;
                            let remainder = 0;
                            let timeelapsed = 0;
                            let time = "";

                            let timeMessage = "";
                            let lastMessage = "";

                            if (results.results[groupKey].finishedIn !== undefined ){

                                secondsElapsed = results.results[groupKey].finishedIn;
                                minutes = Math.floor(secondsElapsed / 60);
                                remainder = secondsElapsed % 60;

                                time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds`;
                                timeMessage = `${time}`;

                            } else {
                                timeMessage = "Puzzle not completed.";
                            }

                            if ( results.resultSnapshot[groupKey] === undefined ){
                            lastMessage = "N/A";
                            lastSecondsElapsed = 0;
                            } else {
                            if ( results.resultSnapshot[groupKey].finishedIn !== undefined ){
                                lastSecondsElapsed = results.resultSnapshot[groupKey].finishedIn;
                                minutes = Math.floor(lastSecondsElapsed / 60);
                                remainder = lastSecondsElapsed % 60;

                                time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${lastSecondsElapsed} seconds`;
                                lastMessage = `${time}`;
                            } else {
                                lastMessage = "Puzzle not completed.";
                            }

                            }
                            
                            let diff = '';
                            if ( lastMessage === "N/A" ){
                            diff = 'N/A';
                            } else {
                            diff = (secondsElapsed - lastSecondsElapsed);
                            }

                            if ( timeMessage === 'Puzzle not completed.' || lastMessage === 'Puzzle not completed.'){
                            diff = "N/A";
                            }

                            if ( diff !== 'N/A' ){
                                
                                minutes = Math.floor(Math.abs(diff) / 60);
                                remainder = lastSecondsElapsed % 60;

                                diff = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${lastSecondsElapsed} seconds`;
                            }
                            let thisTapIns = 0;
                            let lastTapIns = 0;
                            let timeToFirstTapIn = 0;
                            let lastTimeToFirstTapIn = 0;

                            let avgTapTime = 0;
                            let lastAvgTapTime = 0;

                            let tapminutes = 0;
                            let tapremainder = 0;
                            let taptime = '';
                        

                            let tapdiff = 0;
                            let lastDiff = 0;
                            
                            if ( results.results[groupKey] !== undefined ){
                            if ( results.results[groupKey].tapins !== undefined ){
                                thisTapIns = Object.keys(results.results[groupKey].tapins).length;

                                let timeBetween = [];
                                let lastTime = 0;
                                Object.keys(results.results[groupKey].tapins).sort().map((key, index) => {
                                if (index === 0){
                                    timeToFirstTapIn = (key - results.results[groupKey].startedOn) / 1000;
                                } 

                                if ( index === 0 ){
                                    timeBetween.push( timeToFirstTapIn );
                                } else {
                                    timeBetween.push ( Math.abs( key - lastTime ) / 1000 );
                                }
                                lastTime = key;
                                });

                                var total = 0;
                                for(var i = 0; i < timeBetween.length; i++) {
                                    total += timeBetween[i];
                                }
                                avgTapTime = total / timeBetween.length;

                                tapminutes = Math.floor(avgTapTime / 60);
                                tapremainder = Math.round(avgTapTime) % 60;

                                taptime = minutes > 0 ? `${tapminutes} minute(s) and ${tapremainder} seconds` : `${Math.round(avgTapTime)} seconds`;
                                avgTapTime = `${taptime}`;

                                tapminutes = Math.floor(timeToFirstTapIn / 60);
                                tapremainder = Math.round(timeToFirstTapIn) % 60;

                                taptime = tapminutes > 0 ? `${tapminutes} minute(s) and ${tapremainder} seconds` : `${Math.round(timeToFirstTapIn)} seconds`;
                                timeToFirstTapIn = `${taptime}`;

                            } else {
                                avgTapTime = "N/A";
                                timeToFirstTapIn = "N/A";
                            }

                            } else {
                            thisTapIns = "N/A";
                            timeToFirstTapIn = "N/A";
                            avgTapTime = "N/A";
                            }

                            if ( results.resultSnapshot[groupKey] !== undefined ){
                            if ( results.resultSnapshot[groupKey].tapins !== undefined ){
                                lastTapIns = Object.keys(results.resultSnapshot[groupKey].tapins).length;

                                let timeBetween = [];
                                let lastTime = 0;
                                Object.keys(results.resultSnapshot[groupKey].tapins).sort().map((key, index) => {
                                if (index === 0){
                                    lastTimeToFirstTapIn = (key - results.resultSnapshot[groupKey].startedOn) / 1000;
                                } 

                                if ( index === 0 ){
                                    timeBetween.push( lastTimeToFirstTapIn );
                                } else {
                                    timeBetween.push ( Math.abs( key - lastTime ) / 1000 );
                                }
                                lastTime = key;

                                var total = 0;
                                for(var i = 0; i < timeBetween.length; i++) {
                                    total += timeBetween[i];
                                }
                                lastAvgTapTime = total / timeBetween.length;

                                minutes = Math.floor(lastAvgTapTime / 60);
                                remainder = Math.round(lastAvgTapTime) % 60;

                                time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${Math.round(lastAvgTapTime)} seconds`;
                                lastAvgTapTime = `${time}`;

                                });
                                } else {
                                    lastTimeToFirstTapIn = "N/A";
                                    lastAvgTapTime = "N/A";
                                }
                                } else {
                                    lastTapIns = "N/A";
                                    lastTimeToFirstTapIn = "N/A";
                                    lastAvgTapTime = "N/A";
                                }

                                if ( lastTapIns === "N/A" ){
                                    tapdiff = "N/A";
                                } else {
                                    tapdiff = thisTapIns - lastTapIns;
                                }

                            if ( lastTimeToFirstTapIn !== 'N/A' ){
                                tapminutes = Math.floor(lastTimeToFirstTapIn / 60);
                                tapremainder = Math.round(lastTimeToFirstTapIn) % 60;
                                taptime = tapminutes > 0 ? `${tapminutes} minute(s) and ${tapremainder} seconds` : `${Math.round(lastTimeToFirstTapIn)} seconds`;
                                lastTimeToFirstTapIn = `${taptime}`;
                            }
              

                            return (
                            <React.Fragment key={ind}>
                                <View style={{marginHorizontal: "50", marginBottom: "40"}} wrap={false}>
                                    {/* Comparison table */}
                                    <Text style={{marginBottom: "10", fontSize: "20pt"}}>Puzzle Times</Text> 
                                    <View style={{marginVertical: "5", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(000, 000, 000)"}}>
                                        <View style={{ width:'15%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>GROUP</Text> 
                                        </View>
                                        <View style={{ width:'30%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>LAST ROUND</Text> 
                                        </View>
                                        <View style={{ width:'30%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>THIS ROUND</Text> 
                                        </View>
                                        <View style={{ width:'25%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>DIFFERENCE</Text> 
                                        </View>
                                    </View>
                                    <View style={{marginTop: '5', marginBottom: "10", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(255, 255, 255)"}}>
                                        <View style={{ width:'15%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}>{results.groups[groupKey].title}</Text> 
                                        </View>
                                        <View style={{ width:'30%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}>{lastMessage}</Text> 
                                        </View>
                                        <View style={{ width:'30%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}>{timeMessage}</Text> 
                                        </View>
                                        <View style={{ width:'25%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}>{diff}</Text> 
                                        </View>
                                    </View> 

                                   {/* Comparison tap table */}
                                   <Text style={{marginBottom: "10", fontSize: "20pt"}}>Tap-Ins</Text> 
                                    <View style={{marginVertical: "5", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(000, 000, 000)"}}>
                                        <View style={{ width:'15%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>GROUP</Text> 
                                        </View>
                                        <View style={{ width:'30%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>LAST ROUND</Text> 
                                        </View>
                                        <View style={{ width:'30%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>THIS ROUND</Text> 
                                        </View>
                                        <View style={{ width:'25%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>DIFFERENCE</Text> 
                                        </View>
                                    </View>
                                    <View style={{marginTop: '5', marginBottom: "10", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(255, 255, 255)"}}>
                                        <View style={{ width:'15%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}>{results.groups[groupKey].title}</Text> 
                                        </View>
                                        <View style={{ width:'30%', textAlign: 'left', flexDirection: 'column'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}>{lastTapIns} Tap-Ins</Text>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}> Avg Time Between Tap-Ins: {avgTapTime === "N/A" ? avgTapTime : `${avgTapTime}`}</Text>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}> Time Until First Tap-In:        {lastTimeToFirstTapIn === "N/A" ? lastTimeToFirstTapIn : `${lastTimeToFirstTapIn}`}</Text> 
                                        </View>
                                        <View style={{ width:'30%', textAlign: 'left'}}>
                                        <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}>{thisTapIns} Tap-Ins</Text>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}> Avg Time Between Tap-Ins: {lastAvgTapTime === "N/A" ? lastAvgTapTime : `${lastAvgTapTime}`}</Text>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}> Time Until First Tap-In:        {timeToFirstTapIn === "N/A" ? timeToFirstTapIn : `${timeToFirstTapIn}`}</Text> 
                                        </View>
                                        <View style={{ width:'25%', textAlign: 'left'}}>
                                           <Text style={{ fontSize: '11pt', marginVertical: '10', marginLeft: '10'}}>{tapdiff}</Text> 
                                        </View>
                                    </View>                                    
                                </View>
                            </React.Fragment>       
                            );
                        })}
                        </View>
                    </Page>
                </Document> 
            ) 
        }

        return (
            <div>
                 <Dialog
                    open={this.state.dialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to restart the activity and use the <span style={{fontWeight: "bold"}}>{this.state.newPuzzleTitle}</span> puzzle?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleDialogClose} variant="contained" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.endGame} variant="contained" color="primary">
                        Confirm
                    </Button>
                    </DialogActions>
                </Dialog>
                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
                <Grid container>
                    <Grid item xs={6}>
                        <img src={theImg} alt='puzzle clue' className={classes.imageSize} style={{maxWidth: "300px"}}/>                        
                    </Grid>
                    <Grid item xs={6}>    
                        <ComparisonTable results={results}/>
                        <ComparisonTapTable results={results}/>
                    </Grid>
                </Grid>
                
                <Paper square className={classes.root}  style={{minHeight: "700px", minWidth: "920px"}}>
                    <AppBar position="static">
                    <Tabs
                    value={this.state.tab}
                    onChange={this.handleTabChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    aria-label="icon label tabs example" >
                    { Object.keys(results.groups).sort().map((groupKey, ind) => {
                        if ( groupKey !== 'group-0' ){
                            return (
                                <Tab label={results.groups[groupKey].title} key={ind-1} />
                            )
                        } else {
                            return null;
                        }
                    })}
                    </Tabs>
                    </AppBar>
                    { Object.keys(results.groups).sort().map((groupKey, ind) => {
                         if ( groupKey !== 'group-0' ){
                            let minutes = 0;
                            let secondsElapsed = 0;
                            let remainder = 0;
                            let timeelapsed = 0;
                            if ( results.groupStates[groupKey].params.finishedOn !== undefined ){
                                timeelapsed = results.groupStates[groupKey].params.finishedOn - results.groupStates[groupKey].params.startedOn;
                                secondsElapsed = Math.floor(timeelapsed / 1000);
                                minutes = Math.floor(secondsElapsed / 60);
                                remainder = secondsElapsed % 60;
                            } 
                            return (
                                <TabPanel value={this.state.tab} index={ind-1} key={ind-1} >
                                    <Typography variant="body1">Puzzle completed in <span style={{fontWeight: "bold"}}>{ minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds` }</span>.</Typography>
                                    <TangramMS results={results} group_id={groupKey} host={null} role='Engineer'/>
                                </TabPanel>
                            )
                        } else {
                            return null;
                        }  
                    })}                    
                </Paper>
                
            </div>
        )   
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params)),
    moveBackToInitPhaseWithOption : (session,host,optionName,optionVal) => dispatch(moveBackToInitPhaseWithOption(session,host,optionName,optionVal))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);