import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import styles from '../../../../Styles';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
});

class GridBlock extends Component {
  
  render() {
    const { classes } = this.props;
    if ( this.props.control === true ){
      return (      
        <Draggable draggableId={`${this.props.blockid}`} index={parseInt(this.props.blockid)}>
        {(provided, snapshot) => (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                <div className={`box ${classes.gridB}`} style={{width: this.props.boxWidth, height: this.props.boxWidth, backgroundColor: this.props.bgColorTable[this.props.blockid]}} key={this.props.index} data-id={this.props.blockid}/>
          </div>
          )}
        </Draggable>      
      );
    } else {
      return (      
        <div className={`box ${classes.gridB}`} style={{width: this.props.boxWidth, height: this.props.boxWidth, backgroundColor: this.props.bgColorTable[this.props.blockid]}} key={this.props.index} data-id={this.props.blockid}/>
      );
    }
  }
}

GridBlock.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridBlock);
