import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './../../store';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LiquidLoadingBlock from '../Loading/loadingCup';
import EmotionalIntelligence from '../Game/games/emotionalIntelligence';
import WalkAwhileInMyShoes from '../Game/games/walkAwhileInMyShoes';
import ExpectationsAndAccountabilities from '../Game/games/expectationsAndAccountabilities';
import DiscCards from '../Game/games/discCards';
import CircleOfInfluence from '../Game/games/circleOfInfluence';
import BBSS from '../Game/games/bbss.js';
import Distortion from '../Game/games/distortion';
import Aggregate from '../Game/games/aggregate';
import Journey from '../Game/games/journey';
import Journey2 from '../Game/games/journey2';
import Feedback from '../Game/games/feedback';
import CircleWords from '../Game/games/circleWords';
import ManagingTime from '../Game/games/managingTime';
import DiscussionForum from '../Game/games/discussionForum';
import DiscussionForumUniversity from '../Game/games/discussionForumUniversity';
import TrustSpectrum from '../Game/games/trustSpectrum';
import CommunicationStyles from '../Game/games/communicationStyles';
import TrustSpectrumIndividual from '../Game/games/trustSpectrumIndividual';
import TransitionPeerToBoss from '../Game/games/transitionPeerToBoss';
import DiscConflict from '../Game/games/discConflict';
import BestWorstBoss from '../Game/games/bestWorstBoss';
import DiscussionForumShowcase from '../Game/games/discussionForumShowcase';
import Personify from '../Game/games/personify';
import CountyFair from '../Game/games/countyFair';
import TrustBehaviors from '../Game/games/trustBehaviors';
import ShowcaseFeedbackDiscussion from '../Game/games/showcaseFeedbackDiscussion';
import OnDeck from '../Game/games/ondeck';
import FindingThePoint from '../Game/games/seeingThePoint';
import EngagingStakeholders from '../Game/games/engagingStakeholders';
import LearningTransfer from '../Game/games/learningTransfer';
import styles from '../Styles';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  PDFDownloadLink
} from "@react-pdf/renderer";
import {PDFView} from './pdfView';


/* 
Export PDF of results - Create a template page
Priority for PDFs:
- Discussion Form - VERY HIGH
- Feedback - VERY HIGH
- Tell us about the journey
- Bring Brag
- Communication styles
- Emotional intelligence
- Transition Peer to boss
*/


class ActivityResults extends Component {
  
  
  viewAsPDF = () => {
    
  }


  render() {
    const { results, profiles, isPDFView, groups } = this.props;

    console.log('options', this.props.game_options);

    let options = this.props.game_options !== undefined ? this.props.game_options[results.id] !== undefined ? this.props.game_options[results.id] : false : false;
    if ( options !== false ){
      let host = this.props.host;
      options = options[host] !== undefined ? options[host] : options.default;
    }

    console.log('res', results);
    switch (results.id) {
      case 'Cd43w7C4VTHMhxaCefY8':
        // Learning Transfer
        return <div id="capture">
            { isPDFView ? 
              <PDFView title={`Discussion_Forum_Showcase`}>
                <LearningTransfer results={results}  profiles={profiles}  isPDFView={true} host={this.props.host} game_options={options}  groups={groups}/>
              </PDFView>
              :
              null }
            <LearningTransfer results={results}  profiles={profiles}  isPDFView={false} host={this.props.host} game_options={options}  groups={groups}/>
            </div>
      case 'eGS0SyLeRo5kSeDh4Vom':
        // Engaging Stakeholders
        return <div id="capture">
            { isPDFView ? 
              <PDFView title={`Discussion_Forum_Showcase`}>
                <EngagingStakeholders results={results}  profiles={profiles}  isPDFView={true} host={this.props.host} game_options={options} groups={groups}/>
              </PDFView>
              :
              null }
            <EngagingStakeholders results={results}  profiles={profiles}  isPDFView={false} host={this.props.host} game_options={options} groups={groups}/>
            </div>
      case 'bJhzgqZsI9lzXZFsMHdi':
        // Discussion Forum (Showcase)
          return <div id="capture">
            { isPDFView ? 
              <PDFView title={`Discussion_Forum_Showcase`}>
                <DiscussionForumShowcase results={results}  profiles={profiles}  isPDFView={true} host={this.props.host} game_options={options}/>
              </PDFView>
              :
              null }
            <DiscussionForumShowcase results={results}  profiles={profiles}  isPDFView={false} host={this.props.host} game_options={options}/>
            </div>
        case '6HkVJoZZBa6JeZYrNKoz':
        // Best/Worst Boss
          return <div id="capture"><BestWorstBoss results={results}  profiles={profiles}  isPDFView={false} host={this.props.host} game_options={options}/></div>;
        case 'FUm6uMZ9QhLkbsnmEbQN':
          // Trust Spectrum Individual
          return <div id="capture"><TrustSpectrumIndividual results={results}  profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/></div>;
        case 'oAOQt7YfZzDTa2WiQ7ZR':
          // Trust Spectrum
          return <div id="capture">
            { isPDFView ? 
                <PDFView title={`Trust_Spectrum`}>
                  <TrustSpectrum results={results}  profiles={profiles} isPDFView={true}  host={this.props.host} game_options={options}/>
                </PDFView>
              :
              null }
            <TrustSpectrum results={results}  profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/>
            </div>;
        case 'vcZv6f1cOktHSds2kdme':
          // Emotional Intelligence
          return <div id="capture">
            { isPDFView ? 
                <PDFView title={`Emotional_Intelligence`}>
                  <EmotionalIntelligence results={results} profiles={profiles} isPDFView={true} host={this.props.host} game_options={options} />
                </PDFView>
              :
              null }
            <EmotionalIntelligence results={results} profiles={profiles} isPDFView={false} host={this.props.host} game_options={options} />
            </div>;  
        case 'QFbnz1m3ydc20VbuGVHP':
          // Bring, Brag, Show, Steal        
            return <div id="capture">
              { isPDFView ? 
                <PDFView title={`Bring_Brag_Share_Steal`}>
                    <BBSS results={results}  profiles={profiles} isPDFView={true} host={this.props.host} game_options={options} />
                </PDFView>
              :
              null }
              <BBSS results={results} profiles={profiles}  isPDFView={false} host={this.props.host} game_options={options} />
              </div>;      
        case 'YCSJs4yYGYTK5N6sLjZG':
          // Walk a While in my Shoes
          return <div id="capture">
            { isPDFView ? 
              <PDFView title={`Walk_A_While_In_My_Shoes`} >
                <WalkAwhileInMyShoes results={results} profiles={profiles}  isPDFView={true} host={this.props.host} game_options={options}/>
              </PDFView>
            :
            null }
            <WalkAwhileInMyShoes results={results} profiles={profiles}  isPDFView={false} host={this.props.host} game_options={options}/>
            </div>;  
        case 'VoJYJGwzxiUQap9ZJZEu':
          // DISC Cards
          return <div id="capture">
            { isPDFView ?
            <PDFView title={`DISC_Work_Of_Leaders`}> 
              <DiscCards results={results}  profiles={profiles}  isPDFView={true} host={this.props.host} game_options={options}/>
            </PDFView>
            :
            null}
            <DiscCards results={results}  profiles={profiles}  isPDFView={false} host={this.props.host} game_options={options}/>
            </div>;
        case 'PiFZGYehVialCu1eRDvt':
          // Communication Styles
          return <div id="capture">
            { isPDFView ?
            <PDFView title={`Communication_Styles`}> 
              <CommunicationStyles results={results}  profiles={profiles}  isPDFView={true} host={this.props.host} game_options={options} />
            </PDFView>
            :
            null}
            <CommunicationStyles results={results}  profiles={profiles}  isPDFView={false} host={this.props.host} game_options={options} />
            </div>;
        case '3f19Vfx31DCuCRC9W3Fw':
          // Circle of Influence
          return <div id="capture">
            { isPDFView ?
            <PDFView title={`Circle_Of_Influence`}>
              <CircleOfInfluence results={results} profiles={profiles} isPDFView={true} host={this.props.host}  game_options={options}/> 
            </PDFView>
            :
            null}
            <CircleOfInfluence results={results} profiles={profiles} isPDFView={false} host={this.props.host}  game_options={options}/>
          </div>;

        case 'i9VYXjMHierYCCFwcDvV':
          // Expectations and Accountabilities
          return <div id="capture">
            { isPDFView ? 
              <PDFView title={`Expectations_and_Accountabilities`} >
                <ExpectationsAndAccountabilities results={results}  profiles={profiles}  isPDFView={true} host={this.props.host} game_options={options} />
              </PDFView>
            :
            null }
            <ExpectationsAndAccountabilities results={results}  profiles={profiles}  isPDFView={false} host={this.props.host} game_options={options} />
            </div>;
        case '7xgKmmwM9RlvOmYtY9Qc':
            // Tell Us About The Journey
            return <div id="capture">
              { isPDFView ? 
              <PDFView title={`Tell_Us_About_The_Journey_Moduals_1-2`} >
                <Journey results={results} profiles={profiles} isPDFView={true} host={this.props.host}  game_options={options}/>
              </PDFView>
            :
            null }
              <Journey results={results} profiles={profiles} isPDFView={false} host={this.props.host}  game_options={options}/>
              </div>;
        case 'XHfImKbo8S2UDPl2cerz':
          // Tell Us About The Journey - Mod 3&4
          return <div id="capture">
            { isPDFView ? 
              <PDFView title={`Tell_Us_About_The_Journey_Moduals_3-4`} >
                <Journey2 results={results} profiles={profiles} isPDFView={true} host={this.props.host}  game_options={options}/>
              </PDFView>
            :
            null }
            <Journey2 results={results} profiles={profiles} isPDFView={false} host={this.props.host}  game_options={options}/>
            </div>;
        case '6MK8K4f5uenoAFBBShto':
          // Possibly Aggregate
          return <div id="capture"><Aggregate results={results} profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/></div>;
        case 'CFDb34wvDq0sC5BWfMPL':
          // Managing Time
          return <div id="capture">
            { isPDFView ? 
                <PDFView title={`Managing_Time`}>
                  <ManagingTime results={results}  profiles={profiles}  isPDFView={true} host={this.props.host} game_options={options}/>
                </PDFView>
              :
              null }
            <ManagingTime results={results}  profiles={profiles}  isPDFView={false} host={this.props.host} game_options={options}/>
            </div>;
        case 'VzVPeJxDCqc2K5Juo14e':
          // Distortion
          return <div id="capture">
            { isPDFView ? 
              <PDFView title={`Distortion`} >
                <Distortion results={results}  profiles={profiles}  isPDFView={true} host={this.props.host}  game_options={options}/>
              </PDFView>
            :
            null }
            <Distortion results={results}  profiles={profiles}  isPDFView={false} host={this.props.host}  game_options={options}/>
            </div>;  
        case 'JP2iV9t6J2KGkX7hDQIb':
          // Discussion Forum
          return <div id="capture">
          { isPDFView ? 
            <PDFView title={`Discussion_Forum_University`} >
                <DiscussionForumUniversity results={results} profiles={profiles} isPDFView={true} host={this.props.host} game_options={options} />
            </PDFView>
          :
          null }
          <DiscussionForumUniversity results={results}  profiles={profiles}  isPDFView={false} host={this.props.host}  game_options={options}/>
          </div>;    
        case 'zjgMvCBlfUo25Xalulti':
            // Discussion Forum
            return <div id="capture">
            { isPDFView ? 
              <PDFView title={`Discussion_Forum`} >
                  <DiscussionForum results={results} profiles={profiles} isPDFView={true} host={this.props.host} game_options={options} />
              </PDFView>
            :
            null }
            <DiscussionForum results={results}  profiles={profiles}  isPDFView={false} host={this.props.host}  game_options={options}/>
            </div>;
        case 'ojQicb2jdFh77nNPn3Tv':
          // Feedback
          return <div id="capture">
          { isPDFView ? 
            <PDFView title={`Feedback`} >
                <Feedback results={results} profiles={profiles} isPDFView={true} host={this.props.host} game_options={options} />
            </PDFView>
          :
          null }
          <Feedback results={results}  profiles={profiles}  isPDFView={false} host={this.props.host}  game_options={options}/>
          </div>;
        case 'gMuD2xH6Kl37u9WbxYxN':
            // DiSC Conflict
          return <div id="capture">
            { isPDFView ?
            <PDFView title={`DiSC_Conflict`}> 
              <DiscConflict results={results}  profiles={profiles} isPDFView={true} host={this.props.host}  game_options={options}/>
            </PDFView>
            :
            null}
            <DiscConflict results={results}  profiles={profiles} isPDFView={false} host={this.props.host}  game_options={options}/>
            </div>;
        case '4njCJXU1wrNjpWRToxvo':
          // Circle Words
          return <div id="capture">
            { isPDFView ?
              <PDFView title={`Circle_Words`} >
                <CircleWords results={results}  profiles={profiles} isPDFView={true}  host={this.props.host} game_options={options}/>
              </PDFView>
              :
              null}
            <CircleWords results={results}  profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/>
            </div>;
        case 'd0UzzPqrpkeiqdmONmYD':
          // Transition Peer to Boss
          return <div id="capture">
            { isPDFView ?
              <PDFView title={`Transition_Peer_To_Boss`} >
                <TransitionPeerToBoss results={results}  profiles={profiles} isPDFView={true}  host={this.props.host} game_options={options}/>
              </PDFView>
              :
              null}
            <TransitionPeerToBoss results={results}  profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/>
            </div>;
        case 'f3Ec5g6amsoAaFr34U9J':
          // Personify
          return <div id="capture">
            <Personify results={results}  profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/>
            </div>;
        case 'hKfvgwPfyOg1DKZ5WrFf':
          // County Fair
          return <div id="capture">
            { isPDFView ?
              <PDFView title={`County_Fair`} >
                <CountyFair results={results}  profiles={profiles} isPDFView={true}  host={this.props.host} game_options={options}/>
              </PDFView>
              :
              null}
            <CountyFair results={results}  profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/>
            </div>;
        case 'zj8FYS7xS9bAqZRKsxDu':
          // Trust Behaviors
          return <div id="capture">
            { isPDFView ?
              <PDFView title={`Trust_Behaviors`} >
                <TrustBehaviors results={results}  profiles={profiles} isPDFView={true}  host={this.props.host} game_options={options}/>
              </PDFView>
              :
              null}
            <TrustBehaviors results={results}  profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/>
            </div>;
        case 'uTeZJmY1zc6PDDvMC1Xk':
        // Showcase Feedback Discussion
          return <div id="capture">
            { isPDFView ?
              <PDFView title={`Showcase_Feedback_Discussion`} >
                <ShowcaseFeedbackDiscussion results={results}  profiles={profiles} isPDFView={true}  host={this.props.host} game_options={options}/>
              </PDFView>
              :
              null}
            <ShowcaseFeedbackDiscussion results={results}  profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/>
            </div>;
        case 'I5vxqN2knisqFS2wJ3Hk':
          // On Deck
          return <div id="capture"><OnDeck results={results}  profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/></div>;
        case 'Rh4R3HbDo5J2WetudJrn':
          // FindingThePoint
          return <div id="capture"><FindingThePoint results={results}  profiles={profiles} isPDFView={false}  host={this.props.host} game_options={options}/></div>;
        
          default:
          return null;
    }
    return null;
  }

}

const mapStateToProps = (state) => {
  return {
   
  }
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ActivityResults);