/**********************************************************************
 *                                                                    *
 * File Name:     SessionData                                         *
 * Purpose:       Component to handle the showing of session data     *
 * Author:        Ben Wakutz                                          *
 * Last Modified: 03/10/21                                            *
 *                                                                    *
 **********************************************************************/
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Footer from '../Footer';
import LogoHeader from '../Header';
import styles from '../Styles';
import PropTypes from 'prop-types';
import { Typography,TableContainer, Grid, Table, TableBody, TableCell, Collapse, TableHead, TableRow, Box, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, TextField, } from '@material-ui/core';
import {renameSession, removeSession, removeRecord, removeActiveRecord} from '../../store/actions/adminActions';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import ActivityResults from './activityResults';
import { accessCheck } from './../Functions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import GroupIcon from '@material-ui/icons/Group';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const INITIAL_STATE = {
  copied: false,
  valid: true,
  nameDialog: false,
  newName: '',
  targetID:'',
  rowOpen:{},
  activityGroups: null,
  activityResults: null,
  activityProfiles: null,
  isPDFView: false
};

export function getHost(profile, auth) {
    return profile.role === 'host' ? auth.uid : profile.sessionHost;
}

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

class SessionData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };
  }

  pxTomm = (px) => {
    console.log('...', parseInt(window.getComputedStyle(document.querySelector("#capture")).height.replace('px', '')));
    return Math.floor(px / parseInt(window.getComputedStyle(document.querySelector("#capture")).height.replace('px', '')));
  }

  _exportPdf = () => {
    this.setState({
      ...this.state,
      isPDFView: true
    })
    /*html2canvas(document.querySelector("#capture"), {allowTaint: true}).then(canvas => {

      var aheight = parseInt(window.getComputedStyle(document.querySelector("#capture")).height.replace('px', ''));
      var awidth = parseInt(window.getComputedStyle(document.querySelector("#capture")).width.replace('px', ''));
      
      const imgData = canvas.toDataURL('image/jpeg');
      const pdfDOC = new jsPDF("p", "mm", "a0"); //  use a4 for smaller page
  
      const width = pdfDOC.internal.pageSize.getWidth();
      let height = pdfDOC.internal.pageSize.getHeight();
     
      console.log('The PDF Data: ', aheight, awidth, width, height);
  
      pdfDOC.addImage(imgData, 'JPEG', 20, 10, width - 40, height - 20);
      pdfDOC.save('summary.pdf');   //Download the rendered PDF.

      var a = document.createElement('a');
      a.href = imgData.replace("image/jpeg", "image/octet-stream");
      a.download = 'test.jpg';
      a.click();

      
   });*/

  }

  removeSession = (docid, hostid) => {
    console.log('Removing Session:', docid, hostid);
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to remove this Session? This action is not able to be undone.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.removeSession(docid, hostid)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  
  }

  removeRecord = (docid, hostid, record) => {
    console.log('Removing Record:', docid, hostid, record);
    //if ( confirm('Are you sure you want to remove this Session record? This action is not able to be undone.') ){
      confirmAlert({
        title: 'Confirm Delete',
        message: 'Are you sure you want to remove this Session Activity Record? This action is not able to be undone.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.props.removeRecord(docid, hostid, record)
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
      
    //}
  }

  removeActiveRecord = (docid, hostid) => {
    console.log('Removing Sessions Active Record:', docid, hostid);
    //if ( confirm('Are you sure you want to remove this Session record? This action is not able to be undone.') ){
      confirmAlert({
        title: 'Confirm Delete',
        message: 'Are you sure you want to remove this Session Activity Record? This action is not able to be undone.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.props.removeActiveRecord(docid, hostid)
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
      
    //}
  }

  viewPlayers = (profiles) => {
    console.log('Player Profiles:', profiles);
    let p = "<ul class='browser-default'>";
    Object.keys(profiles).forEach((profile) => {
      p += "<li>" + profiles[profile].displayName + "</li>";
    });
    p += "</ul>";

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h3>Players</h3>
            <div dangerouslySetInnerHTML={ { __html: `${p}` } }></div>
            <button onClick={onClose}>Close</button>
          </div>
        );
      }
    });
  }

  createSession = (event) => {
    event.preventDefault();
    this.props.maybeCreateSession(this.props.profile);
  };

  endSession = (event) => {
    event.preventDefault();
    this.props.endSession();
  };

  onChange = (event) => {
    this.setState({ ...this.state,
      [event.target.name]: event.target.value });
  };

  handleClose = (event) => {
    this.setState({
      ...this.state,
      copied: false
    });
  }

  handleDialogClose =() =>{
    this.setState({
      ...this.state,
      nameDialog: false});
  };

  closePopUps =() =>{
    this.setState({
      ...this.state,
      adminErrorPopUp: false,
      adminPopUp: false,
    });
  };

  handleSubmit=(host,doc,newname)=>{
      this.props.renameSession(host, doc, newname);
      this.handleDialogClose();
  };

  getNewSessionName=(docid)=>{
    this.setState({
        ...this.state,
        nameDialog:true,
        targetID: docid
    })
  };

  toggleOpen=(uid) =>{

    if ( this.state.rowOpen[uid] === true ){
      this.setState({
        ...this.state,
        rowOpen: {
          ...this.state.rowOpen,
          [uid]: false
        }
      })
    } else {
      this.setState({
        ...this.state,
        rowOpen: {
          ...this.state.rowOpen,
          [uid]: true
        }
      })
    }
  };

  viewResults=(res, profiles, groups, session, docid) =>{
    //TO DO - PLUG IN VIEW CODE
    //console.log('YOU HAVE VIEWED RESULTS');
    console.log('groups', groups, session, docid);
    this.setState({
      ...this.state,
      activityResults: res,
      activityProfiles: profiles,
      activityGroups: groups,
      isPDFView: false
    })
  };

  returnToList = () => {
    this.setState({
      ...this.state,
      activityResults: null,
      activityProfiles: null,
      isPDFView: false
    })
  }

  getNumberOfGames=(docid)=>{
      let count=0;

    if(this.props.pastSessions !== undefined){
      if(this.props.pastSessions[docid].past_games !== undefined){
        this.props.pastSessions[docid].past_games.forEach(element => {
          count = count +1;  
        });
        if(this.props.pastSessions[docid].active_game.id !== undefined){
          if ( this.props.pastSessions[docid].active_game.removed !== true ){
            count = count +1;
          }
        }
      }

      if ( this.props.pastSessions[docid].removedActivities !== undefined ){
        count = count - this.props.pastSessions[docid].removedActivities.length;
      }

      return(count)
    }
  };

  getActivityDataTable=(docid) =>{

if(this.props.pastSessions[docid].past_games !== undefined){
  
    return(
        <Table aria-label='Activity table' style={{width: '100%'}}>
            <TableHead>
              <TableRow>
                <TableCell align='left'>ACTIVITY NAME</TableCell>
                <TableCell align='left'>STARTED ON</TableCell>
                <TableCell align='center'>OPTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.pastSessions[docid].past_games.slice().sort((a,b) => { 
                    if ( a === null || b === null ){
                      return 0;
                    }
                    if ( a.activeOn > b.activeOn ){  
                      return -1; 
                    } else { 
                      return 1; 
                    }
                    //return 1;
                  }).map((pastgame,index) => {
                    if ( pastgame === null ){
                      return null;
                    }

                    //console.log('test' , this.props.games, pastgame);

                    if ( Object.keys(pastgame) <= 0 ){
                      return null;
                    }
                    //console.log(this.props.pastSessions[docid].removedActivities, pastgame.activeOn);
                    if ( this.props.pastSessions[docid].removedActivities !== undefined ){
                      if ( this.props.pastSessions[docid].removedActivities.includes(pastgame.activeOn) ){
                        return null;
                      }
                    }
                return(             
                  <TableRow key={index}>
                    <TableCell align='left'>{pastgame.name} {this.props.games !== undefined ? this.props.games[pastgame.id].hasPDFView === true ? <PictureAsPdfIcon/> : null : null}</TableCell>
                    <TableCell align='left'>{new Date(pastgame.activeOn).toDateString()}</TableCell>
                    <TableCell align='center'>
                    {this.props.games !== undefined ? this.props.games[pastgame.id].hasAdminResultsView === true ? <Button startIcon={<AssessmentIcon />} onClick={()=> this.viewResults(pastgame, this.props.pastSessions[docid].playerProfiles, this.props.pastSessions[docid].groups, this.props.pastSessions[docid], docid)}>view results</Button> : <Button startIcon={<AssessmentIcon />} disabled={true}>results not available</Button> : <Button startIcon={<AssessmentIcon />} disabled={true}>results not available</Button>}<br/>
                    { accessCheck(this.props.profile.role, ['host', 'admin']) === true ? <Button startIcon={<DeleteIcon />} onClick={()=> this.removeRecord(docid, this.props.hostID, pastgame.activeOn)}>Remove Activity Record</Button> : null }
                    </TableCell>
                    </TableRow>
                )  
              })}
              {Object.keys(this.props.pastSessions[docid].active_game).length >0 ?
                this.props.pastSessions[docid].active_game.removed !== true ?
              (
                <TableRow>
                  <TableCell align='left'>{this.props.pastSessions[docid].active_game.name}</TableCell>
                  <TableCell align='left'>{new Date(this.props.pastSessions[docid].active_game.activeOn).toDateString()}</TableCell>
                  <TableCell align='center'>
                  {this.props.games !== undefined ? this.props.games[this.props.pastSessions[docid].active_game.id].hasAdminResultsView === true ? <Button startIcon={<AssessmentIcon />} onClick={()=> this.viewResults(this.props.pastSessions[docid].active_game, this.props.pastSessions[docid].playerProfiles, this.props.pastSessions[docid].groups,  this.props.pastSessions[docid], docid)}>view results</Button> : <Button startIcon={<AssessmentIcon />} disabled={true}>results not available</Button> : <Button startIcon={<AssessmentIcon />} disabled={true}>results not available</Button>}
                    <br/>
                    { accessCheck(this.props.profile.role, ['host', 'admin']) === true ? <Button startIcon={<DeleteIcon />} onClick={()=> this.removeActiveRecord(docid, this.props.hostID)}>Remove Activity Record</Button> : null }
                  </TableCell>
                </TableRow>
                ):(null):(null)
              } 
            </TableBody>
        </Table>
      )
    }
    return(
      <Typography>No Past Game Found</Typography>
    )
  };

  
  render(){

    

    if ( !isLoaded(this.props.pastSessions) ){
      return(
        <Typography>Loading</Typography>
        )
    }

    if ( isEmpty(this.props.pastSessions) ){
      return(
        <Typography>No Data to Display</Typography>
        )
    }

    if ( this.state.activityResults !== '' && this.state.activityResults !== null && this.state.activityResults !== undefined ){
      // show game RESULTS
      console.log('asdfasdfa', this.state.activityProfiles, this.state.activityGroups);
        return <div>
          <Button variant="contained" color="primary" style={{marginBottom:"30px", marginRight: "10px"}} onClick={() => this.returnToList()}>Return to List</Button>
          {
            this.props.games[this.state.activityResults.id].hasPDFView === true ?
           this.state.isPDFView !== true ? <Button variant="contained" color="primary" style={{marginBottom:"30px"}} onClick={() => this._exportPdf()}>Generate PDF</Button> : null 
           : null
          }
          <ActivityResults results={this.state.activityResults} profiles={this.state.activityProfiles} isPDFView={this.state.isPDFView} game_options={this.props.game_options} host={this.props.hostID} groups={this.state.activityGroups}/>
        </div>;

    }

       //return list of sessions for id (2)
    if(this.props.pastSessions !== undefined && this.props.pastSessions !==null ){
      return(
        //IF DATA EXISTS
        <div className='meta-react'>
          <br/>
          <Dialog aria-labelledby='rename-session-dialog' aria-describedby='rename session page' open={this.state.nameDialog} onClose={this.handleClose}>
            <DialogTitle id='rename-user-page'>
              <Grid container spacing={2} justify='flex-start' alignItems='center'>
                <Grid item xs={10}>
                  <Typography variant='h6'>Rename User.</Typography>
                </Grid>
                <Grid item sm={2} xs={1} align='right'>
                  <IconButton  onClick={this.handleDialogClose} >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid> 
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the new desired name for the session.
              </DialogContentText>
              <TextField required id='new-session-name' name='newName' label='New Session Name' value={this.state.newName} onChange={this.onChange}/>
            </DialogContent>
            <DialogActions>
              <Grid item container xs={12}>
                <Grid item xs={12} />
                <Grid item xs={1} />
                <Grid item xs={4} align='left'>
                  <Button  onClick={this.handleDialogClose} >CANCEL</Button>
                </Grid>
                <Grid item xs={4} align='right'>
                  <Button color='primary'  onClick={()=> this.handleSubmit(this.props.hostID, this.state.targetID , this.state.newName)}  >
                    SUBMIT
                  </Button>
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid item xs={12} />
            </DialogActions>
          </Dialog>

          <TableContainer>
            <Table aria-label='Past Session table'>
              <TableHead>                  
                <TableRow>
                  <TableCell />
                  <TableCell align='left'>SESSION NAME</TableCell>
                  <TableCell align='left'>ENDED ON</TableCell>
                  <TableCell align='center'>NUMBER OF ACTIVITIES RUN</TableCell>
                  <TableCell align='center'>OPTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(this.props.pastSessions).sort((a,b) => { 
                    if ( this.props.pastSessions[a] === null || this.props.pastSessions[b] === null ){
                      return 0;
                    }
                    if ( this.props.pastSessions[a].savedOn > this.props.pastSessions[b].savedOn ){  
                      return -1; 
                    } else { 
                      return 1; 
                    }
                  }).map((docid,index)=>{
                  if ( this.props.pastSessions[docid] === null ){
                    return null;
                  }
                  if (this.props.pastSessions[docid].savedOn===undefined){
                    return null;
                  }
                  if (this.props.pastSessions[docid].removed===true){
                    return null;
                  }
                  return(
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>
                          <IconButton size='small' onClick={() => this.toggleOpen(docid)}>
                            {this.state.rowOpen[docid] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                          </IconButton>
                        </TableCell>
                        <TableCell align='left'>
                          {this.props.pastSessions[docid].sessionName !== undefined ?(
                            this.props.pastSessions[docid].sessionName
                          ):( ' ' )
                          }
                        </TableCell>
                        <TableCell align='left'>{new Date(this.props.pastSessions[docid].savedOn).toDateString()}</TableCell>
                        <TableCell align='center'>{this.getNumberOfGames(docid)}</TableCell>
                        <TableCell align='center'>
                        { accessCheck(this.props.profile.role, ['host', 'admin']) === true ? <React.Fragment><Button startIcon={< CreateIcon/>} onClick={()=> this.getNewSessionName(docid)}>
                            Name Session
                          </Button><br/>
                          <Button startIcon={< DeleteIcon/>} onClick={()=> this.removeSession(docid, this.props.hostID)}>
                            Remove Session
                          </Button><br/>
                          <Button startIcon={< GroupIcon/>} onClick={()=> this.viewPlayers(this.props.pastSessions[docid].playerProfiles)}>
                            View Players
                          </Button>
                          </React.Fragment>
                          : null }
                          </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                          <Collapse in={this.state.rowOpen[docid]} timeout="auto" unmountOnExit>
                            <Box margin={1}  style={{ padding: "20px" }}>
                              <Typography variant="h6" gutterBottom component="div">
                                Session Activity Break Down
                              </Typography>
                              
                              { this.state.rowOpen[docid] ?
                                this.getActivityDataTable(docid)
                              : null
                              }
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )})
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )
    }
    return(
    <Typography>No Data Found</Typography>
    )
  }
}

SessionData.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    games: state.firestore.data.games,
    hostInfo: state.firestore.data.hostInfo,
    playerList: state.firestore.data.playerList,
    pastSessions: state.firestore.data.pastSessions,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    game_options: state.firestore.data.game_options
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    renameSession: (name,sessID,docid) =>dispatch(renameSession(name, sessID,docid)),
    removeSession: (docid, hostid) => dispatch(removeSession(docid, hostid)),
    removeRecord: (docid, hostid, record) => dispatch(removeRecord(docid, hostid, record)),
    removeActiveRecord: (docid, hostid) => dispatch(removeActiveRecord(docid, hostid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => {
    //console.log(props.hostID);
   if ( props.hostID === undefined || props.hostID == '' || props.hostID === null ){
       return [];
   } else {
    return [{
      collection: 'past_sessions',
      doc: props.hostID,
      subcollections:[{
          collection: 'sessions',
          where: [
            ['savedOn', '>', 0]                  
          ],
      }],
      storeAs: 'pastSessions'
    },
    {
      collection: 'game_options'
    }, 
    { 
      collection: 'games' 
    }
    ]
  }
})
)(SessionData);