import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
//import { GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
//    HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS } from '../../../../store/actions/gameActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import LiquidLoadingBlock from '../../../../Loading/loadingCup';
import {
  hasGameControl,
  getUserGroup_hc,
  determineHost
} from '../../game_functions/functions';
import { moveToPhaseWithParamsOneGroup, GROUP_STATE_ACTIVE } from './../../../../../store/actions/gameActions';
import Button from '@material-ui/core/Button';
//import { wordGroups } from './../data/data';
import LoadingDots from './../../game_components/loadingDots';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';



class Results extends Component {
  /*
  constructor(props) {
    super(props);
  }
*/
    componentDidMount() {
        window.scrollTo(0, 0)
    }

  

  render() {
    const {
      //game_id, game_title,
      session,
      classes,
      profile,
      auth
      //profile, auth, group_state, game_state
    } = this.props;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    const timeelapsed = session.active_game.groupStates[group_id].params.finishedOn - session.active_game.groupStates[group_id].params.startedOn;
    let secondsElapsed = Math.floor(timeelapsed / 1000);

    if ( isNaN(secondsElapsed) ){
      secondsElapsed = 0;
    }

    

    return (
      <div>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">Wrapping Up!</Typography>
              <div>
                  <Typography variant="body1">Waiting for other groups to finish their activity.</Typography>
                <br />
                <br />
                <div className={classes.cupContainerLeft}>
                  <LiquidLoadingBlock/>
                </div>
              </div>
            </Grid>
          </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveToPhaseWithParamsOneGroup: (session, host, parameters, groupState, group) => dispatch(moveToPhaseWithParamsOneGroup(session, host, parameters, groupState, group)),
  };
  
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Results);
