export const CARD_CAT_DOMINANCE = "dominance";
export const CARD_CAT_INFLUENCE = "influence";
export const CARD_CAT_STEADINESS = "steadiness";
export const CARD_CAT_CONSCIENTIOUSNESS = "conscientiousness";

export const CardCategoryColors = {
    [CARD_CAT_DOMINANCE] : '#e3efd9',
    [CARD_CAT_INFLUENCE] : '#fee6e8',
    [CARD_CAT_STEADINESS] : '#dde9f5',
    [CARD_CAT_CONSCIENTIOUSNESS] : '#fef2cc',
}

export const CardList = [
    {
        id: 1,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Strong Willed',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 2,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Ambitious',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 3,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Enjoys Challenges',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 4,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Sense of Urgency',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 5,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Power and Control',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 6,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Competitive',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 7,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Winning',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 8,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Direct',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 9,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Immediate Results',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 10,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Fast Paced',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 11,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Motivating Others',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 12,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'People Oriented',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 13,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Outgoing',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 14,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Persuasive',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 15,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Share Openly',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 16,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Encouraging and Collaborating',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 17,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Taking Action',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 18,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Group Activities',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 19,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Friendly Relationships',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 20,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Extrovert',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 21,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Avoidance of Conflict',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 22,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Loyal and Fair',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 23,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Even Tempered',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 24,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Prefers Being in the Background',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 25,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Team Player',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 26,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Conventional',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 27,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Good Listener',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 28,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Supports Others',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 29,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Cooperation',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 30,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Humble',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 31,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Orderly',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 32,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Methodical',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 33,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Consistency',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 34,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Follow the Rules',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 35,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Attention to Quality',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 36,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Reserved',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 37,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Conventional',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 38,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Competent',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 39,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Theme Song',
        card_subwords: 'My Way or Welcome to the Jungle',
        card_fun: true
    },
    {
        id: 40,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Car',
        card_subwords: 'Sherman Tank',
        card_fun: true
    },
    {
        id: 41,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Animal Mascot',
        card_subwords: 'Tiger',
        card_fun: true
    },
    {
        id: 42,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'When Making Decisions',
        card_subwords: 'Decisive',
        card_fun: true
    },
    {
        id: 43,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Likes Environment To Be',
        card_subwords: 'Businesslike',
        card_fun: true
    },
    {
        id: 44,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Tendency To Do Things',
        card_subwords: 'Rapidly',
        card_fun: true
    },
    {
        id: 45,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Famous People',
        card_subwords: 'Barbara Walters (Newscaster)',
        card_fun: true
    },
    {
        id: 46,
        card_category : CARD_CAT_DOMINANCE,
        card_words: 'Famous People',
        card_subwords: 'General Patton (Military)',
        card_fun: true
    },
    {
        id: 47,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Theme Song',
        card_subwords: 'Celebration or Born Free',
        card_fun: true
    },
    {
        id: 48,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Car',
        card_subwords: 'Sports Car - Convertible',
        card_fun: true
    },
    {
        id: 49,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Animal Mascot',
        card_subwords: 'Lamb',
        card_fun: true
    },
    {
        id: 50,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'When Making Decisions',
        card_subwords: 'Spontaneous',
        card_fun: true
    },
    {
        id: 51,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Likes Environment To Be',
        card_subwords: 'Playful',
        card_fun: true
    },
    {
        id: 52,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Tendency To Do Things',
        card_subwords: 'Enthusiastically',
        card_fun: true
    },
    {
        id: 53,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Famous People',
        card_subwords: 'Jimmy Kimmel (TV Personality)',
        card_fun: true
    },
    {
        id: 54,
        card_category : CARD_CAT_INFLUENCE,
        card_words: 'Famous People',
        card_subwords: 'Lady Gaga (Singer)',
        card_fun: true
    },
    {
        id: 55,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Theme Song',
        card_subwords: 'People or Stand By Me',
        card_fun: true
    },
    {
        id: 56,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Car',
        card_subwords: 'Mini Van',
        card_fun: true
    },
    {
        id: 57,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Animal Mascot',
        card_subwords: 'Golden Retriever',
        card_fun: true
    },
    {
        id: 58,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'When Making Decisions',
        card_subwords: 'Collaborative',
        card_fun: true
    },
    {
        id: 59,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Likes Environment To Be',
        card_subwords: 'Calm',
        card_fun: true
    },
    {
        id: 60,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Tendency To Do Things',
        card_subwords: 'Patiently',
        card_fun: true
    },
    {
        id: 61,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Famous People',
        card_subwords: 'Anderson Cooper (Newscaster)',
        card_fun: true
    },
    {
        id: 62,
        card_category : CARD_CAT_STEADINESS,
        card_words: 'Famous People',
        card_subwords: 'Wayne Gretzky (Athlete)',
        card_fun: true
    },
    {
        id: 63,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Theme Song',
        card_subwords: 'Don\'t Rain on My Parade',
        card_fun: true
    },
    {
        id: 64,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Car',
        card_subwords: 'Volvo',
        card_fun: true
    },
    {
        id: 65,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Animal Mascot',
        card_subwords: 'Bull',
        card_fun: true
    },
    {
        id: 66,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'When Making Decisions',
        card_subwords: 'Deliberate',
        card_fun: true
    },
    {
        id: 67,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Likes Environment To Be',
        card_subwords: 'Methodical',
        card_fun: true
    },
    {
        id: 68,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Tendency To Do Things',
        card_subwords: 'Precisely',
        card_fun: true
    },
    {
        id: 69,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Famous People',
        card_subwords: 'Kevin Costner (Actor)',
        card_fun: true
    },
    {
        id: 70,
        card_category : CARD_CAT_CONSCIENTIOUSNESS,
        card_words: 'Famous People',
        card_subwords: 'Bill Gates (Businessman)',
        card_fun: true
    },
];


