
export const CardList = [
    {
        id: 1,        
        card_words: '1. Prepare and present a clear proposal, supported by research; KPIs – be a partner',
    },
    {
        id: 2,        
        card_words: '2. Demonstrate the value of the training - what’s in it for the stakeholder?',
    },
    {
        id: 3,        
        card_words: '3. Together define how stakeholders can support the transfer of learning',
    },
    {
        id: 4,        
        card_words: '4. Understand stakeholder pain points regarding the learning initiative; what barriers are in the way of success',
    },
    {
        id: 5,        
        card_words: '5. Collaborate with stakeholders to ensure real-world examples and application',
    },
    {
        id: 6,        
        card_words: '6. Discuss stakeholder role before, during, after training',
    },
    {
        id: 7,        
        card_words: '7. Identify who your stakeholders are – what level of power or interest do they have',
    },
    {
        id: 8,        
        card_words: '8. Review stakeholder needs and requirements',
    },
    {
        id: 9,        
        card_words: '9. Track and measure progress',
    },
    {
        id: 10,        
        card_words: '10. Talk the language of business',
    }
];


