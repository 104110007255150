import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import LiquidLoadingBlock from '../../../../Loading/loadingCup';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import TurnsManager from './../specific_components/turns.js';
import { ComparisonTable } from './../specific_components/comparisonTable';

class Results extends Component {
  componentDidMount() {
        window.scrollTo(0, 0)
    }


  render() {
    const {
      classes, session, profile, auth, group_id, hasControl
    } = this.props;

    return (
      <div>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">Wrapping Up!</Typography>
              <div>
                  <Typography variant="body1">Waiting for other groups to finish their activity.</Typography>
                <br />
                <br />

                
  
                <TurnsManager 
                  debrief={true}
                  session={session} 
                  profile={profile} 
                  auth={auth} 
                  group_id={group_id} 
                  control={hasControl} 
                  results={session.active_game.results}
                  vsKey={session.active_game.groups[group_id].vsKey}
                />
              </div>
             
            </Grid>
          </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Results);