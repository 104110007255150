export const PROFILE_EMPLOYEE = 'employee';
export const PROFILE_MANAGER = 'manager';

export const PROFILE_MALE = 'Microsoft Guy Online (Natural) - English (United States)';
export const PROFILE_FEMALE = 'Microsoft Clara Online (Natural) - English (Canada)';

export const PROFILE_MALE2 = 'Google UK English Male';
export const PROFILE_FEMALE2 = 'Google UK English Female';


export const StepList = {
    1 : { rotation: 20 },
    2 : { rotation: 20 },
    3 : { rotation: 20 },
    4 : { rotation: 20 },
};


export const ProfileCategories = {
    1 : 'Dealing with Performance Problems',
    2 : 'Resolving Disputes',
    3 : 'Personal Problems vs Sharing Information',
    4 : 'Saying No vs Respecting My Time',
}

export const ProfileList = [
    {
        id: 5,
        profile_step : 1,
        profile_type : PROFILE_EMPLOYEE,
        profile_text: `If I screw up, I don’t expect you to overlook it. Just treat me with respect, deal with the facts, consider my side and give me a chance to correct the problem. And please, don’t wait for me to get into deep weeds before you talk with me. The sooner you bring it up, the sooner I can fix it. Generally, how I respond will be determined by how you deal with me. Talk to me like an adult, and I’ll most likely respond in kind. If I don’t, I’m “the heavy” not you... and I’ve bought any consequence I get.`,
        profile_gender: PROFILE_FEMALE,
        profile_gender2: PROFILE_FEMALE2
    },
    {
        id: 7,
        profile_step : 2,
        profile_type : PROFILE_EMPLOYEE,
        profile_text: `Chances are you boast of having an “open door policy,” where I can come in and discuss my concerns “at any time.” I appreciate the good intentions such a policy represents. But I don’t think you realize how difficult it can be to step through that door. Sometimes I find that you’re just too busy to give me the attention I think I deserve. For you, it may be just another problem, or perhaps just another “shiny employee.” For me, it’s a very important issue that’s probably been bothering me for some time.`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 8,
        profile_step : 3,
        profile_type : PROFILE_EMPLOYEE,
        profile_text: `Here’s a suggestion: How about letting me be the judge of what I need or want to know about? I may be interested in more than you think! When you get information on our organizations’ performance, financial picture, quality statistics, etc. pass them along. Let me know about future plans for expansion into new products and services. Share information about our competition and trends in our industry. If I throw it in the trash, that’s my choice. At least you’ll have made an effort to make me feel like I’m an important part of this business.`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 9,
        profile_step : 4,
        profile_type : PROFILE_EMPLOYEE,
        profile_text: `Ask me what I’m working on before you give me an assignment, and I’ll be much more likely to believe that my work truly is important. Ask if I have a few minutes to discuss your needs instead of walking up and telling me what to do, and I’ll be much more inclined to believe that time is a precious resource that must be respected and used wisely. Act like my time isn’t important and I’ll resent it. Even worse, I just might follow your lead.`,
        profile_gender: PROFILE_FEMALE,
        profile_gender2: PROFILE_FEMALE2
    },
    {
        id: 16,
        profile_step : 1,
        profile_type : PROFILE_MANAGER,
        profile_text: `Everyone’s got something they can identify as the absolute worst part of their job. This is mine. Addressing employee performance problems is the necessary evil of management. I hate it... it takes a definite toll on me. Just once I’d like people to understand how tough this is on me.`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 18,
        profile_step : 2,
        profile_type : PROFILE_MANAGER,
        profile_text: `I’d like you to consider the real reason I frequently keep a distance between us. It’s tough to supervise your friends. If you’ve ever been placed in a lead worker position, you know exactly what I mean.`,
        profile_gender: PROFILE_FEMALE,
        profile_gender2: PROFILE_FEMALE2
    },
    {
        id: 19,
        profile_step : 3,
        profile_type : PROFILE_MANAGER,
        profile_text: `Personal problems don’t go away when you join management. In fact, it’s often just the opposite. You end up with more things to worry about, more things potentially to get you down. And knowing that nobody forced me to take the job doesn’t minimize my concerns one bit.`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 20,
        profile_step : 4,
        profile_type : PROFILE_MANAGER,
        profile_text: `One of the hardest words to say in the English language is “no.” It’s as hard to say as it is to hear... sometimes harder. Given a choice, I’d say “yes” all the time. Most people would. It makes us feel good when we please others. But you and I both know that’s not realistic - it’s not always the right thing to do. Somebody has to periodically say no, and I got elected.`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
];


