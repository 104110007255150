import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults, updateGroupStateParamsVal, editResultsPath, updateResultsPath, removeResultsPath } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { ProfileList } from '../profiles/profileList';
import { PROFILE_VALUES } from '../profiles/profileList';
import DoneIcon from '@material-ui/icons/Done';
import Board from './board';
import WalkProfile from './../specific_components/profile';
import SkillPicker from './../specific_components/skillPicker';
import GameHelp from './../specific_components/gameHelp';


const INITIAL_STATE = {
  noteOpen: false,
  entry_text: '',
  isEdit: false, 
  choiceSet: Object.keys(PROFILE_VALUES),
  choices: Object.keys(PROFILE_VALUES),
  answer: []
};

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( this.props.session.active_game.results.hasOwnProperty(groupID) ){
      if ( this.props.session.active_game.results[groupID].hasOwnProperty(`step_${this.props.step}`) ){
        if ( this.props.session.active_game.results[groupID][`step_${this.props.step}`].choice !== false && this.props.session.active_game.results[groupID][`step_${this.props.step}`].choice !== undefined ){
          let c = [...Object.keys(PROFILE_VALUES)];
          let ch = this.props.session.active_game.results[groupID][`step_${this.props.step}`].choice;
          let i = c.indexOf(ch);
          c.splice(i, 1);
          this.setState({
            ...this.state,
            answer: [ch],
            choices: [...c]
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps){
    if ( this.props.session.active_game.results.hasOwnProperty(this.props.group_id) ){
      if ( this.props.session.active_game.results[this.props.group_id].hasOwnProperty(`step_${this.props.step}`) ){
        if ( this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].choice !== undefined ){
          if ( prevProps.session.active_game.results[this.props.group_id][`step_${this.props.step}`].choice !== this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].choice ){
            let c = [...Object.keys(PROFILE_VALUES)];
            let ch = this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].choice;
            if ( ch === false ){
              this.setState({
                ...this.state,
                answer: [],
                choices: [...c]
              });
            } else {
              let i = c.indexOf(ch);
              if ( i > 0 ){
                c.splice(i, 1);
              }
              this.setState({
                ...this.state,
                answer: [ch],
                choices: [...c]
              });
            }
          }
        }
      }
    }
  }

  handleClose = (event) => {
    this.props.updateGroupStateParamsVal(this.props.group_id, 'writing', false, determineHost(this.props.profile, this.props.auth));
    this.setState({
      ...this.state,
      noteOpen: false,
      isEdit: false,
    });
  };

  handleSave = (event) => {
    this.props.updateResultsPath(`active_game.results.${this.props.group_id}.step_${this.props.step}.notes`, this.state.entry_text, determineHost(this.props.profile, this.props.auth));
    this.props.updateGroupStateParamsVal(this.props.group_id, 'writing', false, determineHost(this.props.profile, this.props.auth));
    this.setState({
      ...this.state,
      noteOpen: false,
    });
  };

  itemAction = (index, value) => {
    this.setState({
      ...this.state,
      isEdit: index,
      noteOpen: true,
      entry_text: value,
    });
  };

  updateRow = (row) => {
    let c = [...this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].notes];
    c[row] = this.state.entry_text;
    this.props.editResultsPath(`active_game.results.${this.props.group_id}.step_${this.props.step}.notes`, c, determineHost(this.props.profile, this.props.auth));
    this.props.updateGroupStateParamsVal(this.props.group_id, 'writing', false, determineHost(this.props.profile, this.props.auth));
    this.setState({
      ...this.state,
      noteOpen: false,
      isEdit: false,
    });
  };

  deleteRow = (row) => {
    let c = [...this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].notes];
    c.splice(row, 1);
    this.props.removeResultsPath(`active_game.results.${this.props.group_id}.step_${this.props.step}.notes`, c, determineHost(this.props.profile, this.props.auth));
    this.setState({
      ...this.state,
      noteOpen: false,
      isEdit: false,
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  nextStep = () => {
    this.props.updateGroupStateParams(this.props.group_id, {
      phase : 'board'
    }, determineHost(this.props.profile, this.props.auth));
  }
  
  addToNotes = () => {
    this.setState({
        ...this.state,
        entry_text: '',
        noteOpen: true
      });
      this.props.updateGroupStateParamsVal(this.props.group_id, 'writing', true, determineHost(this.props.profile, this.props.auth));
  }

  getCorrespondingProfiles = (step) => {
    let e = ProfileList.find(obj => {
      return (obj.profile_step === step)
    });
    return {
      profile : e,
    };
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    if (result.destination.droppableId === 'step_answer' && this.state.answer.length > 0 ){
      return;
    }
    if ( result.destination.droppableId === result.source.droppableId ){
      return;
    }

    const c = [...Object.keys(PROFILE_VALUES)];
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    if ( result.destination.droppableId === 'step_answer' ){
      const a = Object.keys(PROFILE_VALUES)[result.source.index];
      const answer = [a];
      c.splice(result.source.index,1);
      this.setState({
        ...this.state,
        choices: [...c],
        answer: [...answer]
      });    
      this.props.editResultsPath(`active_game.results.${groupID}.step_${this.props.step}.choice`, a, determineHost(this.props.profile, this.props.auth));
    }

    if ( result.destination.droppableId === 'step_eis' ){
        if ( result.source.droppableId === 'step_answer' ){
          this.setState({
            ...this.state,
            choices: [...c],
            answer: []
          }); 
          this.props.editResultsPath(`active_game.results.${groupID}.step_${this.props.step}.choice`, false, determineHost(this.props.profile, this.props.auth));
        }
    }
    return;
  }
  
  render() {
    const {
      session,
      profile,
      auth,
      params, 
      step,
      phase,
      classes,
      group_id,
    } = this.props;

    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);
    const results = session.active_game.results;
    let group_result = results[group_id] ? results[group_id] : false;
    let e_result = false;
    let boardStep = 1;
    let stepsCompleted = 0;
    if ( group_result !== false ){       
        if ( group_result[`step_${step}`] ){ 
          e_result = group_result[`step_${step}`].done;         
        }
        boardStep = parseInt(Object.keys(group_result).length) + 1;
        stepsCompleted = parseInt(Object.keys(group_result).length)
        if ( boardStep > 11 ){
          boardStep = 11;
        }
    }

    if ( phase === 'board' ){
      return <Board
        params={params}
        step={boardStep}
        session={session}
        group_id={group_id}
        profile={profile}
        auth={auth}
        stepsDone={stepsCompleted}
        leader={session.active_game.groups[group_id].leadPlayer}
      />;
    }

    /* IF WE NEED TO CHANGE THE STEP DEPENDING ON THE GROUP, WE DO THAT HERE */
    const calculatedStep = step;
    const walkAwhileProfiles = this.getCorrespondingProfiles(calculatedStep);  

    return (
      <div>
        <Typography variant="body1"  className={classes.spaceBottom}>
          { hasControl ? 
            `Tap on the profile image below to view the scenario.`
          :
            `Your group leader can tap on the profile image below to allow your group to view the scenario.`
          }  <GameHelp/>
        </Typography>  
        <Typography variant='h5' className={`${classes.spaceBottom} ${classes.profileTypeDescriptor} ${classes.minorMargin}`} align="center">Scenario {step}<br/><span className={classes.profileScenario}></span></Typography>  
        <Grid container spacing={3} className={classes.spacingTop}>
            <Grid item xs={12}>
                <WalkProfile 
                 walkProfile={walkAwhileProfiles.profile}
                 step={step}
                 session={session}
                 group_id={group_id}
                 profile={profile}
                 auth={auth}
                 hasControl={hasControl}
                 d_result={e_result}
                />
            </Grid>
            <Grid item xs={12}>
              { e_result === true ?
                <SkillPicker 
                  walkProfile={walkAwhileProfiles.profile}
                  step={step}
                  session={session}
                  group_id={group_id}
                  profile={profile}
                  auth={auth}
                  hasControl={hasControl}
                  d_result={e_result}
                />
              : null }
            </Grid>
            {
             this.props.session.active_game.results.hasOwnProperty(this.props.group_id) ?
                this.props.session.active_game.results[this.props.group_id].hasOwnProperty(`step_${this.props.step}`) ?
                (this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].choice !== false && this.props.session.active_game.results[this.props.group_id][`step_${this.props.step}`].choice !== undefined) ?
                    <Grid item xs={12} align="right">
                      <Button type='submit' variant="contained"  onClick={() => this.nextStep()} startIcon={<DoneIcon/>}>
                        Finish Scenario
                      </Button>
                    </Grid>
                  : null
                : null
              : null
            }
        </Grid>  
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    updateGroupStateParamsVal: ( group, value, groupStateParams, hostID ) => dispatch(updateGroupStateParamsVal(group, value, groupStateParams, hostID) ),
    editResultsPath: (path, value, host) => dispatch(editResultsPath(path, value, host)),
    updateResultsPath: (path, value, host) => dispatch(updateResultsPath(path, value, host)),
    removeResultsPath: (path, value, host) => dispatch(removeResultsPath(path, value, host)),

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);