import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GROUP_STATE_RESULTS, } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, editResultsPath, 
  //editResultsPaths,
   removeResults } from './../../../../../store/actions/gameActions';
import CardList from './../specific_components/cardList';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingDots from './../../game_components/loadingDots';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import { CSSTransition } from "react-transition-group";
import CreateIcon from '@material-ui/icons/Create';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import PieTable from './../specific_components/pieTable';
import { CLUES } from './../data/data';

const INITIAL_STATE = {
  open: false
};




class Activity extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        ...INITIAL_STATE,
    };
    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    
  }

  componentDidUpdate(prevProps, prevState) {
      
  }

  shuffle = (array)  => {
    var currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

  dealCardsToPlayers = () => {
      const playersList = {};

      //console.log(CARDS);
      this.props.session.active_game.groups[this.props.group_id].playerIds.forEach((playerId, ind) => {
          playersList[playerId] = []
      })
          
    
      console.log(playersList);

      let cards = this.shuffle(CLUES);
      //console.log('cards: ', cards);
      let numPlayers = Object.keys(playersList).length;
      //console.log('num players: ', numPlayers);
      let cardsToHave = 1;
      let cardAdded = false;
      let i = 0;
      cards.forEach( (card, ind) => {
          cardAdded = false;
          i+=1;
          if ( i > numPlayers ){
              i = 1;
              cardsToHave += 1;
          }
          Object.keys(playersList).forEach( (playerId, ind) => {
              if ( playersList[playerId].length < cardsToHave && cardAdded === false ){
                  playersList[playerId].push(card);
                  cardAdded = true;
              }
          })            
      })
      

      return playersList;
  }

  updateCards = (event) => {
      let groupID = getUserGroup_hc(
          this.props.session,
          this.props.auth.uid,
          this.props.profile.role
      )


          let p2 = `active_game.results.${this.props.group_id}.cards`;


          let v2 = this.dealCardsToPlayers();
         // console.log(v2);

          let paths = [p2];
          let vals = [v2];

          this.props.editResultsPaths(
              paths,
              vals,
              determineHost(this.props.profile, this.props.auth)
          )
      

      
  }

  openCardList = () => {
    this.setState({
        ...this.state,
        open: true
    })
  }

  finishActivity = () => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_RESULTS,
      params: {}
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  closeCardList = () => {
      this.setState({
          ...this.state,
          open: false
      })
  }

  updateResult = (val, spot) => {
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const results = this.props.session.active_game.results[group_id] === undefined ? {} : this.props.session.active_game.results[group_id].table === undefined ? {} : this.props.session.active_game.results[group_id].table;

    console.log('results:', results);
    let key_to_reset = false;
    if ( results !== {} && results !== undefined ){
      let vals = Object.keys(results);
      if (val === 'X' ){

      } else { 
        vals.forEach( (key) => {
          if ( results[key] === val ){
            key_to_reset = key;
          }
      })
      }
      
      

      if ( key_to_reset !== false ){
          let p = `active_game.results.${this.props.group_id}.table.${key_to_reset}`;
          let p2 = `active_game.results.${this.props.group_id}.table.${spot}`;

          let paths = [p,p2];
          let vals = [false,val];

          this.props.editResultsPaths(
              paths,
              vals,
              determineHost(this.props.profile, this.props.auth)
          )
      } else {
        let p = `active_game.results.${this.props.group_id}.table.${spot}`;
        this.props.editResultsPath(p, val, determineHost(this.props.profile, this.props.auth));
      }
    }

  }
  

  render() {
    const {
      session,
      profile,
      auth,
      classes,
      game_options
    } = this.props;

    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( this.props.session.active_game.results[group_id] === undefined ){
      this.updateCards();
      return (
        <div>Shuffling Cards...</div>
      )
    } else {
      if ( this.props.session.active_game.results[group_id].cards === undefined ){
        this.updateCards();
        return (
          <div>Shuffling Cards...</div>
        )
      }
    }


    const { entry_text } = this.state;
    const isInvalid = entry_text === '';
    
    const hasControl = hasGameControl(session.active_game.groups[group_id].leadPlayer, auth.uid, profile);
    let title = session.active_game.name;
        if ( game_options !== false ){
            title = game_options.game_texts.game_title;
        }
    const cards = this.props.session.active_game.results[group_id].cards[auth.uid] === undefined ? [] : this.props.session.active_game.results[group_id].cards[auth.uid];
    const results = this.props.session.active_game.results[group_id] === undefined ? {} : this.props.session.active_game.results[group_id].table === undefined ? {} : this.props.session.active_game.results[group_id].table;
    return (
      <div>
         <Typography variant='h3' className={classes.spaceBottom}>
              {title}
          </Typography>
          <Typography variant="body1" className={classes.spaceBottom}>Who baked which pie? What prize did each pie win?</Typography>

          <ul className="browser-default"><li>Using the 12 clues provided, see if your group can solve the mystery of who baked each pie and which prize each pie won.  </li><li>Each team member has different clues to share with the team.</li><li>With the clues, use the chart to identify the correct information.</li><li>Eliminate possibilities by leaving an X in the grid.</li><li>Determine how you will take notes to solve the mystery.</li><li>Your group must work together to solve the mystery.</li></ul>

          <Grid container>
              <Grid item sm={12} xs={12}> 
                  <Button
                      variant="contained"
                      color="primary"
                      disabled={this.state.cardListOpen === true ? true : false}
                      startIcon={<ViewQuiltIcon />}
                      onClick={() => this.openCardList()}                      
                  >
                      View Your Clues
                  </Button>
              </Grid>
          </Grid>
          <CardList open={this.state.open} session={session}
                        profile={profile}
                        auth={auth}
                        group_id={group_id}
                        handleClose={this.closeCardList}
                        cards={cards} />

          <PieTable results={results} updateResult={this.updateResult} control={hasControl}/>
          <Grid container>
            <Grid item xs={12} className={classes.spacingTop}>
                  { hasControl ? 
                    <div>
                    <div className={classes.spacingTop}>When you are finished, click on the finish activity button.</div>
                    <Button type='submit' color="primary" variant='contained' onClick={this.finishActivity} startIcon={<DoneIcon/>} className={classes.spacingTop}>
                      Finish Activity
                    </Button>
                    </div>
                  : null
                  }
              </Grid>
            </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    editResultsPath: (path, value, hostID) =>
            dispatch(editResultsPath(path, value, hostID)),
    /* editResultsPaths: (paths, values, hostID) =>
            dispatch(editResultsPaths(paths, values, hostID)) */
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);