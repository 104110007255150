export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signInAnon = (code) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection('current_sessions')
      .where('url', '==', code)
      .get()
      .then((snapshot) => {
        
        let host = null;
        snapshot.forEach((doc) => {
          host = doc.data().host;
        });
        if (!snapshot.empty) {
         
            // not yet signed in, go for it.
            firebase
              .auth()
              .signInAnonymously()
              .then((userResp) => {
                
                let a = {...getState().gol_auth};
                a.authCode = code;
                a.sessionHost = host;
                a.authCodeValid = true;
                
                dispatch({ type: 'LOGIN_ANON_SUCCESS', d: {...a, authMessage: 'LOGIN_SUCCESS'} });
              })
              .catch((err) => {
                dispatch({ type: 'LOGIN_ANON_ERROR', err });
              });
        } else {
          dispatch({ type: 'LOGIN_CODE_ERROR' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_CODE_ERROR', err });
      });
  };
};

export const validateCode = (code) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('current_sessions')
      .where('url', '==', code)
      .get()
      .then((snapshot) => {
        
        //let host = null;
        let a = {...getState().gol_auth};
        if ( snapshot.empty) {
          dispatch({ type: 'SESSION_NOT_FOUND', ret : { ...a, authCodeValid : false }});
        } else {
          //snapshot.forEach((doc) => {
          //host = doc.data().host;
         // });
          dispatch({ type: 'CODE_VALIDATED', ret : { ...a, authCodeValid : true } });
        }
      });
  };
}

export const anonProfileCreate = (a) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection('players')
      .doc(firebase.auth().currentUser.uid)
      .set({
        role: 'player',
        sessionHost: a.sessionHost,
        sessionCode: a.authCode,
        userAdded: new Date(),
      })
      .then(() => {
        let st = 'playerProfiles.' + firebase.auth().currentUser.uid;

        firestore
        .collection('current_sessions')
        .where('url', '==', a.authCode)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
            .update({
              [st] : {
                  role: 'player',
                  sessionHost: a.sessionHost,
                  sessionCode: a.authCode,
                  userAdded: new Date(),
                }
              }).then(() => {
                dispatch({ type: 'LOGIN_PROFILE_CREATED', ret : { ...a, authMessage: 'LOGIN_PROFILE_CREATED' } });
              });       
          });
        }) 
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_PROFILE_ERROR', err });
      });
  };
};

export const endLoading = () => {
  return (dispatch) => {
    dispatch({ type: 'LOADING_DONE' });
  };
}

export const updateProfileName = (name, profile) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    let sessionCode = profile.sessionCode;
    if ( profile.role === 'host' ){
      sessionCode = firebase.auth().currentUser.uid;

      dispatch({ type: 'LOADING' });

      let a = {...getState().gol_auth};
      firestore
        .collection('players')
        .doc(firebase.auth().currentUser.uid)
        .update({
          displayName: name,
        })
        .then(() => {
          let st = 'playerProfiles.' + firebase.auth().currentUser.uid + '.displayName';
          firestore
          .collection('current_sessions')
          .where('host', '==', sessionCode)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              doc.ref
              .update({
                [st] : name                     
              });
            })
          });
          dispatch({ type: 'PROFILE_UPDATED', ret: {...a, authMessage: 'PROFILE_UPDATED'} });
        })
        .catch((err) => {
          dispatch({ type: 'LOGIN_PROFILE_UPDATE_ERROR', err });
        });
    } else {
      dispatch({ type: 'LOADING' });

      let a = {...getState().gol_auth};
      firestore
        .collection('players')
        .doc(firebase.auth().currentUser.uid)
        .update({
          displayName: name,
        })
        .then(() => {
          let st = 'playerProfiles.' + firebase.auth().currentUser.uid + '.displayName';
          firestore
          .collection('current_sessions')
          .where('url', '==', sessionCode)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              doc.ref
              .update({
                [st] : name                     
              });
            })
          });
          dispatch({ type: 'PROFILE_UPDATED', ret: {...a, authMessage: 'PROFILE_UPDATED'} });
        })
        .catch((err) => {
          dispatch({ type: 'LOGIN_PROFILE_UPDATE_ERROR', err });
        });
    }
  };
};




export const signOut = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let uid = getState().firebase.auth.uid;
    let a = {...getState().gol_auth};

    if (getState().firebase.profile.role === 'player') { 
      firebase
        .auth()
        .signOut()
        .then(() => {
          firestore
            .collection('players')
            .doc(uid)
            .delete()
            .then(() => {  
              dispatch({ type: 'SIGNOUT_SUCCESS', ret: { ...a, authMessage: 'SIGNOUT_SUCCESS' } });
            })
            .catch((err) => {
              dispatch({ type: 'SIGNOUT_ERROR' });
            });
        });
    } else {
      firebase
        .auth()
        .signOut()
        .then(() => {
          dispatch({ type: 'SIGNOUT_SUCCESS', ret: { ...a, authMessage: 'SIGNOUT_SUCCESS' } });
        });
    }
  };
};

export const closeAuthPopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_AUTH_POPUPS' });
  };
};

export const sendForgottenPasswordEmail = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({ type: 'PASSWORD_RESET_SENT' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SENDING_PASSWORD_RESET', err });
      });
  };
};

export const createEmailUser = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.emailAddress, newUser.password)
      .then((userResp) => {
        firestore
          .collection('players')
          .doc(userResp.user.uid)
          .set({
            role: 'host',
            userAdded: new Date(),
          })
          .then((r) => {
            dispatch({ type: 'NEW_USER_SUCCESS' });
          })
          .catch((err) => {
            dispatch({ type: 'NEW_USER_ERROR', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'NEW_USER_ERROR', err });
      });
  };
};

export const updateUserInfoDB = (user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('consumer')
      .doc(user.id)
      .update({
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.firstName + ' ' + user.lastName,
        initials: user.firstName[0] + user.lastName[0],
        company: '',
        role: 'consumer',
      })
      .then(() => {
        dispatch({ type: 'USER_DB_INFO_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'USER_DB_INFO_ERROR', err });
      });
  };
};

export const updateUserPassword = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, user.currentPassword);
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updatePassword(user.newPassword)
          .then(() => {
            dispatch({ type: 'USER_PASSWORD_WAS_UPDATED' });
          })
          .catch((err) => {
            dispatch({ type: 'USER_PASSWORD_WAS_NOT_UPDATED', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'CHANGE_PASSWORD_REAUTHENTICATION_ERROR', err });
      });
  };
};

export const signUpWithGoogle = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch((err) => {
        dispatch({ type: 'GOOGLE_LOGIN_ERROR', err });
      });
  };
};

export const signInGoogle = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
    dispatch({ type: 'LOGIN_SUCCESS' });
  };
};