import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextEntryList from './../specific_components/textEntryList';
import trustBehaveIcon from '../images/Trust-Build.jpg';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  PDFDownloadLink
} from "@react-pdf/renderer";
import { PDFHeader } from '../../../../PastData/PDFHeader';



const INITIAL_STATE = {
    open: false
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#673ab7",
    color: theme.palette.common.white,
    borderRadius: 0,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class GameResults extends Component {

    constructor(props) {
        super(props);
        // session.active_game.results
        this.state = {
          ...INITIAL_STATE,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }   

    render() {
    const {
      results,
      classes,
      auth,
      profile,
      isPDFView,
      game_options
    } = this.props;
  
    if( this.props.isPDFView === true ){
      return (
        <Document>
             <Page wrap style={{padding: "30 0"}}>
                <PDFHeader/>
                <View>
                    <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={trustBehaveIcon}/>
                {Object.keys(results.results).sort().map((groupKey, ind) => {
                  return (
                  <React.Fragment key={ind}>
                      <View style={{marginHorizontal: "50", marginBottom: "40"}} wrap={false}>
                          <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text> 
                          <View style={{marginTop: "5", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(103, 58, 183)"}}>
                              <View style={{ width:'20%', textAlign: 'center'}}>
                                  <Text style={{ fontSize: '9pt', marginVertical: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>ROLE</Text> 
                              </View>
                              <View style={{ width:'40%', textAlign: 'left', flexDirection: 'row'}}>
                                  <Text style={{ fontSize: '8pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>DO/SAY TO </Text>
                                  <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(000, 128, 000)'}}>BUILD </Text>
                                  <Text style={{ fontSize: '8pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}> TRUST</Text> 
                              </View>
                              <View style={{ width:'40%', textAlign: 'left', flexDirection: 'row'}}>
                                  <Text style={{ fontSize: '8pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>DO/SAY TO </Text>
                                  <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(128, 000, 000)'}}>DESTROY </Text>
                                  <Text style={{ fontSize: '8pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}> TRUST</Text> 
                              </View>
                          </View> 

                          <View style={{marginTop: "5", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(245, 245, 245)",borderBottom: "1pt solid #D3D3D3",borderRight: "1pt solid #D3D3D3"}}>
                              <View style={{ width:'20%', textAlign: 'left'}}>
                                  <Text style={{ fontSize: '9pt', marginVertical: '10', marginLeft: '5', fontWeight: 'bold'}}>Plant Manager</Text> 
                              </View>
                              <View style={{ width:'40%', textAlign: 'left', flexDirection: 'column', marginHorizontal: '10', marginVertical: '10',borderRadius:'5px', border: "1pt solid #D3D3D3" }}>
                                {results.results[groupKey][`PM_buildtrust`] ?
                                  Object.keys(results.results[groupKey][`PM_buildtrust`]).map((item, k) =>{
                                    return(
                                      <Text key={k} style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}>{results.results[groupKey][`PM_buildtrust`][item]} </Text> 
                                    )
                                  }
                                ):(
                                  <Text style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}> </Text> 
                                )}   
                              </View>
                              <View style={{ width:'40%', textAlign: 'left', flexDirection: 'column', marginHorizontal: '10', marginVertical: '10',borderRadius:'5px', border: "1pt solid #D3D3D3" }}>
                                {results.results[groupKey][`PM_destroytrust`] ?
                                  Object.keys(results.results[groupKey][`PM_destroytrust`]).map((item, k) =>{
                                    return(
                                      <Text key={k} style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}>{results.results[groupKey][`PM_destroytrust`][item]} </Text> 
                                    )
                                  }
                                ):(
                                  <Text style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}> </Text> 
                                )}
                              </View>
                          </View>
                          <View style={{marginTop: "5", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(255, 255, 255)",borderBottom: "1pt solid #D3D3D3",borderRight: "1pt solid #D3D3D3"}}>
                              <View style={{ width:'20%', textAlign: 'left'}}>
                                <Text style={{ fontSize: '9pt', marginVertical: '10', marginLeft: '5', fontWeight: 'bold'}}>Department Manager</Text> 
                              </View>
                              <View style={{ width:'40%', textAlign: 'left', flexDirection: 'column', marginHorizontal: '10', marginVertical: '10',borderRadius:'5px', border: "1pt solid #D3D3D3" }}>
                                {results.results[groupKey][`DM_buildtrust`] ?
                                  Object.keys(results.results[groupKey][`DM_buildtrust`]).map((item, k) =>{
                                    return(
                                      <Text key={k} style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}>{results.results[groupKey][`DM_buildtrust`][item]} </Text> 
                                    )
                                  }
                                  ):(
                                    <Text style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}> </Text> 
                                  )}
                              </View>
                              <View style={{ width:'40%', textAlign: 'left', flexDirection: 'column', marginHorizontal: '10', marginVertical: '10',borderRadius:'5px', border: "1pt solid #D3D3D3" }}>
                              {results.results[groupKey][`DM_destroytrust`] ?
                                  Object.keys(results.results[groupKey][`DM_destroytrust`]).map((item, k) =>{
                                    return(
                                      <Text key={k} style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}>{results.results[groupKey][`DM_destroytrust`][item]} </Text> 
                                    )
                                  }
                                ):(
                                  <Text style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}> </Text> 
                                )}
                              </View>
                          </View>
                          <View style={{marginTop: "5", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(245, 245, 245)",borderBottom: "1pt solid #D3D3D3",borderRight: "1pt solid #D3D3D3"}}>
                              <View style={{ width:'20%', textAlign: 'left'}}>
                                <Text style={{ fontSize: '9pt', marginVertical: '10', marginLeft: '5', fontWeight: 'bold'}}>Supervisor (YOU)</Text> 
                              </View>
                              <View style={{ width:'40%', textAlign: 'left', flexDirection: 'column', marginHorizontal: '10', marginVertical: '10',borderRadius:'5px', border: "1pt solid #D3D3D3" }}>
                                {results.results[groupKey][`SUPERVISOR_buildtrust`] ?
                                  Object.keys(results.results[groupKey][`SUPERVISOR_buildtrust`]).map((item, k) =>{
                                    return(
                                      <Text key={k} style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}>{results.results[groupKey][`SUPERVISOR_buildtrust`][item]} </Text> 
                                    )
                                  }
                                ):(
                                  <Text style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}> </Text> 
                                )}
                              </View>
                              <View style={{ width:'40%', textAlign: 'left', flexDirection: 'column', marginHorizontal: '10', marginVertical: '10',borderRadius:'5px', border: "1pt solid #D3D3D3" }}>
                              {results.results[groupKey][`SUPERVISOR_destroytrust`] ?
                                  Object.keys(results.results[groupKey][`SUPERVISOR_destroytrust`]).map((item, k) =>{
                                    return(
                                      <Text key={k} style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}>{results.results[groupKey][`SUPERVISOR_destroytrust`][item]} </Text> 
                                    )
                                  }
                                ):(
                                  <Text style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}> </Text> 
                                )}
                              </View>
                          </View>
                          <View style={{marginTop: "5", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(255, 255, 255)",borderBottom: "1pt solid #D3D3D3",borderRight: "1pt solid #D3D3D3"}}>
                              <View style={{ width:'20%', textAlign: 'left'}}>
                                <Text style={{ fontSize: '9pt', marginVertical: '10', marginLeft: '5', fontWeight: 'bold'}}>Employee</Text> 
                              </View>
                              <View style={{ width:'40%', textAlign: 'left', flexDirection: 'column', marginHorizontal: '10', marginVertical: '10',borderRadius:'5px', border: "1pt solid #D3D3D3" }}>
                                {results.results[groupKey][`EMPLOYEE_buildtrust`] ?
                                  Object.keys(results.results[groupKey][`EMPLOYEE_buildtrust`]).map((item, k) =>{
                                    return(
                                      <Text key={k} style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}>{results.results[groupKey][`EMPLOYEE_buildtrust`][item]} </Text> 
                                    )
                                  }
                                ):(
                                  <Text style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}> </Text> 
                                )}
                              </View>
                              <View style={{ width:'40%', textAlign: 'left', flexDirection: 'column', marginHorizontal: '10', marginVertical: '10',borderRadius:'5px', border: "1pt solid #D3D3D3" }}>
                              {results.results[groupKey][`EMPLOYEE_destroytrust`] ?
                                  Object.keys(results.results[groupKey][`EMPLOYEE_destroytrust`]).map((item, k) =>{
                                    return(
                                      <Text key={k} style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}>{results.results[groupKey][`EMPLOYEE_destroytrust`][item]} </Text> 
                                    )
                                  }
                                ):(
                                  <Text style={{ fontSize: '8pt', marginVertical: '5', marginLeft: '10',  fontWeight: 'bold'}}> </Text> 
                                )}
                              </View>
                          </View>
                      </View>
                  </React.Fragment>       
                  );
                })}
                </View>
            </Page>
        </Document> 
    ) 
      
    }

        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
                { /*: null */ }
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(results.results)
                .sort()
                .map((groupKey, ind) => {
                    return (
                    <div key={ind}>
                        <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{results.groups[groupKey].title}</Typography>   

                        <TableContainer component={Paper} style={{width: "100%"}}>
                          <Table aria-label="customized table">
                          <TableHead>
                            <TableRow style={{fontSize: "1.3em", textTransform: "uppercase"}}>
                              <StyledTableCell align="center">Role</StyledTableCell>
                              <StyledTableCell align="center">Do/Say to <span style={{fontWeight:"bold", fontSize: "1.3em", color: "green"}}>Build</span> Trust</StyledTableCell>
                              <StyledTableCell align="center">Do/Say to <span style={{fontWeight:"bold", fontSize: "1.3em", color: "red"}}>Destroy</span> Trust</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                  
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Plant Manager
                                  </StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={results} profile={profile} auth={auth} group_id={groupKey} resultsPath={results.results} finalPath={`PM_buildtrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={results} profile={profile} auth={auth} group_id={groupKey} resultsPath={results.results} finalPath={`PM_destroytrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                  <StyledTableCell component="th" scope="row">
                                    Department Manager
                                  </StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={results} profile={profile} auth={auth} group_id={groupKey} resultsPath={results.results} finalPath={`DM_buildtrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={results} profile={profile} auth={auth} group_id={groupKey} resultsPath={results.results} finalPath={`DM_destroytrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                  <StyledTableCell component="th" scope="row">
                                    Supervisor (YOU)
                                  </StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={results} profile={profile} auth={auth} group_id={groupKey} resultsPath={results.results} finalPath={`SUPERVISOR_buildtrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={results} profile={profile} auth={auth} group_id={groupKey} resultsPath={results.results} finalPath={`SUPERVISOR_destroytrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                  <StyledTableCell component="th" scope="row">
                                    Employee
                                  </StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={results} profile={profile} auth={auth} group_id={groupKey} resultsPath={results.results} finalPath={`EMPLOYEE_buildtrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={results} profile={profile} auth={auth} group_id={groupKey} resultsPath={results.results} finalPath={`EMPLOYEE_destroytrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                </StyledTableRow>
                                  
                            </TableBody>
                          </Table>
                        </TableContainer>                     
                    </div>
                    );
                })}
            </Grid>
            </Grid>
        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);