import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
//import PropTypes from 'prop-types';
//import Paper from '@material-ui/core/Paper';
//import Box from '@material-ui/core/Box';
//import Typography from '@material-ui/core/Typography';
//import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
//import Fab from '@material-ui/core/Fab';
//import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import Popover from '@material-ui/core/Popover';
import styles from '../../../Styles';

const INITIAL_STATE = {
  anchorEl: null
};

class LeaderControls extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleClick = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  render() {
    const {
      //group_id, session,
      classes,
      leader,
      auth,
      //profile
    } = this.props;

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover-lc' : undefined;

    if (leader === auth.uid) {
      return (
        <div><Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={true}
          className={classes.persistentSnackbar}
        >
          <SnackbarContent 
            message='You are the group leader.' 
            action={
                <IconButton aria-label='help' className={classes.whiteButton}  onClick={this.handleClick}>
                    <HelpIcon  className={classes.whiteButton}/>
                </IconButton>
            }
            />
        </Snackbar>
        <Popover
            id={id}
            open={open}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
        >
        <div className={classes.popover}>
          <div className={classes.descriptiveText}>What does this mean?</div>
          <p>You have been designated as leader of your group. This means you have control of all game related activities.</p>
          <p>If you wish to transfer control, open the player controls at the top right and click the star next to the name of the user in your group you wish to transfer to.</p>
        </div>
      </Popover>
      </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(LeaderControls);
