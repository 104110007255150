import { Dialog, DialogActions, IconButton, DialogContent, DialogContentText, DialogTitle, Grid, Typography, MenuItem, InputLabel } from '@material-ui/core';
import React, {Component} from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TextField from '@material-ui/core/TextField';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './../Styles';
import Select from '@material-ui/core/Select';
import { createNewEmailUser} from '../../store/actions/adminActions';
import LiquidLoadingBlockModal from '../Loading/loadingModal';

const INITIAL_STATE = {
    addDialog: false,
    isMod: false,
    firstName: '',
    lastName: '',
    emailAddress: '',
    password:'',
    reTypePassword:'',
    moderates: '',
}; 

class AddUser extends Component{
    constructor(props) {
        super(props);
    
        this.state = {
          ...INITIAL_STATE
        };
    
      }
   
     onChange = (event) => {
        this.setState({...this.state,
             [event.target.name]: event.target.value });
      };

    handleDialogClose=()=>{
        this.setState({...this.state,
            addDialog: false,
            firstName: '',
            lastName: '',
            emailAddress: '',
            password:'',
            reTypePassword:'',
            selectRole:'',
            moderates:'',
            isMod:false,
        });

    }

    handleDialogOpen=()=>{
        this.setState({...this.state,
            addDialog:true,

        });
    }

    getHostID=(nameString)=>{
        //name string holds user input for who they moderate
        const objCopy   = this.props.playerList;
        let hostID ='';
        let idArray =Object.keys(objCopy);

        // search for where display name === nameString && role === 'host'
        if(this.props.playerList !== undefined ){

            idArray.map((uid,ind)=>{
                let user =objCopy[uid];
                if(user.role ==='host' && user.displayName === nameString){
                   hostID = uid
                }
              })
        }
        return (hostID)
    };

    addNewUser=()=>{
        let idOfHost ='';
        let amAMod = false
        if(this.props.hostID !== undefined){
            idOfHost = this.props.hostID;
            amAMod = true;
        }

    //(firstName,lastName,eMail,passWord,mod,modsWho)
       this.props.createNewEmailUser(this.state.firstName,this.state.lastName,this.state.emailAddress,this.state.password,amAMod,idOfHost);
        this.handleDialogClose();
    };

    render(){

        const {firstName, lastName, emailAddress, password, reTypePassword, addDialog, isMod, moderates} = this.state;
        const { classes, hostID } = this.props;
        const formFilledOut = firstName.length > 0 && lastName.length > 0 && emailAddress.length > 0 && password.length > 8 && reTypePassword.length >8;
        


        return(
            <React.Fragment>
            {/* Dialog for adding new user by email */}
                <Dialog aria-labelledby='add-user-page' aria-describedby='add new user page' open={addDialog} onClose={()=>this.handleDialogClose()}>
                    <DialogTitle id='add-user-page'>
                        <Grid container spacing={2} justify='flex-start' alignItems='center'>
                            <Grid item xs={10}>
                                <Typography variant='h6'>Setup New {this.props.verbage}.</Typography>
                            </Grid>
                            <Grid item sm={2} xs={1} align='right'>
                                <IconButton  onClick={()=>this.handleDialogClose()} >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the required information below.
                        </DialogContentText>
                        <br/>
                         <Grid item xs={12}>
                            <TextField required id='user-first-name' name='firstName' label='First Name' value={firstName} onChange={(event)=>this.onChange(event)} className={classes.TextField}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id="user-last-name" name='lastName' label='Last Name' value={lastName} onChange={(event)=>this.onChange(event)} className={classes.TextField}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id='emailAddress' name='emailAddress' label='Email' value={emailAddress} onChange={(event)=>this.onChange(event)} className={classes.textField} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required type='password' id='password' name='password' label='Password' value={password} onChange={(event)=>this.onChange(event)} className={classes.textField} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required type='password' id='reTypePassword' name='reTypePassword' label='Retype Password' value={reTypePassword} onChange={(event)=>this.onChange(event)} className={classes.textField} />
                        </Grid>
                    </DialogContent> 
                    <DialogActions>
                        <Button size='large' disabled={!formFilledOut} onClick={()=>this.addNewUser()}>
                            SUBMIT
                        </Button>
                    </DialogActions>
                </Dialog>

            { /* button to show- on click open dialog */}
            
                { this.props.adminLoading === true ? <LiquidLoadingBlockModal/> : null }
                <Button startIcon={< PersonAddIcon/>} variant='contained' color="primary" size='large' style={{maxWidth: "300px"}} className={classes.button} onClick={()=>this.handleDialogOpen()} >
                    ADD NEW {this.props.verbage}
                </Button>
             </React.Fragment>
         )
    }
};

AddUser.propTypes = {
    classes: PropTypes.object.isRequired,
}; 

const mapStateToProps = (state) =>{
    return {
        adminLoading: state.admin_state.adminLoading,
        playerList: state.firestore.data.playerList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createNewEmailUser: (firstName,lastName,eMail,passWord,mod,modsWho) => dispatch(createNewEmailUser(firstName,lastName,eMail,passWord,mod,modsWho)),
    }
};

export default 
    compose(withStyles(styles),
    connect(mapStateToProps,
    mapDispatchToProps),
    firestoreConnect((props) => [
    {
      collection: 'players',
      storeAs: 'playerList'
    },
])
)(AddUser);