import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styles from '../../../../Styles';

const DELTA = "challenges";

class ActivityListDelta extends Component {
  
  render() {
    const { results, profile, auth, group_id, classes, debrief } = this.props;
      return (
      <List>
          {results.results.hasOwnProperty(group_id)
            ? results.results[group_id].hasOwnProperty(DELTA)
              ? results.results[group_id][DELTA].map((item, index) => {
                  if ( debrief === false ){
                    return (
                      
                      <ListItem key={index}>                 
                         <div dangerouslySetInnerHTML={ {__html: item } } className={classes.bodyListText}></div>                                         
                      </ListItem>
                    );
                  } else {
                    return (                      
                      <ListItem key={index} button>
                        <div dangerouslySetInnerHTML={ {__html: item } } className={classes.bodyListText}></div>
                      </ListItem>                      
                    );
                  }
                })
              : null
            : null}
        </List>
      );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ActivityListDelta);
