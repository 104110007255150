import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styles from '../../../../Styles';

const PLUS = "solutions";

class ActivityListPlus extends Component {
  
  render() {
    const { results, group_id, classes, debrief } = this.props;
    return (
    <List>
        {results.results.hasOwnProperty(group_id)
          ? results.results[group_id].hasOwnProperty(PLUS)
            ? results.results[group_id][PLUS].map((item, index) => {
                if ( debrief === false ){
                  return (
                    
                    <ListItem key={index}>                 
                        <div dangerouslySetInnerHTML={ {__html: item } } className={classes.bodyListText}></div>                                      
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem key={index}>                 
                       <div dangerouslySetInnerHTML={ {__html: item } } className={classes.bodyListText}></div>                                         
                    </ListItem>
                  );
                }
              })
            : null
          : null}   
      </List>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ActivityListPlus);