import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';





const useStyles = makeStyles({
    tCell: {
        '&:hover': {
        backgroundColor: 'rgba(103, 58, 183, 0.3)'
        },
        fontSize: "20px",        
    },
    normalTCell: {
        fontStyle: "italic",
        fontSize: "14px"
    },
    selectedtCell: {
        fontWeight: "bold"
    }

    });



const TurnTimer = (props) => {
    
    const [secondsElapsed, setSecondsElapsed] = React.useState( Math.round((Date.now() - props.lastActionTaken ) / 1000) )
    // move to separate component to prevent re-render
    
    React.useEffect(() => {
        const timer = setInterval( () => {
            setSecondsElapsed( Math.round((Date.now() - props.lastActionTaken) / 1000) );
        },1000);
        return( () => {
            clearInterval(timer);
        })
    },[])

    React.useEffect(() => {
        setSecondsElapsed( Math.round((Date.now() - props.lastActionTaken) / 1000) );
    },[props.lastActionTaken])

    let minutes = Math.floor(secondsElapsed / 60);
    let remainder = secondsElapsed % 60;

    let time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds`;
    let timeMessage = `${time}`;

    return (
    <div>
        Turn Timer: {timeMessage}
    </div>
    );
}


export default TurnTimer
