import arrow_1 from './../images/activity_svgs/arrow_1.svg';
import arrow_2 from './../images/activity_svgs/arrow_2.svg';
import arrow_3 from './../images/activity_svgs/arrow_3.svg';
import arrow_4 from './../images/activity_svgs/arrow_4.svg';
import arrow_5 from './../images/activity_svgs/arrow_5.svg';
import arrow_6 from './../images/activity_svgs/arrow_6.svg';
import arrow_7 from './../images/activity_svgs/arrow_7.svg';




export const pieces = [
    {
        id: 1,
        color: 'red', 
        src: arrow_1,
        width: 137,
        height: 188,
        scaleX: 1,
        scaleY: 1,
        x: Math.random() * (750 - 200) + 200,
        y: Math.random() * (500 - 200) + 200,
    },
    {
        id: 2,
        color: 'red', 
        src: arrow_2,        
        width: 75,
        height: 131,
        scaleX: 1,
        scaleY: 1,
        x: Math.random() * (750 - 200) + 200,
        y: Math.random() * (500 - 200) + 200,
    },
    {
        id: 3,
        color: 'red', 
        src: arrow_3,        
        width: 92,
        height: 149,
        scaleX: 1,
        scaleY: 1,
        x: Math.random() * (750 - 200) + 200,
        y: Math.random() * (500 - 200) + 200,
    },
    {
        id: 4,
        color: 'red', 
        src: arrow_4,
        width: 132,
        height: 150,
        scaleX: 1,
        scaleY: 1,
        x: Math.random() * (750 - 200) + 200,
        y: Math.random() * (500 - 200) + 200,
    },
    {
        id: 5,
        color: 'red', 
        src: arrow_5,
        width: 108,
        height: 148,
        scaleX: 1,
        scaleY: 1,
        x: Math.random() * (750 - 200) + 200,
        y: Math.random() * (500 - 200) + 200,
    },
    {
        id: 6,
        color: 'red', 
        src: arrow_6,       
        width: 117,
        height: 133,
        scaleX: 1,
        scaleY: 1,
        x: Math.random() * (750 - 200) + 200,
        y: Math.random() * (500 - 200) + 200,
    },
    {
        id: 7,
        color: 'red', 
        src: arrow_7,
        width: 133,
        height: 99,
        scaleX: 1,
        scaleY: 1,
        x: Math.random() * (750 - 200) + 200,
        y: Math.random() * (500 - 200) + 200,
    },
    
];