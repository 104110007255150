import {
  SESSION_LOADED,
  SESSION_ERROR_1,
  SESSION_ERROR_2,
  SESSION_ERROR_3,
  SESSION_ERROR_4,
  SESSION_ERROR_5,
  //SESSION_ARCHIVED
} from '../actions/sessionActions';

const sessionReducer = (state = {}, action) => {
  switch (action.type) {
    case SESSION_LOADED:
      return action.payload;
    case SESSION_ERROR_1:
      return state;
    case SESSION_ERROR_2:
      return state;
    case SESSION_ERROR_3:
      return state;
    case SESSION_ERROR_4:
      return state;
    case SESSION_ERROR_5:
      return state;
    default:
      return state;
  }
};

export default sessionReducer;
