/**********************************************************************
 *                                                                    *
 * File Name:     adminReducer                                        *
 * Purpose:       Reducer to handle actions related to admin          *
 * Author:        Ben Wakutz                                          *
 * Last Modified: 03/10/21                                            *
 *                                                                    *
 **********************************************************************/

const initState ={
    adminMessage: null,
    adminError: null,
    adminErrorPopUp: false,
    adminPopUp: false,
    adminLoading: false
};



const adminReducer = (state = initState, action) => {
    switch (action.type){
        case 'LOADING':
            return {
                ...state,
                adminLoading: true
            }
        case 'END_LOADING':
            return {
                ...state,
                adminLoading: false
            }
        case 'PLAYER_EMAIL_SENT':
            return {
                ...state,
                adminLoading: false
            }
        case 'PLAYER_EMAIL_SENDING_ERROR':
            return {
                ...state,
                adminLoading: false,
                adminError: action.err.message
            }
        case 'PLANNED_SESSION_SAVE_DONE':
            return {
                ...state,
                adminLoading: false
            }
        case 'RESET_ADMIN_MESSAGE':
            return { 
                ...state,
                adminMessage: ''
            }
        case 'NEW_USER_EMAIL_ALREADY_EXISTS_AS_HOST':
            return {
                ...state,
                adminLoading: false,
                adminMessage: "EMAIL ALREADY EXISTS"
            }
        case 'NEW_USER_EMAIL_ALREADY_EXISTS_AS_MOD':
            return {
                ...state,
                adminLoading: false,
                adminMessage: "EMAIL ALREADY EXISTS AS MOD"
            }
        case 'NEW_USER_EMAIL_DOES_NOT_EXIST':
            return {
                ...state,
                adminLoading: false,
                adminMessage: "EMAIL OKAY"
            }   
        case 'PLANNED_SESSION_SAVE_ERROR':
            return {
                ...state,
                adminLoading: false,
                adminError: action.err.message
            }
        case 'PLANNED_SESSION_REMOVE_DONE':
            return {
                ...state,
                adminLoading: false
            }
        case 'PLANNED_SESSION_REMOVE_ERROR':
            return {
                ...state,
                adminLoading: false,
                adminError: action.err.message
            }
        case 'PLANNED_SESSION_PLAYER_SAVE_DONE':
            return {
                ...state,
                adminLoading: false
            }
        case 'PLANNED_SESSION_PLAYER_SAVE_ERROR':
            return {
                ...state,
                adminLoading: false,
                adminError: action.err.message
            }
        case 'PLANNED_SESSION_PLAYER_DELETE_DONE':
            return {
                ...state,
                adminLoading: false
            }
        case 'PLANNED_SESSION_PLAYER_DELETE_ERROR':
            return {
                ...state,
                adminLoading: false,
                adminError: action.err.message
            }
        case 'PROFILE_UPDATED':
            return {
                ...state,
                adminMessage: 'Activities Updated Successfully',
                adminPopUp: true,
                adminLoading: false,
            }
        case 'PROFILE_UPDATE_ERROR': 
            return{ 
                ...state,
                adminError: action.err.message,
                adminErrorPopUp: true,
                adminLoading: false
            }
        case 'SEND_CONTACT_EMAIL':
            return {
                ...state,
                adminMessage: 'Email Sent Successfully.',
                adminLoading: false,
            }
        case 'SEND_CONTACT_EMAIL_ERROR': 
            return{ 
                ...state,
                adminError: action.err.message,
                adminErrorPopUp: true,
                adminLoading: false
            }
        case 'RENAME_SUCCESS':
            return {
                ...state,
                adminMessage: 'User successfully renamed',
                adminPopUp: true
            }
        case 'RENAME_ERROR':
            return{ 
                ...state,
                adminError: action.err.message,
                adminErrorPopUp: true
            }
        case 'ENABLE_SUCCESS':
            return{ 
                ...state,
                adminMessage:'User Enabled',
                adminPopUp: true
            }
        case 'ENABLE_ERROR':
            return{
                 ...state,
                 adminError: action.err.message,
                 adminErrorPopUp: true
                }
        case 'DISABLE_SUCCESS':
            return{ 
                ...state,
                adminMessage:'User Disabled',
                adminPopUp: true
            }
        case 'DISABLE_ERROR':
            return{ 
                ...state,
                adminError: action.err.message,
                adminErrorPopUp: true
            }
            case 'NEW_USER_ADMIN_SUCCESS':
            return{ 
                ...state,
                adminMessage:'New user has been added',
                adminLoading: false,
                adminPopUp: true
            }
            case 'NEW_USER_ADMIN_ERROR':
                console.log("Action: ", action);
            return{ 
                ...state,
                adminError: action.result !== undefined ? action.result.data.message.errorInfo.message : action.error.message,
                adminLoading: false,
                adminErrorPopUp: true
            }
            case 'CLOSE_ADMIN_POPUPS':
                return{
                ...state,
                adminError: null,
                adminErrorPopUp: false,
                adminMessage: null,
                adminPopUp: false, 
                }
            case 'RESETPASS_SUCCESS':
                return{
                    ...state,
                    adminMessage: 'Password reset email has been sent.',
                    adminPopUp: true,
                }
            case 'RESETPASS_ERROR':
                return{
                    ...state,
                    adminError: action.err.message,
                    adminErrorPopUp: true,
                }
            case 'SESSION_RENAME_SUCCESS':
                return{
                    ...state,
                    adminMessage: 'Session has been renamed.',
                    adminPopUp: true
                }
            case 'SESSION_RENAME_ERROR':
                return{
                    ...state,
                    adminError: action.err.message,
                    adminErrorPopUp: true
                }
        default:
            return state;
    }
};

export default adminReducer;