import React, { Component } from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { startGame } from '../../store/actions/gameActions';
import placeholder from '../../images/placeholder.jpg';
import styles from '../Styles';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { deepPurple } from '@material-ui/core/colors';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import GameOptions from './gameOptions';

import walkawhileCropped from '../Game/games/walkAwhileInMyShoes/images/walkawhileCropped.png';
import plus_delta from '../Game/games/journey/images/tuatj.png';
import stopDrop from '../Game/games/managingTime/images/stopDrop.png';
import Logo from '../Game/games/distortion/images/Logo.png';
import HiddenAgendaLogo from '../Game/games/hiddenAgenda/images/Logo.png';
import SeeingThePointLogo from '../Game/games/seeingThePoint/images/logo.png';
import DiSC_Icon from '../Game/games/discCards/images/DiSC_Icon.png';
import bbss_logo from '../Game/games/bbss/images/bbss_logo.png';
import scenario from '../Game/games/emotionalIntelligence/images/scenario.png';
import AggregateLogo from '../Game/games/aggregate/images/AggregateLogo.png';
import CircleLogo from '../Game/games/circleWords/images/Circle-Words-Wordmark.png';
import COFLogo from '../Game/games/circleOfInfluence/images/circleLogo.png';
import goldf from '../Game/games/discussionForum/images/goldf.png';
import CommunicationStyles from '../Game/games/communicationStyles/images/CommunicationsStyles.png';
import on_deck from '../Game/games/ondeck/images/on_deck.png';
import TrustLogo from '../Game/games/trustSpectrum/images/Trust-Spectrum-Logo.png';
import BWBLogo from '../Game/games/bestWorstBoss/images/Best-Worst-Leader-wordmark.png';
import CountyFairLogo from '../Game/games/countyFair/images/county_fair.png';
import ptb from '../Game/games/transitionPeerToBoss/images/peertoboss.png';
import set_expectations_square from '../Game/games/expectationsAndAccountabilities/images/set_expectations_square.png';
import ioc from '../Game/games/conflictScenarios/images/ioc.png';
import Feedback from '../Game/games/feedback/images/feedback.png';
import personifyLogo from '../Game/games/personify/images/logo.png';
import discussionForum from '../Game/games/discussionForum/images/Discussion_Forum_Wordmark.png';
import TrustBuild from '../Game/games/trustBehaviors/images/Trust-Build.jpg';


const GameCard = withStyles((theme) => ({
  root: {
    '&:focused': {
      background: deepPurple[100],
    },
  }
}))(CardContent);

const INITIAL_STATE = {
  optionsDisplay: undefined
}


export function getGameImage(game_id) {
  switch (game_id) {
    case 'zj8FYS7xS9bAqZRKsxDu':
      // Trust Behaviors
      return TrustBuild;
    case 'uTeZJmY1zc6PDDvMC1Xk':
      // Showcase Discussion Forum
      return Feedback;
    case 'f3Ec5g6amsoAaFr34U9J':
      // Personify
      return personifyLogo;
  case 'hKfvgwPfyOg1DKZ5WrFf':
      // County Fair
      return CountyFairLogo;
    case 'Rh4R3HbDo5J2WetudJrn':
      // Seeing the Point
      return SeeingThePointLogo;
    case 'BfxbeGXDrjAgfP9Y0ljZ':
      // Managing Time - Supervisors
      return stopDrop;
    case 'jYz1SgcVNJQ6n4c6JT3j':
    // Hidden Agenda
    return HiddenAgendaLogo;
    case 'I5vxqN2knisqFS2wJ3Hk':
    // On Deck
      return on_deck;
    case 'ynJQDyus16D9CIjtdyIN':
      // Conflict Scenarios
      return ioc;
    case 'FUm6uMZ9QhLkbsnmEbQN': 
      // Trust Spectrum - Individual
      return TrustLogo;
    case 'oAOQt7YfZzDTa2WiQ7ZR':
      // Trust Spectrum
      return TrustLogo;
    case 'QFbnz1m3ydc20VbuGVHP':
      // Bring, Brag, Show, Steal
      return bbss_logo;
    case 'vcZv6f1cOktHSds2kdme':
      // Emotional Intelligence
      return scenario;

    case 'YCSJs4yYGYTK5N6sLjZG':
      // Walk a While in my Shoes
      return walkawhileCropped;
    case '6HkVJoZZBa6JeZYrNKoz':
      // Best/Worst Boss
      return BWBLogo;
    case 'gMuD2xH6Kl37u9WbxYxN':
        // DiSC Conflict
        return DiSC_Icon;
    case 'VoJYJGwzxiUQap9ZJZEu':
      // DISC Cards
      return DiSC_Icon;
    case 'PiFZGYehVialCu1eRDvt':
      // Communication Styles
      return CommunicationStyles;
    case '3f19Vfx31DCuCRC9W3Fw':
      // Circle of Influence
      return COFLogo;
    case 'd0UzzPqrpkeiqdmONmYD':
      // Transition Peer to Boss
      return ptb;
    case 'i9VYXjMHierYCCFwcDvV':
      return set_expectations_square;  
    case 'ojQicb2jdFh77nNPn3Tv':
        // Feedback
        return Feedback;     
    case '7xgKmmwM9RlvOmYtY9Qc':
      // Tell Us About The Journey
      return plus_delta;
    case 'XHfImKbo8S2UDPl2cerz':
      // Tell Us About The Journey - Mod 3&4
      return plus_delta;
    case 'zjgMvCBlfUo25Xalulti':
        // Discussion Forum
        return discussionForum;
    case 'bJhzgqZsI9lzXZFsMHdi':
      // Discussion Forum (showcase)
      return discussionForum;
    case 'CFDb34wvDq0sC5BWfMPL':
      // Managing Time
      return stopDrop;
    case '6MK8K4f5uenoAFBBShto':
        // Possibly Aggregate
      return AggregateLogo;
    case 'VzVPeJxDCqc2K5Juo14e':
      // Distortion
      return Logo;
    case '4njCJXU1wrNjpWRToxvo':
        // Circle Words
        return CircleLogo;
    default:
      return placeholder;
  }
}

class GamesList extends Component {
  constructor(props) {
      super(props);
      this.state = {
          ...INITIAL_STATE
      }
  }

  startNewGame = (index, name) => {
    this.props.startGame(index, name);
  };

  

  goToGameOptions = (game_id) => {
    this.setState({
      ...this.state,
      optionsDisplay: game_id
    });
  }

  returnToGameList = () => {
    this.setState({
      ...this.state,
      optionsDisplay: undefined
    })
  }

  sortGames = (a,b) => { var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0; 
  }

  render() {
    const {
      classes,
      games,
      game_options,
      session,
      profile,
      gameData,
      auth
    } = this.props;

    if ( this.state.optionsDisplay !== undefined ){
      return <GameOptions game_id={this.state.optionsDisplay} game_name={gameData[this.state.optionsDisplay].name} session={session} auth={auth} profile={profile} options={game_options[this.state.optionsDisplay]} returnToGameList={this.returnToGameList}/>;
    } else {
      if (games && game_options !== undefined) {
        if (games.length > 0) {
          if (profile.isLoaded && profile.role === 'host') {
            return (
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <Typography variant='h4' className={`${classes.greyText}  ${classes.spacingBottom} ${classes.spacingTop}`}>
                    Activities
                  </Typography>

                  <Grid container spacing={2} className={classes.stretchContainer}>
                    {games.map((game, index) =>
                      game.available || process.env.NODE_ENV === 'development' ? (
                        <Grid item lg={4} key={index} className={classes.stretchMe} >
                          <Card className={classes.gameListRoot} >
                            <CardActionArea>
                              <CardMedia className={`${classes.media} ${classes.lightPurpleBG}`} image={this.getGameImage(game.id)} title={game.name} />
                              <GameCard >
                                <Typography gutterBottom variant='h5' component='h2' className={classes.glassPurple}>
                                  {game.name} {!game.available ? ' - Dev' : null}
                                </Typography>
                                <Typography variant='body1' color='textSecondary' component='p'>
                                  {game.description}
                                </Typography>
                              </GameCard>
                            </CardActionArea>
                            <CardActions>
                              {session !== undefined && Object.keys(session.active_game).length === 0 ? (
                                <div><Button size='small' onClick={() => this.startNewGame(game.id, game.name)} className={classes.glassPurple} startIcon={<PlayCircleFilledWhiteIcon />}>
                                  Start Activity
                                </Button>
                                { game_options !== undefined ? 
                                  game_options[game.id] !== undefined ? 
                                <Button size='small' onClick={() => this.goToGameOptions(game.id)} className={classes.glassPurple} startIcon={<ImageSearchIcon />}>
                                  Activity Options
                                </Button>
                                  : null
                                : null }
                                </div>
                              ) : (
                                ''
                              )}
                            </CardActions>
                          </Card>
                        </Grid>
                      ) : null
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    games: state.firestore.ordered.games,
    gameData: state.firestore.data.games,
    game_options: state.firestore.data.game_options
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startGame: (creds, name) => dispatch(startGame(creds, name)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => {
    return [
      {
        collection: 'games',
      },
      {
        collection: 'game_options'
      }
    ];
  })
)(GamesList);
