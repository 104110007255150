import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { deepPurple } from '@material-ui/core/colors';
import liquidImage from '../../images/loading-liquid.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  glassPurple: {
    color: deepPurple[500],
  },
  relativeItem: {
    position: "relative"
  },
  cup: {
    height: 60,
    width: 70,
    border: `4px solid #fff`,
    borderRadius: '0px 0px 15px 15px',
    background: `url(${liquidImage})`,
    boxShadow: `0px 0px 0px 4px ${deepPurple[500]}`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: '0px 70px',
    animation: `$fill 2.5s infinite`,
  },

  '@keyframes fill': {
    '0%': {
      backgroundPosition: '0px 70px',
    },
    '25%': {
      backgroundPosition: '-250px 30px',
    },
    '50%': {
      backgroundPosition: '-500px 10px',
    },
    '90%': {
      backgroundPosition: '-750px -30px',
    },
    '100%': {
      backgroundPosition: '0px 70px',
    },
  },

  handle: {
    height: 30,
    width: 15,
    backgroundColor: 'transparent',
    border: `4px solid ${deepPurple[500]}`,
    position: 'absolute',
    left: 70,
    top: 0,
    borderRadius: '0px 10px 20px 0px',
  },
}));

export default function LiquidLoadingBlockModal() {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{position: "fixed", top: 0, left: 0, width: '100%', height: '100%', background: "rgba(0,0,0,0.3)", zIndex: 9999}}>
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item className={classes.relativeItem}>
          <div className={classes.cup}>
            <div className={classes.handle}></div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
