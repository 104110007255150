/**********************************************************************
 *                                                                    *
 * File Name:     PastData Index                                      *
 * Purpose:       Site page for Viewing past session data             *
 * Author:        Ben Wakutz                                          *
 * Last Modified: 03/10/21                                            *
 *                                                                    *
 **********************************************************************/
import { firestoreConnect } from 'react-redux-firebase';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Footer from '../Footer';
import LogoHeader from '../Header';
import styles from '../Styles';
import PropTypes from 'prop-types';
import { Typography,TableContainer, Table, TableBody, TableCell, Collapse, TableHead, TableRow, Box, IconButton, MenuItem, } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import Navigation from '../Navigation';
import SessionData from './SessionData';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import ErrorBoundary  from '../Home/errorBoundary';
import { accessCheck } from './../Functions';

const INITIAL_STATE = {
  copied: false,
  valid: true,
  nameDialog: false,
  newName: '',
  targetID:'',
  rowOpen:{},
  selectedHostID: '',
};

export function getHost(profile, auth) {
    return profile.role === 'host' ? auth.uid : profile.sessionHost;
}

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

class PastData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };
  }

  componentDidMount(){
    if(this.props.profile.role === 'host'){
      this.setState({
        ...this.state,
        selectedHostID: this.props.auth.uid
      })
    };
    if(this.props.profile.role === 'moderator'){
      this.setState({
        ...this.state,
        selectedHostID: this.props.moderates        
      })
    };
  };

  onChange = (event) => {
    this.setState({
       ...this.state,
      [event.target.name]: event.target.value });
  };

  /*createSession = (event) => {
    event.preventDefault();
    this.props.maybeCreateSession(this.props.profile);
  };

  endSession = (event) => {
    event.preventDefault();
    this.props.endSession();
  };*/

  getHostsID=()=>{
    let newID='';
    if(this.props.profile.role === 'host'){
      newID=this.props.auth.uid;
    }
    if(this.props.profile.role === 'moderator'){
       newID=this.props.profile.moderates
    }
    return(newID);
  };
  
  render() {
    const { t, classes } = this.props;

    if ( this.props.profile.role === 'player' ){
      return null;
    }

    if ( !this.props.auth.uid ){
      return <Redirect to={ROUTES.LOGIN} />;
    }

    let name = this.props.profile === undefined ? '': this.props.profile.displayName
    let isAdmin = false;
    let hosts = [];
    let hostID= [];
    if(this.props.playerList !== undefined ){
    let idArray =Object.keys(this.props.playerList);
      idArray.map((uid,ind)=>{
        let user =this.props.playerList[uid];
        if ( this.props.profile.role === 'host' ){
          if ( user.role === 'host' && uid !== this.props.auth.uid ){
            return null;
          }          
          if ( user.role === 'moderator' && user.moderates !== this.props.auth.uid ){
            return null;
          }
        }
        if(user.role ==='host'){
          let hostUser = {
            ...user,
            uid: uid
          }
          hosts.push(hostUser);
          hostID.push(uid);  
        }
      });
    }

    if(this.props.profile.role === 'admin'){
      isAdmin = true;
    }
    return(
      <div className='meta-react'>
        <Navigation  profile={this.props.profile} auth={this.props.auth}/>
        <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} />
        <div className='outer-container'>
          <div className='container'>
            <Typography variant="h4">Past Sessions</Typography>
            <br/>
            <br/>
            {isAdmin ? (
              <React.Fragment>
              <Typography> DROP DOWN HOST SELECTOR</Typography>
              <InputLabel id='host-selector-label'>Please select host to view past session data.</InputLabel>
              <Select aria-describedby='Host selector' value={this.state.selectedHostID} name='selectedHostID' className={classes.spacingBottom} onChange={this.onChange}>
                {
                  hosts.map((t, index)=>{
                    return(
                    <MenuItem key={index} value={t.uid}>{t.displayName}</MenuItem>
                    )
                  })
                }
              </Select>

              
                {this.state.selectedHostID !== '' ?
                  <ErrorBoundary><SessionData hostID={this.state.selectedHostID}/></ErrorBoundary>
                :null}
                
              </React.Fragment>

            ):(
              <React.Fragment>
                <ErrorBoundary><SessionData hostID={this.getHostsID()} /></ErrorBoundary>
              </React.Fragment>
            )}
                
          </div>
        </div>
      <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/>
    </div>
    )
  }
}

PastData.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    hostInfo: state.firestore.data.hostInfo,
    playerList: state.firestore.data.playerList,
    pastSessions: state.firestore.data.pastSessions,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [
    {
      collection: 'players',
      storeAs: 'playerList'
    },
  ])
)(PastData);