import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import PieTable from './../specific_components/pieTable';
import countyFairIcon from '../images/county_fair.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
import { PDFHeader } from '../../../../PastData/PDFHeader';

const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        // session.active_game.results
        this.state = {
          ...INITIAL_STATE,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }   

    render() {
    const {
      results,
      classes,
      auth,
      profile,
      isPDFView
    } = this.props;

    if( this.props.isPDFView === true ){

        return (
            <Document>
                 <Page wrap style={{padding: "30 0"}}>
                    <PDFHeader/>
                    <View>
                        <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={countyFairIcon}/>
                    {Object.keys(results.results).sort().map((groupKey, ind) => {
                        return (
                        <React.Fragment key={ind}>
                            <View style={{marginHorizontal: "50", marginBottom: "40"}} wrap={false}>
                                <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>

                                <View style={{marginTop: "5", flexDirection: "row", borderRadius: "2", backgroundColor: "rgb(103, 58, 183)"}}>
                                        <View style={{ width:'16%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}></Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>APPLE</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>CHERRY</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>CHOCOLATE</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>PEACH</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>PECAN</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>BLUEBERRY</Text> 
                                        </View>
                                    </View>

                                    <View style={{marginTop: "1", flexDirection: "row", borderRadius: "2", borderBottom: "1pt solid #D3D3D3", borderRight: "1pt solid #D3D3D3"}}>
                                        <View style={{ width:'16%', textAlign: 'center', backgroundColor: "rgb(103, 58, 183)"}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>Sarah</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`1x1`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`1x2`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`1x3`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`1x4`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`1x5`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`1x6`]}</Text> 
                                        </View>
                                    </View> 
                                    <View style={{marginTop: "1", flexDirection: "row", borderRadius: "2", borderBottom: "1pt solid #D3D3D3", borderRight: "1pt solid #D3D3D3"}}>
                                        <View style={{ width:'16%', textAlign: 'center', backgroundColor: "rgb(103, 58, 183)"}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>Dave</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`2x1`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`2x2`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`2x3`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`2x4`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`2x5`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`2x6`]}</Text> 
                                        </View>
                                    </View>
                                    <View style={{marginTop: "1", flexDirection: "row", borderRadius: "2", borderBottom: "1pt solid #D3D3D3", borderRight: "1pt solid #D3D3D3"}}>
                                        <View style={{ width:'16%', textAlign: 'center', backgroundColor: "rgb(103, 58, 183)"}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>Betty</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`3x1`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`3x2`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`3x3`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`3x4`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`3x5`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`3x6`]}</Text> 
                                        </View>
                                    </View>
                                    <View style={{marginTop: "1", flexDirection: "row", borderRadius: "2", borderBottom: "1pt solid #D3D3D3", borderRight: "1pt solid #D3D3D3"}}>
                                        <View style={{ width:'16%', textAlign: 'center', backgroundColor: "rgb(103, 58, 183)"}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>Andy</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`4x1`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`4x2`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`4x3`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`4x4`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`4x5`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`4x6`]}</Text> 
                                        </View>
                                    </View>
                                    <View style={{marginTop: "1", flexDirection: "row", borderRadius: "2", borderBottom: "1pt solid #D3D3D3", borderRight: "1pt solid #D3D3D3"}}>
                                        <View style={{ width:'16%', textAlign: 'center', backgroundColor: "rgb(103, 58, 183)"}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10', fontWeight: 'bold', color: 'rgb(255, 255, 255)'}}>Ellen</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`5x1`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`5x2`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`5x3`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`5x4`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`5x5`]}</Text> 
                                        </View>
                                        <View style={{ width:'14%', textAlign: 'center'}}>
                                           <Text style={{ fontSize: '9pt', marginVertical: '10',  fontWeight: 'bold'}}>{results.results[groupKey]['table'][`5x6`]}</Text> 
                                        </View>
                                    </View>                                  
                                
                            </View>
                        </React.Fragment>       
                        );
                    })}
                    </View>
                </Page>
            </Document> 
        ) 
    }
   
        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(results.results)
                .sort()
                .map((groupKey, ind) => {
                    const newresults = results.results[groupKey] === undefined ? {} : results.results[groupKey].table === undefined ? {} : results.results[groupKey].table;
                    return (
                    <div key={ind}>
                        <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{results.groups[groupKey].title}</Typography>                        
                        <PieTable results={newresults} control={false}/>
                    </div>                    
                    );
                })}
            </Grid>
            </Grid>
        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return { };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);