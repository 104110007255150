import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';




    const valParser = (val) => {
        //console.log('val?', val);
        if ( val === undefined || val === false ){
            return '';
        } else {
            return val;
        }
    }

    const TableResult = (props) => {
        return (
            <div >
                &nbsp; {props.val} &nbsp;
            </div>
        )
    }

    const useStyles = makeStyles({
        tCell: {
          fontSize: "20px",        
        },
        normalTCell: {
            fontStyle: "italic",
            fontSize: "14px"
        },
        selectedtCell: {
            fontWeight: "bold"
        }

      });
    

  
    const PieTable = (props) => {
        
        const classes = useStyles();
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [curVal, setCurVal] = React.useState(null);
        const [curId, setCurId] = React.useState(null);
        const [results, setResults] = React.useState(props.results);

        React.useEffect( () => {
            setResults(props.results);
            console.log(props.results);
        }, [props.results]);

        const handlePopoverOpen = (event, thisVal, thisId) => {
            //console.log('here?', event);
            if ( !props.control ){
                return;
            }

            setCurVal(thisVal);
            setCurId(thisId);
            setAnchorEl(event.target);  

        };
        const handlePopoverClose = () => {
            setAnchorEl(null);
        }

        const handlePopoverClick = (val, id) => {
            setAnchorEl(null);
            console.log('???', val, id);
            props.updateResult(val, id);
        }

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
        <div>
            <Popover 
                open={open}
                id={id}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                ><div style={{padding:"10px"}}>
                    <div className={classes.tCell}   style={{padding:"5px", borderBottom: "1px solid #000", cursor: "pointer"}} onClick={(event) => handlePopoverClick(false, curId)}>---Clear---</div>
                    { curVal !== 'X' ? <div className={classes.tCell} style={{padding:"5px", borderBottom: "1px solid #000", cursor: "pointer"}} onClick={(event) => handlePopoverClick('X', curId)}>Eliminate Option (X)</div> : null }
                    { curVal !== 1 ? <div className={classes.tCell} style={{padding:"5px", borderBottom: "1px solid #000", cursor: "pointer"}} onClick={(event) => handlePopoverClick(1, curId)}>Set to 1st</div> : null }
                    { curVal !== 2 ? <div className={classes.tCell}  style={{padding:"5px", borderBottom: "1px solid #000", cursor: "pointer"}} onClick={(event) => handlePopoverClick(2, curId)}>Set to 2nd</div> : null }
                    { curVal !== 3 ? <div className={classes.tCell}  style={{padding:"5px", borderBottom: "1px solid #000", cursor: "pointer"}} onClick={(event) => handlePopoverClick(3, curId)}>Set to 3rd</div> : null }
                    { curVal !== 4 ? <div className={classes.tCell}  style={{padding:"5px", borderBottom: "1px solid #000", cursor: "pointer"}} onClick={(event) => handlePopoverClick(4, curId)}>Set to 4th</div> : null }
                    { curVal !== 5 ? <div className={classes.tCell}  style={{padding:"5px", borderBottom: "1px solid #000", cursor: "pointer"}} onClick={(event) => handlePopoverClick(5, curId)}>Set to 5th</div> : null }
                    { curVal !== 6 ? <div className={classes.tCell}  style={{padding:"5px"}} onClick={(event) => handlePopoverClick(6, curId)}>Set to 6th</div> : null }
                </div>
            </Popover>
            <TableContainer component={Paper} style={{marginTop:"30px"}}>
                <Table aria-label="simple table">
                <TableHead>
                    <TableRow style={{backgroundColor: "#673ab7", borderRadius: "0"}}>
                        <TableCell style={{borderRadius: "0"}}></TableCell>
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", color: "#fff", borderRadius: "0"}} align="center">Apple</TableCell>
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", color: "#fff", borderRadius: "0"}} align="center">Cherry</TableCell>
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", color: "#fff", borderRadius: "0"}} align="center">Chocolate</TableCell>
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", color: "#fff", borderRadius: "0"}} align="center">Peach</TableCell>
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", color: "#fff", borderRadius: "0"}} align="center">Pecan</TableCell>
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", color: "#fff", borderRadius: "0"}} align="center">Blueberry</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    <TableRow >
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", backgroundColor: "#673ab7", color: "#fff"}} >Sarah</TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`1x1`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`1x1`}  val={valParser(results[`1x1`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`1x2`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`1x2`}  val={valParser(results[`1x2`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`1x3`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`1x3`}  val={valParser(results[`1x3`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`1x4`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`1x4`}  val={valParser(results[`1x4`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`1x5`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`1x5`}  val={valParser(results[`1x5`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`1x6`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`1x6`} val={valParser(results[`1x6`])} /></TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", backgroundColor: "#673ab7", color: "#fff"}}>Dave</TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`2x1`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`2x1`}  val={valParser(props.results[`2x1`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`2x2`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`2x2`}  val={valParser(props.results[`2x2`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`2x3`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`2x3`}  val={valParser(props.results[`2x3`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`2x4`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`2x4`}  val={valParser(props.results[`2x4`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`2x5`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`2x5`}  val={valParser(props.results[`2x5`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`2x6`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`2x6`}  val={valParser(props.results[`2x6`])} /></TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", backgroundColor: "#673ab7", color: "#fff"}}>Betty</TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`3x1`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`3x1`}  val={valParser(props.results[`3x1`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`3x2`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`3x2`}  val={valParser(props.results[`3x2`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`3x3`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`3x3`}  val={valParser(props.results[`3x3`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`3x4`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`3x4`}  val={valParser(props.results[`3x4`])}/></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`3x5`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`3x5`}  val={valParser(props.results[`3x5`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`3x6`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`3x6`}  val={valParser(props.results[`3x6`])} /></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", backgroundColor: "#673ab7", color: "#fff"}}>Andy</TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`4x1`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`4x1`}  val={valParser(props.results[`4x1`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`4x2`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`4x2`}  val={valParser(props.results[`4x2`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`4x3`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`4x3`}  val={valParser(props.results[`4x3`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`4x4`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`4x4`}  val={valParser(props.results[`4x4`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`4x5`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`4x5`}  val={valParser(props.results[`4x5`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`4x6`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`4x6`}  val={valParser(props.results[`4x6`])} /></TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{fontSize: "16px", fontWeight: "bold", backgroundColor: "#673ab7", color: "#fff"}}>Ellen</TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`5x1`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`5x1`}  val={valParser(props.results[`5x1`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`5x2`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`5x2`}  val={valParser(props.results[`5x2`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`5x3`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`5x3`}  val={valParser(props.results[`5x3`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`5x4`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`5x4`}  val={valParser(props.results[`5x4`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`5x5`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`5x5`}  val={valParser(props.results[`5x5`])} /></TableCell>
                        <TableCell className={`${classes.tCell} ${valParser(props.results[`5x6`]) === 'X' ? classes.normalTCell : classes.selectedtCell}`}  align="center"><TableResult id={`5x6`}  val={valParser(props.results[`5x6`])} /></TableCell>
                    </TableRow>

                </TableBody>
                </Table>
            </TableContainer>
        </div>
        );
    }


export default PieTable
