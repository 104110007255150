
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFirebase } from 'react-redux-firebase';
import plus_delta from '../images/county_fair.png'
import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup, getUserGroup_hc, determineHost } from '../../game_functions/functions'

import {
    updateGroupState,
    editResultsPath,
    //editResultsPaths
} from './../../../../../store/actions/gameActions'
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { CLUES } from './../data/data';

const INITIAL_STATE = {    
    logoURLs: {},
    imagesLoaded: false    
  }

class Instructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };
    }

    processImages = async () => {
        let logos = {};
        const sto = getFirebase().storage();
        await Promise.all( Object.keys(this.props.game_options.game_images).map( async (key, ind) => {
            let ref = sto.ref(this.props.game_options.game_images[key]);
            let url = await ref.getDownloadURL();
            logos[key] = url;
        }));
        return Promise.resolve(logos);
    }

    async componentDidMount() {
        try {
            await this.processImages().then((logos) => {
                window.scrollTo(0, 0)
                this.setState({
                    ...this.state,
                    logoURLs : logos,
                    imagesLoaded: true
                }); 
                return Promise.resolve();
            });
        } catch (error) {
        } 
    }

    shuffle = (array)  => {
        var currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }

    dealCardsToPlayers = () => {
        const playersList = {};

        //console.log(CARDS);
        this.props.session.active_game.groups[this.props.group_id].playerIds.forEach((playerId, ind) => {
            playersList[playerId] = []
        })
            
       
        console.log(playersList);

        let cards = this.shuffle(CLUES);
        //console.log('cards: ', cards);
        let numPlayers = Object.keys(playersList).length;
        //console.log('num players: ', numPlayers);
        let cardsToHave = 1;
        let cardAdded = false;
        let i = 0;
        cards.forEach( (card, ind) => {
            cardAdded = false;
            i+=1;
            if ( i > numPlayers ){
                i = 1;
                cardsToHave += 1;
            }
            Object.keys(playersList).forEach( (playerId, ind) => {
                if ( playersList[playerId].length < cardsToHave && cardAdded === false ){
                    playersList[playerId].push(card);
                    cardAdded = true;
                }
            })            
        })
        

        return playersList;
    }

    sendToNextStep = (event) => {
        let groupID = getUserGroup_hc(
            this.props.session,
            this.props.auth.uid,
            this.props.profile.role
        )

        let startedOn =
            this.props.session.active_game.groupStates[groupID].params
                .startedOn === undefined ||
            this.props.session.active_game.groupStates[groupID].params
                .startedOn === null
                ? Date.now()
                : this.props.session.active_game.groupStates[groupID].params
                      .startedOn

        if (this.props.session.active_game.results[groupID] === undefined) {
            

            let p = `active_game.results.${this.props.group_id}.startedOn`;
            let p2 = `active_game.results.${this.props.group_id}.cards`;


            let v2 = this.dealCardsToPlayers();
            console.log(v2);

            let paths = [p,p2];
            let vals = [startedOn,v2];

            /* this.props.editResultsPaths(
                paths,
                vals,
                determineHost(this.props.profile, this.props.auth)
            ) */
        }

        let s = {
            state: GROUP_STATE_ACTIVE,
            params: {
                startedOn: startedOn
            }
        }
        this.props.updateGroupState(
            groupID,
            s,
            determineHost(this.props.profile, this.props.auth)
        )
    }

    render(){
        const { session, profile, auth, classes, leader, game_options } = this.props;

        let title = session.active_game.name;
        if ( game_options !== false ){
            title = game_options.game_texts.game_title;
        }
        return (
            <div>
               <Typography variant='h3' className={classes.spaceBottom}>
                    {title}
                </Typography>
               <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img src={this.state.logoURLs.main} className={classes.imageStyle} alt="Game"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' className={classes.spaceBottom} dangerouslySetInnerHTML={{ __html : game_options.game_texts.intro_text}}></Typography>
                        { hasGameControl(leader, auth.uid, profile)
                            ?
                            <Button type='submit' color="primary" variant='contained' onClick={this.sendToNextStep}>
                                Continue
                            </Button>
                            :
                            <Button type='submit' disabled={true} variant='contained'>
                                Leader can hit Continue
                            </Button>
                        }
                    </Grid>
                </Grid> 
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {  };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState: (group, groupState, hostID) =>
            dispatch(updateGroupState(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) =>
            dispatch(editResultsPath(path, value, hostID)),
        /* editResultsPaths: (paths, values, hostID) =>
            dispatch(editResultsPaths(paths, values, hostID)) */
    }
  };
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
  )(Instructions);
