
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFirebase } from 'react-redux-firebase';

import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup } from '../../game_functions/functions'
import { updateGroupState } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';

const INITIAL_STATE = {    
    logoURLs: {},
    imagesLoaded: false    
  }

class Instructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };
    }

    processImages = async () => {
        let logos = {};
        const sto = getFirebase().storage();
        await Promise.all( Object.keys(this.props.game_options.game_images).map( async (key, ind) => {
            let ref = sto.ref(this.props.game_options.game_images[key]);
            let url = await ref.getDownloadURL();
            logos[key] = url;
        }));
        return Promise.resolve(logos);
    }

    async componentDidMount() {
        try {
            await this.processImages().then((logos) => {
                window.scrollTo(0, 0)
                this.setState({
                    ...this.state,
                    logoURLs : logos,
                    imagesLoaded: true
                }); 
                return Promise.resolve();
            });
        } catch (error) {
        } 
    }

    sendToNextStep = (event) => {
        if ( this.props.profile.role === 'host' ){
            let groupID = this.props.session.active_game.hostState.params.group ? this.props.session.active_game.hostState.params.group : null;
            let s = { 
                    'state' : GROUP_STATE_ACTIVE,
                    'params' : {}
            };
            this.props.updateGroupState(groupID, s, this.props.auth.uid);
        } else {
            let groupID = getUserGroup(this.props.session, this.props.auth.uid);
            let s = { 
                    'state' : GROUP_STATE_ACTIVE,
                    'params' : {}
            };
            this.props.updateGroupState(groupID, s, this.props.profile.sessionHost);
        }
    }

    render(){
        const { session, profile, auth, classes, leader, game_options } = this.props;

        let title = session.active_game.name;
        if ( game_options !== false ){
            title = game_options.game_texts.game_title;
        }
        return (
            <div>
               <Typography variant='h3' className={classes.spaceBottom}>
                    {title}
                </Typography>
               <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img src={this.state.logoURLs.main} className={classes.imageStyle} alt="Game"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' className={classes.spaceBottom} dangerouslySetInnerHTML={{ __html : game_options.game_texts.intro_text}}></Typography>
                        { hasGameControl(leader, auth.uid, profile)
                            ?
                            <Button type='submit' color="primary" variant='contained' onClick={this.sendToNextStep}>
                                Continue
                            </Button>
                            :
                            <Button type='submit' disabled={true} variant='contained'>
                                Leader can hit Continue
                            </Button>
                        }
                    </Grid>
                </Grid> 
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {  };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState : (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID))
    };
  };
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
  )(Instructions);
