import React, { useState, CSSProperties } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
    TableBody, TableCell, Collapse, TableHead, TableRow, Box, } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import CSVReader from './file_reader';

export default function AddSession (props) {

    const [formFilledOut, setFormFilledOut] = useState(true);
    const [sessionName, setSessionName] = useState('');
    const [sessionDate, setSessionDate] = useState('');
    const [sessionError, setSessionError] = useState(false);
    const [csvMessage, setCsvMessage] = useState(false);
    const [csvData, setCsvData] = useState([]);

    const FORMAT = "YYYY-MM-DD[T]HH:mm";

    const onChangeName = (event) => {
        setSessionName(event.target.value);
    }
    const onChangeDate = (event) => {
        //console.log(event.target.value, moment(event.target.value).format("YYYY-MM-DD[T]HH:mm"), moment(event.target.value).utc().format("YYYY-MM-DD[T]HH:mm"));
       
       
        //console.log(moment(event.target.value).utc().local().format("YYYY-MM-DD[T]HH:mm") );
        setSessionDate(event.target.value);
    }
    const saveAddSession = () => {
        if ( moment().isAfter(sessionDate) || moment().isSame(sessionDate) ){
            setSessionError('Please choose a date that is sometime in the future.');
        } else {
            setSessionError(false);
            props.saveAddSession(sessionName, moment(sessionDate).utc().toISOString(), csvData);
        }
    }
    const closeEditSession = () => {
        setSessionError(false);
        props.closeEditSession();
    }
    const getUserData = (data) => {
        if ( data.data.length > 1 && data.errors.length <= 0 ){
            //console.log('got data', data.data);

            // let's parse what we want out of the data
            const playerData = data.data;
            const headers = playerData[0];
            let playerArray = [];

            if ( headers !== undefined ){

                let emailColumn = false;
                let nameColumn = false;
                let teamColumn = false;
                let nameColumns = {};

                const NAME_COL = 'name';
                const EMAIL_COL = 'email';
                const TEAM_COL = ['team', 'table', 'group'];
                


                headers.forEach((columnHeader, index) => {
                    if ( columnHeader.toLowerCase() === 'email' ){
                        emailColumn = index;
                    }
                    if ( columnHeader.toLowerCase() === 'name' ){
                        nameColumn = index;
                    }

                    if ( (columnHeader.toLowerCase() === TEAM_COL[0] || columnHeader.toLowerCase() === TEAM_COL[1] || columnHeader.toLowerCase() === TEAM_COL[2]) && teamColumn === false ){
                        teamColumn = index;
                    } 
                });

                // if there is no name column, we want to check and see if first name and last name are used
                if ( nameColumn === false ){
                    headers.forEach((columnHeader, index) => {
                        if ( columnHeader.toLowerCase() === 'first name' ){
                            nameColumn = null;
                            nameColumns['first'] = index;
                        }
                        if ( columnHeader.toLowerCase() === 'last name' ){
                            nameColumn = null;
                            nameColumns['last'] = index;
                        }
                    })
                }

             
                if ( nameColumn !== false && emailColumn !== false ){
                    
                    // parse the rest of the data
                    playerData.forEach((player, index) => {
                        // we don't want the headers
                        
                        if ( index > 0 ){
                            if ( nameColumn === null && nameColumns['first'] !== undefined ){
                                let pName = player[nameColumns['first']];
                                if ( nameColumns['last'] !== undefined ){
                                    pName = pName + " " + player[nameColumns['last']];
                                }
                                if ( player[emailColumn] !== undefined && pName !== '' ){
                                    let p = {
                                        'email' : player[emailColumn],
                                        'name' : pName
                                    };
                                    if ( teamColumn !== false ){
                                        p['group'] = player[teamColumn];
                                    }
                                    if ( p.email !== undefined && p.email !== '' && p.name !== undefined && p.name !== '' ){
                                        playerArray.push(p);
                                    }
                                }
                            } else {
                                if ( player[nameColumn] !== undefined && player[emailColumn] !== undefined ){
                                    let p = {
                                        'email' : player[emailColumn],
                                        'name' : player[nameColumn]
                                    };
                                    if ( teamColumn !== false ){
                                        p['group'] = player[teamColumn];
                                    }

                                    if ( p.email !== undefined && p.email !== '' && p.name !== undefined && p.name !== '' ){
                                        playerArray.push(p);
                                    }
                                }
                            }
                        }
                    });

                    //console.log("We've got data", playerArray);
                    setCsvData(playerArray);
                    setCsvMessage(false);                   
                }




            }

        } else {
            //console.log('got error from data or no data at all');
  
            setCsvMessage('Warning: either the selected CSV has no data, or an error has occured. Please try another file');
          
            setTimeout(() => {
                setCsvMessage(false);
            }, 4000); 
            /*setTimeout(() => {
                setCsvMessage(false);
            }, 4000);  */
        }
    }

    React.useEffect(() => {
        if ( sessionName === '' || sessionDate === '' ){
            setFormFilledOut(false);
        } else {
            if ( formFilledOut === false ){
                setFormFilledOut(true);
            }
        }
    }, [sessionName, sessionDate]);

    return (
        <Dialog aria-labelledby='add-planned-session' aria-describedby='Add Planned Session'  maxWidth='md'  open={props.showAddSession} fullWidth>
                        
                <AppBar position="static" className={props.classes.LNNoShadow}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={()=>props.closeAddSession()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                            <Typography variant="h6">
                                Add New Session             
                            </Typography>
                    </Toolbar>
                </AppBar>
            <DialogContent>

                <DialogContentText style={{paddingTop: 30}}>Please fill in the following fields to add a new session.</DialogContentText>
                
                <form className={props.classes.container} style={{display: 'flex', flexWrap: 'wrap'}} noValidate>
                    <div style={{width: '100%'}}><TextField id="outlined-basic" label="Session Title" placeholder={`Session Title`} value={sessionName} variant="outlined" onChange={onChangeName} style={{marginBottom: "20px", width: 400}}/></div>
                    <div style={{width: '100%'}}>
                    <TextField
                        id="datetime-local"
                        label="Date of Session"
                        type="datetime-local"
                        onChange={onChangeDate}
                        value={sessionDate}
                        className={props.classes.textField}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        style={{width: 250}}
                    />
                    </div>
                    <div style={{width: '100%', marginTop: "20px", marginBottom: "20px"}}>
                        <Typography variant="body1" style={{color: "red", fontWeight: "bold"}}>{sessionError}</Typography>                        
                    </div>
                    <div style={{width: '100%', marginTop: "20px", marginBottom: "60px"}}>
                        <Typography variant="body1">Upload Users</Typography>
                        <CSVReader getUserData={getUserData}/>
                        <Typography variant="body2">(Optional) This can be done later if needed. <br/>For appropriate parsing of data, ensure column headers of <b>email</b> and <b>name</b> are present.</Typography>
                    </div>
                    <div style={{width: '100%', marginTop: "20px", marginBottom: "20px"}}>
                        <Typography variant="body1" style={{color: "red", fontWeight: "bold"}}>{csvMessage}</Typography>                        
                    </div>
                </form>


                
            </DialogContent> 
            <DialogActions>
                <Button size='large' onClick={()=>props.closeAddSession()}>
                    Cancel
                </Button>
                <Button size='large' disabled={!formFilledOut} onClick={()=>saveAddSession()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
    
}