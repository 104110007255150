import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import AggregateBoard from './../images/AggregateBoard.png';
import LiquidLoadingBlock from '../../../../Loading/loadingCup';
import Typography from '@material-ui/core/Typography';
import GridArea from './../specific_components/gridArea';
import styles from '../../../../Styles';


class Results extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

  render() {
    const {
      classes,
    } = this.props;

    return (
      <div>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.spacingBottom}>Great Job!</Typography>
              <Typography variant="body1" className={classes.spacingBottom}>You've completed the activity successfully.</Typography>
              <div>
                  <Typography variant="body1">Waiting for other groups to finish their activity. When all groups are done, you will be taken to the debrief.</Typography>
                  <br />
                  <br />
                <div className={classes.cupContainerLeft}>
                  <LiquidLoadingBlock/>
                </div>
              </div>
              <GridArea control={false} results={this.props.session.active_game.results[this.props.group_id]} won={{ row: {}, col : {}}}/>
              
            </Grid>
          </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Results);