import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { updateGroupStateParamsVal } from '../../../../../store/actions/gameActions';
import ImgPuzzleClue from './../images/puzzleClue1.png';
import Typography from '@material-ui/core/Typography';
import { gameOptions } from './../../../gameServer';
import styles from '../../../../Styles';

const INITIAL_STATE = {
    timerActive: true,
    currentInterruption: null
}

class PuzzleClue extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }


    render() {
        const { classes } = this.props;
        let oImg = null;
        let p = this.props.session.active_game.Puzzle;
        let g = gameOptions(this.props.session.active_game.id);
        if ( p !== undefined ){
            g['Puzzle'].map((option, ind) => {
                if ( option.id === p ){
                    oImg = option.img;
                }
                return null;
            })
        }

        const theImg = this.props.session.active_game.Puzzle === undefined ? ImgPuzzleClue : oImg

        return (
            <div>
                <Grid container>
                    <Grid item xs={12} className={`${classes.spacingTop} ${classes.spacingBottom}`}>
                        <Typography variant="body1">You are now the lead engineer! Your contractor needs an accurate depiction of how to position the pieces to create the appropriate layout. The image below breaks down the different pieces and their positioning. Use it to describe to the contractor how they can complete the puzzle.</Typography>

                    </Grid>
                    <Grid item xs={12}>
                        <img src={theImg} alt='puzzle clue'className={classes.imageSize}/>
                    </Grid>
                </Grid>
            </div>
        )    
    }
}



const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
        updateGroupStateParamsVal : (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID))
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(PuzzleClue);
