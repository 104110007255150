import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Footer from '../Footer';
import { compose } from 'redux';
import Divider from '@material-ui/core/Divider';
import GOLLogo from '../../images/Glass-of-Learning-Logo-Main.jpg';
import Typography from '@material-ui/core/Typography';
import ErrorBoundary from '../Home/errorBoundary';
import ImageHeader from '../Header/imageHeader';
import styles from '../Styles';

class Default extends Component {

  componentDidMount() {  
  }

  componentDidUpdate(prevProps, prevState) {   
  }

  render() {
      const { classes } = this.props;

      return (
      <ErrorBoundary>
        <div className='meta-react'>
            <ImageHeader />
            <div className='outer-container'>
                <div className='container' style={{textAlign: 'center'}}>
                    <Typography variant="h3" className={classes.paddingTop}>Virtual Activities</Typography>
                    <Typography variant="h5">by Glass of Learning™</Typography>
                    <br/><br/>
                    <Typography variant="body1" style={{fontSize: '1.2rem'}}>Does your team work remotely? We have virtual options for learning and skill development.  Take part in interactive sessions with activities, information sharing and group or pair discussions, using a variety of on-line learning tools, followed with application leading to behavior change. <a className={classes.underlineText} target="_blank"  rel="noopener noreferrer" href="https://glassoflearning.com/design-learning-solutions/virtual-learning/">Learn more...</a></Typography>
                    <br/><br/><br/>
                    <Typography variant="body1">If you're looking to join up with a session, you will need a special URL. The host will provide you with this.</Typography>
                    <br/>
                    <Typography variant="body1"  className={classes.spacingBottom}>If you already have your URL, then please enter it in the URL bar of your browser and hit enter to get started.</Typography>
                    <Divider/>
                    <Typography variant="body1" className={classes.spacingTop}>If you are a host or facilitator, you can login <a href="/login" className={classes.underlineText}>here</a>.</Typography>
                    <br/><br/>
                    <img src={GOLLogo} alt='Glass of Learning Logo' style={{maxWidth: '350px'}}/>
                </div>
            </div>
            <Footer/>
        </div>
      </ErrorBoundary>
      );
  }
}


const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(Default);
