export const CardList = [
    {
        id: 1,
        card_words: 'Inconsistent results are the standard. This makes it challenging to know how good is good enough.',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 2,
        card_words: 'When working together, colleagues will warn others of potential problems which keeps us all safe.',
        card_subwords: null,
        card_fun: false
    },    
    {
        id: 3,
        card_words: 'There is confusion as to what the company direction is and what is most important to focus on. The flavor of the day can change frequently.',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 4,
        card_words: 'The perception is that information is being withheld by the leaders. Every problem here comes back to a lack of honest communication.',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 5,
        card_words: 'Employees and leaders consistently demonstrate their capability to do a good job.',
        card_subwords: null,
        card_fun: false
    },
    {
        id: 6,
        card_words: 'Rumors are circulating throughout the plant of approaching problems since sales are down and quality issues are increasing.',
        card_subwords: null,
        card_fun: false
    }
];


