import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { saveGroups, beginGame, endLoading } from '../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { gameOptions } from './../../components/Game/gameServer';
import styles from '../Styles';


const INITIAL_STATE = { };

class ActivityOptions extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() { }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    const {
      session,
      classes,
    } = this.props;

    const options = gameOptions(session.active_game.id);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
            {
                Object.keys(options).map((oKey, ind) => {
                    return (
                        <Grid container key={ind}>
                            
                            <Grid container>
                            { options[oKey].map((oItem, index) => {
                                return (
                                    <Grid item xs={3} id={oItem.id} align="center" onClick={() => this.props.setOption(oItem.id, oItem.title)} key={index}>
                                        <Typography variant="body1" style={{fontWeight: 'bold'}}>{oItem.title}</Typography>
                                        <img src={oItem.img} alt='' className={classes.imageSize}/>  
                                    </Grid>
                                )
                            })}
                            </Grid>
                        </Grid>
                    )
                })
            }
        </Grid>        
      </Grid>
    );
  }
}

ActivityOptions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    endLoading: () => dispatch(endLoading()),
    saveGroups: (groups) => dispatch(saveGroups(groups)),
    beginGame: (groups) => dispatch(beginGame(groups)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(ActivityOptions);