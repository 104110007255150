import React, { useState, useEffect } from 'react';
import DropArea from './dropArea';
import { CARDS_LIST, WON, SETS, SETS_EVALUATE, COLS, ROWS, HEARTS, SPADES, DIAMONDS, CLUBS, KINGS, QUEENS, JACKS, ACES, TWOS, THREES, FOURS, FIVES, SIXES, SEVENS, EIGHTS, NINES, TENS } from './../data/data';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const getProperResultValue = (r, k) => {
    if ( r === undefined ){
        return undefined;
    } else {
        if ( r[k] === undefined ){
            return undefined;
        }
        return r[k];
    }
}

const getGridStyle = () => ({
    display: 'flex',
    flexWrap: 'wrap',
    margin: '15px',
    minWidth: '105px',
    minHeight: `148px`,
    border: "1px solid #000",
    alignItems: 'left',
    justifyContent: 'left'
  });
  

const getRequiredClasses = (won, row, col) => {
    if ( won.row[row] === true && won.col[col] === true ){
        return 'card-success-col card-success-row';
    } else if ( won.row[row] === true ){
        return 'card-success-row';
    } else if ( won.col[col] === true ){
        return 'card-success-col';
    }
}


export default function GridArea(props) {
    const { control, results, won } = props;

    

    return (
        <div className="container secondary">
            <Grid container>
                { control ? 
                <Grid item xs={12} sm={12} md={4} className="max-this-width">
                    <div style={{paddingRight: "30px"}}>
                        <Typography variant="h5" style={{fontWeight:"bold", marginBottom: "20px"}}>{SETS[props.set]}</Typography>
                        <Typography vairant="body1" style={{marginBottom:"10px"}}>Using the deck of cards below, figure out the puzzle.</Typography>
                        <Typography vairant="body1" style={{marginBottom:"10px"}}>No row or column of the grid can have the same SUIT or same NUMBER/RANK.</Typography>
                        <Typography vairant="body1" style={{marginBottom:"10px"}}>The grid should contain only {SETS[props.set]}.</Typography>
                    </div>
                </Grid>
                : null }
                <Grid item xs={12} sm={12} md={control ? 8 : 12} align="left" style={{minWidth: "554px"}}>
                    <Grid container align="left" style={{alignItems: 'left', justifyContent: "left"}}>
                    <div className={won.row[1] === true ? 'card-success-row grid-area' : 'grid-area'} style={getGridStyle()}>
                        <div className={won.col[1] === true ? 'card-success-col grid-area' : 'grid-area'}>
                            <DropArea  card={getProperResultValue(results, '1x1')} control={props.control} id="1x1" disabled={false} source={props.source}/>
                        </div> 
                    </div>
                    <div className={won.row[1] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}> 
                        <div className={won.col[2] === true ? 'card-success-col grid-area' : ' grid-area'}>
                            <DropArea  card={getProperResultValue(results, '1x2')} control={props.control} id="1x2" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div className={won.row[1] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}> 
                        <div className={won.col[3] === true ? 'card-success-col grid-area' : ' grid-area'}>
                            <DropArea  card={getProperResultValue(results, '1x3')} control={props.control} id="1x3" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div style={getGridStyle()}> 
                        <div className={won.col[4] === true ? 'card-success-col grid-area' : ' grid-area'}>
                            <DropArea  card={getProperResultValue(results, '1x4')} control={props.control} id="1x4" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    
                    </Grid>
                    <Grid container style={{alignItems: 'left', justifyContent: "left"}}>
                    <div className={won.row[2] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}> 
                        <div className={won.col[1] === true ? 'card-success-col grid-area' : ' grid-area'}>
                            <DropArea  card={getProperResultValue(results, '2x1')} control={props.control} id="2x1" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div className={won.row[2] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}> 
                        <div className={won.col[2] === true ? 'card-success-col grid-area' : ' grid-area'}>
                            <DropArea  card={getProperResultValue(results, '2x2')} control={props.control} id="2x2" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div className={won.row[2] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}> 
                        <div className={won.col[3] === true ? 'card-success-col grid-area' : ' grid-area'}>
                            <DropArea  card={getProperResultValue(results, '2x3')} control={props.control} id="2x3" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div style={getGridStyle()}> 
                        <div className={won.col[4] === true ? 'card-success-col grid-area' : ' grid-area'}>
                            <DropArea  card={getProperResultValue(results, '2x4')} control={props.control} id="2x4" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    
                    </Grid>
                    <Grid container style={{alignItems: 'left', justifyContent: "left"}}>
                    <div className={won.row[3] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}>
                        <div className={won.col[1] === true ? 'card-success-col grid-area' : ' grid-area'}> 
                            <DropArea  card={getProperResultValue(results, '3x1')} control={props.control} id="3x1" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div className={won.row[3] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}> 
                        <div className={won.col[2] === true ? 'card-success-col grid-area' : ' grid-area'}>
                            <DropArea  card={getProperResultValue(results, '3x2')} control={props.control} id="3x2" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div className={won.row[3] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}> 
                        <div className={won.col[3] === true ? 'card-success-col grid-area' : ' grid-area'}>
                            <DropArea  card={getProperResultValue(results, '3x3')} control={props.control} id="3x3" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div  style={getGridStyle()}> 
                        <div className={won.col[4] === true ? 'card-success-col grid-area' : ' grid-area'}>
                            <DropArea  card={getProperResultValue(results, '3x4')} control={props.control} id="3x4" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    
                    </Grid>
                    <Grid container style={{alignItems: 'left', justifyContent: "left"}}>
                    <div className={won.row[4] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}> 
                        <div>
                            <DropArea card={getProperResultValue(results, '4x1')}  control={props.control} id="4x1" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div className={won.row[4] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}> 
                        <div>
                            <DropArea  card={getProperResultValue(results, '4x2')} control={props.control} id="4x2" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div className={won.row[4] === true ? 'card-success-row grid-area' : ' grid-area'} style={getGridStyle()}> 
                        <div>
                            <DropArea  card={getProperResultValue(results, '4x3')} control={props.control} id="4x3" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    <div style={getGridStyle()}> 
                        <div>
                            <DropArea  card={getProperResultValue(results, '4x4')} control={props.control} id="4x4" disabled={false} source={props.source}/>
                        </div>
                    </div>
                    
                    </Grid>
                </Grid>
                
            </Grid>
        </div>
    )

}