import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Draggable } from 'react-beautiful-dnd';
//import { deepPurple } from '@material-ui/core/colors';
import styles from '../../../../Styles';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  padding: "0",
  margin: "12px 12px 0 12px",
  // change background colour if dragging
  //background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
  background: 'none'
});

const truncateStr = (str, n) => {
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}

class PostIt extends Component {
  
  handleClick = () => {
    console.log('this');
  }

  render() {
    const { classes } = this.props;
    console.log('TESTING', );

    const hasControl = (this.props.author === this.props.auth.uid);

    const name = this.props.playerProfiles[this.props.author] === undefined ?
                  'Host / Moderator' :
                  this.props.playerProfiles[this.props.author].displayName;
    

    const cls = this.props.auth.uid === this.props.author ? 'highlight' : '';
    console.log('class', cls);

    let the_stuff_to_show = `${truncateStr(this.props.text, 50)} 
    <br/><br/>
    <span style="margin-top: 10px; font-weight: bold; font-size: 10px">
    - ${name}</span>`
    

    if ( hasControl ){
      return (      
        <Draggable draggableId={`${this.props.id}`} index={this.props.index}>
        {(provided, snapshot) => (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                <div className={`${`postitv2`} ${cls}`} onClick={() => this.props.itemAction(this.props.id, this.props.text, hasControl)}>
                  <div className={classes.nameSpacing} dangerouslySetInnerHTML={{ __html: the_stuff_to_show}}>
                    
                    
                  </div>
                  
                </div>
          </div>
          )}
        </Draggable>      
      );
    } else {
      return (      
        <Draggable draggableId={`${this.props.id}`} index={this.props.index}>
        {(provided, snapshot) => (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                <div className={`${`postitv2`} ${cls}`} onClick={() => this.props.itemAction(this.props.id, this.props.text, hasControl)}>
                  <div className={classes.nameSpacing} dangerouslySetInnerHTML={{ __html: the_stuff_to_show}}>
                    
                    
                  </div>
                  
                </div>
          </div>
          )}
        </Draggable>      
      );
    }
  }
}

PostIt.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PostIt);
