import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import { Redirect } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { accessCheck } from './../Functions';
import TimeToLeave from '@material-ui/icons/TimeToLeave';
import Grid from '@material-ui/core/Grid';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import AssessmentIcon from '@material-ui/icons/Assessment';

const INITIAL_STATE = {
  menuDrawer: false,
  anchor: 'left',
  redirect: false,
  redirectLink: null,
};

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  handleMenu = (event) => {
    this.setState({
      anchorEll: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  openDrawer = () => {
    this.setState({
      menuDrawer: true,
    });
  };

  goToVirtual = () => {    
    window.open('https://virtual.glassoflearning.com', '_blank').focus();
  }

  toggleDrawer = () => {
    this.setState({
      menuDrawer: false,
    });
  };

  clickLink = ({ link }) => {
    this.setState({
      menuDrawer: false,
      redirectLink: link,
      redirect: true,
    });
  };

  sendUserInfo = (userID) => {
    this.props.editingThisUser(userID);
  };

  render() {
    const { t, classes, auth, profile } = this.props;
    const { menuDrawer, anchor, redirect, redirectLink } = this.state;
    const name = profile === undefined ? '': profile.displayName;

    const signOutLinks = auth.uid ? null : (
      <Button type='button' onClick={this.handleClose} variant='contained' compontent={Link} to={ROUTES.LOGIN}>
        {t('navigation.signInButton')}
      </Button>
    );
    return (
      <div>
        {redirect ? <Redirect push to={redirectLink} /> : null}
        <AppBar position='static'>
          <Toolbar className='nav-wrapper darken-3' style={{backgroundColor: "#673ab7"}}>
            <Grid container>
              <Grid item xs={6}>
              <div>
              <IconButton aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={this.openDrawer} color='inherit'>
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer anchor={anchor} open={menuDrawer} onClose={this.toggleDrawer} onOpen={this.openDrawer}>
                <div className={classes.drawerHeader}>
                  <IconButton onClick={this.toggleDrawer}>
                    <ChevronLeftIcon />
                  </IconButton>{' '}
                  <span className={classes.playersTitle}>Menu</span>
                </div>
                <Divider />
                <List className={classes.list}>
                { accessCheck(profile.role, ['host', 'admin'] ) === true ?
                  <div><ListItem button onClick={() => this.clickLink({ link: ROUTES.DASHBOARD })}>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary='Dashboard' />
                  </ListItem>
                  <Divider />
                  </div>
                    : null
                  }
                  { accessCheck(profile.role, ['host', 'admin'] ) === true ?
                  <div><ListItem button onClick={() => this.clickLink({ link: ROUTES.ADMIN })}>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary='User Management' />
                  </ListItem>
                  <Divider />
                  </div>
                    : null
                  }

                  { accessCheck(profile.role, ['host', 'admin'] ) === true ?
                  <div><ListItem button onClick={() => this.clickLink({ link: ROUTES.THEME })}>
                    <ListItemIcon>
                      <ColorLensIcon />
                    </ListItemIcon>
                    <ListItemText primary='Theme Management' />
                  </ListItem>
                  <Divider />
                  </div>
                    : null
                  }

                { accessCheck(profile.role, ['host', 'admin'] ) === true ?
                  <div><ListItem button onClick={() => this.clickLink({ link: ROUTES.PLANNING })}>
                    <ListItemIcon>
                      <ScheduleIcon />
                    </ListItemIcon>
                    <ListItemText primary='Session Planning' />
                  </ListItem>
                  <Divider />
                  </div>
                    : null
                  }


                  <ListItem button onClick={() => this.clickLink({ link: ROUTES.PASTDATA })}>
                    <ListItemIcon>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary='Past Data' />
                  </ListItem>
                  <Divider />

                  <ListItem button onClick={() => this.clickLink({ link: ROUTES.CONTACT })}>
                    <ListItemIcon>
                      <ContactMailIcon />
                    </ListItemIcon>
                    <ListItemText primary='Contact' />
                  </ListItem>
                  <Divider />

                  
                  <ListItem button onClick={() => this.goToVirtual()}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary='Go to Virtual' />
                  </ListItem>
                  <Divider />
                 
                  <ListItem button onClick={this.props.signOut}>
                    <ListItemIcon>
                      <PowerSettingsNewIcon />
                    </ListItemIcon>
                    <ListItemText primary='Logout' />
                  </ListItem>
                </List>
              </SwipeableDrawer>
            </div>
              </Grid>
              <Grid item xs={6}>
              <div align="right" style={{padding:"12px"}}>Welcome, <span style={{fontWeight: "bold", textTransform: "uppercase"}}>{name}</span></div>
              </Grid>
            </Grid>
            
           
            
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDisptachToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles), connect(mapStateToProps, mapDisptachToProps))(Navigation);
