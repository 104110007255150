export const PROFILE_SELF_AWARENESS = 'self_awareness';
export const PROFILE_SELF_MANAGEMENT = 'self_management';
export const PROFILE_SOCIAL_AWARENESS = 'social_awareness';
export const PROFILE_RELATIONSHIP_MANAGEMENT = 'relationship_management';

export const PROFILE_TYPE_HAS = 'has_skill';
export const PROFILE_TYPE_LACKS = 'lacks_skill';

export const PROFILE_VALUES = {
    [PROFILE_SELF_AWARENESS] : {
        color: "#b35e3e",
        title: 'Self-Awareness'
    },
    [PROFILE_SOCIAL_AWARENESS] : {
        color: "#2a9da3",
        title: 'Social Awareness'
    },
    [PROFILE_SELF_MANAGEMENT] : {
        color: "#5891cd",
        title: 'Self-Management'
    },
    [PROFILE_RELATIONSHIP_MANAGEMENT] : {
        color: "#8f72a3",
        title: 'Relationship Management'
    }
}


export const PROFILE_MALE = 'Microsoft Guy Online (Natural) - English (United States)';
export const PROFILE_FEMALE = 'Microsoft Clara Online (Natural) - English (Canada)';

export const PROFILE_MALE2 = 'Google UK English Male';
export const PROFILE_FEMALE2 = 'Google UK English Female';

export const ProfileList = [
    {
        id: 8,
        profile_step : 1,
        profile_title: "Nora - Warehouse Manager",
        profile_type : PROFILE_TYPE_LACKS,
        profile_skill : PROFILE_RELATIONSHIP_MANAGEMENT,
        profile_text: `“Nora often minimizes a person’s point of view or experience. She justifies bad situations by stating that it could always be worse, you just don’t understand, or you should just get over it. She comes across as blunt and not empathic, particularly with her direct reports. I want her to be more genuine in her interactions with them, and show a general appreciation for others. Nora needs to stop finding faults in every situation. It is tiring and de-motivating. She needs to start recognizing people’s achievements. There is a stigma that exists that Nora is tough, difficult to work for and unapproachable. It would be nice if Nora could see that encouraging people to share what they think could be done differently, is actually helpful for development. However, her continued negative feedback comes across as her feeling the need to belittle people. People no longer value her input, and at times view it as her need to be seen as superior.”`,
        profile_gender: PROFILE_FEMALE,
        profile_gender2: PROFILE_FEMALE2
    },
    {
        id: 2,
        profile_step : 2,
        profile_title: "Roger - Maintenance Manager",
        profile_type : PROFILE_TYPE_LACKS,
        profile_skill : PROFILE_SELF_AWARENESS,
        profile_text: `“Roger is very much in his ‘own little world’. He obviously does care about his team, but he doesn’t seem to know where to draw a line. His personality can be overwhelming, but he doesn’t notice when the other person is feeling annoyed, frustrated, or overwhelmed by him. Roger is passionate about what he does. Sometimes that passion gets in the way. He might not notice that I am busy with something else before he jumps in and starts talking to me. When he is excited, he talks over you, and it is hard to get a word in edge-wise. He doesn’t mean to, he is just excited about what he does.”`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 6,
        profile_step : 3,
        profile_title: "Marco - Production Manager",
        profile_type : PROFILE_TYPE_LACKS,
        profile_skill : PROFILE_SELF_MANAGEMENT,
        profile_text: `“Marco needs to allow others to feel good about their ideas, even when he has a better plan. He also needs to be more patient, and allow them to have equally effective plans that are just different from his plan. I would like him to seek to understand what people are feeling and thinking and notice what evidence there is regarding situations before speaking his opinion or offering solutions. He typically seems oblivious to others’ reactions. He is so focused on work and sometimes comes across as not interested in what’s going on with a person on that particular day. When he has a new idea (or ideas from his former plant), he has a hard time explaining them so the operators will accept them. He seems stuck on his position and unwilling to accept other people’s viewpoints or include their input in his decisions.”`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 3,
        profile_step : 4,
        profile_title: "Ru - Lab Manager",
        profile_type : PROFILE_TYPE_HAS,
        profile_skill : PROFILE_SOCIAL_AWARENESS,
        profile_text: `“Ru handles stressful and confrontational situations very well. No matter how harshly engineers push Ru, he never loses his cool! This gives him a lot of credibility with the engineers. He’s also able to work with other people whose working style he isn’t a fan of. I know going back and forth with them can be frustrating sometimes, but Ru never loses his patience. I have never heard Ru speak negatively about someone who has a different opinion or idea. A lot of talking behind people’s backs happens around here, and he doesn’t give in to the temptation, even when he feels strongly about an issue.”`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 1,
        profile_step : 5,
        profile_title: "Joanna - HR Manager",
        profile_type : PROFILE_TYPE_HAS,
        profile_skill : PROFILE_SELF_AWARENESS,
        profile_text: `“In every situation that I have been involved with, good or bad, Joanna has always remained calm, cool and collected – even at times when I know she must have felt frustrated or angry. Joanna is really honest about what she is feeling without getting bent out of shape about it. When faced with a difficult situation, she knows how to be firm and still be kind at the same time. During challenging situations with employees, Joanna is very aware of her tone and makes an effort to keep the conversation appropriate. People here trust her.”`,
        profile_gender: PROFILE_FEMALE,
        profile_gender2: PROFILE_FEMALE2
    },
    {
        id: 5,
        profile_step : 6,
        profile_title: "Miriam - Engineer",
        profile_type : PROFILE_TYPE_HAS,
        profile_skill : PROFILE_SELF_MANAGEMENT,
        profile_text: `“Miriam has an uncanny ability to spot and address the issues no one is talking about, within a group. She does a good job acknowledging other people’s feelings when communicating difficult news. She reflects how others are feeling and adapts her communication style to help reach a resolution. She gets to know people on a personal level so she can better understand their perspectives and work well with them. Miriam is the most effective ‘active listener’ I have ever seen. She is skilled at communicating the ‘context’ for her comments with the goal of ensuring understanding. She is respectful toward others while being able to establish her authority. Miriam motivates and inspires people. She can uplift people and put them at ease.”`,
        profile_gender: PROFILE_FEMALE,
        profile_gender2: PROFILE_FEMALE2
    },    
    {
        id: 7,
        profile_step : 7,
        profile_title: "Jacob - Mining Manager",
        profile_type : PROFILE_TYPE_HAS,
        profile_skill : PROFILE_RELATIONSHIP_MANAGEMENT,
        profile_text: `“Jacob has an innate ability to read people and their emotions, and he uses what he learns to create a safe and inviting forum for discussion. There has never been a time that Jacob’s door was not ’open’ when I have needed him, and he always manages to maintain a pleasant and professional manner even when his workload is demanding. People know that they can count on Jacob and what they say to him in confidence will be respected and not repeated. Jacob’s interaction skills are supreme. In situations that I’ve witnessed him less than pleased with a specific outcome, he has always expressed his position with thoughtful insight about his expectations without anger or outbursts. I’d describe him as direct, yet free from confrontation or sounding out of control. Jacob is respected for collecting feedback before drawing conclusions. His ability to empathize with others is outstanding, and it creates positive, strong relationships.”`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },    
    {
        id: 4,
        profile_step : 8,
        profile_title: "Steve - Process Engineer",
        profile_type : PROFILE_TYPE_LACKS,
        profile_skill : PROFILE_SOCIAL_AWARENESS,
        profile_text: `“In stressful situations, or when something goes wrong, Steve sometimes responds too quickly, sharply or clumsily. I wish Steve would take some time to cool off and slow down before responding. He’s so emotional. I have seen his coworkers respond in disbelief to the manner in which he communicated with them. Steve means well but can panic when he is stressed. His reactions ripple onto his teammates. He lets his emotions rule his behavior. Sometimes he acts or speaks hurriedly. I wish he would be a bit more patient and give the situation the opportunity to work itself out before reacting. Many times these situations resolve themselves or aren’t quite as urgent as he perceives, but before you know it, he’s heightened the intensity with a flurry of messages.”`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
];


export const ProfileListHTML = [
    {
        id: 8,
        profile_step : 1,
        profile_title: "Nora - Warehouse Manager",
        profile_type : PROFILE_TYPE_LACKS,
        profile_skill : PROFILE_RELATIONSHIP_MANAGEMENT,
        profile_text: `“Nora often <span style='font-weight:bold'>minimizes a person’s point of view or experience</span>. She <span style='font-weight:bold'>justifies bad situations</span> by stating that it could always be worse, you just don’t understand, or you should just get over it. She <span style='font-weight:bold'>comes across as blunt and not empathic</span>, particularly with her direct reports. I want her to be more genuine in her interactions with them, and show a general appreciation for others. Nora <span style='font-weight:bold'>needs to stop finding faults in every situation</span>. It is tiring and de-motivating. She <span style='font-weight:bold'>needs to start recognizing people’s achievements</span>. There is a stigma that exists that <span style='font-weight:bold'>Nora is tough, difficult to work for and unapproachable</span>. It would be nice if Nora could see that encouraging people to share what they think could be done differently, is actually helpful for development. However, her <span style='font-weight:bold'>continued negative feedback</span> comes across as her feeling the need to <span style='font-weight:bold'>belittle people</span>. People no longer value her input, and at times view it as her need to be seen as superior.”`,
        profile_gender: PROFILE_FEMALE,
        profile_gender2: PROFILE_FEMALE2
    },
    {
        id: 2,
        profile_step : 2,
        profile_title: "Roger - Maintenance Manager",
        profile_type : PROFILE_TYPE_LACKS,
        profile_skill : PROFILE_SELF_AWARENESS,
        profile_text: `“Roger is very much in his <span style='font-weight:bold'>‘own little world’</span>. He obviously does care about his team, but he <span style='font-weight:bold'>doesn’t seem to know where to draw a line</span>. His <span style='font-weight:bold'>personality can be overwhelming</span>, but he <span style='font-weight:bold'>doesn’t notice when the other person is feeling annoyed</span>, frustrated, or overwhelmed by him. Roger is passionate about what he does. Sometimes that passion gets in the way. He <span style='font-weight:bold'>might not notice that I am busy with something else before he jumps in and starts talking to me</span>. When he is excited, <span style='font-weight:bold'>he talks over you</span>, and it is <span style='font-weight:bold'>hard to get a word in edge-wise</span>. He doesn’t mean to, he is just excited about what he does.”`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 6,
        profile_step : 3,
        profile_title: "Marco - Production Manager",
        profile_type : PROFILE_TYPE_LACKS,
        profile_skill : PROFILE_SELF_MANAGEMENT,
        profile_text: `“Marco needs to allow others to feel good about their ideas, even when he has a better plan. He also needs to <span style='font-weight:bold'>be more patient</span>, and <span style='font-weight:bold'>allow them to have equally effective plans that are just different from his plan</span>. I <span style='font-weight:bold'>would like him to seek to understand what people are feeling</span> and thinking and <span style='font-weight:bold'>notice what evidence there is</span> regarding situations <span style='font-weight:bold'>before speaking his opinion or offering solutions</span>. He typically <span style='font-weight:bold'>seems oblivious to others’ reactions</span>. He is <span style='font-weight:bold'>so focused on work</span> and <span style='font-weight:bold'>sometimes comes across as not interested in what’s going on with a person</span> on that particular day. When he has a new idea (or ideas from his former plant), he has a hard time explaining them so the operators will accept them. He <span style='font-weight:bold'>seems stuck on his position and unwilling to accept other people’s viewpoints</span> or include their input in his decisions.”`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 3,
        profile_step : 4,
        profile_title: "Ru - Lab Manager",
        profile_type : PROFILE_TYPE_HAS,
        profile_skill : PROFILE_SOCIAL_AWARENESS,
        profile_text: `“Ru handles stressful and confrontational situations very well. No matter how harshly engineers push Ru, <span style='font-weight:bold'>he never loses his cool</span>! This gives him a lot of credibility with the engineers. He’s also <span style='font-weight:bold'>able to work with other people whose working style he isn’t a fan of</span>. I know going back and forth with them can be frustrating sometimes, but <span style='font-weight:bold'>Ru never loses his patience</span>. I have <span style='font-weight:bold'>never heard Ru speak negatively about someone who has a different opinion or idea</span>. A lot of talking behind people’s backs happens around here, and <span style='font-weight:bold'>he doesn’t give in to the temptation, even when he feels strongly about an issue</span>.”`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 1,
        profile_step : 5,
        profile_title: "Joanna - HR Manager",
        profile_type : PROFILE_TYPE_HAS,
        profile_skill : PROFILE_SELF_AWARENESS,
        profile_text: `“In every situation that I have been involved with, good or bad, Joanna has always <span style='font-weight:bold'>remained calm, cool and collected</span> – even at times when I know she must have felt frustrated or angry. Joanna is really <span style='font-weight:bold'>honest about what she is feeling</span> without getting bent out of shape about it. When faced with a difficult situation, she knows how to be <span style='font-weight:bold'>firm and still be kind at the same time</span>. During challenging situations with employees, Joanna is very <span style='font-weight:bold'>aware of her tone</span> and makes an effort to keep the conversation appropriate. People here trust her.”`,
        profile_gender: PROFILE_FEMALE,
        profile_gender2: PROFILE_FEMALE2
    },
    {
        id: 5,
        profile_step : 6,
        profile_title: "Miriam - Engineer",
        profile_type : PROFILE_TYPE_HAS,
        profile_skill : PROFILE_SELF_MANAGEMENT,
        profile_text: `“Miriam has an uncanny ability to <span style='font-weight:bold'>spot and address the issues no one is talking about</span>, within a group. She does a good job <span style='font-weight:bold'>acknowledging other people’s feelings when communicating difficult news</span>. She <span style='font-weight:bold'>reflects how others are feeling</span> and <span style='font-weight:bold'>adapts her communication style to help reach a resolution</span>. <span style='font-weight:bold'>She gets to know people on a personal level</span> so she can better <span style='font-weight:bold'>understand their perspectives</span> and work well with them. Miriam is the most effective <span style='font-weight:bold'>‘active listener’</span> I have ever seen. She is skilled at <span style='font-weight:bold'>communicating the ‘context’ for her comments</span> with the goal of ensuring understanding. She is respectful toward others while being able to establish her authority. Miriam motivates and inspires people. She can uplift people and put them at ease.”`,
        profile_gender: PROFILE_FEMALE,
        profile_gender2: PROFILE_FEMALE2
    },    
    {
        id: 7,
        profile_step : 7,
        profile_title: "Jacob - Mining Manager",
        profile_type : PROFILE_TYPE_HAS,
        profile_skill : PROFILE_RELATIONSHIP_MANAGEMENT,
        profile_text: `“Jacob has an <span style='font-weight:bold'>innate ability to read people and their emotions</span>, and he <span style='font-weight:bold'>uses what he learns to create a safe and inviting forum for discussion</span>. There has never been a time that Jacob’s door was not ’open’ when I have needed him, and he <span style='font-weight:bold'>always manages to maintain a pleasant and professional manner even when his workload is demanding</span>. People know that they can count on Jacob and <span style='font-weight:bold'>what they say to him in confidence will be respected and not repeated</span>. Jacob’s interaction skills are supreme. In situations that I’ve witnessed him less than pleased with a specific outcome, he <span style='font-weight:bold'>has always expressed his position with thoughtful insight about his expectations without anger or outbursts</span>. I’d describe him as direct, yet free from confrontation or sounding out of control. Jacob is <span style='font-weight:bold'>respected for collecting feedback before drawing conclusions</span>. His <span style='font-weight:bold'>ability to empathize with others is outstanding</span>, and it creates positive, strong relationships.”`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },    
    {
        id: 4,
        profile_step : 8,
        profile_title: "Steve - Process Engineer",
        profile_type : PROFILE_TYPE_LACKS,
        profile_skill : PROFILE_SOCIAL_AWARENESS,
        profile_text: `“In stressful situations, or when something goes wrong, Steve sometimes <span style='font-weight:bold'>responds too quickly, sharply or clumsily</span>. I wish Steve would take some time to cool off and slow down before responding. <span style='font-weight:bold'>He’s so emotional</span>. I have seen his coworkers respond in disbelief to the manner in which he communicated with them. Steve means well but <span style='font-weight:bold'>can panic when he is stressed</span>. His reactions ripple onto his teammates. He <span style='font-weight:bold'>lets his emotions rule his behavior</span>. Sometimes he acts or speaks hurriedly. I wish he would be a bit more patient and give the situation the opportunity to work itself out before <span style='font-weight:bold'>reacting</span>. Many times these situations resolve themselves or aren’t quite as urgent as he perceives, but before you know it, <span style='font-weight:bold'>he’s heightened the intensity with a flurry of messages</span>.”`,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
];


