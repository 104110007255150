export const CLUES = [
    {
        id: 1,
        text: 'There were six entries in the pie contest at the county fair.'
    },
    {
        id: 2,
        text: 'Judges awarded prizes for first, second, third, fourth, fifth, and sixth place.'
    },
    {
        id: 3,
        text: 'The contest judges awarded first prize to the apple pie.'
    },
    {
        id: 4,
        text: 'The peach pie won second place.'
    },
    {
        id: 5,
        text: 'The cherry pie was so sour the judges did not place it in the top five places.'
    },
    {
        id: 6,
        text: 'Betty\'s pie placed lower than Andy\'s pie and higher than Dave\'s pie.'
    },
    {
        id: 7,
        text: 'Ellen\'s pie was one of the best she ever made.'
    },
    {
        id: 8,
        text: 'Sarah substituted brown sugar for regular sugar in her pie.'
    },
    {
        id: 9,
        text: 'Sarah did not receive either first or sixth prize.'
    },
    {
        id: 10,
        text: 'Andy has never entered a fruit pie in the county fair.'
    },
    {
        id: 11,
        text: 'The chocolate pie placed one place higher than the pecan pie.'
    },
    {
        id: 12,
        text: 'One person won two prizes, for fifth and sixth places and one of these pies was Blueberry.'
    },
]

