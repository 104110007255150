import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { PROFILE_EMPLOYEE, PROFILE_MANAGER, ProfileList } from '../profiles/profileList_temp';
import styles from '../../../../Styles';
/* updateGroupStateParams to toggle the profiles, etc */

const INITIAL_STATE = {
    open: false
}

function capitalizeFLetter(s) { 
    return s[0].toUpperCase() + s.slice(1); 
}

class WalkProfileResult extends Component {
    constructor(props){
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        }); 
    }

    openProfile = () => {
        this.setState({
            ...this.state,
            open: true
        });        
    }  

    getCorrespondingProfiles = (step) => {
        let e = ProfileList.find(obj => {
          return (obj.profile_step === step && obj.profile_type === PROFILE_EMPLOYEE)
        });
        let m = ProfileList.find(obj => {
          return (obj.profile_step === step && obj.profile_type === PROFILE_MANAGER)
        });
        return {
          [PROFILE_EMPLOYEE] : e,
          [PROFILE_MANAGER] : m          
        };
      }
      
  endSpeech = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.state.speechBot.cancel();      
  }

  render() {
      
    const { classes, type, stepTitle, step } = this.props;
    const { open } = this.state;
    const walkProfile = this.getCorrespondingProfiles(parseInt(step))[type];

    return (
        <div>
            <Dialog onClick={this.handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose} >
                   <span className={classes.inlineProfileScenario}>{stepTitle}</span> - <span className={classes.inlineProfileDescriptor}>{capitalizeFLetter(type)} </span> 
                </DialogTitle>
                <DialogContent dividers className={classes.noPad}> 
                    <Grid container className={classes.alignVerticalCenter} style={{alignItems: 'stretch'}}>
                        <Grid item sm={4} xs={12} align="center" style={{backgroundImage: `url(${require(`./../images/p${walkProfile.id}.jpeg`)})`, backgroundSize: 'cover', minHeight: '200px', backgroundPosition: 'center'}}>
                        </Grid>
                        <Grid item sm={8} xs={12} className={classes.padMe}>
                            <Typography variant="body1">
                                {walkProfile.profile_text}
                            </Typography>
                        </Grid>                           
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size="small" color="primary" onClick={this.handleClose}>
                        Done
                    </Button>                    
                </DialogActions>
            </Dialog>                 
            <Grid container className={classes.alignVerticalCenter}>
                <Grid item xs={12} className={classes.cardPadding} align="center">
                    <div className={classes.relativeIconParent}>                        
                        <Avatar alt="Remy Sharp" src={require(`./../images/avatar/p${walkProfile.id}.jpeg`)}  onClick={() => this.openProfile()} className={classes.profileAvatar} style={{cursor: 'pointer'}}/>
                    </div>
                    <Typography variant="body1" className={`${classes.profileTypeDescriptor} ${classes.minorMargin}`}>
                        {capitalizeFLetter(type)}
                    </Typography>                                
                </Grid>
            </Grid>     
        </div>
    ); 
  }
}

const mapStateToProps = (state) => {
        return {}
    };
    
    const mapDispatchToProps = (dispatch) => {
        return {        
            
        };
    };

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(WalkProfileResult);



