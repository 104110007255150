import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import { updateGroupStateParamsVal, editResultsPath, updateGroupStateStateVal } from '../../../../../store/actions/gameActions';
//import { createPiecesGroup } from './../utils/createPiecesGroup';
import paper from "paper/dist/paper-core"
import { Tangram } from './tangramImport';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
    //GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
    //HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE,
    GROUP_STATE_RESULTS, GROUP_STATE_ACTIVE, GAME_STATE_RESULTS
  } from '../../../../../store/actions/gameActions';
import { Stage, Layer, Text, Image, Line, Transformer } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import { v4 as uuidv4 } from 'uuid';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


import styles from '../../../../Styles';

//import { wordGroups } from '../data/data.js';


const INITIAL_STATE = {
    timerActive: true,
    currentInterruption: null,
    x: 50,
    y: 50,
    isDragging: false,
    images: [],
    isDrawing: false,
    lines: []
}

const URLImage = ({ image, isDrawing, handleDragEnd, dragStart, transformEnd, control, openContext, deselectAll, resetDeselectAll }) => {
    //console.log('what', image, isSelect);
    const [img] = useImage(image.src);
    const trRef = React.useRef();
    const imgRef = React.useRef();
    const [isSelected, setIsSelected] = React.useState([false]);


    React.useEffect(() => {
        //console.log('HMMM', deselectAll);
        if ( deselectAll === true ){
            setIsSelected(false);
            resetDeselectAll();
        }
    }, [deselectAll])

    React.useEffect(() => {
        //console.log("INSIDE SELECTED THING", isSelected);
        if (isSelected === true && control === true) {
          // we need to attach transformer manually
          trRef.current.nodes([imgRef.current]);
          trRef.current.getLayer().batchDraw();
        }
      }, [isSelected]);
    
    //console.log('selected', isSelected);


    let offsetX = img ? parseInt(image.width) / 2 : 0;
    let offsetY = img ? parseInt(image.height) / 2 : 0;

    const canvasWidth = 900;
    const canvasHeight = 700;

    const contextHandler = (e) => {
        //console.log('context', e);
        //e.stopPropagation();
        e.evt.preventDefault();
        setIsSelected(true);
        openContext(e.evt, image.id);
    }

    return (
        <React.Fragment>
             <Image
                ref={imgRef}
                image={img}
                x={image.x}
                y={image.y}
                id={image.id}
                dataId={image.dataId}
                rotation={image.rotation}
                scaleX={image.scaleX !== undefined ? image.scaleX : 1}
                scaleY={image.scaleY !== undefined ? image.scaleY : 1}
                onClick={(e) => {
                    e.evt.preventDefault();
                    if ( e.evt.button === 0 ){
                        setIsSelected(isSelected === true ? false : true);
                    }
                }} 
                dragBoundFunc={(pos) => {
                    var newY = pos.y < 10 ? 10 : pos.y;
                    newY = newY > canvasHeight ? canvasHeight : newY;

                    var newX = pos.x < 10 ? 10 : pos.x;
                    newX = newX > canvasWidth ? canvasWidth : newX;

                    return {
                      x: newX,
                      y: newY,
                    };
                }}
                onContextMenu={contextHandler}
                width={parseInt(image.width)}
                height={parseInt(image.height)}
                // I will use offset to set origin to the center of the image
                offsetX={offsetX}
                offsetY={offsetY}
                draggable={isDrawing === true ? false : control === true ? true : false}
                onDragEnd={handleDragEnd}
                onDragStart={dragStart}  
                onTransformEnd={transformEnd}  
            />

            {(isSelected === true && control === true ) ? (
                <Transformer
                ref={trRef}
                boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    //console.log('DOOT', oldBox, newBox);
                    if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                    }
                    return newBox;
                }}
                />
            ) : null}
            
            </React.Fragment>
     
    );
  };

const WordPlayground = (props) => {
       
    const stageRef = React.useRef();
    const [images, setImages] = React.useState([]);

    const [lines, setLines] = React.useState([]);
    const isDrawing = React.useRef(false);

    const [enableDrawing, setEnableDrawing] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);

    const [mouseY, setMouseY] = React.useState(null);
    const [mouseX, setMouseX] = React.useState(null);
    const [contextImageId, setContextImageId] = React.useState(null);

    const [deselectAll, setDeselectAll] = React.useState(false);

    //console.log('wtf', props.draggedEl);


    React.useEffect(() => {
        /*if ( props.control  ){
            if ( !loaded ){
                // only set this way for someone 
                if ( lines.length === 0 && images.length === 0 && (props.results.lines !== undefined || props.results.images !== undefined )){
                    if (props.results.lines !== undefined){
                        let newLines = props.results.lines.slice();
                        setLines(newLines);
                    }
                    if ( props.results.images !== undefined){
                        let newImages = props.results.images.slice();
                        setImages(newImages);
                    }
                    setLoaded(true);
                }
            }
        } else {*/
            if (props.results.lines !== lines && props.results.lines !== undefined){
                let newLines = props.results.lines.slice();
                setLines(newLines);
            }
            if ( props.results.images !== images && props.results.images !== undefined){
                let newImages = props.results.images.slice();
                setImages(newImages);
            }
        //}

        //console.log('RESULTS ARE CHANGING');
      }, [props.results]);

    const handleTransformEnd = (event) => {
        //setImageToState(e);
        //return;
        //console.log('Transform Done: ', event, images);
        
        let scaleX = event.target.attrs.scaleX;
        let scaleY = event.target.attrs.scaleY;
        let rotation = event.target.attrs.rotation;

        const id = event.target.attrs.id;
        
        const slicedImages = images.slice();
        
        const imageInQuestion = slicedImages.find(i => i.id === id);
        const index = slicedImages.indexOf(imageInQuestion);

        //console.log('Image in Question', imageInQuestion);
        let newImage = {
            ...imageInQuestion,
            scaleX : scaleX,
            scaleY : scaleY,
            rotation: rotation,
            x: event.target.x(),
            y: event.target.y()
        };

        slicedImages[index] = newImage;

        if ( props.control ){
            setImages(
                slicedImages
            );
        }
        props.updateImages('update', slicedImages);

        //console.log('Transform Done: ', scaleX, scaleY, rotation);
        
    }

    
    const onDragStartImage = (e) => {
        //const id = e.target.attrs.id;
        //console.log('Drag Start!', images);
        //console.log('HM ', id);

        if ( isDrawing.current === true ){
            //console.log('NO');
            //e.evt.preventDefault();
            //e.evt.stopPropagation();
            return false;
        }

  
        
    }


    const sendToBack = (e) => {
        //console.log('Sending to back', contextImageId);
        const id = contextImageId;

        const items = images.slice();
        //console.log('Images', items);
        const item = items.find(i => i.id === id);
        const index = items.indexOf(item);
        // remove from the list:
        items.splice(index, 1);

        let newImage = {
            ...item
        }

        // add to the back
        items.unshift(newImage);
        if ( props.control ){
            setImages(
                items
            );
        }
        props.updateImages('update', items);
        handleContextClose();
    }

    


   

    const handleImageDragEnd = (e) => {
        
        //console.log('Drag End!', images, e);
        const id = e.target.attrs.id;

        const items = images.slice();
        //console.log('Images', items);
        const item = items.find(i => i.id === id);
        const index = items.indexOf(item);
        // remove from the list:
        items.splice(index, 1);

        //console.log('Image in Question', imageInQuestion);
        let newImage = {
            ...item,
            x : e.target.x(),
            y : e.target.y()
        }

        //items[index] = newImage;

        setDeselectAll(true);
        // add to the top
        items.push(newImage);
        if ( props.control ){
            setImages(
                items
            );
        }
        props.updateImages('update', items);

        //const image = params.image;

        

        /*const images = images.slice();
        const imageInQuestion = images.find(i => i.id === image.id);
        const index = images.indexOf(imageInQuestion);

        let newImage = {
            ...image,
            x : pos.x
        }*/

        /*if (self.props.type === 'personal' ){
                return;
            }
            let x = e.target.x();
            let y = e.target.y();
            console.log('dragend', x, y, e);
            console.log('HA', self.refs[`recLayer_${self.props.type}_${self.props.group_id}`]);

            console.log('the text of the dragged item', e.target.children[1].attrs.text);
            let theText = e.target.children[1].attrs.text;

            let path = `active_game.results.${self.props.group_id}.sandbox.${theText}`;
            let val = {
                x : x,
                y : y
            }
            self.props.editResultsPath(path, val, self.props.host);*/
    }


    const handleMouseDown = (e) => {
        if ( !enableDrawing || !props.control ){
            return;
        }
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        //console.log('Mouse Down', pos);
        setLines([...lines, { id: uuidv4(), tool : enableDrawing === true ? 'pen' : 'eraser', points: [pos.x, pos.y] }]);
    };
    
    const handleMouseMove = (e) => {
        //console.log('EL?', props.draggedEl);
        // no drawing - skipping
        if (!isDrawing.current) {
          return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();

        let lastLine = lines[lines.length - 1];
        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y]);

        // replace last
        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
    };

    const undoDraw = () => {
        //console.log('UNDOING', lines);
        if ( !props.control ){
            return;
        }
        let newLines = lines.slice();
        //console.log('UNDOING2', lines.slice());
        let lastLine = lines[lines.length - 1];
        if ( newLines.length > 0 ){
            newLines.pop();
            setLines(newLines);
        }
        props.updateLines('pop', newLines);
    }
    
    const handleMouseUp = () => {
        if ( isDrawing.current === false ){
            return;
        }
        isDrawing.current = false;
        let lastLine = lines[lines.length - 1];
        props.updateLines('add', lastLine);
    };


    const handleDragStart = (e) => {
     
    };

    const toggleDrawing = () => {
        setEnableDrawing(
            enableDrawing === true ? false : true
        );
    }

    const addElementToCanvas = (el) => {
        //console.log('Adding to canvas', el);
        let newImage = {
            ...stageRef.current.getPointerPosition(),
            src: el.src,
            width: el.width,
            height: el.height,
            dataId: el.id,
            scaleX: 1,
            scaleY: 1,
            rotation: 0,
            id: uuidv4()
        };
        setImages(
            images.concat([
                newImage
            ])
        ); 
        props.updateImages('add', newImage);          
    }

    const openContextMenu = (event, contextImageId) => {
        //console.log('OPENING', event);
        setContextImageId(contextImageId);
        setMouseY(event.clientY - 4);
        setMouseX(event.clientX - 2);
    }

    const handleContextClose = () => {
        setMouseY(null);
        setMouseX(null);
        setContextImageId(null);
        setDeselectAll(true);
    }

    const resetDeselectAll = () => {
        setDeselectAll(false);
    }

    const doDeSel = (event) => {
        console.log(event);
        if ( event.target.attrs.id === 'theStage' ){
            setDeselectAll(true);
        }
        
    }

    const deletePiece = () => {
        //console.log('Sending to back', contextImageId);
        const id = contextImageId;

        const items = images.slice();
        //console.log('Images', items);
        const item = items.find(i => i.id === id);
        const index = items.indexOf(item);
        // remove from the list:
        items.splice(index, 1);

       
        if ( props.control ){
            setImages(
                items
            );
        }
        props.updateImages('update', items);
        handleContextClose();
    }

    return (
       
            <div>
                { props.control ? 
                <Button
                  variant="contained"
                  color={enableDrawing === true ? `secondary` : `primary`}
                  startIcon={<CreateIcon/>}
                  onClick={toggleDrawing}
                  style={{marginRight: "20px"}}
                >
                  { enableDrawing === true ? `Drawing Enabled` : `Enable Drawing` }
                </Button>
                : null }

                { props.control && lines.length > 0 ? 
                <Button
                  variant="contained"
                  color={`primary`}
                  startIcon={<DeleteOutlineIcon/>}
                  onClick={undoDraw}
                  style={{marginRight: "20px"}}
                >
                   Undo Last Draw 
                </Button>
                : null }

                <Menu
                    keepMounted
                    open={mouseY !== null && mouseX !== null}
                    onClose={handleContextClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        mouseY !== null && mouseX !== null
                        ? { top: mouseY, left: mouseX }
                        : undefined
                    }
                    >
                    <MenuItem onClick={sendToBack}>Send to Back</MenuItem>
                    <MenuItem onClick={deletePiece}>Remove this Piece</MenuItem>
                    <MenuItem>--------------</MenuItem>
                    <MenuItem onClick={handleContextClose}>Cancel</MenuItem>
                </Menu>

                <div style={{height: "760px", width: '900px', marginTop: "20px"}} id="white-board" onDrop={(e) => {
                    e.preventDefault();
                    stageRef.current.setPointersPositions(e);
                    //console.log("DROPPING!", props.draggedEl);
                    //console.log('Position?: ', stageRef.current.getPointerPosition() );
                    addElementToCanvas(props.draggedEl);

                    
                    }}
                    onDragOver={(e) => e.preventDefault()}
                >
                    <div className="whiteboard-base-text">{props.title === undefined ? 'Create your Best/Worst Leader' : props.title}</div>
                    <Stage width={900} height={700} onMouseDown={handleMouseDown} ref={stageRef} id="theStage"
                    onMousemove={handleMouseMove}
                    onMouseup={handleMouseUp}
                    onClick={doDeSel}>
                        
                        <Layer>
                        {images.map((image, ind) => {
                            return <URLImage 
                                    key={ind} 
                                    isDrawing={isDrawing.current} 
                                    image={image} 
                                    handleDragEnd={handleImageDragEnd} 
                                    dragStart={onDragStartImage} 
                                    transformEnd={handleTransformEnd} 
                                    control={props.control} 
                                    openContext={openContextMenu} 
                                    deselectAll={deselectAll} 
                                    resetDeselectAll={resetDeselectAll}
                                />;
                        })}                        
                        </Layer>
                        <Layer>
                        {lines.map((line, i) => (
                            <Line
                            key={i}
                            points={line.points}
                            stroke="#000"
                            strokeWidth={line.tool === 'eraser' ? 15 : 5}
                            tension={0.5}
                            lineCap="round"
                            globalCompositeOperation={
                                line.tool === 'eraser' ? 'destination-out' : 'source-over'
                            }
                            />
                        ))}
                        </Layer>
                    </Stage>
                </div>
               
            </div>
       
    )
}




  
export default WordPlayground;
