import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import ActivityListPlus from '../specific_components/activityListPlus';
import ActivityListDelta from '../specific_components/activityListDelta';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import journeyIcon from '../images/tuatj.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
  import { PDFHeader } from './../../../../PastData/PDFHeader';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        // results.results
        this.state = {
          ...INITIAL_STATE,
        };
    }

 

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.results.resultSnapshot === undefined ? {} : this.props.results.resultSnapshot, this.props.results.results)
        })
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
    const {
      results,
      classes,
      profiles,
      isPDFView
    } = this.props;



    if(this.props.isPDFView === true){
        return (
            <Document>
                 <Page wrap style={{padding: "30 0"}}>
                    <PDFHeader/>
                    <View>
                        <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={journeyIcon }/>
                        {Object.keys(results.results)
                        .sort()
                        .map((groupKey, ind) => {
                        return (
                        <React.Fragment key={ind}>
                            <View style={{marginHorizontal: "50", marginBottom: "50"}} wrap={false}>
                                <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>                                    
                                <View style={{marginVertical: "5", flexDirection: "row",}} wrap={false}>
                                    {/* Plus View */}
                                    <View style={{ width: "49%", borderRadius: '3', border: "1pt solid #000", marginRight: "10"}}>
                                        <View style={{ flexDirection: "row", textAlign: "center", backgroundColor:"rgb(103, 58, 183)"}}>
                                            <Text style={{color: "#fff", paddingVertical: "10"}}>PLUS +</Text>
                                        </View>
                                        <View style={{backgroundColor: "rgb(255, 255, 255)"}}>
                                        { 
                                            results.results[groupKey] !== undefined ?
                                                results.results[groupKey]["plus"] !== undefined ?
                                            
                                                    results.results[groupKey]['plus'].map((item, i) =>{
                                                        return(
                                                            <Text key={i} style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}>{item}</Text>
                                                        )
                                                    })
                                            
                                                : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                            : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                        }
                                       </View>
                                    </View>
                                    {/* Delta View */}
                                    <View style={{ width: "49%", borderRadius: '3', border: "1pt solid #000"}}>
                                        <View style={{ flexDirection: "row", textAlign: "center", backgroundColor:"rgb(103, 58, 183)"}}>
                                            <Text style={{color: "#fff", paddingVertical: "10"}}>DELTA ∆</Text>
                                        </View>
                                        <View style={{backgroundColor: "rgb(255, 255, 255)"}}>
                                        { 
                                            results.results[groupKey] !== undefined ?
                                                results.results[groupKey]["delta"] !== undefined ?
                                            
                                                    results.results[groupKey]['delta'].map((item, i) =>{
                                                        return(
                                                            <Text key={i} style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}>{item}</Text>
                                                        )
                                                    })
                                            
                                                : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                            : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                        }
                                        </View>
                                    </View>
                                </View>
                            </View> 
                        </React.Fragment>       
                        );
                    })}
                    </View>
                </Page>
            </Document> 
        ) 
    }
       
        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
                { /*: null */ }
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(results.results)
                .sort()
                .map((groupKey, ind) => {
                    return (
                    <div key={ind}>
                        <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{results.groups[groupKey].title}</Typography>
                        <Grid container spacing={2}>        
                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12}  align="center" className={classes.tableHead}>
                                    Plus <AddIcon/>
                                </Grid>
                                <Grid item xs={12}  align="center" className={classes.tableBody}>
                                    <ActivityListPlus results={results} group_id={groupKey} debrief={true} hasGameControl={false} showList={true}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <Grid item xs={12}  align="center" className={classes.tableHead}>
                                    Delta <ChangeHistoryIcon/> <sup className={classes.tableHeadSup}>(change)</sup>
                                </Grid>
                            <Grid item xs={12}  align="center" className={classes.tableBody}>
                                    <ActivityListDelta results={results} group_id={groupKey} debrief={true} hasGameControl={false} showList={true} />
                            </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    );
                })}
            </Grid>
            </Grid>
        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);