import React, { Component } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import OnDeckCard from './card';
import PutIn from './../images/put-in-button.png';
import Sort from './../images/sort.png';

const getListStyle = (isDraggingOver, hasCard, source) => ({
  backgroundColor: source ? "rgba(103, 58, 183, 0.3)" : "#fff",
  backgroundImage: isDraggingOver && !source ? hasCard ? `url(${Sort})` : `url(${PutIn})` : 'none',
  backgroundSize: "75px 75px",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'hidden',
  margin: '0',
  height: "148px",
  width: '102px',
  alignItems: 'center',
  justifyContent: 'center'
});



/* 
  -----------

    Props:
    id: (int) drop area id
    key: (int)
    cardId: (int) id of the card to display
    control: (boolean)


    TODO: need data to include a set of cards, each with an ID associated with it. That way, we can just pass the ids through and do the lookup
 
  -----------
*/

export default function DropArea(props) {

      const [card, setCard] = React.useState(props.card);

      const dragStart = () => {
        //console.log('test');
      }

      React.useEffect(() => {
        //console.log('card: ', props.card);
        setCard(props.card);
      }, [props.card])

      if ( props.control === true ){
        return (                 
          <Droppable droppableId={props.id} key={props.id} >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver, (card !== undefined && card !== false) ? true : false, props.source !== false && props.source === props.id ? true : false)} >
                { props.card !== undefined && props.card !== false ?
                  <OnDeckCard control={props.control} cardId={card} key={card} index={0} visible={snapshot.isDraggingOver ? false : true}/>
                  : <div style={{textAlign:"center", fontSize: "10px", fontStyle: "italic", opacity: snapshot.isDraggingOver ? "0" : "0.5", transition: "opacity 0.2s"}}>Drop Card Here</div>
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>        
      );
      } else {
        return (                 
              <div style={getListStyle(false, props.direction === 'vertical' ? true : false, true)}>
                { props.card !== undefined && props.card !== false ?
                  <OnDeckCard control={props.control} cardId={card} key={card} index={0} visible={ true}/>
                  : null
                }
              </div>
        );
      }
}


