/**********************************************************************
 *                                                                    *
 * File Name:     adminActions                                        *
 * Purpose:       Handle the action related to admin                  *
 * Author:        Ben Wakutz                                          *
 * Last Modified: 03/10/21                                            *
 *                                                                    *
 **********************************************************************/

import { FirestoreReducer } from "react-redux-firebase";
import { batch } from "react-redux";
import moment from 'moment-timezone';
import group from "../../components/PlanningPage/groups/group";


const MailHeaderHTML = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html>
  <head>
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="content-type" content="text/html; charset=UTF-8">
    <title>Webinar Reminder</title>
    
  <style type="text/css">
		img{
			border:none;
			-ms-interpolation-mode:bicubic;
			max-width:100%;
		}
		body{
			font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
			-webkit-font-smoothing:antialiased;
			font-size:14px;
			line-height:1.4;
			margin:0;
			padding:0;
			color:#666;
			background-color:#ccc;
			-ms-text-size-adjust:100%;
			-webkit-text-size-adjust:100%;
		}
		table{
			margin:0 auto;
		}
</style></head>
  <body class="">
  <div style="width:100%;height:100%;background-color:#ccc">
    <table border="0" cellpadding="0" cellspacing="0" width="600">
      <tr>
        <td class="container">
          
          <!-- START CENTERED WHITE CONTAINER -->
          
          <table width="600" border="0" cellpadding="0" cellspacing="0">
            <!-- START MAIN CONTENT AREA -->
            <tr>
              <td>
                <table border="0" cellpadding="0" cellspacing="0" width="600" >
                  <tr>
                    <td>
                      <img src="https://glassoflearning.com/wp-content/uploads/golvirtual_email/header.png" style="display:block;" alt="header.png" width="600">
                    </td>
                  </tr>
                </table>
                <table border="0" cellpadding="0" cellspacing="0" width="600">
                  <tr>
                    <td bgcolor="#ffffff">
                      <table border="0" cellpadding="0" cellspacing="0" width="600" bgcolor="#ffffff" style="background-color:#fff">
                        <tr>
                          <td width="40"> </td>
                          <td width="520" bgcolor="#ffffff" style="background-color:#fff">
                            <br>
                            <br>                            
`;

const MailFooterHTML = `
</td>
                      <td width="40"> </td>
                    </tr>
                    <tr>
                      <td colspan="3">
							<br/><br/>
							<table border="0" cellpadding="0" width="600" style="border-top: 8px solid #6E5895">
								<tr>
									<td width="20"></td>
									<td width="200" style="font-size: 10px;"><br/>© COPYRIGHT 2015 - 2022 | GLASS OF LEARNING. ALL RIGHTS RESERVED</td>
									<td width="60"></td>
									<td width="200" align="right" style="text-align: right"><br/><img width="125" src="https://glassoflearning.com/wp-content/uploads/golvirtual_email/footer_logo.png" alt="Virtual Glass" title="Virtual-Glass-Wordmark-OneLine" ><br/><br/></td>									
									<td width="20"></td>
								</tr>
							</table>

				
				
			
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
          
        </tr>
      </table>
    </td>
  </tr>
</table>
</div>
</body>
</html>
`;

export const renameUser = (name,userID) => {
  if( name === null){
    
  }
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

      firestore
       .collection('players')
       .doc(userID)
       .update({
         displayName: name
       })
        .then(() => {

          dispatch({ type: 'RENAME_SUCCESS' });
        })
        .catch((err) => {
          dispatch({ type: 'RENAME_ERROR', err });
        });
    };
  };

  export const closeAdminPopUps = () => {
    return (dispatch, getState) => {
      dispatch({ type: 'CLOSE_ADMIN_POPUPS' });
    };
  };

  export const resetPasswordFromAdmin =(email) =>{
    return(dispatch, getState,{getFirebase, getFirestore}) =>{
      const firebase = getFirebase();
      const firestore = getFirestore();
      firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(()=>{
        dispatch({type:'RESETPASS_SUCCESS'});
      })
      .catch((err) =>{
        dispatch({ type: 'RESETPASS_ERROR', err});
      });
    }

  };

  export const toggleStatus = (userID,status) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
      if (status === 'Disabled'){
      firestore
       .collection('players')
       .doc(userID)
       .update({
         status : 'Active'
       })
        .then(() => {

          dispatch({ type: 'ENABLE_SUCCESS' });
        })
        .catch((err) => {
          dispatch({ type: 'ENABLE_ERROR', err });
        });
      }
      if(status === 'Active'){
        firestore
       .collection('players')
       .doc(userID)
       .update({
         status: 'Disabled'
       })
        .then(() => {
          
          dispatch({ type: 'DISABLE_SUCCESS' });
        })
        .catch((err) => {
          dispatch({ type: 'DISABLE_ERROR', err });
        });
      }
    };
  };

  export const createNewEmailUser = (firstName,lastName,eMail,passWord,mod,modsWho) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firebase = getFirebase();
      const firestore = getFirestore();
      let roletype='';
      const addUser = firebase.functions().httpsCallable('addUserFromAdmin');
      let data ={ };
      dispatch({type:'LOADING'});
      
      if(mod === false){
        roletype ='host'
        data={
          role: roletype,
          displayName: firstName+ ' '+ lastName,
          status: 'Active',
          moderates: '',
          email: eMail,
          password: passWord,
        };
      }else if(mod === true){
          roletype = 'moderator'
          data={
            role: roletype,
            displayName: firstName+ ' '+ lastName,
            status: 'Active',
            moderates: modsWho,
            moderatesFor: [modsWho],
            email: eMail,
            password: passWord,
          };
        } 
        console.log('DATA?', data);
       addUser(data).then((result) =>{
         console.log('res', result);
          if(result.data.success === true){
            dispatch({type: 'NEW_USER_ADMIN_SUCCESS'});
            } else if (result.data.success === false){
              dispatch({type: 'NEW_USER_ADMIN_ERROR', result });
            }
          }).catch((error) => {
            console.log('ERR', error);
            dispatch({type: 'NEW_USER_ADMIN_ERROR', error });
          });
          //console.log("THE DATA", data);
        //dispatch({type: 'NEW_USER_ADMIN_SUCCESS' });
    }
  };

  export const checkEmailAddress = (email) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      dispatch({ type: 'LOADING' });
      firestore
      .collection('players')
      .where('email', '==', email)
      .get()
      .then((snapshot) => {  
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            const data = { ...doc.data() };
            if ( data.role === 'moderator' ){
              dispatch({type: 'NEW_USER_EMAIL_ALREADY_EXISTS_AS_MOD' });
            } else {
              dispatch({type: 'NEW_USER_EMAIL_ALREADY_EXISTS_AS_HOST' });
            } 
          });
          
        } else {
          dispatch({type: 'NEW_USER_EMAIL_DOES_NOT_EXIST' });
        }
      });         
    };
  }

  export const addExistingModToMyAccount = (email, hostID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
      const firestore = getFirestore();
      dispatch({ type: 'LOADING' });
      firestore
      .collection('players')
      .where('email', '==', email)
      .get()
      .then((snapshot) => {  
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            const data = { ...doc.data() };
            if ( data.role === 'moderator') {
              doc.ref.
              update({
                moderatesFor: firestore.FieldValue.arrayUnion(hostID)
              })
            }
                   
          });
          dispatch({ type: 'END_LOADING' });    
        } else {
          dispatch({type: '' });
        }
      });
    }
  }

  export const resetAdminMessage = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
      dispatch({type: 'RESET_ADMIN_MESSAGE'})
    }
  }

  export const updateAvailableActivities = (hostID, data) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firebase = getFirebase();
      const firestore = getFirestore();
      dispatch({ type: 'LOADING' });
      firestore
        .collection('players')
        .doc(hostID)
        .update({
          activitiesAvailable: data,
        })
        .then(() => {          
          dispatch({ type: 'PROFILE_UPDATED', ret: { authMessage: 'PROFILE_UPDATED'} });
        })
        .catch((err) => {
          dispatch({ type: 'PROFILE_UPDATE_ERROR', err });
        });
    }     
  };

  export const renameSession = (hostId,docId,name) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firebase = getFirebase();
      const firestore = getFirestore();

      firestore
      .collection('past_sessions')
      .doc(hostId)
      .collection('sessions')
      .doc(docId)
      .update({
          sessionName: name
        })
      .then(() => {  
        dispatch({ type: 'SESSION_RENAME_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'SESSION_RENAME_ERROR', err });
      });
    };
  };


  export const saveSpecificActivityOptionPath = (doc, fieldName, val, setOrUpdate) => {
    return (dispatch, getState, { getFirestore, getFirebase }) => {
      const firestore = getFirestore();
      const firebase = getFirebase();
  
      dispatch({ type: 'OPTIONS_SAVE_LOADING', s : getState().game_state } );
  

      if ( setOrUpdate === 'set' ){
        firestore
        .collection('theme')
        .doc(doc)
        .set({
          [`${fieldName}`] : val
        })
        .then(() => {
            dispatch({ type: 'OPTIONS_SAVE_DONE', s : getState().game_state, newImage: false });        
        })
        .catch((err) => {
          dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
        }); 
      } else {
        firestore
        .collection('theme')
        .doc(doc)
        .update({
          [`${fieldName}`] : val
        })
        .then(() => {
            dispatch({ type: 'OPTIONS_SAVE_DONE', s : getState().game_state, newImage: false });        
        })
        .catch((err) => {
          dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
        }); 
      }

      
    }
  }
  export const saveActivityOptionSetFile = (doc, fieldName, file, setOrUpdate) => {
    return (dispatch, getState, { getFirestore, getFirebase }) => {
      const firestore = getFirestore();
      const firebase = getFirebase();
  
      let path = `${fieldName}`;
      let newImg = "";

      console.log('IN SAVE: ', path, doc, fieldName, file)
  
      dispatch({ type: 'OPTIONS_SAVE_LOADING', s : getState().game_state } );
  
      firebase
      .uploadFiles(`theme/${doc}/`, file)
      .then((snapshot) => {
        newImg = snapshot[0].uploadTaskSnapshot.metadata.fullPath;

        if ( setOrUpdate === 'set' ){
          firestore
            .collection('theme')
            .doc(doc)
            .set({
              [path] : snapshot[0].uploadTaskSnapshot.metadata.fullPath
            })
            .then(() => {
                console.log("here");
                dispatch({ type: 'OPTIONS_SAVE_DONE', s : getState().game_state, newImage: newImg });
              
            })
            .catch((err) => {
              console.log("err", err);
              dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
            });
            
          
        } else {
          firestore
            .collection('theme')
            .doc(doc)
            .update({
              [path] : snapshot[0].uploadTaskSnapshot.metadata.fullPath
            })
            .then(() => {
                console.log("here");
                dispatch({ type: 'OPTIONS_SAVE_DONE', s : getState().game_state, newImage: newImg });
              
            })
            .catch((err) => {
              console.log("err", err);
              dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
            });
        }

      }).catch((err) => {
        console.log("err2", err);
        dispatch({ type: 'OPTIONS_SAVE_ERROR', err, s : getState().game_state });
      });
    }
        
    
  }

  export const renamePlannedSessionGroup = (groupKey, groupName, sessionDoc) => {
    return (dispatch, getState, { getFirestore, getFirebase }) => {
      const firestore = getFirestore();
      let st = `groups.${groupKey}.title`;
  
      firestore
        .collection('planned_sessions')
        .doc(sessionDoc)
        .update({
          [st] : groupName,
        })
        .then(() => {
          dispatch({ type: 'GROUP_NAME_UPDATED' });
        })
        .catch((err) => {
          dispatch({ type: 'GROUP_NAME_UPDATE_ERROR', err });
        });
       
    };
  };

  export const addPlannedSession = (sessionTitle, sessionDate, players, hostID) => {
      return (dispatch, getState, { getFirebase, getFirestore}) => {
          const firebase = getFirebase();
          const firestore = getFirestore();

          dispatch({ type: "LOADING" });

          console.log('SESSION DATE', sessionDate);
          const d = moment(sessionDate).unix();
          console.log("WHAT?", d);
          const url_code = d + Math.floor(Math.random() * 1000 + 1);

          const DEFAULT_GROUPS = {
            'group-0' : {
              'id' : 'group-0',
              'playerIds' : [],
              'title' : 'Users not in Groups'
            },
            'group-1' : {
              'id' : 'group-1',
              'playerIds' : [],
              'title' : 'Group 1'
            },
            'group-2' : {
              'id' : 'group-2',
              'playerIds' : [],
              'title' : 'Group 2'
            },
            'group-3' : {
              'id' : 'group-3',
              'playerIds' : [],
              'title' : 'Group 3'
            },
            'group-4' : {
              'id' : 'group-4',
              'playerIds' : [],
              'title' : 'Group 4'
            }
          };

          let GROUPS = DEFAULT_GROUPS;

          if ( players.length > 0 ){
            if ( players[0].group !== undefined ){
              // we have groups in the player data, let's use those
              let group_keys = [];
              players.forEach((player,ind) => {
                if ( !group_keys.includes(player.group) ){
                  group_keys.push(player.group);
                }
              });
              group_keys.sort();

              let REVISED_GROUPS = {
                'group-0' : {
                  'id' : 'group-0',
                  'playerIds' : [],
                  'title' : 'Users not in Groups'
                }
              };
              group_keys.forEach((group, index) => {
                if (group !== undefined && group.trim() !== ''){
                  REVISED_GROUPS[encodeURI(group)] = {
                    'id' : encodeURI(group),
                    'playerIds' : [],
                    'title' : group.toUpperCase()
                  }
                }
              });
              GROUPS = REVISED_GROUPS;
            }
          }



          firestore
          .collection('planned_sessions')
          .add({
            'title' : sessionTitle,
            'date' : sessionDate,
            'host' : hostID, 
            'number_of_players' : players.length,
            'url_code' : `${url_code}`,
            'groups' : {
               ...GROUPS
            }
          })
          .then((docRef) => {
            console.log('in the next part');
              if ( players.length > 0 ){
                console.log('have players');
                let batch  = firestore.batch();
                let playerRef = firestore.collection("planned_session_players").doc(docRef.id).collection("players");
                let codeArray = [];
                let group_codes = {};
                players.forEach((player, ind) => {
                  let randCode = Math.floor(Math.random()*90000) + 10000;
                  
                  // ensure the code is unique
                  if ( codeArray.includes(randCode) ){
                    let t = false;
                    while( t === false ){
                      randCode = Math.floor(Math.random()*90000) + 10000;
                      if ( !codeArray.includes(randCode) ){
                        codeArray.push(randCode);

                        if ( player.group !== undefined ){
                          let the_g = player.group === undefined || player.group.trim() === '' ? 'group-0' : encodeURI(player.group);
                          if ( group_codes[the_g] === undefined ){
                            group_codes[the_g] = [randCode];
                          } else {
                            group_codes[the_g].push(randCode);
                          }                          
                        }

                        t = true;
                      }
                    }
                  } else {
                    codeArray.push(randCode);
                    if ( player.group !== undefined ){
                      let the_g = player.group === undefined || player.group.trim() === '' ? 'group-0' : encodeURI(player.group);
                      if ( group_codes[the_g] === undefined ){
                        group_codes[the_g] = [randCode];
                      } else {
                        group_codes[the_g].push(randCode);
                      }                          
                    }
                  }

                  // add the player
                  let theRef = playerRef.doc(`${randCode}`);
                  batch.set(theRef, { ...player, code : randCode })

                });

                let theSRef = firestore.collection('planned_sessions').doc(docRef.id);
                //firebase.firestore.FieldValue.arrayUnion(...group_codes[groupCode])
                if ( Object.keys(group_codes).length > 0 ){
                  let update_o = {};
                  Object.keys(group_codes).forEach((groupCode, ind) => {
                    update_o[ [`groups.${groupCode}.playerIds`] ] = group_codes[groupCode]
                  });
                  batch.update(theSRef, {
                    ...update_o
                  });
                } else {
                  batch.update(theSRef, {
                    [`groups.group-0.playerIds`] : codeArray
                  });
                }
               

                batch.commit()
                .then(() => {
                  console.log('here?');
                  dispatch({ type: 'PLANNED_SESSION_SAVE_DONE' });
                })
                .catch((err) => {
                  console.log('error....', err);
                  dispatch({ type: 'PLANNED_SESSION_SAVE_ERROR', err });
                })

              } else {
                console.log('no players?');
                dispatch({ type: 'PLANNED_SESSION_SAVE_DONE' });
              }              
          })
          .catch((err) => {
            console.log('what the...', err);
            dispatch({ type: 'PLANNED_SESSION_SAVE_ERROR', err });
          })

      };
  }

  export const sendEmailToPlannedSesssionPlayer = (session, player) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {

      const firestore = getFirestore();
      dispatch({ type: "LOADING" });

      firestore.collection('players').doc(session.host).get().then((hostDoc) => {
        let hostData = false;
        if ( hostDoc.exists ){
          hostData = hostDoc.data();
        }

        const hostName = !hostData ? '' : hostData.displayName;

        const html = MailHeaderHTML + `<font size="+2">Hello ${player.name},</font> <br/><br/>Your host, <strong>${hostName}</strong>, has scheduled a session for <strong>${moment(session.date).local().format("LLLL")}</strong>.
        <br/><br/>
        At that time, you may use the following link to join the session.
        <br/><br/>
        <a href="https://virtual.glassoflearning.com/p/${session.url_code}/${player.code}">https://virtual.glassoflearning.com/p/${session.url_code}/${player.code}</a>
        ` + MailFooterHTML;

        firestore
        .collection('mail')
        .doc()
        .set({
          bcc: 'ryan@metastudios.co',
          to: player.email,
          message: {
            subject: `You have an Upcoming Glass of Learning Virtual Session`,
            text: `Your host, ${hostName}, has scheduled a session for ${moment(session.date).local().format("LLLL")}. At that time, you may use the following link to join the session: https://virtual.glassoflearning.com/p/${session.url}/${player.code}`,
            html: html,
          },
        })
        .then(() => {
          dispatch({ type: "PLAYER_EMAIL_SENT" });
        })
        .catch((err) => {
          dispatch({ type: "PLAYER_EMAIL_SENDING_ERROR", err });
        })
      })
      .catch((err) => {
        dispatch({ type: "PLAYER_EMAIL_SENDING_ERROR", err });
      });
      
    }
  }

  export const updatePlannedSession = (sessionDoc, sessionName, sessionDate, hostID) => {
    return (dispatch, getState, { getFirebase, getFirestore}) => {
      const firestore = getFirestore();
      dispatch({ type: "LOADING" });
      firestore
      .collection('planned_sessions')
      .doc(sessionDoc)
      .update({
        title: sessionName,
        date : sessionDate,
      })
      .then(() => {
        dispatch({ type: 'PLANNED_SESSION_SAVE_DONE' });
      })
      .catch((err) => {
        dispatch({ type: 'PLANNED_SESSION_SAVE_ERROR', err });
      })
    }
  }

  export const removePlannedSession = (sessionDoc) => {
    return (dispatch, getState, { getFirebase, getFirestore}) => {
      const firestore = getFirestore();
      dispatch({ type: "LOADING" });
      firestore
      .collection('planned_sessions')
      .doc(sessionDoc)
      .delete()
      .then(() => {
        dispatch({ type: 'PLANNED_SESSION_REMOVE_DONE' });
      })
      .catch((err) => {
        dispatch({ type: 'PLANNED_SESSION_REMOVE_ERROR', err });
      })
    }
  }

  export const addPlannedSessionPlayers = (sessionDoc, players, sessionGroups) => {
    return (dispatch, getState, { getFirebase, getFirestore}) => {
      const firestore = getFirestore();
      console.log("PLAYERS", players);
      dispatch({ type: "LOADING" });
      let batch  = firestore.batch();
      let playerRef = firestore.collection("planned_session_players").doc(sessionDoc).collection("players");
      let codeArray = [];      
      let group_codes = {};
      let group_keys = [];
      players.forEach((player, ind) => {
        let randCode = Math.floor(Math.random()*90000) + 10000;
        if ( !group_keys.includes(player.group) ){
          group_keys.push(player.group);
        }
        
        // ensure the code is unique
        if ( codeArray.includes(randCode) ){
          let t = false;
          while( t === false ){
            randCode = Math.floor(Math.random()*90000) + 10000;
            if ( !codeArray.includes(randCode) ){
              codeArray.push(randCode);
              if ( player.group !== undefined ){
                let the_g = player.group === undefined || player.group.trim() === '' ? 'group-0' : encodeURI(player.group);
                if ( group_codes[the_g] === undefined ){
                  group_codes[the_g] = [randCode];
                } else {
                  group_codes[the_g].push(randCode);
                }                          
              }
              t = true;
            }
          }
        } else {
          codeArray.push(randCode);
          if ( player.group !== undefined ){
            let the_g = player.group === undefined || player.group.trim() === '' ? 'group-0' : encodeURI(player.group);
            if ( group_codes[the_g] === undefined ){
              group_codes[the_g] = [randCode];
            } else {
              group_codes[the_g].push(randCode);
            }                          
          }
        }

        // add the player
        let theRef = playerRef.doc(`${randCode}`);
        batch.set(theRef, { ...player, code : randCode })

      });

      console.log('group_codes', group_codes, group_keys);
      let theSRef = firestore.collection('planned_sessions').doc(sessionDoc);
      if ( Object.keys(group_codes).length > 0 && (group_keys.length > 0 && group.keys[0] !== undefined )){
        group_keys.sort();
        let update_o = {};
        group_keys.forEach((group, index) => {
          /*REVISED_GROUPS[encodeURI(group)] = {
            'id' : encodeURI(group),
            'playerIds' : [],
            'title' : group.toUpperCase()
          }*/
          console.log('THE GROUP: ', group);
          let enc_group = encodeURI(group);
          if ( sessionGroups[enc_group] !== undefined ){
            update_o[`groups.${enc_group}.playerIds`] = firestore.FieldValue.arrayUnion(...group_codes[enc_group])
          } else {
            update_o[`groups.${enc_group}`] = {
              'id' : enc_group,
              'playerIds' : [...group_codes[enc_group]],
              'title' : group.toUpperCase()
            }
          }
        });

        batch.update(theSRef, {
          ...update_o,
          [`number_of_players`] : firestore.FieldValue.increment(codeArray.length)
        });
      } else {
        batch.update(theSRef, {
          [`groups.group-0.playerIds`] : firestore.FieldValue.arrayUnion(...codeArray),
          [`number_of_players`] : firestore.FieldValue.increment(codeArray.length)
        });
      }
      

      
      

      batch.commit()
      .then(() => {
        console.log('here?');
        dispatch({ type: 'PLANNED_SESSION_SAVE_DONE' });
      })
      .catch((err) => {
        console.log('error....', err);
        dispatch({ type: 'PLANNED_SESSION_SAVE_ERROR', err });
      })
    }
  }

  export const updatePlannedSessionPlayer = (sessionDoc, playerDoc, playerObj) => {
    return (dispatch, getState, { getFirebase, getFirestore}) => {
      const firestore = getFirestore();
      dispatch({ type: "LOADING" });
      firestore
      .collection('planned_session_players')
      .doc(sessionDoc)
      .collection('players')
      .doc(playerDoc)
      .update({
        ...playerObj,
        code: playerDoc
      })
      .then(() => {
        dispatch({ type: 'PLANNED_SESSION_PLAYER_SAVE_DONE' });
      })
      .catch((err) => {
        dispatch({ type: 'PLANNED_SESSION_PLAYER_SAVE_ERROR', err });
      })
    }
  }

  export const removePlannedSessionPlayer = (sessionDoc, playerDoc) => {
    return (dispatch, getState, { getFirebase, getFirestore}) => {
      const firestore = getFirestore();
      dispatch({ type: "LOADING" });
      firestore
      .collection('planned_session_players')
      .doc(sessionDoc)
      .collection('players')
      .doc(playerDoc)
      .delete()
      .then(() => {
        firestore.collection('planned_sessions').doc(sessionDoc)
        .update({
          'number_of_players' : firestore.FieldValue.increment(-1)
        }).then(() => {
          dispatch({ type: 'PLANNED_SESSION_PLAYER_DELETE_DONE' });
        }).catch((err) => {
          dispatch({ type: 'PLANNED_SESSION_PLAYER_DELETE_ERROR', err });
        });        
      })
      .catch((err) => {
        dispatch({ type: 'PLANNED_SESSION_PLAYER_DELETE_ERROR', err });
      })
    }
  }

  export const saveSessionGroups = (groups, sessionDoc) => {
    return (dispatch, getState, { getFirestore, getFirebase }) => {
      const firebase = getFirebase();
      const firestore = getFirestore();

  
      firestore
        .collection('planned_sessions')
        .doc(sessionDoc)
        .update({
          'groups': {...groups},
        })
        .then(() => {
          dispatch({ type: 'GAME_GROUPS_UPDATED' });
        })
        .catch((err) => {
          dispatch({ type: 'GAME_GROUPS_UPDATE_ERROR', err });
        });
           
    };
  };


  export const removeSession = (docID, hostID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firebase = getFirebase();
      const firestore = getFirestore();

      firestore
      .collection('past_sessions')
      .doc(hostID)
      .collection('sessions')
      .doc(docID)
      .update({
        'removed': true
      })
      .then(() => {  
        dispatch({ type: 'SESSION_REMOVE_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'SESSION_REMOVE_ERROR', err });
      });
    };
  }

  export const removeRecord = (docID, hostID, record) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firebase = getFirebase();
      const firestore = getFirestore();

      firestore
      .collection('past_sessions')
      .doc(hostID)
      .collection('sessions')
      .doc(docID)
      .update({
        'removedActivities' : firestore.FieldValue.arrayUnion(record)
      })
      .then(() => {  
        dispatch({ type: 'SESSION_RECORD_REMOVE_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'SESSION_RECORD_REMOVE_ERROR', err });
      });
    };
  }

  export const removeActiveRecord = (docID, hostID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firebase = getFirebase();
      const firestore = getFirestore();

      firestore
      .collection('past_sessions')
      .doc(hostID)
      .collection('sessions')
      .doc(docID)
      .update({
        'active_game.removed' : true
      })
      .then(() => {  
        dispatch({ type: 'SESSION_ACTIVE_RECORD_REMOVE_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'SESSION_ACTIVE_RECORD_REMOVE_ERROR', err });
      });
    };
  }

  export const sendContactEmail = (name, company, email, phone, message) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firebase = getFirebase();
      const firestore = getFirestore();

      firestore
      .collection('mail')
      .doc()
      .set({
        bcc: 'ryan@metastudios.co',
        to: 'swartz.ryan@gmail.com',
        message: {
          subject: `Virtual Glass Admin - Contact Us Request`,
          text: `A contact us request was submit through the glass of learning admin portal. Name: ${name}. Email: ${email}. Phone: ${phone}. Company: ${company}. Message: ${message}.`,
          html: `A contact us request was submit through the glass of learning admin portal. <br/><br/>Name: ${name}. <br/>Email: ${email}. <br/>Phone: ${phone}. <br/>Company: ${company}. <br/><br/>Message: ${message}.`,
        },
      }).then(() => {  
        dispatch({ type: 'SEND_CONTACT_EMAIL' });
      })
      .catch((err) => {
        dispatch({ type: 'SEND_CONTACT_EMAIL_ERROR', err });
      });
    };
  }



  
 
 