import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { EXPECTATIONS, ACCOUNTABILITIES } from './activity';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { deepDiffMapper } from '../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import CircleOfInfluenceDefbrief from './images/CircleOfInfluenceDebrief.png';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import expectationIcon from './images/set_expectations.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
import { PDFHeader } from './../../../PastData/PDFHeader';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    returnToResults = () => {
        this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { phase : ACCOUNTABILITIES } );
    }
    returnToResultsE = () => {
        this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { phase : EXPECTATIONS } );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.results.resultSnapshot === undefined ? {} : this.props.results.resultSnapshot, this.props.results.results)
        })
    }


    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
    const {
      results,
      classes,
      profiles,
      isPDFView
    } = this.props;
    const { diff } = this.state;

    if( this.props.isPDFView === true ){

        return (
            <Document>
                 <Page wrap style={{padding: "30 0"}}>
                    <PDFHeader/>
                    <View>
                        <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={expectationIcon}/>
                    {Object.keys(results.results)
                    .sort()
                    .map((groupKey, ind) => {
                            return (
                           <React.Fragment key={ind}>
                                <View style={{marginHorizontal: "50", marginBottom: "40"}} wrap={false}>
                                    <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>                                    
                                    <View style={{marginVertical: "5", flexDirection: 'column', borderRadius: "15", backgroundColor: "rgb(209, 196, 233)"}}>
                                    {/* purple box */}
                                        {/* Expectations: */}
                                        <View style={{ width: "100%"}}>
                                            <Text style={{ fontWeight: 700, fontSize: "10pt", marginBottom: "15", marginLeft: "15", marginTop: "20"}}>Expectations:</Text>
                                            <View style={{marginVertical: "10", marginHorizontal: "7", flexDirection: "column", borderRadius: "15", border: "1pt solid #FFF", backgroundColor: "rgb(209, 196, 233)"}}>
                                                {/* map Expectations */}
                                                 { 
                                                    results.results[groupKey] !== undefined ?
                                                        results.results[groupKey][EXPECTATIONS] !== undefined ?
                                                    
                                                            results.results[groupKey][EXPECTATIONS].map((item, i) =>{
                                                                return(
                                                                    <Text key={i} style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}>{item}</Text>
                                                                )
                                                            })
                                                   
                                                        : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                                    : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                               } 
                                            </View>
                                        </View>
                                        {/* Accountabilities: */}
                                        <View style={{ width: "100%"}}>
                                            <Text style={{ fontWeight: 'bold', fontSize: "10pt", marginBottom: "15", marginLeft: "15", marginTop: "20"}}>Accountabilities:</Text>
                                            <View style={{marginVertical: "10", marginHorizontal: "7", flexDirection: "column", borderRadius: "15", border: "1pt solid #FFF", backgroundColor: "rgb(209, 196, 233)"}}>
                                                {/* map Accountabilities: */}
                                                 { 
                                                    results.results[groupKey] !== undefined ?
                                                        results.results[groupKey][ACCOUNTABILITIES] !== undefined ?
                                                    
                                                            results.results[groupKey][ACCOUNTABILITIES].map((item, i) =>{
                                                                return(
                                                                    <Text key={i} style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}>{item}</Text>
                                                                )
                                                            })
                                                   
                                                        : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                                    : <Text style={{display: "block" ,fontSize: "12pt", marginLeft: "20",marginBottom: "10", marginTop: "6" }}> </Text>
                                               } 
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </React.Fragment>       
                            );
                    })}
                    </View>
                </Page>
            </Document> 
        ) 
    }


    if ( diff === undefined ){
        return null;
    } else {
        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                        {results.name} - Debrief
                        </Typography>                    
                    </Toolbar>
                    </AppBar>
                    <Grid container spacing={2} className={classes.dialogueGrid}>
                        <Grid item xs={12} sm={6} className={classes.doubleSpacingTop}>
                            <img src={CircleOfInfluenceDefbrief} className={classes.imageSize} alt="Circle of Influence"/>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.doubleSpacingTop}>
                            <Typography variant="body1" className={`${classes.spaceBottom} ${classes.medBodyText} ${classes.spacingTop}`}>When we focus on what we <span className={classes.boldedText}>can’t influence</span>, our <span className={classes.boldedText}>circle of influence shrinks.</span></Typography>
                            <Typography variant="body1" className={classes.medBodyText}>When we focus on what we <span className={classes.boldedText}>can influence</span>, our <span className={classes.boldedText}>circle of concern shrinks</span> – limit what you can’t influence; focus on what you can influence.</Typography>
                            <Typography variant="body1" className={classes.medBodyText}>Limit what you can't influence; <span className={classes.boldedText}>focus on what you can influence.</span></Typography>
                        </Grid>
                    </Grid>                    
                </Dialog>
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(results.results)
                .sort()
                .map((groupKey, ind) => {
                    let hasAcc = results.results.hasOwnProperty(groupKey) ? results.results[groupKey].hasOwnProperty(ACCOUNTABILITIES) ? true : false : false;

                    return (
                    <div key={ind}>
                        <Typography variant="h4">{results.groups[groupKey].title}</Typography>
                        <Grid container className={classes.paperExtra}>
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                            <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Expectations:</Typography>
                                {/* Results Here */}
                                <List className={classes.listClassExtra}>
                                {diff.hasOwnProperty(groupKey)
                                    ? diff[groupKey].hasOwnProperty(EXPECTATIONS)
                                    ? Object.keys(diff[groupKey][EXPECTATIONS]).map((item, index) => {
                                        return (
                                            <ListItem key={index}>
                                            <ListItemText primary={diff[groupKey][EXPECTATIONS][item].data} className={classes[diff[groupKey][EXPECTATIONS][item].type]} />
                                            </ListItem>
                                        );
                                        })
                                    : 
                                    diff[groupKey].data.hasOwnProperty(EXPECTATIONS) ?
                                    Object.keys(diff[groupKey].data[EXPECTATIONS]).map((item, index) => {
                                        return (
                                            <ListItem key={index}>
                                            <ListItemText primary={diff[groupKey].data[EXPECTATIONS][item]}/>
                                            </ListItem>
                                            );
                                        })
                                        : null
                                    : null}
                                </List>
                            </Grid>
                            { hasAcc === true ?
                            <Grid item xs={12} sm={12}>
                            <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Accountabilities:</Typography>
                                {/* Results Here */}  
                                <List className={classes.listClassExtra}>
                                {results.results.hasOwnProperty(groupKey)
                                    ? results.results[groupKey].hasOwnProperty(ACCOUNTABILITIES)                                   
                                        ?
                                        results.results[groupKey][ACCOUNTABILITIES].map((item, index) => {
                                            return (
                                                <ListItem key={index}>
                                                <ListItemText primary={item}/>
                                                </ListItem>
                                                );
                                            })
                                    : null                                                
                                : null }
                                </List>
                            </Grid>
                            : null }
                            </Grid>
                        </Grid>
                        </Grid>
                    </div>
                    );
                })}
            </Grid>
            
            </Grid>
        </div>
        );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
