import React, { useState, CSSProperties } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
    TableBody, TableCell, Collapse, TableHead, TableRow, Box, } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import CSVReader from './file_reader';

export default function CSVParser (props) {

    const [csvMessage, setCsvMessage] = useState(false);
    const [csvData, setCsvData] = useState([]);

    const saveParser = () => {
        props.saveParser(csvData);
        
    }
    const closeParser = () => {
        props.closeParser();
    }
    const getUserData = (data) => {
        if ( data.data.length > 1 && data.errors.length <= 0 ){
            //console.log('got data', data.data);

            // let's parse what we want out of the data
            const playerData = data.data;
            const headers = playerData[0];
            let playerArray = [];

            if ( headers !== undefined ){

                let emailColumn = false;
                let nameColumn = false;
                let teamColumn = false;
                let nameColumns = {};

                const NAME_COL = 'name';
                const EMAIL_COL = 'email';
                const TEAM_COL = ['team', 'table', 'group'];
                


                headers.forEach((columnHeader, index) => {
                    if ( columnHeader.toLowerCase() === 'email' ){
                        emailColumn = index;
                    }
                    if ( columnHeader.toLowerCase() === 'name' ){
                        nameColumn = index;
                    }

                    if ( (columnHeader.toLowerCase() === TEAM_COL[0] || columnHeader.toLowerCase() === TEAM_COL[1] || columnHeader.toLowerCase() === TEAM_COL[2]) && teamColumn === false ){
                        teamColumn = index;
                    } 
                });

                // if there is no name column, we want to check and see if first name and last name are used
                if ( nameColumn === false ){
                    headers.forEach((columnHeader, index) => {
                        if ( columnHeader.toLowerCase() === 'first name' ){
                            nameColumn = null;
                            nameColumns['first'] = index;
                        }
                        if ( columnHeader.toLowerCase() === 'last name' ){
                            nameColumn = null;
                            nameColumns['last'] = index;
                        }
                    })
                }

             
                if ( nameColumn !== false && emailColumn !== false ){
                    
                    // parse the rest of the data
                    playerData.forEach((player, index) => {
                        // we don't want the headers
                        
                        if ( index > 0 ){
                            if ( nameColumn === null && nameColumns['first'] !== undefined ){
                                let pName = player[nameColumns['first']];
                                if ( nameColumns['last'] !== undefined ){
                                    pName = pName + " " + player[nameColumns['last']];
                                }
                                if ( player[emailColumn] !== undefined && pName !== '' ){
                                    let p = {
                                        'email' : player[emailColumn],
                                        'name' : pName
                                    };
                                    if ( teamColumn !== false ){
                                        p['group'] = player[teamColumn];
                                    }
                                    if ( p.email !== undefined && p.email !== '' && p.name !== undefined && p.name !== '' ){
                                        playerArray.push(p);
                                    }
                                }
                            } else {
                                if ( player[nameColumn] !== undefined && player[emailColumn] !== undefined ){
                                    let p = {
                                        'email' : player[emailColumn],
                                        'name' : player[nameColumn]
                                    };
                                    if ( teamColumn !== false ){
                                        p['group'] = player[teamColumn];
                                    }

                                    if ( p.email !== undefined && p.email !== '' && p.name !== undefined && p.name !== '' ){
                                        playerArray.push(p);
                                    }
                                }
                            }
                        }
                    });

                    //console.log("We've got data", playerArray);
                    setCsvData(playerArray);
                    setCsvMessage(false);                   
                }




            }

        } else {
            //console.log('got error from data or no data at all');
  
            setCsvMessage('Warning: either the selected CSV has no data, or an error has occured. Please try another file');
          
            /*setTimeout(() => {
                setCsvMessage(false);
            }, 4000);  */
        }
    }

    return (
        <Dialog aria-labelledby='add-planned-session' aria-describedby='Add Planned Session'  maxWidth='md'  open={props.showParser} fullWidth>
                        
                <AppBar position="static" className={props.classes.LNNoShadow}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={()=>props.closeParser()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                            <Typography variant="h6">
                                Upload CSV     
                            </Typography>
                    </Toolbar>
                </AppBar>
            <DialogContent>

                
            <form className={props.classes.container} style={{display: 'flex', flexWrap: 'wrap', paddingTop: 30}} noValidate>                   
                <div style={{width: '100%', marginTop: "20px", marginBottom: "60px"}}>
                    <Typography variant="body1">Upload Users</Typography>
                    <CSVReader getUserData={getUserData}/>
                </div>
                <div style={{width: '100%', marginTop: "20px", marginBottom: "20px"}}>
                    <Typography variant="body1" style={{color: "red", fontWeight: "bold"}}>{csvMessage}</Typography>                        
                </div>
            </form>


                
            </DialogContent> 
            <DialogActions>
                <Button size='large' onClick={()=>props.closeParser()}>
                    Cancel
                </Button>
                <Button size='large' disabled={csvData===false} onClick={()=>saveParser()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
    
}