import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import TurnsManager from './../specific_components/turns.js';
import { ComparisonTable, PointCalculation  } from './../specific_components/comparisonTable';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GamePiece from '../specific_components/gamePiece';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#333",
      color: theme.palette.common.white,
      borderRadius: 0,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        // session.active_game.results
        this.state = {
          ...INITIAL_STATE,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }   


    render() {
    const {
      results,
      classes,
      auth,
      profile,
      game_options,
      isPDFView
    } = this.props;

    
        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{results.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(results.results).sort().map((groupKey, ind) => {

                  if ( groupKey === 'group-0' ){
                    return null;
                  }
                  //console.log('huh',groupKey);
                 
                  const split = groupKey.split(":");
                  let round = 0;
                  ////console.log('whoha', props.session.active_game.resultSnapshot);
                  let score = results.results[groupKey] === undefined ? { [split[0]] : 0, [split[1]]: 0} : results.results[groupKey].grid === undefined ? { [split[0]] : 0, [split[1]]: 0} : PointCalculation(results.results[groupKey].grid, groupKey );
                  
                  console.log('split',split);
                  if ( split.length < 2 ){
                    return null;
                  }
                    return (
                    <div key={ind}>
                        <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{results.groups[split[0]].title} & {results.groups[split[1]].title}</Typography>
                       
                        <TableContainer component={Paper} style={{maxWidth: "400px"}}>
                          <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Round</StyledTableCell>
                              <StyledTableCell align="right">{results.groups[split[0]].title} <GamePiece type={'team1'} size="icon"/></StyledTableCell>
                              <StyledTableCell align="right">{results.groups[split[1]].title} <GamePiece type={'team2'} size="icon"/></StyledTableCell>
                              <StyledTableCell align="right">Total</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {
                              results.results !== undefined ? 
                                Object.keys(results.results).length > 0 ?            
                                  Object.keys(results.results).sort().map((roundKey, ind) => {
                                    round += 1;
                                    ////console.log("PAST ROUND: ", roundKey)
                                    let scoreB = results.results[roundKey][groupKey] === undefined ? { [split[0]] : 0, [split[1]]: 0} : results.results[roundKey][groupKey].grid === undefined ? { [split[0]] : 0, [split[1]]: 0} : PointCalculation(results.resultst[roundKey][groupKey].grid, groupKey );
                                    //let scoreB = PointCalculation(props.session.active_game.resultSnapshot[roundKey][props.vsKey].grid, props.vsKey );
                                    return (
                                    <StyledTableRow key={ind}>
                                      <StyledTableCell component="th" scope="row">
                                        {round}
                                      </StyledTableCell>
                                      <StyledTableCell align="right">{scoreB[split[0]]}</StyledTableCell>
                                      <StyledTableCell align="right">{scoreB[split[1]]}</StyledTableCell>
                                      <StyledTableCell align="right">{scoreB[split[0]] + scoreB[split[1]]}</StyledTableCell>
                                    </StyledTableRow>
                                    )
                                  })
                                  : null
                              : null
                            }
                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {round+1}
                              </StyledTableCell>
                              <StyledTableCell align="right">{score[split[0]]}</StyledTableCell>
                              <StyledTableCell align="right">{score[split[1]]}</StyledTableCell>
                              <StyledTableCell align="right">{score[split[0]] + score[split[1]]}</StyledTableCell>
                            </StyledTableRow>

                            </TableBody>
                          </Table>
                        </TableContainer>
            
                    </div>
                    );
                })}
            </Grid>
            </Grid>
        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);