import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import styles from '../../../../Styles';
import DiSC_Icon from '../images/DiSC_Icon.png'
import TrustCard from './card.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {CardList} from './../cards/cardList.js';

class SpectrumCategory extends Component {
    

    render() {
        const { classes, cards, hasControl, session, profile, auth } = this.props;
        let cardListing = (cards === undefined) ? [] : cards;

        return (
            <div className={classes.cardListVertical}>
                <List className={`${classes.relativeList} ${classes.doNotPadTop}`} style={{width: "100%"}}>
                    <ListItem className={classes.doNotPad}>
                        <List className={`${classes.horizontalList}`} style={{width: "100%"}}>
                {
                    cardListing.map((card, ind) => {
                            let the_card = CardList.filter(v => v.id === card )[0];
                            //console.log('the card', the_card, card);
                            return (<ListItem key={ind} className={classes.stretchToFit}>
                                <TrustCard column={this.props.column} clearSelected={this.props.clearSelected} hasControl={hasControl} type={`minimal`} session={session} card={the_card} profile={profile} auth={auth} card_id={the_card.id} card_words={the_card.card_words} card_category={the_card.card_category} card_subwords={the_card.card_subwords} card_fun={the_card.card_fun}/>
                            </ListItem>)
                        
                    })
                }
                        </List>
                    </ListItem>
                </List>
            </div>
        );
    }
}

SpectrumCategory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SpectrumCategory);



