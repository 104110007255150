import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import AdminPage from '../AdminPage';
import PastData from '../PastData';
import LoadingBlock from '../Loading';
import ErrorBoundary from './errorBoundary';
import Dashboard from '../Dashboard';
import Footer from '../Footer';
import LogoHeader from '../Header';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { signOut } from '../../store/actions/authActions';

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#9b3e96',
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
};

const INITIAL_STATE = {
  loading: true,
};

class HomePageBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });

    if (this.props.auth.uid && !this.props.auth.isAnonymous && this.props.user.isLoaded) {

    }
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {

    const {
      auth,
    } = this.props;

    
    if (!auth.uid) return <Redirect to={ROUTES.LOGIN} />;
    console.log('here', this.props.user.role);
    if (auth.uid && this.props.user.isLoaded) {
      if (this.props.user.isLoaded && this.props.user.role === 'player') {
        return (
          <div className='meta-react'>
                
                <LogoHeader  profile={this.props.user} auth={this.props.auth} />
                <div className='outer-container'>
                    <div className='container'>
                        <Typography variant="h2">You are currently signed in as a player</Typography>
                        <Button variant="contained" color="primary" onClick={() => this.props.signOut()}>Sign Out</Button>
                    </div>
                </div>
                <Footer profile={this.props.user} auth={this.props.auth} />
            </div>
        );
      }
      if ( this.props.user.role === 'moderator' ){
        return <ErrorBoundary><PastData auth={this.props.auth} profile={this.props.user} /></ErrorBoundary>;
      }
      //return <ErrorBoundary><AdminPage auth={this.props.auth} profile={this.props.user} /></ErrorBoundary>;
      return <ErrorBoundary><Dashboard auth={this.props.auth} profile={this.props.user} /></ErrorBoundary>;
    } else {
      return <LoadingBlock />;
    }
  }
}

HomePageBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    user: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(HomePageBase);