import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import ActivityListPlus from '../specific_components/activityListPlus';
import ActivityListDelta from '../specific_components/activityListDelta';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
  import { PDFHeader } from './../../../../PastData/PDFHeader';
  import feedback from '../images/feedback.png';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

   

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            
        })
    }


    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
    const {
      results,
      classes,
      profiles,
    } = this.props;

        const PLUS = "plus";
        const DELTA = "delta";
        const regex = /<br[^>]*>/gi;
        if ( this.props.isPDFView === true ){

            return (
                <Document>
                    <Page wrap style={{margin: "30 0"}}>
                        <PDFHeader/>
                        <View>
                            <Image style={{marginHorizontal: "150", marginBottom: "30"}} src={feedback}/>
                        {Object.keys(results.results)
                        .sort()
                        .map((groupKey, ind) => {
                            
                                return (
                            <React.Fragment key={ind}>
                                    <View style={{marginHorizontal: "50", marginBottom: "50"}}>
                                        <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>                                    
                        
                                    
                                            <View  style={{marginVertical: "5", flexDirection: "row"}}>                                                
                                            <View style={{ flex: 1, fontSize: "12pt", marginRight: "10", border: "1pt solid #000"}}>
                                                <View style={{backgroundColor: "#673ab7", padding: "10", textAlign: "center"}}><Text style={{color: "#fff"}}>Plus</Text></View>
                                                <View style={{padding: "10" }}>
                                                    {
                                                    results.results[groupKey].hasOwnProperty(PLUS)
                                                        ? results.results[groupKey][PLUS].map((item, index) => {
                                                            return (
                                                                <Text key={index} style={{padding: "6 0"}}>                 
                                                                - {item.replace(regex, "\n")}                                 
                                                                </Text>
                                                            )
                                                        })
                                                        : null
                                                    }    
                                                </View>
                                            </View>
                                            <View style={{ flex: 1, fontSize: "12pt", marginLeft: "10", border: "1pt solid #000"}}>
                                                <View style={{backgroundColor: "#673ab7", padding: "10", textAlign: "center"}}><Text style={{color: "#fff"}}>Delta</Text></View>
                                                <View style={{padding: "10" }}>
                                                    {
                                                    results.results[groupKey].hasOwnProperty(DELTA)
                                                        ? results.results[groupKey][DELTA].map((item, index) => {
                                                            return (
                                                                <Text key={index} style={{padding: "6 0"}}>                 
                                                                - {item.replace(regex, "\n")}                                 
                                                                </Text>
                                                            )
                                                        })
                                                        : null
                                                    }    
                                            </View>
                                            </View>
                                        </View>
                                    
                                        
                                        
                                    
                                    </View>
                                </React.Fragment>       
                                );
                            
                        })}
                        </View>
                    </Page>
                </Document> 
            )
        }

        return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">Feedback</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {Object.keys(results.results)
                    .sort()
                    .map((groupKey, ind) => {
                        return (
                        <div key={ind}>
                            <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{results.groups[groupKey].title}</Typography>
                            <Grid container spacing={2}>        
                                <Grid item xs={12} sm={6}>
                                    <Grid item xs={12}  align="center" className={classes.tableHead}>
                                        Plus <AddIcon/>    
                                    </Grid>
                                    <Grid item xs={12}  align="center" className={classes.tableBody}>  
                                        <ActivityListPlus results={results} group_id={groupKey} debrief={true} hasGameControl={false} showList={true}/> 
                                    </Grid>  
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid item xs={12}  align="center" className={classes.tableHead}>       
                                        Delta <ChangeHistoryIcon/> <sup className={classes.tableHeadSup}>(change)</sup>     
                                    </Grid> 
                                <Grid item xs={12}  align="center" className={classes.tableBody}>
                                    <ActivityListDelta results={results} group_id={groupKey} debrief={true} hasGameControl={false} showList={true} />  
                                </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        );
                    })}
                </Grid>  
            </Grid>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
