import React, { useMemo, useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import Button from '@material-ui/core/Button';

const DialogChoices = ( props) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedChoice, setSelectedChoice] = useState(false);
    const [dialogText, setDialogText] = useState('');
    let p = props.hasControl ? 'pointer' : 'default';

    const selectChoice = (choice) => {
        if ( !props.hasControl || selectedChoice !== false ) {
            return;
        }

        if ( props.choices[choice].choiceSubText !== '' ){
            setDialogText(props.choices[choice].choiceSubText);
            setSelectedChoice(choice);
            props.openDialog(props.choices[choice].choiceSubText);
            setDialogOpen(true);
        } else {
            setSelectedChoice(choice);
            props.chooseChoice(props.choices[choice]);
        }
    }

    React.useEffect(() => {
        if ( props.isDialogOpen === false ){
            setDialogOpen(false);
        } else {
            setDialogText(props.isDialogOpen);
            setDialogOpen(true);
        }
    }, [props.isDialogOpen]);

    React.useEffect(() => {
        if ( props.groupState.effect === 'choiceMade' ){
            setSelectedChoice(props.groupState.val);
        }
    }, [props.groupState])

    const proceedNow = () => {
        setDialogOpen(false);
        props.chooseChoice(props.choices[selectedChoice], false);        
    }

    if ( props.choices.length === 2 ){
        return (
            <div>
                <Dialog open={dialogOpen} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth>
                    <DialogTitle id="customized-dialog-title" style={{background: "#3f51b5", color: "#fff"}} >
                        Result
                    </DialogTitle>
                    <DialogContent style={{paddingTop: "20px", paddingBottom: "20px", fontSize: "1.1rem"}}>
                        {dialogText}
                    </DialogContent>
                    <DialogActions>                    
                        <div>
                        <Button disabled={props.hasControl ? false : true} onClick={proceedNow} variant="contained" color='primary'>
                            Proceed
                        </Button>
                        </div>                   
                    </DialogActions>
                </Dialog>
            
                <div className="DialogChoices">
                    <div style={{textAlign: "center", fontSize: "1.3rem", fontWeight: "bold", padding: "0 0 15px 0"}}>Which choice will you make?</div>
                    <Grid container spacing={3} style={{display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridAutoRows: "1fr", gridColumnGap: "5px", gridRowGap: "5px"}}>
                        <Grid item xs={6} style={{display: "flex", maxWidth: "100%"}}>
                            <div style={{cursor: p}} className={`${props.classes.choiceOption} ${ selectedChoice === 0 ? props.classes.choiceOptionPulse : null }`} onClick={() => selectChoice(0)}>
                            { props.choices[0].text }
                            </div>
                        </Grid>
                        <Grid item xs={6} style={{display: "flex", maxWidth: "100%"}}>
                            <div style={{cursor: p}} className={`${props.classes.choiceOption} ${ selectedChoice === 1 ? props.classes.choiceOptionPulse : null }`} onClick={() => selectChoice(1)}>
                            { props.choices[1].text }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    } else if ( props.choices.length === 1 ) {
        return (
            <div className="DialogChoices">
                <Grid container spacing={3}>
                    <Grid item xs={3}>
    
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{cursor: p}} className={`${props.classes.choiceOption} ${ selectedChoice === 0 ? props.classes.choiceOptionPulse : null }`} onClick={() => selectChoice(0)}>
                        { props.choices[0].text }
                        </div>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                </Grid>
            </div>
        );
    } else {
        return null;
    }

    
};

export default DialogChoices;
