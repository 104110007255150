import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import styles from '../../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DiSC_Icon from '../images/DiSC_Icon.png'
import Button from '@material-ui/core/Button';


const INITIAL_STATE = {
    open: false
}

class TrustCard extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    handleOpen(){
        console.log("opening?");
        this.setState({
            ...this.state,
            open: true
        });
    }

    handleClose(){
        this.setState({
            ...this.state,
            open: false
        });
    }


    render() {
        const { classes, card_words, card_subwords, card, card_fun, card_category, type } = this.props;
        if ( type === 'minimal' ){
            //console.log('card', card);
            return (
                <div>
                    <Dialog onClose={() => this.handleClose()} aria-labelledby="customized-dialog-title" open={this.state.open} >
                        <DialogTitle id="customized-dialog-title" onClose={() => this.handleClose()} style={{backgroundColor: "#2e75b5", color: "#fff"}}>
                        <span className={classes.inlineProfileScenario}>Trust Card #{card.id}</span> 
                        </DialogTitle>
                        <DialogContent dividers className={classes.noPad} style={{borderColor: "#2e75b5", background: 'linear-gradient(180deg, rgba(46,117,181,0.27354691876750703) 0%, rgba(255,255,255,1) 100%)'}}> 
                            <Grid container className={classes.alignVerticalCenter} style={{alignItems: 'stretch'}}>                        
                                <Grid item sm={12} xs={12} className={classes.padMe}>
                                    <Typography variant="body1">
                                        {card.card_words}
                                    </Typography>
                                </Grid>                           
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            { this.props.hasControl === true ? 
                            <Button size="small" color="secondary" onClick={(e) => this.props.clearSelected(e, card.id, this.props.column)}>
                                Clear from Column
                            </Button>
                            : null }
                            <Button size="small" color="primary" onClick={() => this.handleClose()}>
                                Done
                            </Button>                    
                        </DialogActions>
                    </Dialog>                 
                
                
                <Card onClick={() => this.handleOpen()}  variant="outlined" style={{borderColor: "#fff", borderRadius: "5px", background: '#2e75b5'}}>
                    <CardActionArea className={`${classes.alignVerticalCenter}`} style={{padding: "10px 5px"}}>
                        <Grid container className={classes.alignVerticalCenter}>
                            <Grid item xs={12} align="center" style={{color: '#fff'}}>
                                Trust Card #{card.id}                                
                            </Grid>                        
                        </Grid>
                    </CardActionArea>
                </Card>
            </div>
        
            );
        } 
        
        return (
                    <Card className={`${classes.discCard}`} variant="outlined" style={{borderColor: "#2e75b5", background: 'linear-gradient(180deg, rgba(46,117,181,0.27354691876750703) 0%, rgba(255,255,255,1) 100%)'}}>
                        <CardActionArea className={`${classes.alignVerticalCenter}`} style={{padding: "10px 5px"}}>
                            <Grid container className={classes.alignVerticalCenter}>
                                <Grid item xs={12} align="center" style={{color: '#2e75b5'}}>
                                    Trust Card #{card.id}                                
                                </Grid>
                                <Grid item xs={12} className={classes.cardPadding} style={{paddingTop: "20px"}}>
                                    <Typography variant="body2" style={{fontSize: "0.95rem"}}>
                                        {card_words}
                                    </Typography>                                
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
            
        );
    }
}



export default withStyles(styles)(TrustCard);



