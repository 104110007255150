import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { URGENT_AND_NOT_IMPORTANT, URGENT_AND_IMPORTANT, NOT_URGENT_AND_NOT_IMPORTANT, IMPORTANT_AND_NOT_URGENT } from './../data/data';
import manageTimeIcon from '../images/stopDrop.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
  import { PDFHeader } from './../../../../PastData/PDFHeader';


const INITIAL_STATE = {
    open: false
};

const UPPER_LEFT = "ul";
const UPPER_RIGHT = "ur";
const LOWER_LEFT = "ll";
const LOWER_RIGHT = "lr";

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }



    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.results.resultSnapshot === undefined ? {} : this.props.results.resultSnapshot, this.props.results.results)
        })
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    getResults( results, group_id, quadrant ){
        if ( results === undefined ){
            return {};
        }
        if ( results[group_id] === undefined ){
            return {};
        }
        if ( results[group_id][quadrant] === undefined ){
            return {};
        }
        return results[group_id][quadrant];        
    }

    render() {
    const {
      results,
      classes,
      profiles,
      isPDFView,
    } = this.props;


    if(this.props.isPDFView === true){
        return (
            <Document>
                 <Page wrap style={{padding: "30 0"}}>
                    <PDFHeader/>
                    <View>
                        <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={manageTimeIcon}/> 
                        {Object.keys(results.results)
                        .sort()
                        .map((groupKey, ind) => {
                           
                        return (
                        <React.Fragment key={ind}>
                            <View style={{marginHorizontal: "50", marginBottom: "50"}} wrap={false} >
                                <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text>
                                
                                <View style={{flexDirection: 'row'}}>
                                    <View  style={{ width: '49%', textAlign: 'center'}}>
                                        <Text style={{ fontSize: '10pt'}}>Urgent &amp; Not Important</Text>
                                    </View>
                                    <View style={{ width: '49%', textAlign: 'center'}}>
                                        <Text style={{ fontSize: '10pt'}}>Urgent &amp; Important</Text>
                                    </View>
                                </View>
                                
                                <View style={{flexDirection: 'row'}}>
                                    <View style={{ width: '49%', height: '90', textAlign: "left", borderRadius: '2px',border: "1px solid #000", backgroundColor: 'rgba(207,165,97,0.6979166666666667)'}}>
                                        {
                                            Object.keys(this.getResults(results.results, groupKey, UPPER_LEFT)).map((k, ind) => {
                                                
                                                return (
                                                    <React.Fragment key={ind} >
                                                        <Text style={{ marginHorizontal: '10', marginVertical: '10', fontSize: '10pt'}}>{results.results[groupKey][UPPER_LEFT][k]} </Text>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </View>
                                    <View style={{ width: '49%', height: '90', textAlign: "left", borderRadius: '2px',border: "1px solid #000", backgroundColor: 'rgba(80,156,147,0.7035189075630253)'}}>
                                        {
                                            Object.keys(this.getResults(results.results, groupKey, UPPER_RIGHT)).map((k, ind) => {
                                                return (
                                                    <React.Fragment key={ind} >
                                                        <Text style={{ marginHorizontal: '10', marginVertical: '10', fontSize: '10pt'}}>{results.results[groupKey][UPPER_RIGHT][k]} </Text>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </View>
                                </View>
                                <View style={{flexDirection: 'row'}}>
                                    <View style={{ width: '49%', height: '90', textAlign: "left", borderRadius: '2px',border: "1px solid #000", backgroundColor: 'rgba(6,114,185,0.6979166666666667)'}}>
                                        {
                                            Object.keys(this.getResults(results.results, groupKey, LOWER_LEFT)).map((k, ind) => {
                                                return (
                                                    <React.Fragment key={ind} >
                                                        <Text style={{ marginHorizontal: '10', marginVertical: '10', fontSize: '10pt'}}>{results.results[groupKey][LOWER_LEFT][k]} </Text>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </View>
                                    <View style={{ width: '49%', height: '90', textAlign: "left", borderRadius: '2px',border: "1px solid #000", backgroundColor: 'rgba(142,115,179,0.6979166666666667)'}}>
                                        {
                                            Object.keys(this.getResults(results.results, groupKey, LOWER_RIGHT)).map((k, ind) => {
                                                return (
                                                    <React.Fragment key={ind} >
                                                        <Text style={{ marginHorizontal: '10', marginVertical: '10', fontSize: '10pt'}}>{results.results[groupKey][LOWER_RIGHT][k]} </Text>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </View>
                                </View>
                            
                                <View style={{flexDirection: 'row'}}>
                                    <View  style={{ width: '49%'}}>
                                        <Text style={{ fontSize: '10pt', textAlign: 'center'}}>Not Urgent &amp; Not Important</Text>
                                    </View>
                                    <View style={{ width: '49%'}}>
                                        <Text style={{ fontSize: '10pt', textAlign: 'center'}}>Important &amp; Not Urgent</Text>
                                    </View>
                                </View>
                                    
                                
                            </View>
                        </React.Fragment>       
                        );
                    })}
                    </View>
                </Page>
            </Document> 
        ) 
    }

        return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">{results.name}</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {Object.keys(results.results)
                    .sort()
                    .map((groupKey, ind) => {
                        return (
                        <div key={ind}>
                            <Typography variant="h4"  className={`${classes.spacingTop} ${classes.spacingBottom}`}>{results.groups[groupKey].title}</Typography>
                            <Grid container spacing={2} className={classes.spacingTop}>
                                <Grid item md={12} lg={10}>
                                    <Grid container>
                                    <Grid item xs={6} align="center" className={classes.boldedText}>Urgent &amp; Not Important</Grid>
                                    <Grid item xs={6} align="center" className={classes.boldedText}>Urgent &amp; Important</Grid>
                                    <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_ul} ${classes[URGENT_AND_NOT_IMPORTANT]}`}>
                                        <div className={classes.padMe}>
                                            {
                                                Object.keys(this.getResults(results.results, groupKey, UPPER_LEFT)).map((k, ind) => {
                                                    return (
                                                        <div className={classes.spacingBottom} key={ind}>
                                                            <Typography variant="body1">{results.results[groupKey][UPPER_LEFT][k]}</Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_ur} ${classes[URGENT_AND_IMPORTANT]}`}>
                                        <div className={classes.padMe}>
                                            {
                                                Object.keys(this.getResults(results.results, groupKey, UPPER_RIGHT)).map((k, ind) => {
                                                    return (
                                                        <div className={classes.spacingBottom} key={ind}>
                                                            <Typography variant="body1">{results.results[groupKey][UPPER_RIGHT][k]}</Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_bl} ${classes[NOT_URGENT_AND_NOT_IMPORTANT]}`}>
                                        <div className={classes.padMe}>
                                            {
                                                Object.keys(this.getResults(results.results, groupKey, LOWER_LEFT)).map((k, ind) => {
                                                    return (
                                                        <div className={classes.spacingBottom} key={ind}>
                                                            <Typography variant="body1">{results.results[groupKey][LOWER_LEFT][k]}</Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_br} ${classes[IMPORTANT_AND_NOT_URGENT]}`}>
                                        <div className={classes.padMe}>
                                            {
                                                Object.keys(this.getResults(results.results, groupKey, LOWER_RIGHT)).map((k, ind) => {
                                                    return (
                                                        <div className={classes.spacingBottom} key={ind}>
                                                            <Typography variant="body1">{results.results[groupKey][LOWER_RIGHT][k]}</Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} align="center" className={classes.boldedText}>Not Urgent &amp; Not Important</Grid>
                                    <Grid item xs={6} align="center" className={classes.boldedText}>Important &amp; Not Urgent</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        );
                    })}
                </Grid>
            </Grid>
        </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);