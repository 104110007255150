
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
  TableBody, TableCell, Collapse, TableHead, TableRow, Box, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import Footer from '../Footer';
import LogoHeader from '../Header';
import styles from '../Styles';
import { Typography } from '@material-ui/core';
import {saveActivityOptionSetFile, saveSpecificActivityOptionPath} from '../../store/actions/adminActions';

import Navigation from '../Navigation';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import SessionPlanningPage from './session_planning';


const INITIAL_STATE = {
  selectedHost: null
};



class PlanningPage extends Component {
  constructor(props) {
        super(props);

        //console.log('props?', props);
        this.state = {
            ...INITIAL_STATE,
        };
    }

    onChange = (event) => {
        this.setState({
           ...this.state,
          [event.target.name]: event.target.value });
    
    };

    componentDidUpdate(){
        if ( isLoaded(this.props.hosts) === true && isLoaded(this.props.profile) === true && isLoaded(this.props.auth) === true && this.state.selectedHost === null ){
            //console.log('here?', this.props.profile, isLoaded(this.props.hosts));
            this.setState({
                selectedHost: this.props.profile.role === 'host' ? this.props.auth.uid : false
            });
        }
    }

    render() {

        if ( !this.props.auth.uid ){
            return <Redirect to={ROUTES.LOGIN} />;
        }

        if ( !isLoaded(this.props.hosts) && !isLoaded(this.props.profile) ){
            return null;
        } 

        //console.log('hosdfsst: ', this.state.selectedHost);

        return (
            <div className='meta-react'>
                <Navigation profile={this.props.profile} auth={this.props.auth}/>
                <LogoHeader profile={this.props.profile} auth={this.props.auth} />
                <div className='outer-container'>
                    <div className='container'>
                    <Typography variant="h4">Session Planning</Typography>
                        <br/>
                        { /* HOST SELECTION HERE */ }
                        {
                            this.state.selectedHost === false && this.props.profile.role === 'admin' ?
                            <div>
                                <Typography>Host Selector</Typography>
                                <InputLabel id='host-selector-label'>Please select host to view theme data.</InputLabel>
                                <Select aria-describedby='Host selector' value={this.state.selectedHost} name='selectedHost' onChange={this.onChange}>
                                    {
                                    this.props.hosts.map((t, index)=>{
                                        return(
                                        <MenuItem key={index} value={t.id}>{t.displayName}</MenuItem>
                                        )
                                    })
                                    }
                                </Select>
                            </div>
                        : 
                            this.props.profile.role === 'admin' ?
                            <Button variant="contained" color="primary" style={{marginBottom: 15}} onClick={() => { this.setState({ ...this.state, selectedHost: false })}}>Select a Different Host</Button>
                            : null
                        }

                        { this.state.selectedHost !== false ?
                            <SessionPlanningPage host={this.state.selectedHost} profile={this.props.profile} auth={this.props.auth}/>
                        : null }
                        

                    

                    </div>
                    </div>
                    <Footer profile={this.props.profile} auth={this.props.auth}/>
            </div>
        )
   
    
    }
}



const mapStateToProps = (state) => {
  return {
    hosts: state.firestore.ordered.hosts,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => {
    if ( props.auth.uid === undefined ){
      return [];
    }

    return [
        {
          collection: 'players',
          where: [
            ['role', '==', 'host'],      
          ],
          storeAs: 'hosts'
        },
    ]
     
    
    
  })
)(PlanningPage);