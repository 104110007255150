import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { deepPurple } from '@material-ui/core/colors';
import gol_logo from '../../images/gol_logo.png';

const styles = {
  root: {
    flexGrow: 1,
    height: '100%',
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  logo: {
    width: "auto",
    maxHeight: "180px",
    margin: '0 auto',
    display: 'block',
  },
  purpleLine: {
    background: deepPurple[500],
    display: 'block',
    height: 10,
  },
  headerImage: {
    display: 'block',
    margin: 0,
    padding: 0,
  },
  headerBlock: {
    backgroundColor: '#ffffff',
    height: 250,
    display: 'block',
    backgroundSize: '75%',
    backgroundPosition: 'center left',
  },
  headerTabletBlock: {
    backgroundColor: '#ffffff',
    height: 250,
    display: 'block',
    backgroundSize: '75%',
    backgroundPosition: 'center left',
  },
  headerMobileBlock: {
    backgroundColor: '#ffffff',
    height: "auto",
    display: 'block',
  },
  header: {
    height: 250,
  },
  soltaLogo: {
    width: 250,
    marginRight: 50,
    marginTop: 50,
    '@media (max-width: 1919px)': {
      marginRight: 0,
    },
    '@media (max-width: 1025px)': {
      width: 175,
    },
    '@media (max-width: 959px)': {
      width: 150,
      marginTop: 80,
    },
  },
  purpleGradient: {
    background: 'linear-gradient(0deg, rgba(255,255,255,0.45) 0%, rgba(204,156,201,0.45) 100%)',
    height: 60,
    display: 'block',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    color: ' rgba(255,255,255,0.45) 0% !important',
    top: 25,
    '@media (max-width: 1919px)': {
      right: '25%',
    },
    '@media (max-width: 1300px)': {
      right: '25%',
    },
    '@media (max-width: 959px)': {
      right: '10%',
      top: 40,
    },
    '@media (max-width: 599px)': {
      right: '25%',
      top: 0,
    },
    purple: {
      color: 'rgba(67,22,63,1) !important',
    },
  },
};

class ImageHeader extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className='outer-header-container'>
        <div className={classes.root}>
          <Grid container spacing={0}>
              <Grid item xl={12} lg={12} xs={12} className={classes.headerBlock}>
                <Grid item container xl={12} xs={12} className={classes.header}>
                  <Grid item xl={4} lg={4} md={4} xs={4}></Grid>
                  <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                    <Grid container item xs={12} lg={12} xl={12} spacing={1} className={classes.header} justify='center' alignItems='center'>
                      <Grid item xs={12} align='right'>
                        <div className={classes.logoBlock}>
                          <img src={gol_logo} alt='Solta' className={classes.logo} />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} xs={4} align='right' container alignItems='flex-start'>
                    <Grid item xs={12}></Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Paper square elevation={0} className={classes.purpleLine}></Paper>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Paper square elevation={0} className={classes.purpleGradient}></Paper>
              </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

ImageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { };
};

const mapDispatchToProps = (dispatch) => {
  return { };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(ImageHeader);