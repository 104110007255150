import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import WalkProfileResult from './../specific_components/profileResult';
import { getUserGroup_hc } from '../../game_functions/functions';
import { PROFILE_EMPLOYEE, PROFILE_MANAGER, ProfileCategories } from './../profiles/profileList_temp';
import walkIcon from '../images/walkawhileCropped.png';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    PDFDownloadLink
  } from "@react-pdf/renderer";
  import { PDFHeader } from './../../../../PastData/PDFHeader';

const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }


    render() {
        const {
            results,
            classes,
            isPDFView,
            profiles
        } = this.props;
        console.log('results?', results);

        if(this.props.isPDFView === true){
            return (
                <Document>
                     <Page wrap style={{padding: "30 0"}}>
                        <PDFHeader/>
                        <View>
                            <Image style={{marginHorizontal: "210", marginBottom: "30"}} src={walkIcon }/>
                            {Object.keys(results.results)
                            .sort()
                            .map((groupKey, ind) => {
                            return (
                            <React.Fragment key={ind}>
                                <View style={{marginHorizontal: "50", marginBottom: "50"}}>
                                    <Text style={{marginBottom: "10", fontSize: "20pt"}}>{results.groups[groupKey].title}</Text> 
                                    <View>
                                    {Object.keys(ProfileCategories).map((p, indy) =>{
                                        return(
                                            <View key={indy} style={{ marginBottom: '5' ,borderRadius:"5px", border: "1px solid #000000", alignContent: "center", textAlign: 'center'}} wrap={false}>
                                                <Text style={{ fontSize:"10pt", fontWeight: 700, marginVertical: "10"}}>SCENARIO {p}</Text>
                                                
                                                <Text style={{ fontSize:"12pt"}}>{ProfileCategories[p]}</Text>
                                                <View style={{marginHorizontal: "7", marginVertical: "5", borderRadius:"15px", border: "1px solid #D3D3D3", textAlign: 'left', flexDirection: "column"}}>
                                                {results.results[groupKey].hasOwnProperty(`step_${p}`) ?
                                                    results.results[groupKey][`step_${p}`].notes !== false && results.results[groupKey][`step_${p}`].notes !== undefined ?
                                                
                                                        results.results[groupKey][`step_${p}`].notes.map((note, n)=>{
                                                            return(
                                                                <Text key={n} style={{marginVertical: "20", marginLeft: "10" ,fontSize: "12pt" ,fontWeight: "bold"}}>{note}</Text>
                                                            )
                                                        })
                                                    : <Text style={{ marginVertical: "20", marginLeft: "10" ,fontSize:"12pt"}} >Your notes for this scenario are empty.</Text>
                                                : <Text style={{ marginVertical: "20", marginLeft: "10" ,fontSize:"12pt"}}>Your notes for this scenario are empty.</Text>
                                                }
                                            </View>
                                        </View>
                                        )
                                    })}                                   
                                   </View>
                                </View> 
                            </React.Fragment>       
                            );
                        })}
                        </View>
                    </Page>
                </Document> 
            ) 
        }

       return (
           <div>
            <Typography variant="h3">{results.name}</Typography>
            <Typography variant='h5' className={classes.spaceBottom}>
                Debrief
            </Typography>
            {Object.keys(results.groups).sort().map((groupKey, ind) => {
                console.log(results.results[groupKey]);
                if ( groupKey === 'group-0'){
                    return null;
                }
                if ( !results.results.hasOwnProperty(groupKey) ){
                    return null;
                }
                return (
                <Grid container key={ind}>
                    <Grid item xs={12} sm={12} className={classes.spacingTop} style={{paddingBottom: "30px"}}>
                    <Typography variant="h3" className={classes.spacingTop}>{results.groups[groupKey].title}</Typography>
                
                    
                    { Object.keys(ProfileCategories).map((step, ind) => {        
                        return(
                        <Grid container spacing={3} key={ind} className={`${classes.spacingBottom} ${classes.walkResult} ${classes.spacingTop}`}>
                            <Grid item xs={12}>
                                <Typography variant='h5' className={`${classes.spaceBottom} ${classes.profileTypeDescriptor} ${classes.minorMargin}`} align="center">Scenario {step}<br/><span className={classes.profileScenario}>{ProfileCategories[step]}</span></Typography>  
                                <List className={classes.listClass}>
                                    {
                                    results.results.hasOwnProperty(groupKey) ?
                                        results.results[groupKey].hasOwnProperty(`step_${step}`) ?
                                        results.results[groupKey][`step_${step}`].hasOwnProperty('notes')
                                            ? results.results[groupKey][`step_${step}`].notes.map((item, index) => {
                                                return (        
                                                    <ListItem key={index}>                 
                                                        <ListItemText
                                                            className={classes.bodyText} 
                                                            primary={item}                                
                                                        />
                                                    </ListItem>
                                                )
                                            })
                                            : <div className={classes.padMe}>Your notes for this scenario are empty.</div> 
                                            : 
                                            <div className={classes.padMe}>Your notes for this scenario are empty.</div>
                                        :
                                        <div className={classes.padMe}>Your notes for this scenario are empty.</div>
                                }
                                </List>
                            </Grid>
                        </Grid>)
                        })}

                    </Grid>
                </Grid>
                )
            })}
         </div>
    )






        /*const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
        return (
        <div>
            <Typography variant="h3">{results.name}</Typography>
            <Typography variant='h5' className={classes.spaceBottom}>
                Debrief
            </Typography>
            <Typography variant="h4" className={classes.spacingBottom}>Your Notes:</Typography>
            { Object.keys(ProfileCategories).map((step, ind) => {        
                return(
                <Grid container spacing={3} key={ind} className={`${classes.spacingBottom} ${classes.walkResult}`}>
                    <Grid item xs={12}>
                        <Typography variant='h5' className={`${classes.spaceBottom} ${classes.profileTypeDescriptor} ${classes.minorMargin}`} align="center">Scenario {step}<br/><span className={classes.profileScenario}>{ProfileCategories[step]}</span></Typography>  
                        <List className={classes.listClass}>
                            {
                            results.results.hasOwnProperty(group_id) ?
                                results.results[group_id].hasOwnProperty(`step_${step}`) ?
                                results.results[group_id][`step_${step}`].hasOwnProperty('notes')
                                    ? results.results[group_id][`step_${step}`].notes.map((item, index) => {
                                        return (        
                                            <ListItem key={index}>                 
                                                <ListItemText
                                                    className={classes.bodyText} 
                                                    primary={item}                                
                                                />
                                            </ListItem>
                                        )
                                    })
                                    : <div className={classes.padMe}>Your notes for this scenario are empty.</div> 
                                    : 
                                    <div className={classes.padMe}>Your notes for this scenario are empty.</div>
                                :
                                <div className={classes.padMe}>Your notes for this scenario are empty.</div>
                           }
                        </List>
                    </Grid>
                </Grid>)
            })}
        </div>
        );*/
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
