import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InterruptionPostIt from './interruptionPostIt';
import { updateGroupStateParamsVal, editResultsPath } from './../../../../../store/actions/gameActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Interruptions } from './../data/data';
import Backdrop from '@material-ui/core/Backdrop';
import styles from '../../../../Styles';


const INITIAL_STATE = {
    showPostIt: false
};

class Interruption extends Component {
    constructor(props){
        super(props);
        this.state = {...INITIAL_STATE};
    }

    handleClick = () => {  }

    componentDidMount(){
        setTimeout(() => {
            this.setState({
                ...this.state,
                showPostIt: true
            })
        },4000);
        // create postIT in DB
        const result = Interruptions.filter(v => v.id === this.props.id );
        let text = "";
        if ( result.length === 1 ){
            text = result[0].profile_text;
        }
        let p = `active_game.results.${this.props.group_id}.ua.${this.props.id}`;
        this.props.editResultsPath(p, text, this.props.host);
    }

    componentDidUpdate(prevProps, prevState){}

    render() {
        const { classes } = this.props;
        return (
            <Backdrop className={classes.backdrop} open={true}>
                { 
                    this.state.showPostIt === false ?
                        <div className="interruption-alert">Something Has Come Up!</div>
                    :
                    <InterruptionPostIt id={this.props.id}
                    group_id={this.props.group_id}
                    host={this.props.host}
                    control={this.props.control}/>                   
                }
            </Backdrop>        
        );      
    }
}

Interruption.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
        updateGroupStateParamsVal : (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID)),
        editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID)),
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Interruption);